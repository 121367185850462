import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { VendorResponse } from 'Domain/Vendor';
import { getVendorsByIdService } from './getVendorsByIdService';

interface UseVendorsByIdProps {
  vendorId?: string;
  country?: string;
}

export const useVendorsById = ({
  vendorId,
  country,
}: UseVendorsByIdProps): UseQueryResult<VendorResponse> => {
  const result = useQuery({
    queryKey: ['vendor', vendorId, country],
    queryFn: () =>
      getVendorsByIdService({ vendorId: vendorId as string, country: country as string }),
    enabled: !!vendorId && !!country,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  return result;
};
