import { useAuthenticationService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { useGetUserPreferences } from '../hooks/useGetUserPreferences';
import { useHeader } from '../hooks/useHeader';
import { UseWebSocket } from '../interfaces';

export const useWebSocket = ({ url, onOpen, onMessage, onError, onClose }: UseWebSocket) => {
  const authentication = useAuthenticationService().getAuthHeader();
  const { user } = useGetUserPreferences();
  const { vendorId, country } = useHeader();

  useEffect(() => {
    const newSocket = new WebSocket(url);

    newSocket.onopen = onOpen;
    newSocket.onmessage = onMessage;
    newSocket.onerror = onError;
    newSocket.onclose = onClose;

    return () => {
      newSocket.close();
    };
  }, [vendorId, country, authentication, user.id]);
};
