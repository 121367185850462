import { Error403 } from '@hexa-ui/components';
import { Box } from '@material-ui/core';

const Error403Page = () => (
  <Box height={'90vh'} display="flex" justifyContent={'center'} alignItems={'center'}>
    <Error403 hasButton={false} />
  </Box>
);

export default Error403Page;
