export const CARD_COMPONENT_TEST_IDS = {
  CARD_COMPONENT: 'card-component',
  CARD_TITLE: 'card-title',
  INFO_CONTAINER: 'info-container',
  INFO: 'info',
  COUNT: 'count',
  TOTAL: 'total',
  PERCENTAGE_INFO: 'percentage-info',
  PERCENTAGE: 'percentage',
  CHEVRON_BUTTON: 'chevron-button',
  ERROR_MESSAGE: 'error-message',
};

export const OVERVIEW_REFRESH_DATA_TEST_IDS = {
  REFRESH_BUTTON: 'refresh-button',
  REFRESH_ERROR_LABEL: 'refresh-error-label',
  REFRESH_LABEL: 'refresh-last-time-update-label',
};

export const OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS = {
  TOTAL_VISITS_TITLE: 'total-visits-title',
  TOTAL_VISITS_COUNT_LABEL: 'total-visits-count',
  TOTAL_VISITS_COUNT_VALUE: 'total-visits-count-value',
};

export const FILTERS_TEST_IDS = {
  FILTERS_CONTAINER: 'filters-container',
  FILTERS_BY_PERIOD_CONTAINER: 'filter-by-period-container',
  FILTER_BUTTON: 'filter-button',
  FILTER_INPUT: 'filter-input',
  FILTER_BUTTON_LABEL: 'filter-button-label',
  FILTER_BUTTON_ICON_CHECK: 'filter-button-icon-check',
  FILTER_BUTTON_ICON_CALENDAR: 'filter-button-icon-calendar',
  FILTER_BUTTON_ICON_CHEVRON_DOWN: 'filter-button-icon-chevron-down',
};
