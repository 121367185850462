import React from 'react';
import { Label } from './styles';

interface StatusColumnProps {
  fontColor: string;
  borderColor: string;
  text: string;
}

export const StatusColumn: React.FC<StatusColumnProps> = ({ fontColor, borderColor, text }) => {
  return <Label label={text} $fontColor={fontColor} $borderColor={borderColor} />;
};
