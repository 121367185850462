import { IActionLink } from "@/types/common";
import { IExperiment } from "@/types/experiment";
import { Grid, Heading, Paragraph } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import CardHeading from "../../CardHeading/CardHeading";
import EmptySection from "../EmptySection/EmptySection";

const AudienceSelect = ({
  experiment,
  action,
}: {
  experiment: IExperiment;
  action?: IActionLink;
}): JSX.Element => {
  const { Item } = Grid;

  const AudienceInfo = () => {
    const audience = experiment?.countryAsAudience
      ? {
          title: "country_as_audience_title",
          content: (
            <FormattedMessage
              id={`experiment_details.audience_selection.country_as_audience_text`}
            />
          ),
        }
      : {
          title: "audience_title",
          content: experiment?.audiences[0]?.name,
        };
    const audienceInfo = {
      title: (
        <FormattedMessage
          id={`experiment_details.audience_selection.${audience.title}`}
        />
      ),
      content: audience.content,
    };
    return (
      <>
        <Heading size="H5">{audienceInfo.title}</Heading>
        <Paragraph className="mt-1">{audienceInfo.content}</Paragraph>
      </>
    );
  };

  const percentage = experiment?.countryAsAudience
    ? experiment?.countryTrafficPercentage
    : !!experiment?.audiences && !!experiment?.audiences.length
      ? experiment?.audiences[0]?.trafficPercentage
      : "";

  return (
    <Item
      className="px-0"
      sm={12}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <CardHeading
        heading={
          <Heading size="H3">
            <FormattedMessage id="experiment_details.audience_selection.audience_selection" />
          </Heading>
        }
        action={action}
      />
      {experiment &&
      (experiment?.countryAsAudience || !!experiment?.audiences?.length) ? (
        <Item
          lg={12}
          md={12}
          sm={12}
          xl={2}
          xs={12}
          style={{ marginTop: 32, padding: 0 }}
        >
          <Item lg={4} md={4} sm={12} xl={4} xs={12}>
            <div style={{ width: "100%" }}>
              <AudienceInfo />
            </div>
          </Item>
          {!experiment?.countryAsAudience && (
            <Item lg={4} md={4} sm={12} xl={4} xs={12}>
              <div style={{ width: "100%" }}>
                <Heading className="labelHeading" size="H5">
                  <FormattedMessage id="experiment_details.audience_selection.estimated_pocs_target" />
                </Heading>
                <Paragraph className="mt-1">
                  {experiment?.audiences[0]?.estimatedPocTargeted || "-"}
                </Paragraph>
              </div>
            </Item>
          )}
          <Item lg={4} md={4} sm={12} xl={4} xs={12}>
            <div style={{ width: "100%" }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.audience_selection.percentage_roll_out" />
              </Heading>
              <Paragraph className="mt-1">{percentage}%</Paragraph>
            </div>
          </Item>
        </Item>
      ) : (
        <EmptySection
          header={
            <FormattedMessage id="experiment_details.audience_selection.audience_selection" />
          }
          message={<FormattedMessage id="empty_session.audience_message" />}
        />
      )}
    </Item>
  );
};
export default AudienceSelect;
