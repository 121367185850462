import { Card, FileValidated, Grid, Select } from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { InfoButtonText } from '../../components/InfoField/InfoField';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledSelect from '../../components/Select/Select';
import StyledTextarea from '../../components/Textarea/Textarea';
import { changeStateForm } from '../../utils/functions';
import { base64CSVConverter } from '../../utils/toBase64';
import checkValidityCSV from '../../utils/verifyCSV';
import StyledInput from '../../components/Input/Input';

export default function ImportGuess() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('');
  const [form, setForm] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvError, setCsvError] = useState<boolean>(false);
  const csvData = [['name', 'poc']];

  const handleFile = async (file: FileValidated[]) => {
    setCsvError(false);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then((res: boolean) => res);
    if (isValid) {
      const convertedFile = await base64CSVConverter(file[0].file);

    } else {
      setCsvError(true);
      setLoading(false);
    }
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };


  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <PageTitle
          marginBottom="0.5rem"
          title="Import Winner Guess"
          hint=""
        />
      </Grid.Item>
      <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
        <InfoButtonText
          icon={() => <Download size="large" />}
          iconPosition="leading"
          size="large"
          css={{ gap: 11 }}
        >
          <a
            href={
              'data:application/octet-stream;base64,bmFtZSxwb2MKdGVzdGUsMQp0ZXN0ZTIsMgo='
            }
            download="Template_account.csv"
            style={{
              color: 'black',
              fontWeight: '500',
              lineHeight: '1.5rem',
              textDecoration: 'none',
              fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
            }}
          >
            Download template
          </a>
        </InfoButtonText>
        <a
          style={{ textDecoration: 'none' }}
          target={'_blank'}
          href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
        >
          <InfoButtonText
            icon={() => <Info size="xlarge" />}
            iconPosition="leading"
            size="large"
            css={{ gap: 11 }}
          >
            See instructions
          </InfoButtonText>
        </a>
      </Grid.Item>
      <Card elevated="medium" border="medium" css={{ width: '100%' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'center',
            padding: '1.5rem 0 1rem 0',
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0 }}>
              <StyledInput
                size="large"
                label="Tournament campaign*"
                width={'100%'}
                value={"Tournament"}
                placeholder="Insert you text here"
                disabled
              />
              <StyledInput
                size="large"
                label="Ranking config*"
                disabled
                width={'100%'}
                value={"Classificatórias"}
                placeholder="Insert you text here"
              />
               <StyledSelect
                  width={'100%'}
                  label="Guess type*"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <Select.Option value="forfun">For Fun</Select.Option>
                  <Select.Option value="forprize">For Prize</Select.Option>
                </StyledSelect>
              <StyledTextarea
                style={{ height: '222px', resize: 'none' }}
                defaultValue={form?.description}
                value={form?.description}
                required
                label="Description"
                hint="Description of the import that will be performed."
                placeholder="Import made on MM/DD/YY - HH:MM"
                width="100%"
                type="textarea"
                height="222px"
                characterCounter
                maxLength={150}
                rows={12}
                onChange={(e) =>
                  changeStateForm(setForm, form, 'description', e.currentTarget.value)
                }
              />
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xl={4} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
            <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
              style={{ marginBottom: '12px', minWidth: '100% ' }}
              options={[
                {
                  id: 'comma',
                  label: ', (Comma)',
                  value: ','
                },
                {
                  id: 'semicolon',
                  label: '; (Semicolon)',
                  value: ';'
                }
              ]}
              onChange={(value => changeStateForm(setForm, form, 'delimiter', value))}
            />
            <StyledFileUploader
              legend={
                <>
                  The csv must have the <b>name</b> and <b>poc</b>{' '}
                  columns.
                </>
              }
              title="Import .csv file"
              accept=".csv"
              maxFileSize={1024 * 1024 * 5}
              message="File must be .csv and must be less than 5MB"
              onDrop={(file) => handleFile(file)}
              maxFiles={1}
              type="file"
              error={
                csvError
                  ? {
                    message: `The csv is not valid. Columns don't match!`,
                  }
                  : undefined
              }
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => navigate(-1)}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Card>
    </>
  );
}
