/* eslint-disable @typescript-eslint/no-explicit-any */
import { useUserMetadata } from 'admin-portal-shared-services';
import { TrackingContext } from 'Context/TrackingContext';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { useStore } from 'effector-react';
import { useContext } from 'react';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { SegmentProps } from './useSegmentProperties.types';

export function useSegmentProperties(): SegmentProps {
  const { workspaceData } = useWorkspaceContext();
  const userMetadata = useUserMetadata();
  const { jwtDecoded } = useStore(LoggedUserStore);
  const { data } = useUserMetadata();
  const userInfo: any = data;
  const trackingContext = useContext(TrackingContext);

  return {
    app_display_name: 'Analytics',
    app_id: 'PARTNER-REPORTS',
    page_url: window.location.href,
    user_country: jwtDecoded?.country,
    user_email: jwtDecoded?.email,
    user_id: userMetadata?.data?.userId,
    user_affiliation: userInfo?.affiliation,
    vendor_country: workspaceData?.country,
    vendor_display_name: workspaceData?.vendorName,
    vendor_id: workspaceData?.vendorId,
    vendor_service_model: workspaceData?.vendorServiceModel ?? null,
    vendor_tier_name: workspaceData?.vendorTierName ?? null,
    origin_workspace_label: workspaceData?.workspace?.title?.['en-US'],
    origin_workspace_tracking_id: workspaceData?.workspace?.trackingId,
    origin_theme_label: trackingContext?.themeName ?? undefined,
    origin_theme_tracking_id: trackingContext?.themeTrackingId ?? undefined,
    origin_report_label: trackingContext?.reportName ?? undefined,
    origin_report_tracking_id: trackingContext?.reportTrackingId ?? undefined,
    page_label: trackingContext?.themeName
      ? `${trackingContext?.themeName} - ${trackingContext?.reportName}`
      : null,
  };
}
