import { Paper } from '@/components/core'
import { GlobalEvents, GlobalStore } from '@/stores'
import { Box, Button, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { useStoreMap } from 'effector-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { nanoid } from 'nanoid'
import { BASE_ROUTE } from '@/utils/constants'
import { IProgram } from '@/domains/program'
import { useAvailablePrograms } from '@/hooks/program/useAvailablePrograms'

const SelectProgramRoute: React.FC = () => {
	const { t } = useTranslation()
	const { goToHome } = useNavigation()

	const selectedProgram = useStoreMap({
		store: GlobalStore,
		keys: [],
		fn: (state) => state.selectedProgram,
	})
	const { programs, isLoadingPrograms } = useAvailablePrograms()
	const [newSelectedProgram, setNewSelectedProgram] = useState<IProgram | null>(null)

	const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
		setNewSelectedProgram({
			id: e.target.value as string,
			name: programs.find((p) => p.id === e.target.value)?.name ?? '',
		})
	}

	const onClick = (): void => {
		GlobalEvents.selectProgram(newSelectedProgram)
		goToHome()
	}

	const options = programs.map(({ id, name }) => (
		<MenuItem key={nanoid()} id={`program-${id}`} value={id}>
			{name}
		</MenuItem>
	))

	const select = (
		<Select
			id="selectCountry"
			data-testid="selectCountry"
			displayEmpty
			autoFocus
			name="selectCountry"
			value={newSelectedProgram?.id ?? ''}
			onChange={onChange}
			fullWidth
			variant="outlined"
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
			}}
		>
			{options}
		</Select>
	)

	useEffect(() => {
		if (isLoadingPrograms) {
			GlobalEvents.load()
		} else {
			GlobalEvents.loadDone()
		}
		return () => {
			GlobalEvents.loadDone()
		}
	}, [isLoadingPrograms])

	useEffect(() => {
		if (selectedProgram) {
			setNewSelectedProgram(selectedProgram)
		}
	}, [selectedProgram])

	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/select-program`}>
				<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
					<Typography variant="h2" style={{ marginTop: '16px', marginBottom: '43px' }}>
						Select a Program
					</Typography>
					<Grid container justifyContent="center">
						<Grid item xl={6} lg={8} md={9} sm={12}>
							<Box mb={6}>
								<Paper>
									{select}
									<Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
										<Button type="button" variant="contained" color="primary" id="submit-btn" onClick={onClick}>
											{t('BUTTON.SUBMIT')}
										</Button>
									</Grid>
								</Paper>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Route>
		</Switch>
	)
}

export default SelectProgramRoute
