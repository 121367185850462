import { useIntl } from 'react-intl';

import { Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';

import { BdrInformation } from './BdrInformation/BdrInformation';
import { VISIT_DETAILS_CARD_TEST_IDS } from './consts/VisitDetailsCardConsts';
import { useVisitDetails } from './hooks/useVisitDetails';
import { NextVisit } from './NextVisit/NextVisit';
import { PocInformation } from './PocInformation/PocInformation';
import * as Styled from './VisitDetailsCard.styles';
import { VisitInformation } from './VisitInformation/VisitInformation';

export const VisitDetailsCard = () => {
  const { formatMessage } = useIntl();
  const { handleClickGoBackButton } = useVisitDetails();

  return (
    <>
      <Styled.VisitDetailsHeader>
        <IconButton
          icon={ArrowLeft}
          variant="tertiary"
          data-testid={VISIT_DETAILS_CARD_TEST_IDS.GO_BACK_ICON_BUTTON}
          size="medium"
          onClick={handleClickGoBackButton}
        />
        <Heading data-testid={VISIT_DETAILS_CARD_TEST_IDS.HEADER} size="H2">
          {formatMessage({ id: 'Components.VisitDetails.Title.VISIT_DETAILS' })}
        </Heading>
      </Styled.VisitDetailsHeader>
      <Styled.WrapperVisitDetailsCard>
        <VisitInformation />
        <PocInformation />
        <NextVisit />
        <BdrInformation />
      </Styled.WrapperVisitDetailsCard>
    </>
  );
};
