/* eslint-disable max-params */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EmbedReportResponse } from 'Domain/NewEmbedReport';
import { getEmbedReport, getEmbedReportProps } from './getEmbedReport';

export const useGetEmbedReport = ({
  lang,
  workspaceId,
  reportId,
  permissionId,
  vendorId,
  country,
  powerBiWorkspaceId,
  powerBiReportId,
}: getEmbedReportProps): UseQueryResult<EmbedReportResponse> =>
  useQuery({
    queryKey: [
      'getEmbedReport',
      lang,
      workspaceId,
      reportId,
      permissionId,
      vendorId,
      powerBiWorkspaceId,
      powerBiReportId,
    ],
    queryFn: () =>
      getEmbedReport({
        lang,
        workspaceId,
        reportId,
        permissionId,
        vendorId,
        country,
        powerBiWorkspaceId,
        powerBiReportId,
      }),
    enabled: !!lang && !!workspaceId && !!reportId,
    refetchOnWindowFocus: false,
    staleTime: 7500,
  });
