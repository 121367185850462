import axios from 'axios';
import { base_url } from '../utils/constants';

export const apiDrupal = axios.create({
  baseURL: `${base_url}/hub/v1/competition`
})

apiDrupal.defaults.headers['Authorization'] = 'Basic YmVlc19hZG1pbjpiZWVzX2FkbWlu'
apiDrupal.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
apiDrupal.defaults.data = {
  country: 1
}

apiDrupal.interceptors.request.use(function (config) {
  // console.log("HTTP_REQUEST_INTERCEPTED", config)

  if (config.method === "POST" && config.url.includes("campaign") && localStorage.getItem("country")) {
    config.data.country = localStorage.getItem("country")
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export const apiDrupalV2 = axios.create({
  baseURL: `${base_url}/hub/v2/competition`
})

apiDrupalV2.defaults.headers['Authorization'] = 'Basic YmVlc19hZG1pbjpiZWVzX2FkbWlu'
apiDrupalV2.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
apiDrupalV2.defaults.data = {
  country: 1
}

apiDrupalV2.interceptors.request.use(function (config) {
  // console.log("HTTP_REQUEST_INTERCEPTED", config)

  if (config.method === "POST" && config.url.includes("campaign") && localStorage.getItem("country")) {
    config.data.country = localStorage.getItem("country")
  }

  return config;
}, function (error) {
  return Promise.reject(error);
}); 
