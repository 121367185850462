import {Campaign, ListRequestProps, Paginated} from '@Models';
import {parseFilterStructure} from '@martech/utils';
import {useApi} from '../../api';

export default async function getCampaignsService(
  pageNumber: number,
  props: ListRequestProps
): Promise<Paginated<Campaign> | any> {
  const {searchTerm, filter, sort} = props;
  const searchString = searchTerm ? `&campaignName=${searchTerm}` : '';
  const filterString = parseFilterStructure(filter);
  const sortString = sort?.prop
    ? `&sortField=${sort.prop}&sortDirection=${sort.direction}`
    : '';

  return await useApi().get({
    url: `/v1/admin/campaigns?pageNumber=${pageNumber}${sortString}${searchString}${filterString}`,
  });
}
