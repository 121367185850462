import { getDataTestForInputs } from '@utils/buildDataTestId';
import Paragraph from 'components/typography/Paragraph';
import { useCallback } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import { Box, BoxWrapper, StyledLabel } from './AttributesListReview.styles';

type AttributeValue = boolean | string | string[] | Record<string, string | string[]> | Date;

interface AttributesListReviewProps {
  attributes: Record<string, AttributeValue>;
  groupAttribute?: boolean;
  allAttributes?: Array<ProductTypeAttribute>;
  'data-index'?: number;
}
const isDate = (obj: any): boolean => {
  return obj instanceof Date && !isNaN(obj.getTime());
};

const renderParagraph = (key, text, index = 0) => (
  <div data-index={index}>
    <Paragraph data-index={index} data-testid={getDataTestForInputs({ id: key })} text={text} />
  </div>
);

const renderAttributesListReview = (key, value, allAttributes, index = 0) => {
  const groupedAttributes = allAttributes?.find(
    (attr) => attr.defaultKey === key
  )?.groupedAttributes;

  return (
    <AttributesListReview
      groupAttribute
      attributes={value}
      allAttributes={groupedAttributes}
      data-index={index}
      data-testid={getDataTestForInputs({ id: key })}
    />
  );
};

const getValueType = (value) => {
  if (Array.isArray(value)) return 'array';
  if (typeof value === 'string') return 'string';
  if (typeof value === 'boolean') return 'boolean';
  if (typeof value === 'undefined') return 'undefined';
  if (typeof value === 'object') {
    if (isDate(value)) return 'date';
    if (value.value && value.uom) return 'objectWithValueAndUom';
    if (!value.value && !value.uom) return 'nestedObject';
  }
  return 'unknown';
};

const AttributesListReview = ({
  attributes,
  groupAttribute,
  allAttributes,
  'data-index': dataIndex = 0,
}: AttributesListReviewProps): React.ReactElement => {
  const getAttributeName = (defaultKey: string): string => {
    if (defaultKey.includes('-selectedIndexes')) return;
    const attributeName =
      allAttributes?.find((attr) => attr.defaultKey === defaultKey)?.name || defaultKey;
    return attributeName;
  };
  const { formatMessage, formatDate } = useIntl();
  const getTextFromStringValue = (value: string): string => {
    if (value === 'true') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_YES' });
    } else if (value === 'false') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_NO' });
    } else {
      return value || '-';
    }
  };

  const handleFormatDate = useCallback(
    (value: any) => {
      const dateOptions: FormatDateOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

      return formatDate(value, dateOptions);
    },
    [formatDate]
  );

  const renderValue = (key, value, index = 0) => {
    const valueType = getValueType(value);
    switch (valueType) {
      case 'array':
        return renderParagraph(key, value.length > 0 ? value.join(', ') : '-', index);
      case 'string':
        return renderParagraph(key, getTextFromStringValue(value), index);
      case 'boolean':
        return renderParagraph(key, value ? 'Yes' : 'No', index);
      case 'undefined':
        return renderParagraph(key, '-', index);
      case 'date':
        return renderParagraph(key, handleFormatDate(value), index);
      case 'objectWithValueAndUom':
        return renderParagraph(key, `${value.value} ${value.uom}`, index);
      case 'nestedObject':
        return renderAttributesListReview(key, value, allAttributes, index);
      case 'unknown':
        return renderAttributesListReview(key, value, allAttributes, index);
      default:
        return null;
    }
  };

  return (
    <BoxWrapper groupAttribute={groupAttribute}>
      {attributes &&
        Object.entries(attributes).map(([key, value]: [key: string, value: AttributeValue]) => {
          const attributeName = getAttributeName(key);
          if (!attributeName) return null;
          return (
            <Box key={key}>
              <StyledLabel id={key}>{attributeName}</StyledLabel>
              {renderValue(key, value, dataIndex)}
            </Box>
          );
        })}
    </BoxWrapper>
  );
};

export default AttributesListReview;
