import Loading from 'components/Loading/Loading';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isFeatureEnabled } from 'utils/featureToggles';

export const BASE_PATH = '/bees-gateway-web';
export const REPORTS_PATH = `${BASE_PATH}/payments`;
export const WORKFLOW_PATH = `${BASE_PATH}/workflow`;
export const CREATE_WORKFLOW_PATH = `${BASE_PATH}/workflow/create`;

const Base = lazy(() => import('../components/AuthLoadingWrapper'));
const Workflow = lazy(() => import('../pages/Workflow'));
const CreateWorkflow = lazy(() => import('../pages/Workflow/components/CreateWorkflowPage'));
const Reports = lazy(() => import('../pages/Reports'));

export const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path={BASE_PATH} element={<Navigate to={REPORTS_PATH} replace />} />

      <Route path={BASE_PATH} element={<Base />}>
        <Route path={REPORTS_PATH} element={<Reports />} />
        {isFeatureEnabled('workflow') && (
          <>
            <Route path={WORKFLOW_PATH} element={<Workflow />} />
            <Route path={CREATE_WORKFLOW_PATH} element={<CreateWorkflow />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  </Suspense>
);

export default AppRoutes;
