import { CodeData } from "./Common";

export enum EvaluationType {
  SELF = 'SELF',
  MANAGER = 'MANAGER',
}

export enum EvaluationStatus {
  IN_PROGRESS = 'IN PROGRESS',
  IN_ANALYSES = 'INANALYSES',
  COMPLETED = 'COMPLETED',
}

export enum EvaluationFeedbackStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
  UNAVAILABLE = 'Unavailable',
}

export interface EvaluationPillarsData {
  evaluationId: number,
  pillars: CodeData[],
}

export interface KpiReponse {
  id: number,
  name: string,
  selected: boolean,
}
export interface Kpi {
  id: number,
  name: string,
  comment: string,
  responses: KpiReponse[],
}

export type KpiResponseObj = {
  comment: string;
  id: number;
  responseId: number;
};

export interface EmployeeAllowSelfEvaluation {
  allow: boolean
}