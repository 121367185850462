import { faker } from '@faker-js/faker';
import { Factory, trait } from 'miragejs';
import { EvaluationFeedbackStatus, EvaluationStatus, EvaluationType } from '../types/Evaluation';
import { randomIntFromInterval } from '../utils/helperFunctions';
import {
  bandsQuantity,
  employeesPerTeamQuantity,
  kpiResponsesPerKpi,
  kpisQuantityPerPillar,
  rolesQuantity,
} from './seedsQuantity';

const randomEvaluationStatus = () => {
  const evaluationTypeValues = Object.values(EvaluationFeedbackStatus);
  return evaluationTypeValues[randomIntFromInterval(1, evaluationTypeValues.length) - 1];
};

const factories = {
  team: Factory.extend({
    name(i) {
      return `Team ${i + 1}`;
    },
    withEmployees: trait({
      afterCreate(team, server) {
        const teams = [team];
        server.createList(
          'employee',
          employeesPerTeamQuantity,
          'withRole',
          'withBand',
          'insertOneMoreTeam',
          {
            teams,
          }
        );
      },
    }),
  }),
  employee: Factory.extend({
    name(i) {
      return faker.name.fullName();
    },
    avatarUrl() {
      return faker.image.avatar();
    },
    hasEvaluation(i) {
      return Boolean(i > 2);
    },
    managerEvaluationStatus() {
      return randomEvaluationStatus();
    },
    selfEvaluationStatus() {
      return randomEvaluationStatus();
    },
    feedbackStatus() {
      return randomEvaluationStatus();
    },
    allowFeedback() {
      const isOdd = faker.datatype.number(50) % 2 == 0;
      return Boolean(isOdd);
    },
    withRole: trait({
      afterCreate(employee, server) {
        const role = server.schema.roles.find(randomIntFromInterval(1, rolesQuantity));
        employee.update({ role });
        employee.save();
      },
    }),
    withBand: trait({
      afterCreate(employee, server) {
        const band = server.schema.bands.find(randomIntFromInterval(1, bandsQuantity));
        employee.update({ band });
        employee.save();
      },
    }),
    insertOneMoreTeam: trait({
      afterCreate(employee, server) {
        const allTeams = server.schema.teams.all().models;
        const team = server.schema.teams.find(randomIntFromInterval(1, allTeams.length));
        const employeeAlreadyInThisTeam = employee.teamIds.includes(team.id);

        if (!employeeAlreadyInThisTeam) {
          employee.teamIds.push(team.id);
          employee.save();
        }
      },
    }),
  }),
  role: Factory.extend({
    name(i) {
      return `Role ${i + 1}`;
    },
  }),
  band: Factory.extend({
    name(i) {
      return `Band ${i + 1}`;
    },
  }),
  trail: Factory.extend({
    name(i) {
      return `Trail ${i + 1}`;
    },
  }),
  allocation: Factory.extend({
    percentage() {
      return faker.random.numeric(2);
    },
    withTeamsAndEmployees: trait({
      afterCreate(allocation, server) {
        const allTeams = server.schema.teams.all().models;
        const allEmployees = server.schema.employees.all().models;
        const employee = server.schema.employees.find(
          randomIntFromInterval(1, allEmployees.length)
        );
        const team = server.schema.teams.find(randomIntFromInterval(1, allTeams.length));
        allocation.teamId = team.id;
        allocation.employeeId = employee.id;
        allocation.save();
      },
    }),
  }),
  pillar: Factory.extend({
    name(i) {
      return `Pillar ${i + 1}`;
    },
    withKpis: trait({
      afterCreate(pillar, server) {
        server.createList('kpi', kpisQuantityPerPillar, 'withKpiResponse', {
          pillar,
        });
      },
    }),
  }),
  kpi: Factory.extend({
    name(i) {
      return `Kpi ${i + 1}`;
    },
    withKpiResponse: trait({
      afterCreate(kpi, server) {
        server.createList('kpiResponse', kpiResponsesPerKpi, {
          kpi,
        });
      },
    }),
  }),
  kpiResponse: Factory.extend({
    name() {
      return faker.lorem.lines(1);
    },
    evaluationValue() {
      return randomIntFromInterval(1, kpiResponsesPerKpi);
    },
  }),
  evaluation: Factory.extend({
    type() {
      const evaluationTypeValues = Object.values(EvaluationType);
      return evaluationTypeValues[randomIntFromInterval(1, evaluationTypeValues.length) - 1];
    },
    complete_date() {
      return null;
    },
    status_evaluation_id() {
      return EvaluationStatus.IN_PROGRESS;
    },
    withPillarsAndEmployee: trait({
      afterCreate(evaluation, server) {
        const pillars = server.schema.pillars.all().models;
        evaluation.update({ pillars });
        const employee = server.schema.employees.find(
          randomIntFromInterval(1, employeesPerTeamQuantity)
        );
        evaluation.employeeId = employee.id;
        evaluation.save();
      },
    }),
  }),
  evaluationResponse: Factory.extend({
    comments() {
      return '';
    },
    withEvaluationKpiResponseId: trait({
      afterCreate(evaluationResponse, server) {
        const evaluation = server.schema.evaluations.first();
        evaluationResponse.evaluationId = evaluation.id;
        const kpi = server.schema.kpis.find(evaluationResponse.attrs.id);
        evaluationResponse.kpiId = kpi.id;
        const kpiResponse = server.schema.kpiResponses.findBy({ kpiId: kpi.id });
        evaluationResponse.kpiResponseId = kpiResponse.id;
        evaluationResponse.save();
      },
    }),
  }),
  user: Factory.extend({
    isManager() {
      return true;
    },
    isAdmin() {
      return false;
    },
    situationStatus() {
      return 'ACTIVE';
    },
  }),
  score: Factory.extend({
    labels() {
      const labelsArr = Array.from(Array(5).keys()).map(() => faker.word.adjective(8));
      return labelsArr;
    },
    datasets() {
      const data = Array.from(Array(6).keys()).map(() => faker.random.numeric(2));
      return [
        {
          label: faker.word.adjective(5),
          data,
        },
      ];
    },
  }),
  feedback: Factory.extend({
    complete_date() {
      return null;
    },
    status_feedback_id() {
      return EvaluationStatus.IN_PROGRESS;
    },
    withPillarsAndEmployee: trait({
      afterCreate(feedback, server) {
        const pillars = server.schema.pillars.all().models;
        feedback.update({ pillars });
        const employee = server.schema.employees.find(
          randomIntFromInterval(1, employeesPerTeamQuantity)
        );
        feedback.employeeId = employee.id;
        feedback.save();
      },
    }),
  }),
};

export default factories;
