import {useApi} from '../../api';

export default async function blockPocManuallyFraudPreventionService(
  pocCode: string,
  campaignId: number
): Promise<any> {
  return await useApi().post({
    url: '/v1/admin/customer/block-fraud-manually',
    body: {pocCode: pocCode, campaignId: campaignId},
  });
}
