import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import StyledSelect from '../../../components/Select/Select';
import SearchDropdown from '../../../components/SearchDropdown/SearchDropdown';
import StyledToggle from '../../../components/Toggle/Toggle';

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

const CreateTournamentImport: React.FC = () => {
  const navigate = useNavigate();
  const { gameName } = useParams();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState('7200');
  const [selectedUtc, setSelectedUtc] = useState('UTC');

  function formatParamsName(str: string) {
    const words = str.split('-');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  const formattedParams = formatParamsName(gameName);

  // TODO:Remove when connecting to the API.

  const handleUtcChange = (value) => {
    setSelectedUtc(value);
  };

  const items = [{ name: 'Item 1' }, { name: 'Item 2' }, { name: 'Item 3' }];

  const search = (searchTerm) => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  return (
    <>
      <Grid.Container type="fluid" style={{ margin: 0 }}>
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Add API {formattedParams}
          </Heading>
        </StyledGridItem>

        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" css={{ paddingBottom: '0.5rem', width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Name*"
                      width={'100%'}
                      hint="The name of the Sports import entity."
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <SearchDropdown
                      width="100%"
                      label="Competition event"
                      hint="The competition event that the guess will be associated with."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <SearchDropdown
                      width="100%"
                      label="Competition event"
                      hint="The competition event that the guess will be associated with."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Football season*"
                      width={'100%'}
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      value={number}
                      label="Interval time to sync scores, in seconds*"
                      width={'100%'}
                      hint="After match started, put the interval time to sync scores, in seconds. This will be used to update matches of the tournament."
                      type="number"
                      min={0}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNumber(e.target.value);
                      }}
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledInput
                      type="datetime-local"
                      size="large"
                      hint="The cron will update this tournament until this date, after that it will be automatically stopped."
                      label="Sync end date*"
                      placeholder="Insert the date"
                      width={'100%'}
                      required
                      className="calendars"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledSelect
                      width={'100%'}
                      placeholder="UTC"
                      label="Time zone"
                      hint=" This should be the timezone you entered the above date in and will also be the timezone in which this event date is displayed."
                      value={selectedUtc}
                      onChange={handleUtcChange}
                    >
                      <Select.Option value="en">UTC</Select.Option>
                      <Select.Option value="br">Portugal</Select.Option>
                      <Select.Option value="fr">France</Select.Option>
                      <Select.Option value="de">Germany</Select.Option>
                    </StyledSelect>
                  </StyledPaddding>
                  <StyledToggle
                    title="Active?"
                    description="Status on indicates that the import is active and score sync will update the event's matches."
                    value={false}
                    onChange={() => null}
                  />
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '10px',
                }}
              >
                <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </StyledGridItem>
      </Grid.Container>
    </>
  );
};

export default CreateTournamentImport;
