import {Grid, Heading} from '@hexa-ui/components';
import React from 'react';
import {
  CustomNumberInput,
  CustomInput,
  CustomSelectWithSearch,
} from '@Components';
import {CurrencyInput} from '@martech/components';
import {useFormikContext} from 'formik';
import {useIntl} from 'react-intl';
import {BrandNameTypes} from '@Models';
import productTypeBr from '../../../../i18n/languages/pt-BR/productType';
import productTypeEn from '../../../../i18n/languages/en-US/productType';
import productTypeEs from '../../../../i18n/languages/es-419/productType';

interface ProductSettingsProps {
  promotionIndex: number;
}

interface ProductTypeMap {
  [key: string]: string;
}

const ProductSettings: React.FC<ProductSettingsProps> = ({promotionIndex}) => {
  const {formatMessage, locale} = useIntl();
  const {values, setFieldValue, setFieldTouched, touched, errors} =
    useFormikContext<any>();

  const productType =
    locale === 'pt-BR'
      ? productTypeBr
      : locale === 'es-419'
        ? productTypeEs
        : productTypeEn;

  const productTypeOptions = Object.keys(productType.PRODUCT_TYPE_LIST).map(
    key => ({
      value: key,
      label: (productType.PRODUCT_TYPE_LIST as {[key: string]: string})[key],
    })
  );

  return (
    <Grid.Container
      type="fluid"
      style={{
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        margin: 0,
        padding: '1rem',
        alignItems: 'self-end',
      }}
    >
      <Grid.Item
        xs={12}
        style={{marginBottom: '1rem', flexDirection: 'column'}}
      >
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TITLE',
          })}
        </Heading>
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomSelectWithSearch
          dataTestId="product-type"
          isProductType
          name={`promotions.${promotionIndex}.productType`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_TYPE.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_TYPE.HINT',
          })}
          addManuallyBtnText={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_TYPE.ADD_MANUALLY_BUTTON',
          })}
          value={values.promotions[promotionIndex].productType}
          optionsList={productTypeOptions}
          onChange={(value: any) => {
            setFieldTouched(`promotions.${promotionIndex}.productType`, true);
            setFieldValue(`promotions.${promotionIndex}.productType`, value);
          }}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomSelectWithSearch
          dataTestId="product-brand"
          isCdpBrandName
          name={`promotions.${promotionIndex}.cdpBrandName`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_BRAND.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_BRAND.HINT',
          })}
          addManuallyBtnText={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_BRAND.ADD_MANUALLY_BUTTON',
          })}
          value={values.promotions[promotionIndex].cdpBrandName}
          optionsList={Object.keys(BrandNameTypes).map(key => ({
            value: key,
            label: (BrandNameTypes as {[key: string]: string})[key],
          }))}
          onChange={(value: any) => {
            setFieldTouched(`promotions.${promotionIndex}.cdpBrandName`, true);
            setFieldValue(`promotions.${promotionIndex}.cdpBrandName`, value);
          }}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomInput
          data-testid="product-name"
          name={`promotions.${promotionIndex}.productName`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_NAME.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_NAME.HINT',
          })}
          maxLength={30}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CustomNumberInput
          data-testid="total-units"
          name={`promotions.${promotionIndex}.totalUnits`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TOTAL_UNITS.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TOTAL_UNITS.HINT',
          })}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CurrencyInput
          testId="price-per-unit"
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRICE_PER_UNIT.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRICE_PER_UNIT.HINT',
          })}
          currency={{
            locale: 'en-US',
            currency: 'USD',
            prefix: {
              actual: '$',
              preferred: '$',
            },
          }}
          value={values.promotions[promotionIndex].pricePerUnit}
          onChange={value => {
            setFieldTouched(`promotions.${promotionIndex}.pricePerUnit`, true);
            setFieldValue(`promotions.${promotionIndex}.pricePerUnit`, value);
          }}
          hasError={
            !!(touched.promotions as any)?.[promotionIndex]?.pricePerUnit &&
            !!(errors.promotions as any)?.[promotionIndex]?.pricePerUnit
          }
          errorText={
            (errors.promotions as any)?.[promotionIndex]?.pricePerUnit as string
          }
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CustomNumberInput
          data-testid="volume-per-unit"
          name={`promotions.${promotionIndex}.mlVolumePerUnit`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.VOLUME_PER_UNIT.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.VOLUME_PER_UNIT.HINT',
          })}
          decimal
        />
      </Grid.Item>
    </Grid.Container>
  );
};

export default ProductSettings;
