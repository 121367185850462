import { FileStatus } from '../interfaces';
import { useNotification } from './useNotification';

export interface IRecommendation {
  id: string;
  status: string;
  referenceName: string;
  description: string;
  total: number;
  validated: number;
  failed: number;
  processed: number;
  previousStatus: string;
  failedCount: number;
}

export const useNotificationMassApproval = () => {
  const { notify: notifyBase } = useNotification();

  const notify = (recommendation: IRecommendation) => {
    if (!recommendation?.status || recommendation?.status === recommendation?.previousStatus)
      return;

    const { status, failedCount, total, processed } = recommendation;

    const createNotification = (
      type: 'info' | 'success' | 'warning' | 'error',
      message: string,
      attributes?: Record<string, any>,
      firstAction?: any
    ) => {
      notifyBase({
        type,
        message,
        attributes,
        firstAction,
      });
    };

    switch (status) {
      case FileStatus.DONE:
        if (failedCount > 0) {
          createNotification('warning', 'massApproval.toasts.issueMessage', {
            value: processed,
            total: total,
            issue: failedCount,
          });
        } else {
          createNotification('success', 'massApproval.toasts.recommendationSuccess', {
            value: total,
          });
        }
        break;

      case FileStatus.FAILED:
        createNotification('error', 'massApproval.toasts.errorApproved', { value: total });
        break;

      default:
        console.warn('Unknown file status:', status);
    }
  };

  return { notify };
};
