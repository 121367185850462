import { useState } from 'react';
import RankingPrizeHeaders from '../../json/columnHeaders/RankingPrizeHeaders.json';
import mockData from '../../json/mock/mockingRankingPrizeData.json';
import handleFilter from '../../campaignDetails/extra/filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';


export default function RankingForPrize() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  /* const [importRankingStatus, setImportRankingStatus] = useState(false); */


  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['position', 'player_name', 'player_poc', 'player_id', 'difference', 'points'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  return (
    <>
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        {/* {importRankingStatus && !loading && (
          <StyledAlert
            message={'You already have an import running. Wait for it to finish and try again!'}
            type={'warning'}
            css={{ width: '100%', marginBottom: 20 }}
          />
        )} */}
        {/* <ExportStatus bundle={''} campaignId={params?.id} /> */}
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
      </FlexContainer>
      <StyledTable
        columns={RankingPrizeHeaders}
        data={filteredData || []}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
    </>
  );
}
