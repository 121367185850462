import { usePreferredLanguage } from 'admin-portal-shared-services';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import LoadingModal from './components/loadingModal/LoadingModal';
import { updateIntlProvider } from './i18n/formatters';
import useStyles from './Router.styles';
import SideBarWrapper from './SideBarWrapper';
import { setIsCheckingDownloads } from './stores/downloadsList/DownloadsListEvents';
import { setIsCheckingUploads } from './stores/uploadsList/UploadsListEvents';

const PromotionPage = lazy(() => import('./pages/promotionRoute/PromotionPage'));
const CreatePromotionRoute = lazy(
  () => import('./pages/createPromotionRoute/CreatePromotionRoute')
);
const ComboDetailsRoute = lazy(() => import('./pages/promotionDetailsRoute/PromotionDetailsRoute'));
const BulkEditorPage = lazy(() => import('./pages/bulkEditor/BulkEditorPage'));

const Routes = (): JSX.Element => {
  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    if (pathname !== '/promotions/combos/new') {
      sessionStorage.clear();
    }
    if (pathname !== '/promotions/bulkEditor') {
      setIsCheckingUploads(false);
      setIsCheckingDownloads(false);
    }
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/promotions" exact component={PromotionPage} />
      <Route path="/promotions/combos/new" component={CreatePromotionRoute} />
      <Route path="/promotions/combos/:id" component={ComboDetailsRoute} />
      {/* <Route path="/promotions/bulkEditor/:tab" component={BulkEditorPage} /> */}
    </Switch>
  );
};

const Router = (): JSX.Element => {
  const { containerBody, content } = useStyles();
  const language = usePreferredLanguage();
  const [currentLanguage, setCurrentLanguage] = React.useState('');
  React.useEffect(() => {
    if (currentLanguage !== language) {
      updateIntlProvider(language);
      setCurrentLanguage(language);
    }
  }, [language]);

  return (
    <BrowserRouter>
      <div className={containerBody}>
        {/* <SideBarWrapper language={currentLanguage} /> */}
        <Suspense fallback={<LoadingModal />}>
          <div className={content}>
            <Routes />
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
