import { useNavigate } from 'react-router-dom';
import { FileStatus } from '../interfaces';
import { format } from '../utils/formatting';
import { useNotification } from './useNotification';

export interface File {
  id: string;
  status: string;
  referenceName: string;
  description: string;
  total: number;
  totalAnalysis: number;
  validated: number;
  failed: number;
  processed: number;
  analysisPercentage: string;
  processedPercentage: string;
  previousStatus: string;
}

const { truncateText } = format;
const maxLength = 20;

export const useFileNotification = () => {
  const { notify } = useNotification();
  const navigate = useNavigate();

  const notifyFileStatus = (file: File) => {
    if (!file?.status || file?.status === file?.previousStatus) return;

    const { status, previousStatus, referenceName, id, validated, failed, processed } = file;
    const processing = previousStatus === FileStatus.PROCESSING;

    const redirectToFile = () => {
      navigate(`/bees-credit-management/upload/progress/${id}/${referenceName}`);
    };

    switch (status) {
      case FileStatus.ANALYZING:
        notify({
          type: 'info',
          message: 'notifications.analysis.info',
          attributes: { fileName: truncateText(referenceName, maxLength) },
        });
        break;

      case FileStatus.PROCESSING:
        notify({
          type: 'info',
          message: 'notifications.update.info',
          attributes: { pocAmount: validated },
        });
        break;

      case FileStatus.DONE:
        notify({
          type: 'success',
          message: 'notifications.update.success',
          attributes: { pocAmount: processed, fileName: truncateText(referenceName, maxLength) },
        });
        break;

      case FileStatus.ANALYZED:
        if (failed) {
          notify({
            type: 'warning',
            message: 'notifications.analysis.warning',
            attributes: { fileName: truncateText(referenceName, maxLength) },
            firstAction: { name: 'notifications.firstAction', action: redirectToFile },
          });
        } else {
          notify({
            type: 'success',
            message: 'notifications.analysis.success',
            attributes: { fileName: truncateText(referenceName, maxLength) },
            firstAction: { name: 'notifications.firstAction', action: redirectToFile },
          });
        }
        break;

      case FileStatus.FAILED:
        if (processing) {
          notify({
            type: 'error',
            message: 'notifications.update.failed',
            attributes: { fileName: truncateText(referenceName, maxLength) },
            firstAction: { name: 'notifications.firstAction', action: redirectToFile },
          });
        } else {
          notify({
            type: 'error',
            message: 'notifications.analysis.failed',
            attributes: { fileName: truncateText(referenceName, maxLength) },
            firstAction: { name: 'notifications.firstAction', action: redirectToFile },
          });
        }
        break;

      default:
        console.warn('Unknown file status:', status);
    }
  };

  return { notifyFileStatus };
};
