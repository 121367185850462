// Status Codes
const CODE_200 = 200;
const CODE_201 = 201;
const CODE_204 = 204;
const CODE_403 = 403;
const CODE_404 = 404;

export const HTTP_STATUS_CODE = {
  CODE_200,
  CODE_201,
  CODE_204,
  CODE_403,
  CODE_404,
};

export const SUCCESS_CODE = [CODE_200, CODE_201, CODE_204];
export const ERROR_CODE = [CODE_403, CODE_404];
