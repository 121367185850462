import { currencyCode } from '@utils';
import { FormatCurrencyProps } from './formatCurrency.types';

function formatSouthAfricaCurrency(value: string): string {
  return value.replace(/,/g, '\u00A0').replace('.', ',');
}

export const formatCurrency = ({
  country,
  value,
  customLocale,
  customCurrency,
}: FormatCurrencyProps): string => {
  const fractionDigits = Number.isInteger(value) ? 0 : 2;

  const locale = customLocale || currencyCode[country].locale;
  const currency = customCurrency || currencyCode[country].currency;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  const formattedValue = formatter.format(value);

  return country === 'ZA' || customLocale === 'en-ZA'
    ? formatSouthAfricaCurrency(formattedValue)
    : formattedValue;
};
