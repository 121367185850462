import { Card, Grid, Select } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import SearchDropdown from '../../../components/SearchDropdown/SearchDropdown';
import StyledSelect from '../../../components/Select/Select';
import StyledButton from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import StyledAlert from '../../../components/Alert/Alert';
import PageTitle from '../../../components/PageTitle/PageTitle';
import StyledRadio from '../../../components/Radio/StyledRadio';
import { GameSettingsContext } from '../../../contexts/gameSettings.context';

const StyledPaddding = styled('div', {
  paddingBottom: '0.5rem',
});

const GenerateInstantPrize: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [selectedType, setSelectedType] = useState('TYPE');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [radioValue, setRadioValue] = useState('match');
  const [searchDropdownValue, setSearchDropdownValue] = useState('');
  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);

  const items = [{ name: 'Item 1' }, { name: 'Item 2' }, { name: 'Item 3' }];
  const tournamentSelected = gameSettingsState.tournamentSelected;

  const search = (searchTerm) => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  const handleSearch = (searchTerm, type) => {
    const dataList = tournamentSelected[type] || [];
    const filteredData = dataList.filter((item) => item.name.includes(searchTerm));
    return Promise.resolve(filteredData);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const successButton = () => {
    setSuccess(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle marginBottom="1.2rem" title={`Do you want to generate instant prize?`} hint='Generate instant prizes by match or group.' />
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1.5rem' }}>
          <Card elevated="medium" border="medium" css={{ width: '100%'}}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '1.5rem 0 1rem 0',
              }}
            >
              {success && (
                <StyledAlert
                  css={{ width: '100%' }}
                  message="*Saved the instant prize."
                  type="success"
                />
              )}

              <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    rowGap: '1rem'
                  }}
                >

                    <SearchDropdown
                      width=""
                      label="Campaign"
                      hint="The current campaign."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />

                   <StyledPaddding>
                    <SearchDropdown
                      width=""
                      label="Tournament"
                      hint="The tournament the matches and groups will be loaded."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />
                  </StyledPaddding>

                    <StyledRadio
                      label="Generate Instant Prizes By"
                      hint="How the instant prizes will be generated?"
                      defaultValue="match"
                      options={[
                        { id: 'match', label: 'Match', value: 'match' },
                        { id: 'group', label: 'Group', value: 'group' },
                      ]}
                      onChange={(e) => {
                        setRadioValue(e);
                        setSearchDropdownValue('');
                      }}
                    />

                  {radioValue === 'match' ? (
                    <StyledPaddding>
                      <SearchDropdown
                        onSearch={(term) => handleSearch(term, 'matches')}
                        onChange={(e) => setSearchDropdownValue(e.currentTarget.value)}
                        value={searchDropdownValue}
                        required
                        size="large"
                        label="Match*"
                        hint="The instant prize match entity."
                        placeholder="Start typing to search"
                      />
                    </StyledPaddding>
                  ) : (
                    <StyledPaddding>
                      <SearchDropdown
                        onSearch={(term) => handleSearch(term, 'groups')}
                        onChange={(e) => setSearchDropdownValue(e.currentTarget.value)}
                        value={searchDropdownValue}
                        required
                        size="large"
                        label="Group*"
                        hint="The tournament's group in which the matches of the instant prizes will go."
                        placeholder="Start typing to search"
                      />
                    </StyledPaddding>
                  )}
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Prize*"
                      width={''}
                      hint="The instant prize entity."
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Quantity*"
                      width={''}
                      hint="The prize quantity to distribution."
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledSelect
                      width={''}
                      placeholder="- Select -"
                      label="Release method*"
                      hint="The release method."
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      <Select.Option value="- Select -">- Select -</Select.Option>
                    </StyledSelect>
                  </StyledPaddding>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }} >
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                  marginBottom:'0',
                }}
              >
                <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton isLoading={loading} variant="primary" onClick={() => successButton()}>
                  Confirm
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
    </>
  );
};

export default GenerateInstantPrize;
