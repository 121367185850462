import { Grid, IconButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const ButtonBackNavigation = styled(IconButton, {
  marginRight: '$2',
});

const AppHeaderWrapper = styled(Grid.Container, {
  rowGap: '$4',
  padding: '$4 0 $4 $2',
  variants: {
    isVisible: {
      false: {
        display: 'none',
      },
      true: {
        display: 'inital',
      },
    },
  },
});

const ButtonsWrapper = styled('div', {
  marginLeft: 'auto',
  display: 'flex',
  gap: '$4',
});

const StyledLink = styled('a', {
  cursor: 'pointer',
});

export { 
  AppHeaderWrapper, 
  ButtonBackNavigation, 
  StyledLink,
  ButtonsWrapper,
};
