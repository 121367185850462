import Api, { Microservices } from 'Api/Api';
import { FeatureActivationData, FeatureActivationItemsKeys } from 'domains/FeatureActivation';
import { pick } from 'lodash';
import { APIResponse } from 'types/APIResponse.interface';

const getFeatureActivationData = async (): Promise<APIResponse<FeatureActivationData>> => {
  const response: APIResponse<FeatureActivationData> = await Api.get({
    microservice: Microservices.flexdeliveryBFFExternal,
    url: `delivery-window/api/v2/Vendor`,
  });

  return response;
};

const updateFeatureActivationData = async (
  featureActivationData: FeatureActivationData
): Promise<APIResponse<FeatureActivationData>> => {
  const body = pick(featureActivationData, FeatureActivationItemsKeys);

  const response: APIResponse<FeatureActivationData> = await Api.put({
    microservice: Microservices.flexdeliveryBFFExternal,
    url: `delivery-window/api/v2/Vendor`,
    body,
  });

  return response;
};

export default {
  getFeatureActivationData,
  updateFeatureActivationData,
};
