import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
    operationsPanel: 'OPERATIONS PANEL COMING SOON!!!!',
  },
  title: {
    pageError: 'Something went wrong',
  },
  OperationsPanel: {
    title: 'Operation panel',
    clear: 'Clear',
    errorBDR: 'BDR ID not found. Review it and try again.',
    exampleBDR: 'Example: BR00004981324',
    newSearch: 'New search',
    country: 'Country',
    vendor: 'Vendor',
    vendorSelectPlaceholder: 'Select a vendor',
    countrySelectPlaceholder: 'Select a country',
    searchType: 'Search by',
    date: 'Date',
    optional: 'optional',
    search: 'Search',
  },
  searchBar: {
    jsonViwer: 'Search by Keyword',
  },
  message: {
    loading: 'Loading...',
    toast: {
      success: {
        jsonViewResult: '{total} results found',
      },
      error: {
        searchNotFound: 'Something went wrong. Try searching for another ID.',
        jsonViewResult: 'No results found',
      },
    },
    error: {
      default: 'Something went wrong',
      noVendor: 'Your user has no vendor or country available.',
    },
  },
  button: {
    tryAgain: 'Try Again',
    newSearch: 'Start new search',
  },
  appHeader: {
    operationPanel: 'Operation panel',
    bdrId: 'BDR ID: {bdrId}',
    visitDetails: 'Visit ID: {visitId}',
    newSearchBtn: 'Start new search',
  },
  searchResults: {
    searchResult: 'Search results',
    bdrDetails: 'BDR details',
    accounts: 'Accounts',
    visits: 'Visits',
    task: 'Tasks',
  },
  visitDetails: {
    pageTitle: 'Visit',
    details: 'Visit details',
    tasks: 'Visit tasks',
  },
  tableColumns: {
    search: {
      accounts: 'Search by account ID or name',
      visit: 'Search by visit or account ID',
      task: 'Search tasks',
    },
    accountId: 'Account ID',
    name: 'Name',
    status: 'Status',
    taskId: 'Task ID',
    visitId: 'Visit ID',
    visits: 'Visits',
    description: 'Description',
    pagination: {
      of: 'of',
    },
    StatusFlag: {
      open: 'Open',
      pending: 'Pending',
      cancelled: 'Cancelled',
      not_completed: 'Not completed',
      closed: 'Closed',
      justified_in_route_finish: 'Justified',
      active: 'Active',
      blocked: 'Blocked',
      inactive: 'Inactive',
    },
  },
  bdrDetails: {
    bdrId: 'BDR ID',
    country: 'Country',
    ddc: 'Distribution Center ID',
    supervisor: 'Supervisor ID',
    sectorId: 'Sector ID',
    seniority: 'Seniority',
    thirdParty: 'Third party BDR',
    profile: 'Profile',
    yes: 'Yes',
    no: 'No',
    notAvailable: 'Not available',
    notFound: 'BDR not found',
  },
  sidebar: {
    taskManagement: 'Task management',
    operationPanel: 'Operation panel',
    taskGroups: 'Task groups',
  },
  screenDateEmpty: {
    descriptionNoSelectedDate: 'Select a date to start consulting BDR {type}.',
    descriptionNoData: 'No {type} found in this date. Select another one.',
    accountEmpty: 'No accounts found.',
  },
  jsonViwer: {
    accountId: 'Account ID:',
    showVisit: 'Show visit tasks',
  },
};
export default enUS;
