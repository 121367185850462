import { Row, RowSelectionState, SortingState, Table } from '@tanstack/react-table';
import { AssessmentsItem, DateType, GenericObject, MoveTypes, Tables, TextType } from '../..';

/**
 * @namespace Tables
 * @interface Selectable
 */

export type RowType = Row<AssessmentsItem | GenericObject>;
export type TableType = Table<AssessmentsItem | GenericObject>;

export interface OnSelectedFn {
  value: boolean;
  data: RowType | TableType;
}

export type SelectedData<T> = {
  mode?: 'single' | 'multiple';
  checked: boolean;
  rows: T[];
};

export interface SelectedRows<T> {
  mode?: 'single' | 'multiple';
  rows: T[];
}

/**
 * @namespace Tables
 * @interface Filter
 */

export type FilterType = 'range-value' | 'range-date' | 'checkbox';
export type InputType = 'text' | 'checkbox';
export type InputFormat = 'text' | 'currency' | 'percentage' | 'days';
export type AffixType = 'text' | 'currency' | 'percentage';

interface BaseAffixProps {
  prefix: boolean;
  sufix: boolean;
  label?: string;
}

interface AffixTextProps extends Required<BaseAffixProps> {
  type: 'text';
}

interface AffixNonTextProps extends BaseAffixProps {
  type: 'currency' | 'percentage';
}

type AffixProps = AffixTextProps | AffixNonTextProps;

export interface BaseInputProps {
  format: InputFormat;
  identifier: string;
  label: string;
  placeholder?: string;
  affix?: AffixProps;
  value?: string;
}

export interface NonCheckboxInputProps extends BaseInputProps {
  type: Exclude<InputType, 'checkbox'>;
}

export interface CheckboxInputProps extends BaseInputProps {
  type: 'checkbox';
  value: string;
}

export type InputProps = NonCheckboxInputProps | CheckboxInputProps;

/**
 * @namespace Tables
 * @interface Columns
 */

type SortingFnType =
  | 'alphanumeric'
  | 'alphanumericCaseSensitive'
  | 'text'
  | 'textCaseSensitive'
  | 'datetime'
  | 'basic';

interface SortingProps {
  has: boolean;
  fn?: SortingFnType | ((rowA: any, rowB: any, columnId: string) => number);
}

interface ColumnSizing {
  size: number;
  minSize: number;
}

export interface ColumnProps {
  accessor: string;
  sorting: SortingProps;
  sizing?: ColumnSizing;
  permissions?: string[];
  render: (value: any) => JSX.Element;
}

export interface FilterProps {
  type: FilterType;
  identifier: string;
  name: string;
  title: string;
  input: InputProps[];
}

/**
 * @namespace Tables
 * @interface CustomColumns
 */

interface TextParams {
  value: any;
  sizing: { size: number };
}

export interface TextDateParams extends TextParams {
  type: DateType;
}

export type TextProps<T extends TextType> = (T extends 'datetime'
  ? { type: T; params: TextDateParams }
  : { type: T; params: TextParams }) & { hasEllipsis?: boolean };

/**
 * @namespace Tables
 * @interface Actions
 */

export enum ActionsTypes {
  PENDING_RECOMMENDED = 'PENDING_RECOMMENDED',
  PENDING_NOT_RECOMMENDED = 'PENDING_NOT_RECOMMENDED',
  FILES = 'FILES',
}

/**
 * @namespace Tables
 * @interface Table
 */

export interface TableData {
  [Tables.PENDING]: AssessmentsItem[];
  [Tables.INPROGRESS]: AssessmentsItem[];
  [Tables.COMPLETED]: AssessmentsItem[];
  [Tables.PROCESSING]: AssessmentsItem[];
  [Tables.ACTIVATE_CREDIT]: AssessmentsItem[];
  [Tables.BLOCKED_CREDIT]: AssessmentsItem[];
  [Tables.CREDIT_CANCELED]: AssessmentsItem[];
  [Tables.FILES]: GenericObject[];
}

export interface DataType {
  [Tables.PENDING]: AssessmentsItem;
  [Tables.INPROGRESS]: AssessmentsItem;
  [Tables.COMPLETED]: AssessmentsItem;
  [Tables.PROCESSING]: AssessmentsItem;
  [Tables.ACTIVATE_CREDIT]: AssessmentsItem;
  [Tables.BLOCKED_CREDIT]: AssessmentsItem;
  [Tables.CREDIT_CANCELED]: AssessmentsItem;
  [Tables.FILES]: GenericObject;
}

interface EmptyMessageProps {
  message: string;
  Icon?: React.ForwardRefExoticComponent<any>;
}

export interface TableMessages {
  empty: EmptyMessageProps;
  loading: string;
}

export interface TableSearch {
  has: boolean;
  width?: string | number;
  placeholder: string;
  value: string;
  onChange: (value: string) => Promise<void>;
}

export interface TablePagination {
  page: number;
  size: number;
  total?: number;
  onChange?: (page: number, size: number) => void;
}

type FilterFnType = (params: string) => Promise<void>;

export interface TableFilters {
  has: boolean;
  chips: boolean;
  config: FilterProps[];
  onFilter: FilterFnType;
  onClear: (page: number, size?: number) => void;
}

export interface TableViews {
  has: boolean;
  table: Tables;
  onClear?: () => void;
}

export interface MoveFn {
  from: MoveTypes;
  to: MoveTypes;
}

export interface TableSelectable<T = any> {
  has: boolean;
  mode: string;
  state: RowSelectionState;
  counter: number;
  identifier: string;
  onSelected: (data?: SelectedData<T>) => void;
  onClear?: (state: RowSelectionState) => void;
}

export interface TableSorting {
  has: boolean;
  state: SortingState;
  onSorting: (props: SortingState) => void;
}

export interface TableProps<T extends Tables> {
  table: T;
  data: TableData[T];
  columns: ColumnProps[];
  loading: boolean;
  messages: TableMessages;
  search?: TableSearch;
  filters?: TableFilters;
  views?: TableViews;
  sorting?: TableSorting;
  pagination?: TablePagination;
  selectable?: TableSelectable;
  toolbarExtra?: React.FC<any>;
  tableHeight?: string;
  onRow?: (row: DataType[T]) => void;
}

export interface UseSelectableProps {
  selectable: Partial<TableSelectable>;
}
