import { useCallback, useMemo } from 'react';
import { completed, files, inprogress, keys, pending } from '../constants';
import {
  AssessmentTag,
  GetColumnsProps,
  GetFiltersProps,
  Tables,
  TablesMapping,
  UseGetTables,
} from '../interfaces';
import { useViews } from '../store/hooks/customViewsHook';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useGetTables = (table: Tables): UseGetTables => {
  const { active } = useViews(table);
  const { permissions, configs, toggles } = useGetUserPreferences();
  const { tables } = configs;

  const recommended = useMemo(() => {
    if (!toggles.has(keys.cm_has_not_recommended)) return null;

    return table === Tables.PENDING ? String(active.tag === AssessmentTag.RECOMMENDED) : null;
  }, [table, active.tag, toggles]);

  const tablesMapping: TablesMapping = {
    [Tables.PENDING]: pending,
    [Tables.INPROGRESS]: inprogress,
    [Tables.COMPLETED]: completed,
    [Tables.FILES]: files,
    [Tables.PROCESSING]: {
      columns: [],
      filters: [],
    },
    [Tables.ACTIVATE_CREDIT]: {
      columns: [],
      filters: [],
    },
    [Tables.BLOCKED_CREDIT]: {
      columns: [],
      filters: [],
    },
    [Tables.CREDIT_CANCELED]: {
      columns: [],
      filters: [],
    },
  };

  const getColumns = useCallback(
    ({ columns, value }: GetColumnsProps) => {
      return columns
        .filter(({ accessor }) => value.includes(accessor))
        .filter(({ permissions: userPermissions }) => {
          if (!userPermissions) {
            return true;
          }

          return userPermissions.some((permission) => permissions.has(permission));
        });
    },
    [active.name, table]
  );

  const getFilters = useCallback(
    ({ filters, value }: GetFiltersProps) => {
      const opFilters = tables[table].filters[active.tag];

      return filters.filter(
        ({ identifier }) => opFilters.includes(identifier) && value.includes(identifier)
      );
    },
    [active.name, table]
  );

  return {
    columns: getColumns({ columns: tablesMapping[table].columns, value: active.columns }),
    filters: getFilters({ filters: tablesMapping[table].filters, value: active.columns }),
    tag: active.tag,
    recommended,
    reload: active.name,
  };
};
