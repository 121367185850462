import { Card } from '@hexa-ui/components';
import { theme } from '@hexa-ui/theme';
import styled from 'styled-components';

export const WrapperOverviewCard = styled(Card)`
  background-color: ${theme.colors.neutral0.value};
  padding: ${({ theme }) => theme.measure.space['space-4']};
  flex-basis: 300px;
`;

export const WrapperOverviewInfoContainer = styled.div`
  margin-top: ${({ theme }) => theme.measure.space['space-4']};
`;

export const WrapperOverviewInfo = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.measure.space['space-1']};
`;

export const WrapperOverviewInfoPercentage = styled.div`
  display: flex;
  justify-content: space-between;
`;
