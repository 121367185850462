import { useRetrieveAuditLog } from '@hooks/useAuditLog';
import { Field } from '@hooks/useAuditLog/useAuditLog.types';
import { useIntl } from 'react-intl';
import { useFormatters } from './useFormatters/useFormatters';
import {
  FormatRecentActivityDataProps,
  FormatRecentActivityDataResponse,
} from './useRecentActivity.types';

export const useRecentActivityData = ({
  entity,
  entityId,
  country,
}: FormatRecentActivityDataProps): FormatRecentActivityDataResponse => {
  const { data, isLoading, hasError, hasNextPage, fetchNextPage } = useRetrieveAuditLog({
    entity,
    entityId,
    country,
  });

  const { formatMessage } = useIntl();

  const formatters = useFormatters();

  const recentActivityItems = data
    .map((auditLog): FormatRecentActivityDataResponse['data']['0'] => {
      try {
        const action = auditLog.action.metadata.action;
        const fields: Field[] = auditLog.action.metadata.fields
          ? JSON.parse(auditLog.action.metadata?.fields)
          : [];
        const entityName = auditLog.action.metadata?.entityName;
        const entityLevel = auditLog.action.metadata?.entityLevel;
        const languageCode = auditLog.action.metadata?.language;
        const formatter = formatters[action];
        const log = formatter({
          action,
          entity,
          entityId,
          entityName,
          fields,
          entityLevel,
          languageCode,
        });
        return {
          date: auditLog.creationDate,
          requestor: {
            userName: auditLog.action.metadata.userName,
            userId: auditLog.action.metadata.userId,
          },
          moreDetailsModified:
            log.details.length > 1
              ? formatMessage(
                  { id: 'RecentActivity.MORE_DETAILS_MODIFIED' },
                  { action, quantity: log.details.length - 1 }
                )
              : undefined,
          log,
        };
      } catch (error) {
        console.error(error);
        return;
      }
    })
    .filter(Boolean);

  return {
    data: recentActivityItems,
    isLoading: isLoading || !entityId,
    hasError,
    hasNextPage,
    fetchNextPage,
  };
};
