import React from 'react';

export interface ITrackingContext {
  themeName: string | null;
  themeTrackingId: string | null;
  reportName: string | null;
  reportTrackingId: string | null;
  reportType: string | null;
  reportSubType: string | null;
  tabName: string;
  vendorId: string | null;
  vendorName: string | null;
  country: string | null;
  businessModel: string | null;
  vendorCountry?: string | null;
}

export const TrackingContext = React.createContext<ITrackingContext | null>(null);
