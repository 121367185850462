import { styled } from '@hexa-ui/theme';

export const StatusCircle = styled('div', {
  $$sizeDot: '8px',
  $$statusEnabled: '#00A838',
  $$statusPending: '#D47300',
  $$statusDisabled: '#E4423F',

  width: '$$sizeDot',
  height: '$$sizeDot !important',
  borderRadius: '$2',

  variants: {
    type: {
      ENABLED: {
        backgroundColor: '$$statusEnabled',
      },
      PENDING: {
        backgroundColor: '$$statusPending',
      },
      DISABLED: {
        backgroundColor: '$$statusDisabled',
      },
      NONE: {
        backgroundColor: '$interfaceLabelDisabled',
      },
      DELETED: {
        backgroundColor: '$interfaceLabelDisabled',
      },
    },
  },
});

export const StatusContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  textAlign: 'center',
  alignItems: 'center',
});
