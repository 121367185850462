import { Card } from '@components';
import { EditionBar } from '@components/editionBar/EditionBar';
import { handleGetPatchAttributes } from '@components/GenericAttributes';
import permissionConstants from '@config/permissionConstants';
import { useDynamicForm } from '@hooks/forms/useDynamicForm/useDynamicForm';
import { AttributeToPatch } from '@services/common.types';
import { useHasPermission } from 'admin-portal-shared-services';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface GenericAttributeFormParams {
  id: string;
  title: string;
  description: string;
  attributes: ClassAttribute[];
  isUpdating: boolean;
  isLoading: boolean;
  canEdit: boolean;
  subTitle: string;
  onSubmit: ({ attributes }: { attributes: AttributeToPatch[] }) => Promise<void>;
  onEditCallback?: () => void;
  onCancelCallback?: () => void;
  onSaveCallback?: (values: ClassAttribute[]) => void;
  onEditionStateChange?: (isEditing: boolean) => void;
}

export default function GenericAttributeForm({
  attributes,
  description,
  title,
  canEdit,
  subTitle,
  isLoading,
  isUpdating,
  id,
  onSubmit,
  onCancelCallback,
  onEditCallback,
  onSaveCallback,
  onEditionStateChange,
}: GenericAttributeFormParams): React.ReactElement {
  const [isEditing, setIsEditing] = useState(false);
  const hasAccessToEdit = useHasPermission(
    [permissionConstants.PRODUCT_WRITE, permissionConstants.ITEM_WRITE],
    'OR'
  );
  const {
    render,
    form: {
      formState: { isValid, isDirty },
      handleSubmit,
      reset,
    },
  } = useDynamicForm(isEditing, attributes);

  const onEdit = useCallback(() => {
    setIsEditing(true);
    onEditCallback?.();
  }, [onEditCallback]);

  const onSave = useCallback(async () => {
    handleSubmit(async (values) => {
      const patchAttributesBody = handleGetPatchAttributes(values.attributes);
      try {
        await onSubmit({
          attributes: patchAttributesBody,
        });
      } catch (error) {
        reset();
      }
      onSaveCallback?.(values.attributes);
      setIsEditing(false);
    })();
  }, [handleSubmit, onSubmit, reset, onSaveCallback]);

  const onCancel = useCallback(() => {
    setIsEditing(false);
    reset();
    onCancelCallback?.();
  }, [onCancelCallback, reset]);

  const isSubmitEnabled = useMemo(() => {
    return isValid && isDirty;
  }, [isValid, isDirty]);

  const shouldShowCard = useMemo(() => {
    return !!(isLoading || attributes?.length);
  }, [attributes, isLoading]);

  useEffect(() => {
    onEditionStateChange?.(isEditing);
  }, [isEditing, onEditionStateChange]);

  return (
    <>
      {shouldShowCard && (
        <Card
          title={title}
          id={id}
          border="medium"
          elevated="small"
          subTitle={subTitle}
          description={description}
          headerAction={
            <EditionBar
              isDisabled={isLoading}
              isSubmitButtonDisabled={!isSubmitEnabled}
              isUpdating={isUpdating}
              onEdit={onEdit}
              onSubmit={onSave}
              onCancel={onCancel}
              isEditing={isEditing}
              showEditIcon={canEdit && hasAccessToEdit}
            />
          }
        >
          {render(isLoading)}
        </Card>
      )}
    </>
  );
}
