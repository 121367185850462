import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import Loading from 'Components/Loading/Loading';
import { TrackingContext } from 'Context/TrackingContext';
import { MenuItem } from 'Domain/NewMenuItem';
import usePageViewed from 'Hooks/segment/usePageViewedEvent/usePageViewedEvent';
import useRenderEvents from 'Hooks/segment/useRenderEvents/useRenderEvents';
import { useSegmentCommonProps } from 'Hooks/segment/useSegmentCommonProps/useSegmentCommonProps';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useReports } from 'Hooks/useReports/useReports';
import { ReportsPageStatus } from 'Hooks/useReports/useReports.types';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useGetEmbedReport } from 'Services/GetEmbedReport/useGetEmbedReport';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { reportPowerBI, reportPowerBIWithBigNumbers, Styled } from './EmbedContent.style';
import { usePowerBi } from './usePowerBi';
import { PowerBIReportEvent, PowerBIReportStatus } from './usePowerBi.types';

type EmbedContentProps = {
  workspaceId: string;
  reportId?: string;
  permissionId?: string;
  vendorId?: string;
  country: string;
  powerBiWorkspaceId?: string;
  powerBiReportId?: string;
  hasBigNumbersSection?: boolean;
  sortedMenuItems?: MenuItem[];
};

export function EmbedContent({
  workspaceId,
  permissionId,
  reportId,
  vendorId,
  country,
  powerBiWorkspaceId,
  powerBiReportId,
  hasBigNumbersSection = false,
  sortedMenuItems,
}: Readonly<EmbedContentProps>): React.ReactElement {
  const { triggerPageViewed } = usePageViewed();
  const { preferredLanguage } = usePreferredLanguageV2();
  const { data: workspacesData } = useGetWorkspaces();
  const { reportsByTheme } = useReports();
  const { report_subtype, report_type } = useSegmentCommonProps();
  const trackingContext = useContext(TrackingContext);

  const allWorkspaces = workspacesData?.workspaces || [];

  const { triggerInitRenderingProcess } = useRenderEvents();

  const { formatMessage, locale: SELECTED_LANGUAGE } = useIntl();

  const { status: EmbedStatus } = useReports();

  const { eventHandlers, status, isLoading, trackReportEvent, setStatus } = usePowerBi();

  const lang = SELECTED_LANGUAGE.split('-')[0].toUpperCase();

  const { data, isError } = useGetEmbedReport({
    lang,
    workspaceId,
    reportId,
    permissionId,
    vendorId,
    country,
    powerBiWorkspaceId,
    powerBiReportId,
  });
  const currentPage = useCheckCurrentPage();

  /* istanbul ignore next */
  useEffect(() => {
    if (data && !hasBigNumbersSection) {
      /* istanbul ignore else */
      if (EmbedStatus === ReportsPageStatus.LOADED) {
        if (data && !hasBigNumbersSection) {
          triggerPageViewed({
            app_display_name: 'Analytics',
            app_id: 'PARTNER-REPORTS',
            is_core_event: true,
            page_label: `${report_type}-${report_subtype}`,
            page_name: 'REPORTS',
            referrer: window.document.referrer,
            workspace_count: allWorkspaces.length,
            theme_count: sortedMenuItems?.length ?? 0,
            origin_theme_label: trackingContext?.themeName ?? null,
            origin_theme_tracking_id: trackingContext?.themeTrackingId ?? null,
            origin_report_label: trackingContext?.reportName,
            origin_report_tracking_id: trackingContext?.reportTrackingId,
            report_count: reportsByTheme?.length,
            has_bt_guide: null,
            occurence_id: '1.4',
            preferred_language: preferredLanguage ?? 'en-US',
          });
          triggerInitRenderingProcess({
            is_core_event: false,
            page_name: currentPage === 'MainPage' ? 'MAIN_PAGE' : 'REPORT_PAGE',
            occurence_id: 'A1',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, EmbedStatus]);

  /* istanbul ignore next */
  useEffect(() => {
    if (reportId) {
      setStatus(PowerBIReportStatus.LOADING_POWERBI);
      trackReportEvent(PowerBIReportEvent.INIT);
    } else {
      setStatus(PowerBIReportStatus.LOADING_EMBED_REPORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  if (isError) {
    return <GenericErrorView hasBoxShadow={!hasBigNumbersSection} />;
  }

  return (
    <Styled.Container key={data?.embedUrl} hasBigNumbersSection={hasBigNumbersSection}>
      {isLoading && (
        <Loading
          title={formatMessage({ id: `EMBED_REPORT.${status}.TITLE` })}
          body={formatMessage({ id: `EMBED_REPORT.${status}.BODY` })}
          type={hasBigNumbersSection ? 'default' : 'container'}
        />
      )}

      <PowerBIEmbed
        cssClassName={
          !hasBigNumbersSection
            ? reportPowerBI(isLoading)()
            : reportPowerBIWithBigNumbers(isLoading)()
        }
        eventHandlers={eventHandlers}
        embedConfig={{
          embedUrl: data?.embedUrl,
          type: 'report',
          id: data?.reportId,
          accessToken: data?.embedToken,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.Read,
          viewMode: models.ViewMode.View,
          settings: {
            background: models.BackgroundType.Transparent,
            localeSettings: {
              language: lang,
            },
            layoutType: hasBigNumbersSection ? models.LayoutType.Master : models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            visualRenderedEvents: false,
            bookmarksPaneEnabled: false,
          },
        }}
      />
    </Styled.Container>
  );
}
