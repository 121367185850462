import { EmployeeData } from 'types/Employees';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface EmployeesSearchStore {
  employeesFilter: string;
  autocompleteEmployeesList: EmployeeData[];
  filteredEmployees: EmployeeData[];
  viewAsFilter: string;
  podFilter: string;

  setEmployeesFilter: (searchedTerm: string) => void;
  setAutocompleteEmployeesList: (list: EmployeeData[]) => void;
  setFilteredEmployees: (employees: EmployeeData[]) => void;
  setViewAsFilter: (viewAsFilter: string) => void;
  setPodFilter: (viewPodFilter: string) => void;

  clearEmployeeFilters: () => void;
  removeStorage: () => void;
}

const storageKey = '@bees-move:useSearchStoreStates';

export const useEmployeesSearchStore = create(
  persist<EmployeesSearchStore>(
    (set) => ({
      employeesFilter: null,
      autocompleteEmployeesList: [],
      filteredEmployees: [],
      viewAsFilter: '',
      podFilter: '',

      setEmployeesFilter: (searchedTerm: string) => set({ employeesFilter: searchedTerm }),
      setAutocompleteEmployeesList: (list: EmployeeData[]) =>
        set({ autocompleteEmployeesList: list }),
      setFilteredEmployees: (employees: EmployeeData[]) => set({ filteredEmployees: employees }),
      setViewAsFilter: (viewAsFilter: string) => set({ viewAsFilter }),
      setPodFilter: (podFilter: string) => set({ podFilter }),

      clearEmployeeFilters: () => {
        set((state) => ({
          ...state,
          employeesFilter: null,
          autocompleteEmployeesList: [],
          filteredEmployees: [],
        }));
      },
      removeStorage: () => {
        set(() => ({
          viewAsFilter: '',
          podFilter: '',
        }));

        localStorage.removeItem(storageKey);
      },
    }),
    {
      name: storageKey,
    }
  )
);
