import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import StyledButton from '../../../components/Button/Button';
import StyledSelect from '../../../components/Select/Select';
import StyledTextarea from '../../../components/Textarea/Textarea';

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

export default function CreateTournament() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  return (
    <Grid.Container type="fluid" style={{ margin: 0 }}>
      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '1rem' }}>
          Add Tournament
        </Heading>
      </StyledGridItem>

      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="large" css={{ width: '100%', paddingBottom: '0.5rem' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '2rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledSelect
                    width={'100%'}
                    label="Languages"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <Select.Option value="en">English</Select.Option>
                    <Select.Option value="br">Portuguese</Select.Option>
                    <Select.Option value="fr">French</Select.Option>
                    <Select.Option value="de">German</Select.Option>
                  </StyledSelect>
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Name*"
                    width={'100%'}
                    hint="The name of Tournament. Such as: FIFA Worldcup 3022 or Brazil National Soccer Tournament 3023. "
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledTextarea
                    style={{ height: '222px', resize: 'none' }}
                    label="Description"
                    width={'100%'}
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '10px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </StyledGridItem>
    </Grid.Container>
  );
}
