import { useLogService as logService } from 'admin-portal-shared-services';
import { SUCCESS_CODE, USER_ROUTES } from 'constants/routes/server';
import { SituationStatus } from 'enums/situationStatus';

import Api from '../../Api';

const log = logService();

export type UserInfoResponse = {
  isManager: boolean;
  employeeId?: number;
  isAdmin: boolean;
  situationStatus?: SituationStatus;
};

export const getUserInfo = async (): Promise<UserInfoResponse> => {
  const response = await Api.get({
    url: USER_ROUTES.getUserInfo,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-user-info-error', response);
      reject(response);
    }
  });
};
