import MessageMap from '../../i18n';

const common: MessageMap = {
  SIDEBAR: {
    COUPON_CAMPAIGN: 'Campañas de cupones',
  },
  FILTER: {
    DROPDOWN: 'Filtros',
    APPLY: 'Aplicar',
    CANCEL: 'Cancelar',
    CLEAR_ALL: 'Limpiar todo',
  },
  TABLE: {
    OF: 'de',
    LOADING: 'Cargando',
    LOADING_SEARCH: 'Cargando los resultados de la búsqueda',
    EMPTY_RESULTS: 'No se encontraron resultados',
    EMPTY_SEARCH:
      'Intentá una búsqueda diferente, utilizando otras palabras clave.',
    EMPTY_FILTER: 'Intenta usar diferentes filtros',
  },
  BUTTONS: {
    GO_BACK: 'Volver atrás',
    CANCEL: 'Cancelar',
    YES_CANCEL: 'Si, cancelar',
    CONFIRM: 'Confirmar',
    CONTINUE: 'Continuar',
    SAVE: 'Guardar',
    SELECT_FILE: 'Seleccionar archivo',
    DOWNLOAD_TEMPLATE: 'Descargar plantilla',
    NO_GO_BACK: 'No, volver',
    SELECT: 'Seleccionar',
    DELETE: 'Descartar',
    ENABLED: 'Habilitado',
    DISABLED: 'Deshabilitado',
    DISABLE: 'Deshabilitar',
    OK: 'Ok',
  },
  FORM: {
    OPTIONAL: '(Opcional)',
    REQUIRED: 'Campo obligatorio.',
  },
  ERRORS: {
    DEFAULT: 'Algo salió mal. Vuelve a intentar más tarde.',
    UPLOAD: 'Formato Erróneo',
    MAX_SIZE: 'El archivo superó los {size}MB.',
    TYPE_ERROR: 'Tipo de archivo no soportado.',
    INVALID_URL: 'Ingresa una URL válida.',
    FIELD_MIN: 'El campo debe tener al menos {min} caracteres.',
    SPECIAL_INVALID: 'No se admiten espacios ni caracteres especiales.',
    NUMBER_MIN: 'El valor debe ser mayor que {min}.',
    NUMBER_MAX: 'El valor debe ser menor o igual a {max}.',
    INVALID_FIELD: 'Campo inválido',
    MIN_DATE: 'Elija una fecha a partir del {date}.',
    MAX_DATE: 'Elija una fecha a más tardar el {date}.',
    INVALID_DATE: 'Elija una fecha válida.',
  },
  COMPLEMENTS: {
    POINTS: 'puntos',
  },
};

export default common;
