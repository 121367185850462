import MessageMap from '../i18n.d';

const idID: MessageMap = {
  ImportDialog: {
    IMPORT_BTN_TEXT: 'Impor',
    IMPORT_STRUCTURE: 'Impor struktur',
    WARNING_OVERRIDE:
      'Anda dapat mengunggah file dengan struktur lengkap, tindakan ini akan menimpa semua struktur yang ada.',
    CURRENT_STRUCTURE: 'Struktur saat ini',
    SAVE_BACKUP: 'Anda dapat menyimpan cadangan dengan struktur tim saat ini.',
    DOWNLOAD_BACKUP: 'Unduh file cadangan',
    UPLOAD_FROM_FILE: 'Perbarui dari file',
    ConfirmationModal: {
      MODAL_OVERRIDE: 'Apakah Anda yakin ingin menimpa struktur saat ini?',
      MODAL_WARNING_A: 'Struktur saat ini akan digantikan dengan yang baru.',
      MODAL_WARNING_B: 'Tindakan ini tidak dapat dibatalkan.',
      CANCEL_BTN_TEXT: 'Membatalkan',
      CONFIRM_OVERRIDE: 'Tindih',
    },
  },
  TeamStructureTitle: {
    TEAM_STRUCTURE_TITLE: 'Struktur tim',
  },
  NoStructureCardReadOnly: {
    NO_STRUCTURE_CREATED: 'Belum ada struktur yang dibuat.',
  },
  NoStructureCard: {
    NO_STRUCTURE_CREATED: 'Belum ada struktur yang dibuat!',
    CAN_UPLOAD_FILE: 'Anda dapat mengunggah file dengan struktur lengkap.',
    UPLOAD_FROM_TEMPLATE: 'Unggah file CSV dari template kami.',
    DOWNLOAD_TEMPLATE: 'Unduh template',
  },
  TemplateDownload: {
    UPLOAD_FROM_TEMPLATE: 'Unggah file CSV dari template kami.',
    DOWNLOAD_TEMPLATE: 'Unduh template',
  },
  ImportConfirmationCard: {
    CONFIRMATION_WARNING: 'Apakah Anda yakin ingin mengimpor struktur tim?',
    SUCCESS_WARNING: 'File berhasil diproses dan siap untuk diimpor.',
    CANCEL_BTN_TEXT: 'Membatalkan',
    IMPORT_BTN_TEXT: 'Impor',
  },
  DragAndDropInputFile: {
    DROP_FILE_TEXT: 'Seret file untuk mengunggah',
    UPLOAD_CSV_TEXT: 'Unggah file CSV',
    DRAG_AND_DROP_TEXT: 'atau seret dan lepas di sini',
  },
  ImportedFile: {
    POSITION_FOUND_TEXT: 'posisi ditemukan',
    POSITION_FOUND_TEXT_PLURAL: 'posisi ditemukan',
    PROCESSING_FILE: 'Memproses',
  },
  TeamStructureCard: {
    INVALID_EMAIL_TOOLTIP:
      'Kami tidak dapat menemukan email orang ini, perlu diperbaiki atau didaftarkan.',
    WHO: 'Anda',
  },
  CustomToast: {
    PROCESSING_FILE_ERROR: 'Proses gagal. Jenis file tidak valid atau template tidak lengkap.',
    SUCCESS_UPDATED: 'Struktur tim berhasil diperbarui.',
    CONNECTION_ERROR: 'Unggah gagal. Silakan periksa koneksi internet Anda dan coba unggah lagi.',
  },
  EllipsisPopover: {
    SET_SPECIALIZATION: 'Atur spesialisasi',
  },
};

export default idID;
