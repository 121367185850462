import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const HomePage = lazy(() => import('./pages/HomePage'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <Switch>
          <Route path="/" component={HomePage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
