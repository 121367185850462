import { useLogService } from 'admin-portal-shared-services';
import VendorService from 'services/Vendor/VendorService';
import { setFeatureActivationData, setLoading, unsetLoading } from 'stores/MetaData/MetaDataEvents';

async function execute(): Promise<void> {
  const log = useLogService();
  setLoading();
  try {
    const result = await VendorService.getFeatureActivationData();
    log.info('queryFeatureActivationData - get feature activation data');

    if (result.hasError) {
      throw new Error();
    }

    setFeatureActivationData(result.data);
  } catch (error) {
    log.error('queryFeatureActivationData error');
  }
  unsetLoading();
}

export default { execute };
