import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { APPLICATION_TITLE } from 'constants/';
import { useEffect, useState } from 'react';

type UseFeatureToggle = {
  featureToggle: string;
};

export function useVerifyFeatureToggle({ featureToggle }: UseFeatureToggle) {
  const [featureToggleStatus, featureToggleIsLoading] = useFeatureToggleV2(
    featureToggle,
    APPLICATION_TITLE
  );
  const [isFeatureToggleEnabled, setIsFeatureToggleEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (!featureToggleIsLoading) setIsFeatureToggleEnabled(featureToggleStatus);
  }, [featureToggleStatus, featureToggleIsLoading]);

  return {
    featureToggleStatus,
    featureToggleIsLoading,
    isFeatureToggleEnabled,
  };
}
