export const PIM_PDP_MULTILANGUAGE = 'pim-pdp-multilanguage';
export const PIM_PDP_MULTILANGUAGE_IMAGE = 'pim-pdp-multilanguage-image';
export const PIM_IDENTIFY_PDP_PROBLEMS = 'pim-identify-pdp-problems';
export const PIM_SINGLE_ZONE_MANAGEMENT = 'pim-single-zone-management';
export const PIM_ALLOW_ENABLE_WITH_PROBLEMS = 'pim-allow-enable-with-problems';
export const PIM_PDP_REDESIGN = 'pim-pdp-redesign';
export const PIM_MULTI_VENDORS = 'pim-multi-vendors';
export const PIM_PDP_DYNAMIC_ATTRIBUTES = 'pim-pdp-dynamic-attributes';
export const PIM_ENABLE_BULK_MANUFACTURER_PLP = 'pim_enable_bulk_manufacturer_plp';
export const PIM_SELECT_COUNTER = 'pim-select-counter';
