import styled from "styled-components";

export const StyledExperimentListTable = styled.div({
  width: "100%",
  tbody: {
    tr: {
      "&:nth-child(even)": {
        backgroundColor: "#fafafa",
      },
    },
    td: {
      "div[data-testid='tooltip'] ~ div": {
        wordBreak: "break-word",
        wordWrap: "break-word",
      },
    },
  },
});
