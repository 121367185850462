import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import { ElementClickedEventProps } from './useElementClicked.types';

export interface ElementClickedEventReturn {
  triggerElementClicked: (elementClickedProps: ElementClickedEventProps) => void;
}

export default function useElementClickedEvent(): ElementClickedEventReturn {
  const { elementClicked } = useSegment();
  const {
    app_display_name,
    app_id,
    origin_workspace_label,
    origin_workspace_tracking_id,
    page_url,
    user_affiliation,
    user_country,
    user_email,
    user_id,
    vendor_country,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
  } = useSegmentProperties();

  const triggerElementClicked = ({
    is_core_event = false,
    page_label = null,
    page_name = null,
    element_location_type = null,
    element_location_name = null,
    element_location_label = null,
    element_label = null,
    element_name = null,
    element_type = null,
    element_position = null,
    destiny_report_label = null,
    destiny_report_tracking_id = null,
    destiny_theme_label = null,
    destiny_theme_tracking_id = null,
    destiny_workspace_label = null,
    destiny_workspace_tracking_id = null,
    occurence_id = null,
    origin_report_label = null,
    origin_report_tracking_id = null,
    origin_theme_label = null,
    origin_theme_tracking_id = null,
  }: ElementClickedEventProps) => {
    elementClicked({
      app_display_name,
      app_id,
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      element_location_type,
      element_location_name,
      element_location_label,
      element_label,
      element_name,
      element_type,
      element_position,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id,
      destiny_workspace_label,
      destiny_workspace_tracking_id,
      destiny_theme_label,
      destiny_theme_tracking_id,
      destiny_report_label,
      destiny_report_tracking_id,
      occurence_id,
    });
  };

  return {
    triggerElementClicked,
  };
}
