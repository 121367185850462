import { UploadableFile } from '@hexa-ui/components';
import { NavigateFunction } from 'react-router-dom';
import {
  FilesConfig,
  ProgressProps,
  ServiceApi,
  Toggles,
  UploadedProps,
  UseToast,
} from '../../interfaces';

/**
 * @namespace Types
 */

type RefType<T> = React.MutableRefObject<T>;

export interface MessageProps {
  message: string;
  attributes?: Record<string, string | number>;
}

export interface EventQuery {
  retry: { value: RefType<number>; should: RefType<boolean> };
  timeout: { slow: NodeJS.Timeout; retry: NodeJS.Timeout };
}

/**
 * @namespace Contexts
 */

export interface UploadMachineInput extends UseToast {
  user: { name: string };
  api?: ServiceApi;
  toggles?: Toggles;
  filesConfig?: FilesConfig;
  file?: { id: string; name: string };
  navigate?: NavigateFunction;
}

export interface FileProps {
  value: File;
  size: string;
  name: string;
  description: string;
}

export interface FileErrorProps extends MessageProps {
  has: boolean;
}

export interface StartProps {
  upload: boolean;
  processing: boolean;
}

export interface AnalysisProps {
  failed: boolean;
}

export interface LoadingProps {
  upload: boolean;
  processing: boolean;
}

export interface UploadMachineContext {
  slow: boolean;
  file: FileProps;
  files: UploadableFile[];
  input: UploadMachineInput;
  query?: EventQuery;
  error: FileErrorProps;
  start: StartProps;
  loading: LoadingProps;
  uploaded: UploadedProps;
  progress: ProgressProps;
  analysis: AnalysisProps;
}

/**
 * @namespace Events
 */

export type UploadMachineEvents =
  | { type: 'BACK'; to: string }
  | { type: 'RETRY'; to: string }
  | { type: 'SLOW' }
  | { type: 'FILE_SELECTED'; files: UploadableFile[] }
  | { type: 'CLEAR' }
  | { type: 'ADD_DESCRIPTION'; description: string }
  | { type: 'VALIDATION' }
  | { type: 'UPLOAD_FILE' }
  | { type: 'EVALUATE_FILE'; query: EventQuery }
  | { type: 'DOWNLOAD_FAILED' }
  | { type: 'APPLY_UPDATE' };

/**
 * @namespace Delays
 */

export interface UploadMachineDelays {
  DELAY_1000: number;
}

/**
 * @namespace Machine
 */

export interface MachineProps {
  context: UploadMachineContext;
  event: UploadMachineEvents;
}

export const types = {
  context: {} as UploadMachineContext,
  input: {} as UploadMachineInput,
  events: {} as UploadMachineEvents,
  delays: {} as UploadMachineDelays,
};

export interface UploadContextProps {
  send: (event: UploadMachineEvents) => void;
  slow: boolean;
  file: FileProps;
  files: UploadableFile[];
  start: StartProps;
  loading: LoadingProps;
  error: FileErrorProps;
  uploaded: UploadedProps;
  analysis: AnalysisProps;
  progress: ProgressProps;
}
