import { formatCurrency } from '@utils';
import { getCurrencyByLocale } from '../../components/NumberDisplay';
import { abbreviateNumber } from '../../utils/formmattedNumbers';
import { getCurrencySymbol } from '../../utils/getCurrency';
import { TFormatCommon, TFormatCurrency } from './formattedAchievement.types';

export const BILLION = 1000000000;
export const MILLION = 1000000;

export type TFormattedAchievement = {
  currentProgress: number;
  measure: string;
  measureDescription?: string;
  country?: string;
  currency?: string;
  currencyLocale?: string;
  precision?: number;
  initialNumber?: 'thousand' | 'billion' | 'million';
  text?: boolean;
};

const VALID_INITIAL_NUMBERS = ['thousand', 'billion', 'million'];

export const validatePrecisionAndInitialNumber = (
  precision: number,
  initialNumber: string
): boolean => {
  const isPrecisionValid = precision >= 0;
  const isInitialNumberValid = VALID_INITIAL_NUMBERS.includes(initialNumber);
  return isPrecisionValid && isInitialNumberValid;
};

const formattNumberAccordingLocale = (currentProgress: number, locale: string) => {
  const hasDecimals = !Number.isInteger(currentProgress);
  const precision = hasDecimals ? 2 : 0;
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(currentProgress);
};

export const formattedAchievement = ({
  currentProgress,
  measure,
  measureDescription = '',
  country = 'US',
  currency = 'USD',
  currencyLocale = 'en-US',
}: TFormattedAchievement) => {
  const numberFormattedAccordinLocale = formattNumberAccordingLocale(
    currentProgress,
    currencyLocale
  );
  switch (measure) {
    case '$':
      return formatCurrency({
        value: currentProgress,
        country,
        customCurrency: currency,
        customLocale: currencyLocale,
      });

    case 'Vol.':
    case '#':
      if (country === 'ZA') {
        return `${numberFormattedAccordinLocale
          .replace(/,/g, ' ')
          .replace('.', ',')} ${measureDescription}`;
      } else {
        return `${numberFormattedAccordinLocale} ${measureDescription}`;
      }

    case '%':
      if (country === 'ZA') {
        return `${numberFormattedAccordinLocale.replace(/,/g, ' ').replace('.', ',')}%`;
      } else {
        return `${numberFormattedAccordinLocale}%`;
      }

    default:
      return `${numberFormattedAccordinLocale} ${measureDescription}`;
  }
};

const formatValue = ({
  currentProgress,
  formattedNumber,
  country,
  currency,
  currencyLocale,
  isInitialNumberMillionAndProgressLessThanMillion,
  isInitialNumberBillionAndProgressLessThanBillion,
}: TFormatCurrency) => {
  if (
    isInitialNumberMillionAndProgressLessThanMillion ||
    isInitialNumberBillionAndProgressLessThanBillion
  ) {
    return formatCurrency({
      value: currentProgress,
      country,
      customCurrency: currency,
      customLocale: currencyLocale,
    });
  } else {
    const currencySymbol = currencyLocale
      ? getCurrencySymbol(currency, currencyLocale)
      : getCurrencyByLocale(country);
    return `${currencySymbol} ${formattedNumber.abbreviatedNumber}${formattedNumber.suffix}`;
  }
};

const formatVolume = ({
  currentProgress,
  formattedNumber,
  measureDescription,
  isInitialNumberMillionAndProgressLessThanMillion,
  isInitialNumberBillionAndProgressLessThanBillion,
  locale,
}: TFormatCommon) => {
  const numberFormattedAccordinLocale = formattNumberAccordingLocale(currentProgress, locale);
  if (
    isInitialNumberMillionAndProgressLessThanMillion ||
    isInitialNumberBillionAndProgressLessThanBillion
  ) {
    if (locale === 'en-ZA') {
      return `${numberFormattedAccordinLocale
        .replace(/,/g, ' ')
        .replace('.', ',')} ${measureDescription}`;
    } else {
      return `${numberFormattedAccordinLocale} ${measureDescription}`;
    }
  } else {
    return `${formattedNumber.abbreviatedNumber}${formattedNumber.suffix} ${measureDescription}`;
  }
};

const formatCase = (currentProgress: number, locale: string) => {
  const numberFormattedAccordinLocale = formattNumberAccordingLocale(currentProgress, locale);
  if (locale === 'en-ZA') {
    return numberFormattedAccordinLocale.replace(/,/g, ' ').replace('.', ',');
  } else {
    return numberFormattedAccordinLocale;
  }
};

const formatNumber = ({
  currentProgress,
  formattedNumber,
  measureDescription,
  isInitialNumberMillionAndProgressLessThanMillion,
  isInitialNumberBillionAndProgressLessThanBillion,
  locale,
}: TFormatCommon) => {
  const numberFormattedAccordinLocale = formattNumberAccordingLocale(currentProgress, locale);
  if (
    isInitialNumberMillionAndProgressLessThanMillion ||
    isInitialNumberBillionAndProgressLessThanBillion
  ) {
    if (locale === 'en-ZA') {
      return `${numberFormattedAccordinLocale
        .replace(/,/g, ' ')
        .replace('.', ',')} ${measureDescription}`;
    } else {
      return `${numberFormattedAccordinLocale} ${measureDescription}`;
    }
  } else {
    return `${formattedNumber.abbreviatedNumber}${formattedNumber.suffix} ${measureDescription}`;
  }
};

const formatPercentage = ({
  currentProgress,
  formattedNumber,
  measureDescription,
  isInitialNumberMillionAndProgressLessThanMillion,
  isInitialNumberBillionAndProgressLessThanBillion,
  locale,
}: TFormatCommon) => {
  const numberFormattedAccordinLocale = formattNumberAccordingLocale(currentProgress, locale);
  if (
    isInitialNumberMillionAndProgressLessThanMillion ||
    isInitialNumberBillionAndProgressLessThanBillion
  ) {
    if (locale === 'en-ZA') {
      return `${numberFormattedAccordinLocale
        .replace(/,/g, ' ')
        .replace('.', ',')}${measureDescription}`;
    } else {
      return `${numberFormattedAccordinLocale}${measureDescription}`;
    }
  }
  return `${formattedNumber.abbreviatedNumber}${formattedNumber.suffix}${
    formattedNumber.suffix ? ' ' : ''
  }${measureDescription}`;
};

export const abbreviatedAchievement = ({
  currentProgress,
  measure,
  measureDescription = '',
  country = 'US',
  currency = 'USD',
  currencyLocale = 'en-US',
  precision = 1,
  initialNumber = 'thousand',
}: TFormattedAchievement) => {
  if (!validatePrecisionAndInitialNumber(precision, initialNumber)) {
    throw new Error('Precision or InitialNumber are invalid');
  }

  const formattedNumber = abbreviateNumber({
    value: currentProgress,
    precision,
    initialNumber:
      initialNumber === 'thousand' ? 1000 : initialNumber === 'billion' ? BILLION : MILLION,
    country,
  });

  const isInitialNumberBillionAndProgressLessThanBillion =
    initialNumber === 'billion' && currentProgress < BILLION;
  const isInitialNumberMillionAndProgressLessThanMillion =
    initialNumber === 'million' && currentProgress < MILLION;

  const numberFormattedAccordinLocale = formattNumberAccordingLocale(
    currentProgress,
    currencyLocale
  );

  switch (measure) {
    case '$':
      return formatValue({
        currentProgress,
        formattedNumber,
        country,
        currency,
        currencyLocale,
        isInitialNumberMillionAndProgressLessThanMillion,
        isInitialNumberBillionAndProgressLessThanBillion,
      });
    case 'Vol.':
      return formatVolume({
        currentProgress,
        formattedNumber,
        measureDescription,
        isInitialNumberMillionAndProgressLessThanMillion,
        isInitialNumberBillionAndProgressLessThanBillion,
        locale: currencyLocale,
      });
    case 'Case':
      return formatCase(currentProgress, currencyLocale);
    case '#':
      return formatNumber({
        currentProgress,
        formattedNumber,
        measureDescription,
        isInitialNumberMillionAndProgressLessThanMillion,
        isInitialNumberBillionAndProgressLessThanBillion,
        locale: currencyLocale,
      });
    case '%':
      return formatPercentage({
        currentProgress,
        formattedNumber,
        measureDescription: '%',
        isInitialNumberMillionAndProgressLessThanMillion,
        isInitialNumberBillionAndProgressLessThanBillion,
        locale: currencyLocale,
      });
    default:
      if (isInitialNumberMillionAndProgressLessThanMillion) {
        if (country === 'ZA') {
          return `${numberFormattedAccordinLocale.replace(/,/g, ' ').replace('.', ',')}`;
        } else {
          return `${numberFormattedAccordinLocale}`;
        }
      }
      return `${formattedNumber.abbreviatedNumber}${formattedNumber.suffix}`;
  }
};
