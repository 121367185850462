import {useApi} from '../../api';

export default async function addPocsService(
  uploadId: string,
  request: any
): Promise<any> {
  return await useApi().post({
    url: `/v1/admin/campaign-uploads/${uploadId}/eligibility`,
    config: {cancelToken: request.token},
  });
}
