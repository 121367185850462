export enum FeatureActivationItems {
  regularDeliveryEnabled = 'regularDeliveryEnabled',
  flexDeliveryEnabled = 'flexDeliveryEnabled',
  clickCollectEnabled = 'clickCollectEnabled',
}

export const FeatureActivationItemsKeys: FeatureActivationItems[] = [
  FeatureActivationItems.clickCollectEnabled,
  FeatureActivationItems.flexDeliveryEnabled,
  FeatureActivationItems.regularDeliveryEnabled,
];

export interface FeatureActivationData {
  [FeatureActivationItems.regularDeliveryEnabled]: boolean;
  [FeatureActivationItems.flexDeliveryEnabled]: boolean;
  [FeatureActivationItems.clickCollectEnabled]: boolean;
}
