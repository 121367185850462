import {PocCampaignsSummary} from '@Models';
import {useApi} from '../../api';

export default async function getCampaignsAndPointsService(
  pocCode: string
): Promise<PocCampaignsSummary[] | any> {
  const status = 'BLOCKED';

  return await useApi().get({
    url: `/v1/admin/loyalty-program/${pocCode}/coupons/summary?status=${status}`,
  });
}
