export interface IAuth {
  roles?: UserRoles[];
  verifyRole: (roleToVerify: UserRoles | UserRoles[]) => boolean;
  isUserActive?: boolean;
  isUserInfoFTEnabled?: boolean;
}

export default interface IUserMetadata {
  avatarUrl: string | undefined;
  userId: string | undefined;
  acceptedTerms?: string[];
  accounts?: string[];
  supportedCountries?: string;
  profile?: {
    preferredLanguage?: string;
    preferredTimeFormat?: string;
    timezone?: string;
  };
  authorization?: {
    groups?: string[];
    scopes?: string;
  };
  deliveryCenterIds?: string[];
  routeId?: string;
  vendors?: Vendor[];
}
export interface Vendor {
  id: string | undefined;
  country: string | undefined;
  displayName: string | undefined;
  serviceModel: string | undefined;
  governmentId: string | undefined;
  abiVendorId: string | undefined;
}

export enum UserRoles {
  LINE_MANAGER = 'LINE_MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  ADMIN = 'ADMIN',
}
