import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import handleFilter from "../../campaignDetails/extra/filter";
import { Card, Heading, IconButton, Modal, Paragraph } from "@hexa-ui/components";
import { Edit2, Trash2 } from "@hexa-ui/icons";
import FlexContainer from "../../../components/FlexContainer";
import StyledButton from "../../../components/Button/Button";
import PageTitle from "../../../components/PageTitle/PageTitle";
import StyledFilter from "../../../components/Filter/Filter";
import StyledTable from "../../../components/Table/Table";
import LeaguesHeaders from "../../json/columnHeaders/FootballLeaguesHeaders.json"
import mockData from "../../json/mock/FootballleaguesMock.json"

const TournamentFootballLeagues: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['id', 'league_id', 'league_name', 'type', 'logo', 'country', 'actions'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const InsertActions = (data) => {
    return data.map((item) => {
      return {
        ...item,
        logo: <img src={item.logo.url} width={50} height={50} alt="Football Leagues Logo"/>,
        actions: (
          <div style={{ display: 'inline-flex', gap: '1rem' }}>
            <>
              <IconButton
                icon={Edit2}
                variant="inherit"
                onClick={() => {
                  navigate(`/bees-games/tournament/football-leagues/edit-football-leagues`);
                }}
              />
              <Modal.Root
                actions={
                  <FlexContainer
                    width="100%"
                    display="inline-flex"
                    justifyContent="flex-end"
                    gap="0.5rem"
                  >
                    <Modal.Action>
                      <StyledButton variant="secondary">Cancel</StyledButton>
                    </Modal.Action>
                    <Modal.Action>
                      <StyledButton variant="destructive" onClick={() => ''}>
                        Delete
                      </StyledButton>
                    </Modal.Action>
                  </FlexContainer>
                }
                trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
                variant="overlay"
                destructive="critical"
              >
                <Heading type="H3" css={{ marginBottom: '0.5rem' }}>
                  Delete football leagues?
                </Heading>
                <Paragraph>{"Do you really wish to delete this football leagues?"}</Paragraph>
                <Paragraph>{"This action can't be undone."}</Paragraph>
              </Modal.Root>
            </>
          </div>
        )
      };
    });
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Football Leagues" />
      <Card elevated={'medium'} border={'large'}>
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e) => handleSearch(e)}
              onClear={(e) => handleSearch(e)}
            />
          </div>
        </FlexContainer>
        <StyledTable
          columns={LeaguesHeaders}
          search={false}
          data={data || []}
          loading={loading}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            showPageSizeSelector: true,
          }}
        />
      </Card>
    </>
  );
};

export default TournamentFootballLeagues
