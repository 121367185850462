import MessageMap from '../../i18n';

const productType: MessageMap = {
  PRODUCT_TYPE_LIST: {
    BEER: 'Cerveza',
    WATER: 'Agua',
    NON_ALCOHOLIC: 'No Alcohólico',
    NON_ALCOHOLIC_BEER: 'Cerveza No Alcohólica',
    BEATS_AND_ICE: 'Bebidas y Hielo',
    FOOD: 'Comida',
    OTHERS: 'Otros',
  },
};

export default productType;
