const urlBuilder = (
  url: string,
  paramMap: {
    [key: string]: string | undefined;
  }
): string => {
  const params = Object.entries(paramMap);
  let newUrl = url;

  if (params?.length > 0) {
    params?.forEach(([key, value]) => {
      if (value) {
        newUrl = newUrl.replace(`{${key}}`, value).replace(`:${key}`, value);
      }
    });
  }

  return newUrl;
};

export default urlBuilder;
