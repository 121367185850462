import { Tabs } from '@hexa-ui/components';
import { GLOBAL_OVERVIEW } from 'Assets/Constants';
import { EmbedReport } from 'Components/EmbedReport/EmbedReport';
import useDefaultTab from 'Hooks/useDefaultTab/useDefaultTab';
import useReportTabs from 'Hooks/useReportTabs/useReportTabs';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React from 'react';
import { useIntl } from 'react-intl';
import { isGlobalPartner, isMultiVendor } from '../../../../domain/User';
import ContentReportsTabsView from './ContentReportsTabs/ContentReportsTabsView';
import ReportTab from './ReportTab/ReportTab';
import { TabsContainer } from './ReportsTabComponent.style';

function ReportsTabComponent(): React.ReactElement | null {
  const { locale: selectedLocale, defaultLocale } = useIntl();
  const locale = selectedLocale || defaultLocale;

  const { selectedVendor } = LoggedUserStore.getState();
  const { vendorId, selectedCountry } = useSelectedVendor();

  const selectedTab = useSelectedTab();
  const defaultTab = useDefaultTab();
  const reportTabs = useReportTabs();

  return (
    <>
      {reportTabs.length > 1 && (
        <TabsContainer>
          <Tabs.Root
            id="tabs"
            data-testid="tabs-container"
            className="tabs"
            defaultValue={defaultTab.slug}
            value={selectedTab.slug}
          >
            <Tabs.List>
              {reportTabs.map((tab) => (
                <ReportTab reportTab={tab} key={tab.reportCode} />
              ))}
            </Tabs.List>
          </Tabs.Root>
        </TabsContainer>
      )}

      <ContentReportsTabsView />
      <EmbedReport
        vendorId={
          (isMultiVendor() && selectedVendor.id !== GLOBAL_OVERVIEW) || isGlobalPartner()
            ? selectedVendor.id
            : (vendorId as string)
        }
        lang={locale}
        reportCode={selectedTab.reportCode}
        globalOverview={selectedTab.reportCode === GLOBAL_OVERVIEW}
        country={selectedCountry}
      />
    </>
  );
}

export default ReportsTabComponent;
