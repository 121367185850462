import { fromPromise } from 'xstate';
import { UploadStatus } from '../../../interfaces';
import { applyUpdate, downloadFailedFile, getProgress, uploadFile } from '../../../services';
import { Actors } from './upload.actors.types';

export const actors: Actors = {
  'upload-file': fromPromise(async ({ input }) => {
    const { user, params, post } = input;
    const { file } = params;

    const response = await uploadFile({
      api: { post },
      params: {
        userId: user.name,
        file: file.value,
        fileName: file.name,
        fileDescription: file.description,
      },
    });

    if (!response?.success) {
      const is412 = response?.data?.request?.status === 412;
      const message = is412 ? 'files.errors.412.description' : 'pages.upload.buttons.toasts.error';

      return Promise.reject(message);
    }

    return Promise.resolve({ fileId: response.data?.id });
  }),
  'get-progress': fromPromise(async ({ input }) => {
    const { fileId, query, get } = input;
    const { timeout, retry } = query;

    const response = await getProgress({
      api: { get },
      params: { fileId },
    });

    if (!response?.success && retry.should.current) return;
    clearTimeout(timeout.retry);

    const status = response?.data?.status;
    const finished = status === UploadStatus.ANALYZED || status === UploadStatus.DONE;

    if (!response?.success || status === UploadStatus.FAILED) {
      clearTimeout(timeout.slow);

      return Promise.reject(response.data);
    }

    if (finished) clearTimeout(timeout.slow);

    retry.value.current = 0;
    return Promise.resolve({ progress: response.data });
  }),
  'apply-update': fromPromise(async ({ input }) => {
    const { fileId, post } = input;

    const response = await applyUpdate({
      api: { post },
      params: { fileId },
    });

    if (!response?.success) {
      return Promise.reject('pages.upload.buttons.toasts.error');
    }
  }),
  'download-failed': fromPromise(async ({ input }) => {
    const { fileId, filesConfig, get } = input;

    const response = await downloadFailedFile({
      api: { get },
      params: { fileId, filesConfig },
    });

    if (!response?.success) {
      return Promise.reject('errors.generic');
    }
  }),
};
