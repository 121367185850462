import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import StyledButton from '../../../components/Button/Button';
import StyledFileUploader from '../../../components/FileUploader/FileUploader';
import { base_url } from '../../../utils/constants';
import { AlertContext } from '../../../contexts/alert.context';
import verifyImgType from '../../../utils/verifyImgType';

export default function EditTournamentParticipant() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const StyledPaddding = styled('div', {
    paddingBottom: '1rem',
  });

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const sizeValidation = (file) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
    }
  };

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Edit Tournament Participant
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Name*"
                    width={'30vw'}
                    hint="The name of tournament participant."
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>

                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Acronym*"
                    width={'30vw'}
                    hint="Inform short the name. Like BR for Brazil or PRY for Paraguay. Two or three letters can be used"
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledFileUploader
                  title="Add a new file*"
                  url={base_url + ''}
                  accept=".png,.jpg,.jpeg,.gif"
                  maxFileSize={1000000}
                  message="Can be flags, team badge or icon.
                One file only.
                1MB limit.
                Allowed types: png gif jpg jpeg."
                  width="30vw"
                  onDrop={(e) => {
                    verifiedFile(e);
                  }}
                />
              </Grid.Container>
            </Grid.Item>
            <Grid.Container>
              <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div
                  style={{
                    width: '100%',
                    display: 'inline-flex',
                    justifyContent: 'flex-start',
                    gap: '0.5rem',
                    padding: '10px',
                    marginBottom: '1rem',
                  }}
                >
                  <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                    Save
                  </StyledButton>
                  <StyledButton
                    variant="secondary"
                    isLoading={loading}
                    onClick={() => navigate(`/bees-games/tournament/tournament-participant/`)}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  gap: '0.5rem',
                  padding: '10px',
                  marginBottom: '0.5rem',
                }}
              >
                <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Grid.Item>
    </>
  );
}
