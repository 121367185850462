import { Paragraph } from '@hexa-ui/components';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Container,
  ContentSection,
  PreviewSection,
  ToggleVisibilityButton,
} from './QuickPreview.styles';

export interface QuickPreviewProps {
  buttonText: string;
  contentText: string;
  children?: React.ReactNode;
  shouldHide?: boolean;
  closedBehavior?: 'hide' | 'preview';
  marginTop?: CSSProperties['marginTop'];
}

export const QuickPreview = ({
  children,
  shouldHide,
  buttonText,
  contentText,
  closedBehavior = 'hide',
  marginTop,
}: QuickPreviewProps): React.ReactElement => {
  const { $t } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const observeResizeChanges = (element: HTMLElement) => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setContentHeight(entry.contentRect.height);
        }
      }
    });
    resizeObserver.observe(element);
    return resizeObserver;
  };

  useEffect(() => {
    if (shouldHide) {
      setIsExpanded(false);
    }

    const contentElement = contentRef.current as HTMLElement;
    if (!contentElement) return;

    const resizeObserver = observeResizeChanges(contentElement);

    return () => {
      resizeObserver.unobserve(contentElement);
      resizeObserver.disconnect();
    };
  }, [shouldHide, contentText]);

  return (
    <Container data-testid="quick-preview-container">
      {children}
      {!shouldHide && contentText && (
        <PreviewSection css={{ marginTop }}>
          <ContentSection
            css={{
              $$contentHeight: `${contentHeight}px`,
              $$initialHeight: contentHeight > 32 ? '32px' : '16px',
            }}
            expanded={isExpanded}
            preview={closedBehavior === 'preview' && !isExpanded}
          >
            <Paragraph
              data-testid="quick-preview-content-text"
              colortype="secondary"
              size="small"
              ref={contentRef}
            >
              {contentText}
            </Paragraph>
          </ContentSection>
          <ToggleVisibilityButton
            data-testid="quick-preview-toggle-visibility-button"
            type="button"
            size="small"
            onClick={handleExpand}
          >
            {isExpanded ? $t({ id: 'QuickPreviewComponent.HIDE' }) : buttonText}
          </ToggleVisibilityButton>
        </PreviewSection>
      )}
    </Container>
  );
};
