import React from 'react';

export const CreditRecommended = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="credit-recommended-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99327 2.84804C7.93551 2.3706 7.51284 2 7 2C6.44771 2 6 2.42981 6 2.96V4.56H5.33333L5.15044 4.56474C3.39455 4.65588 2 6.0516 2 7.76C2 9.52731 3.49238 10.96 5.33333 10.96H6V13.52H3L2.88338 13.5265C2.38604 13.5819 2 13.9877 2 14.48C2 15.0102 2.44771 15.44 3 15.44H6V17.04L6.00673 17.152C6.06449 17.6294 6.48716 18 7 18C7.55228 18 8 17.5702 8 17.04V15.44H8.18002C8.32774 15.0191 8.56637 14.6242 8.89591 14.287C9.67092 13.4941 10.7583 13.2156 11.7528 13.4515C11.9122 13.0778 12 12.6688 12 12.24C12 10.4727 10.5076 9.04 8.66667 9.04H8V6.48H10.3333L10.45 6.47354C10.9473 6.41809 11.3333 6.01232 11.3333 5.52C11.3333 4.98981 10.8856 4.56 10.3333 4.56H8V2.96L7.99327 2.84804ZM6 6.48H5.33333C4.59695 6.48 4 7.05308 4 7.76C4 8.42534 4.52879 8.97212 5.20492 9.03414L5.33333 9.04H6V6.48ZM8 10.96V13.52H8.66667C9.40305 13.52 10 12.9469 10 12.24C10 11.5747 9.47121 11.0279 8.79507 10.9659L8.66667 10.96H8Z"
        fill="#141414"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6897 12.2759C22.0896 12.6567 22.105 13.2897 21.7241 13.6897L15.0575 20.6897C14.8687 20.8878 14.607 21 14.3333 21C14.0597 21 13.7979 20.8878 13.6092 20.6897L10.2759 17.1897C9.89498 16.7897 9.91041 16.1567 10.3103 15.7759C10.7103 15.395 11.3433 15.4104 11.7241 15.8103L14.3333 18.55L20.2759 12.3103C20.6567 11.9104 21.2897 11.895 21.6897 12.2759Z"
        fill="#141414"
      />
    </svg>
  )
);
