import { ACTION, Field } from '@hooks/useAuditLog/useAuditLog.types';
import { useLanguageContext } from '@providers/LanguageProvider/LanguageProvider';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { MAX_STRING_LENGTH } from '../constants';
import { ActionProps, RecentActivityLog } from '../useRecentActivity.types';
import { LanguageUtils } from '@utils/language/languageUtils';
import { useMemo } from 'react';

const abbreviateString = (str: string): string => {
  if (str.length <= MAX_STRING_LENGTH) {
    return str;
  }
  return str.slice(0, MAX_STRING_LENGTH) + '...';
};

const getFieldsDetails = ({
  fields = [],
  setMessage,
}: {
  fields?: Field[];
  setMessage: (params: { field: Field; previous: string; current: string }) => string;
}) => {
  const initialDetails = {
    details: [] as React.ReactNode[],
    shortDetails: [] as React.ReactNode[],
  };
  return fields.reduce((all, field) => {
    const previous = String(field.previous ?? '');
    const current = String(field.current ?? '');

    if (Object.keys(field).length) {
      all.details.push(setMessage({ field, previous, current }));
      all.shortDetails.push(
        setMessage({
          field,
          previous: abbreviateString(previous),
          current: abbreviateString(current),
        })
      );
    }

    return all;
  }, initialDetails);
};

const formatCreateLog = ({ formatMessage, entity, fields }: ActionProps): RecentActivityLog => {
  const title = formatMessage({ id: 'RecentActivity.ENTITY_CREATE_TITLE' }, { entity });
  return {
    title,
    ...getFieldsDetails({
      fields,
      setMessage: ({ current }) =>
        formatMessage(
          { id: 'RecentActivity.ENTITY_CREATE_DESCRIPTION' },
          { entity, value: current }
        ),
    }),
  };
};

const formatUpdateInformationLog = ({
  formatMessage,
  entity,
  fields,
  action,
  showLanguage,
  registryLanguage,
}: ActionProps): RecentActivityLog => {
  let title = formatMessage(
    { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
    { entity, field: undefined, language: registryLanguage, showLanguage }
  );

  if (fields?.length === 1) {
    title = formatMessage(
      { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
      { entity, field: fields[0].label, language: registryLanguage, showLanguage }
    );
  }

  if (action === 'UPDATE_ATTRIBUTES') {
    title = formatMessage(
      { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
      { entity, field: 'attributes', language: registryLanguage, showLanguage }
    );
  }

  return {
    title,
    ...getFieldsDetails({
      fields,
      setMessage: ({ field, previous, current }) =>
        formatMessage(
          { id: 'RecentActivity.ENTITY_UPDATE_FIELD_DESCRIPTION' },
          {
            entity,
            field: field.label,
            previous,
            current,
          }
        ),
    }),
  };
};

const formatUpdateImageLog = ({
  formatMessage,
  entity,
  registryLanguage,
  showLanguage,
}: ActionProps): RecentActivityLog => {
  const title = formatMessage(
    { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
    { entity, field: 'image', language: registryLanguage, showLanguage }
  );
  const details = [
    formatMessage(
      { id: 'RecentActivity.ENTITY_UPDATE_FIELD_DESCRIPTION' },
      { entity, field: 'image' }
    ),
  ];

  return {
    title,
    details,
  };
};

const formatRemoveImageLog = ({
  formatMessage,
  entity,
  registryLanguage,
  showLanguage,
}: ActionProps): RecentActivityLog => {
  const title = formatMessage(
    { id: 'RecentActivity.ENTITY_REMOVE_IMAGE_TITLE' },
    { entity, language: registryLanguage, showLanguage }
  );
  const details = [
    formatMessage({ id: 'RecentActivity.ENTITY_REMOVE_IMAGE_DESCRIPTION' }, { entity }),
  ];
  return {
    title,
    details,
  };
};

const formatAssignmentLog = ({
  formatMessage,
  action,
  entityName,
  fields,
}: ActionProps): RecentActivityLog => {
  const title = formatMessage({ id: 'RecentActivity.ENTITY_ASSIGNMENT_TITLE' }, { action });

  return {
    title,
    ...getFieldsDetails({
      fields,
      setMessage: ({ field }) =>
        formatMessage(
          { id: 'RecentActivity.ENTITY_ASSIGNMENT_DESCRIPTION' },
          { action, entityName, subEntityName: field.label }
        ),
    }),
  };
};

const formatReorderLog = ({
  formatMessage,
  action,
  entityLevel,
  fields,
}: ActionProps): RecentActivityLog => {
  const title = formatMessage(
    { id: 'RecentActivity.ENTITY_REORDER_TITLE' },
    { action, entityLevel }
  );

  return {
    title,
    ...getFieldsDetails({
      fields,
      setMessage: ({ field, previous, current }) =>
        formatMessage(
          { id: 'RecentActivity.ENTITY_REORDER_DESCRIPTION' },
          { action, subEntityName: field.label, previous, current }
        ),
    }),
  };
};

const formatUpdateStatus = ({ formatMessage, fields, entity }: ActionProps): RecentActivityLog => {
  const title = formatMessage(
    { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
    { entity, field: 'status' }
  );

  return {
    title,
    ...getFieldsDetails({
      fields,
      setMessage: ({ field, previous, current }) =>
        formatMessage(
          { id: 'RecentActivity.ENTITY_UPDATE_FIELD_DESCRIPTION' },
          { entity, field: 'status', subEntityName: field.label, previous, current }
        ),
    }),
  };
};

export const useFormatters = (): Record<
  ACTION,
  (props: Omit<ActionProps, 'formatMessage'>) => RecentActivityLog
> => {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();
  const { availableLanguages } = useLanguageContext();
  const languageUtils = useMemo(() => {
    return preferredLanguage ? new LanguageUtils(preferredLanguage) : undefined;
  }, [preferredLanguage]);

  const formatWithLanguageProps = (props: Omit<ActionProps, 'formatMessage'>): ActionProps => {
    const isMultiLanguage = availableLanguages.length > 1;
    const { languageCode } = props;
    const languageName = languageCode
      ? languageUtils.getFormattedLanguageNameByLangCode(languageCode)
      : undefined;
    const showLanguage = languageName && isMultiLanguage;
    return {
      formatMessage,
      ...props,
      showLanguage,
      registryLanguage: languageName,
    };
  };

  return {
    CREATE: (props) => formatCreateLog({ formatMessage, ...props }),
    UPDATE_INFORMATION: (props) => formatUpdateInformationLog(formatWithLanguageProps(props)),
    UPDATE_ATTRIBUTES: (props) => formatUpdateInformationLog(formatWithLanguageProps(props)),
    UPDATE_IMAGE: (props) => formatUpdateImageLog(formatWithLanguageProps(props)),
    REMOVE_IMAGE: (props) => formatRemoveImageLog({ formatMessage, ...props }),
    ASSIGN_PRODUCT: (props) => formatAssignmentLog({ formatMessage, ...props }),
    UNASSIGN_PRODUCT: (props) => formatAssignmentLog({ formatMessage, ...props }),
    ASSIGN_CATEGORY: (props) => formatAssignmentLog({ formatMessage, ...props }),
    UNASSIGN_CATEGORY: (props) => formatAssignmentLog({ formatMessage, ...props }),
    REORDER_PRODUCT: (props) => formatReorderLog({ formatMessage, ...props }),
    REORDER_CATEGORY: (props) => formatReorderLog({ formatMessage, ...props }),
    REORDER_SUB_CATEGORY: (props) => formatReorderLog({ formatMessage, ...props }),
    UPDATE_CATEGORY_STATUS: (props) => formatUpdateStatus({ formatMessage, ...props }),
  };
};
