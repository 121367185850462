import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  header {
    z-index: 1 !important ;
  }
  
  span div h1 h2 h3 h4 h5 p {
    color: #141414;
  }

  div[id^="single-spa-application:@admin-portal/main-mfe"] {
    z-index: 350;
    position: relative;
  }


  * {
    font-family: Work Sans;
    scrollbar-color: #dfdede #fff;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #aaa;
    }

    ::-webkit-scrollbar-track:hover {
      background-color: #f2f2f2;
    }

    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }
  

  @media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
}
`;

export default GlobalStyle;
