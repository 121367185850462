import { Carousel } from '@hexa-ui/components';
import CardSection from 'Components/CardSection/CardSection';
import { ChangeWorkspaceModal } from 'Components/ChangeWorkspaceModal/ChangeWorkspaceModal';
import { WorkspaceCard } from 'Components/WorkspaceCard/WorkspaceCard';
import MFERoutes from 'Config/MFERoutes';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { useScreenSize } from 'Hooks/useScreenSize/useScreenSize';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { hideSpaces } from 'Utils/hideSpaces/hideSpaces';
import { moveItemToFirstPosition } from 'Utils/sortWorkspaces/sortWorkspaces';
import { Styled } from '../MainPage.style';
import { WorkspaceSectionProps } from './WorkspaceSection.types';

const WORKSPACES_TO_SHOW = 8;

export function WorkspaceSection({
  selectedWorkspace,
  allWorkspaces,
}: Readonly<WorkspaceSectionProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });

  const [isOpen, setIsOpen] = useState(false);

  const { setTempWorkspaceId } = useWorkspaceContext();

  const [isMobile, isTablet, isDesktopSmall] = useScreenSize(['mobile', 'tablet', 'desktopSmall']);

  const getCarouselSize = () => {
    if (isMobile) return 'xl';
    if (isTablet) return 'lg';
    if (isDesktopSmall) return 'md';
    return 'sm';
  };

  const sortedWorkspaces = moveItemToFirstPosition(selectedWorkspace, allWorkspaces);

  const handleToggleModal = (id?: string) => {
    if (id) {
      setTempWorkspaceId(id);
    }

    setIsOpen(!isOpen);
  };

  const filteredWorkspaces = sortedWorkspaces?.slice(0, WORKSPACES_TO_SHOW);

  const shouldHideSpaces = hideSpaces({
    isMobile,
    isTablet,
    isDesktopSmall,
    data: filteredWorkspaces,
    hasBtGuide: false,
  });

  return (
    <>
      <ChangeWorkspaceModal isOpen={isOpen} onClose={handleToggleModal} />

      <CardSection
        title={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.TITLE' })}
        subtitle={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.DESCRIPTION' })}
        hasRedirect={allWorkspaces.length > WORKSPACES_TO_SHOW}
        redirectText={formatMessage({ id: 'MAIN_PAGE.WORKSPACE_SECTION.BUTTON' })}
        pagePath={`${MFERoutes.WORKSPACES_LIST}`}
        key={`${isMobile}-${isTablet}-${isDesktopSmall}`}
      >
        {filteredWorkspaces?.length > 0 && (
          <Styled.ContainerCarousel hideSpaces={shouldHideSpaces}>
            <Carousel.Root
              showArrows
              size={getCarouselSize()}
              swipeable={isTablet}
              autoHideIndicators
            >
              {filteredWorkspaces.map((workspace, index) => (
                <WorkspaceCard
                  key={workspace.id}
                  title={workspace.title[SELECTED_LANGUAGE]}
                  enTitle={workspace.title['en-US']}
                  trackingId={workspace.trackingId}
                  segments={workspace.permissions.length}
                  isSelected={selectedWorkspace === workspace.id}
                  onClick={/* istanbul ignore next */ () => handleToggleModal(workspace.id)}
                  index={index}
                />
              ))}
            </Carousel.Root>
          </Styled.ContainerCarousel>
        )}
      </CardSection>
    </>
  );
}
