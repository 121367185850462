import trackingService from '@hooks/useTrackingService/useTrackingService';

export interface ElementClickedParams {
  element_name: string;
  element_label: string;
  store_name: string;
  store_id: string;
  view_level: string;
  element_location_type: string;
  element_location_name: string;
  store_country: string;
  element_type: string;
  page_label: string;
  page_name: string;
}

export const elementClickedEvent = ({
  page_label,
  page_name,
  element_label,
  element_name,
  store_name,
  store_id,
  view_level,
  element_location_type,
  element_location_name,
  store_country,
  element_type,
}: ElementClickedParams) => {
  const analytics = trackingService().getAnalytics();
  analytics.elementClicked({
    app_display_name: 'catalog-admin',
    app_id: null,
    is_core_event: false,
    manufacturer_country: null,
    manufacturer_display_name: null,
    manufacturer_id: null,
    manufacturer_service_model: null,
    manufacturer_tier_name: null,
    page_label,
    page_name,
    page_url: window.location.pathname,
    vendor_country: null,
    vendor_display_name: null,
    vendor_id: null,
    vendor_service_model: null,
    vendor_tier_name: null,
    store_id,
    store_country,
    store_name,
    view_level,
    element_location_type,
    element_name,
    element_label,
    element_location_name,
    element_type,
    user_permission_group: null,
  });
};
