import { Tables } from '../../interfaces';
import * as completed from './completed';
import * as files from './files';
import * as inprogress from './inprogress';
import * as pending from './pending';

export { completed, files, inprogress, pending };

export const rowHeight = 64;

export const excludeAssessments: Tables[] = [Tables.FILES];

export const status = {
  pending: {
    status: {
      '#0F7FFA:#0F7FFA:formatting.recommendation.NEW_CREDIT_OFFER': ['NEW_CREDIT_OFFER'],
      '#D9912B:#D9912B:formatting.recommendation.DECREASE': ['DECREASE'],
      '#2AA147:#2AA147:formatting.recommendation.INCREASE': ['INCREASE'],
    },
  },
  inprogress: {
    status: {
      '#0F7FFA:#0F7FFA:tables.inprogress.status.processing': [
        'WAITING_FOR_ACTIVATION',
        'APPROVED_BY_BU',
      ],
      '#D9B62B:#D9B62B:tables.inprogress.status.integration': ['WAITING_ONBOARDING'],
    },
  },
  completed: {
    status: {
      '#107418:#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:#14141451:tables.completed.status.denied': [
        'DENIED_BY_BU',
        'DENIED_BY_ONBOARDING',
      ],
    },
  },
  processing: {
    status: {
      '#107418:#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:#14141451:tables.completed.status.denied': [
        'DENIED_BY_BU',
        'DENIED_BY_ONBOARDING',
      ],
    },
  },
  activate_credit: {
    status: {
      '#107418:#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:#14141451:tables.completed.status.denied': [
        'DENIED_BY_BU',
        'DENIED_BY_ONBOARDING',
      ],
    },
  },
  credit_canceled: {
    status: {
      '#107418:#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:#14141451:tables.completed.status.denied': [
        'DENIED_BY_BU',
        'DENIED_BY_ONBOARDING',
      ],
    },
  },
  blocked_credit: {
    status: {
      '#107418:#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:#14141451:tables.completed.status.denied': [
        'DENIED_BY_BU',
        'DENIED_BY_ONBOARDING',
      ],
    },
  },
  files: {
    status: {
      '#D47300:#FFD499:tables.files.status.uploaded': ['UPLOADED'],
      '#006EDB:#A1D0FF:tables.files.status.analyzing': ['ANALYZING'],
      '#D47300:#FFD499:tables.files.status.analyzed': ['ANALYZED'],
      '#006EDB:#A1D0FF:tables.files.status.processing': ['PROCESSING'],
      '#009900:#99E699:tables.files.status.success': ['DONE'],
    },
  },
};

export const fixed: { [key in Tables]: string[] } = {
  [Tables.PENDING]: ['pocName', 'actions'],
  [Tables.INPROGRESS]: [],
  [Tables.COMPLETED]: [],
  [Tables.FILES]: [],
  [Tables.PROCESSING]: [],
  [Tables.ACTIVATE_CREDIT]: [],
  [Tables.BLOCKED_CREDIT]: [],
  [Tables.CREDIT_CANCELED]: [],
};
