import { useState } from 'react';
import RankingPrizeHeaders from '../../json/columnHeaders/RankingPrizeHeaders.json';
import mockData from '../../json/mock/mockingRankingPrizeData.json';
import handleFilter from '../../campaignDetails/extra/filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const StyledParagraph = styled(Paragraph, {
  paddingBottom:"$4"
});

export default function RankingForFun() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  /* const [importRankingStatus, setImportRankingStatus] = useState(false); */


  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['position', 'player_name', 'player_poc', 'player_id', 'difference', 'points'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  // Function to remove when we receive the api.

  const groups = ["Group A", "Group B", "Group C", "Group D", "Group E", "Group F", "Group G", "Group H"]

  const mappingGroups = () => {
    return groups.map((group, index) => (
      <Paragraph css={{display: 'initial'}} key={index} weight='medium'>
         {group} {index !== groups.length - 1 && "| "}
      </Paragraph>
    ))
  }

  return (
    <>
      <FlexContainer
        width="100%"
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        flexWrap="wrap"
      >
        {/* {importRankingStatus && !loading && (
          <StyledAlert
            message={'You already have an import running. Wait for it to finish and try again!'}
            type={'warning'}
            css={{ width: '100%', marginBottom: 20 }}
          />
        )} */}
        {/* <ExportStatus bundle={''} campaignId={params?.id} /> */}
        <StyledParagraph weight='medium'>
          Groups ranking: {mappingGroups()}
        </StyledParagraph>
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
      </FlexContainer>
      <StyledTable
        columns={RankingPrizeHeaders}
        data={filteredData || []}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
    </>
  );
}
