import { createContextProvider } from "@hexa-ui/components";

export type VendorIdFromEnv = {
  BR: string;
  MX: string;
  CO: string;
  PE: string;
  EC: string;
};

export type MetricReportsEnv = {
  general: string;
  experiment: string;
};

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  vendorId: VendorIdFromEnv;
  segmentKey: string;
  metricReports: MetricReportsEnv;
  optimizelyKey: string;
  optimizelySddKey: string;
};

export type Env = {
  env: {
    segmentKey: string;
    vendorId: VendorIdFromEnv;
    metricReports: MetricReportsEnv;
  };
};

const initialValues = {
  env: {
    segmentKey: "",
    vendorId: {
      BR: "",
      MX: "",
      CO: "",
      PE: "",
      EC: "",
    },
    metricReports: {
      general: "",
      experiment: "",
    },
  },
};

const [EnvProvider, useEnvProvider] = createContextProvider<Env>(
  "EnvProvider",
  initialValues
);

export { EnvProvider, useEnvProvider };
