import { Card } from '@hexa-ui/components';
import React, { useState } from 'react';
import StyledAlert from '../../components/Alert/Alert';
import DraggableTable from '../../components/DraggableTable/DraggableTable';
import StyledFilter from '../../components/Filter/Filter';
import FlexContainer from '../../components/FlexContainer';
import LogPageHeaders from '../json/columnHeaders/LogPageHeaders.json';
import mockData from '../json/mock/mockingLogPageData.json';
import handleFilter from '../campaignDetails/extra/filter';
import PageTitle from '../../components/PageTitle/PageTitle';

const LogPage: React.FC = () => {

  const [data, setData] = useState(mockData);
  const [savedData, setSavedData] = useState([]);

  const [modifiedData, setModifiedData] = useState(data);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [isReordered, setIsReordered] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['lid', 'message', 'severity-level', 'user', 'created'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  // Functions to change when we receive the api.
  const handleSave = () => {
    simulateAPI(modifiedData);
    setIsReordered(false);
  };

  const simulateAPI = async (data) => {
    try {
      setLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 2000));

      setSavedData(data);
      console.log('Data sent successfully:', data);
    } catch (error) {
      console.error('Error while sending data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <PageTitle
    marginBottom="1rem"
    title={`Instant prize log`}
  />
    <Card elevated={'medium'} border={'large'}>
      {isReordered && (
        <StyledAlert
          css={{ width: '100%', margin: '0.5rem 0' }}
          message="*You have unsaved changes."
          type="warning"
        />
      )}
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
      </FlexContainer>
      <DraggableTable
        columns={LogPageHeaders}
        data={data || []}
        onReorder={(newData) => {
          setIsReordered(true);
          setModifiedData(newData);
        }}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
    </Card>
    </>
  );
};

export default LogPage;
