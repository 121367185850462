import { UserDetails } from 'components/UserReportTable';

const users = [
  {
    id: '1',
    name: 'Mel Beames',
    email: 'mbeames0@domainmarket.com',
    licenseType: 'Light',
    licenseStatus: 'Inactive',
    lastUpdate: '14/10/2021',
    role: 'L2',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['CVC'],
    loginFrequence: 58,
    ticketsAssigned: 44,
    ticketsOpened: 97,
    ticketsSolved: 82,
  },
  {
    id: '2',
    name: 'Sibel Persian',
    email: 'spersian1@reverbnation.com',
    licenseType: 'Full',
    licenseStatus: 'Active',
    lastUpdate: '22/08/2021',
    role: 'Admin',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['B2B'],
    lastLogin: '2021-12-03T00:00:00.00Z',
    loginFrequence: 38,
    ticketsAssigned: 73,
    ticketsOpened: 78,
    ticketsSolved: 4,
  },
  {
    id: '3',
    name: 'Meggie Waddams',
    email: 'mwaddams2@sun.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '11/07/2021',
    role: 'Global',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['Finance'],
    lastLogin: '2021-12-26T00:00:00.00Z',
    loginFrequence: 29,
    ticketsAssigned: 7,
    ticketsOpened: 100,
    ticketsSolved: 67,
  },
  {
    id: '4',
    name: 'Thibaud Oxterby',
    email: 'toxterby3@slideshare.net',
    licenseType: 'Light',
    licenseStatus: 'Active',
    lastUpdate: '19/11/2021',
    role: 'L2',
    zone: 'BR',
    tags: ['user_AC'],
    groups: ['Global'],
    lastLogin: '2021-11-19T00:00:00.00Z',
    loginFrequence: 29,
    ticketsAssigned: 84,
    ticketsOpened: 43,
    ticketsSolved: 66,
  },
  {
    id: '5',
    name: 'Ludvig Wabersinke',
    email: 'lwabersinke4@pcworld.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '02/08/2021',
    role: 'L2',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['Sales'],
    lastLogin: '2021-12-14T00:00:00.00Z',
    loginFrequence: 96,
    ticketsAssigned: 98,
    ticketsOpened: 28,
    ticketsSolved: 44,
  },
  {
    id: '6',
    name: 'Ruttger Dennerly',
    email: 'rdennerly5@mtv.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '29/11/2021',
    role: 'Admin',
    zone: 'US',
    tags: ['user_AC'],
    groups: ['B2B'],
    lastLogin: '2021-05-14T00:00:00.00Z',
    loginFrequence: 30,
    ticketsAssigned: 79,
    ticketsOpened: 90,
    ticketsSolved: 54,
  },
  {
    id: '7',
    name: 'Demetris Playfoot',
    email: 'dplayfoot6@furl.net',
    licenseType: 'Full',
    licenseStatus: 'Active',
    lastUpdate: '23/09/2021',
    role: 'Global',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['Finance'],
    lastLogin: '2022-01-19T00:00:00.00Z',
    loginFrequence: 45,
    ticketsAssigned: 10,
    ticketsOpened: 49,
    ticketsSolved: 5,
  },
  {
    id: '8',
    name: 'Worthington Bingley',
    email: 'wbingley7@geocities.jp',
    licenseType: 'Light',
    licenseStatus: 'Active',
    lastUpdate: '21/05/2021',
    role: 'Admin',
    zone: 'CO',
    tags: ['user_AC'],
    groups: ['Sales'],
    lastLogin: '2021-07-08T00:00:00.00Z',
    loginFrequence: 84,
    ticketsAssigned: 69,
    ticketsOpened: 30,
    ticketsSolved: 51,
  },
  {
    id: '9',
    name: 'Murray Bletsoe',
    email: 'mbletsoe8@sogou.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '17/07/2021',
    role: 'Global',
    zone: 'ZA',
    tags: ['user_AC'],
    groups: ['CXC'],
    lastLogin: '2021-12-24T00:00:00.00Z',
    loginFrequence: 30,
    ticketsAssigned: 73,
    ticketsOpened: 86,
    ticketsSolved: 33,
  },
  {
    id: '10',
    name: 'Danit Beall',
    email: 'dbeall9@blogspot.com',
    licenseType: 'Full',
    licenseStatus: 'Active',
    lastUpdate: '25/08/2021',
    role: 'Admin',
    zone: 'BR',
    tags: ['user_AC'],
    groups: ['CVC'],
    lastLogin: '2021-06-28T00:00:00.00Z',
    loginFrequence: 8,
    ticketsAssigned: 21,
    ticketsOpened: 81,
    ticketsSolved: 42,
  },
  {
    id: '11',
    name: 'Wyatt Balm',
    email: 'wbalma@lycos.com',
    licenseType: 'Light',
    licenseStatus: 'Active',
    lastUpdate: '01/07/2021',
    role: 'Admin',
    zone: 'BR',
    tags: ['user_AC'],
    groups: ['CVC'],
    lastLogin: '2021-11-30T00:00:00.00Z',
    loginFrequence: 68,
    ticketsAssigned: 68,
    ticketsOpened: 75,
    ticketsSolved: 79,
  },
  {
    id: '12',
    name: 'Rebeca Kitteridge',
    email: 'rkitteridgeb@fotki.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '30/09/2021',
    role: 'L2',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['B2B'],
    lastLogin: '2021-09-10T00:00:00.00Z',
    loginFrequence: 26,
    ticketsAssigned: 96,
    ticketsOpened: 41,
    ticketsSolved: 49,
  },
  {
    id: '13',
    name: 'Burnard Campling',
    email: 'bcamplingc@buzzfeed.com',
    licenseType: 'Light',
    licenseStatus: 'Inactive',
    lastUpdate: '24/09/2021',
    role: 'L1',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['CXC'],
    lastLogin: '2021-03-12T00:00:00.00Z',
    loginFrequence: 45,
    ticketsAssigned: 58,
    ticketsOpened: 45,
    ticketsSolved: 92,
  },
  {
    id: '14',
    name: 'Madge Kohneke',
    email: 'mkohneked@seesaa.net',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '08/08/2021',
    role: 'Global',
    zone: 'ZA',
    tags: ['user_AC'],
    groups: ['Global'],
    lastLogin: '2021-06-10T00:00:00.00Z',
    loginFrequence: 32,
    ticketsAssigned: 87,
    ticketsOpened: 42,
    ticketsSolved: 59,
  },
  {
    id: '15',
    name: 'Haskell Hynson',
    email: 'hhynsone@nymag.com',
    licenseType: 'Full',
    licenseStatus: 'Active',
    lastUpdate: '21/08/2021',
    role: 'L2',
    zone: 'DR',
    tags: ['user_AC'],
    groups: ['B2B'],
    lastLogin: '2021-05-08T00:00:00.00Z',
    loginFrequence: 60,
    ticketsAssigned: 14,
    ticketsOpened: 54,
    ticketsSolved: 46,
  },
  {
    id: '16',
    name: 'Tadd Antognazzi',
    email: 'tantognazzif@gnu.org',
    licenseType: 'Light',
    licenseStatus: 'Inactive',
    lastUpdate: '09/03/2021',
    role: 'Admin',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['CVC'],
    lastLogin: '2021-09-04T00:00:00.00Z',
    loginFrequence: 26,
    ticketsAssigned: 64,
    ticketsOpened: 86,
    ticketsSolved: 14,
  },
  {
    id: '17',
    name: 'Faunie Potts',
    email: 'fpottsg@whitehouse.gov',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '16/10/2021',
    role: 'L2',
    zone: 'US',
    tags: ['user_AC'],
    groups: ['Global'],
    lastLogin: '2021-10-13T00:00:00.00Z',
    loginFrequence: 6,
    ticketsAssigned: 76,
    ticketsOpened: 75,
    ticketsSolved: 16,
  },
  {
    id: '18',
    name: 'Wallie Filby',
    email: 'wfilbyh@google.ru',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '28/07/2021',
    role: 'Admin',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['Sales'],
    lastLogin: '2021-11-07T00:00:00.00Z',
    loginFrequence: 24,
    ticketsAssigned: 59,
    ticketsOpened: 7,
    ticketsSolved: 24,
  },
  {
    id: '19',
    name: 'Albertina Alman',
    email: 'aalmani@wp.com',
    licenseType: 'Full',
    licenseStatus: 'Inactive',
    lastUpdate: '13/12/2021',
    role: 'Admin',
    zone: 'CO',
    tags: ['user_AC'],
    groups: ['CXC'],
    lastLogin: '2021-12-18T14:38:18.25Z',
    loginFrequence: 44,
    ticketsAssigned: 9,
    ticketsOpened: 3,
    ticketsSolved: 58,
  },
  {
    id: '20',
    name: 'Lionello Knightley',
    email: 'lknightleyj@washingtonpost.com',
    licenseType: 'Light',
    licenseStatus: 'Active',
    lastUpdate: '10/08/2021',
    role: 'Global',
    zone: 'EC',
    tags: ['user_AC'],
    groups: ['B2B'],
    loginFrequence: 73,
    ticketsAssigned: 22,
    ticketsOpened: 91,
    ticketsSolved: 35,
  },
] as UserDetails[];

export default users;
