import { Button, Card, Grid, Heading, Image, Paragraph, Select } from '@hexa-ui/components';
import React, { useState } from 'react';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import FlexContainer from '../../../components/FlexContainer';
import useWindowScale from '../../../hook/useWindowScale';
import { Link } from 'react-router-dom';
import card from '../../../assets/phone.png';
import { useNavigate } from 'react-router-dom';
import { styled } from '@hexa-ui/theme';

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

const ImportGames: React.FC = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // TODO:Replace when connecting to the API.
  const importName = 'Tournament Games';

  const isAugmentedScale = useWindowScale();

  const ImageScaleStyle = {
    height: isAugmentedScale ? '120px' : '230px',
    width: '100%',
  };

  const CardScaleStyle = {
    height: isAugmentedScale ? '255px' : '460px',
    width: isAugmentedScale ? '250px' : '350px',
  };

  return (
      <Grid.Container type="fluid" style={{margin:0}}>
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            {importName}
          </Heading>
        </StyledGridItem>

        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" css={{ paddingBottom: '2rem', width: '100%'}}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FlexContainer display="flex" margin="1rem 0 1rem 0" gap="1rem" flexWrap="wrap" flexDirection={width <= 924 ? 'column' : 'row'}>
                    <Card
                      style={CardScaleStyle}
                      elevated="medium"
                      border="small"
                      css={{ overflow: 'hidden' }}
                    >
                      <Image style={ImageScaleStyle} src={card} containerCss={{ width: '100%' }} />
                      <FlexContainer
                        height="100%"
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="0.75rem"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <div>
                          <Heading size="H4">API Sports Football</Heading>
                          <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                            <Paragraph>Import Sports Football type.</Paragraph>
                          </div>
                        </div>

                        <Link
                          to={`/bees-games/tournament/tournament-import/tournament-games/sports-football`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                            Select this type
                          </Button>
                        </Link>
                      </FlexContainer>
                    </Card>
                    <Card
                      style={CardScaleStyle}
                      elevated="medium"
                      border="small"
                      css={{ overflow: 'hidden' }}
                    >
                      <Image style={ImageScaleStyle} src={card} containerCss={{ width: '100%' }} />
                      <FlexContainer
                        height="100%"
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="0.75rem"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <div>
                          <Heading size="H4">API Sports Rugby</Heading>
                          <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                            <Paragraph>Import Sports Rugby type.</Paragraph>
                          </div>
                        </div>

                        <Link
                          to={'/bees-games/tournament/tournament-import/tournament-games/sports-rugby'}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                            Select this type
                          </Button>
                        </Link>
                      </FlexContainer>
                    </Card>
                  </FlexContainer>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Card>
        </StyledGridItem>
      </Grid.Container>
  );
};

export default ImportGames;
