export const ACCESSOR_BDR_ID = 'id';
export const ACCESSOR_DATE = 'dateWithoutVisit';

export const TEST_IDS = {
  MODAL: 'modal-bdrs-without-visits',
  SHOWING_RESULTS: 'showing-results',
  DATE_FILTER: 'date-filter',
  SEARCH_FIELD: 'search-field',
  TABLE_BDRS_WITHOUT_VISITS: 'table-bdrs-without-visits',
};
