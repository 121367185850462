import { generatePath } from 'react-router-dom';

export const EMPLOYEE_ROUTES = {
  getEmployees: '/manager/employees',
  getEmployeesSearch: '/manager/employees/search',
  infoEmployee: {
    route: '/employee/:idEmployee/info',
    generate(idEmployee) {
      return generatePath(this.route, { idEmployee });
    },
  },
  getUserInfo: 'user/info',
};
