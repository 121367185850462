import { styled } from '@hexa-ui/theme'
import type * as Stitches from '@stitches/react'

export const SelectWithOptional = styled('div', {
	position: 'relative',
	'& label': {
		fontWeight: 'bold',
	},
	variants: {
		optional: {
			true: {
				'& label::after': {
					content: '"(optional)"',
					marginLeft: '4px',
					color: '#757575',
					fontFamily: 'Work Sans',
					fontSize: '14px',
					fontWeight: '400',
					lineHeight: '20px',
					textTransform: 'lowercase',
				},
			},
		},
	},
})

export type SelectWithOptionalVariants = Stitches.VariantProps<typeof SelectWithOptional>
