import { TypeToast, useToast as useBeesOneToast } from 'admin-portal-shared-services';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ToastProps, UseToast } from '../interfaces';

interface ToastTypeProps {
  type: TypeToast;
}

export const useToast = (): UseToast => {
  const { formatMessage } = useIntl();
  const beesOneToast = useBeesOneToast();

  const notify = useCallback(
    ({ type }: ToastTypeProps) =>
      ({ message, attributes, firstAction }: ToastProps) =>
        beesOneToast.notify({
          type,
          message: formatMessage({ id: message }, attributes),
          actions: firstAction
            ? [
                {
                  name: formatMessage({ id: firstAction?.name }),
                  action: firstAction?.action,
                },
              ]
            : undefined,
        }),
    []
  );

  const toast = {
    info: notify({ type: TypeToast.INFO }),
    success: notify({ type: TypeToast.SUCCESS }),
    warning: notify({ type: TypeToast.WARNING }),
    error: notify({ type: TypeToast.ERROR }),
  };

  return { toast };
};
