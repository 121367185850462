import { UploadStatus } from '../../../interfaces';
import { Guards } from './upload.guards.types';

export const guards: Guards = {
  'has-no-error': ({ context }) => !context.error.has,
  'has-file': ({ context }) => context.files.length > 0,
  'has-failed': ({ context }) => context.progress.failed > 0,
  'is-progress-done': ({ context }) => context.progress.status === UploadStatus.DONE,
  'is-go-to-progress': ({ context }) => context.uploaded.fileId && !context.files.length,
  'is-analyzed-and-has-pocs': ({ context }) =>
    context.progress.status === UploadStatus.ANALYZED && context.progress.validated > 0,
  'has-finished': ({ context }) =>
    context.progress.status === UploadStatus.ANALYZED ||
    context.progress.status === UploadStatus.DONE,
};
