import { getEarningRuleById } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useGetLoadAllItemsByRuleId } from '@/services/settings/hooks/useGetLoadAllItemsByRule'
import { useMemo } from 'react'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import useCurrentSettings from '../settings/useCurrentSettings'

export const useGetEarningRuleById = () => {
	const { selectedTierKey } = useStore(GlobalStore)
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRulesList } = useNavigateToRules()
	const ruleIds = getRuleIdFromPathname()

	const { data: queryData, isLoading } = useQuery(
		['earning-rule-by-id', vendorId, selectedTierKey, ruleIds],
		() =>
			getEarningRuleById({
				tier: selectedTierKey,
				vendorId,
				ruleIds,
			}),
		{
			enabled: !!ruleIds,
			onSuccess: (fetchedData) => {
				if (!fetchedData?.content?.length) {
					goToRulesList()
				}
			},
		},
	)

	return {
		data: queryData?.content?.[0],
		isLoading,
	}
}

export const useGetEarningRuleByIdWithSkusNormalized = () => {
	const { data, isLoading: isLoadingById } = useGetEarningRuleById()

	const { data: dataLoadAll, isLoading: isLoadingAll } = useGetLoadAllItemsByRuleId({
		skuIds: data?.items,
	})

	const dataNormalized = useMemo(() => {
		return {
			...data,
			items: dataLoadAll?.map((item) => ({
				id: item?.id,
				name: item?.itemName,
				itemId: item?.id,
				sku: item?.sku,
				image: item?.itemImage,
				vendorItemId: item.vendorItemId,
			})),
		}
	}, [data, dataLoadAll])

	return {
		data: dataNormalized,
		isLoading: isLoadingById || isLoadingAll,
	}
}
