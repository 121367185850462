import { Permissions } from 'deliver-portal-commons';

export const driverPortfolioPermissions = [
  Permissions.PlanningDriver.Read,
  Permissions.PlanningDriver.Write,
  Permissions.PlanningPortfolio.Read,
  Permissions.PlanningPortfolio.Write,
];

export const routePlanningPermissions = [
  Permissions.PlanningRoute.Read,
  Permissions.PlanningRoute.Write,
  Permissions.PlanningRoute.Delete,
];
