import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUserMetadata } from 'admin-portal-shared-services';
import SelectCountry from 'components/SelectCountry/SelectCountry';
import { LOCAL_STORAGE_COUNTRY } from 'config/constants';
import { useStore } from 'effector-react';
import React from 'react';
import { useIntl } from 'react-intl';
import * as userStoreEvents from 'stores/User/UserEvents';
import UserStore from 'stores/User/UserStore';
import { TrackingPageView } from 'tracking/events/PageView';
import { GetPageIdByLocation } from 'tracking/events/utils/utils';
import {
  CheckLocalStorageSelectedCountry,
  GetUserCountryList
} from 'utils/GetUserCountryList/GetUserCountryList';
import useStyles from './SelectCountryModal.styles';

const SelectCountryModal = (): JSX.Element => {

  const [open, setOpen] = React.useState<boolean>(false);
  const [noCountryList, setNoCountryList] = React.useState<boolean>(false);

  const { text, label, title, selectComponent, content } = useStyles();

  const { data, isLoading } = useUserMetadata();

  const selectedCountryLocalStorage = localStorage.getItem(LOCAL_STORAGE_COUNTRY);

  const { selectedCountry } = useStore(UserStore);

  const { formatMessage } = useIntl();

  const handleModalClose = React.useCallback(async () => {
    setOpen(false);
  }, []);

  const checkUserCountryList = () => {
    let options = GetUserCountryList(data?.supportedCountries);

    if (!CheckLocalStorageSelectedCountry(data?.supportedCountries, selectedCountryLocalStorage)) {
      localStorage.removeItem(LOCAL_STORAGE_COUNTRY);
      setOpen(true);
    }

    if (options.length === 1) {
      localStorage.setItem(LOCAL_STORAGE_COUNTRY, options[0].value);
      userStoreEvents.setSelectedCountry(options[0].value);
      setOpen(false);
    }

    if (options.length === 0) {
      setNoCountryList(true);
    }
  };

  React.useEffect(() => {
    if (selectedCountry && open) {

      const currentPage = GetPageIdByLocation();

      handleModalClose();

      const timeout = setTimeout(() => {
        TrackingPageView(currentPage);
      }, 1000);

      return () => {
        clearInterval(timeout)
      }
    }
  }, [selectedCountry]);

  React.useEffect(() => {
    if (!isLoading) checkUserCountryList();
  }, [data, isLoading]);

  return (
    <Dialog open={open} data-testid={'select-country-modal'}>
      {!noCountryList && (
        <>
          <DialogTitle>
            <Typography className={title}>
              {formatMessage({ id: 'ModalSelectCountry.title' })}
            </Typography>
          </DialogTitle>
          <DialogContent className={content}>
            <Typography className={text}>
              {formatMessage({ id: 'ModalSelectCountry.text' })}
            </Typography>
            <Typography className={label}>
              {formatMessage({ id: 'ModalSelectCountry.label' })}
            </Typography>

            <SelectCountry countryCompleteName customClass={selectComponent} />
          </DialogContent>
        </>
      )}

      {noCountryList && (
        <>
          <DialogTitle data-testid={'select-country-modal-empty'}>
            <Typography className={`${title} empty`}>
              {formatMessage({ id: 'ModalSelectCountry.emptyTitle' })}
            </Typography>
          </DialogTitle>
          <DialogContent className={content}>
            <Typography className={`${text} empty`}>
              {formatMessage({ id: 'ModalSelectCountry.emptyText' })}
            </Typography>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default SelectCountryModal;
