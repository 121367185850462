import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';
import ErrorPage from 'Components/ErrorPage/ErrorPage';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import SwitchWorkspacesLoading from 'Components/SwitchWorkspacesLoading/SwitchWorkspacesLoading';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import usePageViewed from 'Hooks/segment/usePageViewedEvent/usePageViewedEvent';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useBTGuideById } from 'Services/GetBTGuide/useBTGuide';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import MainPageContent from './MainPageContent/MainPageContent';

export function MainPage(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { workspaceData: selectedWorkspace, tempWorkspaceId } = useWorkspaceContext();
  const { preferredLanguage } = usePreferredLanguageV2();
  const toastService = useToast();
  const { triggerPageViewed } = usePageViewed();

  const {
    data: btGuideUrl,
    isError: isBTGuideError,
    isLoading: isBtGuideLoading,
    refetch: refetchBTGuide,
  } = useBTGuideById(selectedWorkspace?.vendorId, selectedWorkspace?.country ?? 'BR');

  useEffect(() => {
    refetchBTGuide();
  }, [refetchBTGuide]);

  const {
    data,
    isFetching: AllWorkspacesIsLoading,
    isError: isErrorWorkspaces,
  } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const { data: AllReportsMenuItems, isLoading: AllReportsIsLoading } = useGetReportsByWorkspace(
    selectedWorkspace?.workspace?.id,
    selectedWorkspace?.segment?.id,
    selectedWorkspace?.segment?.country
  );

  const [showWorkspaceLoading, setShowWorkspaceLoading] = useState(false);

  useEffect(() => {
    if (!AllReportsIsLoading) {
      triggerPageViewed({
        app_display_name: 'Analytics',
        app_id: 'PARTNER-REPORTS',
        is_core_event: false,
        page_label: 'Main Page',
        page_name: 'MAIN_PAGE',
        referrer: window.document.referrer,
        workspace_count: allWorkspaces.length,
        theme_count: sortedMenuItems.length,
        origin_theme_tracking_id: null,
        origin_theme_label: null,
        report_count: sortedMenuItems.length,
        origin_report_label: null,
        origin_report_tracking_id: null,
        has_bt_guide: !isBTGuideError,
        occurence_id: '1.1',
        preferred_language: preferredLanguage ?? 'en-US',
      });
    }
  }, [AllReportsIsLoading]);

  /* istanbul ignore next */
  const handleToast = (workspace: any) => {
    if (tempWorkspaceId !== undefined && showWorkspaceLoading) {
      toastService.notify({
        type: TypeToast.INFO,
        message: `${formatMessage({ id: 'REPORTS_LIST_PAGE.TOAST.FIRST_PART' })}  ${
          workspace?.title[preferredLanguage ?? 'en-US']
        } ${formatMessage({
          id: 'REPORTS_LIST_PAGE.TOAST.LAST_PART',
        })}`,
      });
    }
  };

  useEffect(() => {
    if (selectedWorkspace?.workspace?.id && AllReportsIsLoading && tempWorkspaceId !== undefined) {
      setShowWorkspaceLoading(true);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace?.workspace?.id && !AllReportsIsLoading && showWorkspaceLoading) {
      setShowWorkspaceLoading(false);
      handleToast(selectedWorkspace?.workspace);
    }
  }, [AllReportsIsLoading, selectedWorkspace?.workspace?.id, showWorkspaceLoading]);

  const sortedMenuItems = formatMenuItems(AllReportsMenuItems?.menuItems || []);

  if (!AllWorkspacesIsLoading && allWorkspaces?.length === 0) {
    return (
      <ErrorPage
        action="ANALYTICS_ADMIN"
        title={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.TITLE' })}
        message={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.DESCRIPTION' })}
      />
    );
  }

  if (AllReportsIsLoading && showWorkspaceLoading) {
    const workspaceTitle = selectedWorkspace?.workspace?.title[preferredLanguage || 'en-US'];
    const userTitle = selectedWorkspace?.vendorName;

    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <SwitchWorkspacesLoading workspaceTitle={workspaceTitle} segmentTitle={userTitle} />
      </PageContainer>
    );
  }

  if (
    (isErrorWorkspaces || !selectedWorkspace?.workspace?.id || isBtGuideLoading) &&
    !showWorkspaceLoading
  ) {
    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <Loading type="container" />
      </PageContainer>
    );
  }
  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />
      <MainPageContent
        sortedMenuItems={sortedMenuItems}
        selectedWorkspace={selectedWorkspace}
        isBTGuideError={isBTGuideError}
        btGuideUrl={btGuideUrl}
        AllReportsIsLoading={AllReportsIsLoading}
        allWorkspaces={allWorkspaces}
      />
    </PageContainer>
  );
}
