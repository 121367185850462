export const VISIT_DETAILS_CARD_TEST_IDS = {
  GO_BACK_ICON_BUTTON: 'go-back-icon-button',
  HEADER: 'visit-details-header',
};

export const POC_INFORMATION_TEST_IDS = {
  HEADER: 'poc-information-header',
};

export const VISIT_INFORMATION_TEST_IDS = {
  HEADER: 'visit-information-header',
};

export const NEXT_VISIT_TEST_IDS = {
  HEADER: 'next-visit-header',
};

export const BDR_INFORMATION_TEST_IDS = {
  HEADER: 'bdr-information-header',
};
