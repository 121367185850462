import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationState } from '../../types/pagination';

const initialState: PaginationState = {
  currentPage: 0,
  totalRecords: 0,
  totalPages: 0,
  pageSize: 25,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const { setCurrentPage, setTotalRecords, setTotalPages, setPageSize } =
  paginationSlice.actions;

export default paginationSlice.reducer;
