import styled from 'styled-components';

interface ContainerProps {
  margin?: string;
  maxWidth?: string;
}

export const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};

  div:has(> table) {
    max-height: 60vh;
    height: 100%;
  }

  @media (min-resolution: 1.5dppx) {
    th > div > h5 {
      font-size: 0.75rem;
    }

    td {
      font-size: 0.69rem;
    }

    p,
    button,
    nav,
    li {
      font-size: 0.75rem;
      height: 2.2rem;
    }

    div {
      font-size: 0.75rem;
    }

    div > p {
      margin-top: 0.3rem;
    }

    svg {
      height: 1.1rem;
      width: 1.1rem;
    }

    div:has(> table) {
      max-height: 40vh;
      height: 100%;
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: end;
  padding: 0.5rem 1rem;
  @media (min-resolution: 1.5dppx) {
    p,
    button,
    nav,
    li {
      font-size: 0.75rem;
      height: 2.2rem;
    }

    div {
      font-size: 0.75rem;
    }

    div > p {
      margin-top: 0.3rem;
    }

    svg {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
`;
