export const formatPercentage = (
  value: number,
  locale: string,
  options?: Intl.NumberFormatOptions
): string => {
  const formatter = Intl.NumberFormat(locale, {
    maximumFractionDigits: 1,
    style: 'percent',
    ...options,
  });

  return formatter.format(value);
};
