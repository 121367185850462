import { LoadingDots } from "@hexa-ui/components";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { useUserMetadata } from "admin-portal-shared-services";
import { useEffect, useState } from "react";
import { EnvConfig } from "./../../utils/envProvider";

const OptimizelyFeatureProvider = ({
  children,
  env,
}: {
  children: React.ReactNode;
  env: EnvConfig;
}) => {
  const { data } = useUserMetadata();
  const [isLoading, setIsLoading] = useState(true);

  // should be inserted from envConfig
  // SDDK key should be inserted from envConfig
  // id = userId can be found on optimizely dashboard on API values
  // SDDK is going to be different for each environment and can be found on optimizely dashboard

  const optimizely = createInstance({
    sdkKey: env.optimizelySddKey,
    datafileOptions: {
      urlTemplate: "https://cdn.optimizely.com/datafiles/%s.json",
    },
  });

  useEffect(() => {
    if (!data?.userId) {
      return;
    }

    optimizely.setUser({
      id: data?.userId,
    });

    optimizely.onReady().then(() => {
      console.log("Optimizely is ready");
      setIsLoading(false);
    });
  }, [data?.userId]);

  if (isLoading) {
    return <LoadingDots dataTestId="loadingDots" />;
  }

  return (
    <OptimizelyProvider user={{ id: data?.userId }} optimizely={optimizely}>
      {children}
    </OptimizelyProvider>
  );
};

export default OptimizelyFeatureProvider;
