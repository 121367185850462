import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsPerClusterProvider, useHubTheme } from 'admin-portal-shared-services';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { store } from 'store';

import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { AuthProvider, ModalProvider } from 'contexts';
import IntlProvider from 'i18n/provider';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'src/routes/Routes';
import { EnvConfig, EnvProvider } from 'utils/EnvProvider';
import { SetThemeProvider } from 'utils/Theme';
import { AppbarConfig } from './components/AppbarConfig';

const queryClient = new QueryClient();

export default function App(props: Readonly<EnvConfig>) {
  const [hubTheme, setHubTheme] = useState<ThemeProviderType['theme']>('customer');
  useHubTheme(hubTheme);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EnvProvider env={props}>
          <PropsPerClusterProvider propValues={props}>
            <ThemeProvider theme={hubTheme}>
              <SetThemeProvider setTheme={setHubTheme}>
                <AuthProvider>
                  <Provider store={store}>
                    <IntlProvider>
                      <ModalProvider>
                        <BrowserRouter>
                          <AppbarConfig>
                            <AppRoutes />
                          </AppbarConfig>
                        </BrowserRouter>
                      </ModalProvider>
                    </IntlProvider>
                  </Provider>
                </AuthProvider>
              </SetThemeProvider>
            </ThemeProvider>
          </PropsPerClusterProvider>
        </EnvProvider>
      </ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
