enum ScreenName {
	ChallengeHistory = 'Challenge History',
	CreateChallengeConfirmationModal = 'Confirmation Modal - Challenge Creation',
	CreateChallenge2ndStepFiltersDropdown = 'Apply Filters - Filter Values Dropdown',
	CreateChallenge3rdStep = 'Challenge Creation - 3rd Step',
	TransactionLog = 'Transaction Log',
	ReviewIdsModal = 'Review IDs Modal',
	PointsManagement = 'Points Management',
	RewardsOffer = 'Rewards Offer - Create Points Transaction',
	RefundCreatePointsTransaction = 'Refund - Create Points Transaction',
	ChallengeDetailsModal = 'Challenge Details Modal',
	ChallengeSetupModal = 'Challenge Setup Modal',
	ChallengeFilterModal = 'Challenge Filter Modal',
	ChallengeSetup = 'Challenge Setup',
	ChallengeFilter = 'Challenge Filter',
	ChallengeFiltersStep = 'Apply Filters',
	ChallengeDetails = 'Challenge Details',
	DTComboMaxQuantity = 'DT Combo Max Quantity',
	DTComboManagement = 'DT Combo Management',
	DTComboManagementPublishedTab = 'DT Combo Management - Published Tab',
	DTComboManagementPublishedEditModal = 'DT Combo Management - Published - Edit Modal',
	DTComboManagementUnpublishedTab = 'DT Combo Management - Unpublished Tab',
	AddDTCombo = 'Add a DT Combo',
	RemoveDTComboModal = 'Remove DT Combo Modal',
	EditMaxQuantityModal = 'Edit Max Quantity Modal',
	EditDTComboModal = 'Edit DT Combo Modal',
	EarningRules = 'Earning Rules',
	ExclusionRules = 'Exclusion Rules',
	RemoveSkuModal = 'Remove SKU Modal',
	ChangeSkuModal = 'Change SKU Modal',
	AddSkuModal = 'Add SKU Modal',
	EarningRulesSkuList = 'Earning Rule - SKU List',
	ChallengeDetailsPage = 'Challenge Details Page',
	EditChallengeStep2 = 'Edit Challenge Step 2',
	EditChallenge = 'Edit Challenge',
	Edit = 'Edit',
	PointsManagementTransactionLog = 'Points Management - Transaction Log',
	CreatePointsManagement = 'Create Points Transaction',
	CreateEarningRule = 'Earning Rules - Create Earning Rule',
	EarningRuleDetails = 'Earning Rules - Earning Rule Details',
	EditEarningRule = 'Earning Rules - Edit Earning Rule',
	EarningRulesSkus = 'Earning Rules - SKUs',
	EarningRulesDetails = 'Earning Rules - Details',
	EarningRulesSkuEditStep1 = 'Earning Rules - SKUs - Edit - Step 1',
	EarningRulesSkuEditStep2 = 'Earning Rules - SKUs - Edit - Add - Step 2',
	EarningRulesSkuEditStep3Multiple = 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
	EarningRulesSkuEditStep3Single = 'Earning Rules - SKUs - Edit - Add - Single - Step 3',
	CreateExclusionRule = 'Exclusion List - Create Exclusion Rule',
	EditExclusionRule = 'Exclusion List - Edit Exclusion Rule',
	ExclusionRuleDetails = 'Exclusion List - Exclusion Rule Details',
	TransactionHistory = 'Transaction History',
	RewardsPermission = 'Rewards Permission',
	RedeemablesList = 'Redeemables List',
	EditRedeemablePPP = 'Edit redeemable price per point',
	CreateRedeemable = 'Create Redeemable',
}

export default ScreenName
