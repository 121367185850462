import { useIntl } from 'react-intl';

import { Heading } from '@hexa-ui/components';

import { BDR_INFORMATION_TEST_IDS } from '../consts/VisitDetailsCardConsts';
import * as Styled from './BdrInformation.styles';

export const BdrInformation = () => {
  const { formatMessage } = useIntl();
  return (
    <Styled.WrapperBdrInformationCard border="medium" elevated="minimal">
      <Heading data-testid={BDR_INFORMATION_TEST_IDS.HEADER} size="H3">
        {formatMessage({ id: 'Components.VisitDetails.Title.BDR_INFORMATION' })}
      </Heading>
    </Styled.WrapperBdrInformationCard>
  );
};
