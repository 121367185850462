import { useIntl } from 'react-intl';

interface UseTranslationProps {
  bdrId: string;
  accountId: string;
  searchBy: string;
  searchButton: string;
  bdrName: string;
  accountIdError: string;
  bdrIdError: string;
  bdrNameError: string;
  titleCardVisits: string;
  showingResults: string;
  bdrIdColumn: string;
  bdrNameColumn: string;
  bdrAccountIdColumn: string;
  visitDateColumn: string;
  statusColumn: string;
  minTimeColumn: string;
  maxTimeColumn: string;
  minutes: string;
  scheduled: string;
  inProgress: string;
  canceled: string;
  justified: string;
  completed: string;
}

enum TranslationKeys {
  BUTTONS = 'Buttons',
  SEARCH_BAR = 'SearchBar',
  TITLE = 'Title',
  ERROR = 'Error',
  TABLE_VISITS = 'TableVisits',
}

export const useTranslation = (): UseTranslationProps => {
  const { formatMessage } = useIntl();

  const getTranslationById = (key: string, value: string): string => {
    return formatMessage({ id: `Components.VisitTableCard.${key}.${value}` });
  };

  return {
    bdrId: getTranslationById(TranslationKeys.SEARCH_BAR, 'BDR_ID'),
    accountId: getTranslationById(TranslationKeys.SEARCH_BAR, 'ACCOUNT_ID'),
    searchBy: getTranslationById(TranslationKeys.SEARCH_BAR, 'SEARCH_BY'),
    searchButton: getTranslationById(TranslationKeys.BUTTONS, 'SEARCH_BUTTON'),
    bdrName: getTranslationById(TranslationKeys.SEARCH_BAR, 'BDR_NAME'),
    titleCardVisits: getTranslationById(TranslationKeys.TITLE, 'CARD_VISITS'),
    accountIdError: getTranslationById(TranslationKeys.ERROR, 'ACCOUNT_ID_ERROR'),
    bdrIdError: getTranslationById(TranslationKeys.ERROR, 'BDR_ID_ERROR'),
    bdrNameError: getTranslationById(TranslationKeys.ERROR, 'BDR_NAME_ERROR'),

    showingResults: getTranslationById(TranslationKeys.TABLE_VISITS, 'SHOWING_RESULTS'),
    bdrIdColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'BDR_ID'),
    bdrNameColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'BDR_NAME'),
    bdrAccountIdColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'BDR_ACCOUNT_ID'),
    visitDateColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'VISIT_DATE'),
    statusColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'STATUS'),
    minTimeColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'MIN_TIME'),
    maxTimeColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'MAX_TIME'),
    minutes: getTranslationById(TranslationKeys.TABLE_VISITS, 'MINUTES'),
    scheduled: getTranslationById(TranslationKeys.TABLE_VISITS, 'SCHEDULED'),
    inProgress: getTranslationById(TranslationKeys.TABLE_VISITS, 'IN_PROGRESS'),
    canceled: getTranslationById(TranslationKeys.TABLE_VISITS, 'CANCELED'),
    justified: getTranslationById(TranslationKeys.TABLE_VISITS, 'JUSTIFIED'),
    completed: getTranslationById(TranslationKeys.TABLE_VISITS, 'COMPLETED'),
  };
};
