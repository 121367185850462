import {useApi} from '../../api';

export default async function blockUnblockPocService(
  pocCode: string,
  campaignId: number,
  block: boolean,
  unblockForFraud: boolean | undefined
): Promise<any> {
  let body: {campaignId: number; block: boolean; unblockForFraud?: boolean} = {
    campaignId: campaignId,
    block: block,
  };
  if (unblockForFraud !== undefined) {
    body['unblockForFraud'] = unblockForFraud;
  }

  return await useApi().patch({
    url: `/v1/admin/accounts/${pocCode}/block-status`,
    body: body,
  });
}
