import { Heading } from '@hexa-ui/components';

import { getFormattedMessage } from '@/utils/getFormattedMessage/getFormattedMessage';

import { ModalBDRsWithoutVisits } from '../ModalBDRsWithoutVisits/ModalBDRsWithoutVisits';
import { CardComponent } from './components/CardComponent/CardComponent';
import { Filters } from './components/Filters/Filters';
import { OverviewRefreshData } from './components/OverviewRefreshData/OverviewRefreshData';
import { TotalVisitsCard } from './components/TotalVisitsCard/TotalVisitsCard';
import { useOverview } from './hooks/useOverview';
import * as Styled from './Overview.styles';

export const Overview = (): JSX.Element => {
  const { cardRef, formatMessage, handleClickRefreshButton, handleOpenOrCloseModal, isModalOpen } =
    useOverview();

  return (
    <Styled.CardStatus ref={cardRef} border="medium" elevated="small">
      <ModalBDRsWithoutVisits isOpen={isModalOpen} onClose={handleOpenOrCloseModal} />
      <Styled.WrapperHeaderOverview>
        <Heading size="H3">{formatMessage({ id: 'Components.Overview.Title.OVERVIEW' })}</Heading>
        <OverviewRefreshData
          lastTimeUpdate={getFormattedMessage(
            formatMessage({ id: 'Components.Overview.Label.LAST_UPDATE' }),
            { lastUpdate: '3' },
          )}
          hasError={false}
          errorMessage={formatMessage({
            id: 'Components.Overview.Error.REFRESH_ERROR',
          })}
          handleClickRefresh={handleClickRefreshButton}
        />
      </Styled.WrapperHeaderOverview>
      <Filters />
      <Styled.WrapperContentOverview>
        <TotalVisitsCard totalVisits="1.550" />

        <CardComponent
          title={formatMessage({
            id: 'Components.Overview.Title.CANCELLED_VISITS',
          })}
          count={'155'}
          total={getFormattedMessage(
            formatMessage({
              id: 'Components.Overview.Label.CANCELLED_VISITS_TOTAL',
            }),
            { total: '10' },
          )}
          percentage={'25%'}
        />
        <CardComponent
          title={formatMessage({
            id: 'Components.Overview.Title.BDRS_WITHOUT_VISITS',
          })}
          count={'10'}
          total={getFormattedMessage(
            formatMessage({
              id: 'Components.Overview.Label.BDRS_WITHOUT_VISITS_TOTAL',
            }),
            { total: '10' },
          )}
          percentage={'5%'}
          isClickable
          handleCardClick={handleOpenOrCloseModal}
        />
      </Styled.WrapperContentOverview>
    </Styled.CardStatus>
  );
};
