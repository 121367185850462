export enum BrandNameTypes {
  BRAHMA = 'BRAHMA',
  ANDES_ORIGEN = 'ANDES ORIGEN',
  QUILMES = 'QUILMES',
  BUDWEISER = 'BUDWEISER',
  CLUB = 'CLUB',
  CORONA = 'CORONA',
  MODELO = 'MODELO',
  CLUB_PREMIUM = 'CLUB PREMIUM',
  BRUTAL_FRUIT = 'BRUTAL FRUIT',
  SKOL = 'SKOL',
  PONY = 'PONY',
  MICHELOB_ULTRA = 'MICHELOB ULTRA',
  PACIFICO = 'PACIFICO',
  CUSQUEÑA = 'CUSQUEÑA',
  HUARI = 'HUARI',
  PILSEN = 'PILSEN',
  STELLA_ARTOIS = 'STELLA ARTOIS',
  POKER = 'POKER',
  SPATEN = 'SPATEN',
  SALVA_VID = 'SALVA VIDA',
  PILSENER = 'PILSENER',
  BALBOA = 'BALBOA',
  VICTORIA = 'VICTORIA',
  AGUILA = 'AGUILA',
  PRESIDENTE = 'PRESIDENTE',
  PONY_MALTA = 'PONY MALTA',
  FLYING_FISH = 'FLYING FISH',
  CARLING_BLACK_LABEL = 'CARLING BLACK LABEL',
  CRISTAL = 'CRISTAL',
  PACENA = 'PACENA',
}
