import MessageMap from '../i18n';

export const idID: MessageMap = {
  test: {
    translate: 'Terjemahkan',
    missing: 'Hanya opsi Bahasa Inggris yang tersedia',
  },
  MISSIONS_MANAGEMENT: {
    TITLE: 'Manajemen Misi',
  },
  GlobalMissionsPage: {
    TITLE: 'Misi Global',
    CREATE_MISSION: 'Buat misi baru',
    INFO_CREATE_MISSIONS: 'Buat misi yang akan tersedia untuk zona tersebut.',
    TABLE_PERIOD: 'Periode',
    TABLE_IDENTIFICATION: 'Identifikasi',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Prioritas',
    PRIORITIZATION: 'Prioritisasi',
    INFO_CHANGES: 'Semua perubahan yang dilakukan akan berlaku pada hari berikutnya.',
    SAVE_CHANGES: 'Simpan perubahan',
    DISCARD_CHANGES: 'Buang perubahan',

    TOPLINE_GAP: 'Topline gap',
    TOPLINE_GAP_DESCRIPTION: `Pahami di mana gap pelanggan dan rekomendasikan produk untuk membantu mereka berkembang.`,
    MARKETPLACE: 'Marketplace',
    MARKETPLACE_DESCRIPTION: 'Tawarkan pembelian produk marketplace.',
    REWARDS: 'Tantangan hadiah',
    REWARDS_DESCRIPTION:
      'Sarankan pelanggan untuk menerima dan melakukan tantangan yang tersedia untuk mendapatkan poin.',
    CREDIT_ACTIVATION: 'Aktivasi kredit',
    CREDIT_ACTIVATION_DESCRIPTION: `Tingkatkan penggunaan kredit dalam pembelian pelanggan.`,
    SERVICES: 'Layanan',
    SERVICES_DESCRIPTION: 'Tawarkan layanan baru yang tersedia untuk pelanggan kami.',
    LOCAL: 'Misi lokal',
    LOCAL_DESCRIPTION: 'Misi khusus yang dibuat oleh tim back office operasi.',

    STATUS_ONGOING: 'Sedang berlangsung',
    STATUS_SCHEDULED: 'Dijadwalkan',
    STATUS_CONCLUDED: 'Selesai',
    STATUS_EXPIRED: 'Kedaluwarsa',
    STATUS_PAUSED: 'Dijeda',
    PARAMETER: {
      HEAD: 'Batas misi bersama',
      HEAD_SUB_TITLE: 'Atur jumlah misi maksimum yang akan dilakukan oleh setiap agen.',
      GLOBAL_MISSIONS_LABEL: 'Misi Global',
      GLOBAL_MISSIONS_TOOLTIP: 'Topline gap dan Marketplace.',
      LOCAL_MISSIONS_LABEL: 'Misi Lokal',
      LOCAL_MISSIONS_TOOLTIP:
        'Misi lokal dengan prioritas tertinggi adalah yang akan ditugaskan ke agen.',
      TOTAL_LIMIT_LABEL: 'Total',
    },
    HELP_DIALOG: {
      PARAMETERS_HELPMODAL_TITLE: 'Batas misi bersama',
      PARAMETERS_HELPMODAL_SUBTITLE: 'Melebihi batas',
      PARAMETERS_HELPMODAL_TEXT:
        'Ketika batas terlampaui, misi dengan prioritas rendah mungkin tidak akan ditampilkan kepada agen.',
      PARAMETERS_HELPMODAL_SUBTEXT:
        'Untuk memastikan misi ditampilkan, jadikan sebagai prioritas utama.',
    },
    ERROR_TO_LOAD_MISSIONS: 'Kami tidak dapat memuat misi',

    DISCARD_TITLE: 'Buang perubahan?',
    DISCARD_DESCRIPTION: `Perubahan akan dibuang segera, dan tindakan ini tidak dapat dibatalkan.`,
    CANCEL: 'Membatalkan', // Corrected
    CONFIRM_DISCARD: 'Ya, buang',

    CHANGES_SUCCESSFULLY_SAVED: 'Perubahan berhasil disimpan.',
    CHANGES_FAILED_TO_SAVE: `Perubahan tidak dapat disimpan.`,
    TRY_SAVING_AGAIN: 'Coba lagi',
  },
  GlobalMissionsStepTwo: {
    INCLUDE_POCS: 'Sertakan POCs dengan',
    EXCLUDE_POCS: 'Kecualikan POCs dengan',
    ADD_CONDITION: 'Tambahkan kondisi',
    EQUALS_TO: 'Sama dengan',
    GREATER_THAN: 'Lebih besar dari',
    GREATER_THAN_OR_EQUAL_TO: 'Lebih besar atau sama dengan',
    LESS_THAN: 'Lebih kecil dari',
    LESS_THAN_OR_EQUAL_TO: 'Lebih kecil atau sama dengan',
    BETWEEN: 'Antara',
    LISTED_ON: 'Terdaftar pada',

    SELECT_CREDIT: 'Kredit',
    SELECT_PERIOD_CREDIT: 'Periode tanpa menggunakan kredit',
    SELECT_AVAIBLE_CREDIT: 'Kredit yang tersedia',
    SELECT_TERMS_CREDIT: 'Penerimaan syarat penggunaan kredit',

    SELECT_PURCHASE: 'Profil pembelian',
    SELECT_DIGITALIZATION: 'Tingkat digitalisasi',

    SELECT_INFO_POC: 'Informasi POC',
    SELECT_POC: 'ID POC',

    SELECT_CONDITION: 'Pilih kondisi',
    ACCEPTED: 'Diterima',
    NOT_ACCEPTED: 'Tidak diterima',

    UPLOAD: 'Unggah file',
    BLOCK_LIST: 'Daftar blokir',

    ENTER_VALIDATION: 'Masukkan validasi',

    DAYS_PLACEHOLDER: '0',
    PERCENT_MONEY_PLACEHOLDER: '0.00',
    MONEY_PREFIX: '$',
    DAYS_SUFIX: 'hari',
    PERCENT_SUFIX: '%',
    TOOLTIP_DISABLED: 'Beberapa kondisi memiliki operator dan jenis validasi khusus.',

    PRIORIZATION_CONDITIONS: 'Hubungi prioritas dari atas ke bawah.',
    REGISTRY_ON_BLOCK_LIST: 'Registrasi di daftar blokir',
    TOOLTIP_LIMIT_OF_CONDITIONS: 'Batas kondisi bersamaan tercapai.',
  },
  GlobalMissionsStepThree: {
    SCRIPT_TITLE: 'Skrip',
    SCRIPT_SUB_TITLE: '(opsional)',
    SCRIPT_PLACEHOLDER:
      'Contoh: Sapa pelanggan dan beri tahu alasan panggilan.\nKemudian ingatkan mereka tentang manfaat membeli langsung dari Aplikasi BEES.',
    EFFECTIVENESS_LABEL: 'Kriteria efektivitas',
    EFFECTIVENESS_DESCRIPTION: 'Kondisi untuk misi ini dianggap sukses setelah penyelesaiannya.',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_TITLE: 'Kredit sebagai pembayaran',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_SUBTITLE:
      'Membutuhkan penggunaan kredit sebagai metode pembayaran.',
    EFFECTIVENESS_CREDIT_ONBOARDING_TITLE: 'Pendaftaran kredit',
    EFFECTIVENESS_CREDIT_ONBOARDING_SUBTITLE: 'Membutuhkan penerimaan syarat penggunaan kredit.',
    TIME_LIMIT_LABEL: 'Batas waktu',
    TIME_LIMIT_DESCRIPTION: 'Periode untuk mencapai kriteria efektivitas.',
    TIME_LIMIT_WITHIN_THE_MISSION_PERIOD_OPTION: 'Dalam periode misi',
    TIME_LIMIT_SAME_DAY_OF_THE_CALL_OPTION: 'Pada hari yang sama dengan panggilan',
    TIME_LIMIT_THE_DAY_AFTER_THE_CALL_OPTION: 'Hari setelah panggilan',
    TIME_LIMIT_CUSTOMIZED_PERIOD_OPTION: 'Periode yang disesuaikan',
    MINIMUM_AMOUNT_LABEL: 'Jumlah minimum yang dibayar',
    DAYS_SUFIX: 'hari',
    INCLUDE_DAYS_CHECKBOX: 'Termasuk sisa hari bulan ini',
    MINIMUM_AMOUNT_ERROR: 'Nilai melebihi batas yang ditetapkan untuk audiens ini.',
  },
  Loading: {
    BEELOADING_TEXT: 'Memproses…',
  },
  GlobalMissionsSteps: {
    FIRST_STEP: 'Utama',
    SECOND_STEP: 'Audiens',
    THIRD_STEP: 'Konten',
    buttons: {
      CONTINUE: 'Lanjutkan',
      CANCEL: 'Membatalkan',
      GO_BACK: 'Kembali',
      FINISH: 'Selesai',
    },
    inputValidationFeedbacks: {
      ENTER_A_MISSION_NAME: 'Masukkan nama misi',
      MISSION_ALREADY_EXISTS: 'Sudah ada misi dengan judul ini, coba nama lain',
      ENTER_A_DESCRIPTION: 'Masukkan deskripsi',
      REQUIRED_FIELD: 'Kolom ini diperlukan',
      AFTER_TOMORROW_MESSAGE: 'Masukkan tanggal setelah besok',
      AFTER_START_DATE_MESSAGE: 'Masukkan tanggal setelah misi dimulai',
      DATE_IN_THE_PAST_MESSAGE: 'Tidak dapat dimulai di masa lalu',
      SELECT_A_VALID_OPTION: 'Pilih opsi yang valid',
      THERE_IS_NO_CONDITION_IN_SECOND_STEP: 'Tentukan untuk POC mana misi ini akan memenuhi syarat',
      REQUIRED_BOTH_FIELDS: 'Kedua kolom diperlukan',
    },
  },
  GlobalMissionsCard: {
    START_DATE: 'Mulai',
    END_DATE: 'Akhir',
    END_DATE_VALUE: 'Tidak ada tanggal akhir',
  },
  NewGlobalMissionsPage: {
    OPTIONAL: 'Opsional',
    MISSION_NAME: 'Nama misi',
    MISSION_NAME_PLACEHOLDER: 'Contoh: Peningkatan Marketplace',
    CATEGORIES: 'Kategori',
    CATEGORIES_PLACEHOLDER: 'Contoh: Fintech, Marketplace, Hadiah',
    CATEGORIES_REWARDS: 'Hadiah',
    CATEGORIES_SERVICES: 'Layanan',
    END_DATE: 'Tanggal akhir',
    END_DATE_PLACEHOLDER: 'Masukkan tanggal akhir',
    START_DATE: 'Tanggal mulai',
    START_DATE_PLACEHOLDER: 'Masukkan tanggal mulai',
    DESCRIPTION: 'Deskripsi',
    DESCRIPTION_PLACEHOLDER_FIRST_TEXT: 'Masukkan deskripsi singkat untuk misi ini',
    DESCRIPTION_PLACEHOLDER_SECOND_TEXT: 'Deskripsi ini juga dapat ditampilkan di platform agen.',
    WHEN_TO_CALL: 'Kapan untuk menelepon',
    DAYS_WITHIN_THE_DELIVERY_WINDOW: 'Hari dalam jendela pengiriman',
    ANY_DAY: 'Hari apa saja',
    CALL_EVERY: 'Setelah kontak pertama, telepon setiap',
    CALL_EVERY_PLACEHOLDER: 'hari',

    SUCCESS_TOAST_MESSAGE: 'Misi berhasil dibuat, dan akan berlaku pada hari berikutnya.',
    ERROR_TOAST_MESSAGE: 'Gagal membuat misi.',
  },
  LOAD_ERROR: {
    MESSAGE: 'Ooops! Ada yang salah.',
    TRY_AGAIN: 'Coba lagi',
  },
};
