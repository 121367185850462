import { useIntl } from 'react-intl';

import { Heading } from '@hexa-ui/components';

import { VISIT_INFORMATION_TEST_IDS } from '../consts/VisitDetailsCardConsts';
import * as Styled from './VisitInformation.styles';

export const VisitInformation = () => {
  const { formatMessage } = useIntl();
  return (
    <Styled.WrapperVisitInformationCard border="medium" elevated="minimal">
      <Heading data-testid={VISIT_INFORMATION_TEST_IDS.HEADER} size="H3">
        {formatMessage({
          id: 'Components.VisitDetails.Title.VISIT_INFORMATION',
        })}
      </Heading>
    </Styled.WrapperVisitInformationCard>
  );
};
