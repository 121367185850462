const BASE_PATH = `/platforminfo`;
const NEW_BASE_PATH = `${BASE_PATH}/:countryCode`;
const MOBILE_PAGE = `${BASE_PATH}/mobile`;
const CRASH_FREE = `${BASE_PATH}/mobile/crashfree`;
const IOS_PATH = `${BASE_PATH}/mobile/ios`;
const ANDROID_PATH = `${BASE_PATH}/mobile/android`;
const BASE_COUNTRY = `${BASE_PATH}/operations`;
const BRAZIL_PAGE = `${BASE_PATH}/operations/BR`;
const ARGENTINA_PAGE = `${BASE_PATH}/operations/AR`;
const CANADA_PAGE = `${BASE_PATH}/operations/CA`;
const BRF_PAGE = `${BASE_PATH}/operations/BRF`;
const COLOMBIA_PAGE = `${BASE_PATH}/operations/CO`;
const ECUADOR_PAGE = `${BASE_PATH}/operations/EC`;
const DOMINICAN_REPUBLIC_PAGE = `${BASE_PATH}/operations/DO`;
const EL_SALVADOR_PAGE = `${BASE_PATH}/operations/SV`;
const HONDURAS_PAGE = `${BASE_PATH}/operations/HN`;
const PARAGUAY_PAGE = `${BASE_PATH}/operations/PY`;
const MEXICO_PAGE = `${BASE_PATH}/operations/MX`;
const PANAMA_PAGE = `${BASE_PATH}/operations/PA`;
const PERU_PAGE = `${BASE_PATH}/operations/PE`;
const SOUTH_AFRICA_PAGE = `${BASE_PATH}/operations/ZA`;
const TANZANIA_PAGE = `${BASE_PATH}/operations/TZ`;
const UNITED_STATES_PAGE = `${BASE_PATH}/operations/US`;
const URUGUAY_PAGE = `${BASE_PATH}/operations/UY`;
const BACKEND_SERVICES_PAGE = `${BASE_PATH}/backendservices`;
const MICROSERVICES_TRAFFIC_PAGE = `${BASE_PATH}/backendservices/microservices`;

export {
  BASE_PATH,
  NEW_BASE_PATH,
  IOS_PATH,
  MOBILE_PAGE,
  ANDROID_PATH,
  SOUTH_AFRICA_PAGE,
  CRASH_FREE,
  BRAZIL_PAGE,
  ARGENTINA_PAGE,
  PANAMA_PAGE,
  PARAGUAY_PAGE,
  BRF_PAGE,
  PERU_PAGE,
  URUGUAY_PAGE,
  BACKEND_SERVICES_PAGE,
  MICROSERVICES_TRAFFIC_PAGE,
  MEXICO_PAGE,
  CANADA_PAGE,
  TANZANIA_PAGE,
  HONDURAS_PAGE,
  COLOMBIA_PAGE,
  EL_SALVADOR_PAGE,
  UNITED_STATES_PAGE,
  ECUADOR_PAGE,
  DOMINICAN_REPUBLIC_PAGE,
  BASE_COUNTRY,
};
