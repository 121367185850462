import { createServer, Server } from 'miragejs';
import factories from './factories';
import models from './models';
import logRoutes from './routes/logRoutes';
import usersCountRoutes from './routes/usersCountRoutes';
import usersRoutes from './routes/usersRoutes';
import seeds from './seeds';
import serializers from './serializer';

const startServer = ({ environment = 'development' } = {}): Server => {
  const server: Server = createServer({
    factories,
    models,
    serializers,
    seeds,
    routes() {
      logRoutes(this);
      usersRoutes(this);
      usersCountRoutes(this);
    },
    environment,
  });
  server.logging = false;

  return server;
};

export default startServer;
