import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { EnvConfig, EnvProvider } from './components';
import { ConnectedWrapper } from './contexts/ConnectedWrapper';
import { IntlProvider } from './i18n';
import Router from './routes/Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-platform-info-mfe',
    seed: 'bees-hub-platform-info-mfe',
  });

  return (
    <EnvProvider env={props}>
      <ConnectedWrapper>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <Router />
          </IntlProvider>
        </StylesProvider>
      </ConnectedWrapper>
    </EnvProvider>
  );
}
