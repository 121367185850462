import { Grid } from '@hexa-ui/components';
import { SetStateAction } from 'react';
import FlexContainer from '../../../../../components/FlexContainer';
import { CampaignType } from '../../../../../store/dataTypes';
import StyledRadio from '../../../../../components/Radio/StyledRadio';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}

const TabPrizeGiving: React.FC<props> = ({ form, setForm }) => {
  return (
    <Grid.Item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      style={{ rowGap: '1rem', flexDirection: 'column' }}
    >
      <FlexContainer display="flex" flexWrap="wrap" gap="1rem">
        <StyledRadio label='Type*' defaultValue=',' hint='based on result: Each quiz result is associated with a specific prize
          stock: All users who reach the minimum number of correct answers in the quiz receive a prize, until the prize stock is depleted
          time window: Users who complete the quiz within the predetermined time windows will have a chance to receive a prize' style={{ marginBottom: '12px', minWidth: '100% ' }}
          options={[
              {
                  id: 'prize_based_on_result',
                  label: 'Prize based on result',
                  value: 'prize_based_on_result'
              },
              {
                  id: 'prize_stock',
                  label: 'Prize Stock',
                  value: 'prize_stock'
              },
              {
                id: 'time_window',
                label: 'Time Window',
                value: 'time_window'
            }
          ]}
          /* onChange={(value => changeStateForm(setForm, form, 'type', value))} */
        />
      </FlexContainer>
    </Grid.Item>
  );
};

export default TabPrizeGiving;
