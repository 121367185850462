import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  TasksCarousel: {
    TITLE: "Tarefas de ligação",
    Empty: {
      DESCRIPTION: "Não há tarefas atribuídas a esta POC.",
    },
    Error: {
      DESCRIPTION: "Não é possível carregar as tarefas da chamada.",
      BUTTON: "Tentar novamente",
      GENERIC_ERROR: "Algo deu errado. Tente novamente mais tarde.",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Patrocinada",
      Global: {
        Title: {
          TOPLINE: "Tarefa de topline",
          MARKETPLACE: "Tarefa de marketplace",
        },
        Description: {
          TOPLINE: "Recomendar produtos com base na ordem sugerida.",
          MARKETPLACE: "Incentivar a compra de produtos do marketplace.",
        },
        Script: {
          TOPLINE:
            "Compreender a diferença entre o pedido atual e o pedido sugerido e recomendar produtos para alcançar metas de receita.",
          MARKETPLACE:
            "Informar sobre os novos produtos disponíveis no mercado e recomendá-los ao PDV.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Tarefa personalizada criada pelas equipes de operações.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Marcar como concluído",
      MARK_AS_NOT_DONE: "Marcar como não concluído",
      Error: {
        RELOAD: "Recarregar",
        GENERIC_ERROR:
          "Não é possível carregar as informações. Verifique sua conexão e tente novamente.",
      },
      TOTAL_QUANTITY: "Quantidade total mínima: {quantity}.",
      MINIMUM_QUANTITY: "Quantidade mínima: {quantity}.",
    },
  },
  ProductSelection: {
    TITLE: "Produtos",
    LAST_ORDER_BUTTON: "Mostrar os últimos pedidos",
    Empty: {
      EMPTY_PRODUCTS_MESSAGE:
        "Esta POC não possui produtos em {param}. Tente outra categoria.",
      EMPTY_PRODUCTS_SEARCH_MESSAGE:
        'Nenhum resultado para "{searchInput}" no catálogo. Tente outro termo.',
    },
    Error: {
      DESCRIPTION:
        "Não foi possível carregar os produtos. Tente carregar novamente.",
      BUTTON: "Recarregar",
    },
    Pagination: {
      LOADING: "Carregando",
      NO_MORE_ITEMS: "Não há mais itens para mostrar.",
      Error: {
        TRY_AGAIN: "Tentar novamente",
        ERROR_TEXT: "Não foi possível carregar os produtos.",
      },
    },
    Modes: {
      SUGGESTED: "Pedido sugerido",
      PROMOTIONS: "Promoções",
      COMBOS: "Combos",
      RECOMMENDED: "Recomendados",
    },
    CategoryStatus: {
      Error: {
        DESCRIPTION: "Não foi possível carregar as categorias.",
        BUTTON: "Tentar novamente",
      },
    },
    Search: {
      PLACEHOLDER: "Buscar por SKU ou produto",
      Filter: {
        DESCRIPTION: "Filtrar por:",
        PROMOTIONS: "Promoções",
      },
      Details: {
        SUMMARY_TEXT: "Mostrando {quantityProducts} resultados para ",
      },
    },
    DiscountDetailsDrawer: {
      SteppedDiscount: {
        TITLE: "Desconto progressivo ",
        SUB_TITLE:
          "Este produto tem desconto progressivo. Mais produtos oferecem mais desconto de acordo ocm a table abaixo. ",
        DISCOUNT_MESSAGE: "Adicione {quantity} e ganhe {discount}% de desconto",
        DISCOUNT: "Desconto",
        PRICE: "Preço",
      },
      RegularDiscount: {
        TITLE: "Desconto",
        DISCOUNT_MESSAGE: "Este produto tem {discount}% de desconto.",
      },
    },
  },
};

export default ptBR;
