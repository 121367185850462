import { useMachine } from '@xstate/react';
import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadContextProps, uploadMachine } from '../../../../machines';

interface UploadProviderProps {
  children: React.ReactNode;
  fileId?: string;
  fileName?: string;
}

const UploadContext = createContext<UploadContextProps | null>(null);

export const UploadProvider: React.FC<UploadProviderProps> = ({ children, fileId, fileName }) => {
  const navigate = useNavigate();

  const { get, post } = useRequester();
  const { toast } = useToast();

  const { user, toggles, configs } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [{ context }, send] = useMachine(uploadMachine, {
    input: {
      toast,
      toggles,
      navigate,
      filesConfig,
      api: { get, post },
      user: { name: user.name },
      file: { id: fileId, name: fileName },
    },
  });

  const value = useMemo(
    () => ({
      send,
      slow: context.slow,
      file: context.file,
      files: context.files,
      start: context.start,
      error: context.error,
      loading: context.loading,
      uploaded: context.uploaded,
      analysis: context.analysis,
      progress: context.progress,
    }),
    [
      send,
      context.slow,
      context.file,
      context.start,
      context.error,
      context.loading,
      context.uploaded,
      context.analysis,
      context.progress,
    ]
  );

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useUpload = () => useContext(UploadContext);
