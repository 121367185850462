import { Grid } from '@hexa-ui/components';
import { ThemeProvider } from '@hexa-ui/components2';
import {
  Calendar,
  DocumentSigned,
  Settings,
  Users,
  Warehouse,
} from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';

import { EnvConfig, EnvProvider } from './components';
import { useIsAdmin, useIsApprover } from './hooks/permissions';
import { IntlProvider } from './i18n';
import Router, { ROUTES } from './Router';

export default function App(props: EnvConfig) {
  const isAdmin = useIsAdmin();
  const isApprover = useIsApprover();

  useSidebar({
    items: [
      {
        id: '1',
        title: 'Bookings',
        icon: () => <Calendar />,
        path: ROUTES.HOME.path,
        visible: true,
      },
      {
        id: '2',
        title: 'Review',
        icon: () => <DocumentSigned />,
        path: ROUTES.REVIEW.path,
        visible: isAdmin || isApprover,
      },
      {
        id: '3',
        title: 'Approvers',
        icon: () => <Users />,
        path: ROUTES.APPROVERS.path,
        visible: isAdmin,
      },
      {
        id: '4',
        title: 'Configuration',
        icon: () => <Settings />,
        path: ROUTES.CONFIGURATION.path,
        visible: isAdmin,
      },
      {
        id: '5',
        title: 'Bridge line',
        icon: () => <Warehouse />,
        path: ROUTES.BRIDGE_LINE.path,
        visible: isAdmin || isApprover,
      },
    ].filter((item) => item.visible),
    utils: [],
  });

  return (
    <EnvProvider env={props}>
      <ThemeProvider>
        <IntlProvider>
          <Grid.Container type="fluid" sidebar>
            <div style={{ width: '100%' }}>
              <Router />
            </div>
          </Grid.Container>
        </IntlProvider>
      </ThemeProvider>
    </EnvProvider>
  );
}
