import { Dialog, Heading, Paragraph, SearchField, Table } from '@hexa-ui/components';

import { getFormattedMessage } from '../../utils/getFormattedMessage/getFormattedMessage';
import { bdrsWithoutVisits } from './consts/BDRs.mock';
import { ACCESSOR_BDR_ID, ACCESSOR_DATE, TEST_IDS } from './consts/BDRsWithoutVisits';
import { useModalBDRsWithoutVisits } from './hooks/useModalBDRsWithoutVisits';
import * as Styled from './ModalBDRsWithoutVisits.styles';
import { ModalBDRsWithoutVisitsProps } from './types/ModalBDRsWithoutVisitsTypes';

export const ModalBDRsWithoutVisits = ({ isOpen, onClose }: ModalBDRsWithoutVisitsProps) => {
  const {
    BDRs,
    formatMessage,
    visitDate,
    totalBDRsWithoutVisits,
    getFormattedDate,
    onSearch,
    onClear,
  } = useModalBDRsWithoutVisits({
    bdrsWithoutVisits: bdrsWithoutVisits,
  });

  return (
    <Styled.ModalContainer>
      <Dialog.Root
        title={
          <Heading size="H3">
            {formatMessage({
              id: 'Components.ModalBDRsWithoutVisits.Title.BDRS_WITHOUT_VISITS',
            })}
          </Heading>
        }
        open={isOpen}
        onClose={() => {
          onClear();
          onClose();
        }}
        data-testid={TEST_IDS.MODAL}>
        <Styled.ModalHeader>
          <Paragraph size="small" colortype="secondary" data-testid={TEST_IDS.SHOWING_RESULTS}>
            {getFormattedMessage(
              formatMessage({
                id: 'Components.ModalBDRsWithoutVisits.Label.SHOWING_RESULTS',
              }),
              { results: totalBDRsWithoutVisits },
            )}
          </Paragraph>
          <Paragraph data-testid={TEST_IDS.DATE_FILTER}>{visitDate}</Paragraph>
        </Styled.ModalHeader>
        <Styled.SearchWrapper>
          <SearchField.Root
            placeholder={formatMessage({
              id: 'Components.ModalBDRsWithoutVisits.Label.SEARCH_BY',
            })}
            size="large"
            style={{
              fontWeight: '500',
            }}
            onChange={onSearch}
            onClear={onClear}
            data-testid={TEST_IDS.SEARCH_FIELD}
          />
        </Styled.SearchWrapper>
        <Styled.TableWrapper>
          <Table
            columns={[
              {
                Header: formatMessage({
                  id: 'Components.ModalBDRsWithoutVisits.Label.BDR_ID',
                }),
                accessor: ACCESSOR_BDR_ID,
                disableSortBy: true,
                style: {
                  width: '50%',
                },
              },
              {
                Header: formatMessage({
                  id: 'Components.ModalBDRsWithoutVisits.Label.DATE_WITHOUT_VISITS',
                }),
                accessor: ACCESSOR_DATE,
                defaultSortAsc: true,
                style: {
                  width: '50%',
                },
                Cell: ({ value }) => {
                  return getFormattedDate(value);
                },
              },
            ]}
            data={BDRs}
            pagination={{
              pageSize: 5,
              quantityIndicatorIntl: formatMessage({
                id: 'Components.ModalBDRsWithoutVisits.Label.PAGINATION_INDICATION',
              }),
            }}
            emptyMessage=""
            data-testid={TEST_IDS.TABLE_BDRS_WITHOUT_VISITS}
          />
        </Styled.TableWrapper>
      </Dialog.Root>
    </Styled.ModalContainer>
  );
};
