export const AlertsType = (status: string, message?: string) => {
  const alertConfig = {
    IN_VALIDATION: {
      type: "info",
      reason:
        "This experiment's is in test version. You can either pause it or publish it to the entire selected audience.",
    },
    REJECTED: {
      type: "error",
      reason: message,
    },
  };
  return alertConfig[status];
};
