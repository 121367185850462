import { ROUTER_BASENAME } from 'config/constants';

/**
 * Technical debt: progressively add the routes in here and replace on the entire application
 * Remember: Update PermissounRoutes.constant.ts and events.constants.ts
 */
export const ROUTES = {
  HOLIDAY: `${ROUTER_BASENAME}/DDCs/Holiday`,
  AUTOBLOCK: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters`,
  AUTOBLOCK_LIST: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/List`,
  AUTOBLOCK_PROGRESS: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Progress`,
  AUTOBLOCK_CREATE: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Create`,
  AUTOBLOCK_EDIT: `${ROUTER_BASENAME}/DeliveryCapacityAvailabilityParameters/Edit/:autoblockId`,
  IMPORTS_AND_EXPORTS: `${ROUTER_BASENAME}/ImportsAndExports`,
  IMPORTS_AND_EXPORTS_RULES_UPLOAD: `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase`,
  IMPORTS_AND_EXPORTS_RULES_DOWNLOAD: `${ROUTER_BASENAME}/ImportsAndExports/DownloadRulesBase`,
  IMPORTS_AND_EXPORTS_CLIENTS_UPLOAD: `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase`,
  IMPORTS_AND_EXPORTS_CLIENTS_DOWNLOAD: `${ROUTER_BASENAME}/ImportsAndExports/DownloadClientsBase`,
  RULE_UPLOAD_FILE: `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase/UploadFile`,
  CLIENT_UPLOAD_FILE: `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase/UploadFile`,
  AUDIENCES: '/audiences?origin=deliveryAdmin',
  DDCS: `${ROUTER_BASENAME}/DDCs`,
  DDCS_EDIT: `${ROUTER_BASENAME}/DDCs/Edit`,
  DDCS_EDIT_CUSTOM_SETTINGS: `${ROUTER_BASENAME}/DDCs/Edit/CustomSettings/:audienceId?`,
  DDCS_RULES: `${ROUTER_BASENAME}/DDCs/DDCsRules`,
  DELIVERY_WINDOW: `${ROUTER_BASENAME}/DeliveryWindow`,
  CLIENT_EDIT: `${ROUTER_BASENAME}/DeliveryWindow/ClientEdit`,
  CLIENT_RULES: `${ROUTER_BASENAME}/DeliveryWindow/ClientRules`,
  SELLER_SETTINGS: `${ROUTER_BASENAME}/SellerSettings`,
};

// #region route URL params
export interface AutoblockEditParams {
  autoblockId: string;
}

export interface CustomSettingsEditParams {
  audienceId?: string;
}
// #endregion route URL params
