import {
  Badge,
  Button,
  Grid,
  LoadingDots,
  Paragraph,
  TextLink,
  Tooltip,
} from "@hexa-ui/components";
import { BarChart2, Copy, Download, EyeOn, Plus } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import CustomTable from "../../components/CustomTable/CustomTable";
import CustomToast from "../../components/CustomToast/CustomToast";
import ExportFileDialog from "../../components/ExportFileDialog/ExportFileDialog";
import CustomPageHeader from "../../components/molecules/CustomPageheader/CustomPageHeader";
import { useDialog } from "../../context/DialogContext/dialogProvider";
import { useToastConfiguration } from "../../context/ToastConfigContext/toastConfigProvider";
import { useExportExperimentQuery } from "../../hooks/queries/experiments/useExportExperimentQuery/useExportExperimentQuery";
import { useGetExperimentListQuery } from "../../hooks/queries/experiments/useGetExperimentListQuery/useGetExperimentListQuery";
import { useStopExperimentValidation } from "../../hooks/queries/experiments/useStopExperimentValidation/useStopExperimentValidation";
import useToastConfig from "../../hooks/useToast/useToastConfig";
import { ExperimentStatusObject } from "../../types/common";
import {
  ExperimentListTable,
  ExperimentStatusList,
} from "../../types/experiment";
import { useEnvProvider } from "../../utils/envProvider";
import { useCustomHistory } from "../../utils/routes";
import {
  SET_TIMEOUT_TOAST_DURATION,
  handleCopyText,
  isActionClick,
} from "../ExperimentMutation/utils";
import { StyledExperimentWrapper } from "../styles";
import useResizeObserverErrorHandler from "./../../hooks/useResizeObserverErrorHandler/useResizeObserverErrorHandler";
import { isValidExportResponse } from "./../../utils/utils";
import * as Styles from "./styles";
import {
  displayDate,
  generateJsonFile,
  hasConfidenceLevelStatus,
  mountListColumns,
  renderPeriod,
} from "./utils/utils";
import { useDecision } from "@optimizely/react-sdk";
export interface IExperimentRowElement {
  id: string;
  name: string;
  country: string;
  startDate: string;
  endDate: string;
}

export type Pagination = {
  page: number;
  pageSize: number;
  totalElements?: number;
};

const ExperimentsList = (): JSX.Element => {
  useResizeObserverErrorHandler();

  const env = useEnvProvider();
  const metricReports = useMemo(
    () => env?.env?.metricReports,
    [env?.env?.metricReports]
  );

  const { Item } = Grid;
  const intl = useIntl();
  const navigate = useCustomHistory();
  const [dataExperiment, setDataExperiment] = useState<ExperimentListTable[]>(
    [] as ExperimentListTable[]
  );

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    pageSize: 10,
    totalElements: 0,
  });

  const query = useGetExperimentListQuery({
    pageSize: pagination.pageSize,
    page: pagination.page,
  });

  const { openDialog, closeDialog } = useDialog();

  const {
    toast: toastConfig,
    handleToast: handleToastCondig,
    handleCloseToast: handleCloseToastConfig,
  } = useToastConfiguration();

  const exportQuery = useExportExperimentQuery();

  const stopExperimentValidationQuery = useStopExperimentValidation("");

  useEffect(() => {
    if (exportQuery.isSuccess) {
      generateJsonFile(exportQuery.data, exportQuery.data?.name);
      handleClose();
      handleToast(
        "File successfully exported.",
        true,
        TypeToast.SUCCESS,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.isSuccess]);

  useEffect(() => {
    if (!isValidExportResponse(exportQuery?.data)) {
      handleToast(
        "Unable to export file. Try again.",
        true,
        TypeToast.ERROR,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.isSuccess]);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { toast, handleToast, handleCloseToast } = useToastConfig();
  const handleClick = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const isValidExperimentPublishResponse = (data) => {
    if (
      data?.hasError ||
      data?.response?.name?.includes("AxiosError") ||
      data?.response?.status?.toString().startsWith("4") ||
      data?.response?.status?.toString().startsWith("5") ||
      data?.response?.code?.includes("ERR_BAD_RESPONSE")
    ) {
      return;
    }

    return true;
  };

  const handleStopExperimentValidation = (experimentId: string) => {
    stopExperimentValidationQuery.mutate(experimentId, {
      onSuccess: (data) => {
        if (!isValidExperimentPublishResponse(data)) {
          handleToastCondig({
            isOpened: true,
            type: TypeToast.ERROR,
            message: "Unable to pause test version. Try again.",
            duration: SET_TIMEOUT_TOAST_DURATION,
          });
        } else {
          handleToastCondig({
            isOpened: true,
            type: TypeToast.SUCCESS,
            message: "Test version will be paused in up to 6 hours",
            duration: SET_TIMEOUT_TOAST_DURATION,
          });
        }
        closeDialog();
        query.refetch();
      },
    });
  };

  const handleStopExperimentValidationDialog = (experimentId: string) => {
    setAnchorEl(null);
    openDialog(
      "Pause test version?",
      "",
      <Paragraph>
        <FormattedMessage id="experiment_details.modals.pause_experiment_paragrapg" />
      </Paragraph>,
      null,
      null,
      [
        {
          action: () => {
            closeDialog();
          },
          dialogActionText: "No, cancel",
          dialogActionButtonType: "secondary",
        },
        {
          action: () => {
            handleStopExperimentValidation(experimentId);
          },
          dialogActionText: "Yes, pause",
          dialogActionButtonType: "primary",
        },
      ],
      true,
      {
        width: "400px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      }
    );
  };

  useEffect(() => {
    if (query.isSuccess) {
      if (!query.data || query.data?.content?.length === 0) {
        setDataExperiment([]);
        return;
      }

      setPagination((prev) => {
        return {
          ...prev,
          totalElements: query.data.pagination.totalElements,
        };
      });
      const { content } = query.data;
      const experiments = content.map((experiment, _) => {
        const status = intl.formatMessage({
          id: `experiment_status.${experiment?.status}`,
        });
        return {
          id: experiment.id,
          name: experiment.name,
          externalKey: (
            <Tooltip
              text={intl.formatMessage({
                id: `${experiment.externalKey}`,
              })}
              placement="top"
            >
              <span>
                {experiment.externalKey.substring(0, 12)}...
                <Copy
                  onClick={(event: React.MouseEvent<SVGSVGElement>) =>
                    handleCopyText(experiment?.externalKey, event)
                  }
                  style={{
                    cursor: "pointer",
                    color: "#0363c4",
                  }}
                  size="large"
                />
              </span>
            </Tooltip>
          ),
          country: intl.formatMessage({
            id: `countries.${experiment?.country}.label`,
          }),
          startDate: displayDate(experiment?.startDate),
          endDate: displayDate(experiment?.endDate),
          period: renderPeriod(experiment),
          status: experiment.status && (
            <Badge.Status
              className="mt-2"
              key={experiment?.status as string}
              color={
                (ExperimentStatusObject[experiment?.status as string]
                  ?.color as any) ?? "info"
              }
              label={`● ${intl.formatMessage({ id: status })}`}
            />
          ),
          confidenceLevel: hasConfidenceLevelStatus(
            experiment?.status as string
          )
            ? `${experiment?.confidenceLevel ?? 0}%`
            : "-",
          action: (
            <>
              <Styles.ActionsButton
                key={`MoreHorizontal-${experiment?.id}`}
                onClick={handleClick}
                id={`${experiment?.id}`}
                data-testid={`MoreHorizontal-${experiment.id}`}
                role={`dots-${experiment.id}`}
              />
              <Styles.PopoverContainer
                key={`Popover-${experiment?.id}`}
                id={`${experiment?.id}simple-popover`}
                data-testid={`popover-${experiment?.id}`}
                open={open && anchorEl.id === experiment?.id}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handleClose}
              >
                <Styles.MenuListCss
                  data-testid={`${experiment?.id}-MenuLists`}
                  key={`MenuList-${experiment?.id}`}
                >
                  <Styles.MenuItemCss
                    key={`go-to-details-${experiment?.id}`}
                    onClick={() => goToDetails(experiment?.id)}
                    data-testid={`go-to-detailss-${experiment?.id}`}
                  >
                    <Styles.MenuItemParagraph>
                      <EyeOn />
                      <span>
                        <FormattedMessage id="experiment_list.action_list.show_details" />
                      </span>
                    </Styles.MenuItemParagraph>
                  </Styles.MenuItemCss>
                  <Styles.MenuItemCss
                    key={`export-${experiment?.id}`}
                    id={`export-${experiment?.id}`}
                    onClick={() => exportQuery.exportJson(experiment?.id)}
                  >
                    <Styles.MenuItemParagraph>
                      <Download />
                      <span>
                        <FormattedMessage id="experiment_list.action_list.export_json_file" />
                      </span>
                    </Styles.MenuItemParagraph>
                  </Styles.MenuItemCss>
                  {experiment?.status ===
                    ExperimentStatusList.IN_VALIDATION && (
                    <Styles.MenuItemCss
                      key={`pause-experiment-validation-${experiment.id}`}
                      id={`pause-experiment-validation-${experiment.id}`}
                      data-testid={`pause-experiment-validation-${experiment.id}`}
                      onClick={() =>
                        handleStopExperimentValidationDialog(experiment.id)
                      }
                    >
                      <Styles.MenuItemParagraph>
                        <Styles.PauseIcon />
                        <span>
                          <FormattedMessage id="experiment_list.action_list.pause_experiment_validation" />
                        </span>
                      </Styles.MenuItemParagraph>
                    </Styles.MenuItemCss>
                  )}
                </Styles.MenuListCss>
              </Styles.PopoverContainer>
            </>
          ),
        };
      });
      setDataExperiment(experiments as any);
    }
  }, [exportQuery.isLoading, query.data, query.isSuccess, open]);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  const goToDetails = (id: string): void => {
    navigate.goTo(`experiment/${id}`);
  };

  const [featureDecision] = useDecision("isDateInPeriodFormat", {
    autoUpdate: true,
  });

  const getDateColumns = () => {
    if (featureDecision?.enabled)
      return [
        {
          ...mountListColumns("period", "period"),
        },
      ];
    return [
      {
        ...mountListColumns("start_date", "startDate"),
      },
      {
        ...mountListColumns("estimated_end_date", "endDate"),
      },
    ];
  };

  const columns = [
    {
      ...mountListColumns("experiment_name", "name"),
    },
    {
      ...mountListColumns("external_key", "externalKey"),
    },
    {
      ...mountListColumns("status", "status"),
    },
    {
      ...mountListColumns("country", "country"),
    },
    ...getDateColumns(),
    {
      ...mountListColumns("confidence_level", "confidenceLevel"),
    },
    {
      ...mountListColumns("action", "action"),
    },
  ];

  return (
    <StyledExperimentWrapper>
      <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toast.isOpened}
        duration={toast.duration}
        onClose={handleCloseToast}
        type={toast.type}
        showCloseButton={true}
        message={toast.message}
      />

      <ExportFileDialog
        open={exportQuery.isLoading}
        title={"exporting_file"}
        messages={["this_may_take_a_few_minutes", "do_not_close_this_window"]}
        close={exportQuery.handleCancelExperimentExportRequest}
      />
      <Item
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xs={12}
        className="px-0"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomPageHeader
          translatedTitleKey="page_title.experiments_list"
          actions={
            <div style={{ display: "flex" }}>
              <TextLink
                href={`${metricReports.general}`}
                css={{ textDecoration: "none" }}
                target="_blank"
              >
                <Button
                  icon={BarChart2}
                  data-testid="button-go-to-reports"
                  size="medium"
                  type="button"
                  leading
                  className="mr-1"
                  variant="secondary"
                >
                  <FormattedMessage id="experiment_list.buttons.reports" />
                </Button>
              </TextLink>

              <Button
                icon={Plus}
                data-testid="button-create-experiment"
                size="medium"
                type="button"
                leading
                className="mr-1"
                variant="primary"
                onClick={() => navigate.goTo("new-experiment")}
              >
                <FormattedMessage id="experiment_list.buttons.create_experiment" />
              </Button>
            </div>
          }
        />

        <Item
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xs={12}
          className="px-0"
          style={{
            marginTop: "32px",
          }}
        >
          {query.isLoading ? (
            <div
              data-testid="loading-dots"
              style={{
                display: "flex",
                marginTop: 50,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LoadingDots />
            </div>
          ) : (
            <CustomTable
              search={true}
              columns={columns}
              data={dataExperiment}
              isLoading={query.isLoading}
              onRowClick={(rowData, rowClickedItem) => {
                const isClickOnAction = isActionClick(rowClickedItem);

                if (!isClickOnAction && !open) {
                  goToDetails(rowData?.id);
                }
              }}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
        </Item>
      </Item>
    </StyledExperimentWrapper>
  );
};

export default ExperimentsList;
