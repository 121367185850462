const FEATURE_TOGGLE_KEY = 'feature_toggles';

interface FeatureToggles {
  [key: string]: boolean;
}

const getFeatureToggles = (): FeatureToggles => {
  const toggles = localStorage.getItem(FEATURE_TOGGLE_KEY);
  return toggles ? JSON.parse(toggles) : {};
};

const setFeatureToggle = (feature: string, value: boolean) => {
  const toggles = getFeatureToggles();
  toggles[feature] = value;
  localStorage.setItem(FEATURE_TOGGLE_KEY, JSON.stringify(toggles));
};

const isFeatureEnabled = (feature: string): boolean => {
  const toggles = getFeatureToggles();
  return !!toggles[feature];
};

if (typeof window !== 'undefined') {
  (window as any).setFeatureToggle = setFeatureToggle;
  (window as any).isFeatureEnabled = isFeatureEnabled;
}

export { isFeatureEnabled, setFeatureToggle };
