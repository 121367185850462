import React, {useState, useRef, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useField} from 'formik';
import {SearchField, Button, Input, InputProps} from '@hexa-ui/components';
import {
  StyledContainer,
  StyledDropdown,
  StyledInput,
  StyledValueItem,
  StyledValueList,
} from './CustomSelectWithSearch.styles';

interface CustomSelectWithSearchProps extends Omit<InputProps, 'onChange'> {
  dataTestId: string;
  isCdpBrandName?: boolean;
  isProductType?: boolean;
  name: string;
  label: string;
  hint: string;
  addManuallyBtnText: string;
  optionsList: {value: string; label: string}[];
  onChange: (value: string) => void;
}

const CustomSelectWithSearch: React.FC<CustomSelectWithSearchProps> = ({
  dataTestId,
  name,
  label,
  hint,
  isCdpBrandName,
  isProductType,
  addManuallyBtnText,
  optionsList,
  onChange,
}) => {
  const {formatMessage} = useIntl();
  const [field, meta, helpers] = useField(name);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredValues, setFilteredValues] = useState(optionsList);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    setIsDropdownOpen(true);
    setFilteredValues(optionsList);
  };

  const handleValueClick = (value: string) => {
    const selectedOption = optionsList.find(option => option.value === value);
    setIsDropdownOpen(false);
    setSearchTerm('');
    setFilteredValues(optionsList);
    if (onChange) {
      onChange(value);
    }
    if (selectedOption) {
      helpers.setValue(selectedOption.label);
    }
  };

  const handleCustomValueChange = (e: {
    target: {value: React.SetStateAction<string>};
  }) => {
    setCustomValue(e.target.value);
  };

  const handleCustomValueSubmit = () => {
    if (customValue.trim()) {
      const upperCaseCustomValue = customValue.toUpperCase();
      setCustomValue('');
      setIsDropdownOpen(false);
      setSearchTerm('');
      setFilteredValues(optionsList);
      if (onChange) {
        onChange(upperCaseCustomValue);
      }
      helpers.setValue(upperCaseCustomValue);
    }
  };

  const handleClickOutside = (event: {target: any}) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setSearchTerm('');
      setCustomValue('');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (typeof query !== 'string' || query.length === 0) {
      setFilteredValues(optionsList);
      return;
    }

    const lowerCaseSearchValue = query.toLowerCase();
    const filtered = optionsList.filter(item =>
      item.label.toLowerCase().includes(lowerCaseSearchValue)
    );

    setFilteredValues(filtered);
  };

  const onSearch = (value: string) => {
    if (!value) return;

    setSearchTerm(value);
  };

  const onClearSearch = () => {
    setFilteredValues(optionsList);
    setSearchTerm('');
    if (customValue) {
      setCustomValue('');
    }
  };

  return (
    <StyledContainer ref={dropdownRef}>
      <Input
        data-testid={dataTestId}
        label={label ? formatMessage({id: label}) : ''}
        hint={hint ? formatMessage({id: hint}) : ''}
        placeholder=""
        optionalText=""
        {...field}
        {...(name as any)}
        onClick={handleInputClick}
        hasError={Boolean(meta.touched && meta.error)}
        errorText={meta.error}
        style={{textTransform: 'uppercase'}}
        width="100%"
        readOnly
      />

      {isDropdownOpen && (
        <StyledDropdown>
          {isCdpBrandName && (
            <div style={{margin: '5px 5px 20px'}}>
              <SearchField.Root
                placeholder={formatMessage({id: hint})}
                data-testid={'search-field'}
                value={searchTerm}
                size="medium"
                onChange={handleSearchChange}
                onClear={onClearSearch}
                onSearch={onSearch}
                style={{height: '30px', width: '100%'}}
              />
            </div>
          )}

          {filteredValues.length > 0 ? (
            <StyledValueList>
              {filteredValues.map(option => (
                <StyledValueItem
                  key={option.value}
                  onClick={() => handleValueClick(option.value)}
                >
                  {option.label}
                </StyledValueItem>
              ))}
            </StyledValueList>
          ) : (
            isCdpBrandName && (
              <>
                <div style={{margin: '0px 5px'}}>
                  <StyledInput
                    type="text"
                    data-testid={'custom-value-input'}
                    value={customValue}
                    onChange={handleCustomValueChange}
                    placeholder={formatMessage({id: hint})}
                    maxLength={30}
                  />
                </div>

                <Button
                  data-testid={'add-manually-btn'}
                  onClick={handleCustomValueSubmit}
                  disabled={customValue === ''}
                  style={{width: '100%', margin: '20px 0px 5px'}}
                >
                  {formatMessage({id: addManuallyBtnText})}
                </Button>
              </>
            )
          )}
        </StyledDropdown>
      )}
    </StyledContainer>
  );
};

export default CustomSelectWithSearch;
