import { RegisterAuditLogProps } from '@hooks/useAuditLog/useAuditLog.types';
import { AttributeToPatch } from '@services/common.types';

type AuditLogField = RegisterAuditLogProps['metadata']['fields'][number];

const createAuditLogField = (
  label: string,
  previous: any,
  current: any,
  id?: string
): AuditLogField => ({
  label,
  previous,
  current,
  ...(id && { id }),
});

export const generateInformationAuditLog = (
  oldObject: Record<string, any>,
  newObject: Record<string, any>
): RegisterAuditLogProps['metadata']['fields'] => {
  return Object.entries(oldObject).reduce<AuditLogField[]>((fields, [key, oldValue]) => {
    const newValue = newObject[key];
    if (String(oldValue) !== String(newValue)) {
      fields.push(createAuditLogField(key, oldValue, newValue));
    }
    return fields;
  }, []);
};

export const generateAttributesAuditLog = (
  oldFields: ClassAttribute[],
  newFields: AttributeToPatch[]
): RegisterAuditLogProps['metadata']['fields'] => {
  return oldFields.flatMap((oldField) => {
    const newField = newFields.find((field) => field.attributeId === oldField.attributeId);

    if (oldField.groupedAttributes) {
      return generateAttributesAuditLog(
        oldField.groupedAttributes,
        newField?.groupedAttributes || []
      );
    }

    const oldFieldValue = String(oldField.value ?? '');
    const newFieldValue = String(newField?.value ?? '');
    const oldFieldUom = oldField.uom ?? '';
    const newFieldUom = newField?.uom ?? '';

    if (oldFieldValue !== newFieldValue || oldFieldUom !== newFieldUom) {
      return [
        createAuditLogField(
          oldField.name,
          oldField.value !== undefined ? `${oldFieldValue}${oldFieldUom}` : undefined,
          newField?.value !== undefined ? `${newFieldValue}${newFieldUom}` : undefined,
          oldField.attributeId
        ),
      ];
    }

    return [];
  });
};
