import MessageMap from '../domains/MessageMap';

const idID: MessageMap = {
  TargetCallList: {
    TITLE: 'Daftar panggilan',
    COMPLETED_TEXT: 'Panggilan selesai',
    TARGET_TEXT: 'Target',
  },
  HomeMainPage: {
    ERROR_GET_AGENT_KPIS:
      'INFORMASI PENGGUNA TIDAK TERSEDIA - Kami tidak dapat mengakses data Anda saat ini. Harap coba lagi nanti.',
    CALL_LIST_COMPLETE: 'Daftar panggilan selesai, semua POC telah dihubungi',
    HAS_OPEN_CALL: 'Lanjutkan klien terakhir',
    GET_NEXT_POC: 'Pergi ke POC berikutnya',
    UPDATE_CARD_BUTTON: 'Perbarui',
    MONTHLY_RESULTS: 'Hasil bulanan',
    DAILY_RESULTS: 'Hasil harian',
    NO_SEGMENTATION:
      'Hubungi administrator/supervisor Anda untuk mengaitkan segmentasi dengan profil Anda.',
  },
  HitRate: {
    TITLE: 'Tingkat keberhasilan',
    COMPLETED_TEXT: 'Panggilan yang efektif',
    TARGET_TEXT: 'Total panggilan',
  },
  AverageHandleTime: {
    TITLE: 'AHT',
    COMPLETED_TEXT: 'Durasi waktu panggilan',
    TARGET_TEXT: 'Target',
    TIME: 'menit',
  },
  ToplineUplift: {
    TITLE_CD: 'Peningkatan topline',
    COMPLETED_TEXT: 'Peningkatan',
    TARGET_TEXT: 'Target',
    TOPLINE_TOTAL_GAP: 'Total gap',
  },
  ErrorHandling_KPI: {
    ERROR_UPDATE_KPI: 'Kami tidak dapat mengakses data Anda saat ini. Harap coba lagi nanti.',
  },
  Search_Component: {
    CLEAR_SEARCH_BUTTON: 'Bersihkan pencarian',
    ITEMS_FOUND_LABEL: 'Item ditemukan',
    PLACEHOLDER: 'Cari berdasarkan POC',
  },
  NPS: {
    NPS_TITLE: 'Skor Promotor Bersih (NPS)',
    NPS_ERROR: 'NPS tidak dapat dimuat. Harap coba lagi segera.',
    NPS_CLOSE: 'Jawab nanti',
    NPS_SUBMIT: 'Kirim',
  },
  TargetProducts: {
    TARGET_PRODUCTS: 'PRODUK TARGET',
    UNIT_PRICE: 'Harga (Unit)',
    NOT_FOUND: 'Tidak ada item untuk ditampilkan',
  },
  TotalPerformance: {
    TITLE: 'Kinerja total',
    EFFECTIVE_CALLS: 'Panggilan yang efektif',
    TOTAL_CALLS: 'Total panggilan',
    REVENUE_UPLIFTED: 'Peningkatan pendapatan bersih',
    TARGET_UPLIFTED: 'Target',
  },
  MISSIONS_MANAGEMENT: {
    LOCAL_MISSION: 'Misi lokal',
    NEW_MISSION: 'Buat misi',
    TITLE: 'Manajemen misi',
    PRIMARY_TAB_LABEL: 'Misi utama',
    SECONDARY_TAB_LABEL: 'Misi sekunder',
    APPLY_CHANGES_BUTTON: 'Terapkan perubahan',
    REWARDSCHALLENGES_TITLE: 'Tantangan hadiah',
    PRIMARY_MISSION_SPONSORED_TITLE: 'Disponsori',
    PRIMARY_MISSION_SERVICES_TITLE: 'Layanan',
    LOCAL_MISSIONS_TITLE: 'Misi lokal',
    TOPLINEGAP_DESCRIPTION:
      'Pahami di mana gap pelanggan dan rekomendasikan produk untuk membantu mereka berkembang.',
    MARKETPLACE_DESCRIPTION: 'Tawarkan pembelian produk marketplace.',
    LOCAL_MISSIONS_DESCRIPTION:
      'Misi yang disesuaikan yang dibuat oleh tim back office operasional.',
    REWARDSCHALLENGES_DESCRIPTION:
      'Anjurkan pelanggan untuk menerima dan melakukan tantangan yang tersedia untuk mendapatkan poin.',
    DIGITAL_SERVICER_DESCRIPTION: 'Tawarkan layanan baru yang tersedia untuk pelanggan kami.',
    PRIMARY_MISSIONS_OBS:
      'Misi utama akan menentukan POC di daftar panggilan dan urutannya.\n\nPilih misi aktif dan ubah urutan prioritasnya dengan menarik kartu.',
    SECONDARY_MISSIONS_OBS:
      'Misi sekunder akan ditampilkan ke POC yang sudah ada di daftar panggilan jika mereka memenuhi kriteria misi. Pilih misi aktif.',
    ACTIVE: 'Aktif',
    INACTIVE: 'Tidak aktif',
    PRIMARY_MISSION_CARD_TYPE: 'Misi utama',
    SECONDARY_MISSION_CARD_TYPE: 'Misi sekunder',
    SECONDARY_MISSION_QUICKORDER: 'Pesanan cepat',
    SECONDARY_MISSION_REWARDSREDEMPTION: 'Tukar hadiah',
    SECONDARY_MISSION_TOPLINEGAP: 'Gap topline',
    SECONDARY_MISSION_REWARDSCHALLENGES: 'Tantangan hadiah',
    BUTTONS_AREA_ANYCHANGES_TEXT: 'Perubahan yang dilakukan hanya akan berlaku besok.',
    BUTTONS_AREA_DISCARDCHANGES_BUTTON: 'Buang perubahan',
    BUTTONS_AREA_SAVECHANGES_BUTTON: 'Simpan perubahan',
    APPLY_CHANGES_MODAL_TITLE: 'Apakah Anda yakin ingin menerapkan perubahan?',
    APPLY_CHANGES_MODAL_DESCRIPTION:
      'Hanya membutuhkan beberapa menit untuk mengaktifkan perubahan Anda.',
    APPLY_CHANGES_MODAL_DESCRIPTION_SECONDARY: 'Perubahan akan diterapkan besok.',
    APPLY_CHANGES_CONFIRM: 'Ya, terapkan',
    APPLY_CHANGES_CANCEL: 'Tidak, batalkan',
    DISCARD_CHANGES_MODAL_TITLE: 'Apakah Anda yakin ingin meninggalkan?',
    DISCARD_CHANGES_MODAL_DESCRIPTION: 'Perubahan yang Anda buat pada manajemen misi akan hilang.',
    DISCARD_CHANGES_CONFIRM: 'Ya, tinggalkan',
    DISCARD_CHANGES_CANCEL: 'Tidak, batalkan',
    DISCARD_CHANGES_TAB_MODAL_TITLE: 'Apakah Anda yakin ingin beralih tab?',
    DISCARD_CHANGES_TAB_MODAL_DESCRIPTION: 'Perubahan yang Anda buat pada tab ini akan hilang.',
    DISCARD_CHANGES_TAB_CONFIRM: 'Ya, beralih',
    DISCARD_CHANGES_TAB_CANCEL: 'Tidak, batalkan',
    EDIT_TEXT: 'Edit',
    ERROR_TO_APPLY_CHANGES: 'Ada yang salah! Perubahan tidak disimpan, coba terapkan lagi.',
    LOADING_TEXT: 'Memproses...',
    SUCCESS_UPDATE_MISSIONS: 'Perubahan berhasil diterapkan.',
    ERROR_UPDATE_MISSIONS_ALL_DISABLED: 'Anda harus memiliki setidaknya 1 misi utama yang aktif.',
    ERROR_TO_LOAD_PRIMARY_MISSIONS: 'Tidak mungkin memuat misi utama.',
    ERROR_TO_LOAD_SECONDARY_MISSIONS: 'Tidak mungkin memuat misi sekunder.',
    ERROR_UPDATE_MISSIONS_INVALID_FIRST_POSITION:
      'Perubahan tidak dapat disimpan. Gap topline atau Marketplace harus berada di posisi pertama dalam daftar.',
    SECONDARY_WARNING_TOAST: 'Perubahan pada misi sekunder hanya akan berlaku besok!',
    PRIMARY_MISSION_SERVICES_SWITCH_HOVER:
      'Misi ini membutuhkan eksklusivitas agen. Pergi ke pengaturan agen untuk mengaktifkannya untuk agen tertentu.',
    SPONSORED_MISSIONS: 'Misi yang disponsori',
    NEW_SPONSORED_MISSIONS: 'Misi yang disponsori baru',
    NEW_SPONSORED_MISSION_FIRST_STEP: 'Detail utama',
    NEW_SPONSORED_MISSION_SECOND_STEP: 'Konten',
    NEW_SPONSORED_MISSION_THIRD_STEP: 'Pengaturan agen',
    NEW_SPONSORED_MISSION_FOURTH_STEP: 'Daftar panggilan',
    SERVICES_MISSIONS: 'Misi layanan',
    BACK_BUTTON: 'Kembali',
    FINISH_BUTTON: 'Selesai',
    NEXT_BUTTON: 'Berikutnya',
    CANCEL_BUTTON: 'Batalkan',
    PROCESSING_BUTTON: 'Memproses...',
    NEW_SPONSORED_MISSION_TITLE: 'Judul misi',
    NEW_SPONSORED_MISSION_TITLE_EXEMPLE: 'mis: Michelob Ultra Longneck Baru',
    NEW_SPONSORED_SPONSOR_PARTNER: 'Mitra sponsor',
    NEW_SPONSORED_SPONSOR_PARTNER_EXEMPLE: 'mis: Diageo',
    NEW_SPONSORED_START_DATE: 'Tanggal mulai',
    NEW_SPONSORED_END_DATE: 'Tanggal akhir',
    NEW_SPONSORED_MANDATORY_FIELD: 'Kolom ini diperlukan.',
    NEW_SPONSORED_MANDATORY_CHAR: 'Judul misi tidak dapat mengandung karakter khusus ini.',
    NEW_SPONSORED_FIRST_STEP_INVALID_END_DATE:
      'Tanggal akhir harus lebih lambat dari tanggal mulai.',
    NEW_SPONSORED_FIRST_STEP_INVALID_CHARACTER:
      'Kami hanya mendukung huruf, angka, dan tanda hubung.',
    NEW_SPONSORED_FIRST_STEP_INVALID_TITLE: 'Sudah ada misi dengan judul ini, coba judul lain.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_LABEL_TITLE: 'Alokasi tim',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_PLACEHOLDER: 'Masukkan spesialisasi',
    NEW_SPONSORED_THIRD_STEP_INVALID_CHARACTER:
      'Kami hanya mendukung huruf, angka, dan tanda hubung.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_MAX_LENGTH:
      'Kami hanya mendukung hingga 30 karakter.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_HINT:
      'Agen yang akan melaksanakan misi adalah mereka yang memiliki spesialisasi yang sama seperti yang ditentukan di atas. Untuk menetapkan spesialisasi ke agen, Anda perlu mengakses pengaturan Agen.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_TOOLTIP:
      'Di halaman pengaturan agen, Anda memilih agen dan kemudian menetapkan spesialisasi. Spesialisasi yang sama ini harus dimasukkan di halaman ini.\n\nAnda dapat menetapkan semua agen untuk melaksanakan misi ini, untuk itu Anda harus menetapkan semuanya dengan spesialisasi yang sama.',
    NEW_SPONSORED_CANT_CHANGE_STEP: 'Anda tidak bisa melanjutkan ke langkah berikutnya sekarang.',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT1: 'Seret misi ke',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT2_BOLD: ' urutkan',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT3: ' dan',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT4_BOLD: ' prioritaskan',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT5: ' sesuai kebutuhan Anda.',
    NEW_MISSIONS_MANAGEMENT_ERROR_UPDATE_MISSIONS_ALL_DISABLED:
      'Anda harus memiliki setidaknya 1 misi yang aktif.',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_TITLE: 'Buang perubahan?',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Perubahan akan dibuang segera, dan tindakan ini tidak dapat dibatalkan.',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CONFIRM: 'Ya, buang',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CANCEL: 'Batalkan',
    NEW_MISSIONS_MANAGEMENT_ERROR_TO_LOAD_MISSIONS: 'Tidak mungkin memuat misi.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING: 'Parameter',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING_SUBTEXT:
      'Ini adalah parameter utama dan batasan misi yang ditetapkan untuk unit bisnis Anda.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_TITLE: 'Bantuan',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_SUBTITLE: 'Mengubah parameter',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_TEXT:
      'Untuk mengubah parameter, hubungi tim bisnis yang bertanggung jawab.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_SUBTITLE: 'Melebihi batas',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_TEXT:
      'Ketika batas terlampaui, misi prioritas rendah mungkin tidak ditampilkan kepada agen.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FOOTER_TEXT:
      'Untuk memastikan misi akan ditampilkan, buatlah menjadi prioritas utama.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TITLE: 'Batas misi bersama',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TEXT:
      'Jumlah maksimum misi yang dapat dilaksanakan oleh setiap agen, yang membantu menjaga efektivitas misi.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALMISSIONS: 'Misi global',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALTOOLTIP:
      'Topline Gap, Marketplace, Tantangan Hadiah, dan Penukaran Hadiah',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALMISSIONS: 'Misi lokal',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALTOOLTIP:
      'Misi lokal dengan prioritas tertinggi adalah yang akan ditugaskan kepada agen.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TOTAL: 'Total',
    TAG_INPUT_LABEL_FIELD_TYPE: '(Opsional)',
    TAG_INPUT_PLACEHOLDER: 'Misal: PortfolioExpansion, BeyondBeer',
  },
  LOCAL_MISSIONS_FILTER: {
    LABEL: 'Filter',
    DATE_ERROR: 'Masukkan tanggal yang valid',
    CLEAR_ALL: 'Hapus semua',
    CANCEL: 'Batalkan',
    APPLY: 'Terapkan',
    SPONSORED: 'Tampilkan hanya yang disponsori',
    STATUS_WARNING:
      'Misi yang dijeda, kedaluwarsa, dan selesai disimpan dalam riwayat selama 90 hari setelah mencapai periode durasinya.',
  },
  LOCAL_MISSIONS: {
    TABLE_DESCRIPTION:
      'Buat misi untuk kontak dan periode waktu tertentu, mengikuti parameter Anda.',
    CREATE_NEW_MISSION: 'Buat misi baru',
    ACTIVE: 'Aktif',
    ALL: 'Semua misi',
    TABLE_START: 'Mulai',
    TABLE_END: 'Akhir',
    TABLE_PERIOD: 'Periode',
    TABLE_IDENTIFICATION: 'Identifikasi',
    TABLE_TYPE: 'Tipe',
    TABLE_TYPE_THRIVE: 'Berkembang',
    TABLE_TYPE_COVERAGE: 'Cakupan',
    TABLE_TYPE_SIMPLE: 'Sederhana',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Prioritas',
    TABLE_SCHEDULED: 'Terjadwal',
    TABLE_ONGOING: 'Sedang berlangsung',
    TABLE_CONCLUDED: 'Selesai',
    TABLE_EXPIRED: 'Kedaluwarsa',
    TABLE_PAUSED: 'Dijeda',
    TABLE_CATEGORY: 'Kategori',
    TABLE_OTHERS: 'Lainnya',
    TOAST_SAVE_SUCCESS: 'Perubahan berhasil disimpan.',
    TOAST_SAVE_ERROR: 'Perubahan gagal disimpan.',
    TOAST_PRIORITY_EDIT_SUCCESS_BODY: 'Prioritas misi berhasil diubah dari',
    TOAST_PRIORITY_EDIT_SUCCESS_PREPOSITION: 'ke',
    TOAST_PRIORITY_EDIT_SUCCESS_FINAL: 'dan akan berlaku besok',
    TOAST_PRIORITY_EDIT_ERROR: 'Prioritas misi gagal diubah. Coba lagi.',
    NOT_RESULTS_FOUND: 'Tidak ada hasil ditemukan',
    NOT_RESULTS_DESCRIPTION:
      'Coba cari lagi dengan nama atau tag misi yang berbeda atau menggunakan filter lainnya.',
    TITLE_FIRST_STEP: 'Utama',
    TITLE_SECOND_STEP: 'Konten',
    TITLE_THIRD_STEP: 'Audiens',
    TITLE_FOURTH_STEP: 'Tim',
    MISSION_TYPE_TITLE: 'Tipe',
    MISSION_TYPE_COVERAGE: 'Cakupan',
    MISSION_TYPE_SIMPLE: 'Sederhana',
    MISSION_TYPE_SURVEY: 'Survei',
    MISSION_TYPE_SERVICES: 'Layanan',
    MISSION_CATEGORY: 'Kategori',
    MISSION_CATEGORY_DIGITAL_EDUCATION: 'Pendidikan Digital',
    MISSION_CATEGORY_VALUE_CREATION: 'Penciptaan Nilai',
    STEP_FOUR_TEAM_ALLOCATION_TITLE: 'Alokasi tim',
    STEP_FOUR_SEGMENTATION_LABEL_TITLE: 'Segmentasi',
    STEP_FOUR_SEGMENTATION_INPUT_PLACEHOLDER: 'Masukkan nama segmentasi',
    STEP_FOUR_SEGMENTATION_DESCRIPTION:
      'Tambahkan segmentasi untuk menetapkan kolaborator yang akan melaksanakan misi ini.',
    TIME_PERIOD_TITLE: 'Periode waktu',
    TIME_PERIOD_SUBTITLE: 'Tentukan tanggal mulai dan akhir.',
    SPONSOR_TITLE: 'Sponsor',
    SPONSOR_OPTIONAL_TAG: '(Opsional)',
    SPONSOR_SUBTITLE: 'Mitra yang mensponsori misi ini.',
    SPONSOR_PLACEHOLDER: 'Masukkan nama mitra sponsor',
    ERROR_TO_LOAD_LOCAL_MISSIONS: 'Tidak dapat memuat misi lokal',
    SPONSOR_CHECKBOX_TITLE: 'Misi yang disponsori',
    SPONSOR_CHECKBOX_SUBTITLE: 'Ada mitra yang mensponsori misi ini',
    SPONSOR_CHECKBOX_PLACEHOLDER: 'Masukkan nama sponsor',
    SPONSOR_CHECKBOX_ERROR: 'Anda perlu menambahkan nama sponsor',
    SPONSOR_DROPDOWN_PLACEHOLDER: 'Pilih sponsor',
    SPONSOR_DROPDOWN_ERROR: 'Anda perlu memilih sponsor',
    SPONSOR_DROPDOWN_REQUEST_ERROR: 'Tidak dapat memuat sponsor.',
    DUPLICATE_TITLE: 'Duplikasi misi',
    DUPLICATE_BUTTON: 'Duplikasi',
    DUPLICATE_CANCEL_BUTTON: 'Batalkan',
    DELETE_BUTTON: 'Hapus',
    DELETE_MODAL_TITLE: 'Hapus misi ini?',
    DELETE_MODAL_DESCRIPTION: 'Misi akan dihapus segera, dan tindakan ini tidak dapat dibatalkan.',
    DELETE_MODAL_DELETE_BUTTON: 'Ya, hapus',
    DELETE_MODAL_CANCEL_BUTTON: 'Kembali',
    DELETE_MODAL_TOAST_SUCCESS: 'Misi berhasil dihapus.',
    DELETE_MODAL_TOAST_ERROR: 'Tidak dapat menghapus misi ini.',
    EXPORT_BUTTON: 'Ekspor laporan',
    DUPLICATE_MANDATORY_FIELD: 'Kolom ini diperlukan.',
    DUPLICATE_MANDATORY_CHAR: 'Judul misi tidak dapat mengandung karakter khusus ini.',
    DUPLICATE_INVALID_END_DATE: 'Tanggal akhir harus lebih lambat dari tanggal mulai.',
    DUPLICATE_INVALID_CHARACTER: 'Kami hanya mendukung huruf, angka, dan tanda hubung.',
    DUPLICATE_REQUIRED_LIST_ITEM: 'Harus menambahkan setidaknya satu item ke dalam daftar',
    DUPLICATE_SEGMENTATION_INPUT_MAX_LENGTH: 'Kami hanya mendukung maksimal 30 karakter.',
    DUPLICATE_DIGITALIZATION_MANDATORY: 'Harus memilih setidaknya satu tingkat.',
    DUPLICATE_CSV_REQUIRED: 'Mengunggah file diperlukan.',
    DUPLICATE_DISCARD_CHANGES_MODAL_TITLE: 'Buang perubahan?',
    DUPLICATE_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Perubahan akan dibuang segera, dan tindakan ini tidak dapat dibatalkan.',
    DUPLICATE_MODAL_DISCARD_BUTTON: 'Ya, buang',
    DUPLICATE_MODAL_CANCEL_BUTTON: 'Batalkan',
    MISSION_DETAILS_TITLE: 'Detail misi',
    MISSION_DETAILS_LABEL_CONTENT_NONE_TEXT: 'Tidak ada',
    MISSION_DETAILS_MAIN_SECTION_TITLE: 'Utama',
    MISSION_DETAILS_MAIN_SECTION_ACTIVATION_LABEL: 'Aktivasi',
    MISSION_DETAILS_MAIN_SECTION_STATUS_LABEL: 'Status',
    MISSION_DETAILS_MAIN_SECTION_TYPE_LABEL: 'Tipe',
    MISSION_DETAILS_MAIN_SECTION_CATEGORY_LABEL: 'Kategori',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_LABEL: 'Sponsor',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_VALUE_NOT_SPONSORED: 'Tidak ada (tidak disponsori)',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_LABEL: 'Periode',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_TO_TEXT: 'ke',
    MISSION_DETAILS_MAIN_SECTION_TAGS_LABEL: 'Tag',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_LABEL: 'Dibuat pada',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_BY_TEXT: 'Oleh',
    MISSION_DETAILS_MAIN_SECTION_STATUS_ON_TEXT: 'Aktif',
    MISSION_DETAILS_MAIN_SECTION_STATUS_OFF_TEXT: 'Nonaktif',
    MISSION_DETAILS_TEAM_SECTION_TITLE: 'Tim',
    MISSION_DETAILS_TEAM_SECTION_SEGMENTATION_LABEL: 'Segmentasi',
    MISSION_DETAILS_CONTENT_SECTION_TITLE: 'Konten',
    MISSION_DETAILS_CONTENT_SECTION_SCRIPT_LABEL: 'Skrip',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_LABEL: 'Kriteria efektivitas',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_QUANTITY_LABEL: 'Minimal total',
    MISSION_DETAILS_AUDIENCE_SECTION_TITLE: 'Audiens',
    MISSION_DETAILS_AUDIENCE_SECTION_CSV_PREVIEW_DOWNLOAD_BUTTON: 'Unduh',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_TITLE: 'Filter',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_BLOCKLIST: 'Daftar blokir',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_DIGITALIZATION_LEVEL: 'Tingkat digitalisasi',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_NO_DIGITALIZATION: 'Tanpa digitalisasi',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_INITIAL_ADOPTION: 'Adopsi awal',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_ENGAGED: 'Terlibat',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_FULL_DIGITAL: 'Full digital',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL: 'Kapan menghubungi',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL_DELIVERY_WINDOW: 'Jendela pengiriman',
    MISSION_DETAILS_AUDIENCE_SECTION_STOP_CALLING_WHEN: 'Berhenti menelepon ketika',
    EDIT_BUTTON_TOOLTIP: 'Anda hanya dapat mengedit misi yang belum dimulai',
    PLACEHOLDER_LOCAL_MISSIONS: 'Cari berdasarkan nama atau tag misi',
    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Aktifkan misi lokal di manajemen Misi, sehingga agen dapat melaksanakan misi ini.',
    NO_SPONSOR_FOUND: 'Tidak ada sponsor ditemukan',
  },
  SPONSORED_MISSIONS: {
    SAVE: 'Simpan',
    NO_ACTIVE_MISSIONS_YET: 'Belum ada misi aktif yang dibuat',
    NO_MISSIONS_YET: 'Belum ada misi yang dibuat.',
    YOU_CAN: 'Anda bisa',
    CREATE_NEW_MISSIONS: 'membuat misi baru',
    ARTICLE: 'Artikel',
    MISSION_ACTIVATION_SUCCESS_MESSAGE: 'misi berhasil diaktifkan, dan akan berlaku besok.',
    MISSION_INACTIVATION_SUCCESS_MESSAGE: 'misi berhasil dinonaktifkan, dan akan berlaku besok.',
    MISSION_ACTIVATION_ERROR_MESSAGE: 'misi tidak bisa diaktifkan.',
    MISSION_INACTIVATION_ERROR_MESSAGE: 'misi tidak bisa dinonaktifkan.',
    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Aktifkan misi yang disponsori di manajemen Misi, sehingga agen bisa melaksanakan misi ini.',
    ERROR_TO_LOAD_SPONSORED_MISSIONS: 'Tidak dapat memuat misi yang disponsori.',
    MISSION_TYPE: 'Tipe misi',
    PACKAGE_TYPE_LABEL: 'Pengemasan',
    PACKAGE_TYPE_BOX: 'Kotak',
    PACKAGE_TYPE_UNIT: 'Unit',
    PACKAGE_TYPE_DISPLAY: 'Display',
    STEP_TWO_LIST_IMG: 'IMG',
    STEP_TWO_LIST_NAMEANDCODE: 'NAMA DAN KODE',
    STEP_TWO_LIST_DESCRIPTION: 'Deskripsi',
    STEP_TWO_LIST_MIN_QUANTITY: 'Kuantitas minimal',
    STEP_TWO_LIST_SUGGESTEDQUANTITY: 'KUANTITAS YANG DISARANKAN',
    STEP_TWO_NOPRODUCTS: 'Belum ada produk yang ditambahkan.',
    STEP_TWO_LIST_ADDPRODUCTS: 'Anda dapat menggunakan kolom di atas untuk menambahkannya',
    STEP_TWO_ERROR_GET_ITEM: 'Item tidak dapat ditambahkan sekarang.',
    STEP_TWO_PRODUCTS_AND_COMBOS: 'Produk dan Combo',
    STEP_TWO_PRODUCTS_AND_COMBOS_PLACEHOLDER: 'Ketik kode produk atau combo...',
    STEP_TWO_PRODUCTS_AND_COMBOS_SUBTITLE:
      'Item dan kuantitas yang disarankan untuk ditawarkan dalam misi.',
    STEP_TWO_PRODUCTS_AND_COMBOS_NEW_SUBTITLE: 'Daftar item yang akan ditawarkan dalam misi ini.',
    STEP_TWO_NO_ITEMS_FOUND: 'Tidak ada item yang ditemukan dengan kode ini.',
    STEP_TWO_ITEM_ALREADY_ADDED: 'Item yang Anda coba tambahkan sudah ada dalam daftar',
    STEP_TWO_ITEM_REQUIRED: 'Harus menambahkan setidaknya satu item ke dalam daftar',
    STEP_TWO_MAXIMUM_REACHED: 'Item tidak bisa ditambahkan karena batas maksimum telah tercapai.',
    STEP_TWO_SCRIPT_TITLE: 'Skrip',
    STEP_FIELD_OPTIONAL: '(opsional)',
    STEP_TWO_SCRIPT_HINT: 'Tentukan instruksi langkah demi langkah untuk agen.',
    STEP_TWO_SCRIPT_NEW_HINT: 'Tentukan skrip singkat untuk pidato agen.',
    STEP_TWO_EFFECTIVENESS_TITLE: 'Kriteria efektivitas',
    STEP_TWO_EFFECTIVENESS_SUBTITLE: 'Kondisi agar misi ini dianggap berhasil setelah selesai.',
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_TITLE: 'Minimal total',
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_SUBTITLE:
      'Memerlukan penjualan kuantitas minimal dari salah satu item.',
    STEP_TWO_EFFECTIVENESS_AT_LEAST_QUANTITY_INPUT_TITLE: 'Minimal total',
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_TITLE: 'Minimal per item',
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_SUBTITLE:
      'Memerlukan penjualan kuantitas minimal dari setiap item.',
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_TITLE: 'Pemeriksaan agen',
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_SUBTITLE:
      'Memerlukan agen untuk menandai misi sebagai selesai.',
    STEP_TWO_COMBO_CHIP: 'Combo',
    STEP_TWO_FREE_GOOD_CHIP: 'Barang gratis',
    STEP_FOUR_POCS: 'POC',
    STEP_FOUR_POCS_UPDATED: 'Daftar POC',
    STEP_FOUR_CSV_TITLE: 'Unggah file CSV',
    STEP_FOUR_CSV_SUBTITLE: 'atau seret dan lepas di sini',
    STEP_FOUR_CSV_TEMPLATE: 'Gunakan file CSV dari template kami.',
    STEP_FOUR_CSV_DOWNLOAD_TEMPLATE: 'Unduh template',
    STEP_FOUR_POC_FILTER_TITLE: 'Filter',
    STEP_FOUR_POC_FILTER_DESCRIPTION:
      'Pilih filter untuk mendefinisikan POC yang memenuhi syarat untuk misi ini.',
    STEP_FOUR_POC_FILTER_DESCRIPTION_UPDATED: 'Saring POC untuk misi ini.',
    STEP_FOUR_DELIVERY_WINDOW_TITLE: 'Jendela pengiriman',
    STEP_FOUR_DELIVERY_WINDOW_DESCRIPTION:
      'POC hanya akan dihubungi pada hari dengan waktu pengiriman terbaik.',
    STEP_FOUR_BLOCK_LIST_TITLE: 'Daftar blokir',
    STEP_FOUR_BLOCK_LIST_DESCRIPTION: 'POC dalam daftar blokir tidak akan dihubungi.',
    STEP_FOUR_BLOCK_LIST_DESCRIPTION_DISABLED: 'Pengaturan ini ditentukan oleh unit bisnis Anda.',
    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE: 'Pilih tingkat digitalisasi',
    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE_UPDATED: 'Tingkat digitalisasi',
    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION:
      'Kelompok yang dipilih akan dihubungi. POC dibagi berdasarkan persentase pembelian melalui BEES.',
    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION_UPDATED:
      'Kelompok dibagi berdasarkan persentase pembelian melalui BEES.',
    STEP_FOUR_DIGITALIZATION_TITLE: 'Digitalisasi',
    STEP_FOUR_DIGITALIZATION_TITLE_UPDATED: 'Tanpa digitalisasi',
    STEP_FOUR_DIGITALIZATION_MANDATORY: 'Harus memilih setidaknya satu tingkat.',
    STEP_FOUR_ADOPTION_TITLE: 'Adopsi',
    STEP_FOUR_ADOPTION_TITLE_UPDATED: 'Adopsi awal',
    STEP_FOUR_ENGAGEMENT_TITLE: 'Keterlibatan',
    STEP_FOUR_ENGAGEMENT_TITLE_UPDATED: 'Terlibat',
    STEP_FOUR_FULL_DIGITAL_TITLE: 'Full digital',
    STEP_FOUR_FULL_DIGITAL_TITLE_UPDATED: 'Full digital',
    STEP_FOUR_CSV_REQUIRED: 'Mengunggah file diperlukan.',
    STEP_FOUR_CSV_ONLY: 'Kami hanya mendukung file CSV.',
    STEP_FOUR_CSV_MAX_SIZE: 'Kami mendukung file dengan ukuran maksimal 1MB.',
    STEP_FOUR_CSV_MAX_SIZE_2MB: 'Kami mendukung file dengan ukuran maksimal 2MB.',
    SUCCESS_CREATE_MISSION: 'Misi berhasil dibuat, dan akan berlaku besok.',
    ERROR_CREATE_MISSION: 'Misi tidak dapat dibuat.',
    WARNING_CREATE_MISSION:
      'Aktifkan misi yang disponsori di manajemen Misi, sehingga agen bisa melaksanakan misi ini.',
    ERROR_TEMPLATE_CSV: 'File tidak dapat diunggah karena tidak mengikuti format template.',
    EXPIRED_MISSION_SWITCH_HOVER: 'Tidak mungkin mengaktifkan misi yang kedaluwarsa.',
    SPONSORED_JUST_TOMORROW: 'Perubahan pada misi yang disponsori hanya akan berlaku besok!',
    SPONSORED_EXPIRED_STARTED: 'Tidak mungkin mengedit misi yang sudah dimulai atau kedaluwarsa.',
    EDIT_SPONSORED_MISSIONS: 'Edit misi yang disponsori',
    EDIT_MISSION: 'Edit misi',
    SAVE_EDIT_SPONSORED_MISSION_ERROR: 'Perubahan tidak dapat disimpan.',
    SAVE_EDIT_SPONSORED_MISSION_SUCESS: 'Perubahan berhasil disimpan.',
    EDIT_SPONSORED_DOWNLOAD_CSV_FILE: 'Unduh',
    STEP_FOUR_GET_STARTED: 'Mulai dengan mengisi file template kami.',
    STEP_FOUR_TEMPLATE_FILE: 'file template.',
    STEP_FOUR_UPLOAD_FILE: 'Unggah file',
    STEP_FOUR_DRAG_AND_DROP: 'atau seret dan lepas di sini',
    STEP_FOUR_CSV_MAX_1MB: 'CSV (maks. 1MB)',
    STEP_FOUR_CSV_MAX_2MB: 'CSV (maks. 2MB)',
    STEP_FOUR_WHEN_TO_CALL: 'Kapan menghubungi',
    STEP_FOUR_WHEN_TO_CALL_DESCRIPTION: 'Waktu terbaik untuk menghubungi POC.',
    STOP_CALLING_WHEN_LABEL: 'Berhenti menelepon ketika',
    STOP_CALLING_SELECT_OPTION: 'Pilih opsi',
    STOP_CALLING_FIRST_CONTACT_OPTION: 'Kontak pertama dibuat',
    STOP_CALLING_EFFECTIVENESS_OPTION: 'Kriteria efektivitas telah tercapai',
    OVERRIDE_CSV_ALERT: 'Segmentasi POC akan diganti dengan yang Anda tentukan.',
    DISCARD_CHANGES_BUTTON: 'Buang perubahan',
    EDIT_DISCARD_CHANGES_MODAL_TITLE: 'Buang perubahan?',
    EDIT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Perubahan akan dibuang segera, dan tindakan ini tidak dapat dibatalkan.',
    EDIT_MODAL_DISCARD_BUTTON: 'Ya, buang',
    EDIT_MODAL_CANCEL_BUTTON: 'Batalkan',
  },
  SERVICES_MISSION: {
    PAGE_TITLE: 'Misi layanan',
    TITLE: 'Skrip',
    SUBTITLE: 'Tentukan instruksi langkah demi langkah untuk agen.',
    BTN_TITLE: 'Simpan perubahan',
    CONFIRMATION_MESSAGE_OK: 'Perubahan berhasil disimpan!',
    CONFIRMATION_MESSAGE_ERROR: 'Perubahan tidak dapat disimpan sekarang.',
    TEXTAREA_PLACEHOLDER:
      '1. Perkenalkan alasan panggilan;\r\n2. Tanyakan bagaimana kabar pelanggan dan coba pahami kebutuhan saat ini;\r\n3. Tawarkan layanan yang tersedia;',
    BTN_ADD_SERVICE: 'Tambahkan Layanan',
    SERVICES: 'Layanan',
    BTN_CANCEL: 'Membatalkan',
    BTN_DELETE: 'Hapus',
    CONFIRMATION_MODAL_TITLE: 'Hapus Layanan?',
    CONFIRMATION_MODAL_MESSAGE:
      'Layanan ini tidak akan muncul lagi untuk agen, tetapi semua catatan akan tetap disimpan. Tindakan ini hanya akan berlaku besok dan tidak bisa dibatalkan.',
    CONFIRMATION_MESSAGE_DELETE_OK: 'Layanan berhasil dihapus',
    CONFIRMATION_MESSAGE_DELETE_ERROR: 'Layanan tidak dapat dihapus sekarang',
  },
  SERVICES_MISSION_FORM: {
    EDIT_TITLE: 'Edit layanan',
    TITLE: 'Tambahkan layanan',
    ICON_LABEL: 'Ikon',
    TITLE_LABEL: 'Judul',
    TITLE_PLACEHOLDER: 'contoh: Pembayaran tagihan',
    AVAILABILITY_LABEL: 'Ketersediaan',
    AVAILABILITY_PLACEHOLDER: 'Layanan ini diaktifkan.',
    PRICING_METHOD_LABEL: 'Metode harga',
    PRICING_METHOD_SUB_LABEL: 'Jika biaya sudah ditetapkan atau berdasarkan konsumsi.',
    PRICING_METHOD_PRESET: 'Preset',
    PRICING_METHOD_VARIABLE: 'Variabel',
    PRICING_METHOD_VALUE_LABEL: 'Nilai',
    PRICING_METHOD_VALUE_SUB_LABEL: 'Berapa banyak biaya dalam poin hadiah',
    PRICING_METHOD_VALUE_SUFIX: 'poin',
    DESCRIPTION_LABEL: 'Deskripsi',
    DESCRIPTION_PLACEHOLDER: 'Deskripsikan secara singkat apa fungsi dan bagaimana cara kerjanya.',
    SUBMIT_SUCCESS: 'Layanan berhasil dibuat',
    SUBMIT_ERROR: 'Layanan tidak dapat dibuat sekarang!',
    EDIT_SUBMIT_SUCCESS: 'Perubahan berhasil disimpan!',
    REQUIRED_FIELD_MESSAGE: 'Kolom ini diperlukan.',
    EXISTING_TITLE_ERROR_MESSAGE: 'Sudah ada misi dengan judul ini, coba yang lain.',
    GREATER_THAN_ZERO_FIELD_MESSAGE: 'Angka harus lebih besar dari nol',
  },
  ERROR: {
    SOMETHING_WRONG: 'Ooops! Ada yang salah.',
    TRY_AGAIN: 'Coba lagi',
    TRY_LATER: 'Ada yang salah. Coba lagi nanti.',
  },
  TOAST: {
    TRY_AGAIN: 'Coba lagi',
  },
  RICH_TEXT: {
    BOLD: 'Tebal',
    ITALIC: 'Miring',
    BULLET_LIST: 'Daftar poin',
    NUMBERED_LIST: 'Daftar bernomor',
    PLACEHOLDER_PT1: 'Contoh: Sapa pelanggan dan beri tahu alasan panggilan.',
    PLACEHOLDER_PT2:
      'Kemudian ingatkan mereka tentang manfaat pembelian langsung dari Aplikasi BEES.',
  },
};

export default idID;
