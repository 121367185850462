import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import { SearchAppliedEventProps, SearchAppliedEventReturn } from './useSearchAppliedEvent.types';

export default function useSearchAppliedEvent(): SearchAppliedEventReturn {
  const { searchApplied } = useSegment();

  const {
    app_display_name,
    app_id,
    page_url,
    user_country,
    user_email,
    user_id,
    user_affiliation,
    vendor_country,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
    origin_workspace_label,
    origin_workspace_tracking_id,
  } = useSegmentProperties();

  const triggerSearchApplied = ({
    is_core_event,
    page_label,
    page_name,
    search_query,
    number_of_results,
    occurence_id,
  }: SearchAppliedEventProps) => {
    searchApplied({
      app_display_name,
      app_id,
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      search_query,
      number_of_results,
      origin_workspace_label,
      origin_workspace_tracking_id,
      occurence_id,
    });
  };

  return {
    triggerSearchApplied,
  };
}
