import { BadgeStatusTypes } from "@hexa-ui/components";

export interface IActionLink {
  content: string;
  actionFn?: () => void;
  link?: string;
  icon?: JSX.Element;
  stateModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExperimentStatusObject = {
  DRAFT: {
    color: "orange" as BadgeStatusTypes,
    label: "Draft",
  },
  READY: {
    color: "gray" as BadgeStatusTypes,
    label: "Loading",
  },
  STARTING: {
    color: "gray" as BadgeStatusTypes,
    label: "Loading",
  },
  STARTED: {
    color: "info" as BadgeStatusTypes,
    label: "In progress",
  },
  IN_VALIDATION: {
    color: "info" as BadgeStatusTypes,
    label: "Sent for validation",
  },
  ERROR: {
    color: "gray" as BadgeStatusTypes,
    label: "Error",
  },
  FINISHED: {
    color: "gray" as BadgeStatusTypes,
    label: "Completed",
  },
  CANCELLED: {
    color: "orange" as BadgeStatusTypes,
    label: "Cancelled",
  },
  WAITING_APPROVAL: {
    color: "orange" as BadgeStatusTypes,
    label: "Pending approval",
  },
  APPROVED: {
    color: "blue" as BadgeStatusTypes,
    label: "Approved",
  },
  REJECTED: {
    color: "red" as BadgeStatusTypes,
    label: "Rejected",
  },
  default: {
    color: "orange" as BadgeStatusTypes,
    label: "Draft",
  },
};

export const VariationsTypes = [
  { name: "Application coded", id: "application-coded" },
  { name: "Platform setting", id: "platform-setting" },
];

export const VariationsSettingOptions = [
  { name: "Copy test", id: "copy-test" },
  { name: "Server driven UI", id: "server-driven-ui" },
];
export interface JSONError {
  status: boolean;
  text: string;
  inputName: string;
  id: string;
}

export const STEP3_VARIATIONS_ALLOW_LIST_MAX_NUMBER = 5;
export const STEP3_VARIATIONS_MAX_NUMBER = 6;
