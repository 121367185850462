import { Card, Grid, Heading, Paragraph, Tabs } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StyledTabs from '../../components/Tabs/Tabs';
import { styled } from '@hexa-ui/theme';
import WinnersForFun from './tabs/WinnersForFun';
import WinnersForPrize from './tabs/WinnersForPrize';
import StyledButton from '../../components/Button/Button';
import { Plus } from '@hexa-ui/icons';

const StyledGridItem = styled(Grid.Item, {
  display: 'flex',
  justifyContent:'space-between'
});

export default function WinnersImportList() {

  const { id, idRanking } = useParams();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(hash || '#rankingforprize');
  const rankingName = ''

  useEffect(() => {
    setCurrentTab(hash || '#winnersforprize');
  }, [hash]);
  const tabs = [
    { component: WinnersForPrize, hash: '#winnersforprize', title: 'Winners for Prize' },
    { component: WinnersForFun, hash: '#winnersforfun', title: 'Winners for Fun' },
  ];

  return (
    <>
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Winners {rankingName}
          </Heading>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() =>
              navigate(`/bees-games/manage-assets/campaign-details/${id}/import-winners/${idRanking}/import-guess`)
            }
          >
            Import Winner Guess
          </StyledButton>
        </StyledGridItem>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" style={{ width: '100%' }}>
            <StyledTabs defaultValue={hash || '#winnersforprize'}>
              <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
                {tabs?.map((tab, index) => (
                  <a
                    key={index + tab.title}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={tab.hash}
                  >
                    <Tabs.Trigger key={index} value={tab.hash}>
                      {tab.title}
                    </Tabs.Trigger>
                  </a>
                ))}
              </Tabs.List>
              {tabs?.map((tab, index) => (
                <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                  <tab.component />
                </Tabs.Content>
              ))}
            </StyledTabs>
          </Card>
        </Grid.Item>
    </>
  );
}
