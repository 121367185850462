import { IExperimentMutationForm } from "../../../store/interfaces/experimentMutation/experimentMutation";
import {
  ExperimentInputErrors,
  IExperiment,
  IExperimentationVariation,
} from "../../../types/experiment";

import {
  IAudienceSelection,
  IBasicInformation,
  IFormVariation,
} from "../../../store/interfaces/experimentMutation/experimentMutation";
import { Guardrails } from "../../../types/guardrails";
import { Metric } from "./../../../types/metrics";

export enum VARIANT_TYPE {
  APPLICATION_CODED = "application-coded",
  PLATFORM_SETTING = "platform-setting",
}

export enum VARIANT_TYPE_SETTINGS {
  COPY_TEST = "copy-test",
  SERVER_DRIVEN_UI = "server-driven-ui",
}

const isFilledArray = (items: string[]) => items.length > 0;

export const SET_TIMEOUT_TOAST_DURATION = 3000;

export const byteLength = (str: string) => {
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
};

export const handleFieldError = (
  forms: IBasicInformation | IAudienceSelection | IFormVariation[],
  step: number
) => {
  if (step === 0) return handleStep1FieldError(forms as IBasicInformation);
  if (step === 1) return handleStep2FieldError(forms as IAudienceSelection);
  if (step === 2 && Array.isArray(forms) && forms.length > 0)
    return handleStep3FieldError(forms as IFormVariation[]);
};

export const handleStep1FieldError = (
  forms: IBasicInformation
): ExperimentInputErrors => {
  const omitProperties = [
    "customGuardrails",
    "requiredGuardrails",
    "externalKey",
  ];
  const keys = Object.keys(forms).map(
    (key) => !omitProperties.includes(key) && key
  );

  const errors: ExperimentInputErrors = {
    step1: [],
    step2: [],
    step3: {
      variant_a: [],
      variant_b: [],
      variant_c: [],
      variant_d: [],
      variant_e: [],
      variant_f: [],
    },
  };

  keys.forEach((key) => {
    if (key === "secondaryMetrics") {
      const hasNoSecondary = forms[key].length === 0;
      if (hasNoSecondary) errors?.step1?.push(key);
      return;
    }
    const isEmpty = forms[key] === "";
    if (isEmpty) errors?.step1?.push(key);
  });

  return isFilledArray(errors.step1)
    ? errors
    : {
        step1: [],
        step2: [],
        step3: {
          variant_a: [],
          variant_b: [],
          variant_c: [],
          variant_d: [],
          variant_e: [],
          variant_f: [],
        },
      };
};

export const handleStep2FieldError = (
  forms: IAudienceSelection
): ExperimentInputErrors => {
  const omitProperties = ["country"];
  const keys = Object.keys(forms).map(
    (key) => !omitProperties.includes(key) && key
  );

  const errors: ExperimentInputErrors = {
    step1: [],
    step2: [],
    step3: {
      variant_a: [],
      variant_b: [],
      variant_c: [],
      variant_d: [],
      variant_e: [],
      variant_f: [],
    },
  };
  const hasNoAudneice =
    !forms?.audiences?.externalId && !forms.countryAsAudience;
  if (hasNoAudneice) errors.step2.push("noAudience");
  if (!forms.trafficPercentage) errors?.step2?.push("trafficPercentage");

  return isFilledArray(errors.step2)
    ? errors
    : {
        step1: [],
        step2: [],
        step3: {
          variant_a: [],
          variant_b: [],
          variant_c: [],
          variant_d: [],
          variant_e: [],
          variant_f: [],
        },
      };
};

export const mountBasicInformationRequest = (
  basicInformation: IBasicInformation,
  vendorId: string,
  guardrails: Guardrails
) => {
  const response = {
    ...basicInformation,
    primaryMetrics: [basicInformation.primaryMetric],
    vendorId: vendorId,
    guardrailsIds: guardrails.requiredGuardrails.map(
      (guardrail) => guardrail.id
    ),
  };
  delete response.requiredGuardrails;
  return response;
};

export const mountAudiencesRequest = (audiences: IAudienceSelection) => {
  const countryAsAudience = audiences.countryAsAudience;

  if (!audiences?.audiences?.externalId && !countryAsAudience)
    return { audiences: [], countryAsAudience: false };
  if (countryAsAudience)
    return {
      audiences: [],
      countryTrafficPercentage: audiences.trafficPercentage,
      countryAsAudience,
    };
  return {
    audiences: [
      {
        ...audiences.audiences,
        trafficPercentage: audiences.trafficPercentage,
      },
    ],
    countryAsAudience,
  };
};

export const mountVariantRequest = (
  variantsForm: IFormVariation[]
): IExperimentationVariation[] => {
  const hasErrors = handleStep3FieldError(variantsForm);
  if (hasErrors.step3.errors) return [];
  const variants: IExperimentationVariation[] = variantsForm?.map((form) => {
    const variant = {
      id: form.id,
      name: form.name,
      description: form.description,
      configObject:
        form.type === VARIANT_TYPE.APPLICATION_CODED ? form.json : null,
      copy: form.settingOptions.includes(VARIANT_TYPE_SETTINGS.COPY_TEST)
        ? {
            androidStringKey: form.androidStringKey,
            stringValue: form.stringValue,
          }
        : null,
      sdui: form.settingOptions.includes(VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI)
        ? { sduiJson: form.json, containerId: form.containerId }
        : null,
      viewers: form.viewers || [],
    };
    return variant;
  });
  return variants;
};

export const handleStep3FormFields = (variant: IFormVariation) => {
  const hasCopyTest = variant.settingOptions.includes(
    VARIANT_TYPE_SETTINGS.COPY_TEST
  );
  const hasServerDrivenUi = variant.settingOptions.includes(
    VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
  );

  const isApplicationCoded = variant.type === VARIANT_TYPE.APPLICATION_CODED;
  let updatedVariant: IFormVariation = {
    id: variant.id,
    name: variant.name,
    description: variant.description,
    type: variant.type,
    settingOptions: isApplicationCoded ? [] : variant.settingOptions,
    json: variant.json,
    viewers: variant.viewers || [],
  };
  if (hasCopyTest && !isApplicationCoded) {
    updatedVariant = {
      ...updatedVariant,
      androidStringKey: variant.androidStringKey || "",
      stringValue: variant.stringValue || "",
    };
  }
  if (hasServerDrivenUi && !isApplicationCoded) {
    updatedVariant = {
      ...updatedVariant,
      containerId: variant.containerId || "",
    };
  }
  return updatedVariant;
};

export const handleStep3FieldError = (
  forms: IFormVariation[]
): ExperimentInputErrors => {
  let errors: ExperimentInputErrors = {
    step1: [],
    step2: [],
    step3: {
      variant_a: [],
      variant_b: [],
      variant_c: [],
      variant_d: [],
      variant_e: [],
      variant_f: [],
    },
  };

  forms?.forEach((form) => {
    errors = { ...errors, step3: { ...errors.step3, [form.id]: [] } };

    const hasApplicationCoded = form.type === VARIANT_TYPE.APPLICATION_CODED;
    const hasCopyTest = form.settingOptions.includes(
      VARIANT_TYPE_SETTINGS.COPY_TEST
    );
    const hasServerDrivenUi = form.settingOptions.includes(
      VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
    );

    const updatedProperties = Object.keys(form).filter((f) => {
      return f !== "viewers" && f !== "id" && f !== "type";
    });
    updatedProperties?.forEach((prop) => {
      if (prop === "json") {
        if (
          (hasApplicationCoded || hasServerDrivenUi) &&
          (!form?.json || !isItValidJson(form.json))
        ) {
          errors.step3[form.id].push("json");
        }
        return;
      }

      if (prop === "androidStringKey") {
        if (hasCopyTest && !form.androidStringKey)
          errors.step3[form.id].push("androidStringKey");
        return;
      }

      if (prop === "stringValue") {
        if (hasCopyTest && !form.stringValue)
          errors.step3[form.id].push("stringValue");
        return;
      }

      if (prop === "containerId") {
        if (hasServerDrivenUi && !form.containerId)
          errors.step3[form.id].push("containerId");
        return;
      }

      if (!form[prop]) errors.step3[form.id].push(prop);
      return;
    });

    if (!hasServerDrivenUi && !hasCopyTest && !hasApplicationCoded)
      errors.step3[form.id].push("settingOptions");
  });

  const array = Object.keys(errors.step3)
    .map((key) => errors.step3[key])
    .flat();

  return array.length > 0
    ? { ...errors, step3: { ...errors.step3, errors: true } }
    : {
        step1: [],
        step2: [],
        step3: {
          variant_a: [],
          variant_b: [],
          variant_c: [],
          variant_d: [],
          variant_e: [],
          variant_f: [],
        },
      };
};

export const handleError = (
  fieldName: string,
  value: string,
  inputErrors: any
) => {
  if (!!value) {
    const index = inputErrors?.indexOf(fieldName);
    if (index === -1) return inputErrors;
    inputErrors?.splice(index, 1);
    return inputErrors;
  }
  return inputErrors;
};

export const fieldValidation = (value: any, jsonField: boolean) => {
  if (jsonField) {
    return isItValidJson(value);
  }
  return value;
};

export const isItValidJson = (value) => {
  let isJson = true;
  try {
    JSON.parse(value);
  } catch (error) {
    isJson = false;
  }

  return isJson;
};

export const isActionClick = (rowClickedItem): boolean => {
  return !!(
    (rowClickedItem?.target as HTMLElement).closest(
      "svg[data-testid='MoreHorizontal']"
    ) ||
    (rowClickedItem?.target as HTMLElement).closest("svg[data-testid='Copy']")
  );
};

export const handleCopyText = async (text: string, e?: React.MouseEvent) => {
  console.log("e", e);

  if (e) {
    e?.nativeEvent?.stopImmediatePropagation();
  }

  if (!navigator?.clipboard) return;
  try {
    await navigator?.clipboard?.writeText(text);
  } catch (error) {
    console.error(error);
  }
};

export const prePopulateExperimentMutation = (
  data: IExperiment
): IExperimentMutationForm => {
  return {
    id: data.id,
    basicInformation: {
      name: data.name,
      description: data?.description,
      hypothesis: data?.hypothesis,
      documentationLink: data?.documentationLink,
      country: data?.country,
      primaryMetric: data?.primaryMetrics[0],
      secondaryMetrics: data?.secondaryMetrics,
      customGuardrails: data?.guardrailsIds,
      requiredGuardrails: data?.guardrailsIds,
      externalKey: data?.externalKey,
    },
    audiences: {
      countryAsAudience: data?.countryAsAudience,
      trafficPercentage: data?.countryAsAudience
        ? data?.countryTrafficPercentage
        : !!data?.audiences?.length
          ? data?.audiences[0].trafficPercentage
          : 0,
      audiences:
        data?.audiences && data?.audiences.length > 0
          ? data?.audiences[0]
          : {
              externalId: "",
              name: "",
            },
    },
    variations: data?.variations
      ? data?.variations?.map((variant) => {
          let settingOptions: string[] = [];
          if (variant?.copy)
            settingOptions.push(VARIANT_TYPE_SETTINGS.COPY_TEST);
          if (variant?.sdui)
            settingOptions.push(VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI);
          return {
            id: variant.id,
            name: variant.name,
            description: variant.description,
            type: variant.configObject
              ? VARIANT_TYPE.APPLICATION_CODED
              : VARIANT_TYPE.PLATFORM_SETTING,
            settingOptions,
            json: variant.configObject || variant.sdui?.sduiJson,
            viewers: variant.viewers || [],
            containerId: variant.sdui?.containerId,
            stringValue: variant.copy?.stringValue,
            androidStringKey: variant.copy?.androidStringKey,
          };
        })
      : [],
  };
};

export const getExportedMetrics = (
  convertedData: IExperiment,
  primaryMetrics: Metric[],
  secondaryMetrics: Metric[]
) => {
  if (convertedData?.primaryMetrics?.length !== 0) {
    convertedData.primaryMetrics = convertedData.primaryMetrics?.map(
      (metric) => {
        return primaryMetrics.find((item) => item.name === metric)?.id || "";
      }
    );
  }

  if (convertedData?.secondaryMetrics?.length !== 0) {
    convertedData.secondaryMetrics = convertedData.secondaryMetrics?.map(
      (metric) => {
        return secondaryMetrics.find((item) => item.name === metric)?.id || "";
      }
    );
  }

  return convertedData;
};

export const cleanUnecessaryFields = (data: IExperiment) => {
  const mapUnecessaryImportedFields = [
    "status",
    "vendorId",
    "externalKey",
    "startDate",
    "endDate",
    "audiences",
  ];

  mapUnecessaryImportedFields.forEach((field) => {
    if (data[field]) delete data[field];
  });

  if (!data.variations) return data;

  data.variations.forEach((variation) => {
    delete variation.viewers;
  });

  return data;
};
