import { RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { AssessmentsItem, DataType, SelectedData, Tables } from '../../../../interfaces';
import { getAssessments } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { useTableStore } from '../../../../store/hooks/tablesHook';
import { debounce } from '../../../../utils/debounce';
import { mappedAssessments } from '../../../../utils/mapper/mappedAssessments';
import { Table } from '../../../atoms/Table/Table';
import { NoFilter } from '../../../icons/NoFilter/index.svg';
import { NoPocs } from '../../../icons/NoPocs/index.svg';
import { ApprovalButtons } from '../../../molecules/ApprovalButtons/ApprovalButtons';
import { useCredit } from '../../../pages/Credit/Context/CreditContext';
import { AssessmentsTableProps, CallbackRef } from './AssessmentsTable.types';

export const AssessmentsTable: React.FC<AssessmentsTableProps> = ({ type }) => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const navigate = useNavigate();

  const { filters, columns, tag, reload, recommended } = useGetTables(type);
  const { configs, selected: selectedHeader } = useGetUserPreferences();
  const { tables } = configs;

  const { hasApproval } = useCredit();

  const {
    pagination: storedPagination,
    filters: storedFilters,
    search: storedSearch,
    selectable,
  } = useTableStore(type);

  const isFirstRender = useRef<boolean>(true);
  const callbackFn = useRef<CallbackRef>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessments, setAssessments] = useState<AssessmentsItem[]>([]);
  const [search, setSearch] = useState<string>(storedSearch.value || '');
  const [filtered, setFiltered] = useState<string>('');
  const [pagination, setPagination] = useState({
    page: 1,
    size: 100,
    total: 0,
  });

  const requestController = useRef(new AbortController());
  const selectableState = useRef<RowSelectionState>({});
  const sortingState = useRef<SortingState>([]);

  const onFilter = async (filters: string) => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    setFiltered(filters);

    await getData({ filters, search: storedSearch.value || search.trim() || '' });
  };

  const onRow = (row: DataType[typeof type]) => {
    SegmentService.paymentsButtonClicked(
      'Credit Line',
      'Credit Line Row',
      selectedHeader.vendorId,
      'Credit Management Access POC Details',
      `CREDIT_MANAGEMENT_${type.toUpperCase()}_TAB`
    );

    navigate(
      `/bees-credit-management/poc-details/${row.assessmentId}?vendorId=${selectedHeader.vendorId}&country=${selectedHeader.country}&projection=RECOMMENDATION`
    );
  };

  const onPagination = (page: number, size?: number) => {
    setPagination((prev) => ({ ...prev, page, size: size ?? prev.size }));
  };

  const mappedRows = (rows: AssessmentsItem[]) => {
    return rows.map((row) => ({
      beesAccountId: row.beesAccountId,
      pocDocument: row.pocDocument,
      pocName: row.pocName,
    }));
  };

  const onSelected = (data: SelectedData<AssessmentsItem>) => {
    if (!data) {
      selectable.clear();
      return;
    }

    const { mode, checked, rows } = data;

    if (checked) {
      selectable.selected.add({
        mode,
        identifiers: rows.map((row) => row.beesAccountId),
        ...(mode === 'single' && { rows: mappedRows(rows) }),
      });

      if (mode === 'multiple' && rows.length > 1) {
        selectable.move({ from: 'exclusions', to: 'selected' });
      }

      return;
    }

    if (!mode && (rows.every((row) => !row) || selectable.selected.value.size === 1)) {
      Object.keys(selectableState.current).forEach((key) => {
        selectableState.current[key] = false;
      });

      selectable.clear();
      return;
    }

    const { beesAccountId, pocDocument, pocName } = rows[0];

    selectable.selected.remove({
      mode,
      identifiers: [beesAccountId],
      rows: [{ beesAccountId, pocDocument, pocName }],
    });
  };

  const onCallback = useCallback(async () => {
    await getData({ filters: filtered || '', search: search.trim() || '' });

    selectableState.current = {};

    selectable.clear();
    hasApproval.setter(!hasApproval.value);
  }, [filtered, search, recommended, pagination.size, pagination.page]);

  const toolbarExtra = useCallback(
    () => (
      <ApprovalButtons
        mode="multiple"
        type="button"
        button={{ size: 'small' }}
        counter={selectable.counter}
        selected={selectable.rows}
        callbackFn={onCallback}
      />
    ),
    [selectable.rows, selectable.counter]
  );

  const getData = async ({ filters = '', search = '' }) => {
    setIsLoading(true);

    const response = await getAssessments({
      api: { get },
      params: {
        status: tables[type].status,
        search,
        filters,
        pagination,
        recommended,
        sort: sortingState.current,
      },
      config: { signal: requestController.current.signal },
    });

    if (!response?.success) {
      setAssessments([]);
      setIsLoading(false);

      return;
    }

    const { data, pageable } = mappedAssessments(response.data);
    data.forEach((item: AssessmentsItem) => {
      item.actions = { tag, selected: item, callbackFn: callbackFn.current };
      item.recommendation = formatMessage({
        id: `formatting.recommendation.${item.recommendation}`,
      });
    });

    selectableState.current = data.reduce((acc, row) => {
      acc[row.beesAccountId] = selectable.mode === 'multiple';
      return acc;
    }, {});

    if (
      selectable.type === 'async' &&
      selectable.mode === 'multiple' &&
      !data.some((row) => selectable.exclusions.value.has(row.beesAccountId))
    ) {
      selectable.selected.add({
        mode: 'multiple',
        identifiers: data.map((row) => row.beesAccountId),
      });
    }

    setAssessments(data);
    setPagination((prev) => ({ ...prev, total: pageable.total }));

    storedPagination.setter(pagination.page, pagination.size, pageable.total);
    setIsLoading(false);
  };

  const debouncedGetData = useCallback(
    debounce(({ search, filters }) => {
      if (search) {
        SegmentService.paymentsButtonClicked(
          'Search',
          'Search',
          selectedHeader.vendorId,
          'Credit Management Search',
          `CREDIT_MANAGEMENT_${type?.toUpperCase()}_TAB`
        );
      }

      storedSearch.setter(search);
      getData({ filters, search });
    }, 700),
    [type, recommended, pagination.size, pagination.page]
  );

  const onSearch = async (search: string) => {
    requestController.current.abort();
    requestController.current = new AbortController();

    setSearch(search);
    setIsLoading(true);
    debouncedGetData({ filters: filtered || '', search: search.trim() });
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onClear = () => {
    setSearch('');
    storedSearch.setter('');
  };

  const onSelectedClear = (state: RowSelectionState) => {
    Object.keys(state).forEach((key) => (state[key] = false));

    onPagination(1);
  };

  const onSorting = (updater: Updater<SortingState>) => {
    const updatedValue = updater instanceof Function ? updater(sortingState.current) : updater;

    sortingState.current = updatedValue;
    onSearch(search);
  };

  const selectableConfig = useMemo(() => {
    const state = selectableState.current;

    Array.from(selectable.selected.value).forEach((id) => (state[id] = true));
    Array.from(selectable.exclusions.value).forEach((id) => (state[id] = false));

    return {
      has: true,
      state,
      mode: selectable.mode,
      counter: selectable.counter,
      identifier: selectable.identifier,
      onSelected: onSelected,
      onClear: onSelectedClear,
    };
  }, [
    selectable.mode,
    selectable.counter,
    selectable.identifier,
    selectableState.current,
    selectable.selected.value,
    selectable.exclusions.value,
  ]);

  useEffect(() => {
    if (Object.keys(storedFilters.value ?? {}).length > 0 && isFirstRender.current) {
      return;
    }

    getData({ filters: filtered || '', search: search.trim() || '' });
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    if (!isFirstRender.current) {
      SegmentService.paymentsButtonClicked(
        `Items per page: ${pagination.size}`,
        `Items per page: ${pagination.size}`,
        selectedHeader.vendorId,
        'Credit Management User Per Page',
        `CREDIT_MANAGEMENT_${type?.toUpperCase()}_TAB`
      );
    }
  }, [pagination.size]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getData({});
  }, [reload]);

  useEffect(() => {
    callbackFn.current = onCallback;
  }, [onCallback]);

  return (
    <Table
      table={type}
      data={assessments}
      columns={columns}
      loading={isLoading}
      tableHeight={`min(100vh - ${filtered ? '258px' : '202px'})`}
      onRow={onRow}
      filters={{ has: true, chips: true, config: filters, onFilter, onClear: onPagination }}
      sorting={{ has: true, state: sortingState.current, onSorting }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        total: pagination.total,
        onChange: onPagination,
      }}
      search={{
        has: true,
        placeholder: formatMessage({ id: 'tables.search' }),
        value: search,
        onChange: onSearch,
      }}
      messages={{
        loading: formatMessage({ id: 'tables.loading' }),
        empty: {
          message: formatMessage({ id: `tables.${filtered ? 'filters.' : ''}empty` }),
          Icon: filtered ? NoFilter : NoPocs,
        },
      }}
      {...(type === Tables.PENDING && {
        views: { has: true, table: type, onClear },
        selectable: selectableConfig,
        toolbarExtra: toolbarExtra,
      })}
    />
  );
};
