import {
  FileMultiUploader,
  Heading,
  Paragraph,
  TextArea,
  TextLink,
  UploadableFile,
} from '@hexa-ui/components';
import { AlertOctagon } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import SegmentService from '../../../../services/segment';
import { RulesAndConditions } from '../../../molecules/RulesAndConditions/RulesAndConditions';
import { useUpload } from '../Context';
import {
  DescriptionTextContainer,
  DescriptionToModal,
  ErrorMsgContainer,
  LabelContainer,
  TextAreaContainer,
  TextContainer,
  UploaderCard,
} from './styles';

export const Uploader: React.FC = () => {
  const { formatMessage } = useIntl();

  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const { files, file, error, send } = useUpload();

  const onClean = () => {
    SegmentService.paymentsButtonClicked(
      'X Icon',
      'File Uploader Remove File',
      selected?.vendorId,
      'Credit Management Files Upload Remove File',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );

    send({ type: 'CLEAR' });
  };

  const onDrop = (dropped: UploadableFile[]) => {
    send({ type: 'FILE_SELECTED', files: dropped });

    SegmentService.paymentsButtonClicked(
      'File Uploader',
      'File Uploader Button',
      selected?.vendorId,
      'Credit Management Files Upload File Inserted',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
  };

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    send({ type: 'ADD_DESCRIPTION', description: e.target.value });
  };

  return (
    <UploaderCard border="medium" elevated="small" data-testid="uploader-card">
      <TextContainer>
        <Heading size="H4">{formatMessage({ id: 'pages.upload.uploader.title' })}</Heading>

        <DescriptionToModal colortype="primary">
          {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.description' })}{' '}
          <RulesAndConditions
            trigger={
              <TextLink css={{ fontSize: '16px' }}>
                {formatMessage({ id: 'pages.upload.uploader.rulesAndConditions.modalLink' })}
              </TextLink>
            }
          />
        </DescriptionToModal>
      </TextContainer>

      <FileMultiUploader
        size="large"
        accept={filesConfig.accept}
        maxFileSize={filesConfig.size}
        hasPreviewButton={false}
        title={formatMessage({ id: 'pages.upload.uploader.fileUploader.title' })}
        inputLabel={formatMessage({ id: 'pages.upload.uploader.fileUploader.label' })}
        message={formatMessage(
          { id: 'pages.upload.uploader.fileUploader.description' },
          { maxFileSize: filesConfig.size / 1000000 }
        )}
        value={files}
        onDrop={onDrop}
        onClean={onClean}
      />

      {error.has && (
        <ErrorMsgContainer data-testid="error-container">
          <AlertOctagon size="tiny" />

          <Paragraph colortype="error" size="xsmall">
            {formatMessage({ id: error.message }, error.attributes)}
          </Paragraph>
        </ErrorMsgContainer>
      )}

      <TextAreaContainer data-testid="text-area-container">
        <LabelContainer>
          <DescriptionTextContainer>
            <Paragraph size="small" weight="semibold">
              {formatMessage({ id: 'pages.upload.uploader.fileDescription.title' })}
            </Paragraph>

            <Paragraph size="small" colortype="secondary">
              {formatMessage({ id: 'pages.upload.uploader.fileDescription.subTitle' })}
            </Paragraph>
          </DescriptionTextContainer>
        </LabelContainer>

        <TextArea
          id="file-description"
          width={'100%'}
          optionalText=""
          characterCounter
          maxLength={150}
          value={file.description}
          placeholder={formatMessage({ id: 'pages.upload.uploader.fileDescription.description' })}
          onChange={onDescription}
        />
      </TextAreaContainer>
    </UploaderCard>
  );
};
