export * from './tables';

/**
 * @namespace Pages
 * @interface Upload
 */

export enum UploadStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  UPLOADED = 'UPLOADED',
  ANALYZING = 'ANALYZING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  ANALYZED = 'ANALYZED',
}

export enum UploadTimelineSteps {
  FILE_UPLOAD = 0,
  FILE_ANALYSIS = 1,
  POC_UPDATE = 2,
}

export type StepsMapping = {
  [key in UploadTimelineSteps]: UploadStatus[];
};

export interface UploadTimeline {
  current: number;
}

export interface StepProps {
  index: number;
  message: string;
  completed: boolean;
}

export interface UploadedProps {
  fileId: string;
  fileName: string;
}

export interface ProgressProps {
  referenceName: string;
  status: string;
  total: number;
  totalAnalysis: number;
  validated: number;
  failed: number;
  processed: number;
  analysisPercentage: string;
  processedPercentage: string;
}

/**
 * @namespace Summary
 * @interface Summary
 */

export interface MappedSummary {
  pending: number;
  inprogress: number;
  active: number;
  denied: number;
  total: number;
}

/**
 * @namespace Modal
 * @interface Portal
 */

export interface ModalPortalProps {
  has: boolean;
  container: Element;
}
