export const getDateByPeriod = (period: string): string => {
  const monthCode = period.slice(0, 2);
  const yearCode = period.slice(2);

  const date = new Date(`${yearCode}-${monthCode}-01T00:00:00Z`);

  if (isNaN(date.getTime())) {
    return '';
  }

  const removeMilliseconds = (dateString: Date) => dateString.toISOString().slice(0, 19);

  return removeMilliseconds(date);
};
