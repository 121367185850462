import { Serializer } from 'miragejs';

let ApplicationSerializer = Serializer.extend({
  root: true,
});

const serializers = {
  application: ApplicationSerializer,
};

export default serializers;
