import { Paragraph, TextLink } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperRefreshButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-1']};
`;

export const RefreshButton = styled(TextLink)`
  align-content: center;
  padding: 0 ${({ theme }) => theme.measure.space['space-3']};
`;

export const RefreshLabel = styled(Paragraph)`
  align-content: center;
`;

export const RefreshErrorLabel = styled(Paragraph)`
  align-content: center;
  padding-right: ${({ theme }) => theme.measure.space['space-3']};
`;
