import { ApiService } from '@services/api/ApiService';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';

export type Country = {
  code: string;
  availableLanguages: string[];
};

export type CountriesResponse = {
  countries: Country[];
};

const COUNTRIES_URL = `${BASE_MANAGEMENT_BFF_PATH}${URLS.COUNTRIES}`;

export const getCountries = async (): Promise<CountriesResponse> => {
  const { get } = ApiService();

  return get<CountriesResponse>({ url: COUNTRIES_URL }).then((response) => {
    if (response instanceof Error) throw response;
    return response;
  });
};
