import { IExperiment } from "@/types/experiment";
import { Card, Grid } from "@hexa-ui/components";
import { useMemo } from "react";
import AudienceSelect from "../../../components/ExperimentCommon/AudienceSelect/AudienceSelect";
import BasicInformation from "../../../components/ExperimentCommon/BasicInformation/BasicInformation";
import ExperimentLaunch from "../../../components/ExperimentCommon/ExperimentLaunch/ExperimentLaunch";
import Metrics from "../../../components/ExperimentCommon/Metrics/Metrics";
import Variants from "../../../components/ExperimentCommon/Variants/Variants";

import { Edit2 } from "@hexa-ui/icons";
import useResizeObserverErrorHandler from "../../../hooks/useResizeObserverErrorHandler/useResizeObserverErrorHandler";
import { VARIANT_TYPE } from "../../../pages/ExperimentMutation/utils";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import { useEnvProvider } from "../../../utils/envProvider";

type ReviewProps = {
  goToStep: (stepIndex: number) => void;
};

const Step4 = ({ goToStep }: ReviewProps): JSX.Element => {
  const { Container, Item } = Grid;
  useResizeObserverErrorHandler();
  const env = useEnvProvider();
  const vendorId = useMemo(() => env?.env?.vendorId, [env?.env?.vendorId]);

  const {
    experimentMutation: { basicInformation, audiences, variations: variants },
  } = useExperimentMutation();

  const experiment: IExperiment = {
    ...basicInformation,
    externalKey: basicInformation.externalKey,
    id: "",
    countryAsAudience: audiences.countryAsAudience,
    vendorId: vendorId[basicInformation.country],
    audiences: [
      {
        ...audiences.audiences,
        trafficPercentage: audiences.trafficPercentage,
      },
    ],
    countryTrafficPercentage: audiences.trafficPercentage,

    primaryMetrics: [basicInformation.primaryMetric],
    guardrailsIds: basicInformation.requiredGuardrails,
    variations: [
      ...variants.map((variant) => ({
        id: variant.id,
        name: variant.name,
        description: variant.description,
        configObject:
          variant?.type === VARIANT_TYPE.APPLICATION_CODED
            ? variant.json
            : null,
      })),
    ],
    lastApprovalRequestId: "",
    lastApprovalRequestStatus: "",
  };

  const ActionCards = {
    basicInformation: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => goToStep(0),
    },
    audiences: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => goToStep(1),
    },
    variants: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => goToStep(2),
    },
  };

  return (
    <Container
      className="w-full"
      style={{ width: "100%" }}
      data-testid="step-4"
    >
      <Card
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
        className="card"
        style={{ width: "100%", marginTop: "32px" }}
      >
        <BasicInformation
          experiment={experiment}
          isPreview
          action={ActionCards.basicInformation}
        />
      </Card>
      <Card
        ref={{
          current: "[Circular1]" as any,
        }}
        border="medium"
        elevated="small"
        className="card"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <Metrics
          experiment={experiment}
          action={ActionCards.basicInformation}
        />
      </Card>
      <Card
        ref={{
          current: "[Circular1]" as any,
        }}
        border="medium"
        elevated="small"
        className="card"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <AudienceSelect
          experiment={experiment}
          action={ActionCards.audiences}
        />
      </Card>
      <Card
        ref={{
          current: "[Circular1]" as any,
        }}
        border="medium"
        elevated="small"
        className="card"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <Variants experiment={experiment} action={ActionCards.variants} />
      </Card>
      <Card
        ref={{
          current: "[Circular1]" as any,
        }}
        border="medium"
        elevated="small"
        className="card"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <ExperimentLaunch experiment={experiment} />
      </Card>
    </Container>
  );
};

export default Step4;
