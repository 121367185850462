import MessageMap from '../i18n.d';

export const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em inglês disponível',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Visão Geral',
        CANCELLED_VISITS: '% Visitas canceladas',
        BDRS_WITHOUT_VISITS: '% BDRs sem visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Última atualização [lastUpdate] horas atrás.',
        FILTER_DESCRIPTION: 'Selecione o intervalo de dados que você gostaria de ver.',
      },
      Button: {
        REFRESH: 'Atualizar',
      },
      Error: {
        REFRESH_ERROR: 'Não foi possível atualizar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoje',
          YESTERDAY: 'Ontem',
          TOMORROW: 'Amanhã',
          OTHER: 'Outro',
        },
      },
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'ID da Conta',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        SEARCH_BY: 'Pesquisar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Pesquisar',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'ID da conta [id] não existe.',
        BDR_ID_ERROR: 'BDR [id] não existe.',
        BDR_NAME_ERROR: 'BDR [name] não existe.',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Mostrando [results] visitas',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        BDR_ACCOUNT_ID: 'ID da conta',
        VISIT_DATE: 'Data da visita',
        STATUS: 'Status',
        MIN_TIME: 'Tempo Min.',
        MAX_TIME: 'Tempo Máx.',
        MINUTES: 'Minutos',
        SCHEDULED: 'Agendada',
        IN_PROGRESS: 'Em andamento',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
        QUANTITY_INDICATION: 'de',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs sem visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Mostrando [results] resultados',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        DATE_WITHOUT_VISITS: 'Data sem visita',
        SEARCH_BY: 'Pesquisar por ID do BDR',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'a',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalhes da visita',
        POC_INFORMATION: 'Informação de POC',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Informação de BDR',
        VISIT_INFORMATION: 'Informação da visita',
      },
    },
  },
};
