import { belongsTo, hasMany, Model } from 'miragejs';

const models = {
  team: Model.extend({
    allocations: hasMany(),
    employees: hasMany(),
  }),
  user: Model,
  role: Model.extend({}),
  band: Model.extend({}),
  trail: Model.extend({}),
  score: Model.extend({}),
  employee: Model.extend({
    teams: hasMany(),
    allocations: hasMany(),
    role: belongsTo(),
    band: belongsTo(),
  }),
  allocation: Model.extend({
    team: belongsTo(),
    employee: belongsTo(),
  }),
  pillar: Model.extend({
    kpis: hasMany(),
  }),
  kpi: Model.extend({
    pillar: belongsTo(),
    kpiResponses: hasMany(),
  }),
  kpiResponse: Model.extend({
    kpi: belongsTo(),
  }),
  evaluation: Model.extend({
    pillars: hasMany(),
    employee: belongsTo(),
    evaluationResponse: hasMany(),
  }),
  evaluationResponse: Model.extend({
    kpi: belongsTo(),
    evaluation: belongsTo(),
    kpiResponse: belongsTo(),
  }),
  feedback: Model.extend({
    pillars: hasMany(),
  }),
};
export default models;
