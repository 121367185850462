import Axios from 'axios';
import { ServicesResponse, TranslationProps, Translations } from '../../interfaces';

const service = {
  getTranslationByLanguage: async ({
    config,
    params,
  }: TranslationProps): Promise<ServicesResponse | null> => {
    const { language } = params;

    try {
      const response = await Axios.get(`${process.env.REACT_APP_BFF_URL}/v1/copydeck/${language}`, {
        ...config,
      });

      if (!response?.data) return null;
      return { success: true, data: response.data as Translations };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const { getTranslationByLanguage } = service;
