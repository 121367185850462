import { Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../../components/Button/Button';
import FlexContainer from '../../../../components/FlexContainer';
import StyledFilter from '../../../../components/Filter/Filter';
import StyledTable from '../../../../components/Table/Table';
import TournamentHeaders from '../../../json/columnHeaders/MatchHeaders.json';
import mockData from '../../../json/mock/mockingMatchesData.json';
import handleFilter from '../../../campaignDetails/extra/filter';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';

export default function MatchesTab() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const { idTournament } = useParams();

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = [
        'group',
        'date',
        'date_utc',
        'home_team',
        'away_team',
        'home_result',
        'away_result',
        'finished',
      ];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const InsertActions = (data) => {
    const actions = (match) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(
                `/bees-games/tournament/settings/tournament-details/${idTournament}/edit-match/${match.id}`
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => ''}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete match?
            </Heading>
            <Paragraph>{'Do you really wish to delete this match?'}</Paragraph>
            <Paragraph>{'This action can’t be undone.'}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    return data.map((item) =>
      Object.assign({}, item, {
        actions: actions(item),
      })
    );
  };

  return (
    <>
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
        <StyledButton
          variant="primary"
          icon={Plus}
          leading
          onClick={() =>
            navigate(
              `/bees-games/tournament/settings/tournament-details/${idTournament}/create-match`
            )
          }
        >
          Add Match
        </StyledButton>
      </FlexContainer>
      <StyledTable
        columns={TournamentHeaders}
        search={false}
        data={data || []}
        loading={loading}
        pagination={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20],
          showPageSizeSelector: true,
        }}
      />
    </>
  );
}
