import { Input, InputProps } from '@hexa-ui/components';
import { Container } from './Input.styles';

const StyledInput = (
    {
        minWidth,
        width,
        ...props
    }: InputProps & { minWidth?: string } & { ref?: React.ForwardedRef<HTMLInputElement> },
    fowardedRef: React.ForwardedRef<HTMLInputElement>
): JSX.Element => {
    return (
        <Container width={width} minWidth={minWidth}>
            <Input {...{ ...props, width: '100%' }} />
        </Container>
    );
};

export default StyledInput;