import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';
import { selectedMapping } from '../../constants';
import { useGetUserPreferences } from '../../hooks/useGetUserPreferences';
import { AddFn, AppState, MoveFn, Tables } from '../../interfaces';

export const useTableStore = (table: Tables) => {
  const dispatch = useDispatch();

  const { toggles } = useGetUserPreferences();

  const { pagination, filters, search, selectable } = useSelector(
    (state: AppState) => state.tables[table]
  );

  const type = selectable.type;
  const mode = selectable.mode;
  const rows = selectable.rows;
  const selected = new Set(selectable.selected);
  const exclusions = new Set(selectable.exclusions);

  const { identifier, selectedType, requestType, counter } = useMemo(() => {
    const { key, identifier } = selectedMapping[table] || {};
    const isAsync = key ? toggles.has(key) : false;

    return {
      identifier,
      selectedType: isAsync ? 'async' : 'sync',
      requestType: isAsync && mode === 'multiple' ? 'async' : 'sync',
      counter: mode === 'multiple' && isAsync ? pagination.total - exclusions.size : selected.size,
    };
  }, [table, toggles, mode, pagination.total, exclusions.size, selected.size]);

  const setPagination = (page: number, size: number, total: number) => {
    dispatch({ type: 'tables/setPagination', payload: { table, page, size, total } });
  };

  const setFilters = (filters: FilterState) => {
    dispatch({ type: 'tables/setFilters', payload: { table, filters } });
  };

  const setSearch = (search: string) => {
    dispatch({ type: 'tables/setSearch', payload: { table, search } });
  };

  const mapped = (exclusions: Set<string>) => {
    return Array.from(exclusions).map((id: string) => ({ [identifier.mapkey]: id }));
  };

  const clear = () => {
    selected.clear();
    exclusions.clear();

    dispatch({
      type: 'tables/setSelectable',
      payload: { table, selected: [], exclusions: [], type: '', mode: '', rows: [] },
    });
  };

  const move = ({ from, to }: MoveFn) => {
    dispatch({ type: 'tables/move', payload: { table, move: { from, to } } });
  };

  const add = (isExclusions: boolean) => {
    return ({ mode, identifiers, rows }: AddFn) => {
      identifiers.forEach((id: string) => {
        if (isExclusions) {
          exclusions.add(id);
          selected.delete(id);

          return;
        }

        selected.add(id);
        exclusions.delete(id);
      });

      dispatch({
        type: 'tables/setSelectable',
        payload: {
          mode: mode ?? '',
          rows,
          table,
          isExclusions,
          identifierKey: identifier.key,
          selected: Array.from(selected),
          exclusions: Array.from(exclusions),
          type: selected.size > 0 ? selectedType : '',
        },
      });
    };
  };

  return {
    pagination: { value: pagination, setter: setPagination },
    filters: { value: filters, setter: setFilters },
    search: { value: search, setter: setSearch },
    selectable: {
      clear,
      move,
      type,
      mode,
      rows,
      counter,
      requestType,
      identifier: identifier?.key ?? 'beesAccountId',
      exclusions: { value: exclusions, mapped: mapped(exclusions) },
      selected: { value: selected, add: add(false), remove: add(true) },
    },
  };
};
