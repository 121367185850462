export enum Periods {
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  OTHER = 'other',
}

export enum Ordinal {
  st = 'st',
  nd = 'nd',
  rd = 'rd',
  th = 'th',
}

export enum DateFormats {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  LONG_ORDINAL = 'longOrdinal',
}
