import ProgramProjection from 'domains/enums/program/ProgramProjection'
import Rule from 'domains/rule/Rule'
import * as LoadProgramUseCase from 'usecase/program/LoadProgramUseCase'
import * as ProgramRulesToRules from 'converters/program/ProgramRulesToRules'
import * as ItemService from '@/services/item/ItemService'
import SkuForm from '@/domains/sku/SkuForm'
import agingGroupValidate from '@/utils/agingGroupValidate'
import VariantsType from '@/domains/Item/variantsType'

export type SkuFound = {
	sku: SkuForm
	parentRule?: Rule
}

const getParentRule = async ({
	sku,
	isToggleEarningRulesByVendorEnabled,
	multiVendorToggle,
}: {
	sku: string
	isToggleEarningRulesByVendorEnabled: boolean
	multiVendorToggle: boolean
}): Promise<Rule | undefined> => {
	if (isToggleEarningRulesByVendorEnabled) {
		return undefined
	}
	const program = await LoadProgramUseCase.execute(ProgramProjection.Rules)
	const programRuleFound = program?.rules.find((rule) =>
		multiVendorToggle ? rule.items?.find(({ vendorItemId }) => vendorItemId === sku) : rule.skus.includes(sku),
	)
	return programRuleFound ? ProgramRulesToRules.convert(programRuleFound, multiVendorToggle) : undefined
}

async function execute({
	sku,
	variantsType,
	isToggleEarningRulesByVendorEnabled,
	multiVendorToggle,
}: {
	sku: string
	variantsType?: VariantsType
	isToggleEarningRulesByVendorEnabled: boolean
	multiVendorToggle: boolean
}): Promise<SkuFound | undefined> {
	const response = multiVendorToggle
		? await ItemService.loadAllItems({ vendorItemIds: [sku], variantsType }).catch(() => undefined)
		: await ItemService.loadAll({ vendorItemIds: [sku], variantsType }).catch(() => undefined)

	if (response?.items?.length) {
		const skuObject = response.items[0]
		const agingValidate = agingGroupValidate(skuObject.agingGroup)
		if (!agingValidate) return Promise.resolve(undefined)

		const parentRule = await getParentRule({
			sku: skuObject.sku,
			isToggleEarningRulesByVendorEnabled,
			multiVendorToggle,
		})

		return {
			sku: {
				id: multiVendorToggle ? skuObject.vendorItemId ?? '' : skuObject.id,
				sku: skuObject.sku,
				name: skuObject.itemName,
				itemId: skuObject.id,
				image: skuObject.itemImage,
				vendorItemId: skuObject.vendorItemId,
			},
			parentRule,
		}
	}
	return Promise.resolve(undefined)
}

async function executeList({
	skus,
	variantsType,
	isToggleEarningRulesByVendorEnabled,
	multiVendorToggle,
}: {
	skus: Array<string>
	variantsType?: VariantsType
	isToggleEarningRulesByVendorEnabled: boolean
	multiVendorToggle: boolean
}): Promise<Array<SkuFound | undefined>> {
	const response = multiVendorToggle
		? await ItemService.loadAllItems({ vendorItemIds: skus, variantsType }).catch(() => undefined)
		: await ItemService.loadAll({ vendorItemIds: skus, variantsType }).catch(() => undefined)

	if (response?.items.length) {
		const skuObject = response.items

		const skuFounds = await Promise.all(
			skuObject.map(async (item): Promise<SkuFound | undefined> => {
				const agingValidate = agingGroupValidate(item.agingGroup)
				if (!agingValidate) return undefined

				const parentRule = await getParentRule({
					sku: item.sku,
					isToggleEarningRulesByVendorEnabled,
					multiVendorToggle,
				})

				return {
					sku: {
						id: multiVendorToggle ? item.vendorItemId ?? '' : item.id,
						name: item.itemName,
						itemId: item.id,
						image: item.itemImage,
						sku: item.sku,
						vendorItemId: item.vendorItemId,
					},
					parentRule,
				}
			}),
		)
		return skuFounds
	}
	return []
}

export { execute, executeList }
