export const bdrsWithoutVisits = [
  {
    id: '289012804318230182139',
    dateWithoutVisit: '2024-06-01T17:28:52.590Z',
  },
  {
    id: '289012804318230182138',
    dateWithoutVisit: '2024-03-01T17:28:52.590Z',
  },
  {
    id: '289012804318230182137',
    dateWithoutVisit: '2024-09-13T17:28:52.590Z',
  },
  {
    id: '289012804318230182136',
    dateWithoutVisit: '2024-05-01T17:28:52.590Z',
  },
  {
    id: '289012804318230182035',
    dateWithoutVisit: '2024-08-23T17:28:52.590Z',
  },
  {
    id: '289012804318230182034',
    dateWithoutVisit: '2024-02-12T17:28:52.590Z',
  },
  {
    id: '289012804318230182033',
    dateWithoutVisit: '2024-04-01T17:28:52.590Z',
  },
  {
    id: '289012804318230182032',
    dateWithoutVisit: '2024-10-20T17:28:52.590Z',
  },
  {
    id: '289012804318230182031',
    dateWithoutVisit: '2024-01-10T17:28:52.590Z',
  },
  {
    id: '289012804318230182030',
    dateWithoutVisit: '2024-07-01T17:28:52.590Z',
  },
];
