import MessageMap from '../i18n.d';

const idID: MessageMap = {
  titleSwitch: {
    title: 'Apa yang ingin Anda akses?',
  },
  cardSwitch: {
    title: {
      taskManagement: 'Manajemen tugas',
      OperationsPanel: 'Panel operasi',
      taskGroups: 'Grup tugas',
      userCreation: 'Pembuatan pengguna',
      audienceBuilder: 'Pembuat audiens',
      visits: 'Manajemen kunjungan',
    },
    description: {
      taskManagement: 'Buat dan kelola tugas BDR.',
      OperationsPanel: `Cari berdasarkan BDR, akun, kunjungan, dan tugas di database Force.`,
      taskGroups: 'Ambil, sunting, dan prioritaskan grup tugas.',
      userCreation:
        'Buat akun untuk BEES One dan Force untuk mengelola alur kerja pengguna secara efektif.',
      audienceBuilder:
        'Buat audiens target menggunakan filter atau daftar yang telah ditentukan sebelumnya.',
      visits: 'Lihat dan kelola kunjungan.',
    },
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
};

export default idID;
