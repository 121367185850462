import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_COUNTRY } from '../../constants/defaults';
import { columnsTable } from 'constants/columnsTable';
import { ReportsState } from './types/reports.types';

const initialState: ReportsState = {
  selectedCountry: DEFAULT_COUNTRY,
  selectedUserViewer: DEFAULT_COUNTRY,
  fieldsToExport: columnsTable.map((column) => column.key),
  shouldRefetch: true,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setUserViewer: (state, action: PayloadAction<string>) => {
      state.selectedUserViewer = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.selectedCountry = action.payload;
    },
    setShouldRefetch: (state, action: PayloadAction<boolean>) => {
      state.shouldRefetch = action.payload;
    },
    setFieldsToExport: (state, action: PayloadAction<string[]>) => {
      state.fieldsToExport = action.payload;
    },
  },
});

export const { setShouldRefetch, setCountry, setUserViewer, setFieldsToExport } =
  reportsSlice.actions;

export default reportsSlice.reducer;
