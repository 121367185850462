import { useAuthenticationService } from 'admin-portal-shared-services';
import React from 'react';
import { useFileNotification } from '../../../hooks/useFileNotification';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useHeader } from '../../../hooks/useHeader';
import { useNotificationMassApproval } from '../../../hooks/useNotificationMassApproval';
import { useWebSocket } from '../../../hooks/useWebSocket';

export const WsNotification: React.FC = () => {
  const authentication = useAuthenticationService().getAuthHeader();
  const { user } = useGetUserPreferences();

  const { vendorId, country } = useHeader();
  const { notifyFileStatus } = useFileNotification();
  const { notify: notifyMassApproval } = useNotificationMassApproval();
  const { REACT_APP_BFF_URL } = process.env;

  useWebSocket({
    url: `wss://${
      window.location.hostname
    }${REACT_APP_BFF_URL}/ws/?status=PENDING,ANALYZING,PROCESSING&data=${btoa(
      JSON.stringify({
        vendorId,
        country,
        token: authentication,
      })
    )}&userId=${user.id}&origin=BU,ASYNC_UPDATE`,
    onOpen: () => {
      console.log('WebSocket connection established');
    },
    onMessage: (event) => {
      const data = JSON.parse(event.data);
      data?.origin === 'BU' ? notifyFileStatus(data) : notifyMassApproval(data);
    },
    onClose: () => {
      console.log('WebSocket connection closed');
    },
    onError: () => {
      console.log('WebSocket connection error');
    },
  });

  return null;
};
