import { BASE_PATH } from 'constants/routes/internal/index';
import { PathRouteProps } from 'react-router-dom';
import { UserRoles } from 'types/Auth';

interface IRoutes {
  path: string;
  name?: string;
  canAccess: UserRoles[];
  redirectTo?: PathRouteProps;
}

export const routes: IRoutes[] = [
  {
    path: `${BASE_PATH}/me`,
    canAccess: [UserRoles.ADMIN, UserRoles.EMPLOYEE, UserRoles.LINE_MANAGER],
    name: 'breadCrumb.selfEvaluation',
  },
  {
    path: `${BASE_PATH}/team`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER],
    name: 'breadCrumb.team',
  },
  {
    path: `${BASE_PATH}`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER, UserRoles.EMPLOYEE],
    name: 'breadCrumb.home',
  },
  {
    path: `${BASE_PATH}/evaluate/:userId`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER, UserRoles.EMPLOYEE],
    name: 'breadCrumb.evaluate',
  },
  {
    path: `${BASE_PATH}/report/:userId`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER, UserRoles.EMPLOYEE],
    name: 'breadCrumb.report',
  },
  {
    path: `${BASE_PATH}/feedback/:feedbackId`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER, UserRoles.EMPLOYEE],
    name: 'breadCrumb.feedback',
  },

  {
    path: `${BASE_PATH}/403`,
    canAccess: [UserRoles.ADMIN, UserRoles.LINE_MANAGER, UserRoles.EMPLOYEE],
    name: 'breadCrumb.accessDenied',
  },
];
