import { hasLoadedOptimizely } from '@/utils/toggle';
import { allCountries } from '@config/constants';
import permissionConst from '@config/permissionConst';
import {
  useAuthenticationService,
  useClusterService,
  usePermissionService
} from 'admin-portal-shared-services';

const europeCountries = ['AQ', 'GB', 'ES', 'DE', 'NL', 'BE', 'PH', 'ID'];
const europeCountriesRestricted: string[] = [];
const dtcEnvs = ['Global_DTC'];
const dtcCountries = ['HN', 'SV', 'PA', 'BO', 'PY', 'PE', 'EC', 'CO', 'DO', 'AR', 'MX', 'ZA', 'BR'];

export const getDtcCountries = async (): Promise<string[]> => {
  return dtcCountries;
};

export const getUserCountries = async (): Promise<string[]> => {
  const isUserGlobal = usePermissionService().has(permissionConst.ALL_COUNTRIES);
  if (isUserGlobal) {
    if (isDTC()) return getDtcCountries();
    return allCountries;
  }
  return useAuthenticationService().getSupportedCountries();
};

export const hasMultiZoneAccess = (): boolean => {
  const isUserGlobal = usePermissionService().has(permissionConst.ALL_COUNTRIES);

  return isUserGlobal || useAuthenticationService().getSupportedCountries().length > 1;
};

export const isDTC = (): boolean => {
  return dtcEnvs.includes(useClusterService().getCluster());
};

export const isLocatedInEurope = (country: string): boolean => europeCountries.includes(country);
export const isRestrictedCountry = (country: string): boolean =>
  europeCountriesRestricted.includes(country);

export const getCluster = async (country: string = undefined): Promise<string> => {
  await hasLoadedOptimizely();

  const isEurope = isLocatedInEurope(country ?? localStorage.getItem('selectedCountry'));
  return isEurope ? '/eu' : '';
};

export const getSelectedCountry = (): string => localStorage.getItem('selectedCountry') ?? '';
