import { SegmentedControl, Tabs } from '@hexa-ui/components';
import { MenuItem } from 'Domain/NewMenuItem';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderEmbedProps } from './HeaderEmbed.types';

export function HeaderEmbed({
  reportSlug,
  themeSelected,
  lang,
}: HeaderEmbedProps): React.ReactElement {
  const history = useHistory();
  const {
    slug: themeSlug,
    reportTabs: reportsByTheme,
    title: themeTitle,
    trackingId: themeTrackingId,
  } = themeSelected;
  const { triggerElementClicked } = useElementClickedEvent();

  const handleTriggerEvent = (destinyReportSlug: string, buttonType: string) => {
    const currentReport = reportsByTheme?.find((report) => report.slug === reportSlug);
    const destinyReportSelected = reportsByTheme?.find(
      (report) => report.slug === destinyReportSlug
    );

    const destinyReportIndex = reportsByTheme?.findIndex(
      (report) => report.slug === destinyReportSlug
    );

    triggerElementClicked({
      is_core_event: true,
      page_label: `${themeTitle['en-US']} - ${currentReport?.title['en-US']}`,
      page_name: 'REPORTS',
      element_location_type: 'PAGE',
      element_location_name: 'REPORTS',
      element_location_label: `${themeTitle['en-US']} - ${currentReport?.title['en-US']}`,
      element_label: `${themeTitle['en-US']} - ${destinyReportSelected?.title['en-US']}`,
      element_name: 'REPORT_TAB',
      element_type: buttonType,
      element_position: destinyReportIndex + 1,
      origin_theme_label: themeTitle['en-US'],
      origin_theme_tracking_id: themeTrackingId,
      origin_report_label: currentReport?.title['en-US'],
      origin_report_tracking_id: currentReport?.trackingId,
      destiny_theme_label: themeTitle['en-US'],
      destiny_theme_tracking_id: themeTrackingId,
      destiny_report_label: destinyReportSelected?.title['en-US'],
      destiny_report_tracking_id: destinyReportSelected?.trackingId,
      occurence_id: '2.14',
    });
  };

  const handleChangeTab = (reportSlug: string) => {
    handleTriggerEvent(reportSlug, 'TAB');
    history.push(`/reports/embed/${themeSlug}/${reportSlug}`);
  };

  const options = (themeSelected: MenuItem, lang: string) => {
    const arr: { label: string; value: string }[] = [];
    themeSelected?.reportTabs.map((tab) => arr.push({ label: tab.title[lang], value: tab.slug }));
    return arr;
  };

  const toggleOptions = options(themeSelected, lang);

  const handleToggleClick = (reportSlug: string) => {
    handleTriggerEvent(reportSlug, 'TOGGLE');
    history.push(`/reports/embed/${themeSlug}/${reportSlug}`);
  };

  return themeSelected?.viewMode === 'TAB' ? (
    <Tabs.Root data-testid="tabs-container" defaultValue={reportSlug}>
      <Tabs.List>
        {reportsByTheme?.map((report) => (
          <Tabs.Trigger
            key={report.id}
            value={report.slug}
            onClick={() => handleChangeTab(report.slug)}
          >
            {report.title[lang]}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    </Tabs.Root>
  ) : (
    <SegmentedControl
      data-testid="toggle-container"
      defaultValue={toggleOptions.find((option) => option.value === reportSlug)?.value}
      options={toggleOptions}
      onValueChange={(value) => handleToggleClick(value)}
    />
  );
}
