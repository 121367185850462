import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import LoadingScreen from 'components/atoms/LoadingScreen/LoadingScreen';
import { APPLICATION_TITLE, USER_INFO } from 'constants/optimizely';
import { createContext, useContext, useEffect, useState } from 'react';
import { getUserInfo } from 'services/lib/user/UserService';
import { IAuth, UserRoles } from 'types/Auth';

const AuthContext = createContext<IAuth>({
  roles: [],
  verifyRole: () => true,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export const AuthProvider = ({ children }) => {
  const [roles, setRole] = useState<UserRoles[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const [isUserInfoFTEnabled, setIsUserFeatureToggleEnabled] = useState<boolean>(false);

  const [userInfoFeatureToggleStatus, userInfoFeatureToggleIsLoading] = useFeatureToggleV2(
    USER_INFO,
    APPLICATION_TITLE
  );

  useEffect(() => {
    if (!userInfoFeatureToggleIsLoading) {
      setIsUserFeatureToggleEnabled(userInfoFeatureToggleStatus);
    }
  }, [userInfoFeatureToggleStatus, userInfoFeatureToggleIsLoading]);

  const roleCondition = (response: any) => {
    const roleConditions = {
      isAdmin: UserRoles.ADMIN,
      isManager: UserRoles.LINE_MANAGER,
      employeeId: UserRoles.EMPLOYEE,
    };

    const rolesConditions = [];
    for (const condition in roleConditions) {
      if (response[condition]) {
        rolesConditions.push(roleConditions[condition]);
      }
    }

    return rolesConditions;
  };

  /* istanbul ignore next */
  const verifyRole = (rolesToVerify: UserRoles | UserRoles[]) => {
    return Array.isArray(rolesToVerify)
      ? rolesToVerify.every((role) => roles.includes(role))
      : roles.includes(rolesToVerify);
  };

  useEffect(() => {
    getUserInfo()
      .then((response) => {
        setRole(roleCondition(response));

        if (response.situationStatus) {
          setIsUserActive(response.situationStatus.toUpperCase() === 'ACTIVE');
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={{ roles, verifyRole, isUserActive, isUserInfoFTEnabled }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
