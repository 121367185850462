import {useApi} from '../../api';

export default async function createPromotionService(
  body: FormData
): Promise<any> {
  return await useApi().post({
    url: 'v1/admin/promotions',
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
}
