import blockPoc from './blockPoc';
import campaignEligiblePocs from './campaignEligiblePocs';
import common from './common';
import couponCampaigns from './couponCampaigns';
import disablePoc from './disablePoc';
import enablePoc from './enablePoc';
import unblockPoc from './unblockPoc';
import promotionList from './promotionList';
import promotionDetails from './promotionDetails';
import productType from './productType';

const enUSTranslations = {
  COMMON: common,
  COUPON_CAMPAIGNS: couponCampaigns,
  CAMPAIGN_ELIGIBLE_POCS: campaignEligiblePocs,
  BLOCK_POC: blockPoc,
  UNBLOCK_POC: unblockPoc,
  ENABLE_POC: enablePoc,
  DISABLE_POC: disablePoc,
  PROMOTION_LIST: promotionList,
  PROMOTION_DETAILS: promotionDetails,
  PRODUCT_TYPE_LIST: productType,
};

export default enUSTranslations;
