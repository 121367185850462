import { PageRouteData, PagesIDs } from 'constants/SegmentTracking.constants';
import segment from 'tracking';
import { isNullOrUndefined } from 'utils/isNullOrUndefined/isNullOrUndefined';
import { getReferrer, setReferrer } from 'utils/referrerUtils/referrerUtils';
import { getTrackingDefaultData, validateEventRequiredData } from '../eventsUtils';

export const trackPageView = (pageID: PagesIDs): void => {
  const pageData = PageRouteData[pageID];
  const defaultData = getTrackingDefaultData();

  const referrer = getReferrer();

  if (!isNullOrUndefined(referrer) && validateEventRequiredData(defaultData)) {
    segment.pageViewed({
      ...defaultData,
      ...pageData,
      referrer,
      page_url: document.location.href,
    });
  }

  setReferrer(document.location.href);
};
