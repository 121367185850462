import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  basicActions: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    next: 'Next',
    back: 'Back',
    return: 'Go back',
    save: 'Save',
    send: 'Send',
    finish: 'Finish',
    exitWithoutSaving: 'Exit without saving',
    close: 'Close',
    access: 'Access',
  },
  employee: {
    date: {
      to: 'to',
      evaluate: 'Evaluate date: ',
      feedback: 'Feedback Data: ',
      hiring: 'Hiring date: ',
      lastMovement: 'Last movement date: ',
    },
    info: {
      role: 'Role: ',
      band: 'Band: ',
      manager: 'Manager: ',
    },
  },
  button: {
    evaluate: 'Evaluate',
    report: 'Report',
    selfEvaluate: 'Self-Evaluate',
    feedback: 'Feedback',
  },
  text: {
    comments: 'Comments',
    notFound: 'Page Not Found',
    backHome: 'Back To Home',
    selfEvaluateTitle: 'Self-evaluation Cycle',
    submit: 'Are you sure you want to submit this evaluation?',
    reportFeedbackTitle: "doesn't have an review!",
    reportFeedbackDescription: 'To access the report, first respond to a review.',
    homeMessageCard:
      'The evaluation cycle takes place in three stages, the first is called Self-evaluation, where you will answer a form about yourself, then there is the Evalution where your Line Manager will also answer this form, finally the Feedback takes place where you will talk about the answers given.',
    homeMessageCardUserInactive: 'Oooops, we are in trouble, please contact the administrator.',
    managerResponse: "Manager's response",
    employeeResponse: "Employee's response",
    finalComment: 'Final comment',
    success: 'Success!',
    warning: 'Warning',
    error: 'Error',
    confirmation: 'Confirmation',
    welcome: 'Welcome',
    search: 'Search by name',
    errorInfo: `There's no information to show`,
    loadErrorData: 'Unable to load data, please try again later',
  },
  validation: {
    name: 'The name entered is invalid, please enter a correct name',
    notEmpty: 'This field cannot be empty, please fill in the field',
  },
  POD: {
    filterByPod: 'Filter by POD',
  },
  ViewAs: {
    lineManager: 'View as Line Manager',
    admin: 'View as Admin',
  },
  breadCrumb: {
    home: 'Home',
    team: 'Evaluate Management',
    evaluate: 'Evaluate',
    report: 'Report',
    selfEvaluation: 'Self-evaluation Cycle',
    feedback: 'Feedback',
    accessDenied: 'Access Denied',
  },
  selectOptions: {
    allPods: 'All Pods',
  },
  userInfoCard: {
    role: 'Role',
    band: 'Band',
    manager: 'Manager',
  },
  evaluationAlert: {
    message: 'It is not possible to end the evaluation without answering all the questions.',
  },
  reportPage: {
    evaluationNotFound: 'Evaluation Completed not found.',
  },
  evaluationGraph: {
    emptyGraph: 'We do not have a rating available to display on the chart.',
  },
  evaluations: {
    evaluate: 'Evaluation',
    selfEvaluate: 'Self-Evaluation',
    feedback: 'Feedback',
  },
  feedback: {
    cycleDate: 'Cycle Date: ',
    from: 'From',
    to: 'to',
    successModalDescription:
      'The feedback was carried out successfully and can be viewed by the employee.',
    viewReport: 'View Report',
    goManagement: 'Go to Evaluation Management',
    warningModalDescription: "You haven't saved the Feedback. Do you want to go out anyway?",
    errorModalDescription: 'Could not finish the feedback, please try again later.',
    messageErrorFeedback: 'This field is required',
  },
  evaluationPage: {
    warningModalDescription: "You haven't saved the Evaluation. Do you want to go out anyway?",
  },
  selfEvaluatePage: {
    warningModalDescription: "You haven't saved the Self-Evaluation. Do you want to go out anyway?",
  },
  feature: {
    evaluateManagement: {
      title: 'Evaluate Management',
      description:
        'Evaluate your team, visualize results, and do 1:1 feedback with your team members!',
    },
    admin: {
      title: 'Admin',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut ex risus.',
    },
  },
};

export default enUS;
