import { useNavigate } from 'react-router-dom';

import { PATH_BASE, PATH_HOME } from '@/consts/route';

export const useVisitDetails = () => {
  const navigate = useNavigate();

  const handleClickGoBackButton = () => {
    navigate(`${PATH_HOME}${PATH_BASE}`);
  };

  return {
    handleClickGoBackButton,
  };
};
