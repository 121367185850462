import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperTotalVisitsCard = styled(Card)`
  width: 100%;
  padding: ${({ theme }) => theme.measure.space['space-4']};
`;

export const WrapperTotalCount = styled.div`
  margin-top: ${({ theme }) => theme.measure.space['space-6']};
`;
