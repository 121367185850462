import { ClipboardButton } from '@components/clipboardButton';
import { ChevronDown, Info } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const RequestorContainer = styled('div', {
  display: 'flex',
  gap: '8px',
});

export const CopyRequestorContainer = styled('div', {
  alignSelf: 'center',
});

export const CopyRequestorButton = styled(ClipboardButton, {
  backgroundColor: 'rgba(20,20,20,0.08)',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  padding: '5px',
});

export const MoreDetailsModified = styled('div', {
  display: 'flex',
  gap: '4px',
});

export const ExpandArrow = styled(ChevronDown, {
  marginLeft: '8px',
  color: 'blue',
  variants: {
    expanded: {
      true: {
        transform: 'rotate(-180deg)',
      },
      false: {
        transform: 'rotate(0deg)',
      },
    },
  },
});

export const InfoMoreDetails = styled(Info, {
  color: 'gray',
});

export const RecentActivityItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const RequestorValue = styled('span', {
  wordBreak: 'break-word',
  marginLeft: '4px',
});
