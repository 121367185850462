import MessageMap from 'i18n/i18n.d';

const en: MessageMap = {
  Commons: {
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
  },
  ErrorMessages: {
    DATA_NOT_FOUND: 'Data has not been loaded.',
    ERROR_FETCH_DATA:
      'An error occurred while trying to fetch the data. Refresh the page and try again.',
  },
  PageTitles: {
    PAYMENTS: 'Payments',
    WORKFLOW: 'Workflow',
    CREATE: 'Create new Workflow',
  },
  downloadModal: {
    titleHeader: 'Email report now',
    description: 'The file containing the filtered data will be sent to your corporate email.',
    fieldEmail: 'Your email',
    fieldFileFormat: 'File format',
    sendButton: 'Send',
    cancelButton: 'Cancel',
  },
  scheduleModal: {
    titleHeader: 'Schedule delivery',
    description: 'Receive the file in your email',
    fieldFileFormat: 'File format',
    fieldFrequency: 'Frequency',
    subFieldFrequency: 'Recurs every week on:',
    fieldDelivery: 'Delivery time',
    fieldEmail: 'Email',
    sendButton: 'Schedule',
    cancelButton: 'Cancel',
  },
  reportsPage: {
    pageTitle: 'Reports',
    downloadButton: 'Download',
    scheduleButton: 'Schedule',
    filterButton: 'Filter',
    table: {
      header: {
        date: 'Date',
        description: 'Description',
        amount: 'Amount',
        status: 'Status',
        actions: 'Actions',
      },
    },
  },
  homePage: {
    pageTitle: 'Welcome to Payments Hub',
  },
  createWorkflowPage: {
    sideMenu: {
      label: 'Select a provider',
      addProviderButton: 'Add provider',
      emptySelect: 'Please select a provider before adding.',
      providerAlreadyAdded: 'Provider {providerName} already added',
      errorMessage: 'No adapters available. Select a different country.',
    },
    providerCard: {
      succeeded: 'Succeeded',
      declined: 'Declined',
      error: 'Error',
    },
    dialog: {
      title: 'Remove provider',
      content: 'Are you sure you want to remove this provider?',
      confirmButton: 'Remove',
      cancelButton: 'Cancel',
    },
  },
};

export default en;
