import { Crosshair, Globe, Phone, Settings, World } from '@admin-portal-shared-components/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { FC, useCallback, useMemo } from 'react';
import { BACKEND_SERVICES_PAGE, BASE_PATH, MOBILE_PAGE } from '../../consts/routes';
import { urlHelper } from '../../helpers';
import { StyledContainer } from './styles';

export const ConnectedWrapper: FC = ({ children }) => {
  const buildUrl = useCallback((path: string): string => urlHelper.urlBuilder(path, {}), []);
  const globalLinks: any = useMemo(
    () => [
      {
        title: 'Operations',
        path: buildUrl(BASE_PATH),
        icon: () => <Crosshair />,
        enabled: true,
        id: 'tools-menu-account-details',
      },
      {
        title: 'Backend Services',
        path: buildUrl(BACKEND_SERVICES_PAGE),
        icon: () => <Globe />,
        enabled: true,
        id: 'tools-menu-vendors',
      },
      {
        title: 'Cloud Engineering',
        path: '',
        icon: () => <Settings />,
        enabled: true,
        id: 'tools-menu-account-details',
      },
      {
        title: 'Mobile',
        path: buildUrl(MOBILE_PAGE),
        icon: () => <Phone />,
        enabled: true,
        id: 'tools-menu-account-details',
      },
      {
        title: 'Web',
        path: '',
        icon: () => <World />,
        enabled: true,
        id: 'tools-menu-account-details',
      },
    ],
    []
  );

  useSidebar({ items: globalLinks, utils: [] });
  return <StyledContainer sidebar>{children}</StyledContainer>;
};
