import { CreditRecommended, CreditSettings, Files } from '../../components/icons';
import { SideBarItems } from '../../interfaces';
import { keys } from '../toggles';

export const sidebar: SideBarItems = {
  management: {
    icon: CreditSettings,
    title: 'routes.management',
    path: '/bees-credit-management/management',
    toggle: keys.cm_has_management,
  },
  credit: {
    icon: CreditRecommended,
    title: 'routes.credit',
    path: '/bees-credit-management/credit',
    permission: 'pages.credit',
  },
  files: {
    icon: Files,
    title: 'routes.files',
    path: '/bees-credit-management/files',
    permission: 'tables.files',
  },
};
