import { Alert, Paragraph, ProgressTracker } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  StepProps,
  StepsMapping,
  UploadStatus,
  UploadTimeline,
  UploadTimelineSteps,
} from '../../../../interfaces';
import { useUpload } from '../Context';

export const Timeline: React.FC = () => {
  const { formatMessage } = useIntl();

  const { progress, uploaded } = useUpload();

  const [showAlert, setShowAlert] = useState<boolean>(true);

  const stepsMapping: StepsMapping = {
    [UploadTimelineSteps.FILE_UPLOAD]: [UploadStatus.PENDING, UploadStatus.UPLOADED],
    [UploadTimelineSteps.FILE_ANALYSIS]: [
      UploadStatus.ANALYZING,
      UploadStatus.ANALYZED,
      UploadStatus.FAILED,
    ],
    [UploadTimelineSteps.POC_UPDATE]: [
      UploadStatus.PROCESSING,
      UploadStatus.DONE,
      UploadStatus.FAILED,
    ],
  };

  const isLoading =
    uploaded.fileId &&
    (progress.status === UploadStatus.ANALYZING || progress.status === UploadStatus.PROCESSING);

  const { current } = Object.entries(stepsMapping).reduce<UploadTimeline>(
    (acc, [step, statuses]) => {
      if ((statuses as string[]).includes(progress.status)) {
        acc.current = Number(step) as UploadTimelineSteps;
      }

      return acc;
    },
    { current: UploadTimelineSteps.FILE_UPLOAD }
  );

  const completed = Object.values(UploadTimelineSteps).filter((step: number) => step < current);

  const Step = ({ index, message, completed }: StepProps) => (
    <ProgressTracker.Step index={index} completed={completed}>
      <Paragraph size="basis">{formatMessage({ id: message })}</Paragraph>
    </ProgressTracker.Step>
  );

  return (
    <React.Fragment>
      <ProgressTracker.Root currentStep={current} orientation="horizontal" size="large">
        <ProgressTracker.Header>
          <Step
            index={UploadTimelineSteps.FILE_UPLOAD}
            message="pages.upload.uploader.steps.fileUpload"
            completed={completed.includes(UploadTimelineSteps.FILE_UPLOAD)}
          />

          <Step
            index={UploadTimelineSteps.FILE_ANALYSIS}
            message="pages.upload.uploader.steps.fileAnalysis"
            completed={completed.includes(UploadTimelineSteps.FILE_ANALYSIS)}
          />

          <Step
            index={UploadTimelineSteps.POC_UPDATE}
            message="pages.upload.uploader.steps.pocUpdate"
            completed={completed.includes(UploadTimelineSteps.POC_UPDATE)}
          />
        </ProgressTracker.Header>
      </ProgressTracker.Root>

      {showAlert && isLoading && (
        <Alert
          type="info"
          showCloseButton
          css={{ width: '70%' }}
          message={formatMessage({ id: 'pages.upload.uploader.alertMessage' })}
          onClose={() => setShowAlert(false)}
        />
      )}
    </React.Fragment>
  );
};
