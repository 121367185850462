import {
  useIsFeatureToggleServiceReady,
  usePermissionService,
  useSidebar,
} from 'admin-portal-shared-services';
import { SCREEN_POSITION_MANAGEMENT } from 'constants/';
import { useVerifyFeatureToggle } from 'hooks/useVerifyFeatureToggle';
import LoadingPage from 'pages/LoadingPage';
import { useAuth } from 'providers';
import { useAppHeader } from 'providers/AppHeaderProvider';
import { siderbarItems } from 'providers/SidebarConfigProvider/settings';
import { Suspense, useLayoutEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routes } from './utils/routes';

const Router = (): JSX.Element => {
  const { setOptionalButtons } = useAppHeader();
  const { userHasDevParametersPermission } = useAuth();

  const location = useLocation();
  const permissionService = usePermissionService();
  const isFeatureToggleServiceReady = useIsFeatureToggleServiceReady() !== false;

  const [previousPathname, setPreviousPathname] = useState<string>(null);

  const { isFeatureToggleEnabled: isPositionManagementFtEnabled } = useVerifyFeatureToggle({
    featureToggle: SCREEN_POSITION_MANAGEMENT,
  });

  const memoListMenuWithAccess = useMemo(() => {
    const filteredItemsByPermissions = siderbarItems.filter((listItem) => {
      if (!userHasDevParametersPermission && listItem.id === 'devs-item') return;
      if (!isPositionManagementFtEnabled && listItem.id === 'positions-management-item') return;
      if (listItem.scopes) return permissionService.hasOneOfScopes(listItem.scopes);

      return listItem.id === 'home-item';
    });

    return filteredItemsByPermissions;
  }, [
    userHasDevParametersPermission,
    siderbarItems,
    permissionService,
    isPositionManagementFtEnabled,
  ]);

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  useSidebar(memoSidebarParams);
  useLayoutEffect(() => {
    if (location.pathname !== previousPathname) setOptionalButtons(null);
    setPreviousPathname(location.pathname);
  }, [location]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        {isFeatureToggleServiceReady &&
          routes.map(({ path, Component }, key) => (
            <Route path={path} key={key} element={Component} />
          ))}
      </Routes>
    </Suspense>
  );
};
export default Router;
