import { ReactNode } from 'react';
import { startFeatureFlagService } from 'services/lib/featureFlagsService/featureFlagsService';

type ServicesWrapperProps = {
  optimizelyKey: string;
  applicationTitle: string;
  children?: ReactNode;
};

const ServicesWrapper = ({ optimizelyKey, applicationTitle, children }: ServicesWrapperProps) => {
  /* istanbul ignore next  */
  if (!window.services?.['FeatureToggleOptimizelyV2']?.[applicationTitle]) {
    startFeatureFlagService(applicationTitle, optimizelyKey);
  }

  return <>{children}</>;
};

export default ServicesWrapper;