import permissionConstants from '@config/permissionConstants';
import { useItemInformationForm } from '@hooks/forms/useItemInformationForm/useItemInformationForm';
import { useHasPermission } from 'admin-portal-shared-services';
import { FormProvider } from 'react-hook-form';
import ItemInformationForm from './ItemInformationForm/ItemInformationForm';

export interface ItemInformationProps {
  data?: ItemDetailsResponse;
  isLoading: boolean;
}

export const ItemInformation = ({ data, isLoading }: ItemInformationProps): JSX.Element => {
  const { form, onSubmit, onEdit, onCancel, isEditing, isUpdating } = useItemInformationForm({
    data,
  });
  const hasAccessToEditItem = useHasPermission([permissionConstants.ITEM_WRITE]);

  return (
    <FormProvider {...form}>
      <ItemInformationForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        onEdit={onEdit}
        isEditing={isEditing}
        isUpdating={isUpdating}
        isEditionDisabled={isLoading}
        canEdit={hasAccessToEditItem}
      />
    </FormProvider>
  );
};
