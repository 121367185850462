import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {
  minWidth: '34px',
  minHeight: '34px',
  maxWidth: '34px',
  maxHeight: '34px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'yellow',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  color: '$neutral50',
  overflow: 'hidden',
  '& svg': {
    minWidth: '28px',
    minHeight: '28px',
    maxWidth: '28px',
    maxHeight: '28px',
    color: '#000',
  },
});

export const Styled = {
  Wrapper,
};
