/* eslint-disable camelcase */
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@bees-force-shared/theme';

import { EnvProvider } from './components';
import { EnvConfig } from './components/EnvProvider/EnvProvider.types';
import { IntlProvider } from './i18n';
import { routes } from './Routes';

const router = createBrowserRouter(routes, {
  future: {
    v7_relativeSplatPath: true,
  },
});

// eslint-disable-next-line import/no-default-export
export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <ThemeProvider>
          <RouterProvider router={router} future={{ v7_startTransition: true }} />
        </ThemeProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
