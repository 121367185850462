import {  Grid, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../hook/useWindowDimensions';
import StyledInput from '../../../../components/Input/Input';
import StyledButton from '../../../../components/Button/Button';
import StyledSelect from '../../../../components/Select/Select';
import StyledTextarea from '../../../../components/Textarea/Textarea';

export default function EditTournamentTab() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const StyledPaddding = styled('div', {
    paddingBottom: '1rem',
  });

  const StyledGridContainer = styled(Grid.Container, {
    margin: '0',
  });

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  return (
    <StyledGridContainer style={{margin:'0'}} type="fluid">
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'contents' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'flex-start',
            display: width <= 1024 ? 'contents' : '',
            padding: '2rem 0 1rem 0',
            width: '100%',
          }}
        >
          <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid.Container
              type="fluid"
              style={{
                width: '100%',
                height: 'max-content',
                margin: 0,
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <StyledPaddding>
                <StyledSelect
                  width={'100%'}
                  label="Languages"
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                >
                  <Select.Option value="en">English</Select.Option>
                  <Select.Option value="br">Portuguese</Select.Option>
                  <Select.Option value="fr">French</Select.Option>
                  <Select.Option value="de">German</Select.Option>
                </StyledSelect>
              </StyledPaddding>
              <StyledPaddding>
                <StyledInput
                  size="large"
                  label="Name*"
                  width={'100%'}
                  hint="The name of Tournament. Such as: FIFA Worldcup 3022 or Brazil National Soccer Tournament 3023. "
                  placeholder="Insert you text here"
                />
              </StyledPaddding>
              <StyledPaddding>
                <StyledTextarea
                  style={{ height: '222px', resize: 'none' }}
                  label="Description"
                  width={'100%'}
                  placeholder="Insert you text here"
                />
              </StyledPaddding>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
            }}
          >
             <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
          </div>
        </Grid.Item>
      </Grid.Item>
    </StyledGridContainer>
  );
}
