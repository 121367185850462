import { useUserMetadata } from 'admin-portal-shared-services';
import { ROUTER_BASENAME } from 'config/constants';
import { useStoreMap } from 'effector-react';
import useSideBar from 'hooks/useSideBar/useSideBar';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoadingView from './components/LoadingView/LoadingView';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SelectCountryModal from './components/SelectCountry/SelectCountryModal/SelectCountryModal';
import intlFormatter from './i18n/formatter';
import useStyles from './Router.styles';
import LoadingStore from './stores/Loading/LoadingStore';

const InventoryImportPage = lazy(() => import('pages/InventoryImport/InventoryImport'));
const SummaryPage = lazy(() => import('pages/Summary/Summary'));
const ClientManagementPage = lazy(() => import('pages/ClientManagement/ClientManagement'));
const StockBalancePage = lazy(() => import('pages/StockBalance'));

const LimitsPerClient = lazy(() => import('pages/LimitsPerClient/LimitsPerClient'));

const ATPParamtersPage = lazy(() => import('pages/ATPParameters/ATPParameters'));
const ATPParamtersFormPage = lazy(() => import('pages/ATPParameters/Form/StockReservationParametersForm'));

const FileMonitoringPage = lazy(() => import('pages/FileMonitoring/FileMonitoring'));

const NotFoundPage = lazy(() => import('pages/NotFound/NotFound'));

const DEFAULT_LANGUAGE = 'en-US';
const Router = (): JSX.Element => {

  const [triggerOptRender, setTriggerOptRender] = React.useState<boolean>(false);

  React.useEffect(() => {

    const timeout = setTimeout(() => {
      setTriggerOptRender(true);
    }, 500);

    return () => {
      clearInterval(timeout)
    }

  }, [])

  useSideBar();

  const { content, containerBody } = useStyles();

  const { data } = useUserMetadata();

  React.useEffect(() => {
    intlFormatter.updateIntlProvider(data?.profile?.preferredLanguage ?? DEFAULT_LANGUAGE);
  }, [data?.profile?.preferredLanguage]);

  const { show, message } = useStoreMap({
    store: LoadingStore,
    keys: [],
    fn: (state) => state,
  });

  return (
    <>
      {triggerOptRender && <div />}
      <BrowserRouter>
        <LoadingView show={show} message={message} />
        <div className={containerBody}>
          <Suspense fallback={<LoadingView show={show} />}>
            <div className={content}>
              <Switch>

                <Route
                  exact
                  path={ROUTER_BASENAME}
                  render={() => (
                    <PrivateRoute>
                      <InventoryImportPage />
                    </PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/not-found`}
                  component={() => (
                    <NotFoundPage />
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/summary`}
                  render={() => (
                    <PrivateRoute>
                      <SummaryPage />
                    </PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/limits-per-client`}
                  render={() => (
                    <PrivateRoute>
                      <LimitsPerClient />
                    </PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/client-management`}
                  render={() => (
                    <PrivateRoute>
                      <ClientManagementPage />
                    </PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/stock-balance`}
                  render={() => (
                    <PrivateRoute>
                      <StockBalancePage />
                    </PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/atp-parameters`}
                  render={() => (
                    <PrivateRoute><ATPParamtersPage /></PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/atp-parameters/form/:parameterId?`}
                  render={() => (
                    <PrivateRoute><ATPParamtersFormPage /></PrivateRoute>
                  )}
                />

                <Route
                  exact
                  path={`${ROUTER_BASENAME}/file-monitoring`}
                  render={() => (
                    <PrivateRoute><FileMonitoringPage /></PrivateRoute>
                  )}
                />

              </Switch>

            </div>
          </Suspense>
        </div>
        <SelectCountryModal />
      </BrowserRouter>
    </>
  );
};
export default Router;
