import { useIntl } from 'react-intl';

import { Heading } from '@hexa-ui/components';

import { NEXT_VISIT_TEST_IDS } from '../consts/VisitDetailsCardConsts';
import * as Styled from './NextVisit.styles';

export const NextVisit = () => {
  const { formatMessage } = useIntl();
  return (
    <Styled.WrapperNextVisitCard border="medium" elevated="minimal">
      <Heading data-testid={NEXT_VISIT_TEST_IDS.HEADER} size="H3">
        {formatMessage({ id: 'Components.VisitDetails.Title.NEXT_VISIT' })}
      </Heading>
    </Styled.WrapperNextVisitCard>
  );
};
