import React, { createContext, useContext, useMemo, useState } from 'react';

export interface LanguageContextValue {
  selectedLanguage?: LanguageOption;
  availableLanguages: LanguageOption[];
  setSelectedLanguage: (language?: LanguageOption) => void;
  setAvailableLanguages: (languages: LanguageOption[]) => void;
}

interface LanguageProviderProps {
  children: React.ReactNode;
}

const LanguageContext = createContext<LanguageContextValue>({
  selectedLanguage: undefined,
  availableLanguages: [],
  setSelectedLanguage: () => undefined,
  setAvailableLanguages: () => [],
});

const LanguageProvider = ({ children }: LanguageProviderProps): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);
  const [availableLanguages, setAvailableLanguages] = useState<LanguageOption[]>([]);

  const value = useMemo(
    () => ({ selectedLanguage, setSelectedLanguage, availableLanguages, setAvailableLanguages }),
    [selectedLanguage, setSelectedLanguage, availableLanguages, setAvailableLanguages]
  );

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

const useLanguageContext = (): LanguageContextValue => {
  const context = useContext(LanguageContext);
  return context;
};

export { LanguageProvider, useLanguageContext };
