import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import { PageViwedEventProps, PageViwedEventReturn } from './usePageViewedEvent.types';

/* istanbul ignore next */
export default function usePageViewed(): PageViwedEventReturn {
  const { pageViewed } = useSegment();

  const {
    page_url,
    user_country,
    user_email,
    user_id,
    user_affiliation,
    vendor_country,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
    origin_workspace_label,
    origin_workspace_tracking_id,
  } = useSegmentProperties();

  const triggerPageViewed = ({
    app_display_name,
    app_id,
    is_core_event,
    page_label,
    page_name,
    referrer,
    workspace_count,
    theme_count,
    origin_theme_tracking_id,
    origin_theme_label,
    report_count,
    origin_report_label,
    origin_report_tracking_id,
    has_bt_guide,
    occurence_id,
    preferred_language,
  }: PageViwedEventProps) => {
    pageViewed({
      app_display_name,
      app_id,
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      referrer,
      workspace_count,
      origin_workspace_label,
      origin_workspace_tracking_id,
      theme_count,
      origin_theme_tracking_id,
      origin_theme_label,
      report_count,
      origin_report_label,
      origin_report_tracking_id,
      has_bt_guide,
      occurence_id,
      preferred_language,
    });
  };

  return {
    triggerPageViewed,
  };
}
