/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  APP_ID,
  APP_NAME,
  HierarchyGroups,
  NO_HIERARCHY_GROUP_FOUND,
} from 'constants/SegmentTracking.constants';
import { VendorFull } from 'domains/Vendor';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import {
  getSelectedCountry,
  getSelectedVendor,
  getSelectedVendorName,
  getUserEmail,
} from 'utils/persistMetaData';

// #region deprecated events utils

type SelectedCountry = string;

interface TrackingBasicData {
  selected_country: SelectedCountry;
  screen_name: string;
  vendor_id: string;
  vendor_name: string;
  user_email: string;
}
/**
 * @deprecated This tracking event or function is deprecated in favor of the new default tracking events (pageView, elementClicked, searchPerformed)
 */
export const formatTrackingBasicData = (
  screenName: string,
  country?: string
): TrackingBasicData => {
  const selectedCountry = country || getSelectedCountry();
  const selectedVendorId = getSelectedVendor();
  const selectedVendorName = getSelectedVendorName();
  const userEmail = getUserEmail();

  return {
    selected_country: selectedCountry as SelectedCountry,
    screen_name: screenName,
    vendor_id: selectedVendorId,
    vendor_name: selectedVendorName,
    user_email: userEmail,
  };
};

// #endregion deprecated events utils

// #region new default events utils

interface TrackingDefaultData {
  is_core_event: boolean;
  vendor_country: string;
  vendor_display_name: string;
  vendor_id: string;
  user_email: string | null;
  user_country: string | null;
  user_permission_group: string;
  app_display_name: string;
  app_id: string;
  manufacturer_country: null;
  manufacturer_display_name: null;
  manufacturer_id: null;
  manufacturer_service_model: null;
  manufacturer_tier_name: null;
  vendor_service_model: string | null;
  vendor_tier_name: string | null;
  view_level: string;
}

export const getVendorAdditionalData = (vendorId: string, vendorList?: VendorFull[]) => {
  const selectedVendor = vendorList?.find((vendor) => vendor.id === vendorId);

  return {
    vendor_display_name: selectedVendor ? selectedVendor?.displayName : '',
    vendor_service_model: selectedVendor ? selectedVendor?.serviceModel : null,
    vendor_tier_name: selectedVendor ? selectedVendor?.tier.name : null,
  };
};

export const getHighestAccessGroup = (groups: string[] = []): string => {
  const highestPriorityGroup = groups.reduce((highestPriority, currentItem) => {
    // Find the index (priority) of current item in ordered list
    const currentPriority = HierarchyGroups.findIndex(
      (orderedItem) => orderedItem.toLowerCase() === currentItem.toLowerCase()
    );

    // If item isn't in priority list, ignore it
    if (currentPriority === -1) {
      return highestPriority;
    }

    // If we haven't found a valid item yet, use current item
    if (!highestPriority) {
      return currentItem;
    }

    // Find priority of our current highest priority item
    const highestPriorityValue = HierarchyGroups.findIndex(
      (orderedItem) => orderedItem.toLowerCase() === highestPriority.toLowerCase()
    );

    return currentPriority < highestPriorityValue ? currentItem : highestPriority;
  }, undefined as string | undefined);

  return highestPriorityGroup || NO_HIERARCHY_GROUP_FOUND;
};

export const getTrackingDefaultData = (): TrackingDefaultData => {
  const MetaData = MetaDataStore.getState();

  const userPermissionGroups = MetaData.groups;

  const countrySelected = MetaData.selectedCountry;

  const userEmail = MetaData.userData.email;
  const userCountry = MetaData.userData.country;

  const vendorId = MetaData.selectedVendor;
  const vendorAdditionalData = getVendorAdditionalData(
    MetaData.selectedVendor,
    MetaData.listVendors
  );

  const staticInfo = {
    app_display_name: APP_NAME,
    app_id: APP_ID,
    is_core_event: false,
    manufacturer_country: null,
    manufacturer_display_name: null,
    manufacturer_id: null,
    manufacturer_service_model: null,
    manufacturer_tier_name: null,
    view_level: 'SINGLE_VENDOR',
  };

  const result = {
    vendor_country: countrySelected,
    vendor_id: vendorId,
    user_email: userEmail,
    user_country: userCountry,
    user_permission_group: getHighestAccessGroup(userPermissionGroups),
    ...vendorAdditionalData,
    ...staticInfo,
  };

  return result;
};

export const validateEventRequiredData = (eventData: TrackingDefaultData): boolean => {
  const requiredProperties: (keyof TrackingDefaultData)[] = [
    'vendor_country',
    'vendor_display_name',
    'vendor_id',
    'vendor_service_model',
    'vendor_tier_name',
    'user_email',
    'user_country',
    'user_permission_group',
  ];

  const missingProps = requiredProperties.filter((prop) => {
    const value = eventData[prop];
    return value === undefined || value === null;
  });

  return missingProps.length === 0;
};

// #endregion new default events utils
