import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ScreenName } from '@/domains'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import { UpdateExclusionRuleDTO } from '../RulesService.types'
import { updateExclusionRule } from '../RulesService'

export const useUpdateExclusionRule = (): {
	isLoading: boolean
	putExclusionRule: (payload: UpdateExclusionRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const selectedZone = useCurrentZone()
	const { selectedTierKey, currentTier } = useCurrentTier()

	const { isLoading, mutate: putExclusionRule } = useMutation(
		(payload: UpdateExclusionRuleDTO) => {
			return updateExclusionRule({ ...payload, vendorId })
		},
		{
			onSuccess: async (response) => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('rules:CREATE_EARNING_RULES.EDITED_RULE_SUCCESSFUL_MESSAGE'),
				})
				await queryClient.invalidateQueries(['exclusionRules'])

				const exclusionRuleStatus = response.status

				if (exclusionRuleStatus === 'DRAFT') {
					AnalyticsService.events.ruleSaved({
						amount: null,
						rule_type: 'Exclusion List',
						earning_rule_description: response.description,
						audience_id: String(response.audienceId),
						earning_rule_id: response.ruleId!,
						points: null,
						screen_name: ScreenName.EditExclusionRule,
						tier: currentTier ? currentTier?.label : selectedTierKey,
						user: getUserEmail(),
						vendor: response.vendorId!,
						zone: selectedZone,
					})
				}

				if (exclusionRuleStatus === 'ACTIVE') {
					AnalyticsService.events.ruleUpdated({
						amount: null,
						rule_type: 'Exclusion List',
						earning_rule_description: response.description,
						earning_rule_id: response.ruleId!,
						points: null,
						screen_name: ScreenName.EditExclusionRule,
						tier: currentTier ? currentTier?.label : selectedTierKey,
						user: getUserEmail(),
						vendor: response.vendorId!,
						zone: selectedZone,
					})
				}

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: t('rules:CREATE_EARNING_RULES.CREATE_RULE_ERROR_MESSAGE'),
				})
			},
		},
	)

	return {
		isLoading,
		putExclusionRule,
	}
}
