import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  test: {
    translate: "Translate",
    missing: "Only English option available",
  },
  countries: {
    AR: { label: "Argentina", code: "AR" },
    BO: { label: "Bolivia", code: "BO" },
    BR: { label: "Brazil", code: "BR" },
    CA: { label: "Canada", code: "CA" },
    CO: { label: "Colombia", code: "CO" },
    DO: { label: "Dominican Republic", code: "DO" },
    EC: { label: "Ecuador", code: "EC" },
    HN: { label: "Honduras", code: "HN" },
    MX: { label: "Mexico", code: "MX" },
    PA: { label: "Panama", code: "PA" },
    PE: { label: "Peru", code: "PE" },
    SV: { label: "El Salvador", code: "SV" },
    ZA: { label: "South Africa", code: "ZA" },
    US: { label: "United States", code: "US" },
  },
  bread_crumb: {
    experimentation_platform: "Experiment List",
    new_experiment: "New Experiment",
    edit_experiment: "Edit Experiment",
  },
  page_title: {
    experiments_list: "Experiment list",
    experiment_details: "Experiment details",
    create_experiment: "Create new experiment",
    edit_experiment: "Edit experiment",
  },
  experiment_list: {
    columns: {
      experiment_name: "Experiment name",
      external_key: "External key",
      period: "Period",
      status: "Status",
      country: "Country",
      start_date: "Start date",
      estimated_end_date: "Estimated end date",
      confidence_level: "Confidence level",
      action: "Action",
    },
    action_list: {
      show_details: "Show details",
      export_json_file: "Export JSON file",
      pause_experiment_validation: "Pause test",
    },
    buttons: {
      create_experiment: "Add new experiment",
      reports: "Go to reports",
    },
  },
  experiment_details: {
    modals: {
      pause_experiment_paragrapg:
        "The test will be paused in the app for up to 6 hours. Make sure both web and app are running correctly before pausing.",
      pause_experiment_via_edit_link_paragrapg:
        "The test version will be paused and you will need to send it again.",
      submit_for_approval:
        "The experiment will be submitted for approval. Once approved, it will be published automatically.",
    },
    status: {
      status: "Status",
      confidence_level: "Confidence level",
      go_to_reports: "Go to reports",
      tooltip_text:
        "This indicates the reliability of the experiment results. Some experiments might conclude when this level reaches 80%, others only at 95%.",
    },
    external_key: {
      label: "External Key",
      copy_external_key: "Copy External key",
      copied: "Copied",
    },
    schedule: {
      schedule: "Schedule",
      start_date: "Start date",
      end_date: "End date",
      edit_date: "Edit date",
      duration: "Duration",
      duration_paragraph:
        "The total duration of the experiment depends on the time needed to reach a statistically valid sample from your target audience.",
      description: "Description",
      empty_estimated_end_date:
        "You may add an estimated end date once the experiment starts.",
    },
    basic_information: {
      basic_information: "Basic Information",
      name: "Name",
      hypothesis: "Hypothesis",
      country: "Country",
      description: "Description",
      documentation: "Confluence documentation link",
    },
    metrics: {
      metrics: "Metrics",
      primary_metric: "Primary metric",
      secondary_metric: "Secondary metrics",
      guardrails: "Guardrails",
      custom_guardrails: "Custom guardrails",
    },
    audience_selection: {
      audience_selection: "Audience selection",
      audience_title: "Audience name",
      estimated_pocs_target: "Estimated POCs targeted",
      percentage_roll_out: "Allocation percentage",
      country_as_audience_title: "Audience",
      country_as_audience_text: "Experiment selected country",
    },
    variants: {
      variants: "Variants",
      variant: "Variant",
      variant_name: "Variant name",
      description: "Description",
      type: "type",
      control_version: "Control version",
      control_version_text: "This represent the current version in production",
    },
    experiment_launch: {
      experiment_launch: "Experiment launch",
      info_message:
        'This experiment includes variants with the "application code" type and will start once the final code is released into production.',
      start_date: "Start date",
      estimated_end_date: "Estimated end date",
      estimated_end_date_text: "Add estimated end date post-experiment launch",
      duration: "Duration",
      duration_text:
        "The total duration of the experiment depends on the time needed to reach a statistically valid sample from your target audience.",
    },
    edit_estimated_end_date: {
      title: "Edit estimated end date",
      cancel: "Go back",
      continue: "Edit",
      cxl_calculator: {
        previous_text: "Input estimated end date according to the",
        link: "CXL calculator",
      },
    },
    results: {
      title: "Experiment results",
      sub_title: "Findings",
    },
    buttons: {
      cancel_button_text: "Cancel experiment",
      finish_button_text: "Finish experiment",
      end_experiment: "End experiment",
      enter_results_button_text: "Enter results",
      export_json_file: "Export JSON file",
    },
  },
  empty_session: {
    audience_message:
      "No audience has been selected yet. Edit experiment to select an audience.",
    variants_message:
      "No variants have been added yet. Edit experiment to add a variant.",
  },
  experiment_status: {
    DRAFT: "Draft",
    READY: "Ready",
    STARTING: "Starting",
    STARTED: "In progress",
    IN_VALIDATION: "Sent for validation",
    ERROR: "Error",
    PAUSED: "Paused",
    FINISHED: "Completed",
    CANCELLED: "Cancelled",
    WAITING_APPROVAL: "Pending approval",
    APPROVED: "Approved",
    REJECTED: "Rejected",
  },
  transactional_custom_dialog: {
    end_experiment: {
      header: "Reason for ending this experiment",
      go_back: "Go back",
      confirm: "Confirm",
      options: {
        cancel:
          "Issues or low confidence levels (experiment will appear as Canceled)",
        finish:
          "High confidence levels reached (experiment will appear as Completed)",
      },
      alert: "This action cannot be undone.",
    },
    cancel: {
      header: "Cancel experiment?",
      cancel: "No, go back",
      continue: "Yes, cancel",
      paragraph:
        "This option is for experiments with bugs or invalid confidence levels, or to discontinue an experiment for other reasons. This action cannot be undone.",
    },
    finish: {
      header: "Finish experiment?",
      cancel: "No, go back",
      continue: "Yes, finish",
      paragraph:
        "This option is intended for experiments that have reached a valid confidence level. Once you finish an experiment, you cannot restart it.",
    },
    result: {
      header: "Enter experiment results",
      sub_header: "Findings",
      explanation:
        "Enter the experiment results and its insights. To do this, you must analyse the PowerBI data.",
      cancel: "Go back",
      continue: "Save results",
      error_text: "You must enter the experiment findings.",
    },
  },
  success_custom_alert: {
    cancel: {
      message: "The experiment has been successfully cancelled.",
    },
    finish: {
      message: "The experiment has been successfully finished.",
    },
  },
  create_and_update_experiment: {
    audience: {
      selected_country_checkbox: "Use selected country as audience",
      create_a_new_audience: "How to create a new audience?",
      dialog: {
        header: "How to create a new audience",
        sub_header:
          "To create a new audience based on specific criteria, you must use the Audience builder tool. Follow these steps:",
        steps: {
          step1: "Access the Audience builder tool",
          step2: "Select the country you want to target for the experiment",
          step3: "Go to “Create new audience”",
          step4:
            "Apply filters or upload a CSV file containing the target audience",
          step5: "Save the audience",
          step6:
            "Once the audience is saved, select it here in the audience selector.",
        },
        cancel: "Go back",
        continue: "Open Audience builder",
      },
    },
    hints: {
      experiment_name: "Enter experiment name following our name guidelines.",
      experiment_description: "Provide a brief explanation for this experiment",
      experiment_metrics:
        "Choose the KPIs that will evaluate this experiment's success.",
      experiment_primary_metrics:
        "Select the decision metric for this experiment.",
      experiment_secondary_metrics:
        "Select one or more complementary metrics to use if the primary metric is inconclusive.",
      guardrails: {
        text: "These are the KPIs you must monitor during the experiment.",
        link: "Read about them.",
      },
    },
    labels: {
      step1: {
        experiment_name: "Name",
        experiment_description: "Description",
        experiment_hypothesis: "Hypothesis",
        confluence_documentation_link: "Confluence documentation link",
        country: "Country",
        primary_metrics: "Primary metric",
        secondary_metrics: "Secondary metrics",
        guardrails: "Guardrails",
        custom_guardrails: "Custom guardrails",
        basic_information: {
          link: "Name guidelines",
        },
        modal: {
          name_guideline: {
            title: "Experiment name guidelines",
            description: "Experiment name should follow this structure:",
            description2:
              "[Value stream alias] Initiative name - Test name.Version",
            description3:
              "Example: [EXP] Banners homepage - Banners content.V1",
            text: {
              description1: {
                title: "Value Stream Alias ",
                description: "A short form of the value stream name. ",
                link: "Check the list here.",
                link_url:
                  "https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3962504277/Experimentation+Platform+-+Value+Streams+Keys",
              },
              description2: {
                title: "Initiative Name",
                description:
                  "The name used for the Initiative within the company.",
              },
              description3: {
                title: "Test Name",
                description: "A brief reference to the experiment.",
              },
              description4: {
                title: "Version",
                description:
                  "Indicates the version of the experiment (e.g., V1, V2, V3).",
              },
            },
            actions: {
              close_button: "Got it",
            },
          },
          read_guardrails: {
            title: "About our default guardrails",
            description:
              "To ensure the protection of our business, you must monitor specific Key Performance Indicators (KPIs) throughout the duration of any experiment. The following KPIs are of utmost importance:",
            text: {
              description1: {
                title: "Total orders ",
                description:
                  "The number of orders placed on BEES by POCs in the experiment.",
              },
              description2: {
                title: "Total AOV",
                description:
                  "The average order value (AOV) refers to the average amount spent by POCs per purchase. Consider only the AOV of POCs in the experiment.",
              },
              description3: {
                title: "Orders ABI",
                description:
                  "The number of orders for AB InBev products placed by POCs in the experiment.",
              },
              description4: {
                title: "Add to cart ABI",
                description:
                  "The number of POCs in the experiment that have added AB InBev products to the cart.",
              },
            },
            actions: {
              close_button: "Got it",
            },
          },
        },
      },
      step2: {
        selected_country: "Selected country",
        allocation_percentage: "Allocation percentage",
      },
      step3: {
        title: "Variants",
        alert: {
          title:
            "The user traffic is equally divided among the number of variants and the control version.",
        },
        control_version: {
          title: "Control version",
          paragraph:
            "The control version for this experiment is the version that is currently live in production",
        },
        variants: {
          section_allow_list: {
            title: "Allow list",
            optional: "optional",
          },
        },
        modal: {
          dialog: {
            description:
              "Add user accounts that must see this variation when they access.",
          },
        },
      },
    },
    placeholder: {
      step1: {
        experiment_name: "Enter experiment name",
        experiment_description: "Enter experiment description",
        experiment_hypothesis: "Enter experiment hypothesis",
        confluence_documentation_link: "Enter Confluence link",
      },
      step2: {
        allocation_percentage: "e.g. 50%",
      },
    },
    page_title_section: {
      step1: {
        experiment_details: "Basic Information",
        metrics: "Metrics",
      },
      step2: {
        audience_selection: "Audience Selection",
        traffic_allocation: {
          title: "Traffic allocation",
          sub_title:
            "Enter the portion of the selected audience to display the experiment. This traffic will then be split evenly between the control version and the variants.",
        },
      },
    },
    error_messages: {
      experiment_name: "You must enter a name",
      hypothesis: "You must enter a hypothesis",
      country: "You must select a country",
      description: "You must enter a description",
      documentation_link: "You must enter a concluence documentation link",
      primary_metric: "You must select a primary metric",
      secondary_metrics: "You must select at least one option",
      allocation_percentage: "Allocation percentage must be above 5%.",
      step3: {
        variant_name: "You must enter a variant name",
        variant_description: "You must enter a variant description",
        setting_options: "You must select at least one option.",
        container_id: "You must enter the container ID",
        copy_key: "You must enter the copy key.",
        copy: "You must enter the copy.",
        json: {
          size_message: "JSON is bigger than 1MB",
          invalid_message: "The code format is invalid.",
        },
      },
    },
    buttons: {
      common: {
        next: "Next",
        back: "Go Back",
      },
      step1: {
        save_and_exit: "Save as draft",
      },
      step3: {
        create_variant: "Add another variant",
        add_viewer: "Add POC IDs",
      },
      step4: {
        publish_experiment: "Publish experiment",
        submit_for_approval: "Submit for approval",
        publish_experiment_validation: "Send test",
        modals: {
          publish_experiment_validation_confirm: "Yes, send",
          publish_experiment_validation_go_back: "No, go back",
          publish_experiment_validation_paragraph1:
            "The test version will be reflected in the app in up to 6 hours for the following user IDs: ",
          publish_experiment_validation_paragraph2:
            "Make sure the app is running correctly before sending the test version.",
        },
      },
    },
  },
  export_file: {
    exporting_file: "Exporting file",
    this_may_take_a_few_minutes: "This may take a few minutes",
    do_not_close_this_window: "Do not close this window",
    toast_message: {
      success: "The experiment was ended and will appear as",
      error: "Unable to end experiment. Try again.",
    },
  },
};

export default enUS;
