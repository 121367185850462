import { generatePath } from 'react-router-dom';

export const REPORT_ROUTES = {
  getReportEvaluationById: {
    route: '/report/employees/:employeeId/evaluations/:evaluationId',
    generate(employeeId, evaluationId) {
      return generatePath(this.route, { employeeId, evaluationId: String(evaluationId) });
    },
  },
  getDatesEvaluations: {
    route: '/report/employees/:idEmployee/evaluations',
    generate(idEmployee) {
      return generatePath(this.route, { idEmployee });
    },
  },
  getReportFeedback: {
    route: '/report/employees/:idEmployee/feedbacks',
    generate(idEmployee) {
      return generatePath(this.route, { idEmployee });
    },
  },
};
