import Axios, { AxiosInstance } from 'axios';

export const externalApiLib = Axios.create();

export interface IApi {
  enhanceService: <HeadersT>(authentication, headers: HeadersT) => void;
  post: <ResponseT, BodyT>({ url, body }: ApiParams<BodyT>) => Promise<ResponseT>;
  delete: <ResponseT>({ url, body }: ApiParams) => Promise<ResponseT>;
  get: <ResponseT, ParamsT = unknown>({ url, params }: ApiParams<ParamsT>) => Promise<ResponseT>;
  put: <ResponseT, BodyT>({ url, body }: ApiParams<BodyT>) => Promise<ResponseT>;
  patch: <ResponseT, BodyT>({ url, body }: ApiParams<BodyT>) => Promise<ResponseT>;
  getBlob: <ParamsT = unknown>({ url, params }: ApiParams<ParamsT>) => Promise<Blob>;
}

class Api implements IApi {
  constructor(private readonly externalApiLib: AxiosInstance) {}

  private handleError = (err: Error, throwOnError: boolean) =>
    throwOnError ? Promise.reject(err) : err;

  enhanceService = <HeadersT>(authentication, headers: HeadersT) => {
    authentication.enhancedAxios(this.externalApiLib, {
      headers,
    });
  };

  post = <ResponseT, BodyT>({ url, body, throwOnError }: ApiParams<BodyT>): Promise<ResponseT> =>
    this.externalApiLib
      .post(url, body)
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));

  delete = <ResponseT>({ url, body, throwOnError }: ApiParams): Promise<ResponseT> => {
    return this.externalApiLib
      .delete(url, { data: body })
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));
  };

  getBlob = <ParamsT = unknown>({ url, params, throwOnError }: ApiParams<ParamsT>): Promise<Blob> =>
    this.externalApiLib
      .get(url, { params, responseType: 'blob' })
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));

  get = <ResponseT, ParamsT = unknown>({
    url,
    params,
    throwOnError,
  }: ApiParams<ParamsT>): Promise<ResponseT> =>
    this.externalApiLib
      .get(url, { params })
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));

  put = <ResponseT, BodyT>({ url, body, throwOnError }: ApiParams<BodyT>): Promise<ResponseT> =>
    this.externalApiLib
      .put(url, body)
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));

  patch = <ResponseT, BodyT>({ url, body, throwOnError }: ApiParams<BodyT>): Promise<ResponseT> =>
    this.externalApiLib
      .patch(url, body)
      .then((res) => res.data)
      .catch((err) => this.handleError(err, throwOnError));
}

export default new Api(externalApiLib);
