import {
  AssessmentsAttributes,
  AssessmentsItem,
  AssessmentsResponse,
  CustomerAttributes,
  MappedAssessments,
} from '../../interfaces';
import { getRecommendation } from '../getRecommendation';

export const mappedAssessments = (response: AssessmentsResponse): MappedAssessments => {
  const findAttribute = (attributes: AssessmentsAttributes[], attributeName: string) => {
    return attributes.find((item) => item?.name === attributeName)?.value;
  };

  return {
    data: response.data.map((item): AssessmentsItem => {
      const customerAttributes = item?.data?.customer?.attributes;
      return {
        assessmentId: item?.id,
        beesAccountId: item?.data?.customer?.bees_account_id,
        accountId: item?.data?.customer?.account_id,
        vendorId: item?.source_id?.vendor,
        status: item?.status?.name,
        pocName: item?.data?.customer?.name,
        pocDocument: findAttribute(customerAttributes, CustomerAttributes.POC_DOCUMENT),
        creditLimit: String(item?.limit?.model),
        term: String(item?.term?.days),
        fee: String(item?.term?.fee),
        salesAgency: item?.data?.customer?.sales_agency,
        salesArea: item?.data?.customer?.sales_area,
        customerType: item?.data?.customer?.category,
        updatedAt: { value: item?.updated_at, type: 'short' },
        updatedBy: item?.updated_by?.identifier || 'Unknown',
        recommendation: getRecommendation(item?.information_tag),
        score: String(item?.score),
        original: {
          limit: String(item?.limit?.source),
          term: String(item?.updated_by?.term_fee?.term),
        },
      };
    }),
    pageable: { total: response?.pageable?.total },
  };
};
