import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from '@hexa-ui/components'
import { LoadReasonFieldsConfigurationUseCase } from '@/usecase'

export function useReasonFieldsOptions() {
	const [reasonFieldsOptions, setReasonFieldsOptions] = useState<JSX.Element[]>([])
	const { t } = useTranslation()

	const createOption = (value: string, label: string) => {
		return (
			<Select.Option key={value} value={value}>
				{label}
			</Select.Option>
		)
	}

	const loadReasonFieldsOptions = useCallback(async () => {
		const placeholderOption = createOption('', t('transaction:POINTS_REMOVAL.REASON_FIELD_SELECT'))
		const offerCategories = await LoadReasonFieldsConfigurationUseCase()
		const newOptions = offerCategories.map((category) => createOption(category.id, category.name))
		setReasonFieldsOptions([placeholderOption, ...newOptions])
	}, [])

	useEffect(() => {
		loadReasonFieldsOptions()
	}, [loadReasonFieldsOptions])

	return reasonFieldsOptions
}
