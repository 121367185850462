import { tokens } from "@bees-grow-shared/theme";
import {
  Heading,
  TextArea as HexaTextArea,
  SkeletonLoader,
} from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Heading)`
  margin-bottom: 0.5rem;
`;

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  height: fit-content;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
export const TextArea = styled(HexaTextArea)`
  min-height: 12rem;
`;
export const TitleLoader = styled(SkeletonLoader)`
  margin: 1.625rem 0 1.125rem 0;
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 6.313rem;
`;
export const TextAreaLoader = styled.div`
  border: 1px solid ${tokens.color.foundation.border.secondary};
  border-radius: ${tokens.measure["border-radius"]["borderradius-2"]};
  min-height: 7.875rem;
  width: 100%;
`;
