// Used to extract the currency and the value from a payment.
// Example:
// Payment: "MXN $ 1,000.00"
// Currency = "MXN"
// Value = "$ 1,000.00"
const matchPaymentRegex = /([A-Z]{3})\s{0,}(.*)/;

export const parsePaymentValueColumn = (props) => {
  const [, currency, value] = props.cell.value.match(matchPaymentRegex) || [];

  return currency && value ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
      }}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{currency}</span>
      <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
    </div>
  ) : (
    props.cell.value
  );
};
