import { Card, Grid, Select } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledInput from '../../../components/Input/Input';
import PageTitle from '../../../components/PageTitle/PageTitle';
import StyledSelect from '../../../components/Select/Select';
import { countryType } from '../../../components/layout/Layout';
import useChallengeHandler from '../../../hook/useChallengeHandler';
import { useAppSelector } from '../../../store';

type ImportChallenge = {
    challenge_id: string;
    country: string;
}

const ImportRewardsChallenges: React.FC = () => {
    const navigate = useNavigate();
    const { getRewardsChallenges } = useChallengeHandler();
    const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
    const [form, setForm] = useState<ImportChallenge>({
        challenge_id: '',
        country: localStorage.getItem('country')
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (value: string, prop: string) => {
        setForm(prevState => ({ ...prevState, [prop]: value }));
    };

    const handleSend = async () => {
        setLoading(true)

        setLoading(false)
    };

    return (
        <>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
                <PageTitle
                    marginBottom="1.2rem"
                    title="Import Challenge"
                />
            </Grid.Item>
            <Card elevated="medium" border="medium" css={{ width: '100%' }}>
                <Grid.Container
                    type="fluid"
                    style={{
                        justifyContent: 'left',
                        padding: '1.5rem 0 1rem 0',
                    }}
                >
                    <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: '0px', gap: '0.5rem', flexWrap: 'wrap-reverse' }}>
                        <StyledSelect
                            size="large"
                            label="Country"
                            width="250px"
                            hint='The country.'
                            value={form?.country}
                            defaultValue={localStorage.getItem('country')}
                            onChange={(value) => handleChange(value, 'country')}
                        >
                            {countriesPromo &&
                                countriesPromo.map((country: countryType, index: number) => {
                                    return (
                                        <Select.Option key={country.name + index} value={`${country.id}`}>
                                            {country.name}
                                        </Select.Option>
                                    );
                                })}
                        </StyledSelect>
                        <StyledInput
                            defaultValue={''}
                            label="Challenge id*"
                            placeholder=""
                            size="large"
                            width="35%"
                            minWidth='300px'
                            hint="The challenge id."
                            onChange={(e) => handleChange(e.currentTarget.value, 'challenge_id')}
                            value={form?.challenge_id}
                        />
                    </Grid.Item>
                </Grid.Container>
                <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            justifyContent: 'flex-end',
                            gap: '0.5rem',
                            padding: '20px',
                        }}
                    >
                        <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                            Cancel
                        </StyledButton>
                        <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
                            Save
                        </StyledButton>
                    </div>
                </Grid.Item>
            </Card>
        </>
    );
};

export default ImportRewardsChallenges;
