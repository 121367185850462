import { theme } from '@hexa-ui/theme';
import styled from 'styled-components';

interface FilterButtonIconProps {
  checked?: boolean;
}

export const FilterByPeriodContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const Period = styled.div`
  display: flex;
`;

export const Label = styled.label`
  border-radius: 512px;
  padding: ${({ theme }) => `${theme.measure.space['space-2']} ${theme.measure.space['space-3']}`};
  border: 1px solid #ccc;
  background-color: ${theme.colors.neutral0.value};
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  input:checked + & {
    border-color: #007bff;
    color: #007bff;
  }
`;

export const Input = styled.input`
  display: none;
`;

const BaseIcon = styled.div<FilterButtonIconProps>`
  display: inline-flex;
  align-items: center;
  height: 1px;

  svg {
    width: 16px;
    height: 16px;
    top: 3px;
    position: relative;
  }
`;

export const Icon = styled(BaseIcon)`
  margin-right: 4px;

  svg {
    fill: ${(props) => (props.checked ? '#007bff' : '#141414')};
  }
`;

export const IconRight = styled(BaseIcon)`
  margin-left: 4px;

  svg {
    fill: '#007bff';
  }
`;
