import { Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, MoreHorizontal, Trash2 } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import StyledButton from '../../Button/Button';
import FlexContainer from '../../FlexContainer';

const DropdownContent = styled('div', {
  position: 'absolute',
  left: 1,
  zIndex: 240,
  backgroundColor: '#fff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  marginTop: '4px',
  padding: '0.35rem',
  display: 'flex'
});

const StyledLink = styled(Link, {
  textDecoration: 'none',
  listStyleType: 'none',
  color: 'inherit',
});

const StyledModal = styled('div', {
  textDecoration: 'none',
  listStyleType: 'none',
  color: 'inherit',
  cursor: 'pointer',
});

const TableRowDropdown = ({ campaignId, rowId, baseRoute, routeName, title }) => {
  const [dropdown, setDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div ref={divRef}>
      <IconButton
        icon={MoreHorizontal}
        variant="inherit"
        onClick={() => setDropdown(!dropdown)}
      />
      {dropdown && (
        <DropdownContent onClick={() => { setDropdown(false); }}>
          <StyledLink to={`${baseRoute}/${campaignId}/${routeName}/${rowId}`}>
            <IconButton
              icon={Edit2}
              variant="inherit"
            />
          </StyledLink>

          <StyledModal>
            <Modal.Root
              actions={
                <FlexContainer width="100%" display="inline-flex" justifyContent="flex-end" gap="0.5rem">
                  <Modal.Action>
                    <StyledButton variant="secondary">Cancel</StyledButton>
                  </Modal.Action>
                  <Modal.Action>
                    <StyledButton variant="destructive" onClick={() => console.log(rowId.id)}>
                      Delete
                    </StyledButton>
                  </Modal.Action>
                </FlexContainer>
              }
              trigger={
                <IconButton
                  icon={Trash2}
                  variant="inherit"
                  onClick={(event) => { event.stopPropagation(); setIsModalOpen(true); }}
                />
              }
              variant="overlay"
              destructive="critical"
            >
              <Heading type="H3" css={{ marginBottom: '1rem' }}>
                Delete {title}?
              </Heading>
              <Paragraph>{`Do you really wish to delete this ${title}?`}</Paragraph>
              <Paragraph>{'This action can’t be undone.'}</Paragraph>
            </Modal.Root>
          </StyledModal>
        </DropdownContent>
      )}
    </div>
  );
};

export default TableRowDropdown;
