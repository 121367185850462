import AutoblockRouter from 'Routers/AutoblockRouter';
import ImportsAndExportsRouter from 'Routers/ImportsAndExportsRouter';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { ROUTER_BASENAME } from 'config/constants';
import { permissionScopes } from 'constants/PermissionScopes.constant';
import { ROUTES } from 'constants/Routes.constants';
import React, { Suspense, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CheckOnePermission, CheckPermission } from 'utils/checkUserScope/checkUserScope';
import { clearReferrer } from 'utils/referrerUtils/referrerUtils';
import useStyles from './Router.styles';
import {
  AutoblockCreateRoute,
  ClientCSVUploadRoute,
  ClientEditRoute,
  ClientRulesRoute,
  DDCsEditV2CustomSettingsRoute,
  DDCsEditV2Route,
  DDCsHolidayRoute,
  DDCsRoute,
  DDCsRulesRoute,
  DeliveryWindowRoute,
  RuleCSVUploadRoute,
  SellerSettingsRoute,
} from './RouterLazyRoutes';
import LoadingView from './components/loadingView/LoadingView';

const Router = (): JSX.Element => {
  const { containerBody, loadingView, content } = useStyles();

  const userHasImportsAndExportsPermission = CheckOnePermission([
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ,
  ]);

  const userHasDDCPermission = CheckPermission(permissionScopes.DDC_LIST);

  const userHasClientPermission = CheckPermission(permissionScopes.CLIENT_LIST);

  const homeRedirect = useMemo(() => {
    if (userHasClientPermission) {
      return `${ROUTER_BASENAME}/DeliveryWindow`;
    }
    if (userHasDDCPermission) {
      return `${ROUTER_BASENAME}/DDCs`;
    }
    if (userHasImportsAndExportsPermission) {
      return ROUTES.IMPORTS_AND_EXPORTS;
    }
    return `/error/404`;
  }, [userHasDDCPermission, userHasClientPermission, userHasImportsAndExportsPermission]);

  /* istanbul ignore next */
  React.useCallback(() => {
    clearReferrer();
  }, []);

  /* istanbul ignore next */
  return (
    <BrowserRouter>
      <div className={containerBody}>
        <Suspense
          fallback={
            <div className={loadingView}>
              <LoadingView />
            </div>
          }
        >
          <div className={content}>
            <Switch>
              <Route exact path={`${ROUTER_BASENAME}`}>
                <Redirect data-testid="home-base-route" to={homeRedirect} />
              </Route>
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs`}
                render={() => (
                  <PrivateRoute>
                    <DDCsRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.DDCS_EDIT}
                render={() => (
                  <PrivateRoute>
                    <DDCsEditV2Route />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.DDCS_EDIT_CUSTOM_SETTINGS}
                render={() => (
                  <PrivateRoute>
                    <DDCsEditV2CustomSettingsRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/Holiday`}
                render={() => (
                  <PrivateRoute>
                    <DDCsHolidayRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DDCs/DDCsRules`}
                render={() => (
                  <PrivateRoute>
                    <DDCsRulesRoute />
                  </PrivateRoute>
                )}
              />

              <Route
                exact
                path={ROUTES.AUTOBLOCK_CREATE}
                render={() => (
                  <PrivateRoute>
                    <AutoblockCreateRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={ROUTES.AUTOBLOCK_EDIT}
                render={() => (
                  <PrivateRoute>
                    <AutoblockCreateRoute />
                  </PrivateRoute>
                )}
              />

              <Route path={ROUTES.AUTOBLOCK} component={AutoblockRouter} />

              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow`}
                render={() => (
                  <PrivateRoute>
                    <DeliveryWindowRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow/ClientEdit`}
                render={() => (
                  <PrivateRoute>
                    <ClientEditRoute />
                  </PrivateRoute>
                )}
              />

              <Route
                exact
                path={ROUTES.CLIENT_UPLOAD_FILE}
                render={() => (
                  <PrivateRoute>
                    <ClientCSVUploadRoute />
                  </PrivateRoute>
                )}
              />

              <Route
                exact
                path={ROUTES.RULE_UPLOAD_FILE}
                render={() => (
                  <PrivateRoute>
                    <RuleCSVUploadRoute />
                  </PrivateRoute>
                )}
              />
              <Route path={ROUTES.IMPORTS_AND_EXPORTS} component={ImportsAndExportsRouter} />

              <Route
                exact
                path={`${ROUTER_BASENAME}/DeliveryWindow/ClientRules`}
                render={() => (
                  <PrivateRoute>
                    <ClientRulesRoute />
                  </PrivateRoute>
                )}
              />
              <Route
                exact
                path={`${ROUTER_BASENAME}/SellerSettings`}
                render={() => (
                  <PrivateRoute ignoreFullAccess>
                    <SellerSettingsRoute />
                  </PrivateRoute>
                )}
              />
            </Switch>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
