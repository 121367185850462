import MessageMap from '../i18n.d';

export const idID: MessageMap = {
  TEST: {
    TRANSLATE: 'Terjemahkan',
    MISSING: 'Hanya opsi Bahasa Inggris yang tersedia',
  },
  UNGROUPED_AGENTS_CARD: {
    UNGROUPED_AGENTS_WARNING_MESSAGE: 'Ada beberapa agen yang mungkin perlu dialokasikan ke grup',
    RELOCATE_BUTTON: 'Pindahkan',
    SELECT_ALL_BUTTON: 'Pilih semua',
    SELECTED_TEXT: 'terpilih',
    UNGROUPED_AGENTS_TEXT: 'Agen yang tidak terkelompok',

    NO_AGENTS: 'Tidak ada agen ditemukan...',
    NO_AGENTS_UNGROUPED: 'Sepertinya tidak ada agen yang tidak terkelompok.',
    NO_AGENTS_DESCRIPTION: `Halaman ini akan terisi setelah tersedia.`,

    ERROR_SERVICE: 'Layanan tidak tersedia...',
    ERROR_SERVICE_DESCRIPTION:
      'Kami tidak dapat memuat daftar agen yang tidak terkelompok. Silakan muat ulang daftar.',
    REFRESH: 'Muat ulang daftar',
  },
  LOADING: {
    BEELOADING_TEXT: 'Memproses…',
  },
  TEAMS_MAIN_PAGE: {
    TITLE: 'Tim',
    CREATE_GROUP: 'Buat grup baru',
    NO_GROUPS_CREATED: 'Belum ada grup yang dibuat.',
    NO_GROUPS_CREATED_DESCRIPTION:
      'Grup memungkinkan pengaturan audiens, misi, dan lainnya untuk beberapa agen.',
    CANCEL: 'Membatalkan', // Corrected
    CREATE: 'Buat',

    PAGINATION_INFO: 'Menampilkan Y dari X grup per halaman',
  },
  ORGANIZATION_CARD: {
    VENDORS: 'Pemasok',
    ORG_INFO: {
      AGENTS: 'Agen',
      GROUPS: 'Grup',
      MISSIONS: 'Misi',
      TOOLTIP_INFO_MESSAGE: 'Total misi aktif untuk organisasi',
    },
    MY_BEES_DATA: 'Data BEES Saya',
    STRUCTURES: 'Struktur',
  },
  GROUP_CARD: {
    AGENTS: 'Agen',
    MISSIONS: 'Misi',
    VENDORS: 'Pemasok',
  },
  CREATE_GROUP_FORM: {
    FIELD_NAME: 'Nama',
    FIELD_DESCRIPTION: 'Deskripsikan grup ini dalam beberapa kata',
    FIELD_PLACEHOLDER: `Contoh: Mitra Penanam`,
    FIELD_GROUP_NAME_REQUIRED: 'Masukkan nama',
    FIELD_GROUP_NAME_MUST_BE_UNIQUE: `Grup dengan nama ini sudah ada, coba nama lain.`,

    OPTIONAL: '(opsional)',
    REMOVE_ALL: 'Hapus semua',
    USE_FIELD_ABOVE: 'Gunakan kolom di atas untuk menambahkan.',

    FIELD_AGENT: 'Agen',
    FIELD_AGENT_TITLE: 'Pilih agen',
    FIELD_AGENT_PLACEHOLDER: 'Cari berdasarkan nama atau email',
    FIELD_AGENT_EMPTY: 'Belum ada agen.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS: 'Semua agen sudah dialokasikan.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS_DESCRIPTION:
      'Anda dapat memindahkan agen dari grup mereka saat ini.',

    FIELD_MISSION: 'Misi',
    FIELD_MISSION_TITLE: 'Tambahkan misi',
    FIELD_MISSION_EMPTY: 'Belum ada misi.',
    FIELD_MISSION_SEARCH: 'Cari misi berdasarkan nama',
    FIELD_MISSION_ERROR:
      'Anda telah mencapai batas 50 misi. Kelompokkan semua misi lokal atau distribusikan misi ke grup.',

    FIELD_AUDIENCE: 'Audiens',
    FIELD_AUDIENCE_TITLE: 'Tambahkan vendor',
    FIELD_AUDIENCE_EMPTY: 'Belum ada vendor.',
    FIELD_AUDIENCE_SEARCH: 'Cari vendor berdasarkan nama atau ID',
    FIELD_AUDIENCE_ERROR: 'Pilih vendor',
    FIELD_SEGMENT_ERROR: 'Masukkan nilai atau hapus kondisinya',

    FIELD_KPIS: 'Target kinerja',
    FIELD_KPIS_AHT: 'AHT',
    FIELD_KPIS_AHT_MEANING: 'Waktu penanganan rata-rata',
    FIELD_KPIS_AHT_DETAILS_MEANING:
      '"Waktu penanganan rata-rata". Dihitung berdasarkan rata-rata waktu yang dihabiskan untuk panggilan.',
    FIELD_KPIS_TOPLINE_UPLIFT: 'Peningkatan topline',
    FIELD_KPIS_TOPLINE_UPLIFT_MEANING:
      'Dihitung berdasarkan jumlah yang meningkat dan persentase setelah panggilan strike dan penempatan pesanan yang sebenarnya.',
    FIELD_KPIS_TOTAL_CALLS: 'Total panggilan',
    FIELD_KPIS_TOTAL_CALLS_MEANING: 'Total panggilan yang dilakukan oleh agen.',
    FIELD_KPIS_HIT_RATE: 'Tingkat keberhasilan',
    FIELD_KPIS_HIT_RATE_MEANING:
      'Dihitung dengan membagi total panggilan yang dijawab dengan total panggilan yang dilakukan.',
    FIELD_KPIS_VISIBLE: 'KPI terlihat',
    FIELD_KPIS_INVISIBLE: 'KPI tidak terlihat',
    FIELD_KPIS_DAILY: 'Harian',
    FIELD_KPIS_MONTHLY: 'Bulanan',
    FIELD_KPIS_TOOLTIP:
      'Tentukan apakah KPI ini akan terlihat atau tidak untuk agen yang dipilih untuk grup ini.',
    FIELD_KPIS_EMPTY: 'Belum ada target.',

    FIELD_SEARCH_NOT_FOUND: 'Tidak ada yang ditemukan dengan istilah yang dicari.',
    ADD_ALL: 'Tambah semua',

    ADD_CONDITION: 'Tambahkan kondisi',
    ADD_VALIDATOR: 'Tambahkan validator',
    REMOVE_CONDITION: 'Hapus kondisi',
    ENTER_VALIDATOR: 'Masukkan validator',
    SEGMENT: 'Segmen',
    SEARCH_CONDITION: 'Cari kondisi',

    TOPLINE_GAP: 'Gap topline',
    MARKETPLACE: 'Marketplace',
    CHALLENGES: 'Tantangan',
    SERVICES: 'Layanan',
    LOCAL_MISSIONS: 'Misi lokal',

    TOAST_SUCCESS_MESSAGE: 'Grup berhasil dibuat.',
    TOAST_ERROR_MESSAGE: 'Gagal membuat grup.',
    TOAST_ERROR_INVALID_ORGANIZATION:
      'Organisasi saat ini tidak tersedia lagi. Hubungi dukungan untuk informasi lebih lanjut.',
    TOAST_ERROR_TRY_AGAIN: 'Coba lagi',

    TOGGLE_ALL_LOCAL_MISSIONS: 'Kelompokkan semua Misi Lokal',
    TOOLTIP_ALL_LOCAL_MISSIONS:
      'Opsi ini mengelompokkan semua misi lokal individu menjadi satu, memungkinkan Anda untuk memprioritaskan mereka lebih cepat.',
  },

  EDIT_GROUP_FORM: {
    TITLE: 'Edit grup',
    SAVE: 'Simpan',
    CANCEL: 'Membatalkan',
    TOAST_SUCCESS_MESSAGE: 'Perubahan berhasil disimpan.',
    TOAST_ERROR_MESSAGE: 'Gagal menyimpan perubahan.',
    TOAST_ERROR_TRY_AGAIN: 'Coba lagi',
  },
  GROUP_DETAILS: {
    EDIT: 'Edit',
    DELETE: 'Hapus',
    START_DATE: 'Mulai',
    END_DATE: 'Akhir',
    END_DATE_VALUE: 'Tidak ada tanggal akhir',
  },

  DELETE_GROUP: {
    DELETE: 'Hapus',
    CONFIRMATION_DELETE: 'Hapus grup ini?',
    DELETE_MESSAGE: `Grup akan dihapus segera, dan tindakan ini tidak dapat dibatalkan.`,
    GO_BACK: 'Kembali',
    TOAST_SUCCESS_MESSAGE: 'Grup berhasil dihapus.',
    TOAST_FAILED_MESSAGE: 'Gagal menghapus grup.',
    TRY_AGAIN: 'Coba lagi',
  },

  ERROR_PAGE_NOT_LOADED: {
    TITLE: 'Tidak dapat memuat halaman',
    TEXT: 'Server kami sedang sibuk sementara. Silakan coba lagi dalam beberapa menit.',
    BUTTON: 'Coba lagi',
  },
};
