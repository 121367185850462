/* eslint-disable max-params */
import MFERoutes from 'Config/MFERoutes';
import { MenuItem } from 'Domain/NewMenuItem';

import { SidebarItem } from 'admin-portal-shared-services';

import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import iconMap from 'Utils/iconMap/iconMap';
import { bySidebarClick, getOriginInfo } from 'Utils/segmentEventByLocation/segmentEventByLocation';
import { Icons } from './icons';

export default function useSidebarItems({
  reportsMenuItems,
}: {
  reportsMenuItems?: MenuItem[];
}): SidebarItem[] {
  const { locale, formatMessage } = useIntl();
  const { triggerElementClicked } = useElementClickedEvent();
  const { pathname } = useLocation();

  const currentPage = useCheckCurrentPage();

  /* istanbul ignore next */
  return useMemo(() => {
    const onPageChange = (destinyLabel: string, position: number, menuItem?: MenuItem) => () => {
      const { themeName, themetrackingId, reportName, reportTrackingId } = getOriginInfo(
        pathname,
        reportsMenuItems
      );

      triggerElementClicked({
        is_core_event: destinyLabel !== 'Main Page',
        page_label:
          currentPage === 'EmbedPage'
            ? `${themeName} - ${reportName}`
            : bySidebarClick(currentPage)?.page_label,
        page_name: bySidebarClick(currentPage)?.page_name,
        element_location_type: 'SIDEBAR',
        element_location_name: 'SIDEBAR_MENU',
        element_location_label: 'Sidebar Menu',
        element_label: destinyLabel,
        element_name: 'SIDEBAR_MENU_OPTION',
        element_type: 'MENU_OPTION',
        element_position: position,
        origin_theme_label: currentPage === 'EmbedPage' ? themeName : null,
        origin_theme_tracking_id: currentPage === 'EmbedPage' ? themetrackingId : null,
        origin_report_label: currentPage === 'EmbedPage' ? reportName : null,
        origin_report_tracking_id: currentPage === 'EmbedPage' ? reportTrackingId : null,
        destiny_theme_label: destinyLabel === 'Main Page' ? null : destinyLabel,
        destiny_theme_tracking_id: destinyLabel === 'Main Page' ? null : menuItem?.trackingId,
        destiny_report_label:
          destinyLabel === 'Main Page' ? null : menuItem?.reportTabs[0].title['en-US'],
        destiny_report_tracking_id:
          destinyLabel === 'Main Page' ? null : menuItem?.reportTabs[0].trackingId,
        occurence_id: bySidebarClick(currentPage)?.occurence_id,
      });
    };

    const items: SidebarItem[] = [
      {
        id: 'main-page',
        icon: Icons.Grid,
        path: MFERoutes.MAIN_PAGE,
        title: formatMessage({ id: 'SIDEBAR.MAIN_PAGE' }),
        onPageChange: onPageChange('Main Page', 1),
      },
    ];

    /* istanbul ignore if */
    if (reportsMenuItems?.length) {
      reportsMenuItems.forEach((menuItem, index) =>
        items.push({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          icon: () => iconMap[menuItem.iconString] || Icons.BarChart,
          id: menuItem.id,
          path: `${MFERoutes.HOME}/embed/${menuItem.slug}`,
          title: menuItem.title[locale],
          onPageChange: onPageChange(menuItem.title['en-US'], index + 2, menuItem),
        })
      );
    }

    return items;
  }, [currentPage, formatMessage, locale, pathname, reportsMenuItems, triggerElementClicked]);
}
