export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const capitalizeFirstLetterOnly = (string) =>
  string
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');

export const arrToArrOfObjects = (arr: any[], prop: string) => {
  const newArr = [];

  arr.forEach((elem) => {
    const newObj = { [prop]: elem };
    newArr.push(newObj);
  });

  return newArr;
};

export const sortObjectsByProperty = (objects: any[], property: string) => {
  return objects.sort(function (a, b) {
    let valueA = a[property].toLowerCase();
    let valueB = b[property].toLowerCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
};

export const isValidInput = (input: string, regex: RegExp) => {
  return input.trim().length === 0 || regex?.test(input);
};
