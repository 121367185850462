import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { Locales } from '@/enums/locales';

import { formatDate } from '../../../../utils/dateFormatter/dateFormatter';
import { DateFormats, Periods } from '../../enums/visits';
import { FILTERS_TEST_IDS } from '../../OverviewConsts';
import { FilterButton } from './components/FilterButton/FilterButton';
import * as Styled from './Filters.styles';

export const Filters = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2() as {
    preferredLanguage: Locales;
  };
  const [selectedPeriod, setSelectedPeriod] = useState<string>(Periods.TODAY);
  const [localeSelectedDate, setLocaleSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    const newDate = new Date();
    switch (selectedPeriod) {
      case Periods.YESTERDAY:
        newDate.setDate(newDate.getDate() - 1);
        break;
      case Periods.TOMORROW:
        newDate.setDate(newDate.getDate() + 1);
        break;
      case Periods.TODAY:
        newDate.setDate(newDate.getDate());
        break;
      case Periods.OTHER:
      default:
        handleOtherPeriodSelection();
        setLocaleSelectedDate(new Date());
        break;
    }
    setLocaleSelectedDate(newDate);
  }, [selectedPeriod]);

  const formattedSelectedDate = formatDate(
    localeSelectedDate,
    preferredLanguage,
    DateFormats.MEDIUM,
  );

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriod(e.target.value);
  };

  const handleOtherPeriodSelection = () => {
    // TODO: Implement calendar opening logic
    // eslint-disable-next-line no-console
    console.log('Other period selected, should open calendar');
  };

  return (
    <Styled.FiltersContainer data-testid={FILTERS_TEST_IDS.FILTERS_CONTAINER}>
      <Styled.FilterDescription>
        {formatMessage({ id: 'Components.Overview.Label.FILTER_DESCRIPTION' })}
      </Styled.FilterDescription>
      <Styled.FilterWrapper>
        {Object.values(Periods).map((period) => (
          <FilterButton
            key={period}
            period={period}
            name="period"
            checked={selectedPeriod === period}
            onChange={handleRadioChange}></FilterButton>
        ))}
        <Styled.DateLabel>{formattedSelectedDate}</Styled.DateLabel>
      </Styled.FilterWrapper>
    </Styled.FiltersContainer>
  );
};
