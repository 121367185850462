import { Heading, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { FileStatus } from '../../../interfaces';
import { Analyzing } from '../../icons/Progress/Analyzing/index.svg';
import { Done } from '../../icons/Progress/Done/index.svg';
import { Failed } from '../../icons/Progress/Failed/index.svg';
import { Processing } from '../../icons/Progress/Processing/index.svg';
import { BarContainer, BlueBar, GreyBar, ProgressBarContainer, TitleContainer } from './styles';

interface ProgressBarProps {
  status: string;
  fileName: string;
  progressLabel: string;
  percentage?: string;
  hasError?: boolean;
  errorMessage?: string;
  filesSize?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  status,
  fileName,
  filesSize = '-',
  progressLabel,
  percentage = '0%',
  hasError = false,
  errorMessage = '',
}) => {
  const Icon = () => {
    if (hasError) {
      return <Failed data-cy="failed-icon" />;
    }
    const statusIcons = {
      [FileStatus.PENDING]: <Analyzing data-cy="analyzing-icon" />,
      [FileStatus.ANALYZING]: <Analyzing data-cy="analyzing-icon" />,
      [FileStatus.PROCESSING]: <Processing data-cy="processing-icon" />,
      [FileStatus.DONE]: <Done data-cy="done-icon" />,
      [FileStatus.ANALYZED]: <Done data-cy="done-icon" />,
    };

    return statusIcons[status] || <Analyzing />;
  };
  return (
    <>
      <ProgressBarContainer>
        <Icon />
        <TitleContainer>
          <Paragraph size="small" css={{ fontWeight: '500' }}>
            {fileName}
          </Paragraph>
          {hasError && (
            <Paragraph size="small" colortype="error">
              {errorMessage}
            </Paragraph>
          )}
          {!hasError && (status === 'ANALYZING' || status === 'PROCESSING') && (
            <BarContainer data-cy="progress-bar-container">
              <GreyBar>
                <BlueBar $percentage={percentage} />
              </GreyBar>
              <Heading size="H5">{percentage}</Heading>
            </BarContainer>
          )}
          {(status === 'ANALYZED' || status === 'DONE') && (
            <Paragraph size="small" color="primary">
              {filesSize}
            </Paragraph>
          )}
        </TitleContainer>
      </ProgressBarContainer>
      {status !== 'ANALYZED' && status !== 'DONE' && (
        <Paragraph css={{ marginTop: '16px' }} size="small" colortype="secondary">
          {progressLabel}
        </Paragraph>
      )}
    </>
  );
};

export default ProgressBar;
