import { Card, Grid, Heading, Tabs } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useLocation } from 'react-router-dom';
import StyledTabs from '../../../components/Tabs/Tabs';
import MatchInformationTab from './tabs/MatchInformationTab';
import MatchResultTab from './tabs/MatchResultTab';

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

export default function EditMatch() {
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(hash || '#matchinformation');

  useEffect(() => {
    setCurrentTab(hash || '#matchinformation');
  }, [hash]);
  const tabs = [
    { component: MatchInformationTab, hash: '#matchinformation', title: 'Match Information' },
    { component: MatchResultTab, hash: '#matchresult', title: 'Match Result' },
  ];

  return (
      <Grid.Container type="fluid" style={{margin:0}}>
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Edit Match
          </Heading>
        </StyledGridItem>
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" css={{ paddingBottom: '2rem', width: '100%'}}>
            <StyledTabs defaultValue={hash || '#matchinformation'}>
              <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
                {tabs?.map((tab, index) => (
                  <a
                    key={index + tab.title}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={tab.hash}
                  >
                    <Tabs.Trigger key={index} value={tab.hash}>
                      {tab.title}
                    </Tabs.Trigger>
                  </a>
                ))}
              </Tabs.List>
              {tabs?.map((tab, index) => (
                <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                  <tab.component />
                </Tabs.Content>
              ))}
            </StyledTabs>
          </Card>
        </StyledGridItem>
      </Grid.Container>
  );
}
