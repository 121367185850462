import Last12MonthsCSVModal from 'Components/CSVDownloadModal/Last12MonthsCSVModal/Last12MonthsCSVModal';
import Last30DaysCSVModal from 'Components/CSVDownloadModal/Last30DaysCSVModal/Last30DaysCSVModal';
import { TrackingContext } from 'Context/TrackingContext';
import CSVDownloadType from 'Domain/CSVDownloadType';
import { CSVDownload } from 'Domain/NewMenuItem';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import React, { useContext } from 'react';
import { useCSVDownload } from './useCsvDownload';

interface CSVDownloadModalProps {
  isOpen: boolean;
  isLoading: boolean;
  error: string;
  onClose: () => void;
  setError: (error: string) => void;
  setIsLoading: (value: boolean) => void;
  indexCSVDownload: number;
  csvDownload: CSVDownload[];
}

/* istanbul ignore next */
export function CSVDownloadModal({
  isOpen,
  isLoading,
  error,
  onClose,
  setError,
  setIsLoading,
  indexCSVDownload,
  csvDownload,
}: CSVDownloadModalProps): React.ReactElement | null {
  const { downloadCSV } = useCSVDownload({
    csvDownload,
    setError,
    setIsLoading,
    indexCSVDownload,
    onSuccess: () => onClose(),
  });

  const handleFreeTrialDownload = () => {
    downloadCSV();
  };

  const { triggerElementClicked } = useElementClickedEvent();
  const trackingContext = useContext(TrackingContext);

  const handleGoldDownload = (period: { year: number; month: number }) => {
    triggerElementClicked({
      is_core_event: true,
      page_label: `${trackingContext?.themeName} - ${trackingContext?.reportName}`,
      page_name: 'REPORTS',
      element_location_type: 'MODAL',
      element_location_name: 'DOWNLOAD_CSV',
      element_location_label: 'Export CSV file',
      element_label: 'Export',
      element_name: 'DOWNLOAD_BUTTON',
      element_type: 'BUTTON',
      origin_theme_label: trackingContext?.themeName,
      origin_theme_tracking_id: trackingContext?.themeTrackingId,
      origin_report_label: trackingContext?.reportName,
      origin_report_tracking_id: trackingContext?.reportTrackingId,
      occurence_id: '2.12',
    });

    downloadCSV(period);
  };

  if (!csvDownload.length) return null;

  if (csvDownload[indexCSVDownload].type === CSVDownloadType.LAST_30_DAYS) {
    return (
      <Last30DaysCSVModal
        isOpen={isOpen}
        isLoading={isLoading}
        isError={!!error}
        onClose={onClose}
        onConfirm={handleFreeTrialDownload}
      />
    );
  }

  return (
    <Last12MonthsCSVModal
      isLoading={isLoading}
      onConfirm={handleGoldDownload}
      isOpen={isOpen}
      onClose={onClose}
      error={error}
      setError={setError}
    />
  );
}
