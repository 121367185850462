import { getDefaultAnalyticsData } from "@bees-grow-shared/services";

import analytics from "@/lib/typewriter";
import { SCREEN_NAME } from "@/utils/constants";

interface TrackComboShowMoreClickProps {
  updatedText: string;
}

export default function trackCustomerNoteChanged({
  updatedText,
}: TrackComboShowMoreClickProps) {
  const defaultAnalyticsData = getDefaultAnalyticsData();

  analytics.customerNotesUpdated({
    ...defaultAnalyticsData,
    screen_name: SCREEN_NAME,
    component_name: "Notes",
    note_updated: updatedText,
  });
}
