import styled, { css } from 'styled-components';

export const Box = styled.div`
  margin-bottom: 16px;
`;

type BoxWrapperProps = {
  groupAttribute?: boolean;
};

export const BoxWrapper = styled.div<BoxWrapperProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  ${(props) =>
    props.groupAttribute &&
    css`
      grid-template-columns: 1fr;
      margin-top: 16px;
      padding-left: 16px;
      border-left: 1px solid #e5e5e5;
    `}
`;

export const StyledLabel = styled.label`
  color: var(--light-txt-secondary, #141414);
  /* subheader/small */
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
