import { Grid } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../../hook/useWindowDimensions';
import StyledInput from '../../../../components/Input/Input';
import StyledButton from '../../../../components/Button/Button';
import StyledToggle from '../../../../components/Toggle/Toggle';

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

export default function MatchResultTab() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  2;
  const [loading, setLoading] = useState(false);
  const { idTournament } = useParams();

  return (
    <Grid.Container type="fluid">
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'contents' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'flex-start',
            display: width <= 1024 ? 'contents' : '',
            padding: '2rem 0 1rem 0',
            width: '100%',
          }}
        >
          <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid.Container
              type="fluid"
              style={{
                width: '100%',
                height: 'max-content',
                margin: 0,
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <StyledPaddding>
                <StyledInput
                  size="large"
                  label="Home result"
                  width={'100%'}
                  placeholder="Insert you text here"
                />
              </StyledPaddding>
              <StyledPaddding>
                <StyledInput
                  size="large"
                  label="Visitor result"
                  width={'100%'}
                  placeholder="Insert you text here"
                />
              </StyledPaddding>
              <StyledToggle
                title="Finished"
                description="Once the match is finished the result should be add here and marked as finished."
                value={false}
                onChange={() => null}
              />
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '10px',
            }}
          >
            <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Grid.Item>
    </Grid.Container>
  );
}
