import MessageMap from "../i18n.d";

const idID: MessageMap = {
  TasksCarousel: {
    TITLE: "Tugas panggilan",
    Empty: {
      DESCRIPTION: "Tidak ada tugas yang ditugaskan untuk POC ini.",
    },
    Error: {
      DESCRIPTION: "Tidak dapat memuat tugas panggilan.",
      BUTTON: "Coba lagi",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Disponsori",
      Global: {
        Title: {
          TOPLINE: "Tugas topline",
          MARKETPLACE: "Tugas marketplace",
        },
        Description: {
          TOPLINE: "Rekomendasikan produk berdasarkan pesanan yang disarankan.",
          MARKETPLACE: "Dorong pembelian produk Marketplace.",
        },
        Script: {
          TOPLINE:
            "Memahami gap antara pesanan saat ini dan pesanan yang disarankan dan merekomendasikan produk untuk mencapai tujuan pendapatan.",
          MARKETPLACE:
            "Informasikan tentang produk Marketplace baru yang tersedia dan rekomendasikan mereka ke POC.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Tugas khusus yang dibuat oleh tim operasi.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Tandai sebagai sudah dilakukan",
      MARK_AS_NOT_DONE: "Tandai sebagai belum selesai",
      Error: {
        RELOAD: "Muat ulang",
        GENERIC_ERROR:
          "Tidak dapat memuat informasi. Periksa koneksi Anda dan coba lagi.",
      },
      TOTAL_QUANTITY: "Jumlah total minimum: {quantity}.",
      MINIMUM_QUANTITY: "Jumlah minimum: {quantity}.",
    },
  },
  ProductSelection: {
    TITLE: "Produk",
    LAST_ORDER_BUTTON: "Tunjukkan pesanan terakhir",
    Empty: {
      EMPTY_PRODUCTS_MESSAGE:
        "POC ini tidak memiliki produk di {param}. Coba kategori lain.",
      EMPTY_PRODUCTS_SEARCH_MESSAGE:
        'Tidak ada hasil untuk "{searchInput}" dalam katalog. Coba istilah lain.',
    },
    Error: {
      DESCRIPTION: "Tidak dapat memuat produk. Coba muat ulang halaman.",
      BUTTON: "Muat ulang",
    },
    Pagination: {
      LOADING: "Memuat",
      NO_MORE_ITEMS: "Tidak ada lagi item untuk ditampilkan.",
      Error: {
        TRY_AGAIN: "Coba lagi",
        ERROR_TEXT: "Tidak dapat memuat produk.",
      },
    },
    Modes: {
      SUGGESTED: "Pesanan yang disarankan",
      PROMOTIONS: "Promosi",
      COMBOS: "Combo",
      RECOMMENDED: "Direkomendasikan",
    },
    CategoryStatus: {
      Error: {
        DESCRIPTION: "Tidak dapat memuat kategori.",
        BUTTON: "Coba lagi",
      },
    },
    Search: {
      PLACEHOLDER: "Cari dengan SKU atau Produk",
      Filter: {
        DESCRIPTION: "Filter berdasarkan:",
        PROMOTIONS: "Promosi",
      },
      Details: {
        SUMMARY_TEXT: "Menampilkan {quantityProducts} hasil untuk ",
      },
    },
    DiscountDetailsDrawer: {
      SteppedDiscount: {
        TITLE: "Diskon progresif",
        SUB_TITLE:
          "Produk ini memiliki diskon progresif. Lebih banyak produk memberikan diskon lebih banyak sesuai dengan tabel di bawah ini.",
        DISCOUNT_MESSAGE: "Tambahkan {quantity}, dapatkan diskon {discount}%",
        DISCOUNT: "Diskon",
        PRICE: "Harga",
      },
      RegularDiscount: {
        TITLE: "Diskon",
        DISCOUNT_MESSAGE: "Produk ini memiliki diskon {discount}%.",
      },
    },
  },
};

export default idID;
