import { Card, Grid, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../../hook/useWindowDimensions';
import StyledButton from '../../../../components/Button/Button';
import FlexContainer from '../../../../components/FlexContainer';
import StyledFilter from '../../../../components/Filter/Filter';
import GroupHeaders from '../../../json/columnHeaders/GroupHeaders.json';
import mockData from '../../../json/mock/mockingGroupData.json';
import handleFilter from '../../../campaignDetails/extra/filter';
import { Draganddrop, Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import DraggableTable from '../../../../components/DraggableTable/DraggableTable';
import StyledAlert from '../../../../components/Alert/Alert';

const HiddenDragIcon = styled('div', {
  cursor: 'grab',
});

const ButtonPadding = styled('div', {
  display:'flex',
  justifyContent:'end',
  paddingRight: '1rem',
  paddingTop: '1rem',
});

export default function GroupsTab() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [isReordered, setIsReordered] = useState(false);
  const [modifiedData, setModifiedData] = useState(data);
  const [savedData, setSavedData] = useState([]);
  const { idTournament } = useParams();


  const dragHandlers = (index) => ({
    onDragStart: (e) => {
      e.dataTransfer.setData('rowIndex', index.toString());
    },
    onDragOver: (e) => e.preventDefault(),
    draggable: true,
  });

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['id', 'name', 'group_type', 'created', 'changed'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const InsertActions = (data) => {
    const ActionComponent = ({ group }) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => {
            navigate(
              `/bees-games/tournament/settings/tournament-details/${idTournament}/edit-group/${group.id}`
            );
          }}
        />
        <Modal.Root
          actions={
            <FlexContainer
              width="100%"
              display="inline-flex"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Modal.Action>
                <StyledButton variant="secondary">Cancel</StyledButton>
              </Modal.Action>
              <Modal.Action>
                <StyledButton variant="destructive" onClick={() => ''}>
                  Delete
                </StyledButton>
              </Modal.Action>
            </FlexContainer>
          }
          trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
          variant="overlay"
          destructive="critical"
        >
          <Heading type="H3" css={{ marginBottom: '1rem' }}>
            Delete group?
          </Heading>
          <Paragraph>{'Do you really wish to delete this group?'}</Paragraph>
          <Paragraph>{'This action can’t be undone.'}</Paragraph>
        </Modal.Root>
      </div>
    );

    return data.map((item, index) => ({
      ...item,
      actions: <ActionComponent group={item} />,
      icon: (
        <HiddenDragIcon {...dragHandlers(index)}>
          <Draganddrop />
        </HiddenDragIcon>
      ),
    }));
  };

  // Functions to change when we receive the api.
  const handleSave = () => {
    simulateAPI(modifiedData);
    setIsReordered(false);
  };

  const simulateAPI = async (data) => {
    try {
      setLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 2000));

      setSavedData(data);
      console.log('Data sent successfully:', data);
    } catch (error) {
      console.error('Error while sending data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        {isReordered && (
          <StyledAlert
            css={{ width: '100%', margin: '0.5rem 0' }}
            message="*You have unsaved changes."
            type="warning"
          />
        )}
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
        <StyledButton
          variant="primary"
          icon={Plus}
          leading
          onClick={() =>
            navigate(
              `/bees-games/tournament/settings/tournament-details/${idTournament}/create-group`
            )
          }
        >
          Add Group
        </StyledButton>
      </FlexContainer>
      <DraggableTable
        columns={GroupHeaders}
        data={data || []}
        onReorder={(newData) => {
          setIsReordered(true);
          setModifiedData(newData);
        }}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
      <ButtonPadding>
        <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
          Save order
        </StyledButton>
      </ButtonPadding>
    </>
  );
}
