import { Vendor } from 'admin-portal-shared-services';
import { FeatureActivationData } from 'domains/FeatureActivation';
import { VendorFull } from 'domains/Vendor';
import { createEvent } from 'effector';

export const resetState = createEvent('resetState');

export const setLoading = createEvent('setLoading');
export const unsetLoading = createEvent('unsetLoading');

export const setSelectedCountry = createEvent<{
  selectedCountry: string;
  vendorList: Vendor[];
  pathName: string;
}>('setSelectedCountry');

export const setSelectedVendor = createEvent<{ vendorId: string; vendorName: string }>(
  'setSelectedVendor'
);

export const setUserEmail = createEvent<string>('setUserEmail');

export const setFeatureActivationData = createEvent<FeatureActivationData>(
  'setFeatureActivationData'
);

export const setListVendors = createEvent<VendorFull[] | undefined>('setListVendors');

export const setGroups = createEvent<string[] | undefined>('setGroups');

export const setUserData = createEvent<{
  email: string | null;
  country: string | null;
  id: string | null;
}>('setUserData');

export const setLastUrl = createEvent<string>('setLastUrl');
