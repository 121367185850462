import MessageMap from '../i18n.d';

export const idID: MessageMap = {
  test: {
    translate: 'Terjemahkan',
    missing: 'Hanya tersedia opsi bahasa Inggris',
  },
  title: {
    PAGE_VISITS: 'Kunjungan',
    OVERVIEW: 'Ikhtisar',
  },
  button: {
    REFRESH: 'Segarkan',
  },
  filter: {
    Periods: {
      TODAY: 'Hari ini',
      YESTERDAY: 'Kemarin',
      TOMORROW: 'Besok',
      OTHER: 'Lainnya',
    },
    FILTER_DESCRIPTION: 'Pilih rentang data yang ingin Anda tampilkan.',
  },
};
