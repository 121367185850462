import { Crumb } from 'components/organisms/AppHeader/AppHeader';
import { BASE_PATH } from 'constants/routes/internal';
import { useAppHeader } from 'providers/AppHeaderProvider/AppHeaderProvider';
import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  NavigateOptions,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { routes } from 'routes/utils/routes';
import { useEmployeesSearchStore } from 'store/useEmployeesSearchStore';

interface RouterServiceProps {
  navigate: (to: string | number, options?: NavigateOptions) => void;
}

const initalState: RouterServiceProps = {
  navigate: () => {},
};

const RouterServiceContext = createContext<RouterServiceProps>(initalState);

export const RouterServiceProvider = ({ children }) => {
  const navigateFromReactRouterDom = useNavigate();
  const { clearEmployeeFilters, removeStorage } = useEmployeesSearchStore();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [historyStack, setHistoryStack] = useState<Crumb[]>([]);
  const { setCrumbs, setPageTitle } = useAppHeader();

  const navigate = (to: string | number, options?: NavigateOptions) => {
    typeof to === 'number'
      ? navigateFromReactRouterDom(to)
      : navigateFromReactRouterDom(`${BASE_PATH + to}`, options);
  };

  useEffect(() => {
    if (location.pathname === '/move') {
      clearEmployeeFilters();
      removeStorage();
    }
  }, [location]);

  useEffect(() => {
    const [routeMatched] = matchRoutes(routes, location.pathname);
    const newRoute = { ...routeMatched.route, path: routeMatched.pathname };

    if (navigationType === 'POP' && !historyStack.length) {
      setHistoryStack([newRoute]);
    } else {
      const tempArr = [...historyStack];

      if (navigationType === 'POP') {
        tempArr.pop();
      } else if (navigationType === 'PUSH') {
        tempArr.push(newRoute);
      } else if (navigationType === 'REPLACE') {
        tempArr.pop();
        tempArr.push(newRoute);
      }

      setHistoryStack(tempArr);
    }
  }, [location]);

  useLayoutEffect(() => {
    if (historyStack.length) {
      setCrumbs(historyStack.slice(-2));
      setPageTitle(historyStack[historyStack.length - 1].name);
    }
  }, [historyStack]);

  return (
    <RouterServiceContext.Provider
      value={{
        navigate,
      }}
    >
      {children}
    </RouterServiceContext.Provider>
  );
};

export const useRouterService = () => useContext(RouterServiceContext);
