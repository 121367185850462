import { forecastDetailsPath, homePath, linkAdminPath, Modules } from '@routes';
import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { t } from 'i18next';

type TAppHeaderConfig = (pageName: Modules) => AppHeaderConfigPayload;

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: ' ',
  vendorSelect: false,
  countrySelect: false,
  countryOptions: [],
  breadcrumbConfig: undefined,
};

const createBreadcrumbConfig = (items) => ({
  homePath: linkAdminPath,
  items,
});

const createBreadcrumbItem = (labelKey, path, isCurrentPage) => ({
  label: t(labelKey),
  path,
  isCurrentPage,
});

export const appHeaderConfig: TAppHeaderConfig = (pageName) => {
  switch (pageName) {
    case 'ORDER_FORECAST':
      return {
        pageTitle: ' ',
        breadcrumbConfig: createBreadcrumbConfig([
          createBreadcrumbItem('forecast:title', homePath, true),
        ]),
      };
    case 'CREATE_FORECAST':
      return {
        pageTitle: ' ',
        breadcrumbConfig: createBreadcrumbConfig([
          createBreadcrumbItem('forecast:title', homePath, false),
          createBreadcrumbItem('createForecast:title', homePath, true),
        ]),
      };
    case 'UPDATE_FORECAST':
      return {
        pageTitle: ' ',
        breadcrumbConfig: createBreadcrumbConfig([
          createBreadcrumbItem('forecast:title', homePath, false),
          createBreadcrumbItem('updateForecast:title', homePath, true),
        ]),
      };
    case 'FORECAST_DETAILS':
      return {
        pageTitle: ' ',
        breadcrumbConfig: createBreadcrumbConfig([
          createBreadcrumbItem('forecast:title', homePath, false),
          createBreadcrumbItem('forecastDetails:title', forecastDetailsPath, true),
        ]),
      };
    default:
      return defaultAppHeaderConfig;
  }
};
