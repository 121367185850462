import { generatePath } from 'react-router-dom';

export const EVALUATION_ROUTES = {
  postEvaluationPillar: '/evaluations/pillars-employee',
  postSelfPillars: '/evaluations/self',
  getKpisForEvaluation: {
    route: '/evaluations/:idEvaluation/pillars/:idPillar/kpis',
    generate(idEvaluation, idPillar) {
      return generatePath(this.route, {
        idEvaluation: String(idEvaluation),
        idPillar: String(idPillar),
      });
    },
  },
  getEmployeeKpis: '/evaluations/manager/:idEmployee/:idEvaluation/:idPillar',
  getSelfKpis: '/evaluations/self/:idEvaluation/:idPillar',
  postFinishEvaluation: {
    route: '/evaluations/:idEvaluation/finish',
    generate(idEvaluation) {
      return generatePath(this.route, { idEvaluation: String(idEvaluation) });
    },
  },
  saveEvaluationPillar: '/evaluations/manager/:idEmploye/:idEvaluation/:idPillar',
  saveSelfPillar: '/evaluations/self/:idEvaluation/:idPillar',
  saveEvaluation: '/evaluations/manager/:idEmployee/:idEvaluation',
  saveSelfEvaluation: '/evaluations/self/:idEvaluation',
  savePillarKpiResponses: {
    route: '/evaluations/:idEvaluation/responses',
    generate(idEvaluation) {
      return generatePath(this.route, { idEvaluation: String(idEvaluation) });
    },
  },
  getAllowSelfEvaluation: 'employee/allowSelfEvaluation',
};
