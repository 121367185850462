import { BASE_PATH } from 'constants/routes/internal/index';
import { useAuth } from 'contexts/auth/AuthContext';
import { useEffect, useState } from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';
import { routes } from '../utils/routes';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const { roles, isUserActive, isUserInfoFTEnabled } = useAuth();
  const [accessAllowed, setAccessAllowed] = useState<boolean>(true);

  const routeRule = routes.find((path) => matchPath(path, location.pathname));

  useEffect(() => {
    const canAccess = routeRule.canAccess.some((role) => roles.includes(role));
    setAccessAllowed(canAccess);
  }, [routeRule]);

  if (!accessAllowed || (isUserInfoFTEnabled && !isUserActive)) {
    return (
      <Navigate to={routeRule?.redirectTo?.path || `${BASE_PATH}/403`} state={{ from: location }} />
    );
  }

  return children;
};

export default PrivateRoute;
