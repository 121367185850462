import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { setAppHeaderConfig } from 'admin-portal-shared-services';

import { PATH_BASE, PATH_HOME } from '@/consts/route';

export function useVisits() {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setAppHeaderConfig({
      vendorSelect: false,
      pageTitle: formatMessage({ id: 'Pages.Visits.Title.PAGE_VISITS' }),
      optionalButtons: [],
      countrySelect: false,
      breadcrumbConfig: {
        homePath: `${PATH_HOME}${PATH_BASE}`,
        items: [
          {
            isCurrentPage: true,
            path: PATH_BASE,
            label: formatMessage({ id: 'Pages.Visits.Title.PAGE_VISITS' }),
          },
        ],
      },
    });
  }, [formatMessage]);
}
