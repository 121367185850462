export enum Country {
  BR = 'BR',
  US = 'US',
  AR = 'AR',
  CO = 'CO',
  DO = 'DO',
  EC = 'EC',
  MX = 'MX',
  PA = 'PA',
  PE = 'PE',
  ZA = 'ZA',
  HN = 'HN',
  SV = 'SV',
}

export interface CountryToCluster {
  cluster: 'EU' | 'GLOBAL';
  name: string;
  code: string;
}
