import { Card, Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const ExperimentStepsWrapper = styled(Grid.Item, {
  display: "flex",
  flexDirection: "column",
  padding: 0,
});

export const ExperimentStepsCard = styled(Card, {
  marginTop: "$$customMarginTop",
  height: "$$height_experiment_steps_wrapper",
  padding: "$$padding",
  paddingInline: "24px",
  width: "100%",
});
export const ExperimentFormHeader = styled(Grid.Item, {
  padding: 0,
  marginTop: "24px",
});
export const ExperimentFormControlCardInput = styled("div", {
  width: "$$width_form_control_input",
  marginTop: "$$marginTop_form_control_input",
  padding: 0,
  minHeight: "$$height_form_control_input",
  height: "auto",
  ".input": {
    padding: "0 16px",
  },
});

export const StepColumnForm = styled(Grid.Container, {
  display: "flex",
  flexDirection: "$$flex_direction",
  justifyContent: "space-between",

  padding: 0,
  ".item": {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  "&.default-config": {
    width: "100%",
    margin: 0,
  },
});
