import MessageMap from '../../i18n';

const productType: MessageMap = {
  PRODUCT_TYPE_LIST: {
    BEER: 'Cerveja',
    WATER: 'Água',
    NON_ALCOHOLIC: 'Não Alcoólico',
    NON_ALCOHOLIC_BEER: 'Cerveja Não Alcoólica',
    BEATS_AND_ICE: 'Bebidas e Gelo',
    FOOD: 'Comida',
    OTHERS: 'Outros',
  },
};

export default productType;
