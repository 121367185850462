import React, { useEffect, useRef } from 'react';
import { RouteProps, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { Error404 } from 'components';
import { useCountryContext } from 'context/country';
import { AllCountries, SupportedCountries } from 'domains/Countries';
import { setCountryInCompanyUrl } from 'utils/url';

interface PageRouteProps extends Omit<RouteProps, 'component'> {
  path: string;
  component: React.ComponentType<unknown>;
}

interface CountryUrlSyncPageProps {
  children: React.ReactNode;
}

const UrlCountryManager = ({ children }: CountryUrlSyncPageProps): JSX.Element => {
  const { country: countryParam } = useParams<{ country: string }>();
  const { country: selectorCountry, setCountry, getSupportedCountries } = useCountryContext();
  const history = useHistory();
  const location = useLocation();

  const urlCountry = countryParam?.toUpperCase();

  const isMounted = useRef(false);

  const isSupportedCountry = getSupportedCountries().includes(urlCountry as SupportedCountries);

  const hasError = !!urlCountry && !isSupportedCountry;

  useEffect(() => {
    if (urlCountry && selectorCountry !== urlCountry && isSupportedCountry) {
      setCountry(urlCountry as AllCountries);
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      history.replace({
        pathname: setCountryInCompanyUrl(location.pathname, selectorCountry),
        search: location.search,
      });
    } else {
      isMounted.current = true;
    }
  }, [selectorCountry]);

  return hasError ? <Error404 /> : <>{children}</>;
};

export const PageRoute = ({ component: Page, ...rest }: PageRouteProps): JSX.Element => (
  <Route {...rest}>
    <UrlCountryManager>{React.createElement(Page)}</UrlCountryManager>
  </Route>
);
