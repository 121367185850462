import { History2, Points } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { Suspense, lazy } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('../../pages/CatalogPage/Catalog'));
const CatalogPage = lazy(() => import('../../pages/CatalogPage/Catalog'));
const Argo = lazy(() => import('../../pages/CatalogPage/Argo'));
const ArgoCDAccess = lazy(() => import('../../pages/ArgoCD/ArgoAccess/ArgoAccess'));
const AzureCloudProvider = lazy(() => import('../../pages/AzureCloudProvider/AzureCloudProvider'));
const AzureServicePrincipal = lazy(
  () => import('../../pages/AzureCloudProvider/AzureServicePrincipal/AzureServicePrincipal')
);
const AzureRoleAssignment = lazy(
  () => import('../../pages/AzureCloudProvider/AzureRoleAssignment/AzureRoleAssignment')
);
const KubernetesPage = lazy(() => import('../../pages/KubernetesPage/KubernetesPage'));
const RabbitMQPage = lazy(() => import('../../pages/RabbitMQPage/RabbitMQPage'));
const SecretsManagementPage = lazy(() => import('../../pages/SecretsManagement/SecretsManagement'));
const IpaasPage = lazy(() => import('../../pages/IpaasPage/IpaasPage'));
const NewRelicPage = lazy(() => import('../../pages/NewRelicPage/NewRelicPage'));
const APMAlerts = lazy(() => import('../../pages/NewRelicPage/APMAlerts/APMAlerts'));
const APMPage = lazy(() => import('../../pages/NewRelicPage/APMAlerts/APMPage'));
const SPNAlerts = lazy(() => import('../../pages/NewRelicPage/SPNAlerts/SPNAlerts'));
const SPNPage = lazy(() => import('../../pages/NewRelicPage/SPNAlerts/SPNPage'));
const ASAlerts = lazy(() => import('../../pages/NewRelicPage/ASAlerts/ASAlerts'));
const INFRAAlerts = lazy(() => import('../../pages/NewRelicPage/INFRAAlerts/INFRAAlerts'));
const MongoDBPage = lazy(() => import('../../pages/MongoDbPage/MongoDbPage'));
const CosmosDBPage = lazy(() => import('../../pages/AzureCosmosDB/AzureCosmosDB'));
const PostgresqlPage = lazy(() => import('../../pages/PostgresqlPage/PostgresqlPage'));
const CosmosDbTemporaryUserAccess = lazy(() => import('../../pages/AzureCosmosDB/TemporaryUserAccess/TemporaryUserAccess'));
const ComsmosGetContainersPage = lazy(() => import('../../pages/AzureCosmosDB/GetContainers/GetContainer'));
const RabbitMQCreateUserApplication = lazy(() => import('../../pages/RabbitMQPage/CreateUserApplication/CreateUserApplication'));
const CloudAMQPRequestAccessPorta = lazy(() => import('../../pages/RabbitMQPage/RequestAccessCloudAMQPortal/RequestAccessCloudAMQP'));
const CreateTemporaryFirewallRulePage = lazy(() => import('../../pages/PostgresqlPage/CreateTemporaryFirewallRule/CreateTemporaryFirewallRule'));
const AzureGetSubscriptionKeysPage = lazy(() => import('../../pages/AzureCloudProvider/AzureGetSubscriptionKeys/AzureGetSubscriptionKeys'));
const ACRImportPublicImagePage = lazy(() => import('../../pages/KubernetesPage/ACRImportPublicImage/ACRImportPublicImage'));
const IPaaSImportSecretsKeyVaultPage = lazy(() => import('../../pages/IpaasPage/InsertSecretsKeyVault/InsertSecretsKeyVault'));

const PipelineBuildHistoryPage = lazy(
  () => import('../../pages/AzurePipelines/PipelineBuildHistoryPage')
);
const CallBackPage = lazy(() => import('../../pages/CallBackPage/CallBackPage'));
const AzureSubscriptionAccess = lazy(
  () => import('../../pages/AzureCloudProvider/AzureSubscriptionAccess/AzureSubscriptionAccess')
);
const DeploymentsPage = lazy(() => import('../../pages/Deployments/DeploymentsPage'));
const GateKeeperMetricsPage = lazy(
  () => import('../../pages/GateKeeperMetrics/GateKeeperMetricsPage')
);
const GithubOnboarding = lazy(() => import('../../pages/GitHub/GithubOnboarding'));
const GithubOffboarding = lazy(() => import('../../pages/GitHub/GithubOffboarding'));
const GithubMainPage = lazy(() => import('../../pages/GitHub/GithubMainPage'));

const IstioPage = lazy(() => import('../../pages/Istio/IstioPage'));
const HpaPage = lazy(() => import('../../pages/Hpa/HpaPage'));

const Router = (): JSX.Element => {
  const baseRoute = '/oneask-portal';

  useSidebar({
    items: [
      {
        id: '1',
        title: 'Services',
        icon: () => <Points />,
        path: baseRoute,
      },
      {
        id: '2',
        title: 'Pipelines Runs',
        icon: () => <History2 />,
        path: baseRoute + '/azure/pipelines/runs',
      },
    ],
    utils: [],
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={baseRoute}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CatalogPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/csp`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <AzureCloudProvider />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/csp/servicePrincipal`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <AzureServicePrincipal />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/csp/subscriptionAccess`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <AzureSubscriptionAccess />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/csp/roleAssignment`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <AzureRoleAssignment />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/csp/subscriptionKey`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <AzureGetSubscriptionKeysPage />
            </Suspense>
          }
        />



        <Route
          path={`${baseRoute}/catalog/mongodb`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <MongoDBPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/newrelic`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <NewRelicPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/APMAlerts`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <APMAlerts />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/APMAlerts/APMPage`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <APMPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/SPNAlerts`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <SPNAlerts />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/SPNAlerts/SPNPage`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <SPNPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/ASAlerts`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <ASAlerts />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/newrelic/INFRAAlerts`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <INFRAAlerts />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/ipaas`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <IpaasPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/ipaas/secrets`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <IPaaSImportSecretsKeyVaultPage />
            </Suspense>
          }
        />



        <Route
          path={`${baseRoute}/catalog/argo`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <Argo />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/argo/ArgoAccess`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <ArgoCDAccess />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/secretsManagement`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <SecretsManagementPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/kubernetes`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <KubernetesPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/kubernetes/acrimport`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <ACRImportPublicImagePage />
            </Suspense>
          }
        />



        <Route
          path={`${baseRoute}/catalog/rabbitmq`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <RabbitMQPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/rabbitmq/createUserApp`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <RabbitMQCreateUserApplication />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/rabbitmq/requestAccessCloudAmqp`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CloudAMQPRequestAccessPorta />
            </Suspense>
          }
        />



        <Route
          path={`${baseRoute}/catalog/cosmosdb`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CosmosDBPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/cosmosdb/temporaryUserAccess`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CosmosDbTemporaryUserAccess />
            </Suspense>
          }
        />
        {/*  */}

        <Route
          path={`${baseRoute}/catalog/cosmosdb/getContainers`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <ComsmosGetContainersPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/postgresql`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <PostgresqlPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/postgresql/firewall`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CreateTemporaryFirewallRulePage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/deployments`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <DeploymentsPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/gatekeepermetrics`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <GateKeeperMetricsPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/istio`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <IstioPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/hpa`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <HpaPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/catalog/github`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <GithubMainPage />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/github/onboarding`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <GithubOnboarding />
            </Suspense>
          }
        />

        <Route
          path={`${baseRoute}/catalog/github/offboarding`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <GithubOffboarding />
            </Suspense>
          }
        />


        <Route
          path={`${baseRoute}/azure/pipelines/runs`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <PipelineBuildHistoryPage />
            </Suspense>
          }
        />
        <Route
          path={`${baseRoute}/login/callback`}
          element={
            <Suspense fallback={<Spinner animation="border" role="status" />}>
              <CallBackPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
