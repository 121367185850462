import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable from '../../../components/Table/Table';
import handleFilter from '../../campaignDetails/extra/filter';
import TournamentHeaders from '../../json/columnHeaders/TournamentHeaders.json';
import mockData from '../../json/mock/mockingTournamentData.json';
import PageTitle from '../../../components/PageTitle/PageTitle';

const SettingsList: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['name', 'authored_by', 'changed', 'created', 'language', 'actions'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const InsertActions = (data) => {
    const actions = (tournament) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(`/bees-games/tournament/settings/tournament-details/${tournament.id}`);
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => ''}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete tournament?
            </Heading>
            <Paragraph>{'Do you really wish to delete this tournament?'}</Paragraph>
            <Paragraph>{'This action can’t be undone.'}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    return data.map((item) =>
      Object.assign({}, item, {
        actions: actions(item),
      })
    );
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Tournament Settings" />
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e) => handleSearch(e)}
              onClear={(e) => handleSearch(e)}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() => navigate(`/bees-games/tournament/settings/create-tournament`)}
          >
            Add Tournament
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={TournamentHeaders}
          search={false}
          data={data || []}
          loading={loading}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            showPageSizeSelector: true,
          }}
        />
      </Card>
    </>
  );
};

export default SettingsList;
