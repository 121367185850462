import { Card, Grid, Heading, Select, Tabs } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import StyledButton from '../../../components/Button/Button';
import StyledSelect from '../../../components/Select/Select';
import StyledTextarea from '../../../components/Textarea/Textarea';
import StyledTabs from '../../../components/Tabs/Tabs';
import EditTournamentTab from './tabs/EditTournamentTab';

export default function TournamentAssets() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const StyledPaddding = styled('div', {
    paddingBottom: '1rem',
  });

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const tabs = [{ component: EditTournamentTab, hash: '#edit', title: 'Edit' }];

  const { hash } = useLocation();

  return (
    <>
      <Grid.Container type="fluid">
        {/* <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Tournament Assets
          </Heading>
        </Grid.Item> */}

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" style={{ width: '100%' }}>
            <StyledTabs defaultValue={hash || '#edit'}>
              <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
                {tabs?.map((tab, index) => (
                  <a
                    key={index + tab.title}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={tab.hash}
                  >
                    <Tabs.Trigger key={index} value={tab.hash}>
                      {tab.title}
                    </Tabs.Trigger>
                  </a>
                ))}
              </Tabs.List>
              {tabs?.map((tab, index) => (
                <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                  <tab.component />
                </Tabs.Content>
              ))}
            </StyledTabs>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </>
  );
}
