import { Button, Card, Heading, Image, Paragraph } from '@hexa-ui/components';
import { Link } from 'react-router-dom';
import FlexContainer from '../../components/FlexContainer';
import { useAuthenticationService } from 'admin-portal-shared-services';
import useWindowScale from '../../hook/useWindowScale';
import { useEffect } from 'react';

const authentication = useAuthenticationService();

const TournamentPage = () => {
  const isAugmentedScale = useWindowScale();

  const ImageScaleStyle = {
    height: isAugmentedScale ? '120px' : '230px',
    width: '100%',
  };

  const CardScaleStyle = {
    height: isAugmentedScale ? '255px' : '460px',
    width: isAugmentedScale ? '250px' : '350px',
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="content-home">
      <Heading type="H3">Tournament</Heading>
      <FlexContainer display="flex" margin="1rem 0 1.5rem 0" gap="1rem" flexWrap="wrap">
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Football Leagues</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>List of available Football leagues and cups.</Paragraph>
              </div>
            </div>

            <Link to={'/bees-games/tournament/football-leagues'} style={{ textDecoration: 'none' }}>
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Tournament Participants</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize participants in you tournaments.</Paragraph>
              </div>
            </div>

            <Link
              to={'/bees-games/tournament/tournament-participant'}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Settings</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize the settings of your tournament.</Paragraph>
              </div>
            </div>

            <Link to={'/bees-games/tournament/settings'} style={{ textDecoration: 'none' }}>
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Score Sync</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize the Score Sync.</Paragraph>
              </div>
            </div>

            <Link to={'/bees-games/tournament/score-sync'} style={{ textDecoration: 'none' }}>
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Import Tournaments</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize your imported Tournaments.</Paragraph>
              </div>
            </div>

            <Link
              to={'/bees-games/tournament/tournament-import'}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
        <Card style={CardScaleStyle} elevated="medium" border="small" css={{ overflow: 'hidden' }}>
          <Image
            style={ImageScaleStyle}
            src={require('../../assets/phone.png')}
            containerCss={{ width: '100%' }}
          />
          <FlexContainer
            height="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            gap="0.75rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div>
              <Heading size="H4">Rugby Leagues</Heading>
              <div style={{ marginTop: isAugmentedScale ? '2px' : '14px' }}>
                <Paragraph>Customize your Rugby Leagues.</Paragraph>
              </div>
            </div>

            <Link to={'/bees-games/tournament/rugby-leagues'} style={{ textDecoration: 'none' }}>
              <Button variant="primary" css={{ alignSelf: 'flex-start' }}>
                Customize
              </Button>
            </Link>
          </FlexContainer>
        </Card>
      </FlexContainer>
    </div>
  );
};
export default TournamentPage;
