import { LoadingBuzz } from '@hexa-ui/components';
import { Box } from '@material-ui/core';

const LoadingScreen = () => (
  <Box
    height={'80vh'}
    display="flex"
    justifyContent={'center'}
    alignItems={'center'}
    data-testid="loading-screen"
    flex={1}
  >
    <LoadingBuzz size="xlarge" />
  </Box>
);
export default LoadingScreen;
