import useFilterInteractionEvent from 'Hooks/segment/useFilterInteractionEvent/useFilterInteractionEvent';
import useRenderEvents from 'Hooks/segment/useRenderEvents/useRenderEvents';
import { useMemo, useState } from 'react';
import {
  PowerBIEventHandler,
  PowerBIReportEvent,
  PowerBIReportStatus,
  UsePowerBIReportReturn,
} from './usePowerBIReport.types';
import usePowerBIReportEvents from './usePowerBIReportEvents';

const usePowerBIReport = (): UsePowerBIReportReturn => {
  const [status, setStatus] = useState(PowerBIReportStatus.LOADING_EMBED_REPORT);
  const { triggerInteractionRenderedProcess } = useRenderEvents();
  const {
    triggerFilterInteraction,
    triggerDataLoadedRendering,
    triggerReportRenderedProcess,
    triggerRenderingStartedRenderingProcess,
  } = useFilterInteractionEvent();

  const { trackReportEvent } = usePowerBIReportEvents();
  const isLoading = ![PowerBIReportStatus.RENDERING_STARTED, PowerBIReportStatus.RENDERED].includes(
    status
  );

  const eventHandlers = useMemo(
    () =>
      new Map<string, PowerBIEventHandler>([
        [
          'loaded',
          () => {
            setStatus(PowerBIReportStatus.LOADING_DATA);
            trackReportEvent(PowerBIReportEvent.DATA_LOADED);
            triggerDataLoadedRendering({
              is_core_event: false,
              page_name: 'MAIN_PAGE',
              occurence_id: 'A2',
            });
          },
        ],
        [
          'rendered',
          () => {
            setStatus(PowerBIReportStatus.RENDERED);
            triggerReportRenderedProcess({
              is_core_event: false,
              page_name: 'MAIN_PAGE',
              occurence_id: 'A4',
            });
            trackReportEvent(PowerBIReportEvent.REPORT_RENDERED);
          },
        ],
        [
          'dataSelected',
          (data) => {
            if (isLoading) return;

            const eventData = data as {
              detail: {
                dataPoints: Array<{ identity: Array<{ equals: string }> }>;
                visual: { name: string; title: string };
              };
            };

            triggerFilterInteraction({
              analytics_component_id: eventData.detail.visual.name,
              analytics_component_name: eventData.detail.visual.title,
              filter_selected: eventData.detail.dataPoints[0].identity[0].equals,
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
        [
          'buttonClicked',
          (data) => {
            if (isLoading) return;

            const eventData = data as { detail: { title: string; id: string } };

            const [name, filterSelected] = eventData.detail.title.split(': ');

            triggerFilterInteraction({
              analytics_component_name: name,
              filter_selected: filterSelected,
              analytics_component_id: eventData.detail.id,
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
        [
          'visualClicked',
          () => {
            triggerInteractionRenderedProcess({
              is_core_event: true,
              page_name: 'MAIN_PAGE',
              occurence_id: 'A5',
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
        [
          'renderingStarted',
          () => {
            setStatus(PowerBIReportStatus.RENDERING_STARTED);
            trackReportEvent(PowerBIReportEvent.RENDERING_STARTED);
            triggerRenderingStartedRenderingProcess({
              is_core_event: false,
              page_name: 'MAIN_PAGE',
              occurence_id: 'A2',
            });
          },
        ],
      ]),
    [
      isLoading,
      trackReportEvent,
      triggerDataLoadedRendering,
      triggerFilterInteraction,
      triggerInteractionRenderedProcess,
      triggerRenderingStartedRenderingProcess,
      triggerReportRenderedProcess,
    ]
  );

  return { eventHandlers, status, isLoading, trackReportEvent, setStatus };
};

export default usePowerBIReport;
