import React from 'react'
import { MenuItem, Select, SelectProps } from '@material-ui/core'
import { MuiThemeProvider, createStyles, createTheme } from '@material-ui/core/styles'
import { nanoid } from 'nanoid'
import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import styles from './styles'

export type SelectOptions = {
	value: string | number
	label: string | number
	disabled?: boolean
}

export type InputSelectProps = {
	options: SelectOptions[]
} & InputContainerProps &
	SelectProps

const InputSelect: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputSelectProps> = (
	{ id, label, optionalLabel, options, errorText, disableGutters, disableTopGutter, helperText, ...rest },
	ref,
) => {
	const classes = styles()

	const hasError = !!errorText

	const menuItems = React.useMemo(
		() =>
			options.map(({ value, label: labelOption, disabled: optionDisabled = false }: SelectOptions, key) => {
				return (
					<MenuItem key={nanoid()} id={`${id}-${key + 1}`} value={value} disabled={optionDisabled}>
						{labelOption}
					</MenuItem>
				)
			}),
		[options, id],
	)

	const selectStyle = createTheme({
		overrides: {
			MuiInputBase: createStyles({
				root: {
					height: '48px',
					borderRadius: '8px !important',
				},
			}),
		},
	})

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			disabled={rest.disabled}
			disableGutters={disableGutters}
			disableTopGutter={disableTopGutter}
			helperText={helperText}
		>
			<MuiThemeProvider theme={selectStyle}>
				<Select
					id={id}
					data-testid={id}
					displayEmpty
					classes={{
						icon: classes.icon,
						select: classes.select,
					}}
					fullWidth
					variant="outlined"
					error={hasError}
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
					}}
					innerRef={ref}
					style={{ paddingBottom: '6px' }}
					{...rest}
				>
					{menuItems}
				</Select>
			</MuiThemeProvider>
		</InputContainer>
	)
}

export default React.forwardRef(InputSelect)
