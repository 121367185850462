import {ListRequestProps, Paginated, Promotion} from '@Models';
import {parseFilterStructure} from '@martech/utils';
import {useApi} from '../../api';

export default async function getPromotionListService(
  campaignId: number,
  pageNumber: number,
  pageSize: number,
  props: ListRequestProps
): Promise<Paginated<Promotion> | any> {
  const {searchTerm, filter, sort} = props;
  const searchString = searchTerm ? `&searchQuery=${searchTerm}` : '';
  const filterString = parseFilterStructure(filter);
  const sortString = sort?.prop
    ? `&sortField=${sort.prop}&sortDirection=${sort.direction}`
    : '';

  return await useApi().get({
    url: `/v1/admin/campaigns/${campaignId}/promotions?page=${pageNumber}&pageSize=${pageSize}${sortString}${searchString}${filterString}`,
  });
}
