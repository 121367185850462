import { useEffect, useState } from 'react';
import { ServicesResponse, Translations } from '../interfaces';
import { getTranslationByLanguage } from '../services/copydeck';

interface UseTranslationsProps {
  locale: string;
  authHeader: string;
}

export const useTranslations = ({ locale, authHeader }: UseTranslationsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [translations, setTranslations] = useState<Translations | null>(null);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    const response: ServicesResponse | null = await getTranslationByLanguage({
      config: {
        headers: {
          Authorization: authHeader,
          requestTraceId: `credit-admin-portal-${new Date().getTime()}`,
        },
      },
      params: { language: locale },
    });

    if (response && response.success) {
      setTranslations(response.data as Translations);
    } else {
      setTranslations(null);
    }

    setIsLoading(false);
    setHasFetched(true);
  };

  useEffect(() => {
    if (!hasFetched) {
      fetchData();
    }
  }, [locale, hasFetched]);

  return { isLoading, translations };
};
