import { Notification } from '../interfaces';
import { useToast } from './useToast';

export const useNotification = () => {
  const { toast } = useToast();

  const notify = (notification: Notification) => {
    const { type, message, attributes, firstAction } = notification;

    const toastOptions = {
      message,
      attributes,
      ...(firstAction ? { firstAction } : {}),
    };

    switch (type) {
      case 'info':
        toast.info(toastOptions);
        break;
      case 'success':
        toast.success(toastOptions);
        break;
      case 'warning':
        toast.warning(toastOptions);
        break;
      case 'error':
        toast.error(toastOptions);
        break;
      default:
        console.warn('Unknown notification type:', type);
    }
  };

  return { notify };
};
