import { Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { SwitchWorkspacesLoadingProps } from './SwitchWorkspacesLoading.d';
import Styled from './SwitchWorkspacesLoading.style';

function SwitchWorkspacesLoading({
  workspaceTitle,
  segmentTitle,
}: SwitchWorkspacesLoadingProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const LoadingContent = (
    <div data-testid="switch-workspace-loading">
      <Styled.SwitchLoading data-testid="LoadingDots">
        <LoadingDots color="mono" size="xxlarge" />
      </Styled.SwitchLoading>
      <Styled.LoadingTitle data-testid="loadingTitle">
        <Heading size="H1" alignment="center" data-testid="loadingTitle">
          {formatMessage({ id: 'SWITCH_LOADING.TITLE' })}
        </Heading>
      </Styled.LoadingTitle>
      <Paragraph alignment="center" size="basis" data-testid="loadingParagraph">
        {formatMessage({ id: 'SWITCH_LOADING.ENTERING' })} {workspaceTitle}{' '}
        {formatMessage({ id: 'SWITCH_LOADING.USER' })} {segmentTitle}
      </Paragraph>
      <Paragraph alignment="center" size="basis" data-testid="loadingParagraph">
        {formatMessage({ id: 'SWITCH_LOADING.PARAGRAPH' })}
      </Paragraph>
    </div>
  );

  const renderLoading = () => <Styled.FullScreen>{LoadingContent}</Styled.FullScreen>;
  return renderLoading();
}

export default SwitchWorkspacesLoading;
