import styled from 'styled-components';

export const VisitTableCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  width: 50%;
`;

export const VisitTableCardHeader = styled.div`
  padding: ${({ theme }) => theme.measure.space['space-6']};
`;
