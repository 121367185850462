import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StackedActionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const InlineActionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '$1',
});

export const StyledParagraph = styled(Paragraph, {
  marginTop: '10px !important',
  maxWidth: '24rem',
  color: '$neutral100'
});
