import {
  AudienceObject,
  AudienceSelector as Selector,
} from "@dlb-shared-business-components/audience";
import { useAppHeader } from "admin-portal-shared-services";
import { useEffect, useMemo } from "react";
import useSelectedAudience from "../../hooks/useSelectedAudience/useSelectedAudience";
import {
  IAudienceSelection,
  IExperimentAudience,
} from "../../types/experiment";
import { useEnvProvider } from "../../utils/envProvider";

type AudienceSelectorProps = {
  step2Form: IAudienceSelection;
  handleOnChangeForm: (
    inputName: string,
    value: string | number | boolean | IExperimentAudience
  ) => void;
};

const AudienceSelector = ({
  handleOnChangeForm,
  step2Form,
}: AudienceSelectorProps) => {
  const env = useEnvProvider();
  const vendorId = useMemo(() => env?.env?.vendorId, [env?.env?.vendorId]);

  const { selectedAudience, handleSelectedAudience } = useSelectedAudience({
    audience: step2Form?.audiences,
  });

  const [appHeader, setAppHeaderConfig] = useAppHeader();
  useEffect(() => {
    setAppHeaderConfig({
      countryOptions: ["BR", "MX", "CO", "PE", "EC"],
      countrySelect: false,
      defaultCountry: "BR",
      vendorSelect: false,
      pageTitle: "",
    });
  }, [setAppHeaderConfig]);

  const onSelectAudience = (audience: AudienceObject) => {
    handleSelectedAudience(audience);
    console.log(audience, " Current Audience");
    handleOnChangeForm("audiences", {
      ...step2Form?.audiences,
      externalId: audience?.audienceId,
      name: audience?.audienceName,
      estimatedPocTargeted: audience?.estimatedPoc,
    });
  };

  return (
    <Selector
      vendorId={vendorId[step2Form.country]}
      country={step2Form.country}
      onSelectAudience={onSelectAudience}
      selectedAudienceId={selectedAudience?.externalId}
      profile="experimentationPlatform"
      isSearchable
    />
  );
};

export default AudienceSelector;
