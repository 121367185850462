import { IRepositoryRequirements } from 'repositories/repositories.common'
import {
  CreateDealsProps,
  ICreateDealDiscount,
  ICreateDealDiscountCoupon,
  ICreateDealFreeGood,
  ICreateDealFreeGoodMinimumAmount,
  ICreateDealInteractiveCombo,
  ICreateDealInteractiveComboMinimumAmount,
  ICreateDealSteppedDiscount,
  ICreateDealSteppedFreeGood,
  ICreateDealSteppedFreeGoodByAmount,
  ICreateDealSteppedInteractiveComboByAmount
} from './Deals.props'
import { Fetch } from 'utils'

export function DealsRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/deals`

  async function create ({ type, payload }: CreateDealsProps) {
    const url = payload.deliveryCenterId ? `${basePath}/ddc` : `${basePath}`

    const dealsKey: Record<typeof type, string> = {
      DISCOUNT: 'discount',
      FREE_GOOD: 'freeGood',
      STEPPED_DISCOUNT: 'scaledDiscounts',
      STEPPED_FREE_GOOD: 'scaledFreeGoods',
      STEPPED_FREE_GOOD_BY_AMOUNT: 'scaledFreeGoods',
      PRICE_REDUCTION: 'priceReduction',
      CROSS_DISCOUNT: 'crossDiscount',
      SPECIAL_OFFERS: 'specialOffers'
    }

    return Fetch.post({
      url,
      data: { ...payload, deal: { [dealsKey[type]]: payload.deal }, dealType: type },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealDiscount (payload: ICreateDealDiscount) {
    const url = `${basePath}/discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedDiscount (payload: ICreateDealSteppedDiscount) {
    const url = `${basePath}/stepped-discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealDiscountCoupon (payload: ICreateDealDiscountCoupon) {
    const url = `${basePath}/discount-coupon`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealFreeGood (payload: ICreateDealFreeGood) {
    const url = `${basePath}/free-good`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealFreeGoodMinimumAmount (payload: ICreateDealFreeGoodMinimumAmount) {
    const url = `${basePath}/free-good-minimum-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedFreeGood (payload: ICreateDealSteppedFreeGood) {
    const url = `${basePath}/stepped-free-good`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedFreeGoodByAmount (payload: ICreateDealSteppedFreeGoodByAmount) {
    const url = `${basePath}/stepped-free-good-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboForAccount (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboMinimumAmountForAccount (payload: ICreateDealInteractiveComboMinimumAmount) {
    const url = `${basePath}/interactive-combo-minimum-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboWithVariantsForAccount (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo-with-variants`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedInteractiveComboByAmountForAccount (payload: ICreateDealSteppedInteractiveComboByAmount) {
    const url = `${basePath}/stepped-interactive-combo-by-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  return {
    create,
    createDealDiscount,
    createDealSteppedDiscount,
    createDealDiscountCoupon,
    createDealFreeGood,
    createDealFreeGoodMinimumAmount,
    createDealSteppedFreeGood,
    createDealSteppedFreeGoodByAmount,
    createDealInteractiveComboForAccount,
    createDealInteractiveComboWithVariantsForAccount,
    createDealInteractiveComboMinimumAmountForAccount,
    createDealSteppedInteractiveComboByAmountForAccount
  }
}
