import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import {
  addUsers,
  addUsersDone,
  addUsersFail,
  closeAddUsersModal,
  loadVendor,
  loadVendorDone,
  loadVendorFail,
  openAddUsersModal,
  setVendorCountry,
  setVendorImage,
  showErrorFeedback,
  updateVendor,
  updateVendorDone,
  updateVendorFail,
} from './VendorDetailEvents';
import VendorDetailState from './VendorDetailState';

const initialState = {
  isLoading: false,
  isUpdating: false,
  vendor: null,
  errorFeedback: false,
  isAddUsersModalOpen: false,
} as VendorDetailState;

const VendorDetailStore = createStore(initialState)
  .on(loadVendor, (state) => updateLoading(state, true))
  .on(loadVendorDone, (state, vendor) => {
    const newState = cloneDeep(state);
    newState.isLoading = false;
    newState.vendor = vendor;
    return newState;
  })
  .on(loadVendorFail, (state) => updateLoading(state, false))
  .on(updateVendor, (state) => {
    const newState = cloneDeep(state);
    newState.isUpdating = true;
    return newState;
  })
  .on(updateVendorDone, (state, vendor) => {
    const newState = cloneDeep(state);
    newState.vendor = vendor;
    newState.isUpdating = false;
    newState.vendorImage = vendor.thumbnailUrl ? vendor.thumbnailUrl : null;
    return newState;
  })
  .on(updateVendorFail, (state) => {
    const newState = cloneDeep(state);
    newState.isUpdating = false;
    return newState;
  })
  .on(setVendorImage, (state, file) => {
    const newState = cloneDeep(state);
    newState.vendorImage = file;
    return newState;
  })
  .on(setVendorCountry, (state, country) => {
    const newState = cloneDeep(state);

    if (newState.vendor) {
      newState.vendor.country = country;
    }

    return newState;
  })
  .on(showErrorFeedback, (state, show) => {
    const newState = cloneDeep(state);
    newState.errorFeedback = show;
    return newState;
  })
  .on(openAddUsersModal, (state) => {
    const newState = cloneDeep(state);
    newState.isAddUsersModalOpen = true;
    return newState;
  })
  .on(closeAddUsersModal, (state) => {
    const newState = cloneDeep(state);
    newState.isAddUsersModalOpen = false;
    return newState;
  })
  .on(addUsers, (state) => updateLoading(state, true))
  .on(addUsersDone, (state, emailList) => {
    const newState = cloneDeep(state);
    newState.isLoading = false;
    if (newState.vendor && emailList) {
      newState.vendor.allowedEmails = emailList.allowedEmails;
    }
    return newState;
  })
  .on(addUsersFail, (state) => {
    const newState = cloneDeep(state);
    newState.isLoading = false;
    newState.errorFeedback = true;
    return newState;
  });

const updateLoading = (state: VendorDetailState, loading: boolean) => {
  const newState = cloneDeep(state);
  newState.isLoading = loading;
  return newState;
};

export default VendorDetailStore;
