import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import SkuForm from '@/domains/sku/SkuForm'
import { SKUProcess } from '@/domains/sku/SkuStep'

export interface UploadFileType {
	value: FileList
	searchIds?: string[]
}

export type SkuModalValueType = { items: SkuForm[]; fileName: string; fileSize: number }

interface SkuModalContextProps {
	errors: string
	setError: (error: string) => void
	clearErrors: () => void
	setSkuModalValue: (file: SkuModalValueType) => void
	skuModalValue: SkuModalValueType
	addType: { type?: SKUProcess; continue: boolean }
	setAddType: (data: { type?: SKUProcess; continue: boolean }) => void
}

const SkuModalContext = createContext<SkuModalContextProps>({} as SkuModalContextProps)

export const SkuModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [skuModalValue, setSkuModalValue] = useState<SkuModalValueType>({ fileName: '', items: [], fileSize: 0 })
	const [addType, setAddType] = useState<{ mode?: SKUProcess; continue: boolean }>({ continue: false }) // Replace SKUProcess.Initial with a valid initial value

	const [errors, setErrors] = useState<string>('')

	const setError = (error: string) => {
		setErrors(() => error)
	}

	const clearErrors = () => setErrors('')

	const contextValue = useMemo(
		() => ({
			errors,
			setError,
			clearErrors,
			skuModalValue,
			setSkuModalValue,
			addType,
			setAddType,
		}),
		[errors, skuModalValue, setSkuModalValue, addType],
	)

	return <SkuModalContext.Provider value={contextValue}>{children}</SkuModalContext.Provider>
}

export const useSkuModalContext = (): SkuModalContextProps => {
	const context = useContext(SkuModalContext)
	return context
}
