import { enUS } from './en-US';

export const ptBr: typeof enUS = {
  breadcrumb: {
    commercialAgreements: {
      title: 'Planos de negócios',
    },
    JBPManagement: {
      title: 'JBP Managementt',
    },
    JBPVisibility: {
      title: 'Visibilidade de planos de negócios',
      kpiPage: {
        title: 'Detalhes de planos de negócios',
      },
    },
    JBPContractManagment: {
      title: 'Gerenciamento de plano',
      createContract: {
        title: 'Criar contrato',
      },
      createContractUpload: {
        title: 'Contract creation',
      },
    },
    JPBUpdate: {
      title: 'Atualização de contratos',
    },
    PINVisibility: {
      title: 'Visibilidade de planos de negócios',
      subTitle: 'Acompanhe as metas da rede que você gerencia.',
      kpiPage: {
        title: 'Detalhes de planos de negócios',
      },
    },
    CatalogPage: {
      title: 'Catalog Page',
    },
    commercialAgreementsDetails: {
      title: 'Detalhes de planos de negócios',
    },
  },
  leverBar: {
    targetAchieved: 'Meta atingida',
    missingTargetFirst: 'Faltam ',
    missingTargetSecond: 'para atingir a meta de ',
    potentialPrize: 'Prêmio potencial',
  },
  leverDonut: {
    storesOnTarget: 'Lojas atingindo a meta',
    storesOutTarget: 'Lojas não atingindo a meta',
    noData: 'Lojas sem informação',
    targetPerStore: 'Meta por loja',
    totalStoresTarget: 'Meta de lojas atingindo',
    potentialReward: 'Recompensa potencial',
  },
  errorMessage: {
    400: 'Algo deu errado. Por favor, tente novamente em alguns minutos. Caso o problema persista, entre em contato com o suporte.',
    403: 'A informação que você está tentando acessar não está disponível.',
    500: 'Algo deu errado. Por favor, tente novamente em alguns minutos. Caso o problema persista, contacte o suporte.',
    404: {
      buttonText: 'Voltar',
      headerText: 'Humm… Parece que esta página desapareceu',
      subHeaderText: 'Parece que esta página não foi encontrada ou não existe.',
    },
    503: {
      buttonText: 'Tente novamente',
      headerText: 'Hm... Serviço indisponível!',
      infoText: 'Se o problema persistir, você pode reportar o problema.',
      subheaderText:
        'Nossos servidores estão temporariamente muito ocupados. Você pode tentar novamente em alguns minutos e tudo deverá estar normalizado.',
    },
    leversPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'Nenhum plano encontrado',
        subtitle:
          'Esta rede não possui nenhum plano de negócio registrado. Crie uma promoção ou contrato.',
        buttonText: 'Create new contract',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: 'Ooops! Something went wrong',
    },
    pinsPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'Nenhum plano encontrado',
        subtitle:
          'Esta rede não possui nenhum plano de negócio registrado. Crie uma promoção ou contrato.',
        buttonText: 'Create new contract',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: 'Ooops! Something went wrong',
    },
    commercialAgreementsDetailsPage: {
      history: {
        404: {
          subtitle: 'Não há planos anteriores para mostrar. Acesse seu contrato atual.',
        },
      },
      currentContract: {
        404: {
          title: 'Nenhum plano encontrado',
          subtitle: 'Esta rede não possui nenhum plano de negócio registrado.',
          subtitleSecondary: 'Crie uma promoção ou contrato.',
          subtitleSelectedPeriod:
            'Esta rede não possui nenhum acordo comercial para o período selecionado.',
          firstAction: 'Criar nova promoção',
          secondAction: 'Criar novo plano',
        },
      },
    },
    accessDenied: {
      title: 'Hmm... Acesso negado!',
      subtitle: 'Parece que essa página não foi encontrada ou não existe.',
      buttonText: 'Home',
    },
  },
  table: {
    rowsPerPage: 'Registros por pagina',
    paginationCounter: 'de',
    emptyMessage: 'Não há informações para mostrar.',
    loadingMessage: 'Carregando dados',
    selectable: {
      displayTextOneRowSelected: 'selecionado',
      displayTextMoreThanOneRowSelected: 'selecionados',
      displayTextDeselectAll: 'Desselecionar tudo',
    },
  },
  rewardCard: {
    title: 'Recompensa ganha',
    label: 'Sumario de recompensas',
    targetProgress: {
      emptyState: 'Para acompanhar as recompensas, as metas devem ser aceitas.',
      supportInfo: {
        increase: 'ganhos no mês passado',
        decrease: 'ganhos no mês passado',
      },
      allCompleted: 'Pagamento máximo baseado nas metas atingidas para esta meta.',
    },
    totalRevenue: {
      toEarn: 'para ganhar',
      moreToEarn: 'more to earn',
    },
  },
  components: {
    growthKpiCard: {
      acceptButton: 'Aceitar',
      targetReachedMessage: 'Meta atingida!',
      invoicedOrder: 'Pedido faturado',
      orderInProgress: 'Pedido em andamento',
      targetLabel: 'Meta',
      currentTarget: 'Meta atual',
      remainingLabel: 'Faltante',
      rewardLabel: 'Recompensa',
      potentialRewardLabel: 'Recompensa',
      currentRebate: 'Reembolso atual',
      comparisonLabel: 'Até',
      lastUpdateLabel: 'Última atualização',
      date: '%day de %month de %year',
      trayButton: 'Mostrar detalhes da meta',
      finalRewardDescription: 'Recompensa máxima garantida',
      orderInProgressAlertMessage:
        'Pedidos em andamento não entregues não serão incluídos no atingimento das metas.',
      measureTypes: {
        singular: {
          case: 'case',
        },
        plural: {
          case: 'cases',
        },
      },
      rewardTooltip: 'Pagamento baseado no atingimento desta meta.',
    },
    numericKpiCard: {
      progress: '%progress de %target',
      targetReachedMessage: 'Meta atingida!',
      targetLabel: 'Meta',
      currentTarget: 'Meta atual',
      remainingLabel: 'Faltante',
      rewardLabel: 'Recompensa',
      potentialRewardLabel: 'Recompensa',
      currentRebate: 'Reembolso atual',
      finalRewardLabel: 'Final reward',
      rewardTooltip: 'Pagamento baseado no atingimento desta meta.',
      rewardTooltipIRS: 'Pagamento baseado no atingimento desta meta.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI.',
      lastUpdateLabel: 'Última atualização',
      measureTypes: {
        singular: {
          store: 'loja',
        },
        plural: {
          store: 'lojas',
        },
      },
      comparisonLabel: 'Até',
      maximumAchieved: 'Recompensa máxima garantida',
    },
    percentageKpiCard: {
      progress: '%progress de %target',
      targetReachedMessage: 'Meta atingida!',
      targetLabel: 'Meta',
      currentTarget: 'Meta atual',
      remainingLabel: 'Faltante',
      rewardLabel: 'Recompensa',
      potentialRewardLabel: 'Recompensa',
      currentRebate: 'Reembolso atual',
      finalRewardLabel: 'Final reward',
      maximumAchieved: 'Recompensa máxima garantida',
      comparisonLabel: 'Até',
      rewardTooltip: 'Pagamento baseado no atingimento desta meta.',
      rewardTooltipIRS: 'Pagamento baseado no atingimento desta meta.',
      currentRewardTooltip: 'Current payout based on the achieved targets for this ICR.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI.',
      lastUpdateLabel: 'Última atualização',
      bulkText:
        'Sugira um produto ou crie uma oferta para seu cliente iniciar o progresso da meta.',
      convenienceText: 'Embalagens descartáveis ajudam a atingir este objetivo.',
    },
    irsKpiCard: {
      targetReachedMessage: 'Exit reached!',
      errorMessage: 'Something went wrong.',
      notStartedMessage: 'An order of %value cases will increase this KPI by 10%.',
      inProgressMessage: '%value from last week',
      targetAchievedMessage: 'Your client must continue buying products to increase the payout!',
      exitAchievedMessage: 'Exit achieved! Maximum guaranteed payout.',
      invoicedOrder: 'Invoiced order',
      targetLabel: 'Target',
      exitLabel: 'Exit',
      rewardLabel: 'Potential payout',
      potentialRewardLabel: 'Potential payout',
      comparisonRewardLabel: 'Até',
      finalRewardLabel: 'Final payout',
      finalRewardDescription: 'Maximum guaranteed payout',
      rewardTooltip: 'Payout based on the achieved targets for this KPI. Incl. VAT.',
      exitTooltip: 'Maximum cases amount to receive a payout.',
      targetTooltip: 'Minimum cases amount to start receiving a payout.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI. Incl. VAT.',
      lastUpdateLabel: 'Last update',
      lastUpdateDate: '%month %day, %year',
      measureTypes: {
        singular: {
          case: 'case',
        },
        plural: {
          case: 'cases',
        },
      },
    },
    generalKpiCardInfo: {
      lastUpdateDate: '%month %day, %year',
      statusLabel: {
        notStarted: 'Not started',
        waitingAcceptance: 'Aguardando aceite',
        accepted: 'Aceito',
        inProgress: 'Em andamento',
        achieved: 'Atingida',
        notAchieved: 'Não atingida',
        expired: 'Expirado',
        almostAchieved: 'Parcialmente atingida',
        overAchieved: 'Meta superada',
        exceeded: 'Exceeded',
      },
    },
    historyTable: {
      contract: 'Contrato',
      contractId: 'ID do contrato',
      targetsAchieved: 'Metas atingidas',
      targetsAchievedMessage: '%s1 de %s2 metas',
      performanceMessage: '% comparado ao plano anterior',
      rewardEarned: 'Recompensa ganha',
      rewardEarnedMessage: 'de ',
      date: '%s1 de %s2 de %s3 - %s4 de %s5 de %s6',
      showDetails: 'Mostrar detalhes',
      hideDetails: 'Ocultar detalhes',
      tableTitle: 'Detalhes das metas',
      columns: {
        kpi: 'Meta',
        status: 'Status',
        target: 'Meta atingida',
        remaining: 'Faltante',
        rebate: 'Reembolso',
        reward: 'Recompensa ganha',
        type: 'Tipo',
      },
      status: {
        achieved: 'Atingido',
        notAchieved: 'Não atingido',
        expired: 'Expirado',
      },
      table: {
        label: 'KPI de contrato passado',
        messages1:
          'Seu cliente receberá uma parte da recompensa por ter quase atingido 1 ou mais metas.',
        messages2: 'Seu cliente receberá uma recompensa bônus por ter superado 1 ou mais metas.',
        messages3:
          'As recompensas do seu cliente da rede foram ajustadas porque ele quase alcançou e superou 1 ou mais metas.',
      },
      unitsMeasurement: {
        store: 'store',
        stores: 'stores',
        percentage: '%',
        hectoliter: 'hL',
        hl: 'hL',
        case: 'Cs',
      },
    },
    abbreviatedMonthNames: {
      m01: 'Jan',
      m02: 'Fev',
      m03: 'Mar',
      m04: 'Abr',
      m05: 'Mai',
      m06: 'Jun',
      m07: 'Jul',
      m08: 'Ago',
      m09: 'Set',
      m10: 'Out',
      m11: 'Nov',
      m12: 'Dez',
    },
    selectWithSearch: {
      searchPlaceholder: 'Search by stores',
      showAllStores: 'Show all stores',
      storeName: '%storeName - %storeCode',
      allStores: 'All stores',
      noResults: 'No results. Review your search or try searching for a different store.',
    },
    tray: {
      currentTarget: 'Meta atual',
      currentRebate: 'Reembolso atual',
      targetLevels: 'Níveis de meta',
      textButton: 'Mostrar detalhes da meta',
      tableHeader: {
        indicator: 'Nome',
        target: 'Meta',
        targetRebate: 'Reembolso da meta',
        status: 'Status',
      },
      statusLabel: {
        success: 'Atingido',
        inProgress: 'Em progresso',
        notStarted: 'Não iniciado',
        default: ' ',
      },
    },
  },
  kpiCard: {
    title: 'Metas no plano',
    amountToAchieve: 'para atingir',
    acceptTargets: 'Para acompanhar o progresso, as metas devem ser aceitas.',
    achievedLastMonth: 'atingido no mês passado',
    allCompleted: 'Todas as metas atingidas!',
    name: 'metas',
  },
  pages: {
    home: {
      title: 'Planos de negócios',
      subTitle: 'Qual informação você gostaria de acessar?',
      navigationCards: {
        JBPManagement: {
          title: 'Gestão de acordos comerciais',
          subTitle: 'Crie e gerencie acordos comerciais',
        },
        JBPVisibility: {
          title: 'Visibilidade de planos de negócios',
          subTitle: 'Acompanhe o progresso das metas por rede e lojas.',
        },
        JBPContractManagment: {
          title: 'Gerenciamento de plano',
          subTitle: 'Crie e gerencie os planos de seus clientes.',
        },
        UpdateByUpload: {
          title: 'Atualização de contratos',
          subTitle: 'Atualize as metas e o progresso de redes e lojas de uma vez.',
        },
        CreateByUpload: {
          title: 'Contract Creation',
          subTitle: 'Bulk create contracts for multiple chains and stores at once.',
        },
      },
      informationMessage: {
        text: 'A equipe da BEESHUB está trabalhando duro para ajudar no dia-a-dia nas execuções de negócios, novos recursos estão chegando',
        highlight: 'em breve..',
      },
    },
    JBPVisibility: {
      title: 'Visibilidade de planos de negócios',
      subTitle: 'Acompanhe o progresso das metas por rede e lojas.',
      chainTable: {
        idNumber: 'ID da rede',
        chainName: 'Nome da rede',
        type: 'Tipo',
        searchPlaceholder: 'Busque pelo nome ou ID do cliente',
        emptyMessage: 'Cliente não encontrado',
        loadingMessage: 'Carregando clientes...',
        group: 'Rede',
        store: 'Loja',
        independentStore: 'Loja independente',
      },
      kpiPage: {
        title: 'Detalhes de planos de negócios',
        currentKpis: 'KPIs ativos',
        kpiSection: {
          growth: {
            title: 'Growth KPIs',
            subtitle: 'Get an overview of your general progress on growth driving.',
          },
          categoryGrowth: {
            title: 'Category growth KPIs',
            subtitle: 'Get an overview of the progress in each category.',
          },
          trade: {
            title: 'Trade KPIs',
            subtitle: 'Get an overview of the general progress on trade driving.',
          },
        },
      },
    },
    JBPContractManagment: {
      title: 'Gerenciamento de plano',
      subTitle: 'Pesquisar por ID do plano ou cliente.',
      updateButton: 'Atualizar planos',
      createNewContract: 'Criar contrato',
      contractDetails: 'Detalhes do contrato',
      table: {
        searchPlaceholder: 'Pesquisar por ID do plano ou cliente',
        client: 'Cliente',
        contractId: 'ID do plano',
        contractType: 'Tipo do plano',
        status: {
          label: 'Status',
          active: 'Ativo',
          canceled: 'Cancelado',
          expired: 'Expirado',
        },
        startDate: 'Data de início',
        endDate: 'Data de término',
        lastUpdate: 'Última atualização',
        date: '%day de %month de %year',
        actions: 'Ações',
        manageDrivers: 'Gerenciar impulsionadores',
        cancelContract: 'Cancelar contrato',
      },
      modalCreateContracts: {
        title: 'Create contract',
        subtitle: 'Select how you want to create the contract:',
        options: {
          JBP: {
            title: 'JBP creation',
            subtitle: 'Create a single contract for a retailer group.',
          },
          IRS: {
            title: 'IRS creation',
            subtitle: 'Create multiple contracts for multiple stores at once.',
          },
        },
        continueButton: 'Continue',
      },
      create: {
        requiredField: 'Campo obrigatório.',
        requiredValidNumber: 'Campo obrigatório. Insira um número válido.',
        endEarlierStartDate: 'Data de término é anterior à data de início',
        detailsStep: {
          label: 'Detalhes do contrato',
          detail: 'Insira as informações da rede',
          selectChain: 'Selecione a rede',
          select: 'Selecionar',
          contractId: 'ID do contrato',
          contractIdHint: 'Este ID pode conter letras e números, até 30 caracteres.',
          insert: 'Inserir',
          startDate: 'Data de início do contrato',
          endDate: 'Data de término do contrato',
          datePlaceholder: 'MM/DD/YYYY',
          cancel: 'Cancelar',
          continue: 'Continuar',
        },
        driversStep: {
          label: 'Impulsionadores do contrato',
          detail: 'Defina as metas do contrato',
          driverName: 'Nome do impulsionador',
          kpiCategory: 'Categoria do impulsionador',
          kpiCategorySub:
            'Apenas um impulsionador pode estar ativo por rede. Todo contrato ativo é considerado.',
          select: 'Selecionar',
          measurement: 'Medição',
          target: 'Meta',
          reward: 'Recompensa',
          optional: 'opcional',
          rewardPlaceholder: 'Inserir recompensa',
          pageSection: 'Page section',
          enterName: 'Inserir nome',
          enterTarget: 'Inserir meta',
          addNew: 'Adicionar novo',
          addExisting: 'Adicionar existente',
          addNewTitle: 'Criar novo plano',
          addNewSubTitle: 'Crie um novo impulsionador para adicionar ao contrato',
          editSubtitle: 'Editar a informação do impulsionador',
          addAdditional: 'Adicionar outro novo impulsionador',
          saveAndContinue: 'Salvar e continuar',
          saveChanges: 'Salvar mudanças',
          successfullyCreated: 'Impulsionador criado com sucesso!',
          successfullyUpdated: 'Impulsionador atualizado com sucesso!',
          alreadyInUse: 'Esse Impulsionador já está em uso.',
          cardTitle: 'Impulsionadores',
          cardDetail:
            'Use impulsionadores para definir a meta do contrato e acompanhar o progresso.',
          cannotEditTargets: 'Não é possível editar metas depois de criá-las.',
          addTargetsWillBeShown: 'As alavancas adicionadas serão mostradas aqui.',
          goBackButton: 'Voltar',
          addContractButton: 'Adicionar ao contrato',
          editDriver: 'Editar impulsionador',
          deleteDriver: 'Excluir KPI',
          completed: 'Concluído',
          notCompleted: 'Não concluído',
          hectoliterSymbol: 'hl',
          contractSuccessfullyCreated: 'Contrato criado com sucesso!',
          saveContractError: 'Algo deu errado!',
          savePermissionError: 'Você não tem permissão para realizar esta ação.',
          sameContractId: 'Esse contrato já está em uso.',
          kpiSuccessfullyRemoved: 'KPI removido com sucesso!',
          modalDeleteDriver: {
            title: 'Excluir KPI?',
            confirmButton: 'Sim, excluir',
            cancelButton: 'Não, voltar',
          },
          notEnabled: 'Already in a contract',
          modalDriverAvailable: {
            title: 'Existing KPIs',
            closeButton: 'Check KPIs',
            message:
              "There's 1 or more KPI active in a current contract. Check the KPIs to create the contract.",
          },
        },
      },
      details: {
        title: 'Detalhes do contrato',
        startDate: 'Data de início',
        endDate: 'Data de término',
        cancel: 'Cancelar',
        addNew: 'Adicionar novo',
        target: 'Meta',
        reward: 'Recompensa',
        progress: 'Progresso',
        openProgress: 'Abrir progresso',
        deactivateDriver: 'Desativar impulsionador',
        showTargetProgress: {
          title: 'Mostrar progresso da meta',
          description:
            'Atualize as metas de cada impulsionador para acompanhar o progresso do contrato.',
        },
        updateDriver: {
          showProgress: 'Mostrar progresso',
          updateProgess: 'Atualizar progresso',
          save: 'Salvar',
          update: 'Atualizar',
          status: 'Status do impulsionador:',
          driverMeasurement: 'Medição do impulsionador:',
          target: 'Meta:',
          progress: 'Progresso',
          companyReachItsTarget: 'A empresa atingiu sua meta?',
          yes: 'Sim',
          no: 'Não',
          driverUpdated: 'Impulsionador atualizado com sucesso!',
          driverUpdateError: 'Algo deu errado!',
          insertValidNumber: 'Insira um número válido.',
          kpiCategory: 'Categoria do KPI:',
          kpiMeasurement: 'Medição do KPI:',
          permissionError: 'Você não tem permissão para realizar esta ação.',
        },
        measurement: {
          currency: 'Moeda',
          number: 'Numérico',
          volHectoliter: 'Volume (hL)',
          volCase: 'Volume (Cs)',
          percentage: 'Porcentagem',
          volume: 'Volume',
          trueFalse: 'Concluído/Não concluído',
        },
        monthOfUpdate: {
          title: 'Mês de atualização',
          subtitle: 'Mês que será atualizado',
        },
      },
      delete: {
        title: 'Excluir planos?',
        subTitle: `Você está excluindo %agreements planos de %clients clientes.`,
        detail: 'Esta ação não poderá ser desfeita.',
        confirmButton: 'Sim, excluir',
        cancelButton: 'Não, voltar',
        canceledContract: 'O contrato foi cancelado com sucesso.',
        canceledContractError: 'Você não tem permissão para realizar esta ação.',
        errorCancelMessage: 'Algo deu errado.',
      },
    },
    UploadPage: {
      update: {
        title: 'Atualização de contratos',
        subTitle: 'Atualize o progresso dos KPIs de redes e lojas de uma vez.',
        uploadCard: {
          title: 'Carregar arquivo',
          subTitle: 'Use a planilha modelo para preencher as informações de atualização.',
        },
        downloadSpreadsheet: {
          title: 'Baixar modelo',
          spreadsheetJBP: 'Planilha JBP',
          spreadsheetIRS: 'Planilha IRS',
        },
        doneButton: 'Atualizar',
      },
      create: {
        title: 'Contract creation',
        subTitle: 'Bulk create contracts for multiple chains and stores at once.',
        uploadCard: {
          title: 'Faça o upload de um arquivo',
          subTitle: 'Use a planilha modelo para preencher as informações de atualização.',
        },
        downloadSpreadsheet: 'Faça o download da planilha modelo',
        doneButton: 'Criar',
      },
      fileUploader: {
        message: 'O arquivo deve estar no formato XLS ou XLSX e ter até 25 MB de tamanho.',
        button: 'Selecione o arquivo',
        uploadSuccess: 'Arquivo enviado com sucesso!',
        uploadError: {
          tryAgain: 'Carregar novo arquivo.',
          length: 'Selecione um arquivo por vez',
          size: 'Tamanho do arquivo acima do limite. Revise o arquivo e tente novamente.',
          format: 'Formato do arquivo não suportado. Revise o arquivo e tente novamente.',
          failed: 'Falha ao carregar arquivo. Tente novamente.',
          messageServer:
            'O upload do arquivo falhou. Tente novamente ou entre em contato com o suporte.',
        },
      },
      fileSummary: {
        analysis: 'Análise do arquivo',
        fileBeingAnalyzed: 'Estamos analisando seus arquivos.',
        dontCloseWindow: 'Mantenha esta página aberta.',
        fileSummary: 'Análise do arquivo',
        clients: 'Clientes',
        chains: 'Rede',
        contracts: 'Contratos',
        kpis: 'KPIs',
        inconsistencies: 'Inconsistências',
        alert:
          'Baixe o arquivo analisado para revisar e corrigir inconsistências. Depois, carregue o arquivo novamente.',
        fileProcessingError: 'Não foi possível analisar o arquivo.',
        reProcessFile: 'Tentar novamente',
      },
      downloadSummarySpreadsheet: 'Baixar arquivo analisado.',
    },
    PINVisibility: {
      title: 'Visibilidade de acordos comerciais',
      subTitle: 'Acompanhe o progresso dos KPIs por cadeias e lojas.',
      updateKpis: 'Atualizar metas',
      chainTable: {
        idNumber: 'ID da rede',
        chainName: 'Nome da rede',
        searchPlaceholder: 'Busque pelo nome ou ID do cliente',
        emptyMessage: 'Cliente não encontrado',
        loadingMessage: 'Carregando clientes...',
      },
      kpiPage: {
        title: 'Detalhes de planos de negócios',
        currentKpis: 'KPIs ativos',
        pinLevers: {
          title: 'KPIs de alavancas de faturamento',
          subtitle: 'Tenha uma visão geral do progresso dos seus KPIs.',
          onLeverClicked: 'Alavanca aceita! Atinja 100% da alavanca para obter a recompensa.',
          cards: {
            loading: 'Carregando alavancas',
            loadingAux: 'Estamos quase lá...',
            incompleteTargetText: 'Faltam %s1 para meta de %s2',
            completeTargetText: 'Meta atingida, %s',
            potentialReward: 'Recompensa potencial',
            toBeAccepted: 'A ser aceito',
            accepted: 'Aceito',
            tooltipText: 'Essa alavanca inclui os seguintes itens:',
          },
          homeButton: 'Início',
          tryAgainButton: 'Tentar novamente',
          errors: {
            tryAgainText: 'Tentar novamente',
            homeText: 'Início',
            '403': {
              title: 'Humm... Acesso negado!',
              subtitle: 'Parece que esta página não foi encontrada ou não existe.',
            },
            '404': {
              title: 'Mmm... Parece que esta página sumiu.',
              subtitle: 'Parece que esta página não foi encontrada ou não existe.',
            },
            '503': {
              title: 'Hum... Serviço indisponível!',
              subtitle:
                'Nossos servidores estão temporariamente muito ocupados. Você pode tentar novamente em alguns minutos e deve estar normalizado.',
              auxText: 'Se o problema persistir, você pode reportar o problema!',
            },
            default: {
              title: 'Opss... Alguma coisa está errada!',
            },
          },
        },
      },
    },
    commercialAgreementsDetailsPage: {
      title: 'Detalhes de planos de negócios',
      subTitle: 'Acompanhe as metas da rede que você gerencia.',
      contractSummary: {
        title: 'Resumo do plano',
        activeOneContractsLabel: 'plano ativo',
        activeMultiContractsLabel: 'planos ativos',
        showContracts: 'Mostrar plano',
      },
      activeContracts: {
        title: 'Planos ativos',
        subtitle: 'Acesse detalhes dos planos e lojas participantes.',
        idLabel: 'ID',
        typeLabel: 'Tipo',
        date: 'Válido de {startDay} {startMonth} de {startYear} a {endDay} {endMonth} de {endYear}',
      },
      history: {
        details: {
          kpi: 'Meta',
          status: 'Status',
          target: 'Meta atingida',
          remaining: 'Faltante',
          reward: 'Recompensa ganha',
          rebate: 'Reembolso',
          notAchieved: 'Não atingida',
          achieved: 'Atingida',
          expired: 'Expirada',
          partiallyAchieved: 'Parcialmente atingido',
          almostAchieved: 'Parcialmente atingida',
          overAchieved: 'Meta superada ',
          type: 'Tipo',
        },
        error:
          'Não foi possível carregar os detalhes do plano. Recarregue esta página e tente novamente.',
      },
      currentContract: {
        generalGroup: {
          title: 'KPIs gerais',
          description: ' ',
        },
        totalGrowth: {
          title: 'Metas de crescimento total',
          description: 'Todos os pedidos feitos pela rede.',
        },
        categoryGrowth: {
          title: 'Metas de crescimento de categoria',
          description: 'Pedidos em categorias específicas feitos pela sua rede.',
        },
        shareSpace: {
          title: 'Share space KPIs',
          description: 'Produtos disponibilizados nas prateleiras da loja.',
        },
        irsKpi: {
          title: 'IRS KPI',
          description: 'All bulk products ordered by your chain.',
        },
        metasMix: {
          title: 'Mix targets',
          description: 'Progress on specific categories made by your group.',
        },
        metasVolumen: {
          title: 'Volume targets',
          description: 'Progress on specific categories made by your group.',
        },
        metasDePerfomance: {
          title: 'Performance targets',
          description: 'Progress on specific categories made by your group.',
        },
        totalVolume: {
          title: 'Total volume',
          description: ' ',
        },
        convenienceVolume: {
          title: 'Convenience volume',
          description: ' ',
        },
        totalGrowthTitle: 'Metas de crescimento total',
        totalGrowthDescription: 'Todos os pedidos feitos pela rede.',
        categoryGrowthTitle: 'Metas de crescimento de categoria',
        categoryGrowthDescription: 'Pedidos em categorias específicas feitos pela sua rede.',
        shareSpaceTitle: 'Share space KPIs',
        shareSpaceDescription: 'Produtos disponibilizados nas prateleiras da loja.',
      },
      currentContractTab: 'Plano atual',
      historyTab: 'Planos anteriores',
    },
  },
};
