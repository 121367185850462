import React from 'react';

import { Heading, Paragraph } from '@hexa-ui/components';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useIntl } from 'react-intl';
import Styled from './WorkspaceCard.style';
import { WorkspaceCardProps } from './WorkspaceCard.types';

export function WorkspaceCard({
  title,
  enTitle,
  trackingId,
  segments,
  isSelected,
  onClick,
  index,
}: Readonly<WorkspaceCardProps>): React.ReactElement {
  const { formatMessage } = useIntl();

  // eslint-disable-next-line max-params
  function formatPlural(value: number, singular: string, plural: string): string {
    return `${value} ${value === 1 ? singular : plural}`;
  }

  const { triggerElementClicked } = useElementClickedEvent();
  const currentPage = useCheckCurrentPage();
  const isMainPage = currentPage === 'MainPage';

  const handleClick = () => {
    onClick?.();

    triggerElementClicked({
      page_label: isMainPage ? 'Main Page' : 'Workspace List',
      page_name: isMainPage ? 'MAIN_PAGE' : 'WORKSPACE_LIST',
      element_location_type: isMainPage ? 'SECTION' : 'PAGE',
      element_location_name: isMainPage ? 'WORKSPACES' : 'WORKSPACE_LIST',
      element_location_label: isMainPage ? 'Workspaces' : 'Workspace List',
      occurence_id: isMainPage ? '2.3' : '2.9',
      is_core_event: false,
      element_label: enTitle,
      element_name: 'WORKSPACE_CARD',
      element_type: 'CARD',
      element_position: index + 1,
      destiny_workspace_label: enTitle,
      destiny_workspace_tracking_id: trackingId,
    });
  };

  return (
    <Styled.CardContainer
      border="medium"
      elevated="minimal"
      isSelected={isSelected}
      onClick={handleClick}
      data-testid="workspace-card"
    >
      <Heading size="H4">{title}</Heading>

      <Paragraph size="small" css={{ color: '$interfaceLabelSecondary' }}>
        {formatPlural(
          segments,
          formatMessage({ id: 'WORKSPACE_CARD.SINGULAR_TEXT' }),
          formatMessage({ id: 'WORKSPACE_CARD.PLURAL_TEXT' })
        )}
      </Paragraph>
    </Styled.CardContainer>
  );
}
