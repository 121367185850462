import { styled } from '@hexa-ui/theme';

export const ScaleParagraph = styled('div', {
    '@media(min-resolution: 1.50dppx)': {
        p: {
            fontSize: '0.75rem',
            lineHeight: '$lineHeights$4'
        },
        variants: {
            size: {
                xsmall: {
                    fontSize: '0.55rem',
                    lineHeight: '$lineHeights$4',
                    p: {
                        fontSize: '0.55rem',
                        lineHeight: '$lineHeights$4'
                    }
                },
                small: {
                    '> p': {
                        fontSize: '0.75rem',
                        lineHeight: '$lineHeights$4'
                    }
                }
            },
        }
    }

})

export const ScaleHeading = styled('div', {
    '@media(min-resolution: 1.50dppx)': {
        h1: {
            fontSize: '1.2rem',
        },
        h2: {
            fontSize: '0.92rem'
        },
        h3: {
            fontSize: '0.82rem'
        },
        h4: {
            fontSize: '0.77rem'
        }

    }
})