import { useApi } from '@api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { useState } from 'react';

import {
  Content,
  ForecastDetailsResponse,
  UseForecastDetailsService,
  UseForecastDetailsServiceProps,
} from './useForecastDetailsServices.types';
import { buildDependencies, buildUrl } from './utils';
import { getDateByPeriod } from './utils/getDateByPeriod/getDateByPeriod';

export const DEFAULT_STATE = {
  content: {
    item: [],
    totalActualForecast: 0,
    totalPercentage: 0,
    totalTargetForecast: 0,
    maximumWeeksQuantityInMonth: 4,
    startDate: '2023-10-01T00:00:00',
  },
  filter: {
    type: [{ type: 'Total' }],
    region: [{ type: 'Nacional' }],
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
  },
};

export const useForecastDetailsService = ({
  setPagination,
  ...params
}: UseForecastDetailsServiceProps): UseForecastDetailsService => {
  const [response, setResponse] = useState<ForecastDetailsResponse | null>(null);
  const { get } = useApi();
  const auth = useAuthenticationService();
  const { user_country: country } = auth.getUserCountryAndLanguage();

  const dependencies = buildDependencies(params);
  const url = buildUrl(params);

  const { isLoading, isError, refetch, isFetching } = useQuery<ForecastDetailsResponse>(
    dependencies,
    () =>
      get({
        url,
        config: {
          headers: {
            country,
            requestTraceId: 'get-order-forecast',
            accountId: params.accountId,
            vendorId: params.vendorId,
            locale: params.locale,
          },
          method: 'GET',
        },
      }).then((response: UseQueryResult<ForecastDetailsResponse>) => {
        if (Number(response.status) === 204) return { ...response.data, isError: false };
        setPagination(response.data.pagination);
        setResponse(response.data);

        return response.data;
      }),
    { refetchOnMount: true }
  );

  const defaultPartialResponse = {
    isLoading: isLoading || isFetching,
    isError,
    refetch,
  };

  const fallbackStartDate = getDateByPeriod(params.period);

  if (!isLoading && response?.content) {
    return {
      ...defaultPartialResponse,
      content: {
        ...response.content,
        startDate: response.content.startDate || fallbackStartDate,
      },
      filter: response.filter,
      pagination: response.pagination,
    };
  } else {
    return {
      ...defaultPartialResponse,
      content: {
        ...DEFAULT_STATE.content,
        startDate: fallbackStartDate,
      } as Content,
      filter: DEFAULT_STATE.filter,
      pagination: DEFAULT_STATE.pagination,
    };
  }
};
