export const ACCESSOR_BDR_ID = 'id';
export const ACCESSOR_BDR_ACCOUNT_ID = 'accountId';
export const ACCESSOR_BDR_NAME = 'name';
export const ACCESSOR_STATUS = 'status';
export const ACCESSOR_VISIT_DATE = 'visitDate';
export const ACCESS_MIN_TIME = 'minTime';
export const ACCESS_MAX_TIME = 'maxTime';

export const STATUS = {
  JUSTIFIED: 'Justified',
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
};
