import { createGenerateClassName, Grid, StylesProvider } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import 'normalize.css';
import React from 'react';
import { IntlProvider } from './i18n';
import Router from './Router';
import theme from './styles/theme';

export function App(): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-beescare-user-mfe',
    seed: 'admin-portal-beescare-user-mfe',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <IntlProvider>
            <Router />
          </IntlProvider>
        </Grid>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
