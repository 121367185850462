import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import AppHeader from 'components/organisms/AppHeader/AppHeader';
import { APPLICATION_TITLE } from 'constants/optimizely';
import { AppHeaderProvider } from 'providers/AppHeaderProvider/AppHeaderProvider';
import { DialogProvider } from 'providers/DialogProvider/DialogProvider';
import { EnvConfig, EnvProvider } from 'providers/EnvProvider';
import { BrowserRouter } from 'react-router-dom';
import ServicesWrapper from './utils/wrappers/ServicesWrapper';
import { AuthProvider } from './contexts/auth/AuthContext';
import GlobalStyles from './GlobalStyles';
import { IntlProvider } from './i18n';
import Router from './routes/Router';
import { RouterServiceProvider } from 'providers/RouterServiceProvider';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-bees-move-mfe',
    seed: 'bees-hub-bees-move-mfe',
  });

  return (
    <EnvProvider env={props}>
      <ServicesWrapper optimizelyKey={props.optimizelyKey} applicationTitle={APPLICATION_TITLE}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <GlobalStyles />
            <AuthProvider>
              <AppHeaderProvider>
                <DialogProvider>
                  <BrowserRouter>
                    <RouterServiceProvider>
                      <AppHeader />
                      <Router />
                    </RouterServiceProvider>
                  </BrowserRouter>
                </DialogProvider>
              </AppHeaderProvider>
            </AuthProvider>
          </IntlProvider>
        </StylesProvider>
      </ServicesWrapper>
    </EnvProvider>
  );
}
