import { generatePath } from 'react-router-dom';

export const SCORE_ROUTES = {
  getScore: '/manager/charts/scoreTeam',
  getReportScore: {
    route: '/report/charts/scoreEmployee/:idEmployee',
    generate(idEmployee) {
      return generatePath(this.route, { idEmployee });
    },
  },
};
