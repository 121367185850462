import { useState } from 'react';

//TODO: Increase functions of this hook
export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return { isOpen, openDialog, closeDialog };
};
