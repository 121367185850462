import MessageMap from "../i18n.d";

const idID: MessageMap = {
  SummaryError: {
    ALL: "Tidak dapat memuat beberapa informasi di halaman ini.",
    GAP: "Tidak dapat memuat informasi gap topline.",
    REWARDS: "Tidak dapat memuat informasi klub B.",
    CREDIT: "Tidak dapat memuat informasi kredit",
    RETRY: "Coba lagi",
    CALL_SUMMARY: "Tidak dapat menyelesaikan panggilan.",
  },
  CopyButton: {
    COPY: "Salin",
    COPIED: "Disalin",
  },
  TopLineGap: {
    TITLE: "Peluang Topline",
    GOAL_TEXT: "Sasaran",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "Tidak dapat memuat informasi gap topline.",
  },
  PocInformation: {
    OWNER_NAME: "Pemilik",
    SHOW_LESS: "Tunjukkan lebih sedikit",
    SHOW_MORE: "Tunjukkan lebih banyak",
    POC_INFORMATION_TITLE: "Informasi POC",
    SALES_STRUCTURE_TITLE: "Struktur penjualan",
    POC_ID: "ID Akun Vendor",
    ERP_ID: "ID ERP",
    TAX_ID: "ID Pajak",
    LIQUOR_LICENSE_NUMBER: "Lisensi minuman keras",
    ACCOUNT_ID: "ID Akun",
    EMAIL: "E-mail",
    FORMATTED_ADDRESS: "Alamat",
    SEGMENT: "Segmen",
    SUB_SEGMENT: "Sub-segmen",
    POTENTIAL: "Potensi",
    DDC: "DDC",
    SALES_REPRESENTATIVE: "Perwakilan Penjual",
    SALES_ORGANIZATION: "Organisasi penjualan",
    SALES_DIRECTION: "Arah penjualan",
    SALES_MANAGEMENT: "Manajemen penjualan",
    SALES_SUPERVISION: "Pengawasan penjualan",
    SALES_ZONE: "Zona penjualan",
    CHANNEL: "Channel",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "poin",
        NOT_ENROLED: "Tidak terdaftar",
        Error: {
          DESCRIPTION: "Tidak dapat memuat informasi klub B.",
          RETRY_TITLE: "coba lagi",
        },
      },
    },
    Credit: {
      TITLE: "Kredit yang tersedia",
      TOTAL_LIMIT: "Batas total",
      CREDIT_USE: "Kredit digunakan",
      CUMULATIVE_DISCOUNT: "Diskon kumulatif",
      EXPIRATION_DATE: "Kedaluwarsa",
      ERROR_MESSAGE: "Tidak dapat memuat informasi kredit.",
      OVERDUE: "Terlambat",
      Payment: {
        INFORMATION: "Informasi pembayaran",
        METHOD: "Metode pembayaran",
        PERIOD: "Periode pembayaran",
      },
      REFERENCE_ID: "ID referensi",
    },
  },
  CallTimerInfo: {
    START_CALL: "Mulai Panggilan",
    ATTEMPTS: "Upaya",
    ONLINE: "Online",
    OFFLINE: "Offline",
    ToastMessage: {
      CTI_CONNECTION_FAILED: "Gagal terhubung dengan CTI.",
    },
  },
  CallSummaryModal: {
    CALL_SUMMARY: "Panggilan Ringkasan",
    CALL_DURATION: "Durasi:",
    NEXT_STEPS: "Langkah selanjutnya",
    NEXT_STEPS_DESCRIPTION: "Kedua opsi akan mendaftarkan tugas ini.",
    CALL_ATTEMPTS: "Upaya:",
    CONTACT_CLASSIFICATION: "Klasifikasi kontak",
    MAKE_CALL_LATER: "Upaya panggilan berikutnya",
    UNABLE_FINISH_CALL: "Tidak dapat menyelesaikan panggilan.",
    REQUIRED_FIELD: "Kolom ini diperlukan.",
    TAKE_BREAK: "Istirahat",
    GO_HOME: "Pulang",
    CALL_NEXT_POC: "Hubungi POC berikutnya",
    COMMENTS: "Komentar",
    COMMENTS_PLACEHOLDER: "Ketik komentar Anda di sini...",
    CONNECTED: "Online",
    DISCONNECTED: "Offline",
    PLACEHOLDER: "Pilih opsi",
    CallClassification: {
      SUCCESS_ON_CONTACT: "Sukses saat kontak",
      NO_ANSWER_DO_NOT_CALL_AGAIN: "Tidak ada jawaban - jangan menelepon lagi",
      NO_ANSWER_TRY_AGAIN: "Tidak ada jawaban - coba lagi",
      RESPONSIBLE_NOT_AVAILABLE: "Yang Bertanggung jawab tidak tersedia",
      INCORRECT_PHONE_NUMBER: "Nomor telepon salah",
      POC_CLOSED_DUPLICATE: "POC tutup atau terduplikat",
      CALL_LATER_TODAY: "Hubungi nanti hari ini",
    },
    CallTypification: {
      "15_MINUTES": "15 menit",
      "30_MINUTES": "30 menit",
      "1_HOUR": "1 jam",
      "1_HOUR_AND_30_MINUTES": "1 jam dan 30 menit",
      "2_HOURS": "2 jam",
      "2_HOUR_AND_30_MINUTES": "2 jam dan 30 menit",
      "3_HOURS": "3 jam",
      "3_HOUR_AND_30_MINUTES": "3 jam dan 30 menit",
      "4_HOURS": "4 jam",
      "4_HOUR_AND_30_MINUTES": "4 jam dan 30 menit",
      "5_HOURS": "5 jam",
      "5_HOUR_AND_30_MINUTES": "5 jam dan 30 menit",
      "6_HOURS": "6 jam",
      "6_HOUR_AND_30_MINUTES": "6 jam dan 30 menit",
      "7_HOURS": "7 jam",
      "7_HOUR_AND_30_MINUTES": "7 jam dan 30 menit",
      "8_HOURS": "8 jam",
    },
  },
};

export default idID;
