import { Button } from '@hexa-ui/components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useBlocker } from 'react-router-dom';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { UploadStatus } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import { CancelModal } from '../Cancel';
import { useUpload } from '../Context';
import { ButtonsContainer, OuterButtonsContainer } from './styles';

export const Buttons: React.FC = () => {
  const { formatMessage } = useIntl();

  const { selected } = useGetUserPreferences();

  const { file, start, loading, analysis, uploaded, progress, send } = useUpload();

  const { fileId } = uploaded;
  const { status, failed, total } = progress;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      !!file.name &&
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes('upload') &&
      !status
    );
  });

  const label = useMemo(() => {
    if (status === UploadStatus.ANALYZED) {
      if (failed === total || analysis.failed) return 'pages.upload.buttons.uploadNewFile';

      return 'pages.upload.buttons.apply';
    }

    if (status === UploadStatus.DONE) return 'pages.upload.buttons.goToCredit';

    if (status === UploadStatus.FAILED) return 'pages.upload.buttons.uploadNewFile';

    return 'pages.upload.buttons.apply';
  }, [status, failed, total, analysis.failed]);

  const { isDisabled, showBack } = useMemo(
    () => ({
      isDisabled: status === UploadStatus.ANALYZING || status === UploadStatus.PROCESSING,
      showBack: status !== UploadStatus.DONE && status !== UploadStatus.FAILED && !analysis.failed,
    }),
    [status, analysis.failed]
  );

  const onBack = () => {
    if (fileId) {
      SegmentService.paymentsButtonClicked(
        'File Uploader',
        'File Uploader Return Button',
        selected.vendorId,
        'Credit Management Files Upload Return',
        'CREDIT_MANAGEMENT_FILE_PAGE'
      );
    }

    send({ type: 'BACK', to: 'files' });
  };

  const onUpload = () => send({ type: 'UPLOAD_FILE' });

  const onConfirm = () => {
    if (status === UploadStatus.DONE) {
      send({ type: 'BACK', to: 'credit' });
      return;
    }

    if (status === UploadStatus.FAILED || (status === UploadStatus.ANALYZED && failed === total)) {
      send({ type: 'RETRY', to: 'upload' });
      return;
    }

    send({ type: 'APPLY_UPDATE' });
  };

  return (
    <OuterButtonsContainer $alignend={!fileId} data-testid="outer-buttons-container">
      {fileId && (
        <Button id="go-to-files-button" size="medium" variant="secondary" onClick={onBack}>
          {formatMessage({ id: 'pages.upload.buttons.goToFiles' })}
        </Button>
      )}

      <ButtonsContainer data-testid="buttons-container">
        {showBack &&
          ((!status && file.name) || blocker.state === 'blocked' ? (
            <CancelModal blocker={blocker} />
          ) : (
            <Button
              id="back-button"
              size="medium"
              variant="secondary"
              disabled={isDisabled}
              onClick={onBack}
            >
              {formatMessage({ id: 'pages.upload.buttons.back' })}
            </Button>
          ))}

        {fileId ? (
          <Button
            id="apply-button"
            size="medium"
            variant="primary"
            isLoading={loading.processing}
            disabled={isDisabled && !analysis.failed}
            onClick={onConfirm}
          >
            {formatMessage({ id: label })}
          </Button>
        ) : (
          <Button
            id="upload-button"
            size="medium"
            variant="primary"
            isLoading={loading.upload}
            disabled={!loading.upload && start.upload}
            onClick={onUpload}
          >
            {formatMessage({ id: 'pages.upload.buttons.upload' })}
          </Button>
        )}
      </ButtonsContainer>
    </OuterButtonsContainer>
  );
};
