export enum UserType {
  MANAGER = 'Manager',
  GOLD_PARTNER = 'Gold Partner',
  SILVER_PARTNER = 'Silver Partner',
  GLOBAL_PARTNER = 'Global Partner',
  SALES_MANAGER = 'Sales Manager',
  UNAUTHORIZED = '',
  LOADING = 'Loading',
}

export const CODE_200 = 200;
export const CODE_204 = 204;
export const CODE_202 = 202;
export const SUCCESS_CODE = [CODE_200, CODE_202, CODE_204];

export const MFE_NAME = '/reports';

export const FEATURE_TOGGLE_V2_PROJECT_NAME = 'bees-hub-reports-mfe';

export const GOLD_PARTNER_SCOPE = 'TIER.t1csvexport.read';

export const MANAGER_1P_SCOPE = 'PartnerDashboard.InsightsManager1P.Read';
export const MANAGER_3PPRO_SCOPE = 'PartnerDashboard.InsightsManager3PPRO.Read';
export const MANAGER_DTAAS_SCOPE = 'PartnerDashboard.InsightsManagerDTAAS.Read';

export const MANAGER_1P_GROUP = 'PartnerDashboard.1P viewer';
export const MANAGER_3PPRO_GROUP = 'PartnerDashboard.3P pro viewer';
export const MANAGER_DTAAS_GROUP = 'PartnerDashboard.DTaaS viewer';
export const MANAGER_BEES_GROUP = 'PartnerDashboard.BEES viewer';

export const MANAGER_SCOPES = [MANAGER_1P_SCOPE, MANAGER_3PPRO_SCOPE, MANAGER_DTAAS_SCOPE];
export const MANAGER_GROUPS = [
  MANAGER_1P_GROUP,
  MANAGER_3PPRO_GROUP,
  MANAGER_DTAAS_GROUP,
  MANAGER_BEES_GROUP,
];

export const PARTNER_SCOPE = 'PartnerDashboard.DB.Read';

export const GLOBAL_PARTNER_SCOPES = [
  PARTNER_SCOPE,
  'PartnerDashboard.InsightsGlobalManagerViewer.Read',
];

export const EU_CLUSTER_COUNTRIES = ['PH', 'ID', 'CH', 'BE', 'IC', 'ES', 'DE', 'GB', 'NL'];
export const GLOBAL_OVERVIEW = 'GLOBAL_OVERVIEW';

export const SALES_MANAGER_SCOPE = 'PartnerDashboard.SalesManagerViewer.Read';

export const ANALYTICS_ADMIN_GROUP = ['PartnerDashboard.Workspace admin'];

export const MAIN_PAGE_REPORTS_SCOPE = ['PartnerDashboard'];
