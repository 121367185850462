export interface FilterInteractionPayload {
  analytics_component_id: string;
  analytics_component_name: string;
  filter_selected: string;
}

export interface FilterInteractionEventReturn {
  triggerFilterInteraction: (payload: FilterInteractionPayload) => void;
  triggerDataLoadedRendering: (params: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => void;
  triggerReportRenderedProcess: (params: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => void;
  triggerRenderingStartedRenderingProcess: (arams: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => void;
}

export enum FilterSelected {
  '30_DAYS' = '30_DAYS',
  '90_DAYS' = '90_DAYS',
  DC = 'DC',
  MOM = 'MOM',
  MTD = 'MTD',
  PER_ORDER = 'PER_ORDER',
  PER_POC = 'PER_POC',
  SKU = 'SKU',
  STATE = 'STATE',
}
