import { RowSelectionInstance } from '@tanstack/react-table';
import { OnSelectedFn, UseSelectableProps } from '../interfaces';

interface GetModeProps {
  unique: boolean;
  checked: boolean;
}

export const useSelectable = <T>({ selectable }: UseSelectableProps) => {
  if (!selectable.has) return { selection: {} };

  const onClear = (callback: RowSelectionInstance<T>['toggleAllRowsSelected']) => {
    return () => {
      callback(false);

      selectable.onSelected();
      selectable.onClear(selectable.state);
    };
  };

  const getMode = ({ unique, checked }: GetModeProps): 'single' | 'multiple' | undefined => {
    const mode = selectable.mode;

    if (!unique) return checked ? 'multiple' : undefined;

    if (checked) return mode === 'multiple' ? 'multiple' : 'single';
    else return mode === 'multiple' ? 'multiple' : undefined;
  };

  const onSelected = ({ value, data }: OnSelectedFn) => {
    const mode = getMode({ unique: 'toggleSelected' in data, checked: value });

    if ('toggleSelected' in data) {
      data.toggleSelected(value);
      selectable.onSelected({ mode, checked: value, rows: [data.original] });

      return;
    }

    data.toggleAllRowsSelected(value);
    selectable.onSelected({
      mode,
      checked: value,
      rows: value ? data.getRowModel().rows.map((row) => row.original) : [],
    });
  };

  return { selection: { onSelected, onClear } };
};
