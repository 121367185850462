import React from 'react';
import { StatusLabelProps } from '../../../utils/StatusLabelProps';
import { Label } from './styles';

interface IStatusLabel {
  status: string;
}

export const StatusLabel = (props: IStatusLabel): JSX.Element => {
  const { status } = props;
  const labelText = StatusLabelProps(status).translation;

  return <Label label={labelText} $status={status} data-cy="status-label" />;
};
