import ErrorPage from 'Components/ErrorPage/ErrorPage';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import { ITrackingContext, TrackingContext } from 'Context/TrackingContext';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { useReports } from 'Hooks/useReports/useReports';
import { useScreenSize } from 'Hooks/useScreenSize/useScreenSize';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { ContentReportView } from './ContentReportView/ContentReportView';
import { EmbedContent } from './EmbedContent/EmbedContent';
import { Styled } from './EmbedReportsPage.style';
import { HeaderEmbed } from './HeaderEmbed/HeaderEmbed';

export function EmbedReportsPage(): React.ReactElement {
  const { locale: SELECTED_LANGUAGE } = useIntl();

  const {
    themeSlug,
    reportSlug,
    reportsByTheme,
    sortedMenuItems,
    themeSelected,
    reportBySlug,
    workspaceSelected,
    hasReportPermission,
    hasError,
  } = useReports();

  const [isMobile] = useScreenSize(['mobile']);

  const trackingContextValue: ITrackingContext | null = useMemo(() => {
    if (!!themeSelected && !!reportBySlug) {
      const subType = reportBySlug.trackingId;

      return {
        themeName: themeSelected.title['en-US'],
        themeTrackingId: themeSelected.trackingId,
        reportName: reportBySlug.title['en-US'],
        reportTrackingId: reportBySlug.trackingId,
        reportType: themeSelected.trackingId,
        reportSubType: subType,
        tabName: reportBySlug.trackingId,
        vendorId: workspaceSelected.vendorId || null,
        vendorName: workspaceSelected.vendorName || null,
        country: workspaceSelected.country || null,
        businessModel: workspaceSelected.businessModel || null,
      };
    }
    return null;
  }, [themeSelected, reportBySlug, workspaceSelected]);

  const { workspaceData: selectecWorkspaceWithPermission } = useWorkspaceContext();

  if (reportSlug === undefined && reportsByTheme) {
    return <Redirect to={`/reports/embed/${themeSlug}/${reportsByTheme[0].slug}`} />;
  }

  if (themeSelected === undefined || reportSlug === undefined || reportBySlug === undefined) {
    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <Loading type="container" />
      </PageContainer>
    );
  }

  if (hasError || !hasReportPermission) {
    return <ErrorPage />;
  }

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <PageContainer maxWidth="xl" cssContainer={{ paddingBottom: '0px' }}>
        <Sidebar reportsMenuItems={sortedMenuItems} />
        <Styled.Container>
          <HeaderContainer
            showBreadcrumb={!isMobile}
            pageTitle={themeSelected?.title[SELECTED_LANGUAGE]}
          />

          <Styled.Wrapper>
            <HeaderEmbed
              lang={SELECTED_LANGUAGE}
              reportSlug={reportSlug}
              themeSelected={themeSelected}
            />

            {reportBySlug.csvDownload?.csvExportCode && workspaceSelected.vendorId && (
              <ContentReportView csvDownload={[reportBySlug.csvDownload]} />
            )}

            {workspaceSelected.workspaceId && workspaceSelected.segmentId && reportBySlug && (
              <EmbedContent
                workspaceId={workspaceSelected.workspaceId}
                permissionId={workspaceSelected.segmentId}
                reportId={reportBySlug.id}
                country={selectecWorkspaceWithPermission?.segment?.country ?? ''}
                vendorId={workspaceSelected.vendorId}
                sortedMenuItems={sortedMenuItems}
              />
            )}
          </Styled.Wrapper>
        </Styled.Container>
      </PageContainer>
    </TrackingContext.Provider>
  );
}
