import { formatBaseURL } from 'utils/formatBaseURL/formatBaseURL';
import { permissionScopes } from './PermissionScopes.constant';
import { ROUTES } from './Routes.constants';

export const permissionRoutes: Record<string, string[]> = {
  [ROUTES.SELLER_SETTINGS]: [permissionScopes.FEATURE_ACTIVATION_WRITE],
  [formatBaseURL('DDCs')]: [permissionScopes.DDC_LIST],
  [ROUTES.DDCS_EDIT]: [
    permissionScopes.DDC_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_CONSUME_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_WRITE,
    permissionScopes.CLICK_AND_COLLECT_CONSUME_WRITE,
  ],
  [ROUTES.DDCS_EDIT_CUSTOM_SETTINGS]: [
    permissionScopes.DDC_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_CONSUME_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_WRITE,
    permissionScopes.CLICK_AND_COLLECT_CONSUME_WRITE,
  ],
  [formatBaseURL('DDCs/Holiday')]: [permissionScopes.DDC_HOLIDAY_READ],
  [formatBaseURL('DDCs/DDCsRules')]: [permissionScopes.DDC_RULE_READ],
  [formatBaseURL('DeliveryWindow')]: [permissionScopes.CLIENT_LIST],
  [formatBaseURL('DeliveryWindow/ClientEdit')]: [
    permissionScopes.CLIENT_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_READ,
    permissionScopes.CLICK_AND_COLLECT_PARAMETERS_WRITE,
  ],
  [ROUTES.AUTOBLOCK_CREATE]: [permissionScopes.DDC_AUTOBLOCK_WRITE],
  [ROUTES.AUTOBLOCK_EDIT]: [permissionScopes.DDC_AUTOBLOCK_WRITE],
  [ROUTES.AUTOBLOCK_LIST]: [permissionScopes.DDC_AUTOBLOCK_READ],
  [ROUTES.AUTOBLOCK_PROGRESS]: [permissionScopes.DDC_AUTOBLOCK_READ],
  [formatBaseURL('DeliveryWindow/ClientRules')]: [permissionScopes.CLIENT_RULE_READ],
  [formatBaseURL('ImportsAndExports')]: [permissionScopes.IMPORTS_EXPORTS],
  [formatBaseURL('ImportsAndExports/UploadClientsBase')]: [
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ,
  ],
  [formatBaseURL('ImportsAndExports/DownloadClientsBase')]: [
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ,
  ],
  [formatBaseURL('ImportsAndExports/UploadRulesBase')]: [
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ,
  ],
  [formatBaseURL('ImportsAndExports/UploadRulesBase/UploadFile')]: [
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_DATE,
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_FLEXIBLE,
  ],
  [formatBaseURL('ImportsAndExports/DownloadRulesBase')]: [
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ,
  ],
};
