import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { DateFormats } from '@/components/Overview/enums/visits';
import { Locales } from '@/enums/locales';
import { formatDate } from '@/utils/dateFormatter/dateFormatter';

import { ACCESSOR_BDR_ID, ACCESSOR_DATE } from '../consts/BDRsWithoutVisits';
import { useModalBDRsWithoutVisitsProps } from '../types/ModalBDRsWithoutVisitsTypes';

export const useModalBDRsWithoutVisits = ({
  bdrsWithoutVisits,
}: useModalBDRsWithoutVisitsProps) => {
  const { formatMessage } = useIntl();
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2() as {
    preferredLanguage: Locales;
    defaultLanguage: Locales;
  };
  const locale = preferredLanguage || defaultLanguage;

  const formatBdrs = useCallback((bdrsList) => {
    return bdrsList.map((bdr) => ({
      [ACCESSOR_BDR_ID]: bdr.id,
      [ACCESSOR_DATE]: bdr.dateWithoutVisit,
    }));
  }, []);

  const defaultBDRs = formatBdrs(bdrsWithoutVisits);
  const [BDRs, setBDRs] = useState(defaultBDRs);
  const [totalBDRsWithoutVisits, setTotalBDRsWithoutVisits] = useState(
    bdrsWithoutVisits.length.toString(),
  );

  const visitDate = useMemo(
    () => formatDate(new Date(), preferredLanguage, DateFormats.MEDIUM),
    [preferredLanguage],
  );

  const onSearch = useCallback(
    (event) => {
      const searchValue = event.currentTarget.value;
      const filteredBDRs = bdrsWithoutVisits.filter((bdr) => bdr.id.includes(searchValue));

      setBDRs(formatBdrs(filteredBDRs));
      setTotalBDRsWithoutVisits(filteredBDRs.length.toString());
    },
    [bdrsWithoutVisits, formatBdrs],
  );

  const onClear = useCallback(() => {
    setBDRs(defaultBDRs);
    setTotalBDRsWithoutVisits(bdrsWithoutVisits.length.toString());
  }, [bdrsWithoutVisits.length, defaultBDRs]);

  const getFormattedDate = (date) => formatDate(new Date(date), locale, DateFormats.MEDIUM);

  return {
    BDRs,
    formatMessage,
    totalBDRsWithoutVisits,
    visitDate,
    onSearch,
    onClear,
    getFormattedDate,
  };
};
