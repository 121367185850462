import { useIntl } from 'react-intl';

import { Heading, Paragraph } from '@hexa-ui/components';

import { OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS } from '../../consts/OverviewConsts';
import { TotalVisitsCardProps } from '../../types/OverviewTypes';
import * as Styled from './TotalVisitsCard.styles';

export const TotalVisitsCard = ({ totalVisits }: TotalVisitsCardProps) => {
  const { formatMessage } = useIntl();

  return (
    <Styled.WrapperTotalVisitsCard elevated="minimal" border="medium">
      <Heading data-testid={OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS.TOTAL_VISITS_TITLE} size="H5">
        {formatMessage({ id: 'Components.Overview.Title.TOTAL_OF_VISITS' })}
      </Heading>
      <Styled.WrapperTotalCount>
        <Paragraph
          data-testid={OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS.TOTAL_VISITS_COUNT_LABEL}
          colortype="secondary">
          {formatMessage({
            id: 'Components.Overview.Label.TOTAL_OF_VISITS_COUNT',
          })}
        </Paragraph>
        <Heading
          data-testid={OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS.TOTAL_VISITS_COUNT_VALUE}
          size="H2">
          {totalVisits}
        </Heading>
      </Styled.WrapperTotalCount>
    </Styled.WrapperTotalVisitsCard>
  );
};
