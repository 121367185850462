import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';

interface LinkOutProps {
  button_label: string;
  button_name: string;
  country: string;
  screen_name: string;
  vendor_id: string;
  vendor_name: string;
  business_model: string;
}

interface UseAppEventsReturn {
  triggerPartnerInsightsAppLoaded: () => void;
  triggerMenuInteraction: (menuCategory: string) => void;
  triggerSearchInteraction: (searchText: string, resultsCount: number) => void;
  triggerLinkOutButtonClicked: (props: LinkOutProps) => void;
}

export default function useAppEvents(): UseAppEventsReturn {
  const { partnerInsightsAppLoaded, menuInteraction, searchInteraction, linkOutOptionClicked } =
    useSegment();
  const {
    screen_name,
    business_model,
    country,
    user_type,
    vendor_id: vendorId,
    vendor_name: vendorName,
    tab_name,
  } = useSegmentCommonProps();

  const triggerPartnerInsightsAppLoaded = () => {
    partnerInsightsAppLoaded({
      business_model,
      country,
      screen_name,
      user_type,
      vendor_id: vendorId,
      vendor_name: vendorName,
    });
  };

  const triggerMenuInteraction = (menuCategory: string) => {
    menuInteraction({
      business_model,
      country,
      screen_name,
      user_type,
      vendor_id: vendorId,
      vendor_name: vendorName,
      menu_category: menuCategory,
      menu_subcategory: null,
    });
  };

  const triggerSearchInteraction = (searchText: string, resultsCount: number) => {
    searchInteraction({
      business_model,
      country,
      screen_name,
      user_type,
      tab_name,
      results_count: resultsCount,
      search_text: searchText,
      vendor_id: vendorId,
      vendor_name: vendorName,
    });
  };

  const triggerLinkOutButtonClicked = (props: LinkOutProps) => {
    const {
      button_label,
      button_name,
      country,
      screen_name,
      vendor_id,
      vendor_name,
      business_model,
    } = props;
    linkOutOptionClicked({
      business_model,
      country,
      screen_name,
      user_type,
      button_label,
      button_name,
      vendor_id,
      vendor_name,
    });
  };

  return {
    triggerPartnerInsightsAppLoaded,
    triggerMenuInteraction,
    triggerSearchInteraction,
    triggerLinkOutButtonClicked,
  };
}
