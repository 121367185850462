import MessageMap from '../../i18n';

const productType: MessageMap = {
  PRODUCT_TYPE_LIST: {
    BEER: 'Beer',
    WATER: 'Water',
    NON_ALCOHOLIC: 'Non-Alcoholic',
    NON_ALCOHOLIC_BEER: 'Non-Alcoholic Beer',
    BEATS_AND_ICE: 'Beats and Ice',
    FOOD: 'Food',
    OTHERS: 'Others',
  },
};

export default productType;
