import React from 'react';
import ReactDOM from 'react-dom';

import { useLogService as logService } from 'admin-portal-shared-services';
import singleSpaReact from 'single-spa-react';

import App from './app/App';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    logService().error(error);

    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
