import MessageMap from '../../i18n';

const CouponCampaigns: MessageMap = {
  TITLE: 'Coupon campaigns',
  CREATE_COUPON_CAMPAIGN_BUTTON: 'Create coupon campaign',
  SEARCH_PLACEHOLDER: 'Search by campaign name',
  NO_DATA: {
    TITLE: 'You have no coupon campaigns',
    DESCRIPTION: 'Add a new one by clicking on "Create coupon campaign”',
  },
  FILTERS: {
    STATUS: 'Campaign status',
    DATES: {
      TITLE: 'Campaign dates',
      START: 'Start date',
      END: 'End date',
    },
    INVALID_DATE: 'Choose a date after {startDate}.',
  },
  TABLE: {
    CAMPAIGN_NAME: 'Campaign name',
    STATUS: 'Status',
    ACTIVE_PROMOTIONS: 'Active promotions',
    ACTIVE_POCS: 'Participating POCs',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    ACTIONS: 'Actions',
    TOOLTIPS: {
      ELIGIBLE_POCS: 'Eligible POCs that joined the coupon campaign.',
      MANAGE_ELIGIBLE_POCS: 'Manage eligible POCs',
      EDIT: 'Edit',
    },
    STATUSES: {
      ACTIVE: 'Active',
      SCHEDULED: 'Scheduled',
      FINISHED: 'Finished',
    },
    DROPDOWN: {
      POC_MANAGEMENT: 'POC Management',
      EDIT_CAMPAIGN: 'Edit Campaign',
      PROMOTIONS: 'Promotions',
    },
  },

  CREATE: {
    TITLE: 'Create coupon campaign',
    DOWNLOAD_TEMPLATE: 'Download template',
    PREVIEW: 'Preview',
    BUTTON: 'Create campaign',
    SUCCESS_MESSAGE: '{campaignName} campaign successfully created.',
    UPDATED_MESSAGE: '{campaignName} campaign successfully updated.',
    STEPS: {
      CAMPAIGN: {
        TITLE: 'Campaign',
        DESCRIPTION: 'Define campaign details.',
      },
      PROMOTIONS: {
        TITLE: 'Promotions',
        DESCRIPTION: 'Define coupon settings.',
      },
      LEGAL_INFO: {
        TITLE: 'Legal information',
        DESCRIPTION: 'Provide Terms & Policy info.',
      },
      URL_AND_ADS: {
        TITLE: 'URL and ads',
        DESCRIPTION: 'Define domain and ad settings.',
      },
      SUMMARY: {
        TITLE: 'Summary',
        DESCRIPTION: 'Review and publish.',
      },
    },
    DISCARD_MODAL: {
      TITLE: 'Discard campaign?',
      DESCRIPTION: 'This campaign will be discarded permanently.',
      DISCARD: 'Discard',
    },
    CUSTOMER_SETTINGS: {
      TITLE: 'Customer settings',
      NAME: {
        LABEL: 'Campaign name',
        HINT: 'This name is also shown to the consumer.',
      },
      IMAGE: {
        LABEL: 'Campaign image',
        PLACEHOLDER:
          'File format: JPG or PNG. Resolution: 1920x1080. Size: Up to 2MB.',
        BUTTON: 'Select file',
        TYPE_ERROR: 'File type not supported.',
        INVALID_ERROR: 'Upload failed.',
      },
      DOWNLOAD_TEMPLATE: 'Download template',
      CAMPAIGN_PREVIEW: {
        TITLE: 'Campaign card preview',
        NAME: 'Campaign name',
        PROMOTIONS: 'Promotions',
        PROMO: 'promotions',
        POINTS: 'BEES points',
      },
      ALT_TEXT: {LABEL: 'Image ALT text'},
      DESCRIPTION: {
        LABEL: 'Campaign description',
      },
    },
    CONSUMER_SETTINGS: {
      TITLE: 'Consumer settings',
      AGE_GATE: {
        TITLE: 'Age gate',
        CHECKBOX: 'Show age gate',
        TYPE: {
          TITLE: 'Type',
          FIRST: 'Yes or No',
          SECOND: 'MM/DD/YYYY',
        },
        MOBILE_BG: {
          TITLE: 'Mobile background',
          DESCRIPTION:
            'File format: JPG or PNG. Resolution: 1180x1920. Size: Up to 2MB.',
        },
        DESKTOP_BG: {
          TITLE: 'Desktop background',
          DESCRIPTION:
            'File format: JPG or PNG. Resolution: 1920x930. Size: Up to 2MB.',
        },
      },
      HEADER: {
        TITLE: 'Header',
        LOGO: {
          TITLE: 'Logo',
          DESCRIPTION: 'File format: JPG or PNG. Size: Up to 1MB.',
        },
        COLOR: {
          TITLE: 'Header color',
          HINT: 'Example: #ED1941',
        },
      },
      FOOTER: {
        TITLE: 'Footer',
        CONTACT: {
          TYPE: 'Contact type {index}',
          CONTACT: 'Contact {index}',
        },
        TYPES: {
          EMAIL: 'Email',
          PHONE: 'Phone',
          WEBSITE: 'Website',
          WHATSAPP: 'WhatsApp',
        },
        ADD: 'Add contact',
      },
      REGISTER_FORM: {
        TITLE: 'Register form',
        BANNER: {
          TITLE: 'Registration banner',
          DESCRIPTION:
            'File format: JPG or PNG. Resolution: 1920x1280. Size: Up to 2MB.',
        },
        UNIQUE_ID: 'Show unique government identifier input field.',
      },
      MAP: {
        TITLE: 'Map',
        PIN_TYPE: {
          TITLE: 'POC pin type',
          FIRST: 'Default',
          SECOND: 'Custom (upload icon)',
        },
        CUSTOM: {
          TITLE: 'Custom pin',
          DESCRIPTION: 'File format: JPG or PNG. Size: Up to 1MB.',
        },
      },
      BUTTONS: {
        TITLE: 'Button and text color',
        FIRST: {
          TITLE: 'Buttons and age gate text color',
          HINT: 'Example: #ED1941',
        },
        SECOND: {
          TITLE: 'Buttons and links color',
          HINT: 'Example: #ED1941',
        },
        BUTTON: 'Yes',
      },
      VALIDATIONS: {
        UPLOAD_FAILED: 'Upload failed.',
        WRONG_FORMAT: 'Wrong format.',
        INVALID_EMAIL: 'Enter a valid email. Example: name@domain.com.',
        INVALID_PHONE: 'Enter a valid phone number.',
        INVALID_WHATSAPP: 'Enter a valid WhatsApp number.',
        INVALID_URL: 'Enter a valid URL.',
        INVALID_COLOR: 'Enter a valid color.',
        MAX_SIZE: 'The file exceeded {size}.',
      },
    },
    PLATFORM_SETTINGS: {
      TITLE: 'Platform settings',
      CAMPAIGN_HOST: 'Campaign host',
      BEES_COUPONS: 'BEES Digital Coupons',
      THIRD_PARTY_PLATFORM: 'Third party platform',
      CAMPAIGN_PERIOD: 'Campaign period',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      CAMPAIGN_TAG: 'Campaign tag',
      INITIATIVE: 'Initiative',
      YEAR: 'Year',
      LAUNCH: 'Launch',
      COUNTRY: 'Country',
      UNIQUE_NAME: 'Unique name',
      B2O_TAG: 'Global B2O tag',
      DOLLAR_QUOTE: 'Dollar quote',
      SEND_SMS: 'Send coupon code via SMS to the consumer.',
      INVALID_DATE: 'The end date cannot be before the start date.',
      INVALID_DOLLAR_QUOTE: 'Value must be greater than or equal to 0.01.',
      COUPON_REDEMPTION_LIMIT: 'Coupon redemption limit',
      COUPON_REDEMPTION_FREQUENCY_MINUTES: 'every {time} minutes',
      COUPON_REDEMPTION_FREQUENCY_HOUR: 'every {time} hour',
      DAILY_REDEMPTION_LIMIT: 'Daily redemption limit per consumer',
      LIMIT: 'Redemption limit (by period)',
      PERIOD: 'Period',
      PERIODS: {
        FIVE_MIN: 'Every 5 minutes',
        FIFTEEN_MIN: 'Every 15 minutes',
        THIRTY_MIN: 'Every 30 minutes',
        ONE_HOUR: 'Every 1 hour',
      },
      CUSTOMER_LIMITS: 'Customer limits',
      TOOLTIP_INFO:
        'Set a daily redemption limit per consumer to prevent coupon fraud and improve campaign results.',
    },
    LEGAL_INFO: {
      TITLE: 'Legal information',
      CUSTOMER_SECTION: {
        TITLE: 'Customer',
        DOCUMENT: {
          FILE: 'Document {index} file',
          FILE_PLACEHOLDER: 'Format: PDF. Size: Up to 2MB.',
          TITLE: 'Document {index} title',
          TITLE_HINT: 'Example: Terms and conditions',
          ADD: 'Add document',
          LEGAL_TEXT: 'Legal Info',
        },
      },
      CONSUMER_SECTION: {
        TITLE: 'Consumer',
        TERMS_OF_USE: 'Terms of use (URL)',
        PRIVACY_POLICY: 'Privacy policy (URL)',
        CAMPAIGN_RULES: 'Campaign rules',
        CAMPAIGN_RULES_PLACEHOLDER: 'Format: PDF. Size: Up to 2MB.',
      },
    },
    PROMOTIONS: {
      ALERT:
        "Once the campaign is created, promotions can't be deleted, just disabled. Adding new promos is still possible. At least 1 promotion per campaign needs to be enabled.",

      PROMOTION: 'Promotion',
      NAME: {
        LABEL: 'Promotion name',
        HINT: 'Example: 10% off in Stella Artois long neck. (Max 60 characters)',
      },
      CARD: {
        PREVIEW: 'Promotion card preview',
        POINTS: 'BEES Points',
        TYPE: 'Coupon type',
      },
      IMAGE: {
        NAME: 'Informative image',
        SELECT: 'Select file',
        FORMAT:
          'File format: JPG or PNG. Resolution: 1920x721. Size: Up to 1MB.',
        ALT_TEXT: 'Promotion image ALT text',
      },
      TOGGLE: {
        POC_BASE: 'Use the POC base from the campaign level',
      },
      STATUS: {
        IN_PROGRESS: 'In progress',
        PROMOTION_EXPIRED: 'Promotion Expired',
        DISABLED: 'Disabled',
      },
      DELETE: {
        TITLE: 'Delete Promotion {index}?',
        DESCRIPTION: 'This promotion will be deleted permanently.',
        CONFIRM: 'Delete',
      },
      DOWNLOAD_TEMPLATE: 'Download template',
      ADD_BUTTON: 'Add promotion',
      PROMOTION_GOAL: {
        TITLE: 'Promotion goal',
        INTRODUCE: {
          TITLE: 'Introduce',
          DESCRIPTION: 'Increase brand coverage.',
        },
        SUSTAIN: {
          TITLE: 'Sustain',
          DESCRIPTION: 'Increase purchase frequency.',
        },
        GENERATE: {
          TITLE: 'Generate demand',
          DESCRIPTION: 'Increase amount per purchase.',
        },
      },
      PRODUCT_SETTINGS: {
        TITLE: 'Product settings',
        PRODUCT_TYPE: {
          TITLE: 'Product type',
          HINT: 'Example: Beer.',
          ADD_MANUALLY_BUTTON: 'Add product manually',
        },
        PRODUCT_BRAND: {
          TITLE: 'Product brand',
          HINT: 'Example: Stella Artois.',
          ADD_MANUALLY_BUTTON: 'Add brand manually',
        },
        PRODUCT_NAME: {
          TITLE: 'Product name',
          HINT: 'Example: Stella Artois long neck.',
        },
        TOTAL_UNITS: {
          TITLE: 'Total units',
          HINT: 'Units in a single coupon.',
        },
        PRICE_PER_UNIT: {
          TITLE: 'Price per unit',
          HINT: 'Individual value.',
        },
        VOLUME_PER_UNIT: {
          TITLE: 'Volume per unit',
          HINT: 'In milliliter (ml).',
        },
      },
      COUPON_SETTINGS: {
        TITLE: 'Coupon settings',
        COUPON_TYPE: 'Coupon type',
        DISCOUNT: 'Discount',
        DISCOUNT_TITLE: 'Discount (%)',
        FREE_GOOD: 'Free good',
        FREE_GOOD_TITLE: 'Free good (un.)',
        COUPON_AMOUNTS: {
          TITLE: 'Coupon amounts',
          TOTAL_GEN: 'Max. total generation',
          CONSUMER_GEN: 'Max. consumer generation',
          CONSUMER_ACTIVE_COUPONS: 'Max. consumer active coupons',
          COUPON_AMOUNT_ALERT:
            'The amount of coupon generation should be greater than the daily redemption limit.',
        },
        COUPON_VALUATION: {
          TITLE: 'Coupon valuation',
          POINTS_PER_COUPON: 'BEES points per coupon',
          COUPON_PRICE: 'Coupon price',
          SUCCESS_MESSAGE: {
            TITLE: 'Success message',
            HINT: 'Example: Congrats! You have just earned a coupon for a free drink. Redeem your benefit at the nearest store.',
          },
        },
      },
      DATES: {
        TITLE: 'Dates',
        DESCRIPTION:
          "The dates must be within the campaign's start and end date period.",
        ALERT:
          'Exceeding the limit suspends the redemption for 30min. 3 suspensions in 24h block the POC in all campaigns for suspicious activity. You can unblock them in Eligible POCs.',
        COUPON_GENERATION: {
          TITLE: 'Coupon generation',
          START_DATE: 'Start date',
          END_DATE: 'End date',
          EXP_TIME: 'Expiration time',
          HOURS: 'hours',
          EXP_TIME_ALERT:
            'Coupons might expire in case the expiration time defined is too short.',
        },
        COUPON_REDEMPTION: {
          TITLE: 'Coupon redemption',
          START_DATE: 'Start date',
          END_DATE: 'End date',
        },
      },
      DIALOG_CONTENT:
        "Notice that if this promotion get disabled you won't be able to eneble or see it on this page anymore. If you need to enable it in the future please open a ticket to our tech team. ",
    },
    DOMAIN_AND_URL: {
      TITLE: 'Domain and URL',
      DOMAIN_TYPE: {
        TITLE: 'Domain type',
        BEESDC: 'BEES Digital Coupons',
        CUSTOM: 'Custom',
      },
      DEFAULT_DOMAIN: {
        TITLE: 'Default domain',
        HINT_BEESDC: 'The Digital Coupons domain will be used.',
        HINT_CUSTOM: 'Example: www.brandname.com/',
      },
      PATH_NAME: {
        TITLE: 'Path name',
        HINT: 'Use only lowercase letters, numbers, and hyphens.',
        ERROR: 'This campaign URL already exists. Please choose another one.',
      },
      GTM_CONTAINER_ID: 'GTM Container ID',
      CUSTOM_URL_COOKIES: 'Custom URL cookies consent',
      GOOGLE_ANALYTICS_UA: 'Google Analytics 360 UA number',
      GOOGLE_ANALYTICS_VIEW_ID: 'Google Analytics 360 property view ID',
    },
    DTC: {
      TITLE: 'DTC banner',
      LINK: {
        LABEL: 'DTC redirect link',
      },
      IMAGE: {
        LABEL: 'DTC banner',
        PLACEHOLDER:
          'File format: JPG or PNG. Resolution: 1366x513. Size: Up to 2MB.',
        BUTTON: 'Select files',
      },
    },
    MEDIA_CAMPAIGNS: {
      TITLE: 'Media Campaigns',
      MEDIA_ACCOUNT: {
        LABEL: 'Media account ID list',
        CHANNEL: 'Channel {index}',
        ID: 'ID {index}',
        ADD: 'Add channel',
        TYPES: {
          DEFAULT: 'Select',
          ADSMOVIL: 'Adsmovil',
          EMAIL_MARKETING: 'Email marketing',
          FACEBOOK: 'Facebook',
          GOOGLE_ADS: 'Google Ads',
          PUSH_NOTIFICATION: 'Push notification',
          SMS: 'SMS',
          TWITTER: 'Twitter',
          VERIZON: 'Verizon',
          YOUTUBE: 'YouTube',
        },
      },
      SOCIAL_MEDIA: {
        LABEL: 'Social media redirect link',
        SERVICE: 'Service {index}',
        LINK: 'Redirect link {index}',
        ADD: 'Add service',
        TYPES: {
          DEFAULT: 'Select',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          TIKTOK: 'TikTok',
          TWITTER: 'Twitter',
          YOUTUBE: 'YouTube',
        },
      },
    },
    SUMMARY: {
      UP_TO: 'Up to {points} points',
      CAMPAIGN: 'Campaign',
      CONSUMER: {
        UNIQUE_ID: 'Unique ID',
        CONTACT_TYPE: 'Contact type',
      },
      PLATFORM: {
        SEND_SMS: 'Send coupon code via SMS',
        START_DATE: 'Campaign start date',
        END_DATE: 'Campaign end date',
      },
      PROMOTIONS: {
        INDEX: 'Promotion {index}',
        POINTS: '{points} points',
        GOAL: 'Goal',
        COUPON_GENERATION_PERIOD: 'Coupon generation period',
        COUPON_REDEMPTION_PERIOD: 'Coupon redemption period',
      },
      URL_AND_MEDIA: {
        TITLE: 'URL & media',
        DOMAIN: 'Domain',
      },
      DTC: {
        BANNER: 'DTC banner',
      },
    },
  },

  SWITCH_COUNTRY: {
    TITLE: 'Switch Country',
    COUNTRIES: {
      AR: 'Argentina',
      BR: 'Brazil',
      CO: 'Colombia',
      DO: 'Dominican Republic',
      EC: 'Ecuador',
      MX: 'Mexico',
      PA: 'Panama',
      PE: 'Peru',
      PY: 'Paraguay',
      SV: 'El Salvador',
      ZA: 'South Africa',
    },
  },

  EDIT: {
    EDIT_BUTTON: 'Edit',
    GO_BACK_BUTTON: 'Go back to coupon campaign',
    DISCARD_MODAL: {
      TITLE: 'Discard changes?',
      DESCRIPTION: 'Unsaved changes will be lost.',
      DISCARD: 'Discard',
    },
  },
};

export default CouponCampaigns;
