import {CreateCampaignProps} from '@Models';
import {useApi} from '../../api';

export default async function getCampaignDetailsService(
  id: number
): Promise<CreateCampaignProps | any> {
  return await useApi().get({
    url: `/v1/admin/campaigns/${id}?isPromotionActive=true`,
  });
}
