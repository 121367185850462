import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useCallback, useContext, useState } from 'react';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import SearchDropdown from '../../../components/SearchDropdown/SearchDropdown';
import StyledSelect from '../../../components/Select/Select';
import StyledToggle from '../../../components/Toggle/Toggle';
import StyledFileUploader from '../../../components/FileUploader/FileUploader';
import { base_url } from '../../../utils/constants';
import StyledButton from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import verifyImgType from '../../../utils/verifyImgType';
import { AlertContext } from '../../../contexts/alert.context';

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const EditFootballLeagues: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [selectedType, setSelectedType] = useState('TYPE');
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);

  const items = [{ name: 'Item 1' }, { name: 'Item 2' }, { name: 'Item 3' }];

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const search = (searchTerm) => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const sizeValidation = (file) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
    }
  };

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Edit Football Leagues {}
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="League name*"
                    width={''}
                    hint="The league name."
                    placeholder="CONCACAF Women U20"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="League identifier*"
                    width={''}
                    hint="The league identifier."
                    placeholder="1001"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <SearchDropdown
                    width=""
                    label="Country"
                    hint="The country name of the league."
                    placeholder="Search for the event"
                    onSearch={search}
                    onChange={(e) => {
                      e.currentTarget.value;
                    }}
                    onClickResult={(item) => {
                      console.log(`Evento selecionado: ${item.name}`);
                    }}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <SearchDropdown
                    width=""
                    label="Authored by"
                    hint="The user ID of author of the Sports soccer league entity."
                    placeholder="Anonymous (0)"
                    onSearch={search}
                    onChange={(e) => {
                      e.currentTarget.value;
                    }}
                    onClickResult={(item) => {
                      console.log(`Evento selecionado: ${item.name}`);
                    }}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledSelect
                    width={''}
                    placeholder="Cup"
                    label="Type"
                    hint="The type of the league."
                    value={selectedType}
                    onChange={handleTypeChange}
                  >
                    <Select.Option value="cup">Cup</Select.Option>
                  </StyledSelect>
                </StyledPaddding>
                <StyledPaddding>
                  <StyledToggle
                    title="Published"
                    description="A boolean indicating whether the Sports soccer league is published."
                    value={false}
                    onChange={() => null}
                  />
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledFileUploader
                  title="Add a new file*"
                  url={base_url + ''}
                  accept=".png,.jpg,.jpeg,.gif"
                  maxFileSize={1000000}
                  message="Allowed PNG, JPG, JPEG, GIF format and must be less than 1MB"
                  width="30vw"
                  onDrop={(e) => {
                    verifiedFile(e);
                  }}
                />
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default EditFootballLeagues;
