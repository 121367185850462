export const teamsQuantity = 5;
export const rolesQuantity = 7;
export const bandsQuantity = 7;
export const trailsQuantity = 5;
export const employeesPerTeamQuantity = 8;
export const allocationsQuantity = teamsQuantity * employeesPerTeamQuantity;
export const pillarsQuantity = 5;
export const kpisQuantityPerPillar = 5;
export const kpisQuantity = 20;
export const kpiResponsesPerKpi = 5;
export const evaluationQuantity = 1;
export const feedbackQuantity = 1;
export const evaluationResponse = pillarsQuantity * kpisQuantityPerPillar * evaluationQuantity;
