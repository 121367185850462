import { Alert, Divider, Heading, Paragraph, TextLink, Tooltip } from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { UploadStatus } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import ProgressBar from '../../../molecules/ProgressBar/ProgressBar';
import { RulesAndConditions } from '../../../molecules/RulesAndConditions/RulesAndConditions';
import { useUpload } from '../Context';
import {
  DescriptionToModal,
  ProgressCard,
  ProgressCardAnalyzed,
  TextContainer,
  TooltipContainer,
  TotalContainer,
  TotalTextContent,
} from './styles';

export const Progress: React.FC = () => {
  const { formatMessage } = useIntl();

  const { selected } = useGetUserPreferences();

  const { file, slow, start, analysis, uploaded, progress, send } = useUpload();

  const { fileId } = uploaded;
  const { status, total, totalAnalysis, validated, failed, processed } = progress;

  const retries = useRef<number>(0);
  const shouldRetry = useRef<boolean>(true);

  const slowTimeout = useRef<NodeJS.Timeout | null>(null);
  const retryTimeout = useRef<NodeJS.Timeout | null>(null);

  const hasFailed = failed > 0;
  const hasAnalyzed = status === UploadStatus.ANALYZED;
  const isProcessing = status === UploadStatus.PROCESSING || status === UploadStatus.DONE;

  const { progressValue, progressMaxValue } = useMemo(
    () => ({
      progressValue: isProcessing ? processed : totalAnalysis,
      progressMaxValue: isProcessing ? validated : total,
    }),
    [isProcessing, processed, validated, total, totalAnalysis]
  );

  const progressLabel = useMemo(() => {
    if (progressValue === 0 && progressMaxValue !== 0) {
      return formatMessage({ id: 'pages.upload.progress.meter.starting' });
    }

    if (slow && progressValue > 0 && progressValue < progressMaxValue) {
      if (status === 'ANALYZING') {
        return `${formatMessage(
          { id: 'pages.upload.progress.meter.progress' },
          { progressValue, progressMaxValue }
        )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
      }

      if (status === 'PROCESSING') {
        return `${formatMessage(
          { id: 'pages.upload.progress.meter.progressUpload' },
          { progressValue, progressMaxValue }
        )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
      }
    }

    if (status === 'PROCESSING') {
      return formatMessage(
        { id: 'pages.upload.progress.meter.progressUpload' },
        { progressValue, progressMaxValue }
      );
    }

    return formatMessage(
      { id: 'pages.upload.progress.meter.progress' },
      { progressValue, progressMaxValue }
    );
  }, [progressValue, progressMaxValue, analysis.failed, slow]);

  const onDownload = () => {
    SegmentService.paymentsButtonClicked(
      'Download error list',
      'Download Failed Files',
      selected?.vendorId,
      'Credit Management Files Download Errors',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );

    send({ type: 'DOWNLOAD_FAILED' });
  };

  const getData = async () => {
    shouldRetry.current = true;

    slowTimeout.current = setTimeout(() => {
      send({ type: 'SLOW' });
    }, 60000);

    retryTimeout.current = setTimeout(() => {
      shouldRetry.current = false;
    }, 60000);

    send({
      type: 'EVALUATE_FILE',
      query: {
        retry: { value: retries, should: shouldRetry },
        timeout: { slow: slowTimeout.current, retry: retryTimeout.current },
      },
    });
  };

  useEffect(() => {
    if (fileId) {
      getData();

      return () => {
        clearTimeout(slowTimeout.current);
        clearTimeout(retryTimeout.current);
      };
    }
  }, [fileId, start.processing]);

  if (!fileId) {
    return <></>;
  }

  return (
    <ProgressCard id="progress-card" border="medium" elevated="small">
      <Heading size="H4" css={{ marginBottom: '8px' }}>
        {isProcessing
          ? formatMessage({ id: 'pages.upload.progress.processingTitle' })
          : formatMessage({ id: 'pages.upload.progress.title' })}
      </Heading>

      <TextContainer>
        {isProcessing ? (
          <Paragraph css={{ fontSize: '16px' }}>
            {formatMessage({ id: 'pages.upload.progress.processingDescription' })}
          </Paragraph>
        ) : (
          <DescriptionToModal colortype="primary">
            <FormattedMessage
              id={'pages.upload.uploader.rulesAndConditions.progress.description'}
              values={{
                TextLink: (chunks: any) => (
                  <RulesAndConditions
                    trigger={<TextLink css={{ fontSize: '16px' }}>{chunks}</TextLink>}
                  />
                ),
              }}
            />
          </DescriptionToModal>
        )}
      </TextContainer>

      {hasAnalyzed && (
        <Heading size="H5" css={{ marginBottom: '16px' }}>
          {formatMessage({ id: 'pages.upload.progress.finished.title' })}
        </Heading>
      )}

      <ProgressBar
        status={status}
        fileName={progress.referenceName}
        filesSize={file.size || '-'}
        percentage={isProcessing ? progress.processedPercentage : progress.analysisPercentage}
        progressLabel={progressLabel}
        hasError={analysis.failed}
        errorMessage={
          isProcessing
            ? formatMessage({ id: 'pages.upload.progress.error.processing' })
            : formatMessage({ id: 'pages.upload.progress.error.analysis' })
        }
      />

      {hasAnalyzed && (
        <ProgressCardAnalyzed id="progress-card-analyzed" border="medium" elevated="small">
          <React.Fragment>
            {hasFailed && (
              <Alert
                type="warning"
                message={formatMessage({ id: 'pages.upload.progress.finished.alert' })}
                css={{ width: '100%' }}
              />
            )}

            <TotalContainer data-testid="total-container">
              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.total' })}
                </Paragraph>

                <Heading id="total" size="H4">
                  {total}
                </Heading>
              </TotalTextContent>

              <Divider orientation="vertical" css={{ height: '100%' }} />

              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.success' })}
                </Paragraph>

                <Heading id="validated" size="H4">
                  {validated}
                </Heading>
              </TotalTextContent>

              <Divider orientation="vertical" css={{ height: '100%' }} />

              <TotalTextContent>
                <TooltipContainer>
                  <Paragraph size="small">
                    {formatMessage({ id: 'pages.upload.progress.finished.pocs.error' })}
                  </Paragraph>

                  <Tooltip
                    placement="bottom"
                    text={formatMessage({ id: 'pages.upload.progress.finished.pocs.tooltip' })}
                  >
                    <Info size="tiny" />
                  </Tooltip>
                </TooltipContainer>

                <Heading id="failed" size="H4" style={{ color: '#C9201D' }}>
                  {failed}
                </Heading>
              </TotalTextContent>
            </TotalContainer>

            {hasFailed && (
              <TextLink
                id="on-download"
                hasUnderline={false}
                color="primary"
                size="small"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '8px',
                  justifyContent: 'flex-end',
                  marginTop: '24px',
                  width: '100%',
                }}
                onClick={onDownload}
              >
                <Download size="medium" style={{ color: '#047AF1' }} />

                {formatMessage({ id: 'pages.upload.progress.finished.download' })}
              </TextLink>
            )}
          </React.Fragment>
        </ProgressCardAnalyzed>
      )}
    </ProgressCard>
  );
};
