import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useHeader } from '../../../hooks/useHeader';
import { useQuery } from '../../../hooks/useQuery';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { AssessmentById } from '../../../interfaces';
import { getAssessmentById, getPocDetails } from '../../../services';
import SegmentService from '../../../services/segment';
import { Page } from '../Page/Page';
import { Conditions } from './Conditions/Conditions';
import { History } from './History/History';
import { Information } from './Information/Information';
import { PocDetailsProps } from './PocDetailsPage.types';
import { Recommendation } from './Recommendation/Recommendation';
import { LeftContainer, PocDetailsContainer, RightContainer } from './styles';

export const PocDetailsPage: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projection = queryParams.get('projection');

  const navigate = useNavigate();
  const { setPageHeader } = useHeader();

  const { get } = useRequester();
  const { get: query } = useQuery();

  const { toast } = useToast();
  const { permissions, selected, configs } = useGetUserPreferences();
  const { features } = configs;
  const { status } = features['poc-details'];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessment, setAssessment] = useState<AssessmentById | null>(null);
  const [pocDetails, setPocDetails] = useState<PocDetailsProps | null>(null);
  const [hasEdit, setHasEdit] = useState<boolean>(false);
  const [error, setError] = useState({ has: false, fn: null });

  const isFirstRender = useRef(true);

  const getAssessment = async () => {
    setIsLoading(true);
    const response = await getAssessmentById({ api: { get }, params: { id } });

    if (!response?.success) {
      setError({ has: true, fn: getAssessment });
      setIsLoading(false);
      return;
    }

    setAssessment(response.data);
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await getPocDetails({
      api: { get },
      params: {
        assessmentId: id,
        beesAccountId: assessment.beesAccountId,
        historyStatus: status.join(','),
        projection,
      },
    });

    if (!response?.success) {
      setError({ has: true, fn: getData });
      setIsLoading(false);
      return;
    }

    setPocDetails(response.data);
    setIsLoading(false);
  };

  const onCallback = async () => setHasEdit(!hasEdit);

  const onOpenEditRecommendationModal = (isOpen: boolean) => {
    if (isOpen) {
      SegmentService.paymentsButtonClicked(
        'Edit Recommendation',
        'Edit Recommendation Button',
        selected.vendorId,
        'Credit Management Edit Line Active',
        'POC_DETAILS_PAGE'
      );
    }
  };

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    if (assessment) {
      setPageHeader({ page: 'pocDetails', attributes: { pageTitle: assessment.pocName } });

      getData();
    }
  }, [assessment]);

  useEffect(() => {
    if ((!query('country') || !query('vendorId')) && !isLoading && (error.has || !pocDetails)) {
      navigate('/bees-credit-management/credit');

      toast.error({ message: 'errors.default' });
    }
  }, [isLoading, error.has, pocDetails]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getAssessment();

      getData();
    }

    isFirstRender.current = false;
  }, [hasEdit]);

  if (!permissions.has('pages.pocDetails')) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  if (isLoading) {
    return <Page.Loading />;
  }

  if (!isLoading && error.has) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload', onClick: () => error.fn() }}
      />
    );
  }

  return (
    <PocDetailsContainer data-testid="poc-details-container">
      <LeftContainer>
        {assessment.recommended === true && (
          <Recommendation
            data={assessment}
            recommendation={pocDetails.creditLineRecommendation}
            creditHistory={pocDetails.creditHistory}
            onCallback={onCallback}
          />
        )}

        <Conditions
          data={assessment}
          conditions={pocDetails.currentCreditLineConditions}
          onCallback={onCallback}
          onOpenEditRecommendationModal={onOpenEditRecommendationModal}
        />

        <Information information={pocDetails.pocInformation} accountId={assessment.accountId} />
      </LeftContainer>

      <RightContainer>
        <History.Credit
          beesAccountId={assessment.beesAccountId}
          creditHistory={pocDetails.creditHistory.slice(0, 2)}
        />
      </RightContainer>
    </PocDetailsContainer>
  );
};
