export const BASE_MANAGEMENT_BFF_PATH = '/api/catalog-admin-product-bff';
export const BASE_FILE_SERVICE_BFF_PATH = '/api/catalog-admin-file-service';
export const BASE_CATEGORY_BFF_PATH = '/api/catalog-admin-category-bff';

export enum URLS {
  GROUPS = '/v1/groups',
  CATEGORIES = '/v1/categories',
  PRODUCTS = '/products',
  BRANDS = '/brands',
  VARIANTS = '/variants',
  VARIANTS_V2 = '/v2/variants',
  ITEMS = '/items',
  ITEMS_DETAILS = '/items/details',
  AI = '/ai',
  CLASSES = '/classes',
  TEMPLATES = '/templates',
  VENDORS = '/vendors',
  VENDORS_WITH_ITEM_TYPE = '/vendors/item-class',
  FILE_REQUEST = '/requests',
  DOWNLOAD_FILE = '/download',
  IMPORT = '/import',
  STORES = '/stores',
  METADATA = '/metadata',
  MANUFACTURERS = '/vendors/manufacturers',
  IMAGES = '/images',
  COUNTRIES = '/countries',
}
