import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGlobalFeedback } from 'types/feedback';
import { IModel, TMessageSource } from 'types/openai';
import { ISystemStore } from 'types/stores';
import { DEFAULT_SOURCE } from 'utils/app/const';

const initialState: ISystemStore = {
  feedbacks: [],
  models: [],
  isUploading: false,
  lastMessageSource: DEFAULT_SOURCE,
  expandedConversationSidebar: false,
  expandedPromptSidebar: false,
  disabledSidebars: false,
  instructionInputTokenCount: 0,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setGlobalFeedbacks(state: ISystemStore, action: PayloadAction<IGlobalFeedback[] | undefined>) {
      state.feedbacks = action.payload;
    },
    setModels(state: ISystemStore, action: PayloadAction<IModel[]>) {
      state.models = action.payload;
    },
    setExpandedConversationSidebar(state: ISystemStore, action: PayloadAction<boolean>) {
      state.expandedConversationSidebar = action.payload;
    },
    setExpandedPromptSidebar(state: ISystemStore, action: PayloadAction<boolean>) {
      state.expandedPromptSidebar = action.payload;
    },
    setDisabledSidebars(state: ISystemStore, action: PayloadAction<boolean>) {
      state.disabledSidebars = action.payload;
    },
    setlastMessageSource(state: ISystemStore, action: PayloadAction<TMessageSource>) {
      state.lastMessageSource = action.payload;
    },
    setInstructionInputTokenCount(state: ISystemStore, action: PayloadAction<number>) {
      state.instructionInputTokenCount = action.payload;
    },
    setIsUploading(state: ISystemStore, action: PayloadAction<boolean>) {
      state.isUploading = action.payload;
    },
  },
});

export const {
  setGlobalFeedbacks,
  setModels,
  setExpandedConversationSidebar,
  setExpandedPromptSidebar,
  setlastMessageSource,
  setDisabledSidebars,
  setInstructionInputTokenCount,
  setIsUploading,
} = systemSlice.actions;

export default systemSlice.reducer;
