import { LayoutResponse } from 'api/services/layouts'
import { create } from 'zustand'

export enum ModalType {
    ADD_LAYOUT = 'add-layout',
    UPDATE_LAYOUT = 'update-layout',
    }

interface LayoutStoreProps {
    modalType: keyof typeof ModalType | null
    layoutId: string
    layoutData: LayoutResponse | null
    exclusionwords: string[]
    setLayoutId: (id: string) => void
    setModalType: (type: keyof typeof ModalType) => void
    setLayoutData: (data: LayoutResponse | undefined) => void
    setExclusionwords: (words: string[]) => void
    resetLayoutData: () => void
}

export const useLayoutStore = create<LayoutStoreProps>((set, get) => ({
    modalType: "ADD_LAYOUT",
    layoutId: "",
    layoutData: null,
    exclusionwords: [],
    setLayoutId: (id: string) => set({ layoutId: id }),
    setModalType: (type: keyof typeof ModalType) => set({ modalType: type }),
    setLayoutData: (data: LayoutResponse | undefined) => set({ layoutData: data }),
    setExclusionwords: (words: string[]) => set({ exclusionwords: words }),
    resetLayoutData: () => set({ layoutData: null })
}))


