import { Dialog as BeesDialog, Heading } from '@hexa-ui/components';
import { useDialogContext } from 'providers/DialogProvider/DialogProvider';
import { InlineActionsWrapper, StackedActionsWrapper, StyledParagraph } from './styles';

export type DialogProps = {
  onClose?: () => void;
  content?: JSX.Element;
  description?: string;
  title?: string;
  closeButton?: boolean;
  stackedActions?: boolean;
  actions?: JSX.Element[] | JSX.Element;
};

const Dialog = () => {
  const { props, isOpen } = useDialogContext();

  return (
    <BeesDialog.Root
      variant="overlay"
      open={isOpen}
      onClose={props.onClose}
      closeButton={props.closeButton || false}
      title={<Heading size="H4">{props.title}</Heading>}
      actions={
        props.stackedActions ? (
          <StackedActionsWrapper>{props.actions}</StackedActionsWrapper>
        ) : (
          <InlineActionsWrapper>{props.actions}</InlineActionsWrapper>
        )
      }
    >
      {props.description ? <StyledParagraph>{props.description}</StyledParagraph> : props.content}
    </BeesDialog.Root>
  );
};

export default Dialog;
