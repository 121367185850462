import { TextButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

export const PreviewSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ToggleVisibilityButton = styled(TextButton, {
  color: '#006EDB',
});

export const ContentSection = styled('div', {
  transition: 'height 0.3s, mask 0.3s',
  overflow: 'hidden',
  variants: {
    expanded: {
      true: {
        height: '$$contentHeight',
        marginBottom: '12px',
      },
      false: {
        height: '0',
      },
    },
    preview: {
      true: {
        height: '$$initialHeight',
        marginBottom: '12px',
        '-webkit-mask':
          'linear-gradient(#000 0 0), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.95)) padding-box',
        '-webkit-mask-composite': 'exclude',
        mask: 'linear-gradient(#000 0 0), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.95)) padding-box',
        maskComposite: 'exclude',
      },
    },
  },
});
