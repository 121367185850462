import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Tables } from '../../../interfaces';
import { List, ManagementPageContainer, Root, Trigger } from './styles';

export const ManagementPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { permissions } = useGetUserPreferences();
  const defaultTab = Tables.PROCESSING;
  const [tab, setTab] = useState<Tables>(defaultTab);

  const tablesMapping = useMemo(
    () => Object.values(Tables).filter((table) => permissions.has(`tables.management.${table}`)),
    [Tables, permissions]
  );

  return (
    <ManagementPageContainer data-testid="management-page">
      <Root defaultValue={tab} value={tab}>
        <List>
          {tablesMapping.map((table) => (
            <Trigger key={`tabs-trigger-${table}`} value={table} onClick={() => setTab(table)}>
              {formatMessage({ id: `tables.management.${table}.tab.title` })}
            </Trigger>
          ))}
        </List>
      </Root>
    </ManagementPageContainer>
  );
};
