import { useCallback, useState } from 'react';

import { SEARCH_PARAMETERS } from '@/enums/searchEnum';

import { UseTableVisitCardProps } from '../../types/TableVisitCard';
import { useTranslation } from '../useTranslation/useTranslation';

export const useTableVisitCard = (): UseTableVisitCardProps => {
  const { accountId } = useTranslation();
  const [filterValue, setFilterValue] = useState<SEARCH_PARAMETERS>(SEARCH_PARAMETERS.BDR_ID);
  const [searchValue, setSearchValue] = useState('');
  const [ids, setIds] = useState<string[]>([]);

  const onClickSearchEvent = useCallback(() => {
    if (filterValue === accountId) {
      const filteredIds = searchValue.includes(';') ? searchValue.split(';') : [searchValue];
      setIds([...filteredIds]);
    }
  }, [filterValue, searchValue, accountId]);

  return {
    searchValue,
    filterValue,
    ids,
    setFilterValue,
    setSearchValue,
    onClickSearchEvent,
  };
};
