/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { MOVE_BFF_PATH } from 'constants/routes/server';

export interface IRequest {
  url: string;
  body?: any;
  params?: any;
}

const axiosClient = Axios.create({
  baseURL: MOVE_BFF_PATH,
});

const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(axiosClient, {
  headers: [
    {
      country: userCountry || '',
      noorders: localStorage.getItem('noOrders') || '',
    },
  ],
});

const Api = {
  post: ({ url, body }: IRequest): Promise<any> =>
    axiosClient.post(url, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    axiosClient.delete(url).catch((error) => ({ hasError: true, ...error })),

  get: ({ url, params }: IRequest): Promise<any> =>
    axiosClient.get(url, { params }).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body }: IRequest): Promise<any> =>
    axiosClient.put(url, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: ({ url, body }: IRequest): Promise<any> =>
    axiosClient.patch(url, body).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
