import { styled } from '@hexa-ui/theme';

export const SwitchLoading = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px',
});

export const LoadingTitle = styled('div', {
  marginBottom: '8px',
});

export const FullScreen = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
});

const Styled = {
  SwitchLoading,
  LoadingTitle,
  FullScreen,
};

export default Styled;
