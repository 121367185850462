import { Select } from '@hexa-ui/components'
import { Field, FieldProps } from 'formik'
import React, { ReactElement } from 'react'
import { SelectWithOptional } from './styles'
export interface SelectOptions {
	value: string
	label: string
	disabled?: boolean
}

type Props = {
	id: string
	name: string
	label?: string
	placeholder?: string
	children: React.ReactNode
	dataTestId?: string
	optional?: boolean
}

const InputSelectHexa: React.FC<Props> = ({ id, name, label, placeholder, children, dataTestId, optional }: Props) => {
	return (
		<Field name={name}>
			{(fieldProps: FieldProps): ReactElement => {
				return (
					<SelectWithOptional optional={optional}>
						{label && <label htmlFor={id}>{label}</label>}
						<Select.Root
							id={id}
							placeholder={placeholder}
							data-testid={dataTestId}
							name={fieldProps.field.name}
							value={fieldProps.field.value ?? ''}
							onChange={(value) => fieldProps.form.setFieldValue(fieldProps.field.name, value)}
						>
							{children}
						</Select.Root>
					</SelectWithOptional>
				)
			}}
		</Field>
	)
}

export default InputSelectHexa
