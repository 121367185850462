import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Tradução',
    missing: 'Somente a versão em Português está disponível',
  },
  basicActions: {
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    next: 'Próximo',
    back: 'Anterior',
    return: 'Voltar',
    save: 'Salvar',
    send: 'Enviar',
    finish: 'Finalizar',
    exitWithoutSaving: 'Sair sem salvar',
    close: 'Fechar',
    access: 'Acessar',
  },
  employee: {
    date: {
      to: 'a',
      evaluate: 'Data de avaliação: ',
      feedback: 'Data de feedback: ',
      hiring: 'Data de contratação: ',
      lastMovement: 'Data da última movimentação: ',
    },
    info: {
      role: 'Cargo: ',
      band: 'Equipe: ',
      manager: 'Gerente: ',
    },
  },
  button: {
    evaluate: 'Avaliar',
    report: 'Relatório',
    selfEvaluate: 'Autoavaliação',
    feedback: 'Feedback',
  },
  text: {
    notFound: 'Página não encontrada',
    backHome: 'Voltar para o início',
    comments: 'Comentários',
    selfEvaluateTitle: 'Ciclo de Autoavaliação',
    submit: 'Você tem certeza que deseja enviar essa avaliação?',
    reportFeedbackTitle: 'ainda não possui uma avaliação respondida!',
    reportFeedbackDescription: 'Para acessar o relatório, primeiro responda a uma avaliação.',
    homeMessageCard:
      'O ciclo de avaliação ocorre em três etapas, a primeira é chamada de Autoavaliação, onde você responderá a um formulário sobre você, depois há a Avaliação onde seu gestor de linha também responderá a este formulário, por último ocorre o Feedback onde você falará sobre as respostas dadas.',
    homeMessageCardUserInactive:
      'Oooops, estamos com problemas. Por favor, entre em contato com o administrador.',
    managerResponse: 'Resposta do Gerente',
    employeeResponse: 'Resposta do Funcionário',
    finalComment: 'Comentário final',
    success: 'Sucesso!',
    warning: 'Aviso',
    error: 'Erro',
    confirmation: 'Confirmação',
    welcome: 'Bem vindo',
    search: 'Procure pelo nome',
    errorInfo: 'Não há informações para mostrar',
    loadErrorData: 'Não foi possível carregar os dados, por favor tente mais tarde',
  },
  validation: {
    name: 'O nome informado é inválido, por favor informe um nome correto',
    notEmpty: 'Este campo não pode ser vazio, preencha o campo por favor',
  },
  POD: {
    filterByPod: 'Filtrar por POD',
  },
  ViewAs: {
    lineManager: 'Visualizar como Line Manager',
    admin: 'Visualizar como Admin',
  },
  breadCrumb: {
    home: 'Página Inicial',
    team: 'Gerenciamento de Avaliações',
    evaluate: 'Avaliação',
    report: 'Relatório',
    selfEvaluation: 'Ciclo de autoavaliação',
    feedback: 'Feedback',
    accessDenied: 'Acesso Negado',
  },
  selectOptions: {
    allPods: 'Todas as pods',
  },
  userInfoCard: {
    role: 'Cargo',
    band: 'Band',
    manager: 'Gerente',
  },
  evaluationAlert: {
    message: 'Não é possível finalizar a avaliação sem responder todas as questões.',
  },
  reportPage: {
    evaluationNotFound: 'Avaliação completa não encontrada.',
  },
  evaluationGraph: {
    emptyGraph: 'Não temos uma avaliação disponível para exibir no gráfico.',
  },
  evaluations: {
    evaluate: 'Avaliação',
    selfEvaluate: 'Autoavaliação',
    feedback: 'Feedback',
  },
  feedback: {
    cycleDate: 'Data do Ciclo: ',
    from: 'De',
    to: 'a',
    successModalDescription:
      'O feedback foi realizado com sucesso e pode ser visualizado pelo funcionário.',
    viewReport: 'Ver Relatório',
    goManagement: 'Ir para Tela de Avaliações',
    warningModalDescription: 'Você não salvou o Feedback. Você quer sair mesmo assim?',
    errorModalDescription: 'Não foi possível finalizar o feedback, por favor tente mais tarde',
    messageErrorFeedback: 'Este campo é obrigatório',
  },
  evaluationPage: {
    warningModalDescription: 'Você não salvou a Avaliação. Você quer sair mesmo assim?',
  },
  selfEvaluatePage: {
    warningModalDescription: 'Você não salvou a Autoavaliação. Você quer sair mesmo assim?',
  },
  feature: {
    evaluateManagement: {
      title: 'Gerenciamento de Avaliações',
      description:
        'Avalie seu time, visualize os resultados e faça feedbacks em 1:1 com membros dos seu time!',
    },
    admin: {
      title: 'Admin',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut ex risus.',
    },
  },
};

export default ptBR;
