import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { DialogProps, Divider } from '@mui/material';
import { UserDetails } from 'components/UserReportTable';
import React, { useState } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}

export interface ModalUserDetailsProps {
  userDetails: UserDetails;
  isOpen: boolean;
  onClose: DialogProps['onClose'];
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        data-testid="close-user-details-modal-button"
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles({
  root: {},
  typography: {
    margin: '0 0 8px 0',
  },
  contentTitle: {
    fontSize: '1.2em',
  },
  contentScope: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    flex: 1,
    fontWeight: 'bold',
  },
  value: {
    flex: 2,
    opacity: 0.9,
  },
});

const ModalUserDetails: React.FC<ModalUserDetailsProps> = ({ userDetails, onClose, isOpen }) => {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={onClose}
        open={isOpen}
        id="modal-user-details"
        data-testid="modal-user-details"
      >
        <DialogTitle onClose={onClose}>Details</DialogTitle>
        <DialogContent dividers>
          <Typography
            className={[classes.typography, classes.contentTitle].join(' ')}
            variant="h6"
            component="h6"
          >
            User Information
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Name:</span>{' '}
            <span className={classes.value}>{userDetails.name}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>E-mail:</span>{' '}
            <span className={classes.value}>{userDetails.email}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Role:</span>{' '}
            <span className={classes.value}>{userDetails.role}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Group:</span>{' '}
            <span className={classes.value}>{userDetails.groups.join(', ')}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>BU:</span>{' '}
            <span className={classes.value}>{userDetails.zone}</span>
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography
            className={[classes.typography, classes.contentTitle].join(' ')}
            variant="h6"
            component="h6"
          >
            License Information
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Last Update:</span>{' '}
            <span className={classes.value}>{userDetails.lastUpdate}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>License Status:</span>{' '}
            <span className={classes.value}>{userDetails.licenseStatus}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>License Type:</span>{' '}
            <span className={classes.value}>{userDetails.licenseType}</span>
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography
            className={[classes.typography, classes.contentTitle].join(' ')}
            variant="h6"
            component="h6"
          >
            User Activity
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Last Login:</span>{' '}
            <span className={classes.value}>{userDetails.lastLogin}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Login Frequency:</span>{' '}
            <span className={classes.value}>{userDetails.loginFrequence}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Tickets Assigned:</span>{' '}
            <span className={classes.value}>{userDetails.ticketsAssigned}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Tickets Opened:</span>{' '}
            <span className={classes.value}>{userDetails.ticketsOpened}</span>
          </Typography>

          <Typography
            className={[classes.typography, classes.contentScope].join(' ')}
            variant="subtitle1"
            component="h6"
          >
            <span className={classes.label}>Tickets Solved:</span>{' '}
            <span className={classes.value}>{userDetails.ticketsSolved}</span>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalUserDetails;
