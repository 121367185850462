import { PageLoader, ProtectedRoute } from '@components';
import { LazyExoticComponent, ReactElement, Suspense, lazy } from 'react';

export type Modules = 'ORDER_FORECAST' | 'CREATE_FORECAST' | 'FORECAST_DETAILS' | 'UPDATE_FORECAST';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
};

export const userPermissions = {
  read: 'LinkAdmin.CommA.read',
  write: 'LinkAdmin.CommA.write',
  all: ['LinkAdmin.CommA.read', 'LinkAdmin.CommA.write'],
};

type RoutesProps = Record<Modules, Route>;

const Home = lazy(() => import('pages').then((module) => ({ default: module.Home })));
const CreteForecast = lazy(() =>
  import('pages').then((module) => ({ default: module.CreateForecast }))
);
const ForecastDetails = lazy(() =>
  import('pages').then((module) => ({ default: module.ForecastDetails }))
);

export const linkAdminPath = '/link-admin';
export const homePath = '/collab-forecast';
export const forecastDetailsPath = `${homePath}/forecast-details`;
export const updateForecastPath = `${homePath}/update-forecast`;

export const createForecastPath = `${homePath}/create-forecast`;
export const editForecastPath = `${homePath}/edit-forecast`;

export const Routes: RoutesProps = {
  ORDER_FORECAST: { path: homePath, Element: Home },
  CREATE_FORECAST: { path: createForecastPath, Element: CreteForecast },
  FORECAST_DETAILS: { path: forecastDetailsPath, Element: ForecastDetails },
  UPDATE_FORECAST: { path: updateForecastPath, Element: CreteForecast },
};
export const routersConfig = Object.values(Routes).map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        <Element />
      </ProtectedRoute>
    </Suspense>
  ),
}));
