import { Card, Grid, Heading, Select, Tabs } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../hook/useWindowDimensions';

import StyledTabs from '../../../components/Tabs/Tabs';
import EditTournamentTab from './tabs/EditTournamentTab';
import MatchesTab from './tabs/MatchesTab';
import GroupsTab from './tabs/GroupsTab';
import styled from 'styled-components';

const StyledGridContainer = styled(Grid.Container)`
 margin-left: 0;
`;

const StyledGridItem= styled(Grid.Item)`
  padding-left: 0;
`;

export default function TournamentDetails() {
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(hash || '#edit');

  useEffect(() => {
    setCurrentTab(hash || '#edit');
  }, [hash]);
  const tabs = [
    { component: EditTournamentTab, hash: '#edit', title: 'Edit' },
    { component: GroupsTab, hash: '#group', title: 'Group' },
    { component: MatchesTab, hash: '#match', title: 'Match' },
  ];

  return (
    <>
      <StyledGridContainer type="fluid">
        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Tournament Details
          </Heading>
        </StyledGridItem>

        <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" css={{ paddingBottom: '0.5rem'}} style={{ width: '100%' }}>
            <StyledTabs defaultValue={hash || '#edit'}>
              <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
                {tabs?.map((tab, index) => (
                  <a
                    key={index + tab.title}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={tab.hash}
                  >
                    <Tabs.Trigger key={index} value={tab.hash}>
                      {tab.title}
                    </Tabs.Trigger>
                  </a>
                ))}
              </Tabs.List>
              {tabs?.map((tab, index) => (
                <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                  <tab.component />
                </Tabs.Content>
              ))}
            </StyledTabs>
          </Card>
        </StyledGridItem>
      </StyledGridContainer>
    </>
  );
}
