import MessageMap from 'I18n/i18n';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Only English option available',
  },

  SELECTED_LANGUAGE: 'pt-BR',

  MAIN_PAGE: {
    TITLE: 'Análise',
    BTGUIDE_SECTION: {
      TITLE: 'Guia de transformação do negócio',
      DESCRIPTION: 'Um guia passo a passo para aproveitar ao máximo com o BEES.',
      LINK: 'Ir para o guia de transformação de negócios',
    },
    REPORT_SECTION: {
      TITLE: 'Relatórios',
      SUBTITLE: 'Explore relatórios detalhados sobre diferentes temas.',
      BUTTON: 'Mostrar todos os relatórios',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Visualizações disponíveis',
      DESCRIPTION: 'Acesse diferentes conjuntos de dados disponíveis para você.',
      BUTTON: 'Mostrar todas as visualizações',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
    BIG_NUMBERS_SECTION: {
      TITLE: 'Destaques do mês até a data',
      SUBTITLE: 'KPIs de clientes do BEES em um relance.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    TITLE: 'Visualizações disponíveis',
    SEARCHFIELD: {
      PLACEHOLDER: 'Pesquisar por tipo de usuário',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Alterar',
    NO_SEGMENT: 'No segment selected',
    VIEW: 'Visualização atual:',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Mudar visualização',
    DESCRIPTION: 'Selecione as opções para alterar a visualização.',
    WORKSPACE: {
      LABEL: 'Tipo de usuário',
      PLACEHOLDER: 'Selecione',
    },
    SEGMENT: {
      LABEL: 'Visualização',
      PLACEHOLDER: 'Selecione',
    },
    COUNTRY: {
      LABEL: 'País',
      PLACEHOLDER: 'Selecione',
    },
    SERVICE_MODEL: {
      LABEL: 'Modelo de negócios',
      PLACEHOLDER: 'Selecione',
    },
    VENDOR: {
      LABEL: 'Fornecedor',
      PLACEHOLDER: 'Selecione',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Alterar',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'visualização',
    PLURAL_TEXT: 'visualizações',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Relatórios',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: 'Você está na',
      LAST_PART: 'visualização',
    },
    REPORTS_QUANTITY: ' relatórios',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Dados e análises',
    REPORTS_PAGE_VENDOR: 'Fornecedor',
    CONTENT_REPORTS_TAB_VIEW:
      'Avalie seu desempenho em todos os pedidos entregues dentro do período selecionado',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Visão geral global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Algo deu errado',
    CONTAINER_ERROR_LABEL: 'Desculpe, estamos com problemas técnicos. Por favor, tente novamente.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Exportar CSV',
    DESCRIPTION: 'Selecione o período para fazer o download.',
    YEAR_LABEL: 'Ano',
    MONTH_LABEL: 'Mês',
    NO_INFO_AVAILABLE:
      'Não há informações para o período selecionado. Por favor, tente uma data diferente.',
    CANCEL: 'Cancelar',
    DOWNLOAD: 'Baixar',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Exportar dados',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Seu teste gratuito termina em breve',
    FIRST_DESCRIPTION:
      'O arquivo CSV que você está prestes a baixar é uma cortesia do nosso programa Gold Partner.',
    SECOND_DESCRIPTION:
      'Para continuar a ter acesso a insights exclusivos sobre o seu negócio, você precisará fazer um upgrade para o programa Gold Partner.',
    BUTTON: 'BAIXAR ARQUIVO',
    NO_INFO_AVAILABLE: 'Não há informações para o período.',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Como acessar um arquivo CSV',
    DESCRIPTION:
      'Não está familiarizado com arquivos CSV? Confira um guia passo a passo sobre como usá-lo!',
    LINK: 'Mostrar guia para arquivos CSV',
    PROCEED_BUTTON: 'Prossiga para baixar',
  },

  GET_STARTED: {
    LOADING: 'Carregando',
    TITLE: 'Começar',
    PARAGRAPH: 'Selecione um fornecedor para descobrir seus dados e insights.',
    NEXT: 'Próximo',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Alterar',
    TITLE: 'Você está vendo',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Abrir',
    CLEAR: 'Limpar',
  },

  MONTH: {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  },

  COMMON: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
  },
  LOADING: {
    TITLE: 'Carregando',
    PARAGRAPH: 'Estamos carregando a página para você.',
  },
  SELECT_VENDOR: {
    TITLE: 'Selecionar um fornecedor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Acesso negado!',
    ERROR_DESCRIPTION: 'Parece que você não tem permissão para acessar esta pagina.',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Algo deu errado. Por favor, tente novamente.',
    PREPARING_DOWNLOAD: 'Preparando o arquivo CSV para download.',
    SUCCESS_DOWNLOAD: 'Download concluído com sucesso.',
  },

  REVENUE_SILVER_PARTNER: {
    TITLE: 'Insights sobre o seu negócio de um',
    TITLE_NEXT_LEVEL: 'jeito inovador',
    DESCRIPTION:
      'Com os nossos insights premium, você tem acesso a análises poderosas que vão expandir o potencial do seu negócio.',
    BUTTON: 'Torne-se um Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusivo para Parceiros Gold',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS:
      'Solicitação enviada com sucesso! Nossa equipe entrará em contato com você em breve.',
    TOAST_ERROR: 'Algo deu errado. Tente novamente mais tarde.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolívia',
    BR: 'Brasil',
    CL: 'Chile',
    CO: 'Colômbia',
    DO: 'República Dominicana',
    EC: 'Equador',
    ID: 'Indonésia',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Peru',
    PH: 'Filipinas',
    PY: 'Paraguai',
    ZA: 'África do Sul',
    CH: 'Switzerland',
    BE: 'Belgium',
    IC: 'Canary Island',
    ES: 'Spain',
    DE: 'Germany',
    GB: 'United Kingdom',
    NL: 'Netherlands',
    GLOBAL_OVERVIEW: 'Visão geral global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Selecionar país',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Selecionar modelo de negócio',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Selecionar fornecedor',
    LOADING: 'Carregando...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Buscando os dados...',
      BODY: 'Etapa 1 de 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Carregando o relatório...',
      BODY: 'Etapa 2 de 3',
    },

    LOADING_DATA: {
      TITLE: 'Estamos quase lá',
      BODY: 'Etapa 3 de 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },

  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },

  SWITCH_LOADING: {
    TITLE: 'Alterando visualizações',
    ENTERING: 'Você está entrando em',
    USER: 'usuário: ',
    PARAGRAPH: 'Aguarde enquanto a visualização carrega.',
  },

  BEES_ASSISTANT: {
    DESCRIPTION: 'Precisa de ajuda com o BEES Analytics? Estamos aqui para ajudar.',
  },

  BIG_NUMBERS: {
    BUTTON: 'Ir para o relatório detalhado',
  },
};

export default ptBR;
