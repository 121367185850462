import React from 'react';
import ReactDOM from 'react-dom';

import { useLogService } from 'admin-portal-shared-services';
import singleSpaReact from 'single-spa-react';

import App from './App';

function ErrorBoundary({ error }: { readonly error: Error }): JSX.Element {
  const logService = useLogService();
  logService.error(error);

  return <div>Error occurred. Please try again later.</div>;
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = reactLifecycles;
