import { VisitDetailsCard } from '@/components/VisitDetailsCard/VisitDetailsCard';

import { useVisits } from './hooks/useVisitDetails';
import * as Styled from './VisitDetails.styles';

export const VisitDetails = (): JSX.Element => {
  useVisits();

  return (
    <>
      <Styled.VisitsPage>
        <VisitDetailsCard />
      </Styled.VisitsPage>
    </>
  );
};
