import { UseForecastDetailsServiceProps } from '../../';

export type BuildDependenciesProps = Omit<
  UseForecastDetailsServiceProps,
  'status' | 'setPagination'
>;

export const buildDependencies = ({
  accountId,
  vendorId,
  period,
  page,
  pageSize,
  type,
  region,
  sort,
  search,
}: BuildDependenciesProps) => {
  const dependencies = [
    'get-order-forecast',
    accountId,
    vendorId,
    period,
    page,
    pageSize,
    type,
    region,
  ];

  if (search) {
    dependencies.push(search);
  }

  if (sort.prop && sort.direction) {
    dependencies.push(sort.prop, sort.direction);
  }

  return dependencies;
};
