import { OptimizelyContext, useFeature } from '@optimizely/react-sdk';
import { useClusterService, useEnvService, useUserMetadata } from 'admin-portal-shared-services';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoadingView from './components/loadingView/LoadingView';
import { Clusters, Features, PREFERRED_LANGUAGE } from './config/constants';
import useI18nProvider from './hooks/useI18nProvider/useI18nProvider';
import useStyles from './Router.styles';
import SideBarWrapper from './SideBarWrapper';

const ClassesRoute = lazy(() => import('./pages/classes/ClassesRoute'));
const ClassDetailsRoute = lazy(() => import('./pages/classDetails/ClassDetailsRoute'));
const AttributeRoute = lazy(() => import('./pages/attributes/AttributeRoute'));
const NewAttributes = lazy(() => import('./pages/newAttribute/NewAttribute'));
const AttributeDetails = lazy(() => import('./pages/attributesDetail/AttributeDetails'));

const BASENAME = '/pim';

const Router = (): JSX.Element => {
  const { content, containerBody, loadingView } = useStyles();
  const [currentLanguage, setCurrentLanguage] = React.useState('');
  const { changeProviderLanguage } = useI18nProvider();
  const { data } = useUserMetadata();
  const { optimizely } = React.useContext(OptimizelyContext);

  const environment = useEnvService().getEnv().toLocaleLowerCase();
  const cluster = useClusterService().getCluster()?.toLocaleLowerCase();
  const [, variables] = useFeature(Features.PIM_TAXONOMY_HIDE_SIDEBAR, {
    autoUpdate: true,
  });

  const suffix = cluster === Clusters.DTC ? '_dtc' : '_global';
  const shouldHideMenuFT = variables[`${environment}${suffix}`];
  const optimizelyIsReady = !optimizely.getIsUsingSdkKey() || optimizely.isReady();

  React.useEffect(() => {
    if (
      data?.profile?.preferredLanguage &&
      (data?.profile?.preferredLanguage !== currentLanguage ||
        data?.profile?.preferredLanguage !== localStorage.getItem(PREFERRED_LANGUAGE))
    ) {
      localStorage.setItem(PREFERRED_LANGUAGE, data.profile.preferredLanguage);
      changeProviderLanguage(data?.profile?.preferredLanguage);
      setCurrentLanguage(data?.profile?.preferredLanguage);
    }
  }, [currentLanguage, data]);

  return (
    <BrowserRouter>
      <div className={containerBody}>
        <Suspense
          fallback={
            <div className={loadingView}>
              <LoadingView />
            </div>
          }
        >
          <div className={content}>
            {optimizelyIsReady && !shouldHideMenuFT && (
              <SideBarWrapper language={data?.profile?.preferredLanguage} />
            )}
            <Switch>
              <Route path={`${BASENAME}/class/:id`} component={ClassDetailsRoute} />
              <Route path={`${BASENAME}/classes`} component={ClassesRoute} />
              <Route exact path={`${BASENAME}/attributes`} component={AttributeRoute} />
              <Route path={`${BASENAME}/attributes/new`} component={NewAttributes} />
              <Route path={`${BASENAME}/attributes/detail`} component={AttributeDetails} />
              <Route path={`${BASENAME}/attribute/pages/edit`} component={NewAttributes} />
            </Switch>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
