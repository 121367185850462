import { combineReducers } from '@reduxjs/toolkit';
import paginationReducer from './pagination.slice';
import reportsSlice from './reports.slice';

const reducers = combineReducers({
  report: reportsSlice,
  pagination: paginationReducer,
});

export default reducers;
