import { useLocation } from 'react-router-dom';

type CheckUrlComponentReturn = 'MainPage' | 'WorkspacesList' | 'ReportsList' | 'EmbedPage';

export function useCheckCurrentPage(): CheckUrlComponentReturn {
  const location = useLocation();

  if (location.pathname === '/reports/workspaces-list') {
    return 'WorkspacesList';
  }
  if (location.pathname === '/reports/reports-list') {
    return 'ReportsList';
  }
  if (location.pathname.startsWith('/reports/embed/')) {
    return 'EmbedPage';
  }

  return 'MainPage';
}
