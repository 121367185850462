import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export const useOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const handleClickRefreshButton = () => {
    // eslint-disable-next-line no-console
    console.log('Refresh button clicked');
  };

  const handleOpenOrCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return {
    formatMessage,
    cardRef,
    handleClickRefreshButton,
    handleOpenOrCloseModal,
    isModalOpen,
  };
};
