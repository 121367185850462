import { VisitDetails } from './pages/VisitDetails/VisitDetails';
import { Visits } from './pages/Visits/Visits';

export const routes = [
  {
    element: <Visits />,
    path: '/force/visits',
  },
  {
    element: <VisitDetails />,
    path: '/force/visits/:id',
  },
];
