/* eslint-disable react-hooks/rules-of-hooks */
import * as Grid from '@admin-portal-shared-components/grid';
import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EnvProvider } from './context/EnvProvider';
import { EnvConfig } from './context/EnvProvider/EnvProvider.types';
import { I18nLanguageProvider } from './i18n/LanguageProvider';
import Router from './Router';
import theme from './themes';

const generateClassName = createGenerateClassName({
  productionPrefix: 'pim-taxonomy-mfe',
  seed: 'pim-taxonomy-mfe',
});

function App(props: EnvConfig): JSX.Element {
  const wrapperProps = {
    ...props,
  };

  const optimizelyClient = createInstance({ sdkKey: wrapperProps.optimizelyKey });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StylesProvider generateClassName={generateClassName}>
          <OptimizelyProvider optimizely={optimizelyClient}>
            <EnvProvider env={props}>
              <I18nLanguageProvider>
                <DndProvider backend={HTML5Backend}>
                  <Grid.Container
                    style={{
                      rowGap: '1rem',
                    }}
                    type="fluid"
                    sidebar
                  >
                    <Router />
                  </Grid.Container>
                </DndProvider>
              </I18nLanguageProvider>
            </EnvProvider>
          </OptimizelyProvider>
        </StylesProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
