export enum Status {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export interface EditableStatusFieldProps {
  name: string;
  title: string;
  options: {
    [key in Status]: string;
  };
  isEditing: boolean;
  disabled?: boolean;
}
