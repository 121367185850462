import { useMutation } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { deleteExclusionRule } from '@/services/rules/RulesService'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useFormContext } from 'react-hook-form'
import { CreateExclusionRuleFormData } from '@/routes/rules/RulesRedesign/Pages/CreateExclusionPageRule/context/CreateExclusionListProvider/CreateExclusionListProvider.types'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ScreenName } from '@/domains'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import useCurrentZone from '../zone/useCurrentZone'
import { useCurrentTier } from '../tier/useCurrentTier'

export const useDeleteExclusionRule = (): {
	isLoading: boolean
	deleteRule: () => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const { selectedTierKey, currentTier } = useCurrentTier()
	const { t } = useTranslation()
	const ruleId = getRuleIdFromPathname()
	const selectedZone = useCurrentZone()

	const { getValues } = useFormContext<CreateExclusionRuleFormData>()

	const exclusionRuleData = getValues()

	const { isLoading, mutate: deleteRule } = useMutation(
		() => {
			return deleteExclusionRule(ruleId ?? '', vendorId)
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('common:TOAST_DELETE_RULE:SUCCESS'),
				})

				AnalyticsService.events.ruleDeleted({
					amount: null,
					rule_type: 'Exclusion List',
					earning_rule_description: exclusionRuleData.description!,
					earning_rule_id: exclusionRuleData.ruleId!,
					points: null,
					screen_name: ScreenName.ExclusionRuleDetails,
					tier: currentTier ? currentTier?.label : selectedTierKey,
					user: getUserEmail(),
					vendor: vendorId,
					zone: selectedZone,
				})

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: t('common:TOAST_DELETE_RULE:ERROR'),
				})
			},
		},
	)

	return {
		isLoading,
		deleteRule,
	}
}
