import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { Blocker } from 'react-router-dom';
import { useUpload } from '../Context';
import { ButtonsContainer, Close, Root, SubtitleSpan } from './styles';

interface CancelModalProps {
  blocker: Blocker;
}

export const CancelModal: React.FC<CancelModalProps> = ({ blocker }) => {
  const { formatMessage } = useIntl();

  const { send } = useUpload();

  const onBack = () => send({ type: 'BACK', to: 'files' });

  const onCancel = () => blocker.reset();
  const onConfirm = () => blocker.proceed();

  return (
    <Root
      open={blocker?.state === 'blocked'}
      title={<Heading size="H2">{formatMessage({ id: 'pages.upload.cancelModal.title' })}</Heading>}
      contentCss={{ minWidth: '570px' }}
      onClose={onCancel}
      trigger={
        <Button id="back-button" size="medium" variant="secondary" onClick={onBack}>
          {formatMessage({ id: 'pages.upload.buttons.back' })}
        </Button>
      }
      actions={
        <ButtonsContainer>
          <Close>
            <Button id="confirm-button" size="large" variant="destructive" onClick={onConfirm}>
              {formatMessage({ id: 'pages.upload.cancelModal.buttons.confirm' })}
            </Button>
          </Close>

          <Close>
            <Button id="cancel-button" size="large" variant="secondary" onClick={onCancel}>
              {formatMessage({ id: 'pages.upload.cancelModal.buttons.cancel' })}
            </Button>
          </Close>
        </ButtonsContainer>
      }
    >
      <Paragraph size="small" colortype="primary" css={{ whiteSpace: 'pre-line' }}>
        <SubtitleSpan>{formatMessage({ id: 'pages.upload.cancelModal.subtitle' })}</SubtitleSpan>
      </Paragraph>
    </Root>
  );
};
