import MessageMap from 'I18n/i18n';

const es419: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Only English option available',
  },

  SELECTED_LANGUAGE: 'es-419',

  // NEED COPYDECK
  MAIN_PAGE: {
    TITLE: 'Análisis',
    BTGUIDE_SECTION: {
      TITLE: 'Guía de transformación comercial',
      DESCRIPTION: 'Una guía paso a paso para maximizar el valor con BEES.',
      LINK: 'Ir a la guía de transformación comercial',
    },
    REPORT_SECTION: {
      TITLE: 'Informes',
      SUBTITLE: 'Explora informes detallados sobre diferentes temas.',
      BUTTON: 'Mostrar todos los informes',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Vistas disponibles',
      DESCRIPTION: 'Accede a diferentes grupos de datos disponibles para ti.',
      BUTTON: 'Mostrar todas las vistas',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
    BIG_NUMBERS_SECTION: {
      TITLE: 'Puntos destacados del mes a la fecha',
      SUBTITLE: 'KPI del cliente de BEES de un vistazo.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    // NEED COPY DECK
    TITLE: 'Vistas disponibles',
    SEARCHFIELD: {
      PLACEHOLDER: 'Buscar por tipo de usuario',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Cambiar',
    NO_SEGMENT: 'No segment selected',
    VIEW: 'Vista actual:',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Cambiar vista',
    DESCRIPTION: 'Selecciona las opciones para cambiar de vista.',
    WORKSPACE: {
      LABEL: 'Tipo de usuario',
      PLACEHOLDER: 'Seleccionar',
    },
    SEGMENT: {
      LABEL: 'Vista',
      PLACEHOLDER: 'Seleccionar',
    },
    COUNTRY: {
      LABEL: 'País',
      PLACEHOLDER: 'Seleccionar',
    },
    SERVICE_MODEL: {
      LABEL: 'Modelo de negocio',
      PLACEHOLDER: 'Seleccionar',
    },
    VENDOR: {
      LABEL: 'Proveedor',
      PLACEHOLDER: 'Seleccionar',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Cambiar',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'vista',
    PLURAL_TEXT: 'vistas',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Informes',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: 'Ahora estás en la',
      LAST_PART: 'vista',
    },
    REPORTS_QUANTITY: ' informes',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Datos y análisis',
    REPORTS_PAGE_VENDOR: 'Proveedor',
    CONTENT_REPORTS_TAB_VIEW:
      'Mida su rendimiento en todos los pedidos entregados dentro del período seleccionado',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Descripción general global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Algó falló',
    CONTAINER_ERROR_LABEL:
      'Lo lamentamos, estamos teniendo dificultades técnicas. Por favor, vuelve a intentarlo.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Descargar CSV',
    DESCRIPTION: 'Seleccionar el período para la descarga.',
    YEAR_LABEL: 'Año',
    MONTH_LABEL: 'Mes',
    NO_INFO_AVAILABLE:
      'No hay información en relación al período seleccionado. Por favor, intenta con fechas diferentes.',
    CANCEL: 'Cancelar',
    DOWNLOAD: 'Descargar',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Exportar datos',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Tu versión de prueba vencerá pronto',
    FIRST_DESCRIPTION:
      'El archivo CSV que estás a punto de descargar es cortesía de nuestro programa de membresía.',
    SECOND_DESCRIPTION:
      'Para mantener el acceso a datos especiales, hechos a la medida de tu empresa, tendrás que hacer el upgrade hacia Gold Partner.',
    BUTTON: 'DESCARGAR ARCHIVO',
    NO_INFO_AVAILABLE: 'No hay información en relación al período.',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Cómo acceder a un archivo CSV',
    DESCRIPTION:
      '¿No estás familiarizado con los archivos CSV? Aquí una guía de paso por paso acerca de cómo utilizar un archivo CSV.',
    LINK: 'Mostrar guía para archivos CSV',
    PROCEED_BUTTON: 'Proceder y descargar',
  },

  GET_STARTED: {
    LOADING: 'Cargando',
    TITLE: 'Comenzar',
    PARAGRAPH: 'Seleccionar un proveedor para ver su información y datos.',
    NEXT: 'Próximo',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Cambiar',
    TITLE: 'Estas viendo',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Abierto',
    CLEAR: 'Limpiar',
  },
  MONTH: {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  },

  COMMON: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
  },
  LOADING: {
    TITLE: 'Cargando la página',
    PARAGRAPH: 'Estamos preparando la página para ti.',
  },
  SELECT_VENDOR: {
    TITLE: 'Seleccionar un proveedor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Acceso denegado!',
    ERROR_DESCRIPTION: 'Parece que no tienes permiso para acceder a esta página',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Algo salió mal. Por favor, inténtalo de nuevo más tarde.',
    PREPARING_DOWNLOAD: 'Preparando archivo CSV para descargar.',
    SUCCESS_DOWNLOAD: 'Descarga completada con éxito.',
  },

  REVENUE_SILVER_PARTNER: {
    TITLE: 'Datos del negocio de un',
    TITLE_NEXT_LEVEL: 'nivel superior',
    DESCRIPTION:
      'Con nuestros datos premium, podrás descubrir poderosos análisis que desplegarán todo el potencial de tu empresa.',
    BUTTON: 'Transfórmate en un Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusivo para Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS:
      '¡Solicitud enviada con éxito! Nuestro equipo se pondrá en contacto contigo pronto.',
    TOAST_ERROR: 'Algo falló. Por favor, vuelve a intentarlo.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brasil',
    CL: 'Chile',
    CO: 'Colombia',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    ID: 'Indonesia',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Perú',
    PH: 'Filipinas',
    PY: 'Paraguay',
    ZA: 'Sudáfrica',
    CH: 'Switzerland',
    BE: 'Belgium',
    IC: 'Canary Island',
    ES: 'Spain',
    DE: 'Germany',
    GB: 'United Kingdom',
    NL: 'Netherlands',
    GLOBAL_OVERVIEW: 'Descripción general global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Seleccionar país',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Seleccionar modelo de negocio',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Seleccionar proveedor',
    LOADING: 'Cargando...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Recuperando los datos…',
      BODY: 'Paso 1 de 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Cargando informe…',
      BODY: 'Paso 2 de 3',
    },

    LOADING_DATA: {
      TITLE: 'Ya casi estamos',
      BODY: 'Paso 3 de 3 ',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },

  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },

  SWITCH_LOADING: {
    TITLE: 'Cambiar de vista',
    ENTERING: 'Estás ingresando a',
    USER: 'usuario: ',
    PARAGRAPH: 'Espera mientras se carga la vista.',
  },

  BEES_ASSISTANT: {
    DESCRIPTION: '¿Necesitas ayuda con BEES Analytics? Estamos aquí para ayudarte.',
  },

  BIG_NUMBERS: {
    BUTTON: 'Ir al informe detallado',
  },
};

export default es419;
