import { Heading, IconButton, Paragraph } from '@hexa-ui/components';
import { ChevronRight } from '@hexa-ui/icons';

import { CARD_COMPONENT_TEST_IDS as TEST_IDS } from '../../consts/OverviewConsts';
import { CardComponentProps } from '../../types/OverviewTypes';
import * as Styled from './CardComponent.styles';

export const CardComponent = ({
  title,
  count,
  total,
  hasError = false,
  errorMessage,
  percentage,
  isClickable = false,
  handleCardClick,
}: CardComponentProps) => {
  return (
    <Styled.WrapperOverviewCard
      elevated="minimal"
      border="medium"
      data-testid={TEST_IDS.CARD_COMPONENT}>
      <Heading size="H5" data-testid={TEST_IDS.CARD_TITLE}>
        {title}
      </Heading>

      {hasError ? (
        <CardErrorComponent errorMessage={errorMessage} />
      ) : (
        <Styled.WrapperOverviewInfoContainer data-testid={TEST_IDS.INFO_CONTAINER}>
          <Styled.WrapperOverviewInfo data-testid={TEST_IDS.INFO}>
            <Paragraph
              size="small"
              colortype="secondary"
              weight="medium"
              data-testid={TEST_IDS.COUNT}>
              {count}
            </Paragraph>
            <Paragraph size="small" colortype="secondary" data-testid={TEST_IDS.TOTAL}>
              {total}
            </Paragraph>
          </Styled.WrapperOverviewInfo>
          <Styled.WrapperOverviewInfoPercentage data-testid={TEST_IDS.PERCENTAGE_INFO}>
            <Heading size="H2" data-testid={TEST_IDS.PERCENTAGE}>
              {percentage}
            </Heading>
            {isClickable && handleCardClick && (
              <IconButton
                variant="inherit"
                icon={ChevronRight}
                data-testid={TEST_IDS.CHEVRON_BUTTON}
                onClick={() => handleCardClick()}
              />
            )}
          </Styled.WrapperOverviewInfoPercentage>
        </Styled.WrapperOverviewInfoContainer>
      )}
    </Styled.WrapperOverviewCard>
  );
};

const CardErrorComponent = ({ errorMessage }) => {
  return (
    <Paragraph size="small" data-testid="error-message">
      {errorMessage}
    </Paragraph>
  );
};
