import { PlatformUserServiceInterface } from 'deliver-portal-commons';

import { PageProps } from '../../../app/router/viewModel/routerViewModel.types';

export function hasPagePermission(
  page: PageProps,
  platformUser: PlatformUserServiceInterface,
): boolean {
  if (!page.permissions) {
    return true;
  }

  return platformUser.hasPermission(page.permissions, 'oneScope');
}
