export type IExperimentResponseContent = Omit<
  IExperiment,
  "startDate" | "endDate"
> & {
  startDate: string;
  endDate: string;
};

export interface IExperimentResponse {
  content: IExperimentResponseContent[];
  pagination: {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface IExperimentAudience {
  externalId: string;
  name: string;
  estimatedPocTargeted?: string | number;
}
export interface IExperimentMetrics {
  primaryMetric: string;
  secondaryMetrics: string[];
  guardrails: IGuardrails;
}

export interface IGuardrails {
  customGuardrails: string[];
  requiredGuardrails: string[];
}

export type ExperimentStatusProp =
  | "DRAFT"
  | "STARTING"
  | "READY"
  | "STARTED"
  | "CANCELLED"
  | "FINISHED"
  | "IN_VALIDATION"
  | "WAITING_APPROVAL"
  | "APPROVED"
  | "REJECTED"
  | "ERROR";

export enum ExperimentStatusList {
  DRAFT = "DRAFT",
  STARTING = "STARTING",
  READY = "READY",
  STARTED = "STARTED",
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
  IN_VALIDATION = "IN_VALIDATION",
  ERROR = "ERROR",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface IBasicInformation {
  name: string;
  description: string;
  hypothesis: string;
  documentationLink: string;
  country: string;
  primaryMetric: string;
  secondaryMetrics: string[];
  customGuardrails?: string[];
  requiredGuardrails?: string[];
  externalKey?: string;
}

export interface IAudienceSelection {
  audiences: {
    externalId: string;
    name: string;
  };
  countryAsAudience: boolean;
  trafficPercentage?: number;
  country?: string;
}

export interface IFormVariation {
  id?: string;
  name: string;
  description: string;
  type: string; // only to form control
  settingOptions: string[]; // only to form control
  json: string; // Represents sduiJson or configObject
  viewers: string[];

  androidStringKey?: string; // copy object
  stringValue?: string; // copy object
  containerId?: string; // sdui object
}

export interface IExperimentationVariation {
  id?: string;
  name: string;
  description: string;
  configObject?: string;
  copy?: {
    androidStringKey: string;
    stringValue: string;
  };
  sdui?: {
    containerId: string;
    sduiJson: string;
    jsonUrl?: string;
  };
  viewers?: string[];
  trafficPercentage?: number;
}

export interface INewExperimentForm {
  step1?: IBasicInformation;
  step2?: IAudienceSelection;
  step3?: IExperimentationVariation[];
}

export interface IExperiment {
  id: string;
  name: string;
  description: string;
  hypothesis: string;
  countryAsAudience: boolean;
  countryTrafficPercentage?: number;
  status?: ExperimentStatusProp | JSX.Element;
  documentationLink: string;
  country: string;
  vendorId: string;
  externalId?: string;
  externalKey?: string;
  startDate?: Date;
  endDate?: Date;
  primaryMetrics: string[];
  secondaryMetrics: string[];
  guardrailsIds: string[];
  audiences: IExperimentationAudience[];
  variations: IExperimentationVariation[];
  result?: string;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
  confidenceLevel?: string;
  lastApprovalRequestId?: string;
  lastApprovalRequestStatus?: string;
  lastApprovalRequestComments?: string;
  lastApprovalRequestUpdatedAt?: Date;
}

export type ExperimentListTable = Pick<
  IExperiment,
  "id" | "name" | "country" | "startDate" | "endDate" | "status"
> & {
  action: JSX.Element;
};

export type ExperimentErrorResponse = {
  code: string;
  message: string;
};

export type ExportExperiment = Omit<IExperiment, "externalId" | "status"> & {
  status: string;
  code?: string[];
  errors?: ExperimentErrorResponse[];
  timestamp?: string;
};

export interface IExperimentationAudience {
  externalId: string;
  name: string;
  trafficPercentage?: number;
  estimatedPocTargeted?: number;
}

export interface IExperimentRequest {
  id?: string;
  name: string;
  description: string;
  hypothesis: string;
  documentationLink: string;
  country: string;
  primaryMetrics: string[];
  secondaryMetrics: string[];
  guardrailsIds: string[];
  countryAsAudience?: boolean;
  countryTrafficPercentage?: number;
  audiences?: IExperimentationAudience[];
  variations?: IExperimentationVariation[];
  externalId?: string;
  vendorId?: string;
  externalKey?: string;
  startDate?: Date;
  endDate?: Date;
  result?: string;
}

export type Countries = {
  [key: string]: {
    label: string;
    code: string;
  };
};

export type ExperimentInputErrors = {
  step1?: string[];
  step2?: string[];
  step3?: {
    errors?: boolean;
    variant_a: string[];
    variant_b: string[];
    variant_c: string[];
    variant_d: string[];
    variant_e: string[];
    variant_f: string[];
  };
};
