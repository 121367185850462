import React from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  optimizelyKey: string;
  segmentKey: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  optimizelyKey: '',
  segmentKey: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = (): EnvConfig => {
  const context = React.useContext(EnvContext);
  if (!context) {
    throw new Error('useEnvContext must be used within an EnvProvider');
  }
  return context;
};

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): React.ReactElement => {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};
