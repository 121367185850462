export class LanguageUtils {
  private readonly displayNames: Intl.DisplayNames;
  constructor(preferredLanguage: string) {
    this.displayNames = new Intl.DisplayNames([preferredLanguage], { type: 'language' });
  }

  splitLanguageCode(languageCode: string): { language: string; country: string } {
    const [language] = this.displayNames.of(languageCode).split('(', 2);
    const [, country] = languageCode.split('-');
    return { language: language.trim(), country };
  }

  getFormattedLanguageName(language: string, country: string): string {
    const formattedLanguageName = language.trim().concat(` (${country})`);
    return formattedLanguageName.charAt(0).toUpperCase() + formattedLanguageName.slice(1);
  }

  getFormattedLanguageNameByLangCode(languageCode: string): string {
    const { language, country } = this.splitLanguageCode(languageCode);
    return this.getFormattedLanguageName(language, country);
  }
}
