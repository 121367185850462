import { axiosClient } from "@shared/api";
import { IPutGroupsParams } from "identity-admin-contracts/user-management";

export const putGroups = ({
  id,
  appName,
  country,
  groups,
  userRole,
  userTeam,
  reason,
}: IPutGroupsParams) =>
  axiosClient.put(
    `/users/${id}/groups`,
    {
      userRole,
      userTeam,
      reason,
      groups,
    },
    {
      params: {
        appName,
        country,
      },
    },
  );
