import { DEFAULT_LANGUAGE } from '@config/constants';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { ReactElement, ReactNode, useEffect } from 'react';
import { MessageFormatElement, IntlProvider as NativeProvider } from 'react-intl';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import koKR from './languages/ko-KR';
import ptBR from './languages/pt-BR';

type IIntlProvider = {
  children: ReactNode;
};

export const messages: MessageInput = {
  'en-US': enUS,
  'es-419': es419,
  'pt-BR': ptBR,
  'ko-KR': koKR,
};

export function IntlProvider({ children }: Readonly<IIntlProvider>): ReactElement {
  const { preferredLanguage, isLoading } = usePreferredLanguageV2();

  const mergedMessages = mergeMessages(messages, preferredLanguage);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem('catalogAdmin-preferredLanguage', preferredLanguage ?? DEFAULT_LANGUAGE);
    }
  }, [isLoading, preferredLanguage]);

  return (
    <NativeProvider
      locale={preferredLanguage ?? DEFAULT_LANGUAGE}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
}

export const flattenObject = (ob: Record<string, any>) => {
  const toReturn: Record<string, any> = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mergeMessages = (
  messages: MessageInput,
  selectedLocale: string
): Record<string, MessageFormatElement[]> => {
  const defaultMessages = flattenObject(messages[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messages[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
