import { Heading, Paragraph } from '@hexa-ui/components';
import { ScaleHeading, ScaleParagraph } from './Typography.styles';

export const StyledParagraph = (
    {
        title,
        size,
        ...props
    }: any
)
    : JSX.Element => {
    return (
        <ScaleParagraph size={size} {...props}>
            <Paragraph size={size}>
                {title}
            </Paragraph>
        </ScaleParagraph>
    )
}

const StyledHeading =
    (
        {
            size = "H3",
            title,
            ...props
        }: any
    )
        : JSX.Element => {
        return (
            <ScaleHeading >
                <Heading size={size} {...props}>
                    {title}
                </Heading>
            </ScaleHeading>
        );
    };

export default StyledHeading;