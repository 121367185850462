/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface CsvDownloadCanceled {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface CsvDownloadRequestFailed {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The month filtered to download the csv. Samples: January, February, March.
	 */
	csv_download_month: string | null
	/**
	 * The year filtered to download the csv. Sample: 2022, 2021
	 */
	csv_download_year: number | null
	/**
	 * The reason of failure in the given context. Samples: "Something went wrong"
	 */
	failure_reason: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface CsvDownloadRequestSuccessful {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The month filtered to download the csv. Samples: January, February, March.
	 */
	csv_download_month: string | null
	/**
	 * The year filtered to download the csv. Sample: 2022, 2021
	 */
	csv_download_year: number | null
	/**
	 * The total time loading for the CSV download.
	 */
	load_time: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface CsvDownloadRequested {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The month filtered to download the csv. Samples: January, February, March.
	 */
	csv_download_month: string | null
	/**
	 * The year filtered to download the csv. Sample: 2022, 2021
	 */
	csv_download_year: number | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface CsvDownloadStarted {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface ChangePartnerButtonClicked {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface DataLoaded {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface DataLoadedRenderingProcess {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * The report name (EN copy) the user going to
	 */
	destiny_report_label?: string | null
	/**
	 * The unique report tracking id for the workspace the user is going to
	 */
	destiny_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	destiny_theme_label?: string | null
	/**
	 * The unique theme tracking id for the workspace the user is going to
	 */
	destiny_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is going to
	 */
	destiny_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is going to
	 */
	destiny_workspace_tracking_id?: string | null
	/**
	 * The element text on the front end with the EN copy
	 */
	element_label?: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
	 */
	element_location_label?: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
	 */
	element_location_name?: string | null
	/**
	 * The category of the object that anchors the button
	 */
	element_location_type?: string | null
	/**
	 * The element name on the back end
	 */
	element_name?: string | null
	/**
	 * The element position indicates how far is it on the order they are presented
	 */
	element_position?: number | null
	/**
	 * The element type
	 */
	element_type?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface FileProcessed {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * The name of the dataset being processed.
	 */
	dataset_name?: string | null
	/**
	 * This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
	 */
	file_format?: string | null
	/**
	 * This identifies rather the process is a Download (starting point) or an Upload (conclusion point)
	 */
	file_process_type?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The time it takes from a click until the download starts or until the upload ends
	 */
	processing_duration?: string | null
	/**
	 * Indicates if the process was succesful
	 */
	processing_status?: boolean | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface FiltersInteraction {
	/**
	 * What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.
	 */
	analytics_component_category: string | null
	/**
	 * The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'
	 */
	analytics_component_id: string | null
	/**
	 * What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL
	 */
	analytics_component_name: string | null
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'
	 */
	filter_selected: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The filter with which the user searches for words in the given context
	 */
	searched_word: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface HeatmapInteraction {
	/**
	 * What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.
	 */
	analytics_component_category: string | null
	/**
	 * What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL
	 */
	analytics_component_name: string | null
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface InitRenderingProcess {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface InteractionRendered {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface InteractionRenderedRenderingProcess {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface KpiFullDocumentationButtonClicked {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface KpiInfoDetailsExpasion {
	/**
	 * Indicates whether the user expanded or contracted the info. Possible values are: 'EXPANDED', 'CONTRACTED'
	 */
	action: string | null
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * A number that indicates how far along the list the KPI being expanded is
	 */
	item_position: number | null
	/**
	 * The name of the KPI being expanded or contracted
	 */
	kpi_name: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface KpiPopUpClosed {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface KpiPopUpOpened {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface LinkOutOptionClicked {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button text on the front end with localization
	 */
	button_label: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface MenuInteraction {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * Top level Menu Category in the given context. Permitted Values:ADDITIONAL_REPORTS, BEHAVIOR, CLUB_B, KPI_DOCUMENTATION, PURCHASE DRIVERS, REAL_TIME_REPORT,FORCE,BUSINESS, REPORTS, SM_OVERVIEW, SM_POC_VISITS, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, SM_TASKS, SM_PHOTO_TASKS
	 */
	menu_category: string | null
	/**
	 * The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Behaviour.
	 */
	menu_subcategory: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface ModalClosed {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The modal name on the back end
	 */
	modal_name: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface ModalConfirmation {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The button name on the back end
	 */
	button_name: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The modal name on the back end
	 */
	modal_name: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface PageViewed {
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr…
	 */
	URL?: string | null
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The category of the page. (Exemple - Reports)
	 */
	category?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * Check if the vendor has access to Business Transformation (BT) Guide
	 */
	has_bt_guide?: boolean | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Name of the page. (Order, Customer, Behavior, Revenue)
	 */
	name?: string | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * Path portion of the URL of the page.
	 */
	path?: string | null
	/**
	 * The language the user has set at preferred
	 */
	preferred_language?: string | null
	/**
	 * Full URL of the previous page. Equivalent to document.referrer from the DOM API
	 */
	referrer?: string | null
	/**
	 * The count of how many reports are available on the page
	 */
	report_count?: number | null
	/**
	 * The count of how many themes are available on the page
	 */
	theme_count?: number | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
	/**
	 * The count of how many workspaces are available on the page
	 */
	workspace_count?: number | null
}
export interface PartnerInsightsAppLoaded {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface PartnerReportsViewed {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface PartnerSelected {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface RederingStarted {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface RederingStartedRenderingProcess {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface ReportRendered {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The report name (EN copy) the user is currently in
	 */
	origin_report_label?: string | null
	/**
	 * The unique report tracking id for the report the user is currently in
	 */
	origin_report_tracking_id?: string | null
	/**
	 * The Theme name (EN copy) the user is currently in
	 */
	origin_theme_label?: string | null
	/**
	 * The unique theme tracking id for the theme the user is currently in
	 */
	origin_theme_tracking_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface ReportRenderedRenderingProcess {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
	 */
	report_subtype?: string | null
	/**
	 * The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
	 */
	report_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface SearchApplied {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name?: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event?: boolean | null
	/**
	 * The number of results from that search
	 */
	number_of_results?: number | null
	/**
	 * Event occurrence identifier
	 */
	occurence_id?: string | null
	/**
	 * The workspace name (EN copy) the user is currently in
	 */
	origin_workspace_label?: string | null
	/**
	 * The unique workspace tracking id for the workspace the user is currently in
	 */
	origin_workspace_tracking_id?: string | null
	/**
	 * The Page title on the front end with EN copy
	 */
	page_label?: string | null
	/**
	 * The name of the page where the event was triggered on the backend
	 */
	page_name?: string | null
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url?: string | null
	/**
	 * The string that was searched
	 */
	search_query?: string | null
	/**
	 * The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
	 */
	user_affiliation?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country?: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email?: string | null
	/**
	 * The unique id of the user that performed or caused this action
	 */
	user_id?: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country?: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model?: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name?: string | null
}
export interface SearchInteraction {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * How many results were listed on after the search was triggered
	 */
	results_count: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * The text that triggered the search
	 */
	search_text: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}
export interface TabInteraction {
	/**
	 * The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
	 */
	business_model?: string | null
	/**
	 * The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
	 */
	country?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
	 */
	manufacturer_id?: string | null
	/**
	 * The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
	 */
	manufacturer_name?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
	 */
	screen_name?: string | null
	/**
	 * Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
	 */
	tab_name: string | null
	/**
	 * The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
	 */
	user_type?: string | null
	/**
	 * The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
	 */
	vendor_id?: string | null
	/**
	 * The name of vendor. Samples: Modelez, Piracanjuba
	 */
	vendor_name?: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef CsvDownloadCanceled
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef CsvDownloadRequestFailed
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} csv_download_month - The month filtered to download the csv. Samples: January, February, March.
 * @property {number | null} csv_download_year - The year filtered to download the csv. Sample: 2022, 2021
 * @property {string | null} failure_reason - The reason of failure in the given context. Samples: "Something went wrong"
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef CsvDownloadRequestSuccessful
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} csv_download_month - The month filtered to download the csv. Samples: January, February, March.
 * @property {number | null} csv_download_year - The year filtered to download the csv. Sample: 2022, 2021
 * @property {string | null} load_time - The total time loading for the CSV download.
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef CsvDownloadRequested
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} csv_download_month - The month filtered to download the csv. Samples: January, February, March.
 * @property {number | null} csv_download_year - The year filtered to download the csv. Sample: 2022, 2021
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef CsvDownloadStarted
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef ChangePartnerButtonClicked
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef DataLoaded
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef DataLoadedRenderingProcess
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef ElementClicked
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {string | null} [destiny_report_label] - The report name (EN copy) the user going to
 * @property {string | null} [destiny_report_tracking_id] - The unique report tracking id for the workspace the user is going to
 * @property {string | null} [destiny_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [destiny_theme_tracking_id] - The unique theme tracking id for the workspace the user is going to
 * @property {string | null} [destiny_workspace_label] - The workspace name (EN copy) the user is going to
 * @property {string | null} [destiny_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is going to
 * @property {string | null} [element_label] - The element text on the front end with the EN copy
 * @property {string | null} [element_location_label] - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
 * @property {string | null} [element_location_name] - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
 * @property {string | null} [element_location_type] - The category of the object that anchors the button
 * @property {string | null} [element_name] - The element name on the back end
 * @property {number | null} [element_position] - The element position indicates how far is it on the order they are presented
 * @property {string | null} [element_type] - The element type
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef FileProcessed
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {string | null} [dataset_name] - The name of the dataset being processed.
 * @property {string | null} [file_format] - This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
 * @property {string | null} [file_process_type] - This identifies rather the process is a Download (starting point) or an Upload (conclusion point)
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [processing_duration] - The time it takes from a click until the download starts or until the upload ends
 * @property {boolean | null} [processing_status] - Indicates if the process was succesful
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef FiltersInteraction
 * @property {string | null} analytics_component_category - What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.
 * @property {string | null} analytics_component_id - The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'
 * @property {string | null} analytics_component_name - What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} filter_selected - The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} searched_word - The filter with which the user searches for words in the given context
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef HeatmapInteraction
 * @property {string | null} analytics_component_category - What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.
 * @property {string | null} analytics_component_name - What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef InitRenderingProcess
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InteractionRendered
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InteractionRenderedRenderingProcess
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef KpiFullDocumentationButtonClicked
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef KpiInfoDetailsExpasion
 * @property {string | null} action - Indicates whether the user expanded or contracted the info. Possible values are: 'EXPANDED', 'CONTRACTED'
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {number | null} item_position - A number that indicates how far along the list the KPI being expanded is
 * @property {string | null} kpi_name - The name of the KPI being expanded or contracted
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef KpiPopUpClosed
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef KpiPopUpOpened
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef LinkOutOptionClicked
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_label - The button text on the front end with localization
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} menu_category - Top level Menu Category in the given context. Permitted Values:ADDITIONAL_REPORTS, BEHAVIOR, CLUB_B, KPI_DOCUMENTATION, PURCHASE DRIVERS, REAL_TIME_REPORT,FORCE,BUSINESS, REPORTS, SM_OVERVIEW, SM_POC_VISITS, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, SM_TASKS, SM_PHOTO_TASKS
 * @property {string | null} menu_subcategory - The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Behaviour.
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef ModalClosed
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} modal_name - The modal name on the back end
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef ModalConfirmation
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} button_name - The button name on the back end
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} modal_name - The modal name on the back end
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef PageViewed
 * @property {string | null} [URL] - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr…
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [category] - The category of the page. (Exemple - Reports)
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {boolean | null} [has_bt_guide] - Check if the vendor has access to Business Transformation (BT) Guide
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [name] - Name of the page. (Order, Customer, Behavior, Revenue)
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [path] - Path portion of the URL of the page.
 * @property {string | null} [preferred_language] - The language the user has set at preferred
 * @property {string | null} [referrer] - Full URL of the previous page. Equivalent to document.referrer from the DOM API
 * @property {number | null} [report_count] - The count of how many reports are available on the page
 * @property {number | null} [theme_count] - The count of how many themes are available on the page
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 * @property {number | null} [workspace_count] - The count of how many workspaces are available on the page
 */
/**
 * @typedef PartnerInsightsAppLoaded
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef PartnerReportsViewed
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef PartnerSelected
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef RederingStarted
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RederingStartedRenderingProcess
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef ReportRendered
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_report_label] - The report name (EN copy) the user is currently in
 * @property {string | null} [origin_report_tracking_id] - The unique report tracking id for the report the user is currently in
 * @property {string | null} [origin_theme_label] - The Theme name (EN copy) the user is currently in
 * @property {string | null} [origin_theme_tracking_id] - The unique theme tracking id for the theme the user is currently in
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ReportRenderedRenderingProcess
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [report_subtype] - The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.
 * @property {string | null} [report_type] - The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef SearchApplied
 * @property {string | null} [app_display_name] - The current name of the app where the event was triggered
 * @property {string | null} [app_id] - The unique id of the app where the event was triggered
 * @property {boolean | null} [is_core_event] - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {number | null} [number_of_results] - The number of results from that search
 * @property {string | null} [occurence_id] - Event occurrence identifier
 * @property {string | null} [origin_workspace_label] - The workspace name (EN copy) the user is currently in
 * @property {string | null} [origin_workspace_tracking_id] - The unique workspace tracking id for the workspace the user is currently in
 * @property {string | null} [page_label] - The Page title on the front end with EN copy
 * @property {string | null} [page_name] - The name of the page where the event was triggered on the backend
 * @property {string | null} [page_url] - The URL of the page where the event was triggered
 * @property {string | null} [search_query] - The string that was searched
 * @property {string | null} [user_affiliation] - The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)
 * @property {string | null} [user_country] - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} [user_email] - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} [user_id] - The unique id of the user that performed or caused this action
 * @property {string | null} [vendor_country] - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_display_name] - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_service_model] - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [vendor_tier_name] - The tier of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef SearchInteraction
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {number | null} results_count - How many results were listed on after the search was triggered
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} search_text - The text that triggered the search
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */
/**
 * @typedef TabInteraction
 * @property {string | null} [business_model] - The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', "DTAAS'
 * @property {string | null} [country] - The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'
 * @property {string | null} [manufacturer_id] - The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.
 * @property {string | null} [manufacturer_name] - The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'
 * @property {string | null} [screen_name] - The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION
 * @property {string | null} tab_name - Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES
 * @property {string | null} [user_type] - The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.
 * @property {string | null} [vendor_id] - The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.
 * @property {string | null} [vendor_name] - The name of vendor. Samples: Modelez, Piracanjuba
 */

/**
 * Triggered when the user ends the CSV download journey by clicking on the "cancel" button or the "x" icon inside the modal
 *
 * @param {CsvDownloadCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvDownloadCanceled(
	props: CsvDownloadCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user ends the CSV download journey by clicking on the "cancel" button or the "x" icon inside the modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'CSV Download Canceled',
		type: 'object',
	}
	const message = {
		event: 'CSV Download Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'CSV Download Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the service returns an error response after the CSV Download Requested
 *
 * @param {CsvDownloadRequestFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvDownloadRequestFailed(
	props: CsvDownloadRequestFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the service returns an error response after the CSV Download Requested',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_month: {
						description:
							'The month filtered to download the csv. Samples: January, February, March.',
						enum: [
							'APRIL',
							'AUGUST',
							'DECEMBER',
							'FEBRUARY',
							'JANUARY',
							'JULY',
							'JUNE',
							'MARCH',
							'MAY',
							'NOVEMBER',
							'OCTOBER',
							'SEPTEMBER',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_year: {
						description:
							'The year filtered to download the csv. Sample: 2022, 2021',
						type: ['integer', 'null'],
					},
					failure_reason: {
						description:
							'The reason of failure in the given context. Samples: "Something went wrong"',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: [
					'csv_download_month',
					'csv_download_year',
					'failure_reason',
					'tab_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'CSV Download Request Failed',
		type: 'object',
	}
	const message = {
		event: 'CSV Download Request Failed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'CSV Download Request Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the service returns a success response after the CSV Download Requested
 *
 * @param {CsvDownloadRequestSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvDownloadRequestSuccessful(
	props: CsvDownloadRequestSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the service returns a success response after the CSV Download Requested',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_month: {
						description:
							'The month filtered to download the csv. Samples: January, February, March.',
						enum: [
							'APRIL',
							'AUGUST',
							'DECEMBER',
							'FEBRUARY',
							'JANUARY',
							'JULY',
							'JUNE',
							'MARCH',
							'MAY',
							'NOVEMBER',
							'OCTOBER',
							'SEPTEMBER',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_year: {
						description:
							'The year filtered to download the csv. Sample: 2022, 2021',
						type: ['integer', 'null'],
					},
					load_time: {
						description: 'The total time loading for the CSV download.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: [
					'csv_download_month',
					'csv_download_year',
					'load_time',
					'tab_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'CSV Download Request Successful',
		type: 'object',
	}
	const message = {
		event: 'CSV Download Request Successful',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'CSV Download Request Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects a date on the CSV download journey and clicks on the "next" button to proceed with download
 *
 * @param {CsvDownloadRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvDownloadRequested(
	props: CsvDownloadRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects a date on the CSV download journey and clicks on the "next" button to proceed with download',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_month: {
						description:
							'The month filtered to download the csv. Samples: January, February, March.',
						enum: [
							'APRIL',
							'AUGUST',
							'DECEMBER',
							'FEBRUARY',
							'JANUARY',
							'JULY',
							'JUNE',
							'MARCH',
							'MAY',
							'NOVEMBER',
							'OCTOBER',
							'SEPTEMBER',
							null,
						],
						type: ['string', 'null'],
					},
					csv_download_year: {
						description:
							'The year filtered to download the csv. Sample: 2022, 2021',
						type: ['integer', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['csv_download_month', 'csv_download_year', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'CSV Download Requested',
		type: 'object',
	}
	const message = {
		event: 'CSV Download Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'CSV Download Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the CSV download journey by clicking on the "CSV" button on the reports page
 *
 * @param {CsvDownloadStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvDownloadStarted(
	props: CsvDownloadStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the CSV download journey by clicking on the "CSV" button on the reports page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'CSV Download Started',
		type: 'object',
	}
	const message = {
		event: 'CSV Download Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'CSV Download Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Change" button at the top right corner
 *
 * @param {ChangePartnerButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePartnerButtonClicked(
	props: ChangePartnerButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "Change" button at the top right corner',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Change Partner Button Clicked',
		type: 'object',
	}
	const message = {
		event: 'Change Partner Button Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Change Partner Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered by PowerBI when the current report data is fully loaded
 *
 * @param {DataLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataLoaded(
	props: DataLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered by PowerBI when the current report data is fully loaded',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Data Loaded',
		type: 'object',
	}
	const message = {
		event: 'Data Loaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Data Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered by PowerBI when the current report data is fully loaded
 *
 * @param {DataLoadedRenderingProcess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataLoadedRenderingProcess(
	props: DataLoadedRenderingProcess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered by PowerBI when the current report data is fully loaded',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Data Loaded Rendering Process',
		type: 'object',
	}
	const message = {
		event: 'Data Loaded Rendering Process',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Data Loaded Rendering Process',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The general event that is triggered when a users clicks on an element
 *
 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	props: ElementClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'The general event that is triggered when a users clicks on an element',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					destiny_report_label: {
						description: 'The report name (EN copy) the user going to',
						type: ['string', 'null'],
					},
					destiny_report_tracking_id: {
						description:
							'The unique report tracking id for the workspace the user is going to',
						type: ['string', 'null'],
					},
					destiny_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					destiny_theme_tracking_id: {
						description:
							'The unique theme tracking id for the workspace the user is going to',
						type: ['string', 'null'],
					},
					destiny_workspace_label: {
						description: 'The workspace name (EN copy) the user is going to',
						type: ['string', 'null'],
					},
					destiny_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is going to',
						type: ['string', 'null'],
					},
					element_label: {
						description: 'The element text on the front end with the EN copy',
						type: ['string', 'null'],
					},
					element_location_label: {
						description:
							'The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name',
						type: ['string', 'null'],
					},
					element_location_name: {
						description:
							'The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name',
						type: ['string', 'null'],
					},
					element_location_type: {
						description: 'The category of the object that anchors the button',
						type: ['string', 'null'],
					},
					element_name: {
						description: 'The element name on the back end',
						type: ['string', 'null'],
					},
					element_position: {
						description:
							'The element position indicates how far is it on the order they are presented',
						type: ['integer', 'null'],
					},
					element_type: {
						description: 'The element type',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Element Clicked',
		type: 'object',
	}
	const message = {
		event: 'Element Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Element Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The general event that is triggered when a file process is completed on the application side. Ex. Download Started, Upload completed.
 *
 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileProcessed(
	props: FileProcessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'The general event that is triggered when a file process is completed on the application side. Ex. Download Started, Upload completed.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					dataset_name: {
						description: 'The name of the dataset being processed.',
						type: ['string', 'null'],
					},
					file_format: {
						description:
							"This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.",
						type: ['string', 'null'],
					},
					file_process_type: {
						description:
							'This identifies rather the process is a Download (starting point) or an Upload (conclusion point)',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					processing_duration: {
						description:
							'The time it takes from a click until the download starts or until the upload ends',
						type: ['string', 'null'],
					},
					processing_status: {
						description: 'Indicates if the process was succesful',
						type: ['boolean', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Processed',
		type: 'object',
	}
	const message = {
		event: 'File Processed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Processed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user interacts with a filter component










 *
 * @param {FiltersInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filtersInteraction(
	props: FiltersInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user interacts with a filter component\n\n\n\n\n\n\n\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					analytics_component_category: {
						description:
							'What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.',
						type: ['string', 'null'],
					},
					analytics_component_id: {
						description:
							"The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'",
						type: ['string', 'null'],
					},
					analytics_component_name: {
						description:
							'What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL',
						type: ['string', 'null'],
					},
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					filter_selected: {
						description:
							"The filter with which the user interacts in the given context. Permitted Values: 'MTD', '30_DAYS', '90_DAYS', 'MOM', 'PER_POC', 'PER_ORDER', 'SKU', 'STATE', 'DC'",
						enum: [
							'30_DAYS',
							'90_DAYS',
							'DC',
							'MOM',
							'MTD',
							'PER_ORDER',
							'PER_POC',
							'SKU',
							'STATE',
							null,
						],
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					searched_word: {
						description:
							'The filter with which the user searches for words in the given context',
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: [
					'analytics_component_category',
					'analytics_component_id',
					'analytics_component_name',
					'filter_selected',
					'searched_word',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Filters Interaction',
		type: 'object',
	}
	const message = {
		event: 'Filters Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Filters Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user interacts with a heatmap by zooming in or out









 *
 * @param {HeatmapInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function heatmapInteraction(
	props: HeatmapInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user interacts with a heatmap by zooming in or out\n\n\n\n\n\n\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					analytics_component_category: {
						description:
							'What is the category of the analyics component: CHART, KPI, HEATMAP, TABLE.',
						type: ['string', 'null'],
					},
					analytics_component_name: {
						description:
							'What is the name of the analytics_component in the given context. Samples: ORDERS_ORDERS_DELIVERED_ALL_DAILY_TOTAL',
						type: ['string', 'null'],
					},
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['analytics_component_category', 'analytics_component_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Heatmap Interaction',
		type: 'object',
	}
	const message = {
		event: 'Heatmap Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Heatmap Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a report starts to be loaded, it can happen by a tab/sidemenu click or direct page loading
 *
 * @param {InitRenderingProcess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function initRenderingProcess(
	props: InitRenderingProcess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when a report starts to be loaded, it can happen by a tab/sidemenu click or direct page loading',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'INIT  Rendering Process',
		type: 'object',
	}
	const message = {
		event: 'INIT  Rendering Process',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'INIT  Rendering Process',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs an action that results in a new render
 *
 * @param {InteractionRendered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function interactionRendered(
	props: InteractionRendered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user performs an action that results in a new render',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Interaction Rendered',
		type: 'object',
	}
	const message = {
		event: 'Interaction Rendered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Interaction Rendered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs an action that results in a new render
 *
 * @param {InteractionRenderedRenderingProcess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function interactionRenderedRenderingProcess(
	props: InteractionRenderedRenderingProcess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user performs an action that results in a new render',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Interaction Rendered Rendering Process',
		type: 'object',
	}
	const message = {
		event: 'Interaction Rendered Rendering Process',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Interaction Rendered Rendering Process',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the button that leads to the full KPI documentation page
 *
 * @param {KpiFullDocumentationButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiFullDocumentationButtonClicked(
	props: KpiFullDocumentationButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the button that leads to the full KPI documentation page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'KPI Full Documentation Button Clicked',
		type: 'object',
	}
	const message = {
		event: 'KPI Full Documentation Button Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'KPI Full Documentation Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user expands or contracts KPI info on the pop-up
 *
 * @param {KpiInfoDetailsExpasion} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiInfoDetailsExpasion(
	props: KpiInfoDetailsExpasion,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user expands or contracts KPI info on the pop-up',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action: {
						description:
							"Indicates whether the user expanded or contracted the info. Possible values are: 'EXPANDED', 'CONTRACTED'",
						enum: ['CONTRACTED', 'EXPANDED', null],
						type: ['string', 'null'],
					},
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					item_position: {
						description:
							'A number that indicates how far along the list the KPI being expanded is',
						type: ['integer', 'null'],
					},
					kpi_name: {
						description: 'The name of the KPI being expanded or contracted',
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['action', 'item_position', 'kpi_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'KPI Info Details Expasion',
		type: 'object',
	}
	const message = {
		event: 'KPI Info Details Expasion',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'KPI Info Details Expasion',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the KPI doc Floating Action Button
 *
 * @param {KpiPopUpClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiPopUpClosed(
	props: KpiPopUpClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the KPI doc Floating Action Button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'KPI Pop Up Closed',
		type: 'object',
	}
	const message = {
		event: 'KPI Pop Up Closed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'KPI Pop Up Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user opens up the KPI doc pop-up
 *
 * @param {KpiPopUpOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiPopUpOpened(
	props: KpiPopUpOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user opens up the KPI doc pop-up',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'KPI Pop Up Opened',
		type: 'object',
	}
	const message = {
		event: 'KPI Pop Up Opened',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'KPI Pop Up Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a linkout button at the Additional reports & documentation page.
 *
 * @param {LinkOutOptionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkOutOptionClicked(
	props: LinkOutOptionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a linkout button at the Additional reports & documentation page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_label: {
						description: 'The button text on the front end with localization',
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_label', 'button_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Link-Out Option Clicked',
		type: 'object',
	}
	const message = {
		event: 'Link-Out Option Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Link-Out Option Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user select one of any available tab inside Reports Page.
 *
 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'When user select one of any available tab inside Reports Page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					menu_category: {
						description:
							'Top level Menu Category in the given context. Permitted Values:ADDITIONAL_REPORTS, BEHAVIOR, CLUB_B, KPI_DOCUMENTATION, PURCHASE DRIVERS, REAL_TIME_REPORT,FORCE,BUSINESS, REPORTS, SM_OVERVIEW, SM_POC_VISITS, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, SM_TASKS, SM_PHOTO_TASKS',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'CLUB_B',
							'KPI_DOCUMENTATION',
							'PURCHASE_DRIVERS',
							'REAL_TIME_REPORT',
							'FORCE',
							'BUSINESS',
							'REPORTS',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_TASKS',
							'SM_PHOTO_TASKS',
							null,
						],
						type: ['string', 'null'],
					},
					menu_subcategory: {
						description:
							'The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Behaviour.',
						enum: [null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['menu_category', 'menu_subcategory'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Menu Interaction',
		type: 'object',
	}
	const message = {
		event: 'Menu Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user closes a modal (aborting the current action)
 *
 * @param {ModalClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalClosed(
	props: ModalClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user closes a modal (aborting the current action)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					modal_name: {
						description: 'The modal name on the back end',
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'modal_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Modal Closed',
		type: 'object',
	}
	const message = {
		event: 'Modal Closed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Modal Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user confirms an action in a modal
 *
 * @param {ModalConfirmation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalConfirmation(
	props: ModalConfirmation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user confirms an action in a modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					button_name: {
						description: 'The button name on the back end',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					modal_name: {
						description: 'The modal name on the back end',
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'modal_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Modal Confirmation',
		type: 'object',
	}
	const message = {
		event: 'Modal Confirmation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Modal Confirmation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track the page loaded.
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Event to track the page loaded.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					URL: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr…',
						type: ['string', 'null'],
					},
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					category: {
						description: 'The category of the page. (Exemple - Reports)',
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					has_bt_guide: {
						description:
							'Check if the vendor has access to Business Transformation (BT) Guide',
						type: ['boolean', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					name: {
						description:
							'Name of the page. (Order, Customer, Behavior, Revenue)',
						type: ['string', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					path: {
						description: 'Path portion of the URL of the page. ',
						type: ['string', 'null'],
					},
					preferred_language: {
						description: 'The language the user has set at preferred',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'Full URL of the previous page. Equivalent to document.referrer from the DOM API',
						type: ['string', 'null'],
					},
					report_count: {
						description:
							'The count of how many reports are available on the page',
						type: ['integer', 'null'],
					},
					theme_count: {
						description:
							'The count of how many themes are available on the page',
						type: ['integer', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					workspace_count: {
						description:
							'The count of how many workspaces are available on the page',
						type: ['integer', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the Partner Insights Application (1 time per session)
 *
 * @param {PartnerInsightsAppLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerInsightsAppLoaded(
	props: PartnerInsightsAppLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user accesses the Partner Insights Application (1 time per session) ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Partner Insights App Loaded',
		type: 'object',
	}
	const message = {
		event: 'Partner Insights App Loaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Partner Insights App Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when any page of the Partner Insights Dashboards loads (including after the user login)
 *
 * @param {PartnerReportsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerReportsViewed(
	props: PartnerReportsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when any page of the Partner Insights Dashboards loads (including after the user login)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Partner Reports Viewed',
		type: 'object',
	}
	const message = {
		event: 'Partner Reports Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Partner Reports Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the manager user (category or key account manager) clicks on the 'Next' button after having the country, business model and vendor information selected
 *
 * @param {PartnerSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelected(
	props: PartnerSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when the manager user (category or key account manager) clicks on the 'Next' button after having the country, business model and vendor information selected ",
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Partner Selected',
		type: 'object',
	}
	const message = {
		event: 'Partner Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Partner Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered by PowerBI when the report data is processed and the blocks starts to render
 *
 * @param {RederingStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rederingStarted(
	props: RederingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered by PowerBI when the report data is processed and the blocks starts to render',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Redering Started',
		type: 'object',
	}
	const message = {
		event: 'Redering Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Redering Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered by PowerBI when the report data is processed and the blocks starts to render
 *
 * @param {RederingStartedRenderingProcess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rederingStartedRenderingProcess(
	props: RederingStartedRenderingProcess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered by PowerBI when the report data is processed and the blocks starts to render',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Redering Started Rendering Process',
		type: 'object',
	}
	const message = {
		event: 'Redering Started Rendering Process',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Redering Started Rendering Process',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 *  Triggered by PowerBI when all content from the report finishes loading
 *
 * @param {ReportRendered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportRendered(
	props: ReportRendered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			' Triggered by PowerBI when all content from the report finishes loading',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_report_label: {
						description: 'The report name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_report_tracking_id: {
						description:
							'The unique report tracking id for the report the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_label: {
						description: 'The Theme name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_theme_tracking_id: {
						description:
							'The unique theme tracking id for the theme the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Report Rendered',
		type: 'object',
	}
	const message = {
		event: 'Report Rendered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Report Rendered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 *  Triggered by PowerBI when all content from the report finishes loading
 *
 * @param {ReportRenderedRenderingProcess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportRenderedRenderingProcess(
	props: ReportRenderedRenderingProcess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			' Triggered by PowerBI when all content from the report finishes loading',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					report_subtype: {
						description:
							'The report subtype, the second level of groups to cluster the partner reports. Permitted Values: ALGO_SELLING, BEHAVIOR, CARE, CUSTOMERS, FORCE_EXECUTIVE, FORCE_VISION, GROW, INSIGHTS, ORDERS, REVENUE, REWARDS, REWARDS_CHALLENGES, SALES_OVERVIEW, DIGI_COMMS, BUYERS, REAL_TIME_REPORT, DAILY_REPORT, DIGITAL_TRANSFORMATION, DIGITALIZATION,FORCE_OVERVIEW,PERFORMANCE_RANKING, POC_VISITS, SM_PHOTO_TASKS, GLOBAL_OVERVIEW.',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CARE',
							'CHALLENGES',
							'CUSTOMERS',
							'DIGI_COMMS',
							'FORCE_EXECUTIVE',
							'FORCE_VISION',
							'GROW',
							'INSIGHTS',
							'ORDERS',
							'REAL_TIME_REPORT',
							'REVENUE',
							'REWARDS',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'TASKS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					report_type: {
						description:
							'The report type, the first level our groups to cluster the partner reports. Permitted Values: ADDITIONAL_REPORTS, BUSINESS, BEHAVIOR, CLUB_B, PURCHASE_DRIVERS, REPORTS, DAILY_REPORT, FORCE, SM_PHOTO_TASKS, GLOBAL_OVERVIEW',
						enum: [
							'ADDITIONAL_REPORTS',
							'BEHAVIOR',
							'BUSINESS',
							'CLUB_B',
							'PURCHASE_DRIVERS',
							'REPORTS',
							'FORCE',
							'SM_OVERVIEW',
							'SM_POC_VISITS',
							'SM_TASKS',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'SM_PHOTO_TASKS',
							'GLOBAL_OVERVIEW',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Report Rendered Rendering Process',
		type: 'object',
	}
	const message = {
		event: 'Report Rendered Rendering Process',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Report Rendered Rendering Process',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The general event that is triggered when a search engine is triggered, given a defined bounce
 *
 * @param {SearchApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchApplied(
	props: SearchApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'The general event that is triggered when a search engine is triggered, given a defined bounce',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action',
						type: ['boolean', 'null'],
					},
					number_of_results: {
						description: 'The number of results from that search',
						type: ['integer', 'null'],
					},
					occurence_id: {
						description: 'Event occurrence identifier',
						type: ['string', 'null'],
					},
					origin_workspace_label: {
						description:
							'The workspace name (EN copy) the user is currently in',
						type: ['string', 'null'],
					},
					origin_workspace_tracking_id: {
						description:
							'The unique workspace tracking id for the workspace the user is currently in',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with EN copy',
						type: ['string', 'null'],
					},
					page_name: {
						description:
							'The name of the page where the event was triggered on the backend',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered',
						type: ['string', 'null'],
					},
					search_query: {
						description: 'The string that was searched',
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The user affiliation category (Growth Global, ABI, Seller Same Store, Seller Store Front, Manufacturer)',
						type: ['string', 'null'],
					},
					user_country: {
						description:
							'The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The email address of the user that performed or caused this action, if there is one.',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							'The unique id of the user that performed or caused this action',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search Applied',
		type: 'object',
	}
	const message = {
		event: 'Search Applied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user triggers a search engine
 *
 * @param {SearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchInteraction(
	props: SearchInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user triggers a search engine',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					results_count: {
						description:
							'How many results were listed on after the search was triggered',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					search_text: {
						description: 'The text that triggered the search',
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['results_count', 'search_text', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search Interaction',
		type: 'object',
	}
	const message = {
		event: 'Search Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user interacts with any menu in the application
 *
 * @param {TabInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabInteraction(
	props: TabInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user interacts with any menu in the application',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					business_model: {
						description:
							"The Partner Business Model information in the given context. Permitted Values: '1P', '3P_PRO', \"DTAAS'",
						enum: ['1P', '3P_PRO', 'DTAAS', 'SAAS', null],
						type: ['string', 'null'],
					},
					country: {
						description:
							"The Partner Country information in the given context. Permitted Values: 'AR', 'BR', 'CO', 'DO', 'EC', 'MX', 'PA', 'PE', 'PY', 'ZA'",
						enum: [
							'AR',
							'BR',
							'CO',
							'DO',
							'EC',
							'MX',
							'PA',
							'PE',
							'PY',
							'ZA',
							'BO',
							'ID',
							'PH',
							null,
						],
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the manufacturerID from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description:
							"The name that identifies the manufacturer. Samples. 'Modelez', 'Piracanjuba', 'BRF', 'Nestlé'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: BECOME_PREMIUM, KPI_DOCUMENTATION, PARTNER_INSIGHTS, PARTNER_SELECTION',
						enum: [
							'KPI_DOCUMENTATION',
							'PARTNER_INSIGHTS',
							'PARTNER_SELECTION',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'Top level tab name in the given context. Permitted Values: ALGO_SELLING, BEHAVIOR, BUYERS, CUSTOMERS, DIGI_COMMS, ORDERS, REVENUE, REWARDS_CHALLENGES, REWARDS_SUMMARY, SALES_OVERVIEW, DIGITAL_TRANSFORMATION, DIGITALIZATION, FORCE, FORCE_OVERVIEW, PERFORMANCE_RANKING, POC_VISITS, PURCHASE_DRIVERS, TASKS, DAILY_REPORT, BUYERS_AND_BEHAVIOUR, CLUB_B, BUSINESS, SM_OVERVIEW, SM_POC_VISITS_ROUTINE, SM_POC_VISITS_MONTHLY, SM_TASKS_ROUTINE, SM_TASKS_MONTHLY, SM_SALES, SM_BUYER_DIGITALIZATION, SM_DIGITAL_ENGAGEMENT, REAL_TIME_REPORT, REWARDS,CHALLENGES',
						enum: [
							'ALGO_SELLING',
							'BEHAVIOR',
							'BUYERS',
							'CUSTOMERS',
							'DIGI_COMMS',
							'ORDERS',
							'REVENUE',
							'REWARDS_CHALLENGES',
							'REWARDS_SUMMARY',
							'SALES_OVERVIEW',
							'DIGITAL_TRANSFORMATION',
							'DIGITALIZATION',
							'FORCE',
							'FORCE_OVERVIEW',
							'PERFORMANCE_RANKING',
							'POC_VISITS',
							'PURCHASE_DRIVERS',
							'TASKS',
							'BUYERS_AND_BEHAVIOR',
							'CLUB_B',
							'BUSINESS',
							'SM_OVERVIEW',
							'SM_POC_VISITS_ROUTINE',
							'SM_POC_VISITS_MONTHLY',
							'SM_TASKS_ROUTINE',
							'SM_TASKS_MONTHLY',
							'SM_SALES',
							'SM_BUYER_DIGITALIZATION',
							'SM_DIGITAL_ENGAGEMENT',
							'REAL_TIME_REPORT',
							'REWARDS',
							'CHALLENGES',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							'The persona type triggering the event in te given context. Permitted Values: PARTNER, MANAGER, ADMIN and SALES_MANAGER.',
						enum: [
							'ADMIN',
							'MANAGER',
							'PARTNER',
							'SALES_MANAGER',
							'PARTNER_GLOBAL',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The internal BEES ID that identifies the Partner. It's the vendorId from the Accounts Microservice.",
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The name of vendor. Samples: Modelez, Piracanjuba',
						type: ['string', 'null'],
					},
				},
				required: ['tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Tab Interaction',
		type: 'object',
	}
	const message = {
		event: 'Tab Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Tab Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user ends the CSV download journey by clicking on the "cancel" button or the "x" icon inside the modal
	 *
	 * @param {CsvDownloadCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvDownloadCanceled,
	/**
	 * Triggered when the service returns an error response after the CSV Download Requested
	 *
	 * @param {CsvDownloadRequestFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvDownloadRequestFailed,
	/**
	 * Triggered when the service returns a success response after the CSV Download Requested
	 *
	 * @param {CsvDownloadRequestSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvDownloadRequestSuccessful,
	/**
	 * Triggered when the user selects a date on the CSV download journey and clicks on the "next" button to proceed with download
	 *
	 * @param {CsvDownloadRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvDownloadRequested,
	/**
	 * Triggered when the user starts the CSV download journey by clicking on the "CSV" button on the reports page
	 *
	 * @param {CsvDownloadStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvDownloadStarted,
	/**
	 * Triggered when the user clicks on the "Change" button at the top right corner
	 *
	 * @param {ChangePartnerButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changePartnerButtonClicked,
	/**
	 * Triggered by PowerBI when the current report data is fully loaded
	 *
	 * @param {DataLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataLoaded,
	/**
	 * Triggered by PowerBI when the current report data is fully loaded
	 *
	 * @param {DataLoadedRenderingProcess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataLoadedRenderingProcess,
	/**
	 * The general event that is triggered when a users clicks on an element
	 *
	 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * The general event that is triggered when a file process is completed on the application side. Ex. Download Started, Upload completed.
	 *
	 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileProcessed,
	/**
	 * Triggered when the user interacts with a filter component
	
	
	
	
	
	
	
	
	
	
	 *
	 * @param {FiltersInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filtersInteraction,
	/**
	 * Triggered when the user interacts with a heatmap by zooming in or out
	
	
	
	
	
	
	
	
	
	 *
	 * @param {HeatmapInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	heatmapInteraction,
	/**
	 * Triggered when a report starts to be loaded, it can happen by a tab/sidemenu click or direct page loading
	 *
	 * @param {InitRenderingProcess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	initRenderingProcess,
	/**
	 * Triggered when the user performs an action that results in a new render
	 *
	 * @param {InteractionRendered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	interactionRendered,
	/**
	 * Triggered when the user performs an action that results in a new render
	 *
	 * @param {InteractionRenderedRenderingProcess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	interactionRenderedRenderingProcess,
	/**
	 * Triggered when the user clicks on the button that leads to the full KPI documentation page
	 *
	 * @param {KpiFullDocumentationButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiFullDocumentationButtonClicked,
	/**
	 * Triggered when the user expands or contracts KPI info on the pop-up
	 *
	 * @param {KpiInfoDetailsExpasion} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiInfoDetailsExpasion,
	/**
	 * Triggered when the user clicks on the KPI doc Floating Action Button
	 *
	 * @param {KpiPopUpClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiPopUpClosed,
	/**
	 * Triggered when the user opens up the KPI doc pop-up
	 *
	 * @param {KpiPopUpOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiPopUpOpened,
	/**
	 * Triggered when the user clicks on a linkout button at the Additional reports & documentation page.
	 *
	 * @param {LinkOutOptionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	linkOutOptionClicked,
	/**
	 * When user select one of any available tab inside Reports Page.
	 *
	 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Triggered when the user closes a modal (aborting the current action)
	 *
	 * @param {ModalClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	modalClosed,
	/**
	 * Triggered when the user confirms an action in a modal
	 *
	 * @param {ModalConfirmation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	modalConfirmation,
	/**
	 * Event to track the page loaded.
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggered when the user accesses the Partner Insights Application (1 time per session)
	 *
	 * @param {PartnerInsightsAppLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerInsightsAppLoaded,
	/**
	 * Triggered when any page of the Partner Insights Dashboards loads (including after the user login)
	 *
	 * @param {PartnerReportsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerReportsViewed,
	/**
	 * Triggered when the manager user (category or key account manager) clicks on the 'Next' button after having the country, business model and vendor information selected
	 *
	 * @param {PartnerSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelected,
	/**
	 * Triggered by PowerBI when the report data is processed and the blocks starts to render
	 *
	 * @param {RederingStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rederingStarted,
	/**
	 * Triggered by PowerBI when the report data is processed and the blocks starts to render
	 *
	 * @param {RederingStartedRenderingProcess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rederingStartedRenderingProcess,
	/**
	 *  Triggered by PowerBI when all content from the report finishes loading
	 *
	 * @param {ReportRendered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reportRendered,
	/**
	 *  Triggered by PowerBI when all content from the report finishes loading
	 *
	 * @param {ReportRenderedRenderingProcess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reportRenderedRenderingProcess,
	/**
	 * The general event that is triggered when a search engine is triggered, given a defined bounce
	 *
	 * @param {SearchApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchApplied,
	/**
	 * Triggered when the user triggers a search engine
	 *
	 * @param {SearchInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchInteraction,
	/**
	 * Triggered when the user interacts with any menu in the application
	 *
	 * @param {TabInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tabInteraction,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_29kegCsU1TG9fPtbi8a4kxlaX3l`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
