import BTGuideSection from 'Components/BTGuideSection/BTGuideSection';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import { WorkspaceData } from 'Context/WorkspacesContext/WorkspacesContext';
import { MenuItem } from 'Domain/NewMenuItem';
import { Workspace } from 'Domain/Workspaces';
import React from 'react';
import { useIntl } from 'react-intl';
import { BigNumbersSection } from '../BigNumbersSection/BigNumbersSection';
import { Styled } from '../MainPage.style';
import { ReportsSection } from '../ReportsSection/ReportsSection';
import { WorkspaceSection } from '../WorkspaceSection/WorkspaceSection';

interface MainPageContentProps {
  sortedMenuItems: MenuItem[];
  selectedWorkspace: WorkspaceData | undefined;
  isBTGuideError: boolean;
  btGuideUrl: { url: string };
  AllReportsIsLoading: boolean;
  allWorkspaces: Workspace[];
}

function MainPageContent({
  sortedMenuItems,
  selectedWorkspace,
  isBTGuideError,
  btGuideUrl,
  AllReportsIsLoading,
  allWorkspaces,
}: MainPageContentProps): JSX.Element {
  const { formatMessage } = useIntl();
  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });

  const hasBigNumbersSection =
    (selectedWorkspace?.workspace?.highlightSection?.powerBiConfig?.reportId?.length ?? 0) > 0 &&
    (selectedWorkspace?.workspace?.highlightSection?.powerBiConfig?.workspaceId?.length ?? 0) > 0;

  return (
    <>
      <HeaderContainer
        pageTitle={formatMessage({ id: 'MAIN_PAGE.TITLE' })}
        showAnalyticsAdminButton
      />
      <Styled.Container key={selectedWorkspace?.segment?.name}>
        {hasBigNumbersSection && selectedWorkspace?.workspace?.id && (
          <BigNumbersSection selectedWorkspace={selectedWorkspace} />
        )}
        <Styled.Row hasBTGuide={!isBTGuideError}>
          {selectedWorkspace?.workspace?.id && (
            <ReportsSection
              hasBTGuide={!isBTGuideError}
              workspaceNameSelected={selectedWorkspace.workspace.title[SELECTED_LANGUAGE]}
              segmentIdSelected={selectedWorkspace?.segment?.id}
              allReportsByTheme={sortedMenuItems}
              isLoading={AllReportsIsLoading}
            />
          )}
          {!isBTGuideError && (
            <BTGuideSection selectedWorkspace={selectedWorkspace} btGuideUrl={btGuideUrl.url} />
          )}
        </Styled.Row>
      </Styled.Container>
      <WorkspaceSection
        allWorkspaces={allWorkspaces}
        selectedWorkspace={selectedWorkspace?.workspace?.id || ''}
      />
    </>
  );
}

export default MainPageContent;
