import styled from 'styled-components';

export const WrapperVisitDetailsCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const VisitDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  margin-bottom: ${({ theme }) => theme.measure.space['space-9']};
`;
