import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  z-index: 1000;
  padding: 10px;
`;

export const StyledValueList = styled.ul`
  padding: 0;
  margin: 0px 5px 5px;
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
`;

export const StyledValueItem = styled.li`
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Work Sans', 'San Francisco', Roboto, 'Segoe UI', Helvetica,
    sans-serif;
  &:hover {
    background-color: rgba(128, 0, 128, 0.04);
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 14.5px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Work Sans', 'San Francisco', Roboto, 'Segoe UI', Helvetica,
    sans-serif;
`;
