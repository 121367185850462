import { Card, Paragraph, SkeletonLoader } from '@hexa-ui/components'
import React, { useCallback } from 'react'
import { Edit2 } from '@hexa-ui/icons'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useTranslation } from 'react-i18next'
import { MANAGE_REDEEMABLE, REDEEMABLE_DEFAULT_CPP } from '@/utils/constants'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { useIsEditingAllowed } from '@/routes/zoneConfiguration/hooks/useIsEditingAllowed'
import { CurrentTransactionStatusEnum } from '@/domains/redeemable/RedeemableItem'
import { formatDateTimeZone } from '@/utils/formatDateTimeZone'
import { useUserMetadata } from 'admin-portal-shared-services'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'
import useStyles from './EditDefaultCppCardStyle'
import { AlertsTooltipIcon } from '../RedeemableItemsListV2/components/RedeemableItemsTable/components/AlertsTooltipIcon/AlertsTooltipIcon'

interface EditDefaultCppCardProps {
	onEdit: () => void
	newDefaultCppContent?: {
		defaultCpp: number
		newDefaultCpp: number
		update: {
			status: CurrentTransactionStatusEnum | null
			timestamp: string
			reason: string
		}
	}
	loading?: boolean
}

export const EditDefaultCppCard: React.FC<EditDefaultCppCardProps> = ({
	onEdit,
	newDefaultCppContent,
	loading = true,
}) => {
	const hasDefaultCPPPermission = useScopeAuthorization([REDEEMABLE_DEFAULT_CPP])
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])
	const { isPricePerPointAllowed } = useIsEditingAllowed()
	const canChangeDefaultCPP = isPricePerPointAllowed && hasDefaultCPPPermission
	const { t } = useTranslation()
	const css = useStyles()
	const { data } = usePricePerPointConfiguration()
	const pricePerPoint = useFormatCurrency(data?.pricePerPoint, t('redeemable:SETTINGS.PPP_MISSING_DEFAULT_VALUE'))

	const sentToApprovalText = t('redeemable:SETTINGS.PPP_SENT_TO_APPROVAL', {
		ppp_sent_to_approval: newDefaultCppContent?.newDefaultCpp,
	})

	const userMetaData = useUserMetadata()
	const profile = userMetaData.data?.profile
	const preferences = {
		date: data?.lastModified as string,
		preferredLanguage: profile?.preferredLanguage,
		preferredTimeFormat: profile?.preferredTimeFormat,
		timeZone: profile?.timezone,
	}

	const lastUpdated = formatDateTimeZone(preferences, { hasDay: true, month: 'long' })

	const editDefaultCppButton = useCallback(() => {
		return (
			<button
				type="button"
				className={css.paragraph}
				onClick={onEdit}
				data-testid="redeemable-items-edit-default-cpp-card-button"
			>
				<Edit2 size="medium" fr={undefined} /> {t('redeemable:EDIT.PPP_UPDATE_BUTTON')}
			</button>
		)
	}, [onEdit])

	const defaultCppIcons = useCallback(() => {
		return (
			<>
				{newDefaultCppContent?.update.status === CurrentTransactionStatusEnum.REJECTED && (
					<AlertsTooltipIcon type="rejected" rejectionReason={newDefaultCppContent?.update.reason ?? ''} />
				)}

				{newDefaultCppContent?.update.status === CurrentTransactionStatusEnum.PROCESSING && (
					<AlertsTooltipIcon type="pending" text={sentToApprovalText} />
				)}
			</>
		)
	}, [newDefaultCppContent, sentToApprovalText])

	if (hasManageRedeemablePermission) {
		return null
	}

	if (loading)
		return (
			<Card
				border="medium"
				elevated="medium"
				className={css.container}
				data-testid="redeemable-items-edit-default-cpp-card"
			>
				<div className={css.infoWrapper}>
					<div className={css.textWrapper}>
						<Paragraph weight="semibold">{t('redeemable:SETTINGS.PPP_LABEL')}</Paragraph>
						<SkeletonLoader width="70px" height="24px" variant="body" />
					</div>
					<div />
					<SkeletonLoader width="70px" height="24px" variant="body" />
				</div>
				<div>
					<SkeletonLoader width="250px" height="24px" variant="body" />
				</div>
			</Card>
		)

	return (
		<Card
			elevated="small"
			border="medium"
			className={css.container}
			data-testid="redeemable-items-edit-default-cpp-card"
		>
			<div className={css.infoWrapper}>
				<div className={css.textWrapper}>
					<Paragraph weight="semibold">{t('redeemable:SETTINGS.PPP_LABEL')}</Paragraph>
					<Paragraph weight="normal">{pricePerPoint}</Paragraph>
					{canChangeDefaultCPP && defaultCppIcons()}
				</div>
				<div />
				{canChangeDefaultCPP && editDefaultCppButton()}
			</div>
			<div>
				{lastUpdated && (
					<Paragraph colortype="secondary">{t('redeemable:EDIT.PPP_UPDATED_ON', { lastUpdated })}</Paragraph>
				)}
			</div>
		</Card>
	)
}
