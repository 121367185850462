import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { setAppHeaderConfig } from 'admin-portal-shared-services';

import { PATH_BASE, PATH_HOME, PATH_VISIT_DETAILS } from '@/consts/route';

export function useVisits() {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setAppHeaderConfig({
      vendorSelect: false,
      pageTitle: ' ',
      optionalButtons: [],
      countrySelect: false,
      breadcrumbConfig: {
        homePath: `${PATH_HOME}${PATH_BASE}`,
        items: [
          {
            isCurrentPage: false,
            path: `${PATH_HOME}${PATH_BASE}`,
            label: formatMessage({ id: 'Pages.Visits.Title.PAGE_VISITS' }),
          },
          {
            isCurrentPage: true,
            path: PATH_VISIT_DETAILS,
            label: formatMessage({
              id: 'Components.VisitDetails.Title.VISIT_DETAILS',
            }),
          },
        ],
      },
    });
  }, [formatMessage]);
}
