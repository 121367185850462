import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface Crumb {
  name?: string;
  path?: string;
}

interface AppHeaderProps {
  crumbs?: Crumb[];
  setCrumbs?: Dispatch<SetStateAction<Crumb[]>>;
  pageTitle?: string;
  setPageTitle?: Dispatch<SetStateAction<string>>;
  optionalButtons?: JSX.Element;
  setOptionalButtons?: Dispatch<SetStateAction<JSX.Element>>;
  isVisible?: boolean;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
}

const AppHeaderContext = createContext<AppHeaderProps>({});

export const AppHeaderProvider = ({ children }) => {
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [pageTitle, setPageTitle] = useState('');
  const [optionalButtons, setOptionalButtons] = useState<JSX.Element>(null);
  const [isVisible, setIsVisible] = useState(true);

  return (
    <AppHeaderContext.Provider
      value={{
        crumbs,
        setCrumbs,
        pageTitle,
        setPageTitle,
        optionalButtons,
        setOptionalButtons,
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

export const useAppHeader = () => useContext(AppHeaderContext);
