export interface SegmentCommonProps {
  screen_name: string | null;
  vendor_id: string | null;
  vendor_name: string | null;
  country: string | null;
  business_model: string | null;
  user_type: string | null;
  report_type: string | null;
  report_subtype: string | null;
  tab_name: string | null;
  vendor_country: string | null;
}

export enum SegmentScreenName {
  PARTNER_INSIGHTS = 'PARTNER_INSIGHTS',
  PARTNER_SELECTION = 'PARTNER_SELECTION',
}

export enum SegmentBusinessModel {
  '1P' = '1P',
  '3P_PRO' = '3P_PRO',
  DTAAS = 'DTAAS',
  SAAS = 'SAAS',
}

export enum SegmentUserType {
  MANAGER = 'MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
  PARTNER = 'PARTNER',
  ADMIN = 'ADMIN',
}
