import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  CONTRIES: {
    AR: 'ARGENTINA',
    BR: 'BRAZIL',
    BRF: 'BRF',
    CA: 'CANADA',
    CO: 'COLOMBIA',
    DO: 'DOMINICAN REPUBLIC',
    EC: 'ECUADOR',
    SV: 'EL SALVADOR',
    HN: 'HONDURAS',
    MX: 'MEXICO',
    PA: 'PANAMA',
    PY: 'PARAGUAY',
    PE: 'PERU',
    ZA: 'SOUTH AFRICA',
    TZ: 'TANZANIA',
    US: 'UNITED STATES',
    UY: 'URUGUAY',
  },

  OPERATIONS: {
    TITLE: 'OPERATIONS',
  },
  BACKEND_SERVICES: {
    TITLE: 'BACKEND SERVICES',
    MICROSERVICE: 'MICROSERVICES TRAFFIC',
  },
  MICROSERVICE_TRAFFIC: {
    TITLE: 'MICROSERVICES TRAFFIC',
    ALL: 'ALL Traffic',
    BY_COUNTRY: 'Traffic by Country',
    TOTAL: 'Total Traffic by Country',
  },
  CLOUD_ENGINEERING: {
    TITLE: 'CLOUD ENGINEERING',
  },
  MOBILE: {
    TITLE: 'MOBILE',
    IOS_TITLE: 'Latest iOS PROD Apps',
    ANDROID_TITLE: 'Latest Android PROD Apps',
    CRASH_FREE: 'Crash Free',
    BUTTON: 'Read more',
  },
  WEB: {
    TITLE: 'WEB',
  },
  IOS_DETAILS: {
    TITLE: 'iOS APPS',
    MESSAGE: '~ Click on either country flag or scan QR code to download the relevant app ~',
    HELP: 'HELP',
    UNTRUSTED_TITLE: 'I get the message “Untrusted Enterprise Developer',
    UNTRUSTED_TEXT:
      'For the app to be available for use, you will need to trust the developer’s certificate. From your phone’s home screen, tap Settings > General > Profiles or Profiles & Device Management. Under the Enterprise App heading, you see will see a profile for the developer. Tap the name of the developer profile and then confirm you trust them. You should now be able to launch the app.',
    UNABLE_DOWLOAD_TITLE:
      'While installing the app, I get an “Unable to Download App” error, and the app is not installed',
    UNABLE_DOWLOAD_TEXT:
      'This error could be caused by many reasons, and you should contact the app developer to help resolve the issue. If you are the app developer, this error could be caused by one of the following reasons: – UDID is not included in the provisioning profile – The build was incorrectly signed or has broken entitlements – Device or iOS version is incompatible with the build – Device storage is full – Actual download error – The app is over 50 MB and downloaded over a cellular connection',
    INSTALL_TITLE:
      'When I press the “Install” button, the installation alert never shows up and the app never installs on my device',
    INSTALL_TEXT:
      'If you have previously installed the app from the App Store, and it carries the same version of the app that is being installed, there will be no alert to confirm the installation. As a workaround, please try uninstalling the existing version of the app, and then tap the Install button again.',
  },
  ANDROID_DETAILS: {
    TITLE: 'Android APPS',
    MESSAGE: '~ Click on either country flag or scan QR code to download the relevant app ~',
    HELP: 'HELP',
    UNTRUSTED_TITLE: 'I get the message “Untrusted Enterprise Developer',
    UNTRUSTED_TEXT:
      'For the app to be available for use, you will need to trust the developer’s certificate. From your phone’s home screen, tap Settings > General > Profiles or Profiles & Device Management. Under the Enterprise App heading, you see will see a profile for the developer. Tap the name of the developer profile and then confirm you trust them. You should now be able to launch the app.',
    UNABLE_DOWLOAD_TITLE:
      'While installing the app, I get an “Unable to Download App” error, and the app is not installed',
    UNABLE_DOWLOAD_TEXT:
      'This error could be caused by many reasons, and you should contact the app developer to help resolve the issue. If you are the app developer, this error could be caused by one of the following reasons: – UDID is not included in the provisioning profile – The build was incorrectly signed or has broken entitlements – Device or iOS version is incompatible with the build – Device storage is full – Actual download error – The app is over 50 MB and downloaded over a cellular connection',
    INSTALL_TITLE:
      'When I press the “Install” button, the installation alert never shows up and the app never installs on my device',
    INSTALL_TEXT:
      'If you have previously installed the app from the App Store, and it carries the same version of the app that is being installed, there will be no alert to confirm the installation. As a workaround, please try uninstalling the existing version of the app, and then tap the Install button again.',
  },
  CRASH_FREE: {
    TITLE: 'CRASH STATISTICS',
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
};

export default enUS;
