import { ParseResult } from 'papaparse'

const MAX_LINES = 1000000

export const handleCsvParseResults = (results: ParseResult<unknown>): boolean => {
	const { data, meta, errors: parseErrors } = results
	const hasParseErrors = !!parseErrors?.length
	const lowerCaseFields = meta.fields?.map((field) => field.toLowerCase()) || []

	const isDataLengthValid = data.length === 0 || data.length > MAX_LINES
	const isFieldCountValid = meta.fields?.length && meta.fields?.length > 3
	const containsRequiredFields = lowerCaseFields.includes('poc_id') && lowerCaseFields.includes('points')

	if (hasParseErrors || isDataLengthValid || isFieldCountValid || !containsRequiredFields) {
		return false
	}

	return true
}
