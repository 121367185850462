import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { APPLICATION_TITLE, HOME_PAGE } from 'constants/optimizely';
import { BASE_PATH } from 'constants/routes/internal/index';
import { useAuth } from 'contexts/auth/AuthContext';
import Error403Page from 'pages/Error/403';
import Error404Page from 'pages/Error/404';
import { useAppHeader } from 'providers/AppHeaderProvider/AppHeaderProvider';
import { Suspense, lazy, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UserRoles } from 'types/Auth';
import PrivateRoute from './components/PrivateRoute';

const TeamPage = lazy(() => import('pages/TeamPage/TeamPage'));
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const EvaluatePage = lazy(() => import('pages/EvaluatePage/EvaluatePage'));
const ReportPage = lazy(() => import('pages/ReportPage/ReportPage'));
const MainTemplate = lazy(() => import('templates/MainTemplate/MainTemplate'));
const EmployeeHomePage = lazy(() => import('pages/EmployeeHomePage/EmployeeHomePage'));
const FeedbackPage = lazy(() => import('pages/FeedbackPage/FeedbackPage'));

const Router = (): JSX.Element => {
  const { setOptionalButtons } = useAppHeader();
  const [previousPathname, setPreviousPathname] = useState<string>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { roles, verifyRole } = useAuth();
  const [homePageFeatureToggleStatus, homePageFeatureToggleIsLoading] = useFeatureToggleV2(
    HOME_PAGE,
    APPLICATION_TITLE
  );

  const isEmployee = verifyRole(UserRoles.EMPLOYEE);

  useEffect(() => {
    if (
      location.pathname === `${BASE_PATH}` &&
      !homePageFeatureToggleStatus &&
      !homePageFeatureToggleIsLoading
    ) {
      if (isEmployee) navigate(`${BASE_PATH}/me`);
    }
  }, [location, roles, homePageFeatureToggleStatus, homePageFeatureToggleIsLoading]);

  useLayoutEffect(() => {
    if (location.pathname !== previousPathname) setOptionalButtons(null);
    setPreviousPathname(location.pathname);
  }, [location]);

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="*" element={<Error404Page />} />
        <Route element={<MainTemplate />}>
          {!homePageFeatureToggleStatus && !homePageFeatureToggleIsLoading ? (
            <Route
              path={`${BASE_PATH}`}
              element={
                <PrivateRoute>
                  <TeamPage />
                </PrivateRoute>
              }
            />
          ) : (
            <Route path={`${BASE_PATH}`} element={<HomePage />} />
          )}
          <Route
            path={`${BASE_PATH}/evaluate/:userId`}
            element={
              <PrivateRoute>
                <EvaluatePage />
              </PrivateRoute>
            }
          />
          <Route
            path={`${BASE_PATH}/report/:userId`}
            element={
              <PrivateRoute>
                <ReportPage />
              </PrivateRoute>
            }
          />

          <Route
            path={`${BASE_PATH}/me`}
            element={
              <PrivateRoute>
                <EmployeeHomePage />
              </PrivateRoute>
            }
          />

          <Route
            path={`${BASE_PATH}/team`}
            element={
              <PrivateRoute>
                <TeamPage />
              </PrivateRoute>
            }
          />

          <Route
            path={`${BASE_PATH}/feedback/:userId`}
            element={
              <PrivateRoute>
                <FeedbackPage />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path={`${BASE_PATH}/403`} element={<Error403Page />} />
      </Routes>
    </Suspense>
  );
};
export default Router;
