import { useMutation } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { deleteEarningRule } from '@/services/rules/RulesService'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { CreateEarningRuleFormData } from '@/routes/rules/RulesRedesign/Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { useFormContext } from 'react-hook-form'
import { ScreenName } from '@/domains'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import useCurrentZone from '../zone/useCurrentZone'

export const useDeleteEarningRule = (): {
	isLoading: boolean
	deleteRule: () => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { selectedTierKey, currentTier } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const { t } = useTranslation()
	const ruleId = getRuleIdFromPathname()
	const selectedZone = useCurrentZone()

	const { getValues } = useFormContext<CreateEarningRuleFormData>()

	const earningRuleData = getValues()

	const { isLoading, mutate: deleteRule } = useMutation(
		() => {
			return deleteEarningRule(ruleId ?? '', vendorId, selectedTierKey ?? '')
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('common:TOAST_DELETE_RULE:SUCCESS'),
				})

				AnalyticsService.events.ruleDeleted({
					amount: { value: earningRuleData.amountSpent },
					rule_type: 'Segmented Earning Rule',
					earning_rule_description: earningRuleData.description!,
					earning_rule_id: earningRuleData.ruleId!,
					points: String(earningRuleData.points),
					screen_name: ScreenName.EarningRuleDetails,
					tier: currentTier ? currentTier?.label : selectedTierKey,
					user: getUserEmail(),
					vendor: vendorId,
					zone: selectedZone,
				})

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: t('common:TOAST_DELETE_RULE:ERROR'),
				})
			},
		},
	)

	return {
		isLoading,
		deleteRule,
	}
}
