import { Card, IconButton, Tooltip } from '@hexa-ui/components';
import { BarChart, Draganddrop, Plus, Trophy } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import DraggableTable from '../../../components/DraggableTable/DraggableTable';
import TableRowDropdown from '../../../components/Dropdown/TableRowDropdown/TableRowDropdown';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import TournamentRankingHeaders from '../../json/columnHeaders/TournamentRankingHeaders.json';
import mockData from '../../json/mock/mockingTournamentData.json';
import handleFilter from '../extra/filter';


const HiddenDragIcon = styled('div', {
  cursor: 'grab',
});

const ButtonPadding = styled('div', {
  paddingLeft: '1rem',
});

const TournamentRankingTab: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [savedData, setSavedData] = useState([]);

  const [modifiedData, setModifiedData] = useState(data);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [isReordered, setIsReordered] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const dragHandlers = (index) => ({
    onDragStart: (e) => {
      e.dataTransfer.setData('rowIndex', index.toString());
    },
    onDragOver: (e) => e.preventDefault(),
    draggable: true,
  });

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['name', 'authored_by', 'changed', 'created', 'language', 'actions'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const InsertActions = (data) => {
    const ActionComponent = ({ ranking }) => {
      return (
        <div style={{ display: 'inline-flex' }}>
          <Tooltip
            placement="top"
            text="View">
            <IconButton
              icon={BarChart}
              variant="inherit"
              onClick={() => {
                navigate(`/bees-games/manage-assets/campaign-details/${params.id}/view-ranking/${ranking.id}`);
              }}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            text="Import">
            <IconButton
              icon={Trophy}
              variant="inherit"
              onClick={() => {
                navigate(`/bees-games/manage-assets/campaign-details/${params.id}/import-winners/${ranking.id}`);
              }}
            />
          </Tooltip>
          <TableRowDropdown campaignId={params.id} rowId={ranking.id} baseRoute={"/bees-games/manage-assets/campaign-details"} routeName={"edit-ranking"} title={"ranking"} />
        </div>
      );
    };

    return data.map((item, index) => ({
      ...item,
      actions: <ActionComponent ranking={item} />,
      icon: (
        <HiddenDragIcon {...dragHandlers(index)}>
          <Draganddrop />
        </HiddenDragIcon>
      ),
    }));
  };

  // Functions to change when we receive the api.
  const handleSave = () => {
    simulateAPI(modifiedData);
    setIsReordered(false);
  };

  const simulateAPI = async (data) => {
    try {
      setLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 2000));

      setSavedData(data);
      console.log('Data sent successfully:', data);
    } catch (error) {
      console.error('Error while sending data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card elevated={'medium'} border={'large'} css={{ paddingBottom: '2rem' }}>
      {isReordered && (
        <StyledAlert
          css={{ width: '100%', margin: '0.5rem 0' }}
          message="*You have unsaved changes."
          type="warning"
        />
      )}
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
        <StyledButton
          variant="primary"
          icon={Plus}
          leading
          onClick={() =>
            navigate(`/bees-games/manage-assets/campaign-details/${id}/create-ranking`)
          }
        >
          Add Ranking Config
        </StyledButton>
      </FlexContainer>
      <DraggableTable
        columns={TournamentRankingHeaders}
        data={data || []}
        onReorder={(newData) => {
          setIsReordered(true);
          setModifiedData(newData);
        }}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
      <ButtonPadding>
        <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
          Save order
        </StyledButton>
      </ButtonPadding>
    </Card>
  );
};

export default TournamentRankingTab;
