/**
 * This is a configuration file for the react-router-dom library.
 * It's used to define the routes of the application.
 * It's not necessary sonar to analyze this file.
 */
import { LoadingDots } from '@hexa-ui/components';
import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { FeatureToggle } from '../components/atoms/FeatureToggle/FeatureToggle';
import { AppHeader } from '../components/organisms/AppHeader/AppHeader';
import { WsNotification } from '../components/organisms/WsNotification/WsNotification';
import { keys } from '../constants';
import { CreditPage } from '../pages/Credit/CreditPage';
import { FilesPage } from '../pages/Files/FilesPage';
import { ManagementPage } from '../pages/Management/ManagementPage';
import { PocDetailsPage } from '../pages/PocDetails/PocDetailsPage';
import { UploadPage } from '../pages/Upload/UploadPage';
import { ViewsPage } from '../pages/Views/ViewsPage';
import {
  CustomViewsProvider,
  HeaderProvider,
  OptimizelyProvider,
  OptionsProvider,
  SourceProvider,
} from '../providers';
import { Router } from './Router';

const ProvidersAndLayout = () => (
  <HeaderProvider>
    <React.Fragment>
      <AppHeader />
      <OptionsProvider>
        <OptimizelyProvider>
          <SourceProvider>
            <CustomViewsProvider>
              <React.Fragment>
                <FeatureToggle keys={[keys.cm_has_ws_notifications]}>
                  <WsNotification />
                </FeatureToggle>

                <Router />
              </React.Fragment>
            </CustomViewsProvider>
          </SourceProvider>
        </OptimizelyProvider>
      </OptionsProvider>
    </React.Fragment>
  </HeaderProvider>
);
const routes = [
  {
    path: '/',
    element: <ProvidersAndLayout />,
    children: [
      {
        path: '/bees-credit-management/credit',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <CreditPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/files',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <FilesPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/upload',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <UploadPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/upload/progress/:fileId/:fileName',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <UploadPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/poc-details/:id',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <PocDetailsPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/custom-view/:mode/:table/:index?',
        element: (
          <Suspense fallback={<LoadingDots />}>
            <ViewsPage />
          </Suspense>
        ),
      },
      {
        path: '/bees-credit-management/management',
        element: (
          <FeatureToggle keys={[keys.cm_has_management]}>
            <Suspense fallback={<LoadingDots />}>
              <ManagementPage />
            </Suspense>
          </FeatureToggle>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/bees-credit-management/credit" />,
  },
];

export const routerConfig = createBrowserRouter(routes);
