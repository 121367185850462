import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { VendorResponse } from 'Domain/Vendor';

interface VendorIdServiceParams {
  vendorId: string;
  country: string;
}

export const getVendorsByIdService = async ({
  country,
  vendorId,
}: VendorIdServiceParams): Promise<VendorResponse> => {
  const baseUrl = getAPIBaseURL(APIEndpoint.GET_VENDORS_BY_ID, country);

  const response = await Api.get({
    url: `/vendors/${vendorId}`,
    headers: { country },
    baseURL: baseUrl,
  });

  return response.data;
};
