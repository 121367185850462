/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface AtpExportFilesFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AtpInventoryFileDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the file the user downloaded
	 */
	file_name?: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AtpInventorySummaryPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AtpOrdersFileDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the file the user downloaded
	 */
	file_name?: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AtpParametersFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The count of parameters returned on the list
	 */
	parameters_count: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AtpParametersPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface AccessedPage {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface AddAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The type of the parameter added
	 */
	parameter_type: string | null
	/**
	 * The number of days of the reservation window on ATP
	 */
	reservation_window: Record<string, any> | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface AddCriticalStockRule {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The limit quantity for this SKU in this rule
	 */
	limit_qty: number | null
	/**
	 * The POCs quantity affected for this rule
	 */
	pocs_selected_qty: number | null
	/**
	 * The name of the region selected
	 */
	region_selected?: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the rule created
	 */
	rule_name: string | null
	/**
	 * The rule type seleted for the rule
	 */
	rule_type: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface ButtonClicked {
	/**
	 * The name of the button clicked
	 */
	button_name: string | null
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface ColumnSorting {
	/**
	 * The name of the column on which sorting was done
	 */
	column_clicked: string | null
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface ConfirmedUnblock {
	/**
	 * The status action done
	 */
	action_status: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
}
export interface ConfirmedUnblockByPoc {
	/**
	 * The status action done
	 */
	action_status: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
}
export interface ConfirmedUpload {
	/**
	 * The status action done
	 */
	action_status: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
}
export interface ConsultActiveLimitsPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface DdcLimitRemoved {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The properties related to the removal of a limit.
	 */
	limit_removal_data: any[] | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface DdcTemplateDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface DeleteAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The type of the parameter added
	 */
	parameter_type: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface DeleteAnAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Id of the parameter that is edit by the user
	 */
	parameter_id: string | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface DeleteCriticalStockRule {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The critical stock value parametrized
	 */
	critical_level_value: number | null
	/**
	 * The limit quantity for this SKU in this rule
	 */
	limit_qty: number | null
	/**
	 * The POCs quantity affected for this rule
	 */
	pocs_selected_qty: number | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The Id of the rule created
	 */
	rule_id: string | null
	/**
	 * The name of the rule created
	 */
	rule_name: string | null
	/**
	 * The rule type seleted for the rule
	 */
	rule_type: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface DownloadRequested {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the region selected
	 */
	region_selected?: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * THe text inputed in SKU field
	 */
	sku_searched: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface EditAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface EditAnAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Id of the parameter that is edit by the user
	 */
	parameter_id: string | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface EditCriticalStockRule {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The critical stock value parametrized
	 */
	critical_level_value: number | null
	/**
	 * The POCs quantity affected for this rule
	 */
	pocs_selected_qty: number | null
	/**
	 * The critical stock value before the edit
	 */
	previous_critical_level_value: number | null
	/**
	 * The Status of the rule before the edit
	 */
	previous_rule_status: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The Id of the rule created
	 */
	rule_id: string | null
	/**
	 * The new status of the rule
	 */
	rule_status: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * The element text on the front end with EN copy
	 */
	element_label: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
	 */
	element_location_name: string | null
	/**
	 * The category of the object that anchors the button
	 */
	element_location_type: string | null
	/**
	 * The element name on the back end
	 */
	element_name: string | null
	/**
	 * The element type
	 */
	element_type: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page.
	 */
	page_name: string | null
	/**
	 * The URL of the page where the event was triggered.
	 */
	page_url: string | null
	/**
	 * Logged user's country
	 */
	user_country: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed
	 */
	view_level: string | null
}
export interface ErrorFileDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The file Id of the user log viewed.
	 */
	file_id: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface ErrorLogLoaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The file Id of the user log viewed.
	 */
	file_id: string | null
	/**
	 * The count of error lines on the file.
	 */
	line_count: number | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface ExportFileDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The file Id of the user log viewed.
	 */
	file_id: string | null
	/**
	 * The type of limit that the user are trying to work with. Permitted values: DC, POC
	 */
	limit_type: LimitType
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FileMonitoringErrorModalViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the file the user downloaded
	 */
	file_name?: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FileMonitoringPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FileMonitoringQueryRefresh {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FileProcessed {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
	 */
	file_format: FileFormat
	/**
	 * When the file is tabular (CSV, XLS), this indicates if it has headers
	 */
	file_has_headers: boolean | null
	/**
	 * When the file is tabular (CSV, XLS), this indicates the number of columns
	 */
	file_number_of_columns: number | null
	/**
	 * When the file is tabular (CSV, XLS), this indicates the number of rows (headers included)
	 */
	file_number_of_rows: number | null
	/**
	 * This identifies rather the process is a Download (starting point) or an Upload (conclusion point)
	 */
	file_process_type: FileProcessType
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page.
	 */
	page_name: string | null
	/**
	 * The URL of the page where the event was triggered.
	 */
	page_url: string | null
	/**
	 * Logged user's country
	 */
	user_country: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed
	 */
	view_level: string | null
}
export interface FileSelected {
	/**
	 * The error message showed to the user when he uploaded a wrong file. Sample:  Invalid file type, select a CSV file type
	 */
	error_message?: string | null
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * The file extention that have been uploaded on the platform. Sample: .CSV, .XLS, .XLSX
	 */
	file_type: FileType
}
export interface FileUploadHistoryLoaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The pagination properties selected by the user.
	 */
	pagination: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FilesExportFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface FiltersSelected {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The filters selected by user on the screen
	 */
	filters_selected: Record<string, any> | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface ImportDcExceptionFile {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Name of the upload file selected
	 */
	name_file_selected: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface InventorySummaryFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitByDdcFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitByPocFiltered {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitByPocSearched {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The count of SKUs limited for the customer
	 */
	limits_count: number | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The current text on the search bar (Null if there is none)
	 */
	search_text: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitCanceled {
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface LimitFileUploaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * The type of limit that the user are trying to work with. Permitted values: DC, POC
	 */
	limit_type: LimitType
	/**
	 * The validity of the limit.
	 */
	limit_validity: Record<string, any> | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The response code of the API request
	 */
	response: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitSelected {
	/**
	 * The type of limit that the user are trying to work with. Permitted values: DC, POC
	 */
	limit_type: LimitType
}
export interface LimitSubmited {
	/**
	 * The failure message showed to the user if something wrong happens.
	 */
	failure_message?: string | null
	/**
	 * The confirmation that the user wants to proceed with the limit process. Sample: Yes
	 */
	limit_submited: string | null
	/**
	 * The success message presented to the user
	 */
	sucess_message: string | null
}
export interface LimitedSkuManagementPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitsExportFileRequested {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The query parameter used for filtering on the screen.
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface LimitsPageAccessed {
	/**
	 * The country selected on the country selector. Sample: BR, EC, MX
	 */
	country: Country
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator
	 */
	user_role: UserRole
}
export interface LimitsUploadPage {
	/**
	 * The country selected on the country selector. Sample: BR, EC, MX
	 */
	country: Country
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator
	 */
	user_role: UserRole
}
export interface LimitsUploadPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PocExceptionFileUploaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The response code of the API request
	 */
	response: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PocExceptionTemplateDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PocLimitRemoved {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The count of customers affected by the limit removal.
	 */
	customer_count: number | null
	/**
	 * The array of DDC ids affected by the limits
	 */
	ddc_id: any[] | null
	/**
	 * The properties related to the removal of a limit.
	 */
	limit_removal_data: any[] | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PocTemplateDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PoCsExeptionManagementPageViewed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface PageViewed {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page.
	 */
	page_name: string | null
	/**
	 * The URL of the page where the event was triggered.
	 */
	page_url: string | null
	/**
	 * URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
	 */
	referrer: string | null
	/**
	 * Logged user's country
	 */
	user_country: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed
	 */
	view_level: string | null
}
export interface PaginationButtonsClicked {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Text/Name/Value of button clicked
	 */
	pagination_button_clicked: string | null
	/**
	 * The Identification of the table
	 */
	pagination_id: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface ProceedUpload {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The end date and time of the validity of the limit to be applied
	 */
	end_date_time: string | null
	/**
	 * The Name of the upload file selected
	 */
	name_file_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * The start date and time of the validity of the limit to be applied
	 */
	start_date_time: string | null
	/**
	 * The option selected for upload
	 */
	upload_file_type_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface RedirectToFileMonitoringPage {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface RequestAtpReportsGeneration {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface SkuSearchPerformed {
	/**
	 * The array of DDC ids affected by the limits
	 */
	ddc_id: any[] | null
	/**
	 * The type of limit that the user are trying to work with. Permitted values: DC, POC
	 */
	limit_type: LimitType
	/**
	 * The region selected by the user. Sample: Geo SP, Geo RJ-ES
	 */
	region: string | null
	/**
	 * The word used as a search expression. Sample: Brahma, Skol, 0000294, 253
	 */
	search_word?: string | null
}
export interface SkuUnlimitCanceled {
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface SkuUnlimitSelect {
	/**
	 * The array of DDC ids affected by the limits
	 */
	ddc_id: any[] | null
	/**
	 * The SKU Ids affected by the limits. Sample: 0000253, 000000000000085684
	 */
	sku_id: string | null
}
export interface SkuUnlimited {
	/**
	 * The failure message showed to the user if something wrong happens.
	 */
	failure_message?: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The success message presented to the user
	 */
	success_message?: string | null
}
export interface SaveNewAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The informations send by or retuned to the user.
	 */
	payload_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface SearchApplied {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The number of results from that search
	 */
	number_of_results: number | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page.
	 */
	page_name: string | null
	/**
	 * The URL of the page where the event was triggered.
	 */
	page_url: string | null
	/**
	 * The string that was searched
	 */
	search_query: string | null
	/**
	 * Logged user's country
	 */
	user_country: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed
	 */
	view_level: string | null
}
export interface SearchByPocIdPerformed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The quantity of skus limited for this POC
	 */
	limited_skus_qty: number | null
	/**
	 * The name of the POC
	 */
	poc_details_account: string | null
	/**
	 * The code of the DC
	 */
	poc_details_id_dc: string | null
	/**
	 * The code of the DC Exception
	 */
	poc_details_id_dc_exception: string | null
	/**
	 * The Text inputed in Search by POC Id field
	 */
	poc_selected: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface SearchBySkuIdPerformed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * Text inputed in the search field
	 */
	text_searched: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface SearchPerformed {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the region selected
	 */
	region_selected?: string | null
	/**
	 * The status code of the sent request
	 */
	request_status: string | null
	/**
	 * The text message that appears in the response to the user
	 */
	request_text_response: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Text inputed in the search field
	 */
	text_searched: string | null
	/**
	 * The total amount of DCs after the applied filter
	 */
	total_dc: number | null
	/**
	 * The total amount of SKUs after the applied filter
	 */
	total_sku: number | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface SelectionOfFilesForDownload {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * Indicates whether order file export was available to the user
	 */
	orders_report_enabled: boolean | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface StartCreatingAnAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Id of the parameter that is edit by the user
	 */
	parameter_id: string | null
	/**
	 * The name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor name that is currently selected when this event occored.
	 */
	vendor_name: string | null
}
export interface SummaryPageViewed {
	/**
	 * The array of DDC ids affected by the limits
	 */
	ddc_id: any[] | null
	/**
	 * The limits final end date. Sample: 23/09/2022 16:51
	 */
	limits_end_date: string | null
	/**
	 * The limits final start date. Sample: 23/09/2022 16:51
	 */
	limits_start_date: string | null
	/**
	 * The count of SKUs affected with limits for each DDC. Sample: 1,2,3,4
	 */
	sku_count: string | null
}
export interface TemplateDownloaded {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * The name of the template file selected
	 */
	template_file_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface UnblockAction {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The SKU code and name
	 */
	data_grid_code_and_name_sku: string | null
	/**
	 * The number of days the applied limit is valid
	 */
	data_grid_duration: string | null
	/**
	 * The end date and time of the limit
	 */
	data_grid_final_validity: string | null
	/**
	 * The start date and time of the limit
	 */
	data_grid_inicial_validity: string | null
	/**
	 * The limit value applied to the sku
	 */
	data_grid_limit: string | null
	/**
	 * The Number of POCs affected by the limit
	 */
	data_grid_poc: number | null
	/**
	 * The limit status
	 */
	data_grid_status: string | null
	/**
	 * The name of the region selected
	 */
	region_selected?: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * THe text inputed in SKU field
	 */
	sku_searched: string | null
	/**
	 * The total amount of DCs after the applied filter
	 */
	total_dc: number | null
	/**
	 * Total amount of selected DCs
	 */
	total_dc_selected: number | null
	/**
	 * The total amount of SKUs after the applied filter
	 */
	total_sku: number | null
	/**
	 * The total amount of selected SKUs
	 */
	total_sku_selected: number | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface UnblockActionByPoc {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The Text inputed in Search by POC Id field
	 */
	poc_selected: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
	/**
	 * The vendor selected on the screen
	 */
	vendor_selected: string | null
}
export interface UploadLimitCompleted {
	/**
	 * The failure message showed to the user if something wrong happens.
	 */
	failure_message?: string | null
	/**
	 * The confirmation that the user wants to proceed with the limit upload. Permitted values: Yes
	 */
	proceed_sending: ProceedSending
}
export interface ValidityEdited {
	/**
	 * The new limit start end, before changing. Sample: 23/09/2022 16:45
	 */
	new_end_date: string | null
	/**
	 * The new limit start date, before changing. Sample: 23/09/2022 16:45
	 */
	new_start_date: string | null
	/**
	 * The previous limit start end, before changing. Sample: 23/09/2022 16:45
	 */
	previous_end_date: string | null
	/**
	 * The previous limit start date, before changing. Sample: 23/09/2022 16:45
	 */
	previous_start_date: string | null
}
export interface ViewAtpParameter {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The type of the parameter added
	 */
	parameter_type: string | null
	/**
	 * The number of days of the reservation window on ATP
	 */
	reservation_window: Record<string, any> | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}
export interface ViewCriticalStockRule {
	/**
	 * The Country selected on the screen
	 */
	country_selected: string | null
	/**
	 * The critical stock value parametrized
	 */
	critical_level_value: number | null
	/**
	 * The type of limit that the user are trying to work with. Permitted values: DC, POC
	 */
	limit_type: LimitType
	/**
	 * The POCs quantity affected for this rule
	 */
	pocs_selected_qty: number | null
	/**
	 * The Id of the rule created
	 */
	rule_id: string | null
	/**
	 * The name of the rule created
	 */
	rule_name: string | null
	/**
	 * The rule type seleted for the rule
	 */
	rule_type: string | null
	/**
	 * The name of the menu selected
	 */
	screen_selected: string | null
	/**
	 * The name of the tab selected by user
	 */
	screen_tab_selected: string | null
	/**
	 * Logged user's email
	 */
	user_email: string | null
	/**
	 * Logged user's time zone
	 */
	user_timezone: string | null
	/**
	 * The Id of vendor selected on the screen
	 */
	vendor_id_selected: string | null
}

export enum LimitType {
	Dc = 'DC',
	Poc = 'POC',
	Null = 'null',
}
export enum FileFormat {
	Csv = 'CSV',
	Txt = 'TXT',
	Pdf = 'PDF',
	Png = 'PNG',
	Xls = 'XLS',
	Null = 'null',
}
export enum FileProcessType {
	DownloadStarted = 'DOWNLOAD_STARTED',
	UploadCompleted = 'UPLOAD_COMPLETED',
	Null = 'null',
}
export enum FileType {
	Csv = 'CSV',
	Xls = 'XLS',
	Xlsx = 'XLSX',
	Null = 'null',
}
export enum Country {
	Ar = 'AR',
	Br = 'BR',
	Ca = 'CA',
	Co = 'CO',
	Ec = 'EC',
	Hn = 'HN',
	Mx = 'MX',
	Pa = 'PA',
	Pe = 'PE',
	Py = 'PY',
	Sv = 'SV',
	Tz = 'TZ',
	Ug = 'UG',
	Us = 'US',
	Uy = 'UY',
	Za = 'ZA',
	Null = 'null',
}
export enum UserRole {
	Admin = 'ADMIN',
	DdcOperator = 'DDC_OPERATOR',
	InventoryVisibility = 'INVENTORY_VISIBILITY',
	RegionOperator = 'REGION_OPERATOR',
	Viewer = 'VIEWER',
	Null = 'null',
}
export enum ProceedSending {
	Yes = 'YES',
	Null = 'null',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef AtpExportFilesFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AtpInventoryFileDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} [file_name] - The name of the file the user downloaded
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AtpInventorySummaryPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AtpOrdersFileDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} [file_name] - The name of the file the user downloaded
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AtpParametersFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} parameters_count - The count of parameters returned on the list
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AtpParametersPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef AccessedPage
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef AddAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_type - The type of the parameter added
 * @property {Record<string, any> | null} reservation_window - The number of days of the reservation window on ATP
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef AddCriticalStockRule
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} limit_qty - The limit quantity for this SKU in this rule
 * @property {number | null} pocs_selected_qty - The POCs quantity affected for this rule
 * @property {string | null} [region_selected] - The name of the region selected
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} rule_name - The name of the rule created
 * @property {string | null} rule_type - The rule type seleted for the rule
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef ButtonClicked
 * @property {string | null} button_name - The name of the button clicked
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef ColumnSorting
 * @property {string | null} column_clicked - The name of the column on which sorting was done
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef ConfirmedUnblock
 * @property {string | null} action_status - The status action done
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 */
/**
 * @typedef ConfirmedUnblockByPoc
 * @property {string | null} action_status - The status action done
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 */
/**
 * @typedef ConfirmedUpload
 * @property {string | null} action_status - The status action done
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 */
/**
 * @typedef ConsultActiveLimitsPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef DdcLimitRemoved
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {any[] | null} limit_removal_data - The properties related to the removal of a limit.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef DdcTemplateDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef DeleteAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_type - The type of the parameter added
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef DeleteAnAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_id - The Id of the parameter that is edit by the user
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef DeleteCriticalStockRule
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} critical_level_value - The critical stock value parametrized
 * @property {number | null} limit_qty - The limit quantity for this SKU in this rule
 * @property {number | null} pocs_selected_qty - The POCs quantity affected for this rule
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} rule_id - The Id of the rule created
 * @property {string | null} rule_name - The name of the rule created
 * @property {string | null} rule_type - The rule type seleted for the rule
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef DownloadRequested
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} [region_selected] - The name of the region selected
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} sku_searched - THe text inputed in SKU field
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef EditAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef EditAnAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_id - The Id of the parameter that is edit by the user
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef EditCriticalStockRule
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} critical_level_value - The critical stock value parametrized
 * @property {number | null} pocs_selected_qty - The POCs quantity affected for this rule
 * @property {number | null} previous_critical_level_value - The critical stock value before the edit
 * @property {string | null} previous_rule_status - The Status of the rule before the edit
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} rule_id - The Id of the rule created
 * @property {string | null} rule_status - The new status of the rule
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef ElementClicked
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {string | null} element_label - The element text on the front end with EN copy
 * @property {string | null} element_location_name - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name
 * @property {string | null} element_location_type - The category of the object that anchors the button
 * @property {string | null} element_name - The element name on the back end
 * @property {string | null} element_type - The element type
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string | null} page_name - The name of the page.
 * @property {string | null} page_url - The URL of the page where the event was triggered.
 * @property {string | null} user_country - Logged user's country
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed
 */
/**
 * @typedef ErrorFileDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} file_id - The file Id of the user log viewed.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef ErrorLogLoaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} file_id - The file Id of the user log viewed.
 * @property {number | null} line_count - The count of error lines on the file.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef ExportFileDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} file_id - The file Id of the user log viewed.
 * @property {LimitType} limit_type - The type of limit that the user are trying to work with. Permitted values: DC, POC
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FileMonitoringErrorModalViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} [file_name] - The name of the file the user downloaded
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FileMonitoringPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FileMonitoringQueryRefresh
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FileProcessed
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {FileFormat} file_format - This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
 * @property {boolean | null} file_has_headers - When the file is tabular (CSV, XLS), this indicates if it has headers
 * @property {number | null} file_number_of_columns - When the file is tabular (CSV, XLS), this indicates the number of columns
 * @property {number | null} file_number_of_rows - When the file is tabular (CSV, XLS), this indicates the number of rows (headers included)
 * @property {FileProcessType} file_process_type - This identifies rather the process is a Download (starting point) or an Upload (conclusion point)
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string | null} page_name - The name of the page.
 * @property {string | null} page_url - The URL of the page where the event was triggered.
 * @property {string | null} user_country - Logged user's country
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed
 */
/**
 * @typedef FileSelected
 * @property {string | null} [error_message] - The error message showed to the user when he uploaded a wrong file. Sample:  Invalid file type, select a CSV file type
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {FileType} file_type - The file extention that have been uploaded on the platform. Sample: .CSV, .XLS, .XLSX
 */
/**
 * @typedef FileUploadHistoryLoaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {Record<string, any> | null} pagination - The pagination properties selected by the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FilesExportFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef FiltersSelected
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filters_selected - The filters selected by user on the screen
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef ImportDcExceptionFile
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} name_file_selected - The Name of the upload file selected
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef InventorySummaryFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitByDdcFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitByPocFiltered
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitByPocSearched
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} limits_count - The count of SKUs limited for the customer
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} search_text - The current text on the search bar (Null if there is none)
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 */
/**
 * @typedef LimitFileUploaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {LimitType} limit_type - The type of limit that the user are trying to work with. Permitted values: DC, POC
 * @property {Record<string, any> | null} limit_validity - The validity of the limit.
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} response - The response code of the API request
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitSelected
 * @property {LimitType} limit_type - The type of limit that the user are trying to work with. Permitted values: DC, POC
 */
/**
 * @typedef LimitSubmited
 * @property {string | null} [failure_message] - The failure message showed to the user if something wrong happens.
 * @property {string | null} limit_submited - The confirmation that the user wants to proceed with the limit process. Sample: Yes
 * @property {string | null} sucess_message - The success message presented to the user
 */
/**
 * @typedef LimitedSkuManagementPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitsExportFileRequested
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef LimitsPageAccessed
 * @property {Country} country - The country selected on the country selector. Sample: BR, EC, MX
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {UserRole} user_role - The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator
 */
/**
 * @typedef LimitsUploadPage
 * @property {Country} country - The country selected on the country selector. Sample: BR, EC, MX
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {UserRole} user_role - The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator
 */
/**
 * @typedef LimitsUploadPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PocExceptionFileUploaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} response - The response code of the API request
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PocExceptionTemplateDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PocLimitRemoved
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} customer_count - The count of customers affected by the limit removal.
 * @property {any[] | null} ddc_id - The array of DDC ids affected by the limits
 * @property {any[] | null} limit_removal_data - The properties related to the removal of a limit.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PocTemplateDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PoCsExeptionManagementPageViewed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef PageViewed
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string | null} page_name - The name of the page.
 * @property {string | null} page_url - The URL of the page where the event was triggered.
 * @property {string | null} referrer - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} user_country - Logged user's country
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed
 */
/**
 * @typedef PaginationButtonsClicked
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} pagination_button_clicked - The Text/Name/Value of button clicked
 * @property {string | null} pagination_id - The Identification of the table
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef ProceedUpload
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} end_date_time - The end date and time of the validity of the limit to be applied
 * @property {string | null} name_file_selected - The Name of the upload file selected
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} start_date_time - The start date and time of the validity of the limit to be applied
 * @property {string | null} upload_file_type_selected - The option selected for upload
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef RedirectToFileMonitoringPage
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef RequestAtpReportsGeneration
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef SkuSearchPerformed
 * @property {any[] | null} ddc_id - The array of DDC ids affected by the limits
 * @property {LimitType} limit_type - The type of limit that the user are trying to work with. Permitted values: DC, POC
 * @property {string | null} region - The region selected by the user. Sample: Geo SP, Geo RJ-ES
 * @property {string | null} [search_word] - The word used as a search expression. Sample: Brahma, Skol, 0000294, 253
 */
/**
 * @typedef SkuUnlimitCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 */
/**
 * @typedef SkuUnlimitSelect
 * @property {any[] | null} ddc_id - The array of DDC ids affected by the limits
 * @property {string | null} sku_id - The SKU Ids affected by the limits. Sample: 0000253, 000000000000085684
 */
/**
 * @typedef SkuUnlimited
 * @property {string | null} [failure_message] - The failure message showed to the user if something wrong happens.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} [success_message] - The success message presented to the user
 */
/**
 * @typedef SaveNewAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {Record<string, any> | null} payload_data - The informations send by or retuned to the user.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef SearchApplied
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {number | null} number_of_results - The number of results from that search
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string | null} page_name - The name of the page.
 * @property {string | null} page_url - The URL of the page where the event was triggered.
 * @property {string | null} search_query - The string that was searched
 * @property {string | null} user_country - Logged user's country
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed
 */
/**
 * @typedef SearchByPocIdPerformed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} limited_skus_qty - The quantity of skus limited for this POC
 * @property {string | null} poc_details_account - The name of the POC
 * @property {string | null} poc_details_id_dc - The code of the DC
 * @property {string | null} poc_details_id_dc_exception - The code of the DC Exception
 * @property {string | null} poc_selected - The Text inputed in Search by POC Id field
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef SearchBySkuIdPerformed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} text_searched - Text inputed in the search field
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef SearchPerformed
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} [region_selected] - The name of the region selected
 * @property {string | null} request_status - The status code of the sent request
 * @property {string | null} request_text_response - The text message that appears in the response to the user
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} text_searched - Text inputed in the search field
 * @property {number | null} total_dc - The total amount of DCs after the applied filter
 * @property {number | null} total_sku - The total amount of SKUs after the applied filter
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef SelectionOfFilesForDownload
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {boolean | null} orders_report_enabled - Indicates whether order file export was available to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef StartCreatingAnAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_id - The Id of the parameter that is edit by the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored.
 */
/**
 * @typedef SummaryPageViewed
 * @property {any[] | null} ddc_id - The array of DDC ids affected by the limits
 * @property {string | null} limits_end_date - The limits final end date. Sample: 23/09/2022 16:51
 * @property {string | null} limits_start_date - The limits final start date. Sample: 23/09/2022 16:51
 * @property {string | null} sku_count - The count of SKUs affected with limits for each DDC. Sample: 1,2,3,4
 */
/**
 * @typedef TemplateDownloaded
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} template_file_selected - The name of the template file selected
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef UnblockAction
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} data_grid_code_and_name_sku - The SKU code and name
 * @property {string | null} data_grid_duration - The number of days the applied limit is valid
 * @property {string | null} data_grid_final_validity - The end date and time of the limit
 * @property {string | null} data_grid_inicial_validity - The start date and time of the limit
 * @property {string | null} data_grid_limit - The limit value applied to the sku
 * @property {number | null} data_grid_poc - The Number of POCs affected by the limit
 * @property {string | null} data_grid_status - The limit status
 * @property {string | null} [region_selected] - The name of the region selected
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} sku_searched - THe text inputed in SKU field
 * @property {number | null} total_dc - The total amount of DCs after the applied filter
 * @property {number | null} total_dc_selected - Total amount of selected DCs
 * @property {number | null} total_sku - The total amount of SKUs after the applied filter
 * @property {number | null} total_sku_selected - The total amount of selected SKUs
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef UnblockActionByPoc
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} poc_selected - The Text inputed in Search by POC Id field
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 * @property {string | null} vendor_selected - The vendor selected on the screen
 */
/**
 * @typedef UploadLimitCompleted
 * @property {string | null} [failure_message] - The failure message showed to the user if something wrong happens.
 * @property {ProceedSending} proceed_sending - The confirmation that the user wants to proceed with the limit upload. Permitted values: Yes
 */
/**
 * @typedef ValidityEdited
 * @property {string | null} new_end_date - The new limit start end, before changing. Sample: 23/09/2022 16:45
 * @property {string | null} new_start_date - The new limit start date, before changing. Sample: 23/09/2022 16:45
 * @property {string | null} previous_end_date - The previous limit start end, before changing. Sample: 23/09/2022 16:45
 * @property {string | null} previous_start_date - The previous limit start date, before changing. Sample: 23/09/2022 16:45
 */
/**
 * @typedef ViewAtpParameter
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {string | null} parameter_type - The type of the parameter added
 * @property {Record<string, any> | null} reservation_window - The number of days of the reservation window on ATP
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */
/**
 * @typedef ViewCriticalStockRule
 * @property {string | null} country_selected - The Country selected on the screen
 * @property {number | null} critical_level_value - The critical stock value parametrized
 * @property {LimitType} limit_type - The type of limit that the user are trying to work with. Permitted values: DC, POC
 * @property {number | null} pocs_selected_qty - The POCs quantity affected for this rule
 * @property {string | null} rule_id - The Id of the rule created
 * @property {string | null} rule_name - The name of the rule created
 * @property {string | null} rule_type - The rule type seleted for the rule
 * @property {string | null} screen_selected - The name of the menu selected
 * @property {string | null} screen_tab_selected - The name of the tab selected by user
 * @property {string | null} user_email - Logged user's email
 * @property {string | null} user_timezone - Logged user's time zone
 * @property {string | null} vendor_id_selected - The Id of vendor selected on the screen
 */

/**
 * This event is triggered when the user filters ATP Export files.
 *
 * @param {AtpExportFilesFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpExportFilesFiltered(
	props: AtpExportFilesFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user filters ATP Export files.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Export Files Filtered',
		type: 'object',
	}
	const message = {
		event: 'ATP Export Files Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Export Files Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user downloads the inventory custom report
 *
 * @param {AtpInventoryFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpInventoryFileDownloaded(
	props: AtpInventoryFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user downloads the inventory custom report',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_name: {
						description: 'The name of the file the user downloaded',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Inventory File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'ATP Inventory File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Inventory File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the ATP Inventory Summary page.
 *
 * @param {AtpInventorySummaryPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpInventorySummaryPageViewed(
	props: AtpInventorySummaryPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the ATP Inventory Summary page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Inventory Summary Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'ATP Inventory Summary Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Inventory Summary Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user downloads the order file
 *
 * @param {AtpOrdersFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpOrdersFileDownloaded(
	props: AtpOrdersFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user downloads the order file',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_name: {
						description: 'The name of the file the user downloaded',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Orders File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'ATP Orders File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Orders File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user filters the ATP Parameters.
 *
 * @param {AtpParametersFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpParametersFiltered(
	props: AtpParametersFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user filters the ATP Parameters.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					parameters_count: {
						description: 'The count of parameters returned on the list',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'parameters_count',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Parameters Filtered',
		type: 'object',
	}
	const message = {
		event: 'ATP Parameters Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Parameters Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the ATP Parameters page.
 *
 * @param {AtpParametersPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function atpParametersPageViewed(
	props: AtpParametersPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the ATP Parameters page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'ATP Parameters Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'ATP Parameters Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ATP Parameters Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses a page in BEES ONE Inventory Management
 *
 * @param {AccessedPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accessedPage(
	props: AccessedPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user accesses a page in BEES ONE Inventory Management',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Accessed Page',
		type: 'object',
	}
	const message = {
		event: 'Accessed Page',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Accessed Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to add a new ATP parameter
 *
 * @param {AddAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAtpParameter(
	props: AddAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to add a new ATP parameter',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_type: {
						description: 'The type of the parameter added',
						type: ['string', 'null'],
					},
					reservation_window: {
						description: 'The number of days of the reservation window on ATP',
						type: ['object', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_type',
					'reservation_window',
					'user_email',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Add ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to add a new rule of Critical Stock
 *
 * @param {AddCriticalStockRule} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addCriticalStockRule(
	props: AddCriticalStockRule,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to add a new rule of Critical Stock',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					limit_qty: {
						description: 'The limit quantity for this SKU in this rule',
						type: ['number', 'null'],
					},
					pocs_selected_qty: {
						description: 'The POCs quantity affected for this rule',
						type: ['number', 'null'],
					},
					region_selected: {
						description: 'The name of the region selected',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					rule_name: {
						description: 'The name of the rule created',
						type: ['string', 'null'],
					},
					rule_type: {
						description: 'The rule type seleted for the rule',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'limit_qty',
					'pocs_selected_qty',
					'request_status',
					'request_text_response',
					'rule_name',
					'rule_type',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Critical Stock Rule',
		type: 'object',
	}
	const message = {
		event: 'Add Critical Stock Rule',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Critical Stock Rule',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on load more for na specific day.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParamenterProgressDetailsCardViewed(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on load more for na specific day.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Paramenter Progress Details Card Viewed',
		type: 'object',
	}
	const message = {
		event: 'Availability Paramenter Progress Details Card Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Paramenter Progress Details Card Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user expands the line of delivey capacity availability for one DDC.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParamenterProgressDetailsExpanded(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user expands the line of delivey capacity availability for one DDC.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Paramenter Progress Details Expanded',
		type: 'object',
	}
	const message = {
		event: 'Availability Paramenter Progress Details Expanded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Paramenter Progress Details Expanded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user views the Capacity Availability Parameters screen.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParametersPageViewed(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user views the Capacity Availability Parameters screen.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Parameters Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Availability Parameters Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Parameters Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a button in the page
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
	props: ButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on a button in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					button_name: {
						description: 'The name of the button clicked',
						type: ['string', 'null'],
					},
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'button_name',
					'country_selected',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Button Clicked',
		type: 'object',
	}
	const message = {
		event: 'Button Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user abort the creating or edition of a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelSavingAvailabilityParamenter(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user abort the creating or edition of a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Cancel Saving Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Cancel Saving Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Cancel Saving Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to sort a column in the page
 *
 * @param {ColumnSorting} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function columnSorting(
	props: ColumnSorting,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to sort a column in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					column_clicked: {
						description: 'The name of the column on which sorting was done',
						type: ['string', 'null'],
					},
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'column_clicked',
					'country_selected',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Column Sorting',
		type: 'object',
	}
	const message = {
		event: 'Column Sorting',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Column Sorting',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Yes" button on modal confirmation
 *
 * @param {ConfirmedUnblock} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmedUnblock(
	props: ConfirmedUnblock,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Yes" button on modal confirmation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action_status: {
						description: 'The status action done',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
				},
				required: ['action_status', 'request_status', 'request_text_response'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Confirmed Unblock',
		type: 'object',
	}
	const message = {
		event: 'Confirmed Unblock',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Confirmed Unblock',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Yes" button on modal confirmation
 *
 * @param {ConfirmedUnblockByPoc} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmedUnblockByPoc(
	props: ConfirmedUnblockByPoc,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Yes" button on modal confirmation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action_status: {
						description: 'The status action done',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
				},
				required: ['action_status', 'request_status', 'request_text_response'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Confirmed Unblock By POC',
		type: 'object',
	}
	const message = {
		event: 'Confirmed Unblock By POC',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Confirmed Unblock By POC',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Confirm" button on modal confirmation
 *
 * @param {ConfirmedUpload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmedUpload(
	props: ConfirmedUpload,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Confirm" button on modal confirmation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action_status: {
						description: 'The status action done',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
				},
				required: ['action_status', 'request_status', 'request_text_response'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Confirmed Upload',
		type: 'object',
	}
	const message = {
		event: 'Confirmed Upload',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Confirmed Upload',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the Consult Active Limits page.
 *
 * @param {ConsultActiveLimitsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consultActiveLimitsPageViewed(
	props: ConsultActiveLimitsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the Consult Active Limits page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Consult Active Limits Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Consult Active Limits Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Consult Active Limits Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user removes a DDC Limit.
 *
 * @param {DdcLimitRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcLimitRemoved(
	props: DdcLimitRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user removes a DDC Limit.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					limit_removal_data: {
						description: 'The properties related to the removal of a limit.',
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'limit_removal_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DDC Limit Removed',
		type: 'object',
	}
	const message = {
		event: 'DDC Limit Removed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DDC Limit Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user donwload the DDC template
 *
 * @param {DdcTemplateDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcTemplateDownloaded(
	props: DdcTemplateDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user donwload the DDC template',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DDC Template Downloaded',
		type: 'object',
	}
	const message = {
		event: 'DDC Template Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DDC Template Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to delete an ATP Parameter in the page
 *
 * @param {DeleteAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteAtpParameter(
	props: DeleteAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to delete an ATP Parameter in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_type: {
						description: 'The type of the parameter added',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_type',
					'user_email',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Delete ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Delete ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Delete ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user deletes an ATP Parameter.
 *
 * @param {DeleteAnAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteAnAtpParameter(
	props: DeleteAnAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user deletes an ATP Parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_id: {
						description: 'The Id of the parameter that is edit by the user',
						type: ['string', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_id',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Delete An ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Delete An ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Delete An ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user delete a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteAvailabilityParameter(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user delete a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Delete Availability Parameter',
		type: 'object',
	}
	const message = {
		event: 'Delete Availability Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Delete Availability Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to delete a critical stock rule
 *
 * @param {DeleteCriticalStockRule} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteCriticalStockRule(
	props: DeleteCriticalStockRule,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to delete a critical stock rule',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					critical_level_value: {
						description: 'The critical stock value parametrized',
						type: ['integer', 'null'],
					},
					limit_qty: {
						description: 'The limit quantity for this SKU in this rule',
						type: ['number', 'null'],
					},
					pocs_selected_qty: {
						description: 'The POCs quantity affected for this rule',
						type: ['number', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					rule_id: {
						description: 'The Id of the rule created',
						type: ['string', 'null'],
					},
					rule_name: {
						description: 'The name of the rule created',
						type: ['string', 'null'],
					},
					rule_type: {
						description: 'The rule type seleted for the rule',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'critical_level_value',
					'limit_qty',
					'pocs_selected_qty',
					'request_status',
					'request_text_response',
					'rule_id',
					'rule_name',
					'rule_type',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Delete Critical Stock Rule',
		type: 'object',
	}
	const message = {
		event: 'Delete Critical Stock Rule',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Delete Critical Stock Rule',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to download an information from the page
 *
 * @param {DownloadRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadRequested(
	props: DownloadRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to download an information from the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					region_selected: {
						description: 'The name of the region selected',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					sku_searched: {
						description: 'THe text inputed in SKU field',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'screen_tab_selected',
					'sku_searched',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Download Requested',
		type: 'object',
	}
	const message = {
		event: 'Download Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Download Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to edit an ATP Parameter in the page
 *
 * @param {EditAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editAtpParameter(
	props: EditAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to edit an ATP Parameter in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Edit ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Edit ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Edit ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user edits an ATP Parameter.
 *
 * @param {EditAnAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editAnAtpParameter(
	props: EditAnAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user edits an ATP Parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_id: {
						description: 'The Id of the parameter that is edit by the user',
						type: ['string', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_id',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Edit An ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Edit An ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Edit An ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to apply changes in a critical stock rule
 *
 * @param {EditCriticalStockRule} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editCriticalStockRule(
	props: EditCriticalStockRule,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to apply changes in a critical stock rule',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					critical_level_value: {
						description: 'The critical stock value parametrized',
						type: ['integer', 'null'],
					},
					pocs_selected_qty: {
						description: 'The POCs quantity affected for this rule',
						type: ['number', 'null'],
					},
					previous_critical_level_value: {
						description: 'The critical stock value before the edit',
						type: ['integer', 'null'],
					},
					previous_rule_status: {
						description: 'The Status of the rule before the edit',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					rule_id: {
						description: 'The Id of the rule created',
						type: ['string', 'null'],
					},
					rule_status: {
						description: 'The new status of the rule',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'critical_level_value',
					'pocs_selected_qty',
					'previous_critical_level_value',
					'previous_rule_status',
					'request_status',
					'request_text_response',
					'rule_id',
					'rule_status',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Edit Critical Stock Rule',
		type: 'object',
	}
	const message = {
		event: 'Edit Critical Stock Rule',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Edit Critical Stock Rule',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Understand the User Behavior through the elements cicked in the page
 *
 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	props: ElementClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Understand the User Behavior through the elements cicked in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					element_label: {
						description: 'The element text on the front end with EN copy',
						type: ['string', 'null'],
					},
					element_location_name: {
						description:
							'The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name',
						type: ['string', 'null'],
					},
					element_location_type: {
						description: 'The category of the object that anchors the button',
						type: ['string', 'null'],
					},
					element_name: {
						description: 'The element name on the back end',
						type: ['string', 'null'],
					},
					element_type: {
						description: 'The element type',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'(IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'(IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'(IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with localization.',
						type: ['string', 'null'],
					},
					page_name: {
						description: 'The name of the page.',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered.',
						type: ['string', 'null'],
					},
					user_country: {
						description: "Logged user's country",
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'(IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'(IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description:
							'(IF APPLICABLE) The view level the action is being executed',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'page_label',
					'page_name',
					'page_url',
					'user_permission_group',
					'user_email',
					'user_country',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'element_location_type',
					'element_location_name',
					'element_label',
					'element_name',
					'element_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Element Clicked',
		type: 'object',
	}
	const message = {
		event: 'Element Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Element Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user downloads an Error File.
 *
 * @param {ErrorFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorFileDownloaded(
	props: ErrorFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user downloads an Error File.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_id: {
						description: 'The file Id of the user log viewed.',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'file_id',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Error File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Error File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Error File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user loads the Error Log.
 *
 * @param {ErrorLogLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorLogLoaded(
	props: ErrorLogLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user loads the Error Log.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_id: {
						description: 'The file Id of the user log viewed.',
						type: ['string', 'null'],
					},
					line_count: {
						description: 'The count of error lines on the file.',
						type: ['number', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'file_id',
					'line_count',
					'screen_name',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Error Log Loaded',
		type: 'object',
	}
	const message = {
		event: 'Error Log Loaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Error Log Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user downloads an export file.
 *
 * @param {ExportFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportFileDownloaded(
	props: ExportFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user downloads an export file.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_id: {
						description: 'The file Id of the user log viewed.',
						type: ['string', 'null'],
					},
					limit_type: {
						description:
							'The type of limit that the user are trying to work with. Permitted values: DC, POC',
						enum: ['DC', 'POC', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'file_id',
					'limit_type',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Export File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Export File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Export File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user loads the error modal for any file.
 *
 * @param {FileMonitoringErrorModalViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileMonitoringErrorModalViewed(
	props: FileMonitoringErrorModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user loads the error modal for any file.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_name: {
						description: 'The name of the file the user downloaded',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Monitoring Error Modal Viewed',
		type: 'object',
	}
	const message = {
		event: 'File Monitoring Error Modal Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Monitoring Error Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the File Monitoring page.
 *
 * @param {FileMonitoringPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileMonitoringPageViewed(
	props: FileMonitoringPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the File Monitoring page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Monitoring Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'File Monitoring Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Monitoring Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user uses the refresh button on the file monitoring page.
 *
 * @param {FileMonitoringQueryRefresh} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileMonitoringQueryRefresh(
	props: FileMonitoringQueryRefresh,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user uses the refresh button on the file monitoring page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Monitoring Query Refresh',
		type: 'object',
	}
	const message = {
		event: 'File Monitoring Query Refresh',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Monitoring Query Refresh',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Understand the User Behavior through the files processed in the application
 *
 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileProcessed(
	props: FileProcessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Understand the User Behavior through the files processed in the application',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					file_format: {
						description:
							"This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.",
						enum: ['CSV', 'TXT', 'PDF', 'PNG', 'XLS', null],
						type: ['string', 'null'],
					},
					file_has_headers: {
						description:
							'When the file is tabular (CSV, XLS), this indicates if it has headers',
						type: ['boolean', 'null'],
					},
					file_number_of_columns: {
						description:
							'When the file is tabular (CSV, XLS), this indicates the number of columns',
						type: ['number', 'null'],
					},
					file_number_of_rows: {
						description:
							'When the file is tabular (CSV, XLS), this indicates the number of rows (headers included)',
						type: ['number', 'null'],
					},
					file_process_type: {
						description:
							'This identifies rather the process is a Download (starting point) or an Upload (conclusion point)',
						enum: ['DOWNLOAD_STARTED', 'UPLOAD_COMPLETED', null],
						type: ['string', 'null'],
					},
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'(IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'(IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'(IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with localization.',
						type: ['string', 'null'],
					},
					page_name: {
						description: 'The name of the page.',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered.',
						type: ['string', 'null'],
					},
					user_country: {
						description: "Logged user's country",
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'(IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'(IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description:
							'(IF APPLICABLE) The view level the action is being executed',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'page_label',
					'page_name',
					'page_url',
					'user_permission_group',
					'user_email',
					'user_country',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'file_process_type',
					'file_format',
					'file_has_headers',
					'file_size',
					'file_number_of_columns',
					'file_number_of_rows',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Processed',
		type: 'object',
	}
	const message = {
		event: 'File Processed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Processed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the na file to upload in his computer
 *
 * @param {FileSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileSelected(
	props: FileSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects the na file to upload in his computer ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					error_message: {
						description:
							'The error message showed to the user when he uploaded a wrong file. Sample:  Invalid file type, select a CSV file type',
						type: ['string', 'null'],
					},
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					file_type: {
						description:
							'The file extention that have been uploaded on the platform. Sample: .CSV, .XLS, .XLSX',
						enum: ['CSV', 'XLS', 'XLSX', null],
						type: ['string', 'null'],
					},
				},
				required: ['file_size', 'file_type'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Selected',
		type: 'object',
	}
	const message = {
		event: 'File Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user loads the File Upload History.
 *
 * @param {FileUploadHistoryLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileUploadHistoryLoaded(
	props: FileUploadHistoryLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user loads the File Upload History.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					pagination: {
						description: 'The pagination properties selected by the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'pagination',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Upload History Loaded',
		type: 'object',
	}
	const message = {
		event: 'File Upload History Loaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Upload History Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user filters exported files.
 *
 * @param {FilesExportFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filesExportFiltered(
	props: FilesExportFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user filters exported files.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Files Export Filtered',
		type: 'object',
	}
	const message = {
		event: 'Files Export Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Files Export Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects an option of a filter component
 *
 * @param {FiltersSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filtersSelected(
	props: FiltersSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects an option of a filter component',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filters_selected: {
						description: 'The filters selected by user on the screen',
						type: ['object', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filters_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Filters Selected',
		type: 'object',
	}
	const message = {
		event: 'Filters Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Filters Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user imports a file of DC Exception
 *
 * @param {ImportDcExceptionFile} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function importDcExceptionFile(
	props: ImportDcExceptionFile,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user imports a file of DC Exception',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					name_file_selected: {
						description: 'The Name of the upload file selected',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'name_file_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Import DC Exception File',
		type: 'object',
	}
	const message = {
		event: 'Import DC Exception File',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Import DC Exception File',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user filters the Inventory Summary.
 *
 * @param {InventorySummaryFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inventorySummaryFiltered(
	props: InventorySummaryFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user filters the Inventory Summary.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Inventory Summary Filtered',
		type: 'object',
	}
	const message = {
		event: 'Inventory Summary Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Inventory Summary Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user filters limits by DDC.
 *
 * @param {LimitByDdcFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitByDdcFiltered(
	props: LimitByDdcFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user filters limits by DDC.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit By DDC Filtered',
		type: 'object',
	}
	const message = {
		event: 'Limit By DDC Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit By DDC Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user filters limits by POC.
 *
 * @param {LimitByPocFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitByPocFiltered(
	props: LimitByPocFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user filters limits by POC.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit By POC Filtered',
		type: 'object',
	}
	const message = {
		event: 'Limit By POC Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit By POC Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user searches limits by POC.
 *
 * @param {LimitByPocSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitByPocSearched(
	props: LimitByPocSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user searches limits by POC.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					limits_count: {
						description: 'The count of SKUs limited for the customer',
						type: ['number', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					search_text: {
						description:
							'The current text on the search bar (Null if there is none)',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'limits_count',
					'screen_name',
					'search_text',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit By POC Searched',
		type: 'object',
	}
	const message = {
		event: 'Limit By POC Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit By POC Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user canceled sending limits
 *
 * @param {LimitCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitCanceled(
	props: LimitCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user canceled sending limits',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit Canceled',
		type: 'object',
	}
	const message = {
		event: 'Limit Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user uploads a Limit File.
 *
 * @param {LimitFileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitFileUploaded(
	props: LimitFileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user uploads a Limit File.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					limit_type: {
						description:
							'The type of limit that the user are trying to work with. Permitted values: DC, POC',
						enum: ['DC', 'POC', null],
						type: ['string', 'null'],
					},
					limit_validity: {
						description: 'The validity of the limit.',
						type: ['object', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					response: {
						description: 'The response code of the API request',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'file_size',
					'limit_type',
					'limit_validity',
					'request_text_response',
					'response',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit File Uploaded',
		type: 'object',
	}
	const message = {
		event: 'Limit File Uploaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the type of limit he wants to send
 *
 * @param {LimitSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitSelected(
	props: LimitSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects the type of limit he wants to send',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					limit_type: {
						description:
							'The type of limit that the user are trying to work with. Permitted values: DC, POC',
						enum: ['DC', 'POC', null],
						type: ['string', 'null'],
					},
				},
				required: ['limit_type'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit Selected',
		type: 'object',
	}
	const message = {
		event: 'Limit Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user confirms sending limits
 *
 * @param {LimitSubmited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitSubmited(
	props: LimitSubmited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user confirms sending limits',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					failure_message: {
						description:
							'The failure message showed to the user if something wrong happens. ',
						type: ['string', 'null'],
					},
					limit_submited: {
						description:
							'The confirmation that the user wants to proceed with the limit process. Sample: Yes',
						type: ['string', 'null'],
					},
					sucess_message: {
						description: 'The success message presented to the user',
						type: ['string', 'null'],
					},
				},
				required: ['limit_submited', 'sucess_message'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limit Submited',
		type: 'object',
	}
	const message = {
		event: 'Limit Submited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limit Submited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the Limited SKU Management page.
 *
 * @param {LimitedSkuManagementPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitedSkuManagementPageViewed(
	props: LimitedSkuManagementPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the Limited SKU Management page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limited SKU Management Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Limited SKU Management Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limited SKU Management Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user requests an export file for limits.
 *
 * @param {LimitsExportFileRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitsExportFileRequested(
	props: LimitsExportFileRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user requests an export file for limits.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					filter_data: {
						description:
							'The query parameter used for filtering on the screen.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'filter_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limits Export File Requested',
		type: 'object',
	}
	const message = {
		event: 'Limits Export File Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limits Export File Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user acesses the Limits management page
 *
 * @param {LimitsPageAccessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitsPageAccessed(
	props: LimitsPageAccessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user acesses the Limits management page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'The country selected on the country selector. Sample: BR, EC, MX',
						enum: [
							'AR',
							'BR',
							'CA',
							'CO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_role: {
						description:
							'The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator',
						enum: [
							'ADMIN',
							'DDC_OPERATOR',
							'INVENTORY_VISIBILITY',
							'REGION_OPERATOR',
							'VIEWER',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['country', 'screen_name', 'user_role'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limits Page Accessed',
		type: 'object',
	}
	const message = {
		event: 'Limits Page Accessed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limits Page Accessed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user acesses the limits upload page
 *
 * @param {LimitsUploadPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitsUploadPage(
	props: LimitsUploadPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user acesses the limits upload page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'The country selected on the country selector. Sample: BR, EC, MX',
						enum: [
							'AR',
							'BR',
							'CA',
							'CO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_role: {
						description:
							'The access group associated to the user. Sample: LOAT InventoryVisibility, DDCOperator',
						enum: [
							'ADMIN',
							'DDC_OPERATOR',
							'INVENTORY_VISIBILITY',
							'REGION_OPERATOR',
							'VIEWER',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['country', 'screen_name', 'user_role'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limits Upload Page',
		type: 'object',
	}
	const message = {
		event: 'Limits Upload Page',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limits Upload Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the Limits Upload page.
 *
 * @param {LimitsUploadPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function limitsUploadPageViewed(
	props: LimitsUploadPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the Limits Upload page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Limits Upload Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Limits Upload Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Limits Upload Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user uploads a POC Exception File.
 *
 * @param {PocExceptionFileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocExceptionFileUploaded(
	props: PocExceptionFileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user uploads a POC Exception File.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					response: {
						description: 'The response code of the API request',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'file_size',
					'request_text_response',
					'response',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'POC Exception File Uploaded',
		type: 'object',
	}
	const message = {
		event: 'POC Exception File Uploaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'POC Exception File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user downloads a POC Exception Template.
 *
 * @param {PocExceptionTemplateDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocExceptionTemplateDownloaded(
	props: PocExceptionTemplateDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user downloads a POC Exception Template.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'POC Exception Template Downloaded',
		type: 'object',
	}
	const message = {
		event: 'POC Exception Template Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'POC Exception Template Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user removes a POC Limit..
 *
 * @param {PocLimitRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocLimitRemoved(
	props: PocLimitRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'This event is triggered when the user removes a POC Limit..',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					customer_count: {
						description:
							'The count of customers affected by the limit removal.',
						type: ['number', 'null'],
					},
					ddc_id: {
						description: 'The array of DDC ids affected by the limits',
						type: ['array', 'null'],
					},
					limit_removal_data: {
						description: 'The properties related to the removal of a limit.',
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'customer_count',
					'ddc_id',
					'limit_removal_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'POC Limit Removed',
		type: 'object',
	}
	const message = {
		event: 'POC Limit Removed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'POC Limit Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user donwload the POC template
 *
 * @param {PocTemplateDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocTemplateDownloaded(
	props: PocTemplateDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user donwload the POC template',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'POC Template Downloaded',
		type: 'object',
	}
	const message = {
		event: 'POC Template Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'POC Template Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user views the POCs Exception Management page.
 *
 * @param {PoCsExeptionManagementPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function poCsExeptionManagementPageViewed(
	props: PoCsExeptionManagementPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user views the POCs Exception Management page.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'screen_tab_selected',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'POCs Exeption Management Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'POCs Exeption Management Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'POCs Exeption Management Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Understand the User Behavior through the tracking of the pages at the Inventory Management.
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Understand the User Behavior through the tracking of the pages at the Inventory Management.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'(IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'(IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'(IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with localization.',
						type: ['string', 'null'],
					},
					page_name: {
						description: 'The name of the page.',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered.',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
						type: ['string', 'null'],
					},
					user_country: {
						description: "Logged user's country",
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'(IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'(IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description:
							'(IF APPLICABLE) The view level the action is being executed',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'page_label',
					'page_name',
					'page_url',
					'user_permission_group',
					'user_email',
					'user_country',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'referrer',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a button to change the pagination option in the page
 *
 * @param {PaginationButtonsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paginationButtonsClicked(
	props: PaginationButtonsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a button to change the pagination option in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					pagination_button_clicked: {
						description: 'The Text/Name/Value of button clicked',
						type: ['string', 'null'],
					},
					pagination_id: {
						description: 'The Identification of the table',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'pagination_button_clicked',
					'pagination_id',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Pagination Buttons Clicked',
		type: 'object',
	}
	const message = {
		event: 'Pagination Buttons Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Pagination Buttons Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to see details os a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parametersDetailsViewed(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to see details os a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Parameters Details Viewed',
		type: 'object',
	}
	const message = {
		event: 'Parameters Details Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Parameters Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the parameters list.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parametersListFiltered(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the parameters list.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Parameters List Filtered',
		type: 'object',
	}
	const message = {
		event: 'Parameters List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Parameters List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Proceed" button
 *
 * @param {ProceedUpload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function proceedUpload(
	props: ProceedUpload,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on "Proceed" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					end_date_time: {
						description:
							'The end date and time of the validity of the limit to be applied',
						format: 'date-time',
						type: ['string', 'null'],
					},
					name_file_selected: {
						description: 'The Name of the upload file selected',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					start_date_time: {
						description:
							'The start date and time of the validity of the limit to be applied',
						format: 'date-time',
						type: ['string', 'null'],
					},
					upload_file_type_selected: {
						description: 'The option selected for upload',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'end_date_time',
					'name_file_selected',
					'screen_selected',
					'screen_tab_selected',
					'start_date_time',
					'upload_file_type_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Proceed Upload',
		type: 'object',
	}
	const message = {
		event: 'Proceed Upload',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Proceed Upload',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered clicks on the "Go to the file monitoring" button.
 *
 * @param {RedirectToFileMonitoringPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function redirectToFileMonitoringPage(
	props: RedirectToFileMonitoringPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered clicks on the "Go to the file monitoring" button.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Redirect to File Monitoring Page',
		type: 'object',
	}
	const message = {
		event: 'Redirect to File Monitoring Page',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Redirect to File Monitoring Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered clicks on the export button after selecting the type of report he wants to generate.
 *
 * @param {RequestAtpReportsGeneration} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestAtpReportsGeneration(
	props: RequestAtpReportsGeneration,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered clicks on the export button after selecting the type of report he wants to generate.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Request ATP Reports Generation',
		type: 'object',
	}
	const message = {
		event: 'Request ATP Reports Generation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Request ATP Reports Generation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a SKU search on the screen
 *
 * @param {SkuSearchPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchPerformed(
	props: SkuSearchPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user performs a SKU search on the screen ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description: 'The array of DDC ids affected by the limits',
						type: ['array', 'null'],
					},
					limit_type: {
						description:
							'The type of limit that the user are trying to work with. Permitted values: DC, POC',
						enum: ['DC', 'POC', null],
						type: ['string', 'null'],
					},
					region: {
						description:
							'The region selected by the user. Sample: Geo SP, Geo RJ-ES',
						type: ['string', 'null'],
					},
					search_word: {
						description:
							'The word used as a search expression. Sample: Brahma, Skol, 0000294, 253',
						type: ['string', 'null'],
					},
				},
				required: ['ddc_id', 'limit_type', 'region'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'SKU Search Performed',
		type: 'object',
	}
	const message = {
		event: 'SKU Search Performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user canceled the unlimit process
 *
 * @param {SkuUnlimitCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuUnlimitCanceled(
	props: SkuUnlimitCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user canceled the unlimit process',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'SKU Unlimit Canceled',
		type: 'object',
	}
	const message = {
		event: 'SKU Unlimit Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'SKU Unlimit Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the unlimit button and see the confirmation message
 *
 * @param {SkuUnlimitSelect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuUnlimitSelect(
	props: SkuUnlimitSelect,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the unlimit button and see the confirmation message',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description: 'The array of DDC ids affected by the limits',
						type: ['array', 'null'],
					},
					sku_id: {
						description:
							'The SKU Ids affected by the limits. Sample: 0000253, 000000000000085684',
						type: ['string', 'null'],
					},
				},
				required: ['ddc_id', 'sku_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'SKU Unlimit Select',
		type: 'object',
	}
	const message = {
		event: 'SKU Unlimit Select',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'SKU Unlimit Select',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user confirms the SKU unlimit
 *
 * @param {SkuUnlimited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuUnlimited(
	props: SkuUnlimited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user confirms the SKU unlimit',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					failure_message: {
						description:
							'The failure message showed to the user if something wrong happens. ',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					success_message: {
						description: 'The success message presented to the user',
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'SKU Unlimited',
		type: 'object',
	}
	const message = {
		event: 'SKU Unlimited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'SKU Unlimited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user saves a new ATP Parameter.
 *
 * @param {SaveNewAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function saveNewAtpParameter(
	props: SaveNewAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user saves a new ATP Parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					payload_data: {
						description: 'The informations send by or retuned to the user.',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'payload_data',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Save New ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Save New ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Save New ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on create a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savingAvailabilityParamenter(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on create a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saving Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Saving Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saving Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Understand the User Behavior through the data from searchs applieds in the system
 *
 * @param {SearchApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchApplied(
	props: SearchApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Understand the User Behavior through the data from searchs applieds in the system',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'(IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'(IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'(IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					number_of_results: {
						description: 'The number of results from that search',
						type: ['number', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with localization.',
						type: ['string', 'null'],
					},
					page_name: {
						description: 'The name of the page.',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered.',
						type: ['string', 'null'],
					},
					search_query: {
						description: 'The string that was searched',
						type: ['string', 'null'],
					},
					user_country: {
						description: "Logged user's country",
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'(IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'(IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description:
							'(IF APPLICABLE) The view level the action is being executed',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'page_label',
					'page_name',
					'page_url',
					'user_permission_group',
					'user_email',
					'user_country',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'search_query',
					'number_of_results',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search Applied',
		type: 'object',
	}
	const message = {
		event: 'Search Applied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a search by POC ID
 *
 * @param {SearchByPocIdPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchByPocIdPerformed(
	props: SearchByPocIdPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user performs a search by POC ID',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					limited_skus_qty: {
						description: 'The quantity of skus limited for this POC',
						type: ['number', 'null'],
					},
					poc_details_account: {
						description: 'The name of the POC',
						type: ['string', 'null'],
					},
					poc_details_id_dc: {
						description: 'The code of the DC',
						type: ['string', 'null'],
					},
					poc_details_id_dc_exception: {
						description: 'The code of the DC Exception',
						type: ['string', 'null'],
					},
					poc_selected: {
						description: 'The Text inputed in Search by POC Id field',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'limited_skus_qty',
					'poc_details_account',
					'poc_details_id_dc',
					'poc_details_id_dc_exception',
					'poc_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search By POC Id Performed',
		type: 'object',
	}
	const message = {
		event: 'Search By POC Id Performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search By POC Id Performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a search by SKU Id
 *
 * @param {SearchBySkuIdPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchBySkuIdPerformed(
	props: SearchBySkuIdPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user performs a search by SKU Id',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					text_searched: {
						description: 'Text inputed in the search field',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'text_searched',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search By SKU Id Performed',
		type: 'object',
	}
	const message = {
		event: 'Search By SKU Id Performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search By SKU Id Performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a search in the page using a search box
 *
 * @param {SearchPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchPerformed(
	props: SearchPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user performs a search in the page using a search box',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					region_selected: {
						description: 'The name of the region selected',
						type: ['string', 'null'],
					},
					request_status: {
						description: 'The status code of the sent request',
						type: ['string', 'null'],
					},
					request_text_response: {
						description:
							'The text message that appears in the response to the user',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					text_searched: {
						description: 'Text inputed in the search field',
						type: ['string', 'null'],
					},
					total_dc: {
						description: 'The total amount of DCs after the applied filter',
						type: ['number', 'null'],
					},
					total_sku: {
						description: 'The total amount of SKUs after the applied filter',
						type: ['number', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'request_status',
					'request_text_response',
					'screen_selected',
					'screen_tab_selected',
					'text_searched',
					'total_dc',
					'total_sku',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Search Performed',
		type: 'object',
	}
	const message = {
		event: 'Search Performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search Performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered clicks on the export button before selecting the type of report he wants to generate.
 *
 * @param {SelectionOfFilesForDownload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectionOfFilesForDownload(
	props: SelectionOfFilesForDownload,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered clicks on the export button before selecting the type of report he wants to generate.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					orders_report_enabled: {
						description:
							'Indicates whether order file export was available to the user',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'orders_report_enabled',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Selection Of Files for Download',
		type: 'object',
	}
	const message = {
		event: 'Selection Of Files for Download',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Selection Of Files for Download',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is triggered when the user starts creating an ATP Parameter.
 *
 * @param {StartCreatingAnAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startCreatingAnAtpParameter(
	props: StartCreatingAnAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event is triggered when the user starts creating an ATP Parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_id: {
						description: 'The Id of the parameter that is edit by the user',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored.',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_id',
					'screen_name',
					'user_email',
					'vendor_id_selected',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Start Creating An ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'Start Creating An ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Start Creating An ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts creating a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startCreatingAvailabilityParamenter(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts creating a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Start Creating Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Start Creating Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Start Creating Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts editing a capacity availability parameter.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startEditAvailabilityParamenter(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts editing a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Start Edit Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Start Edit Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Start Edit Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the strategy progress.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function strategyProgressFiltered(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the strategy progress.',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Strategy Progress Filtered',
		type: 'object',
	}
	const message = {
		event: 'Strategy Progress Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Strategy Progress Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user views the summary page
 *
 * @param {SummaryPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function summaryPageViewed(
	props: SummaryPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user views the summary page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description: 'The array of DDC ids affected by the limits',
						type: ['array', 'null'],
					},
					limits_end_date: {
						description: 'The limits final end date. Sample: 23/09/2022 16:51',
						format: 'date-time',
						type: ['string', 'null'],
					},
					limits_start_date: {
						description:
							'The limits final start date. Sample: 23/09/2022 16:51',
						format: 'date-time',
						type: ['string', 'null'],
					},
					sku_count: {
						description:
							'The count of SKUs affected with limits for each DDC. Sample: 1,2,3,4',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'limits_end_date',
					'limits_start_date',
					'sku_count',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Summary Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Summary Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Summary Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on download templates buttons
 *
 * @param {TemplateDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function templateDownloaded(
	props: TemplateDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on download templates buttons',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					template_file_selected: {
						description: 'The name of the template file selected',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'screen_selected',
					'screen_tab_selected',
					'template_file_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Template Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Template Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Template Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on unblock buttons
 *
 * @param {UnblockAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unblockAction(
	props: UnblockAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on unblock buttons',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					data_grid_code_and_name_sku: {
						description: 'The SKU code and name',
						type: ['string', 'null'],
					},
					data_grid_duration: {
						description: 'The number of days the applied limit is valid',
						type: ['string', 'null'],
					},
					data_grid_final_validity: {
						description: 'The end date and time of the limit',
						format: 'date-time',
						type: ['string', 'null'],
					},
					data_grid_inicial_validity: {
						description: 'The start date and time of the limit',
						format: 'date-time',
						type: ['string', 'null'],
					},
					data_grid_limit: {
						description: 'The limit value applied to the sku',
						type: ['string', 'null'],
					},
					data_grid_poc: {
						description: 'The Number of POCs affected by the limit',
						type: ['number', 'null'],
					},
					data_grid_status: {
						description: 'The limit status',
						type: ['string', 'null'],
					},
					region_selected: {
						description: 'The name of the region selected',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					sku_searched: {
						description: 'THe text inputed in SKU field',
						type: ['string', 'null'],
					},
					total_dc: {
						description: 'The total amount of DCs after the applied filter',
						type: ['number', 'null'],
					},
					total_dc_selected: {
						description: 'Total amount of selected DCs',
						type: ['number', 'null'],
					},
					total_sku: {
						description: 'The total amount of SKUs after the applied filter',
						type: ['number', 'null'],
					},
					total_sku_selected: {
						description: 'The total amount of selected SKUs',
						type: ['number', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'data_grid_code_and_name_sku',
					'data_grid_duration',
					'data_grid_final_validity',
					'data_grid_inicial_validity',
					'data_grid_limit',
					'data_grid_poc',
					'data_grid_status',
					'screen_selected',
					'screen_tab_selected',
					'sku_searched',
					'total_dc',
					'total_dc_selected',
					'total_sku',
					'total_sku_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Unblock Action',
		type: 'object',
	}
	const message = {
		event: 'Unblock Action',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Unblock Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to unblock limits by POC
 *
 * @param {UnblockActionByPoc} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unblockActionByPoc(
	props: UnblockActionByPoc,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to unblock limits by POC',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					poc_selected: {
						description: 'The Text inputed in Search by POC Id field',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
					vendor_selected: {
						description: 'The vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'poc_selected',
					'screen_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
					'vendor_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Unblock Action By POC',
		type: 'object',
	}
	const message = {
		event: 'Unblock Action By POC',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Unblock Action By POC',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click on the continue button
 *
 * @param {UploadLimitCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadLimitCompleted(
	props: UploadLimitCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click on the continue button ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					failure_message: {
						description:
							'The failure message showed to the user if something wrong happens. ',
						type: ['string', 'null'],
					},
					proceed_sending: {
						description:
							'The confirmation that the user wants to proceed with the limit upload. Permitted values: Yes',
						enum: ['YES', null],
						type: ['string', 'null'],
					},
				},
				required: ['proceed_sending'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Upload Limit Completed',
		type: 'object',
	}
	const message = {
		event: 'Upload Limit Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Upload Limit Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the users changes the vality date of the limits
 *
 * @param {ValidityEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function validityEdited(
	props: ValidityEdited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the users changes the vality date of the limits',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					new_end_date: {
						description:
							'The new limit start end, before changing. Sample: 23/09/2022 16:45',
						format: 'date-time',
						type: ['string', 'null'],
					},
					new_start_date: {
						description:
							'The new limit start date, before changing. Sample: 23/09/2022 16:45',
						format: 'date-time',
						type: ['string', 'null'],
					},
					previous_end_date: {
						description:
							'The previous limit start end, before changing. Sample: 23/09/2022 16:45',
						format: 'date-time',
						type: ['string', 'null'],
					},
					previous_start_date: {
						description:
							'The previous limit start date, before changing. Sample: 23/09/2022 16:45',
						format: 'date-time',
						type: ['string', 'null'],
					},
				},
				required: [
					'new_end_date',
					'new_start_date',
					'previous_end_date',
					'previous_start_date',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Validity Edited',
		type: 'object',
	}
	const message = {
		event: 'Validity Edited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Validity Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to view an ATP Parameter in the page
 *
 * @param {ViewAtpParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewAtpParameter(
	props: ViewAtpParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to view an ATP Parameter in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					parameter_type: {
						description: 'The type of the parameter added',
						type: ['string', 'null'],
					},
					reservation_window: {
						description: 'The number of days of the reservation window on ATP',
						type: ['object', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'parameter_type',
					'reservation_window',
					'user_email',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'View ATP Parameter',
		type: 'object',
	}
	const message = {
		event: 'View ATP Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'View ATP Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to view a critical stock rule in the page
 *
 * @param {ViewCriticalStockRule} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewCriticalStockRule(
	props: ViewCriticalStockRule,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to view a critical stock rule in the page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country_selected: {
						description: 'The Country selected on the screen',
						type: ['string', 'null'],
					},
					critical_level_value: {
						description: 'The critical stock value parametrized',
						type: ['integer', 'null'],
					},
					limit_type: {
						description:
							'The type of limit that the user are trying to work with. Permitted values: DC, POC',
						enum: ['DC', 'POC', null],
						type: ['string', 'null'],
					},
					pocs_selected_qty: {
						description: 'The POCs quantity affected for this rule',
						type: ['number', 'null'],
					},
					rule_id: {
						description: 'The Id of the rule created',
						type: ['string', 'null'],
					},
					rule_name: {
						description: 'The name of the rule created',
						type: ['string', 'null'],
					},
					rule_type: {
						description: 'The rule type seleted for the rule',
						type: ['string', 'null'],
					},
					screen_selected: {
						description: 'The name of the menu selected',
						type: ['string', 'null'],
					},
					screen_tab_selected: {
						description: 'The name of the tab selected by user',
						type: ['string', 'null'],
					},
					user_email: {
						description: "Logged user's email",
						type: ['string', 'null'],
					},
					user_timezone: {
						description: "Logged user's time zone",
						type: ['string', 'null'],
					},
					vendor_id_selected: {
						description: 'The Id of vendor selected on the screen',
						type: ['string', 'null'],
					},
				},
				required: [
					'country_selected',
					'critical_level_value',
					'limit_type',
					'pocs_selected_qty',
					'rule_id',
					'rule_name',
					'rule_type',
					'screen_selected',
					'screen_tab_selected',
					'user_email',
					'user_timezone',
					'vendor_id_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'View Critical Stock Rule',
		type: 'object',
	}
	const message = {
		event: 'View Critical Stock Rule',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'View Critical Stock Rule',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * This event is triggered when the user filters ATP Export files.
	 *
	 * @param {AtpExportFilesFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpExportFilesFiltered,
	/**
	 * This event is triggered when the user downloads the inventory custom report
	 *
	 * @param {AtpInventoryFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpInventoryFileDownloaded,
	/**
	 * This event is triggered when the user views the ATP Inventory Summary page.
	 *
	 * @param {AtpInventorySummaryPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpInventorySummaryPageViewed,
	/**
	 * This event is triggered when the user downloads the order file
	 *
	 * @param {AtpOrdersFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpOrdersFileDownloaded,
	/**
	 * This event is triggered when the user filters the ATP Parameters.
	 *
	 * @param {AtpParametersFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpParametersFiltered,
	/**
	 * This event is triggered when the user views the ATP Parameters page.
	 *
	 * @param {AtpParametersPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	atpParametersPageViewed,
	/**
	 * Triggered when the user accesses a page in BEES ONE Inventory Management
	 *
	 * @param {AccessedPage} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accessedPage,
	/**
	 * Triggered when the user clicks to add a new ATP parameter
	 *
	 * @param {AddAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addAtpParameter,
	/**
	 * Triggered when the user clicks to add a new rule of Critical Stock
	 *
	 * @param {AddCriticalStockRule} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addCriticalStockRule,
	/**
	 * Triggered when the user clicks on load more for na specific day.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParamenterProgressDetailsCardViewed,
	/**
	 * Triggered when the user expands the line of delivey capacity availability for one DDC.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParamenterProgressDetailsExpanded,
	/**
	 * Triggered when the user views the Capacity Availability Parameters screen.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParametersPageViewed,
	/**
	 * Triggered when the user clicks on a button in the page
	 *
	 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	buttonClicked,
	/**
	 * Triggered when the user abort the creating or edition of a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelSavingAvailabilityParamenter,
	/**
	 * Triggered when the user clicks to sort a column in the page
	 *
	 * @param {ColumnSorting} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	columnSorting,
	/**
	 * Triggered when the user clicks on "Yes" button on modal confirmation
	 *
	 * @param {ConfirmedUnblock} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmedUnblock,
	/**
	 * Triggered when the user clicks on "Yes" button on modal confirmation
	 *
	 * @param {ConfirmedUnblockByPoc} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmedUnblockByPoc,
	/**
	 * Triggered when the user clicks on "Confirm" button on modal confirmation
	 *
	 * @param {ConfirmedUpload} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmedUpload,
	/**
	 * This event is triggered when the user views the Consult Active Limits page.
	 *
	 * @param {ConsultActiveLimitsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	consultActiveLimitsPageViewed,
	/**
	 * This event is triggered when the user removes a DDC Limit.
	 *
	 * @param {DdcLimitRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcLimitRemoved,
	/**
	 * Triggered when the user donwload the DDC template
	 *
	 * @param {DdcTemplateDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcTemplateDownloaded,
	/**
	 * Triggered when the user clicks to delete an ATP Parameter in the page
	 *
	 * @param {DeleteAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteAtpParameter,
	/**
	 * This event is triggered when the user deletes an ATP Parameter.
	 *
	 * @param {DeleteAnAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteAnAtpParameter,
	/**
	 * Triggered when the user delete a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteAvailabilityParameter,
	/**
	 * Triggered when the user clicks to delete a critical stock rule
	 *
	 * @param {DeleteCriticalStockRule} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteCriticalStockRule,
	/**
	 * Triggered when the user click to download an information from the page
	 *
	 * @param {DownloadRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadRequested,
	/**
	 * Triggered when the user clicks to edit an ATP Parameter in the page
	 *
	 * @param {EditAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editAtpParameter,
	/**
	 * This event is triggered when the user edits an ATP Parameter.
	 *
	 * @param {EditAnAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editAnAtpParameter,
	/**
	 * Triggered when the user clicks to apply changes in a critical stock rule
	 *
	 * @param {EditCriticalStockRule} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editCriticalStockRule,
	/**
	 * Understand the User Behavior through the elements cicked in the page
	 *
	 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * This event is triggered when the user downloads an Error File.
	 *
	 * @param {ErrorFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorFileDownloaded,
	/**
	 * This event is triggered when the user loads the Error Log.
	 *
	 * @param {ErrorLogLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorLogLoaded,
	/**
	 * This event is triggered when the user downloads an export file.
	 *
	 * @param {ExportFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportFileDownloaded,
	/**
	 * This event is triggered when the user loads the error modal for any file.
	 *
	 * @param {FileMonitoringErrorModalViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileMonitoringErrorModalViewed,
	/**
	 * This event is triggered when the user views the File Monitoring page.
	 *
	 * @param {FileMonitoringPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileMonitoringPageViewed,
	/**
	 * This event is triggered when the user uses the refresh button on the file monitoring page.
	 *
	 * @param {FileMonitoringQueryRefresh} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileMonitoringQueryRefresh,
	/**
	 * Understand the User Behavior through the files processed in the application
	 *
	 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileProcessed,
	/**
	 * Triggered when the user selects the na file to upload in his computer
	 *
	 * @param {FileSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileSelected,
	/**
	 * This event is triggered when the user loads the File Upload History.
	 *
	 * @param {FileUploadHistoryLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileUploadHistoryLoaded,
	/**
	 * This event is triggered when the user filters exported files.
	 *
	 * @param {FilesExportFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filesExportFiltered,
	/**
	 * Triggered when the user selects an option of a filter component
	 *
	 * @param {FiltersSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filtersSelected,
	/**
	 * Triggered when the user imports a file of DC Exception
	 *
	 * @param {ImportDcExceptionFile} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	importDcExceptionFile,
	/**
	 * This event is triggered when the user filters the Inventory Summary.
	 *
	 * @param {InventorySummaryFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inventorySummaryFiltered,
	/**
	 * This event is triggered when the user filters limits by DDC.
	 *
	 * @param {LimitByDdcFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitByDdcFiltered,
	/**
	 * This event is triggered when the user filters limits by POC.
	 *
	 * @param {LimitByPocFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitByPocFiltered,
	/**
	 * This event is triggered when the user searches limits by POC.
	 *
	 * @param {LimitByPocSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitByPocSearched,
	/**
	 * Triggered when the user canceled sending limits
	 *
	 * @param {LimitCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitCanceled,
	/**
	 * This event is triggered when the user uploads a Limit File.
	 *
	 * @param {LimitFileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitFileUploaded,
	/**
	 * Triggered when the user selects the type of limit he wants to send
	 *
	 * @param {LimitSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitSelected,
	/**
	 * Triggered when the user confirms sending limits
	 *
	 * @param {LimitSubmited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitSubmited,
	/**
	 * This event is triggered when the user views the Limited SKU Management page.
	 *
	 * @param {LimitedSkuManagementPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitedSkuManagementPageViewed,
	/**
	 * This event is triggered when the user requests an export file for limits.
	 *
	 * @param {LimitsExportFileRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitsExportFileRequested,
	/**
	 * Triggered when the user acesses the Limits management page
	 *
	 * @param {LimitsPageAccessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitsPageAccessed,
	/**
	 * Triggered when the user acesses the limits upload page
	 *
	 * @param {LimitsUploadPage} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitsUploadPage,
	/**
	 * This event is triggered when the user views the Limits Upload page.
	 *
	 * @param {LimitsUploadPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	limitsUploadPageViewed,
	/**
	 * This event is triggered when the user uploads a POC Exception File.
	 *
	 * @param {PocExceptionFileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocExceptionFileUploaded,
	/**
	 * This event is triggered when the user downloads a POC Exception Template.
	 *
	 * @param {PocExceptionTemplateDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocExceptionTemplateDownloaded,
	/**
	 * This event is triggered when the user removes a POC Limit..
	 *
	 * @param {PocLimitRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocLimitRemoved,
	/**
	 * Triggered when the user donwload the POC template
	 *
	 * @param {PocTemplateDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocTemplateDownloaded,
	/**
	 * This event is triggered when the user views the POCs Exception Management page.
	 *
	 * @param {PoCsExeptionManagementPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	poCsExeptionManagementPageViewed,
	/**
	 * Understand the User Behavior through the tracking of the pages at the Inventory Management.
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggered when the user clicks on a button to change the pagination option in the page
	 *
	 * @param {PaginationButtonsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paginationButtonsClicked,
	/**
	 * Triggered when the user clicks to see details os a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parametersDetailsViewed,
	/**
	 * Triggered when the user filters the parameters list.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parametersListFiltered,
	/**
	 * Triggered when the user clicks on "Proceed" button
	 *
	 * @param {ProceedUpload} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	proceedUpload,
	/**
	 * This event is triggered clicks on the "Go to the file monitoring" button.
	 *
	 * @param {RedirectToFileMonitoringPage} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	redirectToFileMonitoringPage,
	/**
	 * This event is triggered clicks on the export button after selecting the type of report he wants to generate.
	 *
	 * @param {RequestAtpReportsGeneration} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	requestAtpReportsGeneration,
	/**
	 * Triggered when the user performs a SKU search on the screen
	 *
	 * @param {SkuSearchPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchPerformed,
	/**
	 * Triggered when the user canceled the unlimit process
	 *
	 * @param {SkuUnlimitCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuUnlimitCanceled,
	/**
	 * Triggered when the user clicks on the unlimit button and see the confirmation message
	 *
	 * @param {SkuUnlimitSelect} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuUnlimitSelect,
	/**
	 * Triggered when the user confirms the SKU unlimit
	 *
	 * @param {SkuUnlimited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuUnlimited,
	/**
	 * This event is triggered when the user saves a new ATP Parameter.
	 *
	 * @param {SaveNewAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	saveNewAtpParameter,
	/**
	 * Triggered when the user clicks on create a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savingAvailabilityParamenter,
	/**
	 * Understand the User Behavior through the data from searchs applieds in the system
	 *
	 * @param {SearchApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchApplied,
	/**
	 * Triggered when the user performs a search by POC ID
	 *
	 * @param {SearchByPocIdPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchByPocIdPerformed,
	/**
	 * Triggered when the user performs a search by SKU Id
	 *
	 * @param {SearchBySkuIdPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchBySkuIdPerformed,
	/**
	 * Triggered when the user performs a search in the page using a search box
	 *
	 * @param {SearchPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchPerformed,
	/**
	 * This event is triggered clicks on the export button before selecting the type of report he wants to generate.
	 *
	 * @param {SelectionOfFilesForDownload} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectionOfFilesForDownload,
	/**
	 * This event is triggered when the user starts creating an ATP Parameter.
	 *
	 * @param {StartCreatingAnAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startCreatingAnAtpParameter,
	/**
	 * Triggered when the user starts creating a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startCreatingAvailabilityParamenter,
	/**
	 * Triggered when the user starts editing a capacity availability parameter.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startEditAvailabilityParamenter,
	/**
	 * Triggered when the user filters the strategy progress.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	strategyProgressFiltered,
	/**
	 * Triggered when the user views the summary page
	 *
	 * @param {SummaryPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	summaryPageViewed,
	/**
	 * Triggered when the user clicks on download templates buttons
	 *
	 * @param {TemplateDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	templateDownloaded,
	/**
	 * Triggered when the user clicks on unblock buttons
	 *
	 * @param {UnblockAction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unblockAction,
	/**
	 * Triggered when the user clicks to unblock limits by POC
	 *
	 * @param {UnblockActionByPoc} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unblockActionByPoc,
	/**
	 * Triggered when the user click on the continue button
	 *
	 * @param {UploadLimitCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadLimitCompleted,
	/**
	 * Triggered when the users changes the vality date of the limits
	 *
	 * @param {ValidityEdited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	validityEdited,
	/**
	 * Triggered when the user clicks to view an ATP Parameter in the page
	 *
	 * @param {ViewAtpParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewAtpParameter,
	/**
	 * Triggered when the user clicks to view a critical stock rule in the page
	 *
	 * @param {ViewCriticalStockRule} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewCriticalStockRule,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2FdapMY6b4fwoZIK135lxCS6Fsh`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
