import React, { ReactElement, ChangeEvent } from 'react'
import { nanoid } from 'nanoid'
import { MenuItem, Select, FormControl } from '@material-ui/core'
import { FieldProps, Field } from 'formik'
import InputLabel from '../InputLabel'
import styles from '../styles'
import localStyles from './styles'
import FieldErrorMessage from '../FieldErrorMessage'

export interface SelectOptions {
	value: string | number
	label: string | number
	disabled?: boolean
}

type Props = {
	id: string
	name: string
	label?: string
	options: SelectOptions[]
	autoFocus?: boolean
	onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>) => void
	showError?: boolean
	disabled?: boolean
	helperText?: string
	disableGutters?: boolean
	disableTopGutter?: boolean
}

const InputSelect: React.FC<Props> = ({
	id,
	name,
	label,
	options,
	autoFocus,
	onChange,
	showError,
	disabled,
	helperText,
	disableGutters,
	disableTopGutter,
}: Props) => {
	const classes = styles({ disableGutters, disableTopGutter })
	const localClasses = localStyles()

	const menuItems = options?.map(
		({ value, label: labelOption, disabled: optionDisabled = false }: SelectOptions, key) => {
			return (
				<MenuItem
					data-testid="select-option"
					key={nanoid()}
					id={`${id}-${key + 1}`}
					value={value}
					disabled={optionDisabled}
				>
					{labelOption}
				</MenuItem>
			)
		},
	)

	return (
		<Field name={name}>
			{(fieldProps: FieldProps): ReactElement => {
				const shouldShowError =
					!!fieldProps.meta.error && (showError || (showError === undefined && fieldProps.form.submitCount > 0))
				return (
					<div className={classes.box}>
						{label && (
							<InputLabel
								label={label}
								htmlFor={id}
								error={shouldShowError}
								disabled={disabled}
								bottomSpacing={!helperText}
							/>
						)}
						<FormControl fullWidth className={localClasses.formcontrol} disabled={disabled}>
							{shouldShowError && <FieldErrorMessage id={id} message={fieldProps.meta.error} />}
							<Select
								id={id}
								data-testid={id}
								displayEmpty
								autoFocus={autoFocus}
								classes={{
									icon: localClasses.icon,
									select: localClasses.select,
								}}
								name={fieldProps.field.name}
								value={fieldProps.field.value ?? ''}
								onChange={(event: ChangeEvent<{ name?: string; value: unknown }>): void => {
									fieldProps.field.onChange(event)
									if (onChange) onChange(event)
								}}
								onBlur={fieldProps.field.onBlur}
								fullWidth
								variant="outlined"
								error={shouldShowError}
								MenuProps={{
									getContentAnchorEl: null,
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
								}}
								disabled={disabled}
							>
								{menuItems}
							</Select>
						</FormControl>
					</div>
				)
			}}
		</Field>
	)
}

InputSelect.defaultProps = {
	autoFocus: false,
	onChange: undefined,
	showError: true,
	disabled: false,
}

export default InputSelect
