import { formatedDate } from "../../../services/utils";
import {
  ExperimentStatusList,
  IExperimentResponseContent,
} from "./../../../types/experiment";

export const generateJsonFile = <T>(data: T, filename: string) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `${filename}.json`;

  link.click();
};

export const hasConfidenceLevelStatus = (status: string) => {
  if (!status) return;

  return (
    status === ExperimentStatusList.CANCELLED ||
    status === ExperimentStatusList.FINISHED ||
    status === ExperimentStatusList.STARTED
  );
};

export const renderPeriod = (experiment: IExperimentResponseContent) => {
  const period = experiment?.startDate && experiment?.endDate;
  if (period)
    return `${formatedDate(new Date(experiment?.startDate))} - ${formatedDate(new Date(experiment?.endDate))}`;
  return `${displayDate(experiment?.startDate)} -`;
};

export const displayDate = (date?:string) => date ? formatedDate(new Date(date)) : "-";

export const mountListColumns = (headerKey: string, accessor: string) => {
  return {
    header: `experiment_list.columns.${headerKey}`,
    accessor,
    disableSortBy: true,
    width: "auto",
  };
};
