import { Grid } from '@hexa-ui/components';
import styled from 'styled-components';

export const OuterContainer = styled.div<{ $alignCenter?: boolean }>`
  width: 100%;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  display: ${({ $alignCenter }) => ($alignCenter ? 'flex' : 'block')};
  justify-content: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'start')}};
`;

export const Container = styled(Grid.Container)<{ $alignCenter?: boolean }>`
  width: ${({ $alignCenter }) => ($alignCenter ? '70%' : 'auto')};
`;

export const HeaderContent = styled.div<{ $hasbreadcrumbs: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $hasbreadcrumbs }) => ($hasbreadcrumbs ? 'space-between' : 'flex-end')};
  flex-direction: row;
  min-height: 38px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  column-gap: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  column-gap: 16px;
`;

export const Link = styled.div`
  cursor: pointer;
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  column-gap: 8px;
`;
export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #ffff00;
  border-radius: 512px;
`;

export const SearchContainer = styled.div`
  margin-top: 16px;
`;
