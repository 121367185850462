import React, { useEffect, useState } from 'react'
import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { LIMIT_CHANGE_STORAGE_KEY, MANAGE_REDEEMABLE } from '@/utils/constants'
import {
	$redeemableItemLimitStore,
	RedeemableLimitChangeState,
} from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { getStorageItemString } from '@/utils/storageUtil'

export const LimitUpdatedExceededDialog = () => {
	const isRedeemableRestrictCppVisibilityEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_RESTRICT_CPP_VISIBILITY)
	const { limitChange, limit, exceededLimitAmount, isLimitExceeded, totalRedeemableItems } =
		useStore($redeemableItemLimitStore)
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		const storedLimitString = getStorageItemString(LIMIT_CHANGE_STORAGE_KEY)
		const storedLimit = storedLimitString && JSON.parse(storedLimitString)

		const open = limit && storedLimit?.map((sl: RedeemableLimitChangeState) => sl.isLimitUpdate)
		setIsOpen(open)
	}, [])

	const redeemablesExceededNumber = exceededLimitAmount ?? 0
	const redeemablesSpareNumber = (limit ?? 0) - totalRedeemableItems
	const hasSameLimit = totalRedeemableItems === limit
	const surplusDescription =
		redeemablesSpareNumber > 1
			? 'redeemable:LIMIT_EXTERNAL_USER_DIALOG.SURPLUS_DESCRIPTION.PLURAL'
			: 'redeemable:LIMIT_EXTERNAL_USER_DIALOG.SURPLUS_DESCRIPTION.SINGULAR'
	const exceededDescription =
		redeemablesExceededNumber > 1
			? 'redeemable:LIMIT_EXTERNAL_USER_DIALOG.EXCEEDED_DESCRIPTION.PLURAL'
			: 'redeemable:LIMIT_EXTERNAL_USER_DIALOG.EXCEEDED_DESCRIPTION.SINGULAR'
	const description = isLimitExceeded ? exceededDescription : surplusDescription

	if (!isRedeemableRestrictCppVisibilityEnabled || !limitChange.isLimitUpdate || !hasManageRedeemablePermission) {
		return null
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<Dialog.Root
			actions={
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Dialog.Close>
						<Button size="medium" variant="primary" onClick={handleClose}>
							{t('redeemable:LIMIT_EXTERNAL_USER_DIALOG.BUTTON')}
						</Button>
					</Dialog.Close>
				</div>
			}
			closeButton={false}
			title={<Heading>{t('redeemable:LIMIT_EXTERNAL_USER_DIALOG.TITLE')}</Heading>}
			headerHeight="auto"
			open={isOpen}
			data-testid="dialog"
		>
			<Paragraph>{t(`redeemable:LIMIT_EXTERNAL_USER_DIALOG.SUB_TITLE`, { limit: limit ?? 0 })}</Paragraph>

			{!hasSameLimit && (
				<Paragraph>
					{t(description, { limitAmount: isLimitExceeded ? redeemablesExceededNumber : redeemablesSpareNumber })}
				</Paragraph>
			)}
		</Dialog.Root>
	)
}
