import { Error404 } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { BASE_PATH } from 'constants/routes/internal/index';
import { useRouterService } from 'providers/RouterServiceProvider';
import { useIntl } from 'react-intl';

const Error404Page = () => {
  const { navigate } = useRouterService();
  const { messages } = useIntl();

  const handleClick = () => navigate(`${BASE_PATH}`);

  return (
    <Box height={'90vh'} display="flex" justifyContent={'center'} alignItems={'center'}>
      <Error404
        buttonText={messages['text.backHome'] as string}
        headerText={messages['text.notFound'] as string}
        subHeaderText=""
        buttonClickHandler={handleClick}
      />
    </Box>
  );
};

export default Error404Page;
