import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledInput from '../../components/Input/Input';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import StyledSelect from '../../components/Select/Select';
import StyledToggle from '../../components/Toggle/Toggle';
import StyledButton from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import StyledAlert from '../../components/Alert/Alert';
import PageTitle from '../../components/PageTitle/PageTitle';

const StyledPaddding = styled('div', {
  paddingBottom: '0.5rem',
});

const EditInstantPrize: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [selectedType, setSelectedType] = useState('TYPE');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const items = [{ name: 'Item 1' }, { name: 'Item 2' }, { name: 'Item 3' }];

  const search = (searchTerm) => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const successButton = () => {
    setSuccess(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PageTitle
          marginBottom="0.5rem"
          title={`Edit instant prize`}
        />
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1.5rem' }}>
          <Card elevated="medium" border="medium" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '1.5rem 0 1rem 0',
              }}
            >
              {success && (
                <StyledAlert
                  css={{ width: '100%' }}
                  message="*Saved the instant prize."
                  type="success"
                />
              )}

              <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    rowGap: '1rem'
                  }}
                >

                    <SearchDropdown
                      width=""
                      label="Campaign*"
                      hint="The campaign the instant prize will be associated with."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />


                    <SearchDropdown
                      width=""
                      label="Competition event*"
                      hint="The competition event the instant prize will be associated with."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />


                    <SearchDropdown
                      width=""
                      label="Match*"
                      hint="The match that the instant prize will be associated with."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />

                  <StyledPaddding>
                    <SearchDropdown
                      width=""
                      label="Prize*"
                      hint="The instant prize the user will earn."
                      placeholder="Search for the event"
                      onSearch={search}
                      onChange={(e) => {
                        e.currentTarget.value;
                      }}
                      onClickResult={(item) => {
                        console.log(`Evento selecionado: ${item.name}`);
                      }}
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Quantity*"
                      width={''}
                      hint="The quantity indicating how many prizes will be delivered for players who hit the match result."
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledToggle
                      title="Release instant prize?"
                      description="Status ON indicates that the prizes of this match will be released to players. *Once enabled this action cannot be undone!"
                      value={false}
                      onChange={() => null}
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledSelect
                      width={''}
                      placeholder="Manual"
                      label="Release method*"
                      hint="Release method."
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      <Select.Option value="Manual">Manual</Select.Option>
                    </StyledSelect>
                  </StyledPaddding>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                }}
              >
                <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton
                  isLoading={loading}
                  variant="primary"
                  onClick={() => successButton()}
                >
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
    </>
  );
};

export default EditInstantPrize;
