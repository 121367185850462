import React, { useState, useEffect } from 'react';
import { Pagination, Table, TableProps } from '@hexa-ui/components';
import { Container, ContainerPagination } from './Table.styles';

interface ContainerProps {
  margin?: string;
  maxWidth?: string;
}

interface DraggableTableProps<T> extends TableProps<T>, ContainerProps {
  onReorder?: (newData: T[]) => void;
  ref?: React.ForwardedRef<HTMLButtonElement>;
}

const DraggableTable = <T,>(
  { onReorder, margin, maxWidth, pagination, ...props }: DraggableTableProps<T>,
  fowardedRef: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => {
  const [rows, setRows] = useState<T[]>(props.data || []);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(
    pagination && typeof pagination !== 'boolean' ? pagination.pageSize : 5
  );

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const handleDrop = (e, rowIndex) => {
    e.preventDefault();

    if (draggingIndex !== null) {
      const actualDraggingIndex = (currentPage - 1) * currentPageSize + draggingIndex;
      const actualDropIndex = (currentPage - 1) * currentPageSize + rowIndex;

      const newRows = [...rows];
      const temp = newRows[actualDraggingIndex];
      newRows[actualDraggingIndex] = newRows[actualDropIndex];
      newRows[actualDropIndex] = temp;

      if (actualDraggingIndex !== actualDropIndex && onReorder) {
        onReorder(newRows);
      }

      setRows(newRows);
      setDraggingIndex(null);
    }
  };

  const onRowHandlers = (data, index) =>
    ({
      onDragStart: (e) => {
        e.dataTransfer.setData('rowIndex', index.toString());
        setDraggingIndex(index);
      },
      onDragOver: (e) => {
        e.preventDefault();
      },
      onDrop: (e) => handleDrop(e, index),
    } as any);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  const displayedRows = rows.slice(
    (currentPage - 1) * currentPageSize,
    currentPage * currentPageSize
  );

  return (
    <Container margin={margin} maxWidth={maxWidth}>
      <Table
        {...props}
        data={displayedRows}
        pagination={false}
        onRow={(data, index) => onRowHandlers(data, index)}
      />
      <ContainerPagination>
        <Pagination
          {...pagination}
          current={currentPage}
          onChange={handlePaginationChange}
          total={rows.length}
        />
      </ContainerPagination>
    </Container>
  );
};

export default DraggableTable;
