import { User } from 'components/UserReportTable';
import { Server } from 'miragejs';
import users from '../data/users';

const usersCountRoutes = (context: Server): void => {
  context.get('/users-status/counter', (_schema, request) => {
    const licenseType = parseInt(request.queryParams.licenseType) || 'full'; // full | light
    const handleSumFull = (sum: number, user: User) =>
      sum + (user.licenseType.toUpperCase() == 'FULL' ? 1 : 0);
    const handleSumLight = (sum: number, user: User) =>
      sum + (user.licenseType.toUpperCase() == 'LIGHT' ? 1 : 0);
    const countFull = users.reduce(handleSumFull, 0);
    const countLight = users.reduce(handleSumLight, 0);
    const count = licenseType === 'full' ? countFull : countLight;

    return { count: { total: users.length, byType: { type: licenseType, count: count } } };
  });
};

export default usersCountRoutes;
