import segmentAnalytics from '../../tracking';
import { LastURLLocalStorage } from "./constants";
import { getTrackingDefaultData, validateEventRequiredData } from "./utils/utils";

export const TrackingPageView = (pageID: string) => {

    const defaultData = getTrackingDefaultData(pageID);

    if (validateEventRequiredData(defaultData)) {

        const lastUrl = localStorage.getItem(LastURLLocalStorage) || '';
        localStorage.setItem(LastURLLocalStorage, document.location.href);

        segmentAnalytics.pageViewed({
            ...defaultData,
            referrer: lastUrl
        });
    }

}