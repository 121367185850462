export function convertCountryCodeToDisplayName(countryCode: string | undefined): string | null {
  if (!countryCode) {
    return null;
  }

  const intlCountryCode = new Intl.DisplayNames(['en'], { type: 'region' });
  const countryDisplayName = intlCountryCode.of(countryCode);

  if (countryCode === countryDisplayName || countryDisplayName === '') {
    return null;
  }

  const upperCaseStr = countryDisplayName.toUpperCase();

  const result = upperCaseStr.replace(/\s+/g, '_');

  return result;
}
