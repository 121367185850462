import { AllCountries } from 'domains/Countries';
import { IVendorMS, IVendorUpdateAllowedEmails } from 'domains/Vendor';
import { createEvent } from 'effector';

export const loadVendor = createEvent<null>('loadVendor');
export const loadVendorDone = createEvent<IVendorMS>('loadVendorDone');
export const loadVendorFail = createEvent<null>('loadVendorFail');

export const updateVendor = createEvent<void>('updateVendor');
export const updateVendorDone = createEvent<IVendorMS>('updateVendorDone');
export const updateVendorFail = createEvent<void>('updateVendorFail');

export const setVendorImage = createEvent<string | null | undefined>('setVendorImage');
export const setVendorCountry = createEvent<AllCountries>('setVendorCountry');

export const showErrorFeedback = createEvent<boolean>('showErrorFeedback');

export const openAddUsersModal = createEvent<null>('openAddUsersModal');
export const closeAddUsersModal = createEvent<null>('closeAddUsersModal');
export const addUsers = createEvent<null>('addUsers');
export const addUsersDone = createEvent<IVendorUpdateAllowedEmails | null>('addUsersDone');
export const addUsersFail = createEvent<null>('addUsersFail');
