import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RankingWinnersHeaders from '../../json/columnHeaders/RankingWinnerHeaders.json';
import mockData from '../../json/mock/mockingRankingWinnerData.json';
import handleFilter from '../../campaignDetails/extra/filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';


export default function WinnersForFun() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['position', 'player_name', 'player_poc', 'player_id', 'prize_name', 'guess_type','ranking_config'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  return (
    <>
      <FlexContainer
        width="100%"
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem'}
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
      </FlexContainer>
      <StyledTable
        columns={RankingWinnersHeaders}
        data={filteredData || []}
        search={false}
        loading={loading}
        pagination={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30, 50],
          showPageSizeSelector: true,
        }}
      />
    </>
  );
}
