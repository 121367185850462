import {ListRequestProps} from '@Models';
import {parseFilterStructure} from '@martech/utils';
import {useApi} from '../../api';

export default async function getPocsByPromotionService(
  campaignId: number,
  pageNumber: number,
  promotionId: string,
  props: ListRequestProps
): Promise<any> {
  const {searchTerm, filter, sort} = props;
  const searchString = searchTerm ? `&queryString=${searchTerm}` : '';
  const filterString = parseFilterStructure(filter);
  const sortString = sort?.prop
    ? `&sortField=${sort.prop}&sortDirection=${sort.direction}`
    : '';

  return await useApi().get({
    url: `/v1/admin/campaigns/${campaignId}/eligible-pocs?page=${pageNumber}${sortString}${searchString}${filterString}&promotionId=${promotionId}`,
  });
}
