import MessageMap from '../i18n.d';

export const es: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Solo disponible la opción en inglés',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Descripción general',
        CANCELLED_VISITS: '% Visitas canceladas',
        BDRS_WITHOUT_VISITS: '% de BDRs sin visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Última actualización hace [lastUpdate] horas.',
        FILTER_DESCRIPTION: 'Seleccione el rango de datos que desea que se muestre.',
      },
      Button: {
        REFRESH: 'Actualizar',
      },
      Error: {
        REFRESH_ERROR: 'No pudimos actualizar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoy',
          YESTERDAY: 'Ayer',
          TOMORROW: 'Mañana',
          OTHER: 'Otro',
        },
      },
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'ID del PDV',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        SEARCH_BY: 'Buscar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Buscar',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'ID del PDV [id] no existe.',
        BDR_ID_ERROR: 'BDR [id] no existe.',
        BDR_NAME_ERROR: 'BDR [name] no existe.',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Mostrando [results] visitas',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        BDR_ACCOUNT_ID: 'ID del PDV',
        VISIT_DATE: 'Fecha de visita',
        STATUS: 'Estado',
        MIN_TIME: 'Tiempo Min.',
        MAX_TIME: 'Tiempo Máx.',
        MINUTES: 'Minutos',
        SCHEDULED: 'Programada',
        IN_PROGRESS: 'Em curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
        QUANTITY_INDICATION: 'de',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs sin visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Mostrando [results] resultados',
        BDR_ID: 'ID del BDR',
        BDR_NAME: 'Nombre de BDR',
        DATE_WITHOUT_VISITS: 'Fecha sin visita',
        SEARCH_BY: 'Buscar por ID del BDR',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'al',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalles de la visita',
        POC_INFORMATION: 'Información de POC',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Información de BDR',
        VISIT_INFORMATION: 'Información de la visita',
      },
    },
  },
};
