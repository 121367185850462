import { createContext, ReactNode, useContext } from 'react';

import { EnvConfig } from './EnvProvider.types';

const defaultEnv: EnvConfig = {
  name: 'default',
  ENV: 'default',
  ENV_TYPE: 'default',
  OPTIMIZELY_KEY: 'default',
  SEGMENT_KEY: 'default',
};

export const EnvContext = createContext<EnvConfig>(defaultEnv);

interface EnvProviderProps {
  env?: EnvConfig;
  children: ReactNode;
}

export const EnvProvider = ({ env = defaultEnv, children }: EnvProviderProps) => {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};

export const useEnvContext = () => useContext(EnvContext);
