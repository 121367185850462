import { Checkbox, Pagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import React, { useState } from 'react';
import ModalUserDetails from '../ModalUserDetails';

interface UserReportTableProps {
  data: User[];
  page: number;
  count: number;
  role: string;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  zone: string;
  licenseType: string;
  licenseStatus: string;
  tags: string[];
  groups: string[];
  lastLogin?: string;
  createdAt?: string;
  updatedAt?: string;
}

type UserField = keyof User;

export interface UserDetails extends User {
  loginFrequence?: number;
  ticketsAssigned?: number;
  ticketsOpened?: number;
  ticketsSolved?: number;
  lastUpdate?: string;
}

export interface UserStatusResponse {
  content?: UserDetails[];
  pagination?: Pagination;
  licenseType? : LicenseType;
}

export interface Pagination {
  page?: number;
  pageSize?: number;
  totalElements?: number;
  totalPages?: number;
}

export interface LicenseType {
  full?: number;
  light?: number;
}

export const ITEMS_PER_PAGE = 10;

const UserReportTable: React.FC<UserReportTableProps> = ({
  data,
  page,
  count,
  role,
  handlePageChange,
}) => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    id: '',
    name: '',
    email: '',
    role: '',
    zone: '',
    licenseType: '',
    licenseStatus: 'inactive',
    tags: [],
    groups: [],
    lastLogin: '',
    loginFrequence: 0,
    ticketsAssigned: 0,
    ticketsOpened: 0,
    ticketsSolved: 0,
    lastUpdate: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [tableColumns, setTableColumns] = React.useState<string[]>([]);
  const [tableFields, setTableFields] = React.useState<UserField[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);

  const processedData = data.map((user) => ({
    ...user,
    lastLogin: user.lastLogin ? format(new Date(user.lastLogin), 'dd/MM/yyyy') : null,
  }));

  React.useEffect(() => {
    if (role === 'global') {
      setTableColumns([
        'Name',
        'Email',
        'License Type',
        'License Status',
        'Role',
        'BU',
        'Group',
        'Last Login',
      ]);
      setTableFields([
        'name',
        'email',
        'licenseType',
        'licenseStatus',
        'role',
        'zone',
        'groups',
        'lastLogin',
      ]);
    }
    if (role === 'local') {
      setTableColumns([
        'Name',
        'Email',
        'License Type',
        'License Status',
        'Role',
        'Tags',
        'Group',
        'Last Login',
      ]);
      setTableFields([
        'name',
        'email',
        'licenseType',
        'licenseStatus',
        'role',
        'tags',
        'groups',
        'lastLogin',
      ]);
    }
  }, [role]);

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = processedData.map((n) => n.id.toString());
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <TableContainer component={Paper} id="user-report-table-container">
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <caption>
          <Pagination
            data-testid="pagination-wrapper"
            count={Math.ceil(count / ITEMS_PER_PAGE)}
            page={page}
            onChange={handlePageChange}
            size="small"
          />
        </caption>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={selected.length > 0 && selected.length < processedData.length}
                checked={processedData.length > 0 && selected.length === processedData.length}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all users',
                }}
                data-testid="table-checkbox-all"
              />
            </TableCell>
            {tableColumns?.map((column) => {
              return <TableCell key={column}>{column}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData?.map((row: UserDetails, index: number) => {
            const isItemSelected = isSelected(row.id.toString());
            const checkboxId = `table-checkbox-${index}`;

            return (
              <TableRow
                id={`user-id${row.id}`}
                key={row.id}
                hover
                role="checkbox"
                onClick={(event) => {
                  const element = event.target as HTMLElement;
                  if (element.tagName.toUpperCase() !== 'INPUT') {
                    setUserDetails({ ...row });
                    handleOpenModal();
                  }
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': checkboxId,
                    }}
                    data-testid={checkboxId}
                    onClick={() => handleClick(row.id.toString())}
                  />
                </TableCell>
                {tableFields.map((field) => {
                  if (row[field] instanceof Array) {
                    const array = row[field] as Array<any>;
                    return <TableCell key={`${field}-${row.id}`}>{array.join(', ')}</TableCell>;
                  } else {
                    return <TableCell key={`${field}-${row.id}`}>{row[field]}</TableCell>;
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ModalUserDetails userDetails={userDetails} onClose={handleCloseModal} isOpen={isModalOpen} />
    </TableContainer>
  );
};

export default UserReportTable;
