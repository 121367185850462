import { TypeToast } from 'admin-portal-shared-services';
import { setup } from 'xstate';
import { ProgressProps } from '../../interfaces';
import { actions } from './actions/upload.actions';
import { actors } from './actors/upload.actors';
import { delays } from './delays/upload.delays';
import { guards } from './guards/upload.guards';
import { types } from './upload.machine.types';

export const uploadMachine = setup({
  types,
  actors,
  guards,
  delays,
  actions,
}).createMachine({
  id: 'upload',
  initial: 'initial',
  context: ({ input }) => ({
    input,
    slow: false,
    error: { has: false, message: '', attributes: {} },
    start: { upload: false, processing: false },
    loading: { upload: false, processing: false },
    analysis: { failed: false },
    files: [],
    file: { value: null, size: '', name: '', description: '' },
    uploaded: { fileId: input.file.id ?? '', fileName: input.file.name ?? '' },
    progress: {
      referenceName: '',
      status: '',
      total: 100,
      totalAnalysis: 0,
      validated: 0,
      failed: 0,
      processed: 0,
      analysisPercentage: '0%',
      processedPercentage: '0%',
    },
  }),
  on: {
    BACK: {
      target: '.final',
      actions: [
        {
          type: 'clear-context',
        },
        {
          type: 'set-navigate',
          params: ({ event }) => ({ to: event.to }),
        },
      ],
    },
    RETRY: {
      target: '.uploader',
      actions: [
        {
          type: 'clear-context',
        },
        {
          type: 'set-navigate',
          params: ({ event }) => ({ to: event.to }),
        },
      ],
    },
  },
  states: {
    initial: {
      always: [{ target: 'loaded', guard: { type: 'is-go-to-progress' } }, { target: 'uploader' }],
    },
    uploader: {
      initial: 'selecting',
      states: {
        selecting: {
          on: {
            FILE_SELECTED: {
              actions: [
                {
                  type: 'set-file',
                  params: ({ event }) => ({ files: event.files }),
                },
              ],
            },
            ADD_DESCRIPTION: {
              actions: [
                {
                  type: 'set-description',
                  params: ({ event }) => ({ description: event.description }),
                },
              ],
            },
            CLEAR: {
              guard: { type: 'has-file' },
              actions: [{ type: 'clear-context' }],
            },
            UPLOAD_FILE: { target: 'validating' },
          },
        },
        validating: {
          entry: [{ type: 'valid-file' }],
          always: [
            { target: 'uploading', guard: { type: 'has-no-error' } },
            { target: 'selecting' },
          ],
        },
        uploading: {
          entry: [
            { type: 'set-loading', params: { key: 'upload', value: true } },
            { type: 'set-start', params: { key: 'upload', value: true } },
            {
              type: 'set-toast',
              params: ({ context }) => ({
                type: TypeToast.INFO,
                message: 'pages.upload.buttons.toasts.processing',
                attributes: { fileName: context.file.name },
              }),
            },
          ],
          exit: [{ type: 'set-loading', params: { key: 'upload', value: false } }],
          invoke: {
            id: 'upload-file',
            src: 'upload-file',
            input: ({ context }) => ({
              user: context.input.user,
              params: { file: context.file },
              post: context.input.api.post,
            }),
            onDone: {
              target: 'uploaded',
              actions: [
                {
                  type: 'set-uploaded',
                  params: ({ event, context }) => ({
                    fileId: event.output.fileId,
                    fileName: context.file.name,
                  }),
                },
                {
                  type: 'set-toast',
                  params: ({ context }) => ({
                    type: TypeToast.SUCCESS,
                    message: 'pages.upload.buttons.toasts.success',
                    attributes: { fileName: context.file.name },
                  }),
                },
                {
                  type: 'restart-ws',
                },
                {
                  type: 'set-navigate',
                  params: ({ context }) => ({
                    to: `upload/progress/${context.uploaded.fileId}/${context.uploaded.fileName}`,
                  }),
                },
              ],
            },
            onError: {
              target: 'selecting',
              actions: [
                {
                  type: 'set-uploaded',
                  params: { fileId: '', fileName: '' },
                },
                {
                  type: 'set-toast',
                  params: ({ event, context }) => ({
                    type: TypeToast.WARNING,
                    message: event.error as string,
                    attributes: { fileName: context.file.name },
                  }),
                },
              ],
            },
          },
        },
        uploaded: { type: 'final' },
      },
      onDone: { target: 'loaded' },
    },
    loaded: {
      on: {
        EVALUATE_FILE: {
          target: 'progress',
          actions: [{ type: 'set-query', params: ({ event }) => ({ query: event.query }) }],
        },
      },
    },
    progress: {
      initial: 'evaluating',
      states: {
        evaluating: {
          on: { SLOW: { actions: [{ type: 'set-slow', params: { slow: true } }] } },
          invoke: {
            id: 'get-progress',
            src: 'get-progress',
            input: ({ context }) => ({
              fileId: context.uploaded.fileId,
              query: context.query,
              get: context.input.api.get,
            }),
            onDone: {
              target: 'pooling',
              actions: [
                {
                  type: 'set-analysis',
                  params: { failed: false },
                },
                {
                  type: 'set-progress',
                  params: ({ event }) => ({ progress: event.output.progress }),
                },
              ],
            },
            onError: {
              actions: [
                {
                  type: 'set-slow',
                  params: { slow: false },
                },
                {
                  type: 'set-analysis',
                  params: { failed: true },
                },
                {
                  type: 'set-progress',
                  params: ({ event }) => ({ progress: event.error as ProgressProps }),
                },
              ],
            },
          },
        },
        pooling: {
          after: {
            DELAY_1000: [{ target: 'evaluated', guard: 'has-finished' }, { target: 'evaluating' }],
          },
        },
        evaluated: { type: 'final', entry: [{ type: 'set-slow', params: { slow: false } }] },
      },
      onDone: { target: 'waiting' },
    },
    waiting: {
      always: [
        {
          target: 'done',
          guard: { type: 'is-progress-done' },
          actions: [
            {
              type: 'set-toast',
              params: ({ context }) => ({
                type: TypeToast.SUCCESS,
                message: 'pages.upload.buttons.toasts.analysis',
                attributes: { validated: context.progress.validated },
              }),
            },
          ],
        },
        { target: 'confirm' },
      ],
    },
    confirm: {
      initial: 'initial',
      states: {
        initial: {
          on: {
            DOWNLOAD_FAILED: { target: 'downloading', guard: { type: 'has-failed' } },
            APPLY_UPDATE: {
              target: 'confirming',
              guard: { type: 'is-analyzed-and-has-pocs' },
            },
          },
        },
        downloading: {
          entry: [
            {
              type: 'set-toast',
              params: { type: TypeToast.INFO, message: 'files.modal.toasts.info' },
            },
          ],
          invoke: {
            id: 'download-failed',
            src: 'download-failed',
            input: ({ context }) => ({
              fileId: context.uploaded.fileId,
              filesConfig: context.input.filesConfig,
              get: context.input.api.get,
            }),
            onDone: { target: 'initial' },
            onError: {
              target: 'initial',
              actions: [
                {
                  type: 'set-toast',
                  params: ({ event }) => ({
                    type: TypeToast.ERROR,
                    message: event.error as string,
                  }),
                },
              ],
            },
          },
        },
        confirming: {
          entry: [{ type: 'set-loading', params: { key: 'processing', value: true } }],
          exit: [{ type: 'set-loading', params: { key: 'processing', value: false } }],
          invoke: {
            id: 'apply-update',
            src: 'apply-update',
            input: ({ context }) => ({
              fileId: context.uploaded.fileId,
              post: context.input.api.post,
            }),
            onDone: {
              target: 'confirmed',
              actions: [
                {
                  type: 'set-start',
                  params: { key: 'processing', value: true },
                },
                {
                  type: 'restart-ws',
                },
              ],
            },
            onError: {
              target: 'initial',
              actions: [
                {
                  type: 'set-toast',
                  params: ({ event, context }) => ({
                    type: TypeToast.WARNING,
                    message: event.error as string,
                    attributes: { fileName: context.file.name },
                  }),
                },
              ],
            },
          },
        },
        confirmed: { type: 'final' },
      },
      onDone: { target: 'progress' },
    },
    done: {},
    final: { type: 'final' },
  },
});
