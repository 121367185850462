import { useLogService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import startServer from './mock-server';

if (process.env.NODE_ENV === 'development') {
  startServer();
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    useLogService().error(error);

    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
