import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface ChallengeHandleInterface {
    linkChallenge: (requestData, campaignId) => Promise<any>,
    getChallengesByCampaign: (campaignUuid, requestData, hasFilter) => Promise<any>
    getAllChallenges: (country) => Promise<any>
    getChallengeDetails: (challengeId, country) => Promise<any>
    deleteChallenge: (campaignChallengeUuid) => Promise<any>
    uploadChallengeCSV: (data, campaignId) => Promise<any>
    importChallenges: (data, campaignId) => Promise<any>
    getChallengeStatus: (campaignId) => Promise<any>
    getCountries: (value, type) => Promise<any>
    getRewardsChallenges: (requestData) => Promise<any>,
}

type ChallengeType = {
    challenge_id: string;
}

type getChallengeRequest = {
    page: number;
    limit: number;
    search: string;
    challenge_id?: string;
}

type CSVChallenge = {
    fileName: string;
    file: any;
}

type ImportChallengeType = {
    description: string,
    field_file: any,
    delimiter: string,
    countryUuid: string
}

export default function useChallengeHandler(): ChallengeHandleInterface {
    const linkChallenge = async (requestData: ChallengeType, campaignId) => {
        return await apiDrupal.post(`${base_url}/api/rewards-integration/v1/campaigns/${campaignId}/challenges/link`,
            requestData)
            .then(response => response.data)
            .catch(error => error.response);

    }

    const getAllChallenges = async (country) => {
        return await apiDrupal.get(`${base_url}/api/rewards-integration/v1/challenges?challenge_ids=&country=${country}`)
            .then(response => response.data)
            .catch(error => error);
    }

    const getChallengesByCampaign = async (campaignUuid, requestData: getChallengeRequest, hasFilter: boolean) => {
        return hasFilter ? await apiDrupal.get(base_url + '/jsonapi/cm_campaign_challenge/cm_campaign_challenge?page[offset]=' + (requestData.page) * (requestData.limit) + '&page[limit]=' + requestData.limit +
            '&include=challenge' +
            '&filter[search-group][group][conjunction]=OR' +
            '&filter[campaign.id]=' + campaignUuid +
            '&filter[name][condition][path]=challenge.name&filter[name][condition][operator]=CONTAINS&filter[name][condition][value]=' + requestData.search +
            '&filter[name][condition][memberOf]=search-group' +
            '&filter[challenge_id][condition][path]=challenge.challenge_id&filter[challenge_id][condition][operator]=CONTAINS&filter[challenge_id][condition][value]=' + requestData.search +
            '&filter[challenge_id][condition][memberOf]=search-group' +
            '&filter[drupal_internal__id][condition][path]=challenge.drupal_internal__id&filter[drupal_internal__id][condition][operator]=CONTAINS&filter[drupal_internal__id][condition][value]=' + requestData.search +
            '&filter[drupal_internal__id][condition][memberOf]=search-group'
        )
            .then(response => response.data)
            .catch(error => error)
            : await apiDrupal.get(base_url + '/jsonapi/cm_campaign_challenge/cm_campaign_challenge?page[offset]=' + (requestData.page) * (requestData.limit) + '&page[limit]=' + requestData.limit +
                '&include=challenge' + '&filter[campaign.id]=' + campaignUuid
            )
                .then(response => response.data)
                .catch(error => error);
    }

    const getChallengeDetails = async (challengeId: string, country: string) => {
        return await apiDrupal.get(`${base_url}/api/rewards-integration/v1/challenges?challenge_ids=${challengeId}&country=${country}`)
            .then(response => response.data)
            .catch(error => error);
    }

    const deleteChallenge = async (campaignChallengeUuid: string) => {
        return await apiDrupal.delete(`${base_url}/jsonapi/cm_campaign_challenge/cm_campaign_challenge/${campaignChallengeUuid}`)
            .then(response => response.data)
            .catch(error => error);
    }

    const importChallenges = async (data: ImportChallengeType, campaignId) => {
        return await apiDrupal.post(`${base_url}/hub/v2/competition/${campaignId}/import/campaign_challenge?_format=json`,
            { ...data, field_added_to_queue: 0 })
            .then(response => response.data)
            .catch(error => error.response);
    }

    const uploadChallengeCSV = async (requestData: CSVChallenge, campaignId: string) => {
        return await apiDrupal.post(`${base_url}/file/upload/cm_import/campaign_challenge/field_file?_format=json&campaign=${campaignId}`, requestData.file, {
            headers: {
                'Content-Type': 'application/octet-stream',
                Accept: 'application/vnd.api+json',
                'Content-Disposition': 'aittachment; filename="' + requestData.fileName + '"',
            }
        })
            .then(response => response.data)
            .catch(error => error);
    }

    const getRewardsChallenges = async (requestData: getChallengeRequest) => {
        return await apiDrupal.get(base_url + '/jsonapi/cm_rewards_challenge/cm_rewards_challenge?page[offset]=' + (requestData.page) * (requestData.limit) + '&page[limit]=' + requestData.limit +
            '&filter[search-group][group][conjunction]=OR' +
            '&filter[name][condition][path]=name&filter[name][condition][operator]=CONTAINS&filter[name][condition][value]=' + requestData.search +
            '&filter[name][condition][memberOf]=search-group' +
            '&filter[challenge_id][condition][path]=challenge_id&filter[challenge_id][condition][operator]=CONTAINS&filter[challenge_id][condition][value]=' + requestData.search +
            '&filter[challenge_id][condition][memberOf]=search-group' +
            '&filter[drupal_internal__id][condition][path]=drupal_internal__id&filter[drupal_internal__id][condition][operator]=CONTAINS&filter[drupal_internal__id][condition][value]=' + requestData.search +
            '&filter[drupal_internal__id][condition][memberOf]=search-group'
        )
            .then(response => response.data)
            .catch(error => error);
    }

    const getChallengeStatus = async (campaignId) => {
        return await apiDrupal.get(`${base_url}/hub/v2/competition/${campaignId}/import/campaign_challenge/status`)
            .then(response => response.data)
            .catch(error => error);
    }

    const getCountries = async (value: string, type: string) => {
        return await apiDrupal.get(base_url + '/jsonapi/cm_country/cm_country?include=zone_code' +
            '&filter[search-group][group][conjunction]=OR' +
            '&filter[' + type + '][condition][path]=' + type + '&filter[' + type + '][condition][operator]=CONTAINS&filter[' + type + '][condition][value]=' + value +
            '&filter[' + type + '][condition][memberOf]=search-group'
        )
            .then(response => response.data)
            .catch(error => error);
    }

    return {
        linkChallenge, getChallengesByCampaign, getAllChallenges, getChallengeDetails, deleteChallenge, uploadChallengeCSV, importChallenges,
        getChallengeStatus, getCountries, getRewardsChallenges
    }
}