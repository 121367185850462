import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import StyledInput from '../../../components/Input/Input';
import StyledButton from '../../../components/Button/Button';
import StyledSelect from '../../../components/Select/Select';

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

export default function CreateMatch() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedUtc, setSelectedUtc] = useState('UTC');
  const { idTournament } = useParams();

  const handleUtcChange = (value) => {
    setSelectedUtc(value);
  };

  return (
    <Grid.Container type="fluid" style={{margin:0}}>
      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '1rem' }}>
          Add Match
        </Heading>
      </StyledGridItem>

      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'contents' }}>
        <Card elevated="medium" border="large" css={{ paddingBottom: '0.5rem', width: '100%'}}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '2rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledInput
                    disabled
                    size="large"
                    label="Tournament*"
                    width={'100%'}
                    hint="The Tournament that predictions and matches will be associated with. "
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    hint="&nbsp;"
                    size="large"
                    label="Home Team*"
                    width={'100%'}
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    hint="&nbsp;"
                    size="large"
                    label="Visitor Team*"
                    width={'100%'}
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledSelect
                    width={'100%'}
                    placeholder="UTC"
                    label="Time zone"
                    hint="Select your registration location's timezone. Don't worry, the system will calculate the correct timezone per country."
                    value={selectedUtc}
                    onChange={handleUtcChange}
                  >
                    <Select.Option value="en">UTC</Select.Option>
                    <Select.Option value="br">Portugal</Select.Option>
                    <Select.Option value="fr">France</Select.Option>
                    <Select.Option value="de">Germany</Select.Option>
                  </StyledSelect>
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Group"
                    hint="&nbsp;"
                    width={'100%'}
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Name*"
                    hint="The name of the Tournament match."
                    width={'100%'}
                    placeholder="Insert you text here"
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    type="datetime-local"
                    hint="select the match date"
                    required
                    className="calendars"
                    size="large"
                    label="Date*"
                    width={'100%'}
                  />
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '10px',
              }}
            >
               <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton> 
            </div>
          </Grid.Item>
        </Card>
      </StyledGridItem>
    </Grid.Container>
  );
}
