export const formatNumberPerLocale = (
  value: number,
  locale,
  options?: Intl.NumberFormatOptions
): string => {
  const formatter = Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  });

  return formatter.format(value);
};
