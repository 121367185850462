import { Server } from 'miragejs';
import { ITEMS_PER_PAGE } from '../../../src/components/UserReportTable';
import users from '../data/users';

const usersRoutes = (context: Server): void => {
  context.get('/users-status', (_schema, request) => {
    let page = parseInt(request.queryParams.page) || 1;
    let offset = page - 1;
    let begin = 0 + ITEMS_PER_PAGE * offset;

    return users.slice(begin, begin + ITEMS_PER_PAGE);
  });
};

export default usersRoutes;
