import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: absolute;
  max-height: 70%;

  [role='dialog'] {
    width: 50%;
    min-width: 50%;
  }
`;

export const SearchWrapper = styled.div`
  margin: 16px 0 12px 0;
  max-width: 45%;
`;

export const TableWrapper = styled.div`
  [data-testid='SortDescending'],
  [data-testid='SortAscending'],
  [data-testid='SortDefault'] {
    height: 20px;
    width: 20px;
    margin-left: 18px;
  }

  [data-testid='SortDefault'] {
    visibility: inherit;
  }
`;
