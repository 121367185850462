import { Card, Grid, Heading, Tabs } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StyledTabs from '../../components/Tabs/Tabs';
import RankingForPrize from './tabs/RankingForPrize';
import RankingForFun from './tabs/RankingForFun';
import ExportModule from '../campaignDetails/modules/ExportModal';
import mockDataPrize from '../json/mock/mockingRankingPrizeData.json';
import mockDataFun from '../json/mock/mockingRankingPrizeData.json';

export default function RankingView() {
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(hash || '#rankingforprize');
  const rankingName = 'teste'
  const params = useParams();

  useEffect(() => {
    setCurrentTab(hash || '#edit');
  }, [hash]);
  const tabs = [
    { component: RankingForPrize, hash: '#rankingforprize', title: 'Ranking for Prize' },
    { component: RankingForFun, hash: '#rankingforfun', title: 'Ranking for Fun' },
  ];

  return (
    <>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Ranking {rankingName}
          </Heading>
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="large" style={{ width: '100%' }}>
            <StyledTabs defaultValue={hash || '#rankingforprize'}>
              <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
                {tabs?.map((tab, index) => (
                  <a
                    key={index + tab.title}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={tab.hash}
                  >
                    <Tabs.Trigger key={index} value={tab.hash}>
                      {tab.title}
                    </Tabs.Trigger>
                  </a>
                ))}
              </Tabs.List>
              {tabs?.map((tab, index) => (
                <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                  <tab.component />
                </Tabs.Content>
              ))}
            </StyledTabs>
          </Card>
        </Grid.Item>
      <ExportModule
        bundle={''}
        campaignId={String(params?.id)}
        disabled={currentTab === '#rankingforprize' ? !!!mockDataPrize?.length : !!!mockDataFun?.length}
      />
      </Grid.Container>
    </>
  );
}
