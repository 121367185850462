import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ScreenName } from '@/domains'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { useFormContext } from 'react-hook-form'
import { CreateEarningRuleFormData } from '@/routes/rules/RulesRedesign/Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import { updateEarningRule } from '../RulesService'
import { UpdateEarningRuleDTO } from '../RulesService.types'
import { handleEarningRulesError, normalizeSkus } from './earningRulesUtils'

export const useUpdateEarningRule = (): {
	isLoading: boolean
	putEarningRule: (payload: UpdateEarningRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId: vendorIdSettings } = settings
	const { selectedTierKey, currentTier } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const selectedZone = useCurrentZone()

	const { getValues } = useFormContext<CreateEarningRuleFormData>()

	const { isLoading, mutate: putEarningRule } = useMutation(
		(payload: UpdateEarningRuleDTO) => {
			const skusWithVendorId = normalizeSkus({ items: payload.items, vendorIdSettings })

			const { amountSpent, points, description, status, vendorId, tier, ruleId } = payload

			const payloadNormalized: UpdateEarningRuleDTO = {
				amountSpent,
				points,
				description,
				status,
				items: skusWithVendorId,
				vendorId,
				tier,
				ruleId,
			}
			return updateEarningRule({ ...payloadNormalized, vendorId: vendorIdSettings, tier: selectedTierKey })
		},
		{
			onSuccess: async (response) => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('rules:CREATE_EARNING_RULES.EDITED_RULE_SUCCESSFUL_MESSAGE'),
				})
				await queryClient.invalidateQueries(['earning-rule-by-id'])

				const SKUListUpdates = getValues().skuUpdates

				if (SKUListUpdates.length > 0) {
					SKUListUpdates.forEach((skuUpdate) => {
						AnalyticsService.events.earningRuleSkUsUpdated({
							current_earning_rule_id: response.ruleId,
							rule_type: 'Segmented Earning Rule',
							file_name: skuUpdate.file_name,
							file_size: skuUpdate.file_size,
							operation_type: skuUpdate.operation_type,
							screen_name: ScreenName.EditEarningRule,
							skus: skuUpdate.skus,
							skus_quantity: String(skuUpdate.skus_quantity),
							target_earning_rule_id: response.ruleId,
							tier: currentTier ? currentTier?.label : selectedTierKey,
							user: getUserEmail(),
							vendor: response.vendorId,
							zone: selectedZone,
						})
					})
				}

				const earningRuleStatus = response.status

				if (earningRuleStatus === 'DRAFT') {
					AnalyticsService.events.ruleSaved({
						amount: { value: response.amountSpent },
						rule_type: 'Segmented Earning Rule',
						earning_rule_description: response.description,
						audience_id: null,
						earning_rule_id: response.ruleId,
						points: String(response.points),
						screen_name: ScreenName.EditEarningRule,
						tier: currentTier ? currentTier?.label : selectedTierKey,
						user: getUserEmail(),
						vendor: response.vendorId,
						zone: selectedZone,
					})
				}

				if (earningRuleStatus === 'ACTIVE') {
					AnalyticsService.events.ruleUpdated({
						amount: { value: response.amountSpent },
						rule_type: 'Segmented Earning Rule',
						earning_rule_description: response.description,
						earning_rule_id: response.ruleId,
						points: String(response.points),
						screen_name: ScreenName.EditEarningRule,
						tier: currentTier ? currentTier?.label : selectedTierKey,
						user: getUserEmail(),
						vendor: response.vendorId,
						zone: selectedZone,
					})
				}

				goToRoutePath('/rules')
			},
			onError: (err: { cause: AxiosError }) => {
				handleEarningRulesError({ err, toast })
			},
		},
	)

	return {
		isLoading,
		putEarningRule,
	}
}
