import SelectorView from '@components/selectorView/SelectorView';
import Paragraph from '@components/typography/Paragraph';

import { Container } from './LanguageSelector.styles';

export interface LanguageSelectorProps extends UseLanguageReturn {
  label?: string;
  disabled?: boolean;
  width?: string;
}

export const LanguageSelector = ({
  selectedLanguage,
  availableLanguages,
  isMultilanguage,
  isLoading,
  onChangeLanguage,
  label,
  disabled,
  width,
}: LanguageSelectorProps) => {
  if (isLoading || !isMultilanguage || !selectedLanguage) return null;
  return (
    <Container>
      {label && (
        <Paragraph
          data-testid="language-selector-label"
          text={label}
          size="small"
          weight="semibold"
        />
      )}
      <SelectorView
        defaultValue={selectedLanguage?.id}
        isLoading={isLoading}
        onChange={onChangeLanguage}
        options={availableLanguages}
        disabled={disabled || isLoading}
        width={width}
        callback="language"
      />
    </Container>
  );
};
