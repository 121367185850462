import {
  BadgeStatusTypes,
  Button,
  Dialog,
  Grid,
  Heading,
  Paragraph,
  TextButton,
  Toggle,
} from '@hexa-ui/components';
import {Download, Draganddrop} from '@hexa-ui/icons';
import {CollapsibleCard, TextItem} from '@martech/components';
import {
  CustomInput,
  CustomStyledUploader,
  CustomTextArea,
  PreviewCard,
} from '@Components';
import {FieldArrayRenderProps, useFormikContext} from 'formik';
import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {getStorageURL, processFile} from '@Utils';
import {useIntl} from 'react-intl';
import {Promotion} from '@Models';
import CouponSettings from '../../../Common/CouponSettings/CouponSettings';
import DatesAndLimits from '../../../Common/DatesAndLimits/DatesAndLimits';
import PromotionGoal from '../../../Common/PromotionGoal/PromotionGoal';
import ProductSettings from '../../../Common/ProductSettings/ProductSettings';

interface PromotionCardProps {
  promotion: Promotion;
  index: number;
  helper: FieldArrayRenderProps;
  isEditing?: boolean;
  hasLimitPerConsumer?: boolean;
}

function PromotionCard({
  promotion,
  index,
  helper,
  isEditing,
  hasLimitPerConsumer,
}: PromotionCardProps) {
  const {formatMessage} = useIntl();
  const {values, errors, setFieldValue} = useFormikContext<any>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [imgError, setImgError] = useState<string | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const beesPointsLabel = useCallback(
    (index: number) => {
      return values.promotions[index].beesPointsPerCoupon
        ? `${values.promotions[index].beesPointsPerCoupon} ${formatMessage({
            id: 'COMMON.COMPLEMENTS.POINTS',
          })}`
        : formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.CARD.POINTS',
          });
    },
    [values.promotions, index, formatMessage]
  );

  const couponTypeLabel = useCallback(
    (index: number) => {
      const type =
        values.promotions[index].couponType?.toLowerCase() === 'discount'
          ? 'DISCOUNT'
          : 'FREE_GOOD';

      return values.promotions[index].couponType
        ? formatMessage({
            id: `COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.${type}`,
          })
        : formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.CARD.TYPE',
          });
    },
    [values.promotions, index, formatMessage]
  );

  const validateDimensions = async (objectUrl: any) => {
    const imgData = await processFile(objectUrl);
    if (imgData.width !== 1920 && imgData.height !== 1080) {
      setImgError('INVALID');
      return false;
    }

    return true;
  };

  const handleCampaignImageValidation = async (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    setImgError(undefined);
    const objectUrl = URL.createObjectURL(file);
    const validation = await validateDimensions(objectUrl);

    if (validation) {
      setFieldValue(`promotions.${index}.promotionImageFile`, file);
      callback(true);
    } else {
      callback(false);
    }
  };

  useEffect(() => {
    const promotionImg = values.promotions[index].promotionImageFile;

    if (promotionImg) {
      const objectUrl = URL.createObjectURL(promotionImg);
      setPreview(objectUrl);
    }
  }, [values.promotions, index]);

  useEffect(() => {
    if (
      values.promotions[index].couponGenStart &&
      !(values.promotions[index].couponGenStart instanceof Date)
    ) {
      setFieldValue(
        `promotions.${index}.couponGenStart`,
        new Date(values.promotions[index].couponGenStart)
      );
    }
    if (
      values.promotions[index].couponGenEnd &&
      !(values.promotions[index].couponGenEnd instanceof Date)
    ) {
      setFieldValue(
        `promotions.${index}.couponGenEnd`,
        new Date(values.promotions[index].couponGenEnd)
      );
    }
    if (
      !!values.promotions[index].couponRedeemStart &&
      !(values.promotions[index].couponRedeemStart instanceof Date)
    ) {
      setFieldValue(
        `promotions.${index}.couponRedeemStart`,
        new Date(values.promotions[index].couponRedeemStart)
      );
    }
    if (
      values.promotions[index].couponRedeemEnd &&
      !(values.promotions[index].couponRedeemEnd instanceof Date)
    ) {
      setFieldValue(
        `promotions.${index}.couponRedeemEnd`,
        new Date(values.promotions[index].couponRedeemEnd)
      );
    }
  }, [values.promotions, index, setFieldValue]);

  const handleRemoveCampaignImage = () => {
    setImgError(undefined);
    setPreview(undefined);
    setFieldValue(`promotions.${index}.promotionImageFile`, undefined);
  };

  const cardPills = useMemo(() => {
    const pills: BadgeStatusTypes[] = [];

    if (!values.promotions[index].promotionStatus) {
      pills.push({
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.STATUS.DISABLED',
        }),
        color: 'gray',
      });
    }

    const promotionErrors = errors.promotions as any[];
    if (promotionErrors && promotionErrors[index]) {
      pills.push({
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.STATUS.IN_PROGRESS',
        }),
        color: 'orange',
      });
    }

    const today = new Date();

    if (values.promotions[index].couponGenEnd <= today) {
      pills.push({
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.STATUS.PROMOTION_EXPIRED',
        }),
        color: 'red',
      });
    }

    return pills;
  }, [values.promotions, errors, index, formatMessage]);

  useEffect(() => {
    if (values.promotions.length === 1) {
      setFieldValue(`promotions.${index}.promotionStatus`, true);
    }
    if (values.uploadNewPromotionInEligiblePocs) {
      setFieldValue(
        `promotions.${index}.uploadNewPromotionInEligiblePocs`,
        true
      );
    }
  }, [
    values.promotions,
    index,
    setFieldValue,
    values.uploadNewPromotionInEligiblePocs,
  ]);

  const handleTemplateDownload = () => {
    const storageURL = getStorageURL();
    const templateUrl = `${storageURL}/templates/campaign/promotion_informative_image_template_1920_721.png`;
    window.open(templateUrl, '_blank');
  };

  const handleCheckedChange = (checked: boolean) => {
    setFieldValue(
      `promotions.${index}.uploadNewPromotionInEligiblePocs`,
      checked
    );
  };

  const message = formatMessage({
    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.TOGGLE.POC_BASE',
  });

  const isChecked = values.promotions[index].uploadNewPromotionInEligiblePocs;

  return (
    <Grid.Item
      data-testid={`promotion-card:${index}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        padding: '0px',
      }}
    >
      <Grid.Item draggable data-drag-icon>
        <Draganddrop
          style={{cursor: 'grab', marginRight: '8px', marginLeft: '5px'}}
          size="large"
        />
      </Grid.Item>
      <CollapsibleCard
        key={`promotion-${promotion.id}`}
        subtitle={promotion.isOpen ? '' : `${promotion.promotionName}`}
        title={`${formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION',
        })} ${index + 1}`}
        pills={cardPills}
        isDeleteDisabled={values.promotions.length === 1}
        isDeleteHidden={!isEditing}
        onDeleteClicked={() => setDeleteOpen(true)}
        isToggleDisabled={
          values.promotions.length === 1 ||
          (values.promotions.filter(
            (promotion: Promotion) => promotion.promotionStatus
          ).length === 1 &&
            promotion.promotionStatus)
        }
        onOpenChange={() =>
          setFieldValue(`promotions.${index}.isOpen`, !promotion.isOpen)
        }
        isOpen={promotion.isOpen}
        isToggle={promotion.promotionStatus}
        onToggle={() => {
          if (promotion.promotionStatus) {
            setIsDialogOpen(true);
          } else {
            setFieldValue(
              `promotions.${index}.promotionStatus`,
              !promotion.promotionStatus
            );
          }
        }}
      >
        <Grid.Container type="fluid" style={{margin: 0}}>
          <Grid.Item
            xs={8}
            style={{
              borderRight: '1px solid #DBDADA',
              paddingRight: '39px',
            }}
          >
            <Grid.Container type="fluid" style={{margin: 0, width: '100%'}}>
              <Grid.Item
                xs={12}
                style={{
                  padding: 0,
                  marginBottom: '0.5rem',
                }}
              >
                <CustomInput
                  data-testid="promotion-name"
                  name={`promotions.${index}.promotionName`}
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.NAME.LABEL',
                  })}
                  hint={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.NAME.HINT',
                  })}
                  maxLength={60}
                />
              </Grid.Item>
              <Grid.Item
                xs={12}
                style={{
                  flexDirection: 'column',
                  gap: '8px',
                  padding: 0,
                }}
              >
                <Paragraph size="small" weight="semibold">
                  {formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.IMAGE.NAME',
                  })}
                </Paragraph>
                <CustomStyledUploader
                  acceptedTypes="image/png, image/jpg, image/jpeg"
                  buttonText={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.IMAGE.SELECT',
                  })}
                  onChange={handleCampaignImageValidation}
                  onRemove={handleRemoveCampaignImage}
                  placeholder={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.IMAGE.FORMAT',
                  })}
                  maxSizeInMB={2}
                  value={values.promotions[index].promotionImageFile}
                  type="img"
                  error={
                    imgError
                      ? formatMessage({
                          id: `COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.IMAGE.${imgError}_ERROR`,
                        })
                      : undefined
                  }
                />
                <TextButton
                  data-testid="promotion-image-template"
                  icon={Download}
                  iconPosition="leading"
                  size="small"
                  onClick={handleTemplateDownload}
                  type="button"
                >
                  {formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DOWNLOAD_TEMPLATE',
                  })}
                </TextButton>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item
            xs={4}
            style={{
              paddingLeft: '39px',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <Paragraph size="small" weight="semibold">
              {formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.CARD.PREVIEW',
              })}
            </Paragraph>
            <PreviewCard
              imgUrl={preview}
              name={values.promotions[index].promotionName}
              placeholder={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.NAME.LABEL',
              })}
              badges={[
                {
                  color: 'gray',
                  label: beesPointsLabel(index),
                },
                {
                  color: 'blue',
                  label: couponTypeLabel(index),
                },
              ]}
            />
          </Grid.Item>

          <Grid.Item
            xs={12}
            style={{
              padding: 0,
              marginTop: '1rem',
            }}
          >
            <CustomTextArea
              data-testid="promotion-alt-text"
              name={`promotions.${index}.promotionImageAltText`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.IMAGE.ALT_TEXT',
              })}
              optionalText={formatMessage({
                id: 'COMMON.FORM.OPTIONAL',
              })}
              maxLength={150}
              characterCounter
              required={false}
            />
          </Grid.Item>
          {values.promotions[index].newPromotion && (
            <Grid.Item>
              <Toggle
                name={`promotions.${index}.uploadNewPromotionInEligiblePocs`}
                checked={isChecked}
                onCheckedChange={handleCheckedChange}
                data-testid="uploadNewPromotionInEligiblePocs-toggle"
              />
              <TextItem title="" value={message} xs={12} />
            </Grid.Item>
          )}
          <Grid.Item
            xs={12}
            style={{
              margin: '1.5rem 0',
              padding: 0,
            }}
          >
            <PromotionGoal promotionIndex={index} />
          </Grid.Item>
          <Grid.Item
            xs={12}
            style={{
              marginBottom: '1rem',
              padding: 0,
            }}
          >
            <ProductSettings promotionIndex={index} />
          </Grid.Item>
          <Grid.Item
            xs={12}
            style={{
              marginBottom: '1rem',
              padding: 0,
            }}
          >
            <CouponSettings
              promotionIndex={index}
              hasLimitPerConsumer={hasLimitPerConsumer}
            />
          </Grid.Item>
          <Grid.Item
            xs={12}
            style={{
              marginBottom: '1rem',
              padding: 0,
            }}
          >
            <DatesAndLimits
              promotionIndex={index}
              hasLimitPerConsumer={hasLimitPerConsumer}
            />
          </Grid.Item>
        </Grid.Container>
      </CollapsibleCard>
      <Dialog.Root
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setFieldValue(`promotions.${index}.promotionStatus`, true);
        }}
        actions={
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              data-testid={`promotion-card:${index}:dialog:disable-button`}
              size="medium"
              variant="primary"
              onClick={() => {
                setIsDialogOpen(false);
                setFieldValue(`promotions.${index}.promotionStatus`, false);
              }}
              style={{background: '#C9201D', marginRight: '0.9375rem'}}
            >
              {formatMessage({id: 'COMMON.BUTTONS.DISABLE'})}
            </Button>
            <Button
              data-testid={`promotion-card:${index}:dialog:cancel-button`}
              size="medium"
              variant="secondary"
              onClick={() => {
                setIsDialogOpen(false);
                setFieldValue(`promotions.${index}.promotionStatus`, true);
              }}
            >
              {formatMessage({id: 'COMMON.BUTTONS.GO_BACK'})}
            </Button>
          </div>
        }
      >
        <Paragraph>
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DIALOG_CONTENT',
          })}
        </Paragraph>
      </Dialog.Root>

      <Dialog.Root
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        actions={
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              data-testid={`promotion-card:${index}:dialog:delete-button`}
              size="medium"
              variant="primary"
              onClick={() => helper.remove(index)}
              style={{background: '#C9201D', marginRight: '0.9375rem'}}
            >
              {formatMessage({id: 'COMMON.BUTTONS.DELETE'})}
            </Button>
            <Button
              data-testid={`promotion-card:${index}:dialog:cancel-button`}
              size="medium"
              variant="secondary"
              onClick={() => setDeleteOpen(false)}
            >
              {formatMessage({id: 'COMMON.BUTTONS.GO_BACK'})}
            </Button>
          </div>
        }
        title={
          <Heading size="H2">
            {formatMessage(
              {
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DELETE.TITLE',
              },
              {index: index + 1}
            )}
          </Heading>
        }
      >
        <Paragraph>
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DELETE.DESCRIPTION',
          })}
        </Paragraph>
      </Dialog.Root>
    </Grid.Item>
  );
}

export default memo(PromotionCard);
