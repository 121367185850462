import { enqueueActions } from 'xstate';
import { keys } from '../../../constants';
import { getFileSize } from '../../../utils/getFileSize';
import { Actions } from './upload.actions.types';

export const actions: Actions = {
  'set-file': enqueueActions(({ enqueue }, { files }) => {
    const { fileContent: file } = files.find((file) => !file.errors.length) || {};

    const fileSize = getFileSize(file?.size ?? 0);

    enqueue.assign({
      files,
      error: { has: false, message: '', attributes: {} },
      file: { value: file, size: fileSize, name: file?.name ?? '', description: '' },
    });
  }),
  'set-description': enqueueActions(({ enqueue, context }, { description }) => {
    enqueue.assign({ file: { ...context.file, description } });
  }),
  'set-query': enqueueActions(({ enqueue }, { query }) => {
    enqueue.assign({ query });
  }),
  'set-start': enqueueActions(({ enqueue, context }, { key, value }) => {
    enqueue.assign({ start: { ...context.start, [key]: value } });
  }),
  'set-loading': enqueueActions(({ enqueue, context }, { key, value }) => {
    enqueue.assign({ loading: { ...context.loading, [key]: value } });
  }),
  'set-slow': enqueueActions(({ enqueue }, { slow }) => {
    enqueue.assign({ slow });
  }),
  'set-analysis': enqueueActions(({ enqueue }, { failed }) => {
    enqueue.assign({ analysis: { failed } });
  }),
  'set-progress': enqueueActions(({ enqueue, context }, { progress }) => {
    enqueue.assign({ progress: progress ?? context.progress });
  }),
  'set-uploaded': enqueueActions(({ enqueue }, { fileId, fileName }) => {
    enqueue.assign({ uploaded: { fileId, fileName } });
  }),
  'set-navigate': enqueueActions(({ context }, { to }) => {
    const { input } = context;
    const { navigate } = input;

    navigate(`/bees-credit-management/${to}`);
  }),
  'set-toast': enqueueActions(({ context }, { type, message, attributes }) => {
    const { input } = context;
    const { toast } = input;

    toast[type]({ message, attributes });
  }),
  'restart-ws': enqueueActions(({ context }) => {
    const { input, uploaded } = context;
    const { api, toggles } = input;

    if (!toggles.has(keys.cm_has_ws_notifications)) return;

    api.get({ path: `/v1/ws-events/restart-pooling?fileId=${uploaded.fileId}` });
  }),
  'valid-file': enqueueActions(({ enqueue, context }) => {
    const { input, files, file } = context;
    const { filesConfig } = input;

    const formats = filesConfig.accept.split(',').map((ext) => ext.trim().toLowerCase());
    const extension = file.name.slice(file.name.lastIndexOf('.'));

    if (!files.length) {
      enqueue.assign({
        error: { has: true, message: 'pages.upload.uploader.errors.noFile' },
      });

      return;
    }

    if (!formats.includes(extension)) {
      enqueue.assign({
        error: { has: true, message: 'pages.upload.uploader.errors.invalidFormat' },
      });

      return;
    }

    if (file.value.size > filesConfig.size) {
      enqueue.assign({
        error: {
          has: true,
          message: 'pages.upload.uploader.errors.maxFileSize',
          attributes: { maxFileSize: filesConfig.size / 1000000 },
        },
      });

      return;
    }

    enqueue.assign({ error: { has: false, message: '', attributes: {} } });
  }),
  'clear-context': enqueueActions(({ enqueue, context }) => {
    enqueue.assign({
      input: context.input,
      slow: false,
      error: { has: false, message: '', attributes: {} },
      start: { upload: false, processing: false },
      loading: { upload: false, processing: false },
      analysis: { failed: false },
      files: [],
      file: { value: null, size: '', name: '', description: '' },
      uploaded: { fileId: '', fileName: '' },
      progress: {
        referenceName: '',
        status: '',
        total: 100,
        totalAnalysis: 0,
        validated: 0,
        failed: 0,
        processed: 0,
        analysisPercentage: '0%',
        processedPercentage: '0%',
      },
    });
  }),
};
