import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import {
  ANDROID_PATH,
  ARGENTINA_PAGE,
  BACKEND_SERVICES_PAGE,
  BASE_PATH,
  BRAZIL_PAGE,
  BRF_PAGE,
  CANADA_PAGE,
  COLOMBIA_PAGE,
  CRASH_FREE,
  DOMINICAN_REPUBLIC_PAGE,
  ECUADOR_PAGE,
  EL_SALVADOR_PAGE,
  HONDURAS_PAGE,
  IOS_PATH,
  MEXICO_PAGE,
  MICROSERVICES_TRAFFIC_PAGE,
  MOBILE_PAGE,
  PANAMA_PAGE,
  PARAGUAY_PAGE,
  PERU_PAGE,
  SOUTH_AFRICA_PAGE,
  TANZANIA_PAGE,
  UNITED_STATES_PAGE,
  URUGUAY_PAGE,
} from '../consts/routes';

const IosDetails = lazy(() => import('../pages/IosDetails/IosDetails'));
const AndroidDetails = lazy(() => import('../pages/AndroidDetails/AndroidDetails'));
const Mobile = lazy(() => import('../pages/Mobile'));
const Operations = lazy(() => import('../pages/Operations'));
const BackendServices = lazy(() => import('../pages/BackendServices/'));
const MicroservicesPage = lazy(() => import('../pages/MicroservicesPage'));
const CrashFree = lazy(() => import('../pages/CrashFree/CrashFree'));
const BrazilPage = lazy(() => import('../pages/CountryPages/BrazilPage'));
const ArgentinaPage = lazy(() => import('../pages/CountryPages/ArgentinaPage'));
const BrfPage = lazy(() => import('../pages/CountryPages/BrfPage'));
const CanadaPage = lazy(() => import('../pages/CountryPages/CanadaPage'));
const ColombiaPage = lazy(() => import('../pages/CountryPages/ColombiaPage'));
const DominicanRepublicPage = lazy(() => import('../pages/CountryPages/DominicanRepublicPage'));
const EcuadorPage = lazy(() => import('../pages/CountryPages/EcuadorPage'));
const ElSalvadorPage = lazy(() => import('../pages/CountryPages/ElSalvadorPage'));
const HondurasPage = lazy(() => import('../pages/CountryPages/HondurasPage'));
const MexicoPage = lazy(() => import('../pages/CountryPages/MexicoPage'));
const PanamaPage = lazy(() => import('../pages/CountryPages/PanamaPage'));
const ParaguayPage = lazy(() => import('../pages/CountryPages/ParaguayPage'));
const PeruPage = lazy(() => import('../pages/CountryPages/PeruPage'));
const SouthAfricaPage = lazy(() => import('../pages/CountryPages/SouthAfricaPage'));
const TanzaniaPage = lazy(() => import('../pages/CountryPages/TazaniaPage'));
const UruguayPage = lazy(() => import('../pages/CountryPages/UruguayPage'));
const UnitedStatesPage = lazy(() => import('../pages/CountryPages/UnitedStatesPage'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={BASE_PATH}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <Operations />
            </Suspense>
          }
        />
        <Route
          path={MOBILE_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <Mobile />
            </Suspense>
          }
        />
        <Route
          path={IOS_PATH}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <IosDetails />
            </Suspense>
          }
        />
        <Route
          path={ANDROID_PATH}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <AndroidDetails />
            </Suspense>
          }
        />
        <Route
          path={CRASH_FREE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <CrashFree />
            </Suspense>
          }
        />
        <Route
          path={BACKEND_SERVICES_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <BackendServices />
            </Suspense>
          }
        />
        <Route
          path={MICROSERVICES_TRAFFIC_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <MicroservicesPage />
            </Suspense>
          }
        />
        <Route
          path={BRAZIL_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <BrazilPage />
            </Suspense>
          }
        />
        <Route
          path={ARGENTINA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <ArgentinaPage />
            </Suspense>
          }
        />
        <Route
          path={BRF_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <BrfPage />
            </Suspense>
          }
        />
        <Route
          path={CANADA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <CanadaPage />
            </Suspense>
          }
        />
        <Route
          path={COLOMBIA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <ColombiaPage />
            </Suspense>
          }
        />
        <Route
          path={DOMINICAN_REPUBLIC_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <DominicanRepublicPage />
            </Suspense>
          }
        />
        <Route
          path={ECUADOR_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <EcuadorPage />
            </Suspense>
          }
        />
        <Route
          path={EL_SALVADOR_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <ElSalvadorPage />
            </Suspense>
          }
        />
        <Route
          path={HONDURAS_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <HondurasPage />
            </Suspense>
          }
        />
        <Route
          path={MEXICO_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <MexicoPage />
            </Suspense>
          }
        />
        <Route
          path={PANAMA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <PanamaPage />
            </Suspense>
          }
        />
        <Route
          path={PARAGUAY_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <ParaguayPage />
            </Suspense>
          }
        />
        <Route
          path={PERU_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <PeruPage />
            </Suspense>
          }
        />
        <Route
          path={SOUTH_AFRICA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <SouthAfricaPage />
            </Suspense>
          }
        />
        <Route
          path={TANZANIA_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <TanzaniaPage />
            </Suspense>
          }
        />
        <Route
          path={UNITED_STATES_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <UnitedStatesPage />
            </Suspense>
          }
        />
        <Route
          path={URUGUAY_PAGE}
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <UruguayPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
