import { Alert, Grid } from '@hexa-ui/components';
import { useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { useGetUserPreferences } from '../hooks/useGetUserPreferences';
import { useUiStore } from '../hooks/useUiStore';
import { SideBarItem } from '../interfaces';

interface IconProps {
  icon: React.ForwardRefExoticComponent<any>;
}

const getIcon = ({ icon }: IconProps): React.ReactElement => {
  const Component = icon;

  return <Component />;
};

export const Router = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const { snackbarMessage, snackbarType } = useUiStore();
  const { configs, toggles, permissions } = useGetUserPreferences();

  const { sidebar } = configs;

  useSidebar({
    items: sidebar
      .filter((item: SideBarItem) => !item.toggle || toggles.has(item.toggle))
      .filter((item: SideBarItem) => !item.permission || permissions.has(item.permission))
      .map((item: SideBarItem, index: number) => ({
        id: `${index}-${item.title}`,
        title: formatMessage({ id: item.title }),
        icon: () => getIcon({ icon: item.icon }),
        path: item.path,
      })),
    utils: [],
  });

  return (
    <Grid.Container sidebar type="fluid" data-testid="router-container">
      {snackbarMessage && (
        <Alert
          css={{ position: 'fixed', zIndex: '4000', bottom: '$10', right: '$10' }}
          type={snackbarType}
          message={snackbarMessage}
        />
      )}

      <Outlet />
    </Grid.Container>
  );
};
