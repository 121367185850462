import { Breadcrumb, Grid, Heading } from '@hexa-ui/components';
import { ChevronLeft } from '@hexa-ui/icons';
import { BASE_PATH } from 'constants/routes/internal';
import { useAppHeader } from 'providers/AppHeaderProvider/AppHeaderProvider';
import { useRouterService } from 'providers/RouterServiceProvider';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  AppHeaderWrapper,
  ButtonBackNavigation,
  ButtonsWrapper,
  StyledLink,
} from './AppHeader.styles';

export interface Crumb {
  name?: string;
  path?: string;
}

const AppHeader = () => {
  const { crumbs, pageTitle, optionalButtons, isVisible } = useAppHeader();
  const { navigate } = useRouterService();

  return crumbs && crumbs.length ? (
    <AppHeaderWrapper isVisible={isVisible} type="fluid">
      <Grid.Item lg={12} md={12} sm={12} xl={12} xs={12}>
        {crumbs.length > 0 && (
          <Breadcrumb.Root>
            <Breadcrumb.HomeItem asChild>
              <Link to={`${BASE_PATH}`} />
            </Breadcrumb.HomeItem>
            {crumbs.map(({ name }, key) => (
              <Breadcrumb.Item asChild key={key} isCurrentPage={key + 1 === crumbs.length}>
                <StyledLink
                  data-testid={`${name}-link`}
                  onClick={() => navigate((crumbs.length - key) * -1 + 1)}
                >
                  <FormattedMessage id={name} />
                </StyledLink>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb.Root>
        )}
      </Grid.Item>
      <Grid.Item lg={12} md={12} sm={12} xl={12} xs={12}>
        {crumbs && crumbs.length > 1 && (
          <ButtonBackNavigation
            data-testid="back-button"
            size="medium"
            icon={ChevronLeft}
            variant="tertiary"
            onClick={() => navigate(-1)}
          />
        )}
        <Heading size="H2" data-testid={'page-header'}>
          <FormattedMessage id={pageTitle} />
        </Heading>
        <ButtonsWrapper>{optionalButtons}</ButtonsWrapper>
      </Grid.Item>
    </AppHeaderWrapper>
  ) : (
    <></>
  );
};

export default AppHeader;
