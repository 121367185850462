import { useEffect } from 'react';

import { Overview } from '@/components/Overview/Overview';
import { TableVisitCard } from '@/components/TableVisitCard/TableVisitCard';

import { useVisits } from './hooks/useVisits';
import * as Styled from './Visits.styles';

export const Visits = (): JSX.Element => {
  useVisits();

  useEffect(() => {
    document.body.style.setProperty('overflow-y', 'auto', 'important');
    return () => {
      document.body.style.removeProperty('overflow-y');
    };
  }, []);

  return (
    <Styled.VisitsPage>
      <Overview />
      <TableVisitCard />
    </Styled.VisitsPage>
  );
};
