import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { ScreenName } from '@/domains'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import { createExclusionRule } from '../RulesService'
import { CreateExclusionRuleDTO, EnuStatusRule } from '../RulesService.types'

export const useCreateExclusionRule = (): {
	isLoading: boolean
	postExclusionRule: (payload: CreateExclusionRuleDTO) => void
} => {
	const toast = useToast()
	const { t } = useTranslation()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()
	const selectedZone = useCurrentZone()
	const { selectedTierKey, currentTier } = useCurrentTier()

	const { isLoading, mutate: postExclusionRule } = useMutation(
		(payload: CreateExclusionRuleDTO) => {
			return createExclusionRule({ ...payload, vendorId })
		},
		{
			onSuccess: async (response) => {
				const isDraft = response.status === EnuStatusRule.draft

				toast.notify({
					type: TypeToast.SUCCESS,
					message: isDraft
						? t('rules:SUCCESS_SAVE_DRAFT_RULE')
						: t('rules:CREATE_EARNING_RULES.CREATE_RULE_SUCCESSFUL_MESSAGE'),
				})
				await queryClient.invalidateQueries(['exclusionRules'])

				AnalyticsService.events.ruleCreated({
					amount: null,
					audience_id: response.audienceName,
					earning_rule_description: response.description,
					earning_rule_id: response.ruleId,
					points: null,
					rule_type: 'Exclusion List',
					tier: currentTier ? currentTier?.label : selectedTierKey,
					user: getUserEmail(),
					vendor: vendorId,
					zone: selectedZone,
					screen_name: ScreenName.CreateExclusionRule,
				})

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: 'An error ocurred. Try again.',
				})
			},
		},
	)

	return {
		isLoading,
		postExclusionRule,
	}
}
