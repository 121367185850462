import { generatePath } from 'react-router-dom';

export const FEEDBACK_ROUTES = {
  getFeedbackScore: {
    route: '/feedback/:idFeedback/charts/scoreFeedback/',
    generate(idFeedback) {
      return generatePath(this.route, { idFeedback });
    },
  },
  postFinishFeedback: {
    route: '/feedback/:idFeedback/finish',
    generate(idFeedback) {
      return generatePath(this.route, { idFeedback });
    },
  },
  postFeedbackPillar: '/feedback/pillars-employee',
  getKpisForFeedback: {
    route: '/feedback/:idFeedback/pillars/:idPillar/kpis',
    generate(idFeedback, idPillar) {
      return generatePath(this.route, {
        idFeedback: String(idFeedback),
        idPillar: String(idPillar),
      });
    },
  },
  savePillarFeedbackResponses: {
    route: '/feedback/:idFeedback/responses',
    generate(idFeedback) {
      return generatePath(this.route, { idFeedback: String(idFeedback) });
    },
  },
  getEmployeeFeedbacksById: {
    route: '/report/employees/:idEmployee/feedbacks/:idFeedback',
    generate(idEmployee, idFeedback) {
      return generatePath(this.route, { idEmployee, idFeedback: String(idFeedback) });
    },
  },
};
