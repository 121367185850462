import { Card, Grid } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import PageTitle from '../../../components/PageTitle/PageTitle';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import useChallengeHandler from '../../../hook/useChallengeHandler';
import { getJsonApiData } from '../../../utils/formatData';
import RewardsChallengesHeaders from '../../json/columnHeaders/RewardsChallenges.json';

const RewardsChallenges: React.FC = () => {
    const navigate = useNavigate();
    const [rewardsChallengesData, setRewardsChallengesData] = useState<any[]>();
    const { getRewardsChallenges } = useChallengeHandler();
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState({
        total: 0,
        page: 0,
        limit: 25,
        search: ''
    })

    const fetchData = async () => {
        setLoading(true)
        await getRewardsChallenges(requestData)
            .then(res => {
                const formatData = getJsonApiData(res)
                console.log({ formatData })
                setRewardsChallengesData(formatData.data)
                setRequestData(prevState => ({ ...prevState, total: formatData.total }))
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchRewards = (value) => {
        requestData.search = value
        fetchData();
    };

    const debouncedSearch = useCallback(
        debounce((value) => handleSearchRewards(value), 250),
        []
    );

    const handleChange = (value) => {
        setSearch(value);
        debouncedSearch(value)
    }

    const handlePagination = (page, limit) => {
        requestData.limit = limit
        requestData.page = page >= 1 ? page - 1 : page
        fetchData();
    };

    const PaginationContainer = styled(Grid.Item, {
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '1rem',
        gap: '0.5rem',
        flexWrap: "wrap"
    });

    return (
        <>
            <PageTitle marginBottom="1.2rem" title="Rewards challenges" />
            <Card elevated={'medium'} border={'large'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        padding: '1rem 20px',
                        alignItems: 'center',
                    }}
                >
                    <StyledFilter
                        maxWidth="496px"
                        width="496px"
                        placeholder="Start typing to filter results"
                        value={search}
                        onChange={(e) => handleChange(e.currentTarget.value)}
                        onClear={() => handleChange('')}
                    />
                    <StyledButton
                        variant="primary"
                        disabled={loading}
                        icon={Plus}
                        leading
                        onClick={() =>
                            navigate(
                                `/bees-games/rewards-challenges/import-challenges`
                            )
                        }
                    >
                        Import Challenge
                    </StyledButton>
                </div>
                <StyledTable
                    loading={loading}
                    columns={RewardsChallengesHeaders}
                    data={rewardsChallengesData !== undefined ? rewardsChallengesData : []}
                    pagination={false}
                />
                <PaginationContainer>
                    <StyledPagination
                        pageSize={requestData.limit}
                        total={requestData.total}
                        onChange={handlePagination}
                    />
                </PaginationContainer>
            </Card>
        </>
    );
};

export default RewardsChallenges;
