import { useEnvService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import startServer from './server';

if (useEnvService().getEnv() === 'LOCALHOST') {
  startServer().logging = true;
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary: (error) => {
    console.table(error);
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
