import { ColumnData } from 'pages/Reports/types/Reports.types';
import { parsePaymentValueColumn } from 'pages/Reports/utils/parseColumn';

export const columnsTable: ColumnData[] = [
  {
    Header: 'Payment ID',
    accessor: 'paymentId',
    key: 'PAYMENT_ID',
  },
  {
    Header: 'Order ID',
    accessor: 'orderNumber',
    key: 'ORDER_NUMBER',
  },
  {
    Header: 'Order Amount',
    accessor: 'orderValue',
    key: 'ORDER_VALUE',
  },
  {
    Header: 'Buyer ID',
    accessor: 'buyerId',
    key: 'BUYER_ID',
  },
  {
    Header: 'Seller ID',
    accessor: 'sellerId',
    key: 'INVOICE_DELIVERY_CENTER_ID',
  },
  {
    Header: 'Invoice Delivery Date',
    accessor: 'invoiceDeliveryDate',
    key: 'INVOICE_DELIVERY_DATE',
  },
  {
    Header: 'Invoice Date',
    accessor: 'invoiceDate',
    key: 'INVOICE_DATE',
  },
  {
    Header: 'Account ID',
    accessor: 'accountId',
    key: 'ACCOUNT_ID',
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    key: 'INVOICE_NUMBER',
  },
  {
    Header: 'Payment Value',
    accessor: 'paymentValue',
    key: 'PAYMENT_VALUE',
    Cell: parsePaymentValueColumn,
  },
  {
    Header: 'Payment Status',
    accessor: 'paymentStatus',
    key: 'PAYMENT_STATUS',
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    key: 'PAYMENT_METHOD',
  },
  {
    Header: 'Payment Method Code',
    accessor: 'paymentMethodCode',
    key: 'PAYMENT_METHOD_CODE',
  },
  {
    Header: 'Creation Date and Time',
    accessor: 'orderDate',
    key: 'ORDER_DATE',
  },
  {
    Header: 'Order Status',
    accessor: 'orderStatus',
    key: 'ORDER_STATUS',
  },
  {
    Header: 'Invoice Value',
    accessor: 'invoiceValue',
    key: 'INVOICE_VALUE',
  },
  {
    Header: 'Invoice Status',
    accessor: 'invoiceStatus',
    key: 'INVOICE_STATUS',
  },
  {
    Header: 'Barcode ID',
    accessor: 'barCodeGenerated',
    key: 'PAYMENT_CODE',
  },
  {
    Header: 'Payment Date and Time',
    accessor: 'paymentDate',
    key: 'PAYMENT_DATE',
  },
  {
    Header: 'Convenience Store',
    accessor: 'storeName',
    key: 'STORE_NAME',
  },
  {
    Header: 'Beneficiary Name',
    accessor: 'beneficiaryName',
    key: 'BENEFICIARY_NAME',
  },
  {
    Header: 'Beneficiary ID',
    accessor: 'beneficiary',
    key: 'BENEFICIARY_ID',
  },
  {
    Header: 'Fee 1',
    accessor: 'fee1',
    key: 'FEE_1_AMOUNT',
  },
  {
    Header: 'Fee 2',
    accessor: 'fee2',
    key: 'FEE_2_AMOUNT',
  },
];
