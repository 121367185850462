export enum Countries {
  AR = 'AR',
  BE = 'BE',
  BR = 'BR',
  CA = 'CA',
  CO = 'CO',
  DE = 'DE',
  DO = 'DO',
  EC = 'EC',
  ES = 'ES',
  GB = 'GB',
  HN = 'HN',
  ID = 'ID',
  KR = 'KR',
  MX = 'MX',
  NL = 'NL',
  PA = 'PA',
  PE = 'PE',
  PH = 'PH',
  PY = 'PY',
  SV = 'SV',
  ZA = 'ZA',
}
